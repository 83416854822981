
<div class="panel-admin">
    <div class="container-panel">
        <app-menu-panel [itemActive]="tabActive" (changeTab)="changeTab($event)"></app-menu-panel>
        <div class="panel-dashboard container">
            <app-breadcrumb></app-breadcrumb>
            <div class="submenu-router" *ngIf="activeSubmenu && !routerCreateEvent">
                <app-submenu-panel></app-submenu-panel>
                <mat-divider></mat-divider>
            </div>
            <div class="router-panel">
                <div *ngIf="routerDetailEvent || routerVenues || routerCreateEvent; else detailEvents">
                    <app-router-events *ngIf="routerDetailEvent"></app-router-events>
                    <app-router-venue *ngIf="routerVenues"></app-router-venue>
                    <app-router-create *ngIf="routerCreateEvent"></app-router-create>
                </div>
                <ng-template #detailEvents>
                    <router-outlet></router-outlet>
                </ng-template>
            </div>
        </div>
    </div>
</div>