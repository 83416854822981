import { Component } from '@angular/core';

@Component({
  selector: 'app-paylands-template',
  templateUrl: './paylands-template.component.html',
  styleUrls: ['./paylands-template.component.scss']
})
export class PaylandsTemplateComponent {

  data!: any;
}
