import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  constructor(private http: HttpClient) { }

  getChatbot(idChatbot: any): Observable<any> {
    let url = `${AppSettings.API_CHATBOT}/api/chatbot/web`;
    if (idChatbot) {
      url += '/' + idChatbot;
    }
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(url, formHeaders).pipe();
  }

  sendMessage(idChatbot: any, message: any, userId: number): Observable<any> {
    const url = `${AppSettings.API_CHATBOT}/api/chatbot/message/${idChatbot}`;
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const content = { message: message, userId: userId }
    return this.http.post<any>(url, content, formHeaders).pipe();
  }
}
