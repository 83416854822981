import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { ReportsService } from 'src/app/services/reports.service';
import { QrCardComponent } from '../../bloques/qr-card/qr-card.component';
import { InfoTicketComponent } from '../../totem/info-ticket/info-ticket.component';

@Component({
  selector: 'app-confirm-pay',
  templateUrl: './confirm-pay.component.html',
  styleUrls: ['./confirm-pay.component.scss']
})
export class ConfirmPayComponent implements OnInit {

  idDownload!: any;
  error!: string;
  isAdmin: boolean = false;

  // totem
  totemActive: any = AppSettings.ENV_TOTEM;

  constructor(private dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) public data, private router: Router, private auth: AuthenticationService,
    private panelService: PanelAdminService, private dialogRef: MatDialogRef<ConfirmPayComponent>, private reportService: ReportsService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.idDownload = this.data?.idOdoo;
    let profile = this.auth.getProfile();
    this.isAdmin = profile?.roleFunctions?.length > 0;
  }

  openPrint() {
    this.reportService.getUrlsDownload(this.idDownload).subscribe({
      next: (url) => {
        if(this.totemActive) {
          this.imprimirPDF(url['ticket_totem']);
        } else {
          let uriDownload = url['ticket_a4'];
          window.open(uriDownload, '_blank');
        }
        this.dialogRef.close({print: true});
      }, error: (err) => {
        this.error = '¡Vaya! Se ha producido un error al descargar la entrada. Podrá descargarla desde el correo o desde su Perfil.';
      }
    })
  }


  // imprimirPDF(url: string) {
  //   const ventana = window.open(url);

  //   if (ventana) {
  //     ventana.print();

  //     setTimeout(() => {
  //       ventana.close();
  //     }, 2000); // Se espera medio segundo para asegurarse de que el PDF se carga antes de imprimir
  //   }
  // }

  openProfile() {
    // Comprobar si es gestor y mandar a pedidos
    this.dialog.closeAll();
    let url = !this.isAdmin ? '/profile' : '/panel-control/orders/list';
    this.router.navigate([url]);
  }

  goHome() {
    if(this.totemActive) {
      this.router.navigate(['/home-totem']);
    }
    this.dialog.closeAll();
  }

  imprimirPDF(base64String: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob);
    const ventana = window.open(url, '_blank');

    if (ventana) {
      setTimeout(() => {
        ventana.print();
      }, 500); // Se espera medio segundo para asegurarse de que el PDF se carga antes de imprimir
    }
  }

  openTicket() {
    this.dialog.open(InfoTicketComponent, {
      panelClass: 'info-dialog',
      data: {
        idTicket: this.idDownload,
        buyTickets: this.data?.seats
      }
    });
  }

  printPDF() {
    const targetElement: any = document.getElementById("printContainer");
    const WindowPrt: any = window.open('', '', 'left=500,top=100,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(targetElement.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

}
