import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class ComunicationsService {

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí la descripción del evento',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]    
  };

  constructor(private http: HttpClient) { }

  sendSubscribersEmail(idCompany: number, payload: any) {
    const url = `${AppSettings.API_URI}/massiveEmails/subscribers/${idCompany}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getSubscribersEmail(idCompany: number, places: any[], page: number, searchText: string) {
    let url = `${AppSettings.API_URI}/massiveEmails/subscribers/${idCompany}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (places.length > 0) {
      places.forEach(element => {
        url += `&places[]=${element}`;
      });
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
