<div class="menu-subscriptions">
    <!-- Notifications Oficial -->
    <div class="section">
        <p class="title-section m-0">Miembro de:</p>
        <mat-divider></mat-divider>
        <p class="info-subscription" *ngIf="!subscriptions || subscriptions?.length <= 0">
            Actualmente no tienes ninguna suscripción activa.
        </p>

        <!-- Options -->
        <div class="option" *ngFor="let subscription of subscriptions">
            <p class="m-0 option-section" (click)="openPlace(subscription.place)">{{subscription.place.name}}</p>
            <!-- <div class="link-cancel-subscription" (click)="cancelSubscription(subscription)">
                <span>Cancelar suscripción</span>
            </div> -->
        </div>
        <mat-divider></mat-divider>
    </div>

    <!-- <div class="d-flex justify-content-center" style="margin-top: 40px;">
        <button class="primary-button solid w-100" style="min-height: 40px;" (click)=saveNotifications()>Guardar cambios</button>
    </div> -->
</div>