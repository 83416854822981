import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import {MapsService} from 'src/app/services/maps.service'
import { EventosService } from 'src/app/services/eventos.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
@Component({
  selector: 'app-list-map',
  templateUrl: './list-map.component.html',
  styleUrls: ['./list-map.component.scss']
})
export class ListMapComponent implements OnInit {

  @Output() results = new EventEmitter<boolean>();
  exists: boolean = false;
  loaded: boolean = false;
  @Input() categoryPage: boolean = false;
  @Input() slugEvent!: any;

  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };
  event!: any;
  @ViewChild("maps1") divContainerMap: any;
  eventsData:Array<any> = [];
  constructor( private filterService: FilterService, private router: Router,private activatedRoute: ActivatedRoute,private mapas: MapsService,private eventosService:EventosService) { }

  ngOnInit(): void {
  }

  existsResults(exists: any) {
    this.exists = exists;
    // this.results.emit(exists);
  }

  loadingList(loaded: any) {
    this.loaded = loaded;
  }

}
