import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ChangeNameComponent } from 'src/app/components/profile/config/change-name/change-name.component';
import { ChangePasswordComponent } from 'src/app/components/profile/config/change-password/change-password.component';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PermissionService } from 'src/app/services/permission.service';
import { PlacesService } from 'src/app/services/places.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-info-profile',
  templateUrl: './info-profile.component.html',
  styleUrls: ['./info-profile.component.scss']
})
export class InfoProfileComponent {

  user!: any;
  now: Date = new Date();
  functions: Observable<any> = new Observable();

  openPermission: boolean = true;
  permissionExtra: any[] = [];

  userPermission: Observable<any> = new Observable();
  companyInfo: Observable<any> = new Observable();

  placesUser: any[] = [];
  rolesUser: any[] = [];
  allPlaces: boolean = false;

  changedUser!: any;
  imgControl: FormControl = new FormControl(null);
  changedImg: boolean = false;
  urlImages: string = AppSettings.MEDIA_URI + '/images/users/';

  constructor(private auth: AuthenticationService, private placesService: PlacesService, private permissionService: PermissionService,
    private dialog: MatDialog, private userService: UserService) { }

  ngOnInit(): void {
    this.user = this.auth.getProfile();
    if (this.user.imagen) {
      this.imgControl.setValue(this.urlImages + '/' + this.user.imagen);
    }
    const business = JSON.parse(localStorage.getItem('business') || '{}');

    if (business && business.id) {
      this.companyInfo = this.permissionService.getInfoEnabledCompany(business?.id).pipe(map((res) => res.data));
      this.userPermission = this.permissionService.getPermissionUser(business?.id, this.user.id).pipe(map((res) => res.data));

      this.permissionService.getPermissionUser(business.id, this.user.id).subscribe({
        next: (res) => {
          this.placesUser = res.data.places;
          this.rolesUser = res.data.roles.map((data: any) => data.role);
        }
      });
    }
  }

  ngAfterViewInit(): void {

  }

  saveProfile() {

  }

  onFileChange(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size >= 20000000) {
        this.openInfoCard('La imagen no puede ocupar más de 2 Mb.');
      } else {
        const file = event.target.files[0];
        let formData = new FormData();
        formData.append("file", file, file.name);
        if (!file.type.match('image.*')) {
          alert('Solo se permite utilizar imagenes');
          return;
        }
        reader.readAsDataURL(file);
        reader.onload = () => {
          filePath = reader.result as string;
          this.imgControl.setValue(filePath);
          this.changedImg = true;
          this.saveImg();
        }
      }
    }
    if (!event.target.files) {
      if (this.user.imagen) {
        this.imgControl.setValue(this.urlImages + '/' + this.user.imagen);
      }
    }
  }

  saveImg() {
    this.userService.uploadFilePut({ user: this.user.id, file: this.imgControl.value }).subscribe({
      next: (res) => {
        this.openInfoCard('La imagen se ha actualizado correctamente', false, false);
        this.changedImg = false;
      }, error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al intentar actualizar la imagen', true, false);
        if (this.user.imagen) {
          this.imgControl.setValue(this.urlImages + '/' + this.user.imagen);
        } else {
          this.imgControl.setValue(null);
        }
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  openChangeName() {
    const dialogRef = this.dialog.open(ChangeNameComponent, {
      panelClass: 'info-dialog',
      data: {
        name: this.user.nombre
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res.status == 'changed') {
          this.openInfoCard('Los cambios se han guardado correctamente', false, false);
          this.user.nombre = res.name;
        }
        if (res.status == 'error') {
          this.openInfoCard('¡Vaya! Ha ocurrido un error.');
        }
      }
    })
  }

  openChangePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'info-dialog',
      data: {
        user: this.user
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res == 'changed') {
          this.openInfoCard('Los cambios se han guardado correctamente');
        }
        if (res == 'error') {
          this.openInfoCard('¡Vaya! Ha ocurrido un error.');
        }
      }
    })
  }
}
