<div class="background-global-footer">
    <div class="footer-component">
        <div class="background-footer">
            <img fallimg class="logo-footer" src="assets/img/citylok_vertical.svg" alt="Citylok logo">
            <div class="social-media-footer d-flex justify-content-between align-items-center">
                <i (click)="openSocialLink('twitter')" class="fa-brands fa-x-twitter fa-2x pointer-in"></i>
                <i (click)="openSocialLink('instagram')" class="fa-brands fa-instagram fa-2x pointer-in"></i>
                <i (click)="openSocialLink('youtube')" class="fa-brands fa-youtube fa-2x pointer-in"></i>
                <i (click)="openSocialLink('facebook')" class="fa-brands fa-facebook-f fa-2x pointer-in"></i>
            </div>
            <div class="menu-footer w-100">
                <div class="container-lg d-md-flex justify-content-between justify-content-md-around">
                    <div class="d-grid justify-content-center grid-group">
                        <p class="title-menu-footer" (click)="optioncityOpen=!optioncityOpen">
                            Citylok <i class="fa-solid {{ optioncityOpen ? 'fa-chevron-up' : 'fa-chevron-down' }} d-md-none"></i>
                        </p>
                        <div class="options-menu" [class.open-menu]="optioncityOpen">
                            <p routerLink="about-us">Quiénes somos</p>
                            <!-- <p>Trabaja con nosotros</p> -->
                            <!-- <p (click)="downloadZIP()">Kit de prensa</p> -->
                            <p routerLink="cityblog">Blog</p>
                        </div>
                    </div>
                    <div class="d-grid justify-content-center grid-group">
                        <p class="title-menu-footer" (click)="optionuserOpen=!optionuserOpen">
                            Usuario <i class="fa-solid {{ optionuserOpen ? 'fa-chevron-up' : 'fa-chevron-down' }} d-md-none"></i>
                        </p>
                        <div class="options-menu" [class.open-menu]="optionuserOpen">
                            <p routerLink="signup" *ngIf="!isLogin">Regístrate</p>
                            <p (click)="redirect('subir', 'new')">Sube tu lok</p>
                            <p (click)="redirect('favoritos', 'profile/favorites')">Mis loks favoritos</p>
                            <p (click)="redirect('cuenta', 'profile')">Mi cuenta</p>
                        </div>
                    </div>
                    <div class="d-grid justify-content-center grid-group">
                        <p class="title-menu-footer" (click)="optionlegalOpen=!optionlegalOpen">
                            Legales <i class="fa-solid {{ optionlegalOpen ? 'fa-chevron-up' : 'fa-chevron-down' }} d-md-none"></i>
                        </p>
                        <div class="options-menu" [class.open-menu]="optionlegalOpen">
                            <p routerLink="legal">Aviso legal</p>
                            <p routerLink="terms-conditions">Términos y condiciones</p>
                            <p routerLink="privacy">Privacidad</p>
                            <p routerLink="cookies">Cookies</p>
                        </div>
                    </div>
                    <div class="d-grid justify-content-center grid-group">
                        <p class="title-menu-footer" (click)="optioncontactOpen=!optioncontactOpen">
                            Contacto <i class="fa-solid {{ optioncontactOpen ? 'fa-chevron-up' : 'fa-chevron-down' }} d-md-none"></i>
                        </p>
                        <div class="options-menu" [class.open-menu]="optioncontactOpen">
                            <p routerLink="faqs">FAQs</p>
                            <p routerLink="suggestions">Sugerencias</p>
                            <p routerLink="contact">Formulario de contacto</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-copyright d-flex justify-content-center align-items-center">
                <p class="m-0">&#169; Kreare Mobile {{now | date:'y'}} - <a class="tel-link" href="mailto:hola@citylok.com"> hola&#64;citylok.com</a></p>
            </div>
        </div>
    </div>
</div>

<div class="section-cookies">
    <app-cookies></app-cookies>
</div>
