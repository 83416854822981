<div class="venues-menu-container">
    <div class="item-submenu" (click)="changeTabBusiness({tab: 0, name: 'Datos del espacio'})"
        [routerLink]="['/panel-control/config/venues/info-venue', idVenue]" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:true}">
        <span>Datos del espacio</span>
    </div>
    <!-- <div class="item-submenu" (click)="changeTabBusiness({tab: 1, name: 'Entradas base'})" [routerLink]="['/panel-control/config/venues/tickets-venue', idVenue]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"> -->
    <div class="item-submenu disabled">
        <span>Entradas base</span>
    </div>
    <!-- <div class="item-submenu" (click)="changeTabBusiness({tab: 2, name: 'Descuentos/Abonos'})" [routerLink]="['/panel-control/config/venues/discounts-venue', idVenue]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"> -->
    <div class="item-submenu disabled">
        <span>Descuentos</span>
    </div>
    <div class="item-submenu" (click)="changeTabBusiness({tab: 3, name: 'Aforo y Mapa de butacas'})" [routerLink]="['/panel-control/config/venues/map-venue', idVenue]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
    <!-- <div class="item-submenu disabled"> -->
        <span>Aforo y Mapa de butacas</span>
    </div>
    <div class="item-submenu" (click)="changeTabBusiness({tab: 4, name: 'Eventos del espacio'})" [routerLink]="['/panel-control/config/venues/event-venue', idVenue]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
    <!-- <div class="item-submenu disabled"> -->
        <span>Eventos del espacio</span>
    </div>
    <!-- <div class="item-submenu" (click)="changeTabBusiness({tab: 5, name: 'Documentos'})" [routerLink]="['/panel-control/config/venues/docs-venue']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"> -->
    <div class="item-submenu disabled">
        <span>Documentos</span>
    </div>
</div>