import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-router-permission',
  templateUrl: './router-permission.component.html',
  styleUrls: ['./router-permission.component.scss']
})
export class RouterPermissionComponent implements OnInit {

  roleActive!: any;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.roleActive = +params.role||0;
    });
  }

  changeMenuRole(event: any) {
    this.roleActive = event.id;
  }

}
