import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-events-venue',
  templateUrl: './events-venue.component.html',
  styleUrls: ['./events-venue.component.scss']
})
export class EventsVenueComponent {

  @Input() idVenue!: any;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idVenue = +params.idVenue;
      }
    });
  }
}
