<div class="edit-seats-object" *ngIf="!openSeatEditing; else editSeat">
    <div class="d-flex align-items-center mb-3 title-section" (click)="cancelEdit(true)">
        <i class="fa-light fa-arrow-left pointer-in" style="margin-right: 20px;"></i>
        <p class="m-0">Sección</p>
    </div>
    <p class="info-hidden">*Si editas el número de filas o de butacas la numeración de los asientos volvera a ser la
        inicial.
    </p>

    <div class="section-option" (click)="activeEditing()" *ngIf="!activedEditing">
        <p class="m-0">Editar sección</p>
        <i class="fa-light fa-pen"></i>
    </div>
    <div class="options-edit-section" [class.active]="activedEditing">
        <div class="section-edit">
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Identificador:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Identificador" [(ngModel)]="sectionNumber" (change)="editId()" required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Sección:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Nombre" [(ngModel)]="valueName" (change)="editName()" required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Área:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Identificador área" [(ngModel)]="areaNumber" (change)="editArea()" required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Mostrar nombre en mapa:</p>
                <mat-checkbox [(ngModel)]="visibilityNameSection" class="checkbox" color="primary"
                    (change)="changeVisibilityName()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Mostrar nombre de filas:</p>
                <mat-checkbox [(ngModel)]="visibilityNameRows" class="checkbox" color="primary"
                    (change)="changeVisibilityRows()"></mat-checkbox>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between">
                <p class="m-0" (click)="changeRows()">Cambiar asientos:</p>
            </div> -->
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Filas:</p>
                <div class="bloque-change-section container-form d-flex align-items-center change-number">
                    <input type="text" placeholder="0" [(ngModel)]="sectionSeats.rows" (change)="editRowsColumns()"
                        required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Butacas por fila:</p>
                <div class="bloque-change-section container-form d-flex align-items-center change-number">
                    <input type="text" placeholder="0" [(ngModel)]="sectionSeats.columns" (change)="editRowsColumns()"
                        required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Total:</p>
                <p class="m-0">{{sectionSeats.rows * sectionSeats.columns - invisibleSeats.length}}</p>
            </div>

            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Inclinar:</p>
                <mat-slider min="-1.2" max="1.2" step="0.4" (valueChange)="editTilt($event)"><input matSliderThumb [value]="valueTilt" />
                    <input matSliderThumb>
                </mat-slider>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Rotar:</p>
                <mat-slider min="-180" max="180" step="10" (valueChange)="editRotation($event)"><input matSliderThumb [value]="valueAngle" />
                    <input matSliderThumb>
                </mat-slider>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between">
            <p class="m-0">Curvar:</p>
            <mat-slider>
                <input matSliderThumb>
            </mat-slider>
        </div> -->
        </div>
        <div class="section-option" (click)="editSeats()" *ngIf="activedEditing">
            <p class="m-0">Editar asientos</p>
            <i class="fa-light fa-pen"></i>
        </div>
        <div class="btns-save-apply" *ngIf="activedEditing">
            <div class="section-option w-100" (click)="cancelEdit()">
                <p class="m-0">Cancelar</p>
                <i class="fa-light fa-ban"></i>
            </div>
            <div class="section-option w-100" (click)="applyChanges()">
                <p class="m-0">Aplicar</p>
                <i class="fa-light fa-floppy-disk"></i>
            </div>
        </div>
    </div>
    <!-- <div class="section-option" (click)="changeHidden(false)">
        <p class="m-0">Duplicar</p>
        <i class="fa-light fa-eye-slash"></i>
    </div> -->
</div>
<ng-template #editSeat>
    <div class="edit-seats-object">
        <div class="d-flex align-items-center mb-3 title-section" (click)="cancelEditSeat()">
            <i class="fa-light fa-arrow-left pointer-in" style="margin-right: 20px;"></i>
            <p class="m-0">Editar asientos</p>
        </div>
        <app-edit-seats [canvas]="canvas" [section]="sectionSeats" [seatElement]="sitSelect"
            [seatsEditSelected]="seatsEditSelected" (editSeatParams)="saveEditSeats($event)"
            (cancel)="cancelEditSeats()"></app-edit-seats>
    </div>
</ng-template>