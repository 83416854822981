<div class="new-mail-subscribers">
    <div class="main-mail-subscriber">
        <h1 class="title-mail-subscriber">Comunicación para todos los usuarios</h1>
        <p class="subtitle-mail-subscriber">Ingresa los datos del email que recibirán todos los usuarios suscritos.</p>
        <div class="form-info-user">
            <form class="form-grid" [formGroup]="emailSubscription">
                <div class="m-2">
                    <p class="label-input">Asunto</p>
                    <div class="form-input-container">
                        <input type="text" class="w-100" formControlName="subject">
                    </div>
                </div>
                <div class="m-2">
                    <p class="label-input">Adjuntos</p>
                    <div class="form-input-container btn-upload">
                        <!-- Permitir múltiples archivos -->
                        <input id="file" hidden #file type="file" (change)="onFileChange($event)" multiple>
                        <div class="btn-create m-0" (click)="file.click()">
                            <i class="fal fa-arrow-up-from-bracket"></i>
                            <p class="m-0">Subir archivos</p>
                        </div>
                    </div>
                    <div class="name-file-upload" *ngIf="files.length > 0">
                        <!-- Mostrar los archivos seleccionados -->
                        <ul>
                            <li *ngFor="let file of files">{{file.name}}</li>
                        </ul>
                    </div>
                </div>
                <div class="m-2">
                    <p class="label-input">Mensaje</p>
                    <angular-editor #edit id="1" formControlName="body" [config]="editorConfig">
                    </angular-editor>
                </div>
            </form>
        </div>
    </div>
    <div class="footer-dialog-comunication">
        <mat-divider></mat-divider>
        <div class="footer-new-subscriber">
            <div class="btn-new btn-create btn-create-border m-0" [class.disabled]="emailSend" (click)="cancelSend()">
                <p class="m-0">Cancelar</p>
            </div>
            <div class="btn-new btn-create m-0" [class.sending]="emailSend" (click)="sendEmail()"
                [class.disabled]="emailSubscription.invalid || isSizeExceeded">
                <p class="m-0">{{emailSend ? 'Enviando...' : 'Aceptar'}}</p>
            </div>
        </div>
    </div>
</div>