import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { of, throwError } from 'rxjs';
import { CommonService } from './common.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EditEventService {

  formHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient, private common: CommonService) { }

  editConfig(eventId: number, payload: string) {
    const url = `${AppSettings.API_URI}/edit/config/${eventId}`;
    return this.http.put<any>(url, payload, this.formHeaders).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  editCategory(eventId: number, payload: string) {
    const url = `${AppSettings.API_URI}/edit/evento/${eventId}/category`;
    return this.http.put<any>(url, payload, this.formHeaders).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  editExtraFile(eventId: number, payload: any) {
    const url = `${AppSettings.API_URI}/edit/extra/file/${eventId}`;
    return this.http.put<any>(url, payload, this.formHeaders).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}