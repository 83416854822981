import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';

@Component({
  selector: 'app-router-events',
  templateUrl: './router-events.component.html',
  styleUrls: ['./router-events.component.scss']
})
export class RouterEventsComponent implements OnInit {

  event!: any;
  profile!: any;
  title!: any;
  nameTab: string = 'Información del evento';
  numberTab: number = 1;
  isDraftEvent: boolean = false;
  activePublish: boolean = false;
  mapPage: boolean = false;
  actionMap!: any;

  allowCreate!: any;
  isDraft: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private eventosService: EventosService,
    private auth: AuthenticationService, private apiEvents: EventosService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    let url = location.pathname.split('/');
    this.mapPage = location.pathname.indexOf('map-event') != -1;
    this.isDraftEvent = location.pathname.indexOf('draft/detail/') != -1;
    this.activePublish = location.pathname.indexOf('draft/detail/info-event') != -1;
    let idEvent = +url[url.length - 1];
    this.event = idEvent;
    this.eventosService.setEventPanel(+idEvent);
    this.eventosService.getEventByIdAdmin(idEvent).subscribe({
      next: (data) => {
        this.isDraft = data.draft;
        this.title = data.titulo;
      }
    });

    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    // this.allowCreate = rolesProfile?.find((element) => (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER'));
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS') {
        this.allowCreate = true;
      }
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        var url = event.url;
        this.mapPage = url.indexOf('map-') != -1;
        this.activePublish = location.pathname.indexOf('draft/detail/info-event') != -1;
      }
    });

    this.apiEvents.mapActionObservable.subscribe({
      next: (data) => {
        if (data) {
          this.actionMap = data.action || data.open ? data : null;
        }
      }
    });
  }

  changeMenuEvent(event: any) {
    this.nameTab = event.name;
    this.numberTab = event.tab;
  }

  changeActionMap(save: boolean) {
    this.apiEvents.saveMapAction(save);
  }

  openLinkEvent() {
    let titleUrl = this.title?.replace(/[^\w\s]/g, '-').replace(/\s+/g, '-').replace(/-{2,}/g, '-').replace(/-{3,}/g, '-');
    if (titleUrl?.charAt(titleUrl.length - 1) === '-') titleUrl = titleUrl.slice(0, -1);
    let url = `${AppSettings.FRONT}`;
    if (this.isDraft) {
      url += '/draft';
    }
    url += `/event/${this.event + '-' + titleUrl?.toLowerCase()}`;
    return url;
    // window.open(url, '_blank');
  }

  publishEvent() {
    this.eventosService.putEventos(this.event, { draft: false, activo: true }).subscribe({
      next: (res) => {
        const dialogRef = this.dialog.open(InfoCardComponent, {
          panelClass: 'info-dialog',
          data: {
            icon: false,
            text: 'Evento publicado correctamente. Ahora podrás verlo en eventos publicados.',
            btnCancel: false
          }
        });
        dialogRef.afterClosed().subscribe({
          next: () => {
            this.router.navigate(['/panel-control/events/detail/info-event', this.event]);
          }
        });
      }
    });
  }

  copyLink() {
    const link = this.openLinkEvent(); // Obtén el enlace desde la función
    navigator.clipboard.writeText(link).then(() => {
      this.infoDialog('¡Enlace copiado al portapapeles!');
    });
  }

  infoDialog(msg: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: msg,
        btnCancel: false
      }
    });
  }

}
