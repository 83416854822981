import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-private-event',
  templateUrl: './private-event.component.html',
  styleUrls: ['./private-event.component.scss']
})
export class PrivateEventComponent {

  codeEvent!: any;

  constructor(private dialogRef: MatDialogRef<PrivateEventComponent>) {}

  validateCode() {
    this.dialogRef.close(this.codeEvent)
  }
}
