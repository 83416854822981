import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { map, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { EventosService } from 'src/app/services/eventos.service';
import { FilterService } from 'src/app/services/filter.service';
import { UserService } from 'src/app/services/user.service';
import { MapsService } from 'src/app/services/maps.service'

@Component({
  selector: 'app-infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.scss']
})
export class InfinityScrollComponent implements OnInit {

  @Input() categoryPage = false;
  @Input() city!: any;
  slugCategory!: string;
  @Input() slugEvent!: Observable<string>;
  filtros: Observable<any[]>;
  @Input() map!: boolean;
  location: Observable<any>;
  loadingPage = false;
  eventos: any[] = [];

  // Subscriptions
  subscrition: any;
  slugSuscription: any;
  filtrosSubscribe: any;
  timeout = false

  // Eventos user
  @Input() idCreator!: any;
  @Input() isBusiness!: any;
  @Input() eventAfter: string = 'active';

  // Infinity scroll
  page: number = 1;
  items: number = 12;
  lastPage: any = 1;
  totalItems: number;
  @Input() teatroIdeal: boolean = false;

  finishScroll: boolean = false;
  @Output() results = new EventEmitter<boolean>();
  exists: boolean = true;
  @Output() loading = new EventEmitter<boolean>();
  @Output() chargeMap = new EventEmitter<boolean>();
  @Output() loadingMap = new EventEmitter<boolean>();
  chargedMap: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;

  loadingUser: boolean = false;
  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };
  @Input() fromHome: boolean = false;

  constructor(private eventosService: EventosService, private filterService: FilterService,
    private commonService: CommonService, private eventUser: UserService, private mapas: MapsService) { }

  ngOnInit(): void {
    this.loadingPage = false;
    this.eventos = [];
    this.filterService.removeFilter({ type: 'location', id: 8 })
    if (window.innerWidth < 1800 && window.innerWidth >= 1400 && !this.map) {
      this.items = 10;
    }
    if (this.map) {
      this.items = 50;
      this.mapas.removeMarkers();
      this.mapas.searchMaps();
    }
    this.filtros = this.filterService.filter;
    this.filtrosSubscribe = this.filtros?.subscribe((result) => {
      this.subscrition = this.commonService.cityStorage.subscribe(city => {
        if (this.city != city) {
          this.mapas.removeMarkers();
          if (this.city && !this.loadingPage) {
            this.filterService.resetAllFilters();
          }
          this.city = city;
        }
        if (!this.loadingPage && !this.loadingUser) {
          this.loadingUser = this.idCreator ? true : false;
          this.initLoading();
        }
      });
    })
    this.slugSuscription = this.slugEvent?.subscribe(slug => {
      if (slug && this.slugCategory != slug) {
        this.slugCategory = slug;
        if (this.slugCategory) {
          this.mapas.removeMarkers();
          this.filterService.resetAllFilters();
        }
      }
    });
  }

  initLoading() {
    if (this.timeout == false && !this.loadingPage) {
      this.loadingPage = true;
      this.page = 1;
      if (!this.map) { this.onScrollToTop(); }
      this.finishScroll = false;
      this.eventos = [];
      this.results.emit(true);
      this.loading.emit(true);
      setTimeout(() => {
        this.timeout = true;
        this.getMoreEvents();
      }, 200);
    }
  }

  getMoreEvents() {
    if (this.timeout) {
      this.timeout = false;
      if (this.idCreator) {
        this.eventUser.getEventsCreator(this.isBusiness, this.idCreator, this.page, this.items).subscribe(data => {
          this.getEventsPaginator(data);
        });
      }
      if (!this.idCreator) {
        let filters = this.filterService.getFiltersValue();
        let search = localStorage.getItem('search') || '';
        if (this.categoryPage) {
          this.eventosService.getEventosSearchingClose(search, this.page, this.items, this.city, filters, this.map, this.slugCategory).subscribe(data => {
            this.getEventsPaginator(data);
          })
        }
        if ((!this.categoryPage)) {
          this.eventosService.getEventosSearchingClose(search, this.page, this.items, this.city, filters, this.map, this.slugCategory).subscribe(data => {
            this.getEventsPaginator(data);
          })
        }
      }
    }
  }

  getEventsPaginator(res: any) {
    let eventos = res['hydra:member'] || res.data;
    if (eventos.length <= 0 && this.page == 1) {
      this.results.emit(false);
      this.exists = false;
      this.finishScroll = true;
    } else {
      this.results.emit(true);
      this.exists = true;
    }
    if (eventos.length == 0) {
      this.finishScroll = true;
    }
    if (eventos[0]) {
      this.eventos = this.eventos.concat(eventos);
      // this.totalItems = this.eventos.length;
      // const urlLastPage: string = data['hydra:view']['hydra:last'] || '';
      // const equal = urlLastPage.lastIndexOf('=');
      // this.lastPage = urlLastPage.substring(equal+1);
      this.finishScroll = false;
    }
    if (this.loadingPage && this.map) {
      this.mapas.removeMarkers();
      this.mapas.markEvents(this.eventos);
      this.loadingMap.emit(true);
      this.chargeMap.emit(true);
    }
    this.loadingPage = false;
    this.loading.emit(false);
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.finishScroll && !this.loadingPage && this.exists) {
      const element = this.containerScroll.nativeElement.getBoundingClientRect();
      let bottomShown = this.map ? false : element.bottom <= window.innerHeight;
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        bottomShown = true;
      }
      if (bottomShown && !this.finishScroll) {
        this.page++;
        this.loadingPage = true;
        this.loading.emit(true);
        setTimeout(() => {
          this.timeout = true;
          this.getMoreEvents();
        }, 200);
      }
    }
  }
  mouseEnter(id) {
    this.eventosService.getEventById(id).subscribe(data => {
      this.mapas.jumpMarker(data.latitude, data.longitude);
    });
  }

  @HostListener('window:resize')
  onResize() {
    // if(!this.map){
    //   this.items = 12;
    //   if(window.innerWidth < 1800 && window.innerWidth >= 1400) {
    //     this.items = 10;
    //   }
    //   this.recalculateItemsView();
    // }
  }

  recalculateItemsView() {
    this.totalItems = this.eventos.length;
    const res = this.totalItems % this.items;
    if (res > 0) {
      if (this.totalItems > res) {
        this.eventos.splice(this.eventos.length - res, res);
        this.page = this.eventos.length / this.items + 1;
      } else {
        this.page = 1;
        this.getMoreEvents();
      }
      // if(this.lastPage <= this.page) {
      //   this.finishScroll = false;
      // }
    }
  }

  onScrollToTop() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.subscrition.unsubscribe();
    this.slugSuscription?.unsubscribe();
    this.filtrosSubscribe?.unsubscribe();
  }

  eliminado(event) {
    if (false) {
      this.eventos = this.eventos.filter((evento: any) => evento.id != event);
    }
  }

}
