<div class="form-tickets-lok">
    <div class="container-tickets">
        <!-- Free -->
        <!-- <div class="d-flex align-items-center justify-content-between section-free">
            <p class="title-free">Entrada libre</p>
            <mat-slide-toggle [(ngModel)]="free"></mat-slide-toggle>
        </div> -->
        <mat-divider></mat-divider>
        <!-- Precio desde de entradas -->
        <div class="input-price section-free">
            <p class="title-free">Precio desde</p>
            <div class="input-border input-ticket">
                <input placeholder="Precio desde" type="number" min="0" [(ngModel)]="minPrice" />
                <i class="fa-light fa-euro-sign icon-primary"></i>
            </div>
            <div class="info-send-tickets">
                <p class="m-0">
                    <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>
                    El precio desde es el precio mínimo que se mostrará en la web, en caso de no indicarlo será el
                    de la
                    entrada más económica.
                </p>
            </div>
        </div>
    </div>
    <button class="btn-new-form btn-save w-100" (click)="save()">GUARDAR</button>
</div>