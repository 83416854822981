import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient) { }

  getRolesFunctions() {
    const url = `${AppSettings.API_URI}/all/functions`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getInfoEnabledCompany(idCompany: any) {
    const url = `${AppSettings.API_URI}/functions/enabled/company/${idCompany}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getPermissionCompany(idCompany: any, page: any, searchText: any, role: any, sorted: any) {
    let url = `${AppSettings.API_URI}/functions/all/company/${idCompany}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (role) {
      url += `&role=${role}`;
    }
    if (sorted) {
      url += `&orderBy=${sorted.sortedBy}&order=${sorted.isAsc ? 'ASC' : 'DESC'}`;
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getPermissionByFilter(idCompany: any, page: any, searchText: any) {
    const url = `${AppSettings.API_URI}/functions/filter/company/${idCompany}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getPermissionUser(idCompany, idUser: any) {
    const url = `${AppSettings.API_URI}/functions/company/${idCompany}?user=${idUser}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  savePermissionUser(idUser: any, payload: any) {
    const url = `${AppSettings.API_URI}/permission/edit/user/${idUser}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  deleteUsersPermission(idCompany: any, payload: any) {
    const url = `${AppSettings.API_URI}/company/delete/users/${idCompany}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getAllUsersBusiness(idCompany: any) {
    const url = `${AppSettings.API_URI}/all/users/company/${idCompany}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

}
