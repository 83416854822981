import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PanelAdminService } from 'src/app/services/panel-admin.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  itemsBreadCrumb: any[] = [];

  constructor(private panelService: PanelAdminService, private router: Router) { }

  ngOnInit(): void {
    this.getBreadCrumb();
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.getBreadCrumb();
        }
      });
  }

  getBreadCrumb() {
    this.itemsBreadCrumb = [];
    let urlTree = this.router.parseUrl(this.router.url);
    const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path);
    urlWithoutParams.forEach((element: any) => {
      let el = this.panelService.getBreadcrumbsPage(element);
      if (el.name !== '') {
        this.itemsBreadCrumb.push(el);
        if (el.extra !== undefined && el.extra.length > 0) {
          this.itemsBreadCrumb.push(el.extra[0]);
        }
      }
    });
  }

  goPage(url: any, params: any) {
    if (url === '') return;
    if (params) {
      this.router.navigate([url], { queryParams: { [params.name]: params.value } });
      return;
    }
    this.router.navigate([url]);
  }

}
