import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from 'src/app/services/invitation.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { AuthenticationService } from '../../../services/authentication.service';

const bcrypt = require('bcryptjs');

@Component({
  selector: 'app-activation-control',
  templateUrl: './activation-control.component.html',
  styleUrls: ['./activation-control.component.scss']
})
export class ActivationControlComponent {

  key!: any;
  manager!: any;
  payload: any;

  activationForm: FormGroup;

  hide: boolean = true;
  hideConfirm: boolean = true;
  salt = bcrypt.genSaltSync(10);

  numerosValidate: boolean = false;
  titlecaseValidate: boolean = false;
  lowerValidate: boolean = false;

  validateUser: boolean = false;
  errorValidateUser: string = '';
  titleConfirm!: string;
  loaded: boolean = false;

  constructor(private formBuilder: FormBuilder, private invitationService: InvitationService, private activatedRouter: ActivatedRoute,
    private dialog: MatDialog, private router: Router, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe({
      next: (params) => {
        this.key = params['key'];
        this.manager = params['man'];

        this.invitationService.verifyInvite(this.key, this.manager, {}).subscribe({
          next: (data) => {
            if (data.code == 200) {
              this.validateUser = true;
              this.titleConfirm = data.message;
              this.manager = data;
            }
            this.loaded = true;
          },
          error: (res) => {
            this.loaded = true;
            this.errorValidateUser = res.error.error;
          }
        });
      }
    });

    this.activationForm = this.formBuilder.group({
      textPassword: ['', Validators.compose([
        Validators.required,
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
        Validators.maxLength(30)
      ])],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
      condaccept: [false, [Validators.required, Validators.requiredTrue]],
      newsletter: [false]
    }, {
      validators: this.password.bind(this)
    });
  }

  // Validacion contraseña
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.controls['textPassword'];
    const { value: confirmPassword } = formGroup.controls['confirmPassword'];
    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordNotMatch: true };
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = regex.test(control.value);
      if (valid) {
        return null;
      } else {
        return error;
      }
    };
  }

  get pass() { return this.activationForm.get('textPassword'); }
  get repPass() { return this.activationForm.get('confirmPassword'); }

  completeInvite() {
    if (this.activationForm.invalid) {
      this.openInfoDialog('Por favor, rellene todos los campos correctamente', true);
      return;
    }
    var password = this.activationForm.controls['textPassword'].value;
    var hash = bcrypt.hashSync(password, this.salt);

    const json = this.activationForm.value;
    const registerJson = {
      password: hash,
      newsletter: json.newsletter
    };
    this.invitationService.verifyInvite(this.key, this.manager, registerJson).subscribe({
      next: (data) => {
        localStorage.setItem('jwt_token', data.token);
        localStorage.setItem('refresh', data.refresh_token);
        this.openInfoDialog('Usuario creado correctamente', false, true);
        }, error: (err) => {
          this.openInfoDialog('¡Vaya! Algo ha fallado al crear la cuenta, por favor, vuelve a intentarlo más tarde.', true);
        }
    });
  }

  openInfoDialog(msg: any, icon: boolean, wait = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
    if(wait) {
      dialogRef.afterClosed().subscribe({
        next: () => {
          this.router.navigate(['/panel-control/orders/list']);
        }
      });
    }
  }

}

