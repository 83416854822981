<div *ngIf="admin; else divPanel">
  <app-home-admin></app-home-admin>
</div>
<ng-template #divPanel>
  <div *ngIf="panelAdmin; else divTemplate">
    <app-dashboard-panel></app-dashboard-panel>
  </div>
  <ng-template #divTemplate>
    <app-header *ngIf="!checkout"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!checkout"></app-footer>
  </ng-template>
</ng-template>