<div class="event-menu-container">
    <div class="item-submenu" (click)="changeTabEvent({tab: 1, name: 'Información del evento', slug: 'info-event'})" [routerLink]="[urlPath+'/info-event'+urlParam]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Información del evento</span>
    </div>
    <div class="item-submenu" (click)="changeTabEvent({tab: 2, name: 'Venta de entradas', slug: 'ticket-event'})" [routerLink]="[urlPath+'/ticket-event'+urlParam]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Venta de entradas</span>
    </div>
    <!-- <div class="item-submenu" (click)="changeTabEvent({tab: 3, name: 'Descuentos/abonos', slug: 'discount-event'})" [routerLink]="[urlPath+'/discount-event'+urlParam]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Descuentos/abonos</span>
    </div> -->
    <div class="item-submenu" (click)="changeTabEvent({tab: 3, name: 'Mapa de butacas', slug: 'map-event'})" [routerLink]="[urlPath+'/map-event'+urlParam]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <span>Aforo/Mapa de butacas</span>
    </div>
</div>