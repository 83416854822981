import { Component } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';

@Component({
  selector: 'app-header-totem',
  templateUrl: './header-totem.component.html',
  styleUrls: ['./header-totem.component.scss']
})
export class HeaderTotemComponent {

  numTicketsCart: number = 0;
  constructor(private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
    this.shoppingCartService.updateCartObservable.subscribe({
      next: (res) => {
        this.shoppingCartService.getCartUser().subscribe({
          next: (value) => {
            this.numTicketsCart = value.cart.tickets.length;
          }
        });
      }
    });
  }
}
