import { TermsConditionsComponent } from './components/shared/static-pages/terms-conditions/terms-conditions.component';
import { SuggestionsComponent } from './components/shared/static-pages/suggestions/suggestions.component';
import { ContactFormComponent } from './components/shared/static-pages/contact-form/contact-form.component';
import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Guards
 */
import { AuthGuard } from './guards/auth.guard';

/**
 * Components
 */
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/shared/login/login.component';
import { EventComponent } from './components/event/event.component';
import { SearchComponent } from './components/search/search.component';
import { CategoryPageComponent } from './components/categories/category-page/category-page.component';
import { NotFoundPageComponent } from './components/shared/not-found-page/not-found-page.component';
import { PrivacyComponent } from './components/shared/static-pages/privacy/privacy.component';
import { PageOwnerComponent } from './components/page-owner/page-owner.component';
import { HomeAdminComponent } from './components/admin/shared/home-admin/home-admin.component';
import { CategoriasComponent } from './components/admin/categorias/categorias.component';
import { EventosComponent } from './components/admin/eventos/eventos.component';
import { UsuariosComponent } from './components/admin/usuarios/usuarios.component';
import { NewEventoComponent } from './components/admin/eventos/new-evento/new-evento.component';
import { EditCategoriaComponent } from './components/admin/categorias/edit-categoria/edit-categoria.component';
import { NewUserComponent } from './components/admin/usuarios/new-user/new-user.component';
import { FaqsComponent } from './components/shared/static-pages/faqs/faqs.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CityblogComponent } from './components/blog/cityblog/cityblog.component';
import { DetailPodsComponent } from './components/blog/detail-pods/detail-pods.component';
import { RegisterComponent } from './components/shared/register/register.component';
import { ConfigMenuComponent } from './components/admin/config/config-menu/config-menu.component';
import { ConfigBannerComponent } from './components/admin/config/config-banner/config-banner.component';
import { SelectionComponent } from './components/admin/config/selection/selection.component';
import { NewBannerComponent } from './components/admin/config/config-banner/new-banner/new-banner.component';
import { AddCategoriaComponent } from './components/admin/config/selection/add-categoria/add-categoria.component';
import { AddBannerComponent } from './components/admin/config/selection/add-banner/add-banner.component';
import { IsuserGuard } from './guards/isuser.guard';
import { ViewUserComponent } from "./components/admin/usuarios/view-user/view-user.component"
import { AdminGuard } from './guards/admin.guard';
import { CheckoutGuard } from './guards/checkout.guard';
import { CheckoutPageComponent } from './components/checkout/checkout-page/checkout-page.component';
import { WaitingPayComponent } from './components/checkout/waiting-pay/waiting-pay.component';
import { ConfirmPayComponent } from './components/checkout/confirm-pay/confirm-pay.component';
import { ProfileComponent } from './components/profile/profile/profile.component';
import { FavoritesComponent } from './components/profile/favorites/favorites.component';
import { TicketsComponent } from './components/new-lok/tickets/tickets.component';
import { EditProfileComponent } from './components/profile/config/edit-profile/edit-profile.component';
import { InteresesComponent } from './components/profile/config/intereses/intereses.component';
import { NotificationsComponent } from './components/profile/config/notifications/notifications.component';
import { EditLokComponent } from './components/edit-lok/edit-lok.component';
import { OwnerGuard } from './guards/owner.guard';
import { LegalComponent } from './components/shared/static-pages/legal/legal.component';
import { CookiesTermsComponent } from './components/shared/static-pages/cookies-terms/cookies-terms.component';
import { GenerateCanvasComponent } from './components/seat-map/generate-canvas/generate-canvas.component';
import { CreateMapComponent } from './components/seat-map/create-map/create-map.component';
import { CartCheckoutComponent } from './components/profile/cart-checkout/cart-checkout.component';
import { DashboardPanelComponent } from './components/control-panel/dashboard-panel/dashboard-panel.component';
import { DetailEventPanelComponent } from './components/panel-admin/detail-event-panel/detail-event-panel.component';
import { OrdersPanelComponent } from './components/panel-admin/orders-panel/orders-panel.component';
import { DetailOrderPanelComponent } from './components/panel-admin/detail-order-panel/detail-order-panel.component';
import { PanelGuard } from './guards/panel.guard';
import { NewPasswordComponent } from './components/shared/new-password/new-password.component';
import { EventsPanelComponent } from './components/control-panel/events/events-panel/events-panel.component';
import { RouterEventsComponent } from './components/control-panel/events/router-events/router-events.component';
import { BondsComponent } from './components/control-panel/bonds/bonds.component';
import { DraftEventsComponent } from './components/control-panel/events/draft-events/draft-events.component';
import { InfoEventComponent } from './components/control-panel/events/detail-event/info-event/info-event.component';
import { TicketsTypeComponent } from './components/control-panel/events/detail-event/tickets-type/tickets-type.component';
import { CommentsEventComponent } from './components/control-panel/events/detail-event/comments-event/comments-event.component';
import { MailsEventComponent } from './components/control-panel/events/detail-event/mails-event/mails-event.component';
import { ReportsEventComponent } from './components/control-panel/events/detail-event/reports-event/reports-event.component';
import { OrdersEventComponent } from './components/control-panel/events/detail-event/orders-event/orders-event.component';
import { MapPanelComponent } from './components/control-panel/events/detail-event/map-panel/map-panel.component';
import { DiscountPassComponent } from './components/control-panel/events/detail-event/discount-pass/discount-pass.component';
import { DetailOrderComponent } from './components/control-panel/orders/detail-order/detail-order.component';
import { OrdersListComponent } from './components/control-panel/orders/orders-list/orders-list.component';
import { ConfigPanelComponent } from './components/control-panel/config/config-panel/config-panel.component';
import { RouterPermissionComponent } from './components/control-panel/config/permission/router-permission/router-permission.component';
import { UserPermissionComponent } from './components/control-panel/config/permission/user-permission/user-permission.component';
import { SubscribersListComponent } from './components/control-panel/subscribers/subscribers-list/subscribers-list.component';
import { NewsletterComponent } from './components/control-panel/subscribers/newsletter/newsletter.component';
import { SubscribersNotificationsComponent } from './components/control-panel/subscribers/subscribers-notifications/subscribers-notifications.component';
import { RouterBusinessComponent } from './components/control-panel/config/business/router-business/router-business.component';
import { InfoProfileComponent } from './components/control-panel/config/info-profile/info-profile.component';
import { RouterVenueComponent } from './components/control-panel/config/venues/detail-venue/router-venue/router-venue.component';
import { VenuesListComponent } from './components/control-panel/config/venues/venues-list/venues-list.component';
import { InfoVenueComponent } from './components/control-panel/config/venues/detail-venue/info-venue/info-venue.component';
import { TicketsVenueComponent } from './components/control-panel/config/venues/detail-venue/tickets-venue/tickets-venue.component';
import { DiscountsVenueComponent } from './components/control-panel/config/venues/detail-venue/discounts-venue/discounts-venue.component';
import { MapVenueComponent } from './components/control-panel/config/venues/detail-venue/map-venue/map-venue.component';
import { EventsVenueComponent } from './components/control-panel/config/venues/detail-venue/events-venue/events-venue.component';
import { UserPurchasesComponent } from './components/control-panel/subscribers/user-purchases/user-purchases.component';
import { DocsVenueComponent } from './components/control-panel/config/venues/detail-venue/docs-venue/docs-venue.component';
import { ActivationControlComponent } from './components/control-panel/activation-control/activation-control.component';
import { FunctionsGuard } from './guards/functions.guard';
import { OrderEventComponent } from './components/control-panel/events/detail-event/order-event/order-event.component';
import { StatusPayComponent } from './components/checkout/status-pay/status-pay.component';
import { SubscriptionsComponent } from './components/profile/config/subscriptions/subscriptions.component';
import { CreateEventComponent } from './components/control-panel/events/create-event/create-event.component';
import { DiscountsCreateComponent } from './components/control-panel/events/components-create/discounts-create/discounts-create.component';
import { TicketsCreateComponent } from './components/control-panel/events/components-create/tickets-create/tickets-create.component';
import { MapSeatsCreateComponent } from './components/control-panel/events/components-create/map-seats-create/map-seats-create.component';
import { PaymentSuccessComponent } from './components/checkout/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/checkout/payment-error/payment-error.component';
import { RouterCreateComponent } from './components/control-panel/events/components-create/router-create/router-create.component';
import { CreateVenueComponent } from './components/control-panel/config/venues/create-venue/create-venue.component';
import { SuperAdminGuard } from './guards/superadmin.guard';
import { BusinessComponent } from './components/control-panel/business/business.component';
import { TicketsProfileComponent } from './components/profile/config/tickets-profile/tickets-profile.component';
import { ReportsPageComponent } from './components/control-panel/reports-page/reports-page.component';

const pathDetailEvent = [
  {
    path: 'info-event/:idEvent',
    component: InfoEventComponent,
    title: 'Citylok - Información del Evento'
  },
  {
    path: 'ticket-event/:idEvent',
    component: TicketsTypeComponent,
    title: 'Citylok - Venta de entradas'
  },
  {
    path: 'discount-event/:idEvent',
    component: DiscountPassComponent,
    title: 'Citylok - Descuentos y Abonos'
  },
  {
    path: 'map-event/:idEvent',
    component: MapPanelComponent,
    title: 'Citylok - Mapa de butacas'
  },
  {
    path: 'order-event/:idEvent',
    component: OrdersEventComponent,
    title: 'Citylok - Pedidos del evento'
  },
  {
    path: 'order-event/:idEvent',
    children: [
      {
        path: 'order/:idOrder',
        component: OrderEventComponent,
        title: 'Citylok - Detalle pedido'
      }
    ]
  },
  {
    path: 'report-event/:idEvent',
    component: ReportsEventComponent,
    title: 'Citylok - Informes del evento'
  },
  {
    path: 'mailing-event/:idEvent',
    component: MailsEventComponent,
    title: 'Citylok - Envío de comunicaciones'
  },
  {
    path: 'comment-event/:idEvent',
    component: CommentsEventComponent,
    title: 'Citylok - Gestión de comentarios'
  },
];

const routes: Routes = [
  { path: '', component: HomeComponent, title: 'Citylok - Inicio' },
  { path: 'inicio', component: HomeComponent, title: 'Citylok - Inicio' },
  // { path: 'create-map', component: CreateMapComponent, title: 'Citylok - Crear Mapa' },
  // { path: 'create-map/:newLok', component: CreateMapComponent, title: 'Citylok - Crear Mapa' },
  { path: 'edit-map/:idMap', component: CreateMapComponent, title: 'Citylok - Editar Mapa', canActivate: [PanelGuard] },
  // { path: 'edit-event/map/:idMap', component: CreateMapComponent, title: 'Citylok - Editar Mapa', canActivate: [PanelGuard] },
  // { path: 'edit-event/map/:idMap/:idEvent', component: CreateMapComponent, title: 'Citylok - Editar Mapa', canActivate: [PanelGuard] },

  // Sign
  { path: 'login', component: LoginComponent, title: 'Citylok - Login', canActivate: [IsuserGuard] },
  { path: 'signup', component: RegisterComponent, title: 'Citylok - Signup', canActivate: [IsuserGuard] },
  { path: 'register/business', component: RegisterComponent, title: 'Citylok - Business' },

  // Static page
  { path: 'privacy', component: PrivacyComponent, title: 'Citylok - Política de Privacidad' },
  { path: 'contact', component: ContactFormComponent, title: 'Citylok - Contacto' },
  { path: 'suggestions', component: SuggestionsComponent, title: 'Citylok - Sugerencias' },
  { path: 'terms-conditions', component: TermsConditionsComponent, title: 'Citylok - Términos y condiciones' },
  { path: 'faqs', component: FaqsComponent, title: 'Citylok - FAQs' },
  { path: 'about-us', component: AboutUsComponent, title: 'Citylok - ¿Quienes somos?' },
  { path: 'cookies', component: CookiesTermsComponent, title: 'Citylok - Política de cookies' },
  { path: 'legal', component: LegalComponent, title: 'Citylok - Aviso legal' },

  // Blog
  { path: 'cityblog', component: CityblogComponent, title: 'Cityblog' },
  { path: 'cityblog/:slug', component: DetailPodsComponent, title: 'Cityblog' },

  // Eventos
  { path: 'category/:nameCategory', component: CategoryPageComponent },
  { path: 'results', component: SearchComponent, title: 'Citylok - Resultados' },
  { path: 'teatroideal', component: PageOwnerComponent, title: 'Citylok - Teatro ideal' },
  { path: 'teatroideal/:date/:shortUrl', component: EventComponent },
  // { path: 'events/:idOwner/:ownerEvent', component: PageOwnerComponent },
  { path: 'events/:pathUrl', component: PageOwnerComponent },
  { path: 'venue/:pathUrl', component: PageOwnerComponent },
  { path: 'event/:slugEvent', component: EventComponent, title: 'Citylok - Eventos' },
  { path: 'draft/event/:slugEvent', component: EventComponent, title: 'Citylok - Eventos' },
  { path: 'event/edit/:slugEvent', component: EditLokComponent, title: 'Citylok - Editar Evento', canActivate: [AuthGuard] },
  { path: 'draft/edit/:slugEvent', component: EditLokComponent, title: 'Citylok - Editar Borrador', canActivate: [AuthGuard] },
  { path: 'event/:slugEvent/:comments', component: EventComponent, title: 'Citylok - Eventos' },

  // Checkout
  { path: 'cart-checkout', component: CartCheckoutComponent, title: 'Citylok - Cart Checkout', canActivate: [AuthGuard] },
  { path: 'checkout', component: CheckoutPageComponent, title: 'Citylok - Checkout', canActivate: [CheckoutGuard] },
  {
    path: 'checkout',
    children: [
      {
        path: 'validate',
        canActivate: [CheckoutGuard],
        component: CheckoutPageComponent
      },
      {
        path: 'payment',
        canActivate: [CheckoutGuard, AuthGuard],
        component: CheckoutPageComponent
      },
      {
        path: 'pay/success',
        canActivate: [AuthGuard],
        component: PaymentSuccessComponent
      },
      {
        path: 'pay/failed',
        canActivate: [AuthGuard],
        component: PaymentErrorComponent
      }
    ]
  },

  // User
  { path: 'profile', component: ProfileComponent, title: 'Citylok - Perfil', canActivate: [AuthGuard] },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'favorites',
        component: FavoritesComponent,
        title: 'Citylok - Favoritos'
      },
      {
        path: 'edit',
        component: EditProfileComponent,
        title: 'Citylok - Editar perfil'
      },
      {
        path: 'preferences',
        component: InteresesComponent,
        title: 'Citylok - Preferencias'
      },
      {
        path: 'seat-maps',
        component: ProfileComponent,
        title: 'Citylok - Perfil - Mapas'
      },
      {
        path: 'drafts',
        component: ProfileComponent,
        title: 'Citylok - Perfil - Borradores'
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        title: 'Citylok - Preferencias'
      },
      {
        path: 'purchases',
        component: TicketsProfileComponent,
        title: 'Citylok - Mis compras'
      }
    ]
  },
  // Panel Admin
  { path: 'panel', component: EventsPanelComponent, title: 'Citylok - Panel de Control', canActivate: [PanelGuard] },
  {
    path: 'panel',
    canActivate: [PanelGuard],
    children: [
      {
        path: 'events',
        component: EventsPanelComponent,
        title: 'Citylok - Eventos'
      },
      {
        path: 'detail-event/:idEvent',
        component: DetailEventPanelComponent,
        title: 'Citylok - Detalle Evento'
      },
      {
        path: 'orders',
        component: OrdersPanelComponent,
        title: 'Citylok - Panel Pedidos'
      },
      {
        path: 'detail-order/:idOrder',
        component: DetailOrderPanelComponent,
        title: 'Citylok - Detalle Pedido'
      },
    ]
  },

  // Panel de Control
  { path: 'panel-control', component: EventsPanelComponent, title: 'Citylok - Panel de Control', canActivate: [AuthGuard], data: { accessRoles: ["ROLE_GESTOR"] } },
  {
    path: 'panel-control',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list-business',
        component: BusinessComponent,
        title: 'Citylok - Empresas',
        canActivate: [SuperAdminGuard, AuthGuard]
      },
      {
        path: 'events',
        component: EventsPanelComponent,
        title: 'Citylok - Eventos',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN", "ACCESS_MANAGER", "CREATE_EVENTS", "ACCESS_REPORTS", "ACCESS_SALES", "ACCESS_MARKETING", "ACCESS_SUPPORT", "ACCESS_QR_APP"]
        }
      },
      {
        path: 'events',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: EventsPanelComponent,
            title: 'Citylok - Información del Evento'
          },
          {
            path: 'draft',
            component: DraftEventsComponent,
            title: 'Citylok - Borradores',
            canActivate: [FunctionsGuard, AuthGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
            },
          },
          {
            path: 'bonds',
            component: BondsComponent,
            title: 'Citylok - Abonos',
            canActivate: [FunctionsGuard, AuthGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
            },
          },
          // Unicamente lo verán los creadores de eventos
          {
            path: 'draft/detail',
            canActivate: [FunctionsGuard, AuthGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
            },
            children: pathDetailEvent
          },
        ]
      },
      {
        path: 'events/detail',
        canActivate: [AuthGuard],
        children: pathDetailEvent
      },
      {
        path: 'events/create',
        canActivate: [AuthGuard],
        component: CreateEventComponent,
      },
      {
        path: 'events/new-event',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
        },
        children: [
          {
            path: ':step',
            component: RouterCreateComponent,
            title: 'Citylok - Creación de Evento',
          },
          {
            path: ':step',
            children: [{
              path: ':idEvent',
              component: RouterCreateComponent,
              title: 'Citylok - Creación de Evento',
            }]
          },
        ]
      },
      {
        path: 'reports',
        component: ReportsPageComponent,
        title: 'Citylok - Informes',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS", "ACCESS_REPORTS", "ACCESS_SALES"]
        }
      },
      {
        path: 'orders',
        component: OrdersListComponent,
        title: 'Citylok - Pedidos',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS", "ACCESS_REPORTS", "ACCESS_SALES", "ACCESS_SUPPORT"]
        }
      },
      {
        path: 'orders',
        children: [
          {
            path: 'list',
            component: OrdersListComponent,
            title: 'Citylok - Listado de pedidos'
          },
          {
            path: 'billing',
            component: DetailOrderComponent,
            title: 'Citylok - Facturación'
          }
        ]
      },
      {
        path: 'orders/detail',
        children: [
          {
            path: 'info-order',
            component: DetailOrderComponent,
            title: 'Citylok - Información del pedido'
          },
        ]
      },
      {
        path: 'subscribers',
        component: UserPurchasesComponent,
        title: 'Citylok - Listado de usuarios',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "ACCESS_MARKETING", "ACCESS_SUPPORT"]
        }
      },
      {
        path: 'subscribers',
        children: [
          {
            path: 'list-users',
            component: UserPurchasesComponent,
            title: 'Citylok - Listado de usuarios'
          },
          {
            path: 'list-subscribers',
            component: SubscribersListComponent,
            title: 'Citylok - Listado de socios'
          },
          {
            path: 'comments',
            component: NewsletterComponent,
            title: 'Citylok - Comentarios'
          },
          {
            path: 'newsletter',
            component: NewsletterComponent,
            title: 'Citylok - Newsletter'
          },
          {
            path: 'notifications',
            component: SubscribersNotificationsComponent,
            title: 'Citylok - Notificaciones'
          },
        ]
      },
      {
        path: 'config',
        component: ConfigPanelComponent,
        title: 'Citylok - Configuración',
        // canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN", "ACCESS_MANAGER", "CREATE_EVENTS", "ACCESS_REPORTS", "ACCESS_SALES", "ACCESS_MARKETING", "ACCESS_SUPPORT", "ACCESS_QR_APP"]
        }
      },
      {
        path: 'config',
        children: [
          {
            path: 'venues',
            component: VenuesListComponent,
            title: 'Citylok - Espacios',
            canActivate: [FunctionsGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
            }
          },
          {
            path: 'create-venue',
            component: CreateVenueComponent,
            title: 'Citylok - Crear Espacio',
            canActivate: [FunctionsGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
            }
          },
          {
            path: 'map-venue',
            component: CreateMapComponent,
            title: 'Citylok - Crear Mapa de butacas',
            canActivate: [FunctionsGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN", "CREATE_EVENTS"]
            }
          },
          {
            path: 'business',
            component: RouterBusinessComponent,
            title: 'Citylok - Organización',
            canActivate: [FunctionsGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN"]
            }
          },
          {
            path: 'permission',
            component: RouterPermissionComponent,
            title: 'Citylok - Permisos y gestores',
            canActivate: [FunctionsGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN"]
            }
          },
          {
            path: 'info-profile',
            component: InfoProfileComponent,
            title: 'Citylok - Perfil de usuario',
            // canActivate: [FunctionsGuard],
            data: {
              accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN", "ACCESS_MANAGER", "CREATE_EVENTS", "ACCESS_REPORTS", "ACCESS_SALES", "ACCESS_MARKETING", "ACCESS_SUPPORT", "ACCESS_QR_APP"]
            }
          },
        ]
      },
      {
        path: 'config/venues',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_ADMIN"]
        },
        children: [
          {
            path: 'info-venue/:idVenue',
            component: InfoVenueComponent,
            title: 'Citylok - Permisos y gestores'
          },
          {
            path: 'tickets-venue/:idVenue',
            component: TicketsVenueComponent,
            title: 'Citylok - Permisos y gestores'
          },
          {
            path: 'discounts-venue/:idVenue',
            component: DiscountsVenueComponent,
            title: 'Citylok - Permisos y gestores'
          },
          {
            path: 'map-venue/:idVenue',
            component: MapVenueComponent,
            title: 'Citylok - Permisos y gestores'
          },
          {
            path: 'event-venue/:idVenue',
            component: EventsVenueComponent,
            title: 'Citylok - Permisos y gestores'
          },
          {
            path: 'docs-venue/:idVenue',
            component: DocsVenueComponent,
            title: 'Citylok - Documentos'
          }
        ]
      },
      {
        path: 'config/permission',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN"]
        },
        children: [
          {
            path: 'function',
            component: RouterPermissionComponent,
            title: 'Citylok - Permisos y gestores'
          },
          {
            path: 'function/user/:idUser',
            component: UserPermissionComponent,
            title: 'Citylok - Permisos y gestores'
          }
        ]
      },
      {
        path: 'config/business',
        canActivate: [FunctionsGuard, AuthGuard],
        data: {
          accessRoles: ["ACCESS_CLOUD", "ACCOUNT_OWNER", "ACCESS_EXTERN_EVENTS", "ACCESS_ADMIN"]
        },
        children: [
          {
            path: ':typePage',
            component: RouterBusinessComponent,
            title: 'Citylok - Organización'
          },
        ]
      },
    ]
  },
  { path: 'activation_control', component: ActivationControlComponent },


  { path: 'reset/password', component: NewPasswordComponent },
  { path: '**', component: NotFoundPageComponent, title: 'Not Found' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
