import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(private common: CommonService, private http: HttpClient) { }

  getBanners(page: number = null, items: number = null,sorted: string = null,search: String = '') {
    // var urlFilter = '';
    // if(search != null) {urlFilter+= `titulo=${ search }`}
    // if(sorted != null) {urlFilter += `&${sorted}`}      //?${urlFilter}&items=${items}&page=${page}
    const url = `${ AppSettings.API_URI }/banners`;
    return this.common.getData(url);
  }

  getBannnerById(id:number) {
    const url = `${ AppSettings.API_URI }/banners/${id}`;
    return this.common.getData(url);
  }

  putBanner(bannerId: number, banner:any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/banners/${ bannerId }`;
    return this.http.put<any>(url, banner ,formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(err);
      })
    );
  }

  postBanner(banner) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/banners`;
    return this.http.post<any>(url, banner, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(err);
      })
    );
  }

  deleteBanner(bannerId: number) {
    const url = `${ AppSettings.API_URI }/banners/${ bannerId }`;
    return this.http.delete<any>( url ).pipe(
      map(data => {
        if(data) {
          return of(true);
        } else {
          return of(false);
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }

  uploadFilePut(bannerImage) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/banner_image`;
    return this.http.put<any>(url, bannerImage, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }
  uploadFilePost(bannerImage) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/banner_image`;
    return this.http.post<any>(url, bannerImage, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

}
