import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ContactPanelComponent } from '../../contact-panel/contact-panel.component';

@Component({
  selector: 'app-help-business',
  templateUrl: './help-business.component.html',
  styleUrls: ['./help-business.component.scss']
})
export class HelpBusinessComponent {

  user!: any;

  constructor(private dialog: MatDialog, private auth: AuthenticationService) { }

  ngAfterViewInit() {
    this.user = this.auth.getProfile();
  }

  openContactBusiness() {
    const dialogRef = this.dialog.open(ContactPanelComponent, {
      panelClass: 'info-dialog',
      data: {
        email: this.user.email
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res?.code == '200') {
          this.openInfoCard('Gracias por contactar con nosotros. En un plazo máximo de 48 horas recibirás una respuesta.', false, false);
        }
        if (res == 'error') {
          this.openInfoCard('¡Vaya! No se ha podido mandar el mensaje. Por favor, inténtalo de nuevo más tarde.');
        }
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }
}
