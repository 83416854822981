import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-etiquetas',
  templateUrl: './etiquetas.component.html',
  styleUrls: ['./etiquetas.component.scss']
})
export class EtiquetasComponent implements OnInit {

  @Input() title: string = 'Hashtags';
  @Input() icon: string = 'fa-hashtag';
  @Input() items: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
