<div class="default-calendar-dates">
    <div class="arrows-navigation">
        <span class="pointer-in"><i class="fa-light fa-chevron-left" (click)="prevMonth()"></i></span>
        <p class="text-center m-0">{{ monthString | titlecase }}</p>
        <span class="pointer-in text-end"><i class="fa-light fa-chevron-right" (click)="nextMonth()"></i></span>
    </div>
    <ngb-datepicker
        class="custom-datepicker-event"
        #dp
        [displayMonths]="1"
        [dayTemplate]="t"
        outsideDays="hidden"
        (dateSelect)="changeDateSelected($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        navigation="none"
        [startDate]="selectedDate">

        <ng-template #t let-date let-focused="focused">
            <span class="custom-day-event"
                    [class.focused]="hoveredDate != null"
                    [class.select-day]="date.equals(selectedDate)"
                    [class.disabled]="date.before(minDate) || date.after(maxDate)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                <span class="inside-day-event" [class.weekend]="isWeekend(date)">{{ date.day }}</span>
            </span>
        </ng-template>
    </ngb-datepicker>
</div>