import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent implements OnInit {

  @Input() profile!: any;
  @Input() event!: any;
  @Input() levels!: any;

  displayedColumns: string[] = ['level', 'price', 'sales'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.dataSource = this.levels;
  }

}
