<div class="content-comments">
    <p class="title-section"><i class="fa-light fa-comment"></i>Comentarios <span *ngIf="comentarios?.totalItems > 0">({{ comentarios?.totalItems }})</span></p>
    <div class="card-comments">
      <div id="comment-{{i+1}}" class="comment-content" [class.appear-animation]="newValue == comment.id" *ngFor="let comment of comentarios?.comments; let i = index">
        <span class="mb-0" style="margin-right: 5px;">
          <span class="user-comment">{{ comment.usuario }}</span>
          {{ comment.comentario }}
        </span>
        <span class="btn-reply" (click)="writeReply(comment.id)">{{ replyComment == comment.id ? '' : 'Responder' }}</span>
        <div class="content-resp" [class.appear-animation]="newValue == resp.id" *ngFor="let resp of comment.respuestas; let j = index">
          <span class="user-comment">{{ resp.usuario }}</span>
          <span>{{ resp.comentario }}</span>
        </div>
        <div id="resp-{{comment.id}}">
          <div *ngIf="replyComment == comment.id" class="d-grid">
            <div class="card-reply">
              <div class="container-form d-flex align-items-center">
                <textarea #textarea class="w-100" type="textarea" placeholder="Escribe una respuesta" maxlength="2500"></textarea>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn-send-comment btn-cancel" (click)="writeReply(comment.id)">Cancelar</button>
              <button class="btn-send-comment" (click)="sendComment(textarea.value)">Enviar</button>
            </div>    
          </div>   
        </div>     
      </div>
      <div id="newComment">
        <div *ngIf="replyComment == 0">
          <div class="card-reply m-0">
            <div class="container-form d-flex align-items-center">
              <textarea #textarea class="w-100" type="textarea" placeholder="Escribe una respuesta" maxlength="2500"></textarea>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn-send-comment btn-cancel" (click)="writeReply(0)">Cancelar</button>
            <button class="btn-send-comment" (click)="sendComment(textarea.value)">Enviar</button>
          </div>
        </div>
      </div>
      <div *ngIf="comentarios?.totalItems > 0; else divFirstComment" class="d-grid d-sm-flex justify-content-sm-between justify-content-center content-btns">
        <a *ngIf="comentarios?.totalItems > comentarios?.comments.length" (click)="getMoreComments()" class="link-comments pointer-in">Cargar más comentarios</a>
        <a *ngIf="comentarios?.totalItems > 3 && comentarios?.comments.length == comentarios?.totalItems && replyComment != 0" (click)="closeComments()" class="link-comments pointer-in text-center">Cerrar</a>
        <a class="link-comments pointer-in" *ngIf="comentarios?.totalItems > 0 && replyComment != 0 && profile" (click)="writeReply(0)"><i class="fa-light fa-circle-plus fa-sm" style="margin-right: 5px;"></i>Añadir comentario</a>
      </div>
      <ng-template #divFirstComment>
        <div class="content-resp text-center" *ngIf="replyComment == null">
          ¡Se el primero en comentar!
          <a class="link-comments pointer-in" (click)="writeReply(0)">Añadir comentario</a>
        </div>
      </ng-template>
    </div>
  </div>