<div class="router-business">
    <div class="d-flex info-panel">
        <app-menu-business [tabSelected]="tabSelected"></app-menu-business>
        <div class="business-panel-container page-panel">
            <div class="card-white-panel">
                <div class="d-flex title-menu-page">
                    <h2><span>Organización</span>
                        <mat-divider [vertical]="true"></mat-divider>
                        <span>{{titleTab}}</span>
                    </h2>
                </div>
                <mat-divider></mat-divider>
                <div>
                    <app-info-business [business]="business" [description]="description" [idBusiness]="businessSelect?.id" [owner]="owner" *ngIf="tabSelected === 0"></app-info-business>
                    <!-- <app-info-business *ngIf="tabSelected === 1"></app-info-business> -->
                    <app-help-business *ngIf="tabSelected === 2"></app-help-business>
                </div>
            </div>
        </div>
    </div>
</div>