import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PermissionService } from 'src/app/services/permission.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.scss']
})
export class UserPermissionComponent implements OnInit {

  user!: any;
  now: Date = new Date();
  functions: Observable<any> = new Observable();

  openPermission: boolean = true;
  permissionExtra: any[] = [];

  userPermission: Observable<any> = new Observable();
  companyInfo: Observable<any> = new Observable();
  idPermission: number = 0;
  infoUserPermission!: any;

  placesUser: any[] = [];
  rolesUser: any[] = [];
  allPlaces: boolean = false;
  profile = this.auth.getProfile();
  rolesProfile: any[] = this.profile?.roleFunctions.map((role: any) => role.role.code);
  admin: boolean = false;
  business!: any;
  isOwner: boolean = false;

  constructor(private auth: AuthenticationService, private placesService: PlacesService, private permissionService: PermissionService,
    private dialog: MatDialog, private location: Location, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.rolesProfile.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER') {
        this.admin = true;
        return;
      }
    });
  }

  ngAfterViewInit(): void {
    this.user = this.auth.getProfile();
    this.business = JSON.parse(localStorage.getItem('business') || '{}');

    this.companyInfo = this.permissionService.getInfoEnabledCompany(this.business?.id).pipe(map((res) => res.data));

    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idPermission = +params.idUser;
        this.userPermission = this.permissionService.getPermissionUser(this.business?.id, this.idPermission).pipe(map((res) => res.data));

        this.permissionService.getPermissionUser(this.business?.id, this.idPermission).subscribe({
          next: (res) => {
            this.isOwner = res.data.roles.find((permission: any) => permission.role.code == 'ACCOUNT_OWNER');
            this.infoUserPermission = res.data;
            this.placesUser = res.data.places.map((place: any) => place.id);
            this.rolesUser = res.data.roles.map((data: any) => data.role.id);
          }
        });
      }
    });

    this.permissionExtra = []
  }

  changeFunction(role: any, checked: any) {
    this.rolesUser = this.rolesUser.filter((data) => data !== role);
    if (checked) {
      this.rolesUser.push(role);
    }
  }

  changePlace(place: any, checked: any) {
    this.placesUser = this.placesUser.filter((data) => data !== place);
    if (checked) {
      this.placesUser.push(place);
    }
  }

  goBack() {
    this.location.back();
  }

  savePermission() {
    const payload = {
      places: this.placesUser,
      functions: this.rolesUser,
      company: this.business.id,
      allPlaces: this.allPlaces
    };
    this.permissionService.savePermissionUser(this.infoUserPermission.id, payload).subscribe({
      next: (res) => {
        this.openInfoCard('Usuario guardado correctamente');
      }
    });
  }

  openInfoCard(msg: string, disabledClose: boolean = false, close: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
    if (close) {
      dialogRef.afterClosed().subscribe({
        next: (val) => this.goBack()
      });
    }
  }

  deleteGestor() {
    // Añadir confirmación
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres cerrar sesión?',
        btnCancel: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.permissionService.deleteUsersPermission(this.business.id, { users: [this.infoUserPermission?.id] }).subscribe({
          next: (res) => {
            if (res) {
              //  Dialog informativo
              this.openInfoCard('El usuario ha sido eliminado correctamente', true, true);
            }
          },
          error: (err) => {
            this.openInfoCard('Error al eliminar el usuario');
          }
        });
      }
    })
  }

}
