import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings } from './app-settings';
import { AuthenticationService } from './services/authentication.service';
import { ShoppingCartService } from './services/shopping-cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  admin: boolean = false;
  checkout: boolean = false;
  panelAdmin: boolean = false;

  totemActive: boolean = AppSettings.ENV_TOTEM;
  numTicketsCart: number = 0;
  cartTotem: boolean = true;

  constructor(private router: Router, private titleService: Title, private auth: AuthenticationService,
    private shoppingCartService: ShoppingCartService) {
    this.titleService.setTitle('CityLok');
    var viewAdmin = localStorage.getItem('admin');
    var rol = localStorage.getItem('currentRol');
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          var url = event.url;
          this.admin = url.indexOf('/admin') == -1 ? false : true;
          this.checkout = url.indexOf('/checkout/') == -1 ? false : true;
          this.panelAdmin = url.indexOf('/panel-control') == -1 ? false : true;
          this.cartTotem = url.indexOf('/cart-checkout') == -1 ? false : true;
        }
      }
      );
    this.admin = (rol === 'true' && viewAdmin) ? true : false;
    // if(localStorage.getItem('jwt_token')) {
    //   this.auth.getUsersMe().subscribe({
    //     next: result => { if (result.email) { 
    //       this.auth.setProfile(result);
    //     } }
    //   });
    // }
  }

  ngOnInit() {
    if (AppSettings.ENV_TOTEM) {
      if (!localStorage.getItem('jwt_token')) {
        this.auth
          .login(
            'totem_teatroideal@citylok.com',
            'GFfZ1H6uvpcEyQ'
          ).subscribe({
            next: data => {
              if (data.token !== '') {
                this.getCheckUser();
              }
            },
          });
      }
      this.shoppingCartService.updateCartObservable.subscribe({
        next: (res) => {
          this.shoppingCartService.getCartUser().subscribe({
            next: (value) => {
              this.numTicketsCart = value.cart.tickets.length;
            }
          });
        }
      });
    }
    this.router.events.subscribe((event) => {
          if (!(event instanceof NavigationEnd)) {
            return;
          }
          window.scrollTo(0, 0)
        });
      }

  getCheckUser() {
        this.auth.check(false).subscribe(usr => {
          if (!usr || !usr.enabled) {
            localStorage.removeItem('jwt_token');
          }
        })
      }
}
