<div class="card-change-business">
    <div class="head-dialog-change title-dialog-edit d-flex justify-content-between align-items-center">
        <p class="title-change m-0">Evento privado</p>
        <div class="icon-close" mat-dialog-close>
            <i class="fal fa-times"></i>
        </div>
    </div>

    <div class="body-change-lok">
        <p>Introduce el código para ver las opciones disponibles:</p>

        <div class="bloque-change-input container-form d-flex align-items-center password-input">
            <input class="w-100" type="text" placeholder="Código" [(ngModel)]="codeEvent" required>
        </div>
    </div>
    <div class="d-flex justify-content-center btn-change">
        <button class="btn-new-form btn-save w-100" [class.btn-disabled]="!codeEvent" (click)="validateCode()">Aceptar</button>
    </div>
</div>