<div class="card-change-name">
    <p class="title-change">Cambiar nombre</p>

    <div>
        <form [formGroup]="changeForm">
            <div class="bloque-change-password container-form d-flex align-items-center password-input">
                <input class="w-100" type="text" placeholder="Nombre" formControlName="name" required>
                <!-- <i class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i> -->
            </div>

            <div class="d-flex justify-content-center btn-change">
                <button class="primary-button solid w-100" style="min-height: 40px;"
                    (click)=changeName()>Actualizar</button>
            </div>
        </form>
    </div>
</div>