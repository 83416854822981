<div class="new-subscriber">
    <div class="main-new-subscriber">
        <h1 class="title-new-subscriber">Añadir usuario</h1>
        <p class="subtitle-new-subscriber">Ingresa los datos del usuario que deseas añadir, se le enviará un correo para que acepte la invitación.</p>
        <div class="form-info-user">
            <form class="form-grid" [formGroup]="subscriber">
                <div class="m-2">
                    <p class="label-input">Nombre</p>
                    <div class="form-input-container ">
                        <input type="text" class="w-100" formControlName="name">
                    </div>
                </div>
                <div class="m-2">
                    <p class="label-input">Apellidos</p>
                    <div class="form-input-container ">
                        <input type="text" class="w-100" formControlName="lastname">
                    </div>
                </div>
                <div class="m-2">
                    <p class="label-input">Correo electrónico</p>
                    <div class="form-input-container ">
                        <input type="email" class="w-100" formControlName="email">
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="footer-new-subscriber">
        <div class="btn-new btn-create btn-create-border m-0" (click)="cancelNew()">
            <p class="m-0">Cancelar</p>
        </div>
        <div class="btn-new btn-create m-0" (click)="sendInvitation()"
            [class.disabled]="subscriber.invalid">
            <p class="m-0">Aceptar</p>
        </div>
    </div>
</div>