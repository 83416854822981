<div class="float-container d-grid select-btns-totem">
    <div *ngIf="event.soldOut; else divCompleteTemplate">
        <div class="container-btns-select">
            <button class="btn-buy-event disabledBtn">Aforo completo</button>
        </div>
    </div>
    <ng-template #divCompleteTemplate>
        <div *ngIf="event.onlyTpvSales; else divTemplate">
            <div class="container-btns-select">
                <button class="btn-buy-event disabledBtn">Venta en taquilla</button>
            </div>
        </div>
        <!-- El evento oculto no se mostrará en las páginas principales pero si tienes el enlace si que permitirá comprar -->
        <ng-template #divTemplate>
            <!-- <div *ngIf="event?.hiddenEvent else divActive">
          <div class="container-btns-select">
            <button class="btn-buy-event disabledBtn">Venta no disponible</button>
          </div>
        </div>
        <ng-template #divActive> -->
            <div *ngIf="(!event?.informativo || !event.external || (event.external && event.url))">
                <div class="container-btns-select"
                    *ngIf="!event.external && !pastEvent && disabledSales; else divEnabled">
                    <button class="btn-buy-event disabledBtn">Próximamente</button>
                </div>
                <ng-template #divEnabled>
                    <div class="container-btns-select" *ngIf="(!endSales) && !disabledSales; else divFinal">
                        <button class="btn-buy-event pointer-in" *ngIf="!event?.external || event?.url"
                            (click)="openEvent(event?.url)" [class.btn-event-totem]="viewTotem">
                            {{ event?.external ? 'Quiero asistir' : 'Seleccionar entradas' }}</button>
                        <button class="btn-buy-event btn-buy-bono pointer-in" [class.btn-event-totem]="viewTotem"
                            *ngIf="!event?.external && !event.imageurl.includes('http') && enableAbono"
                            (click)="buyAbono()">
                            Comprar Abono</button>
                        <!-- Comprobar si tiene entradas tipo festival -->
                        <button class="btn-buy-event btn-buy-bono pointer-in" *ngIf="!enableAbono && ticketsBondEnabled"
                            (click)="openTicketsBond()" [class.btn-event-totem]="viewTotem">
                            Comprar Abono</button>
                    </div>
                    <ng-template #divFinal>
                        <div class="container-btns-select" *ngIf="endSales">
                            <button class="btn-buy-event" [class.btn-event-totem]="viewTotem" style="cursor: default;">
                                Finalizado</button>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </ng-template>
        <!-- </ng-template> -->
    </ng-template>
</div>