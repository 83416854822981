import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { ReportsService } from 'src/app/services/reports.service';
import { QrCardComponent } from '../../bloques/qr-card/qr-card.component';

@Component({
  selector: 'app-confirm-pay',
  templateUrl: './confirm-pay.component.html',
  styleUrls: ['./confirm-pay.component.scss']
})
export class ConfirmPayComponent implements OnInit {

  idDownload!: any;
  error!: string;
  isAdmin: boolean = false;

  constructor(private dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) public data, private router: Router, private auth: AuthenticationService,
  private panelService: PanelAdminService, private dialogRef: MatDialogRef<ConfirmPayComponent>, private reportService: ReportsService) { }

  ngOnInit(): void {
    this.idDownload = this.data?.idOdoo;
    let profile = this.auth.getProfile();
    this.isAdmin = profile?.roleFunctions?.length > 0;
  }

  openPrint() {
    this.reportService.getUrlsDownload(this.idDownload).subscribe({
      next: (url) => {
        let uriDownload = url['ticket_a4'];
        window.open(uriDownload, '_blank');
        this.dialogRef.close({print: true});
      }, error: (err) => {
        this.error = '¡Vaya! Se ha producido un error al descargar la entrada. Podrá descargarla desde el correo o desde su Perfil.';
      }
    })
  }

  openProfile() {
    // Comprobar si es gestor y mandar a pedidos
    this.dialog.closeAll();
    let url = !this.isAdmin ? '/profile' : '/panel-control/orders/list';
    this.router.navigate([url]);
  }

  goHome() {
    this.dialog.closeAll();
  }

}
