import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';

@Component({
  selector: 'app-detail-event-panel',
  templateUrl: './detail-event-panel.component.html',
  styleUrls: ['./detail-event-panel.component.scss']
})
export class DetailEventPanelComponent implements OnInit {

  @Input() profile!: any;
  event!: any;
  tickets: any[] = [];
  levels: any[] = [];
  discounts: any[] = [];
  abonos: any[] = [];

  index: number = 0;
  loaded: boolean = false;
  loadingEvent: boolean = true;
  mapId!: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private eventosService: EventosService,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe(params => {
      let idEvent: string = params.idEvent;
      this.eventosService.getEventByIdAdmin(idEvent).subscribe({
        next: (data) => {
          if (!data) {
            this.router.navigate(['/panel-control/events/list']);
          }
          this.mapId = data.mapEvent?.id;
          this.event = {
            id: data.id,
            titulo: data.titulo,
            imageurl: data.imageurl,
            fechas: data.fechas?.filter((value) => value.activo),
            descripcion: data.descripcion,
            datesRange: data.datesRange,
            draft: data.draft,
            hourStart: data.hourStart,
            localizacion: data.localizacion,
            ciudad: data.ciudad?.nombre,
            online: data.online
          };
          this.tickets = data.entradas || [];
          this.levels = data.mapEvent?.level_maps || [];

          this.levels.forEach(level => {
            let ticketsLevel = [];
            this.tickets.forEach(element => {
              let ticket = element.priceTickets.find((value: any) =>
                value.level?.id == level.id
              );
              ticketsLevel.push({ idLevel: element.id, name: element.nombre, idPrice: ticket?.id, price: ticket?.price });
              ticketsLevel.sort((a: any, b: any) => {
                return b.price - a.price;
              });
            });
            level.ticketsLevel = ticketsLevel;
            level.openTickets = false;
          });

          this.eventosService.getDiscountsByEvent(idEvent).subscribe({
            next: (res) => {
              if (res) {
                this.discounts = res.filter((value) => !value.abono);
                this.abonos = res.filter((value) => value.abono);
              }
              this.loaded = true;
              this.loadingEvent = false;
            }
          });
        }
      });
    })
  }

  openEditMap() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/edit-event/map', this.mapId, this.event.id]));
    window.open(url, '_blank');
  }

  changeTab(event: any) {
    this.index = event.index;
  }

}
