import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AreasSeatService } from 'src/app/services/areas-seat.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-hidden-seats',
  templateUrl: './hidden-seats.component.html',
  styleUrls: ['./hidden-seats.component.scss']
})
export class HiddenSeatsComponent implements OnInit {

  @Input() hiddenTypes: any[] = [];
  @Input() admin: boolean = false;
  @Output() deleteType = new EventEmitter<any>();
  defaultColors: any[] = [];

  @Input() assignedHidden: any[] = [];
  @Input() selectedSeats: any[] = [];
  @Input() capacity: number = 0;
  @Input() fromCreate!: boolean;
  @Output() changeAssigned = new EventEmitter<any>();
  @Output() typeAssigned = new EventEmitter<any>();

  hiddenEdit!: any;
  hiddenSeats!: any;
  enabledClickBtns: boolean = true;

  constructor(private areasSeatService: AreasSeatService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.defaultColors = this.areasSeatService.getDefaultColorsHidden();
  }

  typeAssignActive(type: any) {
    this.hiddenSeats = type;
    this.enabledClickBtns = false;
    this.typeAssigned.emit(type);
  }

  assignSeats(event: boolean) {
    this.enabledClickBtns = true;
    this.hiddenSeats = null;
    this.changeAssigned.emit(event);
  }

  closeEdit() {
    this.hiddenEdit = null;
  }

  editItem(hidden: any, index: number) {
    this.hiddenEdit = this.hiddenEdit != null ? null : hidden;
  }

  deleteItem(item: any) {
    const verifySeats = this.verifyNotSeats(item);
    if(verifySeats) {
      this.deleteType.emit(item);
    }
  }

  verifyNotSeats(item: any) {
    const verify = !item.seats || (item.seats != null && item.seats <= 0) ? true : false;
    if(!verify) {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: '¡No puedes eliminar ningún tipo de retenidos con asientos asignados!',
          btnCancel: false
        }
      });
    }
    // Verificar también que no tiene entradas
    return verify;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

}
