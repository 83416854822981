import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {

  @Input() localizador!: any;
  
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data) {}

  setBrightness() {
  }

  ngOnInit() {
    this.localizador = this.data?.localizador;
  }

  closeModal() {
  }
}
