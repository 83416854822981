import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeAdminComponent } from './shared/home-admin/home-admin.component';
import { HeaderAdminComponent } from './shared/header-admin/header-admin.component';
import { MaterialModule } from '../../material/material.module';
import { CategoriasComponent } from './categorias/categorias.component';
import { EventosComponent } from './eventos/eventos.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { RouterModule } from '@angular/router';
import { NewEventoComponent } from './eventos/new-evento/new-evento.component';
import { EditCategoriaComponent } from './categorias/edit-categoria/edit-categoria.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewUserComponent } from './usuarios/new-user/new-user.component';
import { PaginationPageComponent } from './shared/pagination-page/pagination-page.component';
import { DeletesComponent } from './shared/deletes/deletes.component';
import { ConfigMenuComponent } from './config/config-menu/config-menu.component';
import { ConfigBannerComponent } from './config/config-banner/config-banner.component';
import { SelectionComponent } from './config/selection/selection.component';
import { NewBannerComponent } from './config/config-banner/new-banner/new-banner.component';
import { AddCategoriaComponent } from './config/selection/add-categoria/add-categoria.component';
import { AddBannerComponent } from './config/selection/add-banner/add-banner.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AppModule } from '../../app.module';
import { ViewUserComponent } from './usuarios/view-user/view-user.component';
import { WarningAdminComponent } from './shared/warning-admin/warning-admin.component';
// import { NgFallimgModule } from 'ng-fallimg';
import { WarningComponent } from '../shared/warning/warning.component';
import { CardsModule } from '../bloques/cards.module';


@NgModule({
  declarations: [
    HomeAdminComponent,
    HeaderAdminComponent,
    CategoriasComponent,
    EventosComponent,
    UsuariosComponent,
    NewEventoComponent,
    EditCategoriaComponent,
    NewUserComponent,
    PaginationPageComponent,
    DeletesComponent,
    ConfigMenuComponent,
    ConfigBannerComponent,
    SelectionComponent,
    NewBannerComponent,
    AddCategoriaComponent,
    AddBannerComponent,
    ViewUserComponent,
    WarningAdminComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    PickerModule,
    CardsModule
  ],
  exports: [
    HomeAdminComponent,
    HeaderAdminComponent,
    CategoriasComponent,
    EventosComponent,
    UsuariosComponent,
    NewEventoComponent,
    PaginationPageComponent
  ]
})
export class AdminModule { }
