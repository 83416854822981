import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SeatMapService } from 'src/app/services/seat-map.service';
import { ImageMapComponent } from '../../shared/image-map/image-map.component';

@Component({
  selector: 'app-maps-list',
  templateUrl: './maps-list.component.html',
  styleUrls: ['./maps-list.component.scss']
})
export class MapsListComponent implements OnInit {

  @Input() idSelect!: any;
  @Output() saveSelectMap = new EventEmitter<any>();

  listMaps!: Observable<any[]>;
  editEvent: boolean = false;
  map!: any;

  constructor(private seatMapService: SeatMapService, private router: Router, private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<MapsListComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      this.idSelect = this.data.map;
      this.editEvent = true;
      this.map = this.data.mapObject;
    }
    this.listMaps = this.seatMapService.getMapsPublicUser().pipe();
  }

  openImgMap(item: any) {
    this.dialog.open(ImageMapComponent, {
      panelClass: 'info-dialog',
      data: {
        map: item.map,
        name: item.name
      }
    });
  }

  select(map: any) {
    this.idSelect = map.id;
    this.map = map;
  }

  saveSelect() {
    this.saveSelectMap.emit({ map: this.idSelect, select: false, mapObject: this.map });
    if (this.data) {
      this.dialogRef.close({ map: this.idSelect, mapObject: this.map });
    }
  }

  goToCreate() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/create-map/new']));
    window.open(url, '_blank');
  }

}
