import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-totem',
  templateUrl: './footer-totem.component.html',
  styleUrls: ['./footer-totem.component.scss']
})
export class FooterTotemComponent {

  now: Date = new Date(); 
}
