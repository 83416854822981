import { formatDate } from '@angular/common';
import { Component, OnInit ,Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { FavoritosService } from 'src/app/services/favoritos.service';
import { environment } from 'src/environments/environment';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() card: any;
  @Input() list: boolean = false;
  today = new Date();
  user!: any;
  isWishlist: boolean = false;
  urlImages: string = AppSettings.MEDIA_URI + '/images/events';
  sharedSupported: boolean = false;
  @Output() eliminado = new EventEmitter<any>();
  @Input() teatroIdeal: boolean = false;
  imageUrl: string = '';

  totemActive: any = AppSettings.ENV_TOTEM;

  constructor(private eventos: EventosService, private favoritosService: FavoritosService, 
    private auth: AuthenticationService, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.user = this.auth.user;
    const ob = this.card.favoritos?.findIndex(value => value.id == this.user);
    if(this.card.imageurl && this.card.external && this.card.imageurl.includes('http')) {
      this.imageUrl = this.card.imageurl;
    } else {
      this.imageUrl = this.urlImages+'/'+this.card.id+'/'+this.card.imageurl
    }
    this.isWishlist = ob != -1;
    const navigator = window.navigator as any;
    if (navigator.share) {
      this.sharedSupported = true;
    }
  }

  tagString(card: any) {
    return (this.eventos.getTagString(card));
  }

  tagIcon(card: any) {
    return (this.eventos.getTagIcon(card));
  }

  datesEquals(card: any) {
    let fechaCard = card.fechaInicio;
    if(card.datesRange) {
      fechaCard = card.datesRange.next || card.datesRange.start;
    }
    return (this.eventos.datesEquals(fechaCard));
  }

  shareEvent() {
    if(this.sharedSupported) {
      const shareData = {
        title: this.card.titulo,
        url: AppSettings.FRONT + '/event/' + this.card.id + '-' + this.card.titulo.replaceAll(' ', '-')
      };
      navigator.share(shareData).catch((error) => {if(environment.debug) {console.log('Error sharing', error)}});
    }
  }

  editFavorito() {
    this.isWishlist ? this.deleteFavorito() : this.addFavorito();
  }

  addFavorito() {
    if(this.user) {
      this.favoritosService.postFavoritos(this.card.id).subscribe(res => {
        if(res) {
          this.isWishlist = true;
        }
      })
    } else {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Inicia sesión y empieza a guardar tus eventos favoritos',
          btnCancel: false
      }});    
    }
  }

  deleteFavorito() {
    if(this.user) {
      this.favoritosService.deleteFavorito(this.card.id).subscribe(res => {
        if(res) {
          this.isWishlist = false;
          this.eliminado.emit(this.card.id)
        }
      })
    } else {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Inicia sesión y empieza a guardar tus eventos favoritos',
          btnCancel: false
      }});
    }
  }

  goToPage(card: any) {
    let url = '/event';
    const date = card.fechaInicio;
    if(this.teatroIdeal) {
      url = `/teatroideal/${formatDate(card.datesRange.next, 'yyyyMMdd', 'es')}`;
    }
    this.router.navigate([url, card.id+'-'+card.titulo.replaceAll(' ', '-')]);
  }

}
