import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class CreateEventService {

  // Save Info Event
  private infoObservable = new BehaviorSubject<any>(null);
  public info = this.infoObservable.asObservable();

  // Capacity
  capacityObservable = new BehaviorSubject<any>(null);
  capacity = this.capacityObservable.asObservable();

  // Map Event
  mapObservable = new BehaviorSubject<any>(null);
  map = this.mapObservable.asObservable();

  private jsonMapObject = new BehaviorSubject<any>(null);
  public jsonMap = this.jsonMapObject.asObservable();

  constructor(private http: HttpClient) { }

  getInfoEvent() {
    return this.infoObservable.getValue();
  }

  setInfoDetail(payload: any) {
    this.infoObservable.next(payload);
  }

  getJsonMap() {
    return this.jsonMapObject.getValue();
  }

  setJsonMap(jsonMap: any) {
    this.jsonMapObject.next(jsonMap);
  }

  getCapacity() {
    return this.capacityObservable.getValue();
  }

  setCapacity(capacity: number) {
    this.capacityObservable.next(capacity);
  }

  getMapEvent() {
    return this.mapObservable.getValue();
  }

  setMapEvent(map: any) {
    this.mapObservable.next(map);
  }

  createEventBusiness(payload: any) {
    const url = `${AppSettings.API_URI}/create/business/event`;
    return this.http.post<any>(url, payload).pipe(
      map(data => {
        return data;
      }), 
      catchError(err => { return throwError(() => err); })
    );
  }
}
