import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog'
@Component({
  selector: 'app-warning-admin',
  templateUrl: './warning-admin.component.html',
  styleUrls: ['./warning-admin.component.scss']
})
export class WarningAdminComponent implements OnInit {

  icon: boolean = true;
  text: string = "¿Estás seguro de que deseas cancelar? Se perderán todos los datos introducidos.";
  btnCancel: boolean = true;
  warning: boolean = false;
  delete: boolean = true;
 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,private dialogRef: MatDialogRef<WarningAdminComponent>) {
    if(data){
      if (data.delete) {
        this.delete = true
      }
    }
   }

  ngOnInit(): void {
  }

}
