import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BondsServices {

  constructor(private http: HttpClient) { }

  getBonds(idBusiness: any, page: number, searchText: string, sortFilter: any, places: any | any[]) {
    let url = `${AppSettings.API_URI}/list/bonds/${idBusiness}?page=${page}`;
    if (places && places.length > 0) {
      places?.forEach(element => {
        if (element && element != 'null') {
          url += `&places[]=${element}`;
        }
      });
    }
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (sortFilter) {
      url += `&orderBy=${sortFilter.sortedBy}&order=${sortFilter.sort}`;
    }
    // if (places) {
    //   places?.forEach(element => {
    //     url += `&places[]=${element}`;
    //   });
    // }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  createBond(payload: any, idBond?: number) {
    let url = `${AppSettings.API_URI}`;
    if (idBond) {
      url += `/edit/bond/${idBond}`;
    } else {
      url += `/create/bond`;
    }
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  deleteBond(idBond: any) {
    const url = `${AppSettings.API_URI}/delete/bonds/${idBond}`;
    return this.http.delete<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
