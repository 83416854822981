<div class="map-venue">
    <div *ngIf="!event; else divInfoEvent" class="loading-container-panel h-100 w-100 d-flex justify-content-center">
        <div class="container-lottie">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>
    <ng-template #divInfoEvent>
        <div>
            <!-- Aforo -->
            <div class="container-in-page container-capacity" *ngIf="!map">
                <div class="input-capacity-event">
                    <div class="title-edit">
                        <i class="fa-light fa-seat-airline"></i>
                        <p class="m-0">Aforo del evento</p>
                    </div>
                    <div class="d-flex align-items-center edit-capacity">
                        <input class="w-100" [disabled]="!editCapacity" type="number"
                            placeholder="Indica el aforo del evento (Opcional)" [(ngModel)]="capacity"
                            name="link">
                        <i class="fal" [class]="editCapacity ? 'fa-save icon-primary' : 'fa-pen'"
                            (click)="changeCapacity()"></i>
                    </div>
                </div>
            </div>
            <!-- Mapa de butacas -->
            <div>
                <div class="container-in-page d-grid" *ngIf="!map">
                    <div class="title-edit">
                        <!-- <i class="fa-light fa-seat-airline"></i> -->
                        <p class="m-0">Mapa de butacas</p>
                    </div>
                    <p *ngIf="!map && !openedCreateMap">Para crear un mapa de butacas, por favor, ponte en contacto
                        con los administradores de Citylok.</p>
                    <div class="conteiner-map-venue">
                        <div class="btns-create">
                            <div class="btn-create btn-bono m-0" [class.disabled]="!map" (click)="createMap()">
                                <i class="fal fa-plus-circle"></i>
                                <p class="m-0">Crear mapa de butacas</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-map-venue" *ngIf="map || openedCreateMap">
                    <app-create-map [allowCreate]="allowCreate" [fromEvent]="true" [idEvent]="event.id" [idMap]="map.id"></app-create-map>
                </div>
            </div>
        </div>
    </ng-template>
</div>