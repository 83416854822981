<div class="business-menu-container">
    <div class="item-submenu" [class.active]="tabSelected==0" (click)="changeTabBusiness({tab: 0, name: 'Datos de la organización'})" [routerLink]="['/panel-control/config/business/info-business']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <span>Datos de la organización</span>
    </div>
    <!-- <div class="item-submenu" [class.active]="submenuActive===1" (click)="changeTabBusiness({tab: 1, name: 'Página de la organización'})" [routerLink]="['/panel-control/config/business/custom-page']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <span>Página de la organización</span>
    </div> -->
    <div class="item-submenu" (click)="changeTabBusiness({tab: 2, name: 'Ayuda'})" [routerLink]="['/panel-control/config/business/help']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <span>Ayuda</span>
    </div>
</div>