import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class CreatorService {

  constructor(private http: HttpClient) { }

  getInfoCreator(urlBusiness: boolean, slug: string) {
    const url = `${AppSettings.API_URI}/info/${urlBusiness?'business':'venue'}/${slug}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

}
