<!-- <script src="https://js.stripe.com/v3/"></script>
<script src="https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&components=YOUR_COMPONENTS"></script> -->


<div class="container-step-payment">
    <div class="loading-container" *ngIf="waiting">
        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
            containerClass="loading-box"></ng-lottie>
    </div>

    <!-- Columna de resumen -->
    <div>
        <div class="section resume-card padding-resume" [class.open-resume]="openResumeResponsive">
            <div class="d-flex align-items-center justify-content-between">
                <p class="title-section m-0">Resumen</p>
                <span class="d-md-none pointer-in" (click)="openResumeResponsive=!openResumeResponsive">
                    <i class="fal fa-times icon-grey"></i>
                </span>
            </div>
            <div *ngFor="let event of dataBuy.tickets">
                <div *ngFor="let option of event.options" class="item-resume-event">
                    <div class="number-event d-flex align-items-center justify-content-between">
                        <div class="title-event text-grid-event">
                            <span class="number-tickets-event">x{{event.tickets}}</span>
                            <span class="name-ticket-event">{{event.titulo}}</span>
                        </div>
                        <p class="total-price-event title-event">{{option.priceDate | number: '2.2-2'}} €</p>
                    </div>
                    <div class="text-show-extra">
                        <div class="detail-resume">
                            <div class="locate-event d-flex align-items-center">
                                <i class="fa-solid fa-map-marker-alt icon-grey"></i>
                                <p class="m-0">{{event.place?.name?event.place.name:event.localizacion}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between gap-1 pointer-in"
                            (click)="option.showTickets=!option.showTickets">
                            <i class="far icon-primary"
                                [class]="option.showTickets?'fa-chevron-up':'fa-chevron-down'"></i>
                            <span class="text-show-tickets">Mostrar entradas</span>
                        </div>
                    </div>
                    <div class="show-tickets-option" *ngIf="option.showTickets">
                        <div class="date-option d-flex align-items-center gap-1">
                            <i class="fa-light fa-clock icon-primary"></i>
                            <p class="m-0 icon-grey">{{option.date | date:'EEEE, dd MMM yyyy' | titlecase}}
                                <span *ngIf="option.time"> - </span>
                                <span class="m-0" *ngIf="option.time">{{option.time}}</span>
                            </p>
                        </div>
                        <div class="ticket-option" *ngFor="let item of option.tickets">
                            <div>
                                <p class="m-0 name-item">{{item.ticket.nombre}}<span *ngIf="item.abono">- <span
                                            class="icon-primary">abono</span></span></p>
                                <p class="m-0 name-section-row-seat"><span *ngIf="item.section">Sección:
                                        {{item.sectionName}},</span> <span *ngIf="item.row && item.showRow"> Fila:
                                        {{item.row}},</span>
                                    <span *ngIf="item.seat"> Asiento: {{item.seat}}</span>
                                </p>
                            </div>
                            <span class="price-ticket-option" [class.icon-primary]="item.abono">{{item.price|number:
                                '2.2-2'}}€</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex price-resume price-gestion justify-content-between" *ngIf="dataBuy.fees">
                <p class="m-0">Gastos de gestion</p>
                <p class="price-total m-0">{{ (dataBuy.fees) | number:'1.2-2' }} €</p>
            </div>
            <div class="d-flex price-resume justify-content-between">
                <p>Total</p>
                <p class="price-total">{{ (dataBuy.totalPrice) | number:'1.2-2' }} €</p>
            </div>
        </div>
        <div class="padding-resume card-info-resume">
            <div class="d-flex gap-2 align-items-center mb-2">
                <i class="far fa-lock icon-primary"></i>
                <span class="title-info-resume">Protegemos tus datos</span>
            </div>
            <div>
                <p class="info-security-resume">Proteger tu privacidad es importante para nosotros, incluida su
                    información personal, métodos de
                    pago, información de la tarjeta, etc. Tu información se mantendrá segura. No divulgaremos ni
                    venderemos tus datos.</p>
            </div>
        </div>
    </div>

    <!-- Paso de elección de método de pago -->
    <div class="payment-card" *ngIf="!stepPayOpen; else templateCardPay">
        <mat-radio-group [(ngModel)]="optionPayment" class="section-buttons-card" [class.payment-active]="waiting">
            <mat-radio-button *ngFor="let card of cardsUser" class="w-100 check-card-button section card-option" [value]="card?.id">
                <span class="title-section m-0">Tarjeta ****{{card.last4}}</span>
                <div class="d-flex gap-2">
                    <!-- <i class="fab fa-cc-mastercard fa-2x icon-grey"></i> -->
                    <i class="fab fa-2x icon-grey" [class]="card.brand == 'MASTERCARD'?'fa-cc-mastercard':'fa-cc-visa'"></i>
                </div>
            </mat-radio-button>
            <mat-radio-button class="w-100 check-card-button section card-option" value="card">
                <span class="title-section m-0">Tarjeta de crédito o débito</span>
                <div class="d-flex gap-2">
                    <i class="fab fa-cc-mastercard fa-2x icon-grey"></i>
                    <i class="fab fa-cc-visa fa-2x icon-grey"></i>
                </div>
            </mat-radio-button>
            <div class="d-none d-md-flex">
                <button class="btn-buy-event pointer-in" [class.disabledBtn]="disabledButton"
                    (click)="stepPay()">{{optionPayment == 'card' ? 'Continuar' : 'Pagar'}}</button>
            </div>
        </mat-radio-group>
    </div>

    <!-- Columna de tarjeta de crédito -->
    <ng-template #templateCardPay>
        <mat-radio-group [(ngModel)]="optionPayment" class="payment-card" [class.payment-active]="waiting">
            <div class="section credit-card">
                <mat-radio-button class="w-100 check-card-button" value="card">
                    <span class="title-section m-0">Tarjeta de crédito o débito</span>
                    <div class="d-flex gap-2">
                        <i class="fab fa-cc-mastercard fa-2x icon-grey"></i>
                        <i class="fab fa-cc-visa fa-2x icon-grey"></i>
                    </div>
                </mat-radio-button>
                <div class="h-100">
                    <iframe id="paylands" class="paylands-container" *ngIf="redirectPaylands" [src]="redirectPaylands"
                        width="100%" height="auto" frameborder="0"></iframe>
                </div>
            </div>
        </mat-radio-group>
    </ng-template>
    <div class="responsive-resume d-md-none">
        <div class="pointer-in d-flex align-items-center gap-2" (click)="openResumeResponsive=!openResumeResponsive">
            <p class="price-total m-0">{{ (dataBuy.totalPrice) | number:'1.2-2' }} €</p>
            <i class="far" [class]="openResumeResponsive?'fa-chevron-up':'fa-chevron-down'"></i>
        </div>
        <div>
            <button id="triggerButton" class="btn-buy-event pointer-in" [class.disabledBtn]="disabledButton"
                (click)="stepPay()">{{optionPayment == 'card' ? 'Continuar' : 'Pagar'}}</button>
        </div>
    </div>
</div>