import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, first } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { Observable, of } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ComentariosService {

  constructor(private common: CommonService, private http: HttpClient) { }

  getComentarios(event: number, items: number = 3) {
    const url = `${ AppSettings.API_URI }/comments/${event}/${items}`;
    return this.common.getData(url);
  }

  getComentariobyId(id:number) {
    const url = `${ AppSettings.API_URI }/comentarios/${id}`;
    return this.common.getData(url);
  }

  postComentario(comentario: any) {
    const url = `${ AppSettings.API_URI }/createcomment`;
    return this.common.postData(url, comentario);
  }

  putComentario(ciudadId: number) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/comentarios/${ ciudadId }`;
    return this.http.put<any>(url, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }

  deleteComentario(ciudadId: number) {
    const url = `${ AppSettings.API_URI }/comentarios/${ ciudadId }`;
    return this.http.delete<any>( url ).pipe(
      map(data => {
        if(data) {
          return of(true);
        } else {
          return of(false);
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }
}
