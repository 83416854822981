<div class="new-manager">
    <div class="main-new-manager">
        <h1 class="title-new-manager">{{editBond ? 'Editar' : 'Crear'}} Abono</h1>
        <div class="form-info-user">
            <form class="form-grid" [formGroup]="formNewManager">
                <!-- <div class="main-inputs"> -->
                    <div class="mb-3">
                        <p class="label-input">Nombre</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="name" [required]="true">
                        </div>
                    </div>
                    <!-- <div>
                        <p class="label-input">Código</p>
                        <div class="form-input-container ">
                            <input type="text" class="w-100" formControlName="code">
                        </div>
                    </div> -->
                <!-- </div> -->
                <div class="main-inputs">
                    <div>
                        <p class="label-input">Descuento €</p>
                        <mat-form-field appearance="fill" class="filter-place form-input-container">
                            <mat-select placeholder="Tipo de descuento" formControlName="type">
                                <mat-option value="amount">Cantidad total</mat-option>
                                <mat-option value="percentage">Porcentaje</mat-option>
                            </mat-select>
                            <mat-select-trigger matSuffix style="vertical-align: middle;">
                                <i class="fal fa-angle-down pointer-in"></i>
                            </mat-select-trigger>
                        </mat-form-field>
                    </div>
                    <div>
                        <p class="label-input">Cantidad</p>
                        <div class="form-input-container d-flex">
                            <input type="number" class="w-100" formControlName="cuantity">
                            <i matSuffix>{{formNewManager.value.type == 'amount' ? '€' : '%'}}</i>
                        </div>
                    </div>
                </div>
            </form>
        </div>        
    </div>
    <mat-divider></mat-divider>
    <div class="footer-new-manager">
        <div class="btn-new btn-create btn-create-border m-0" (click)="cancelNew()">
            <p class="m-0">Cancelar</p>
        </div>
        <div class="btn-new btn-create m-0" (click)="nextStep()"
            [class.disabled]="formNewManager.invalid">
            <p class="m-0">Siguiente</p>
        </div>
    </div>
</div>