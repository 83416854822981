import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class ApiSalesService {

  // Category
  private openPurchase = new BehaviorSubject<boolean>(false);
  public openedPurchase = this.openPurchase.asObservable();

  constructor(private http: HttpClient) { }

  setOpenPurchase(openPurchase: boolean) {
    this.openPurchase.next(openPurchase);
  }

  getOpenPurchase() { return this.openPurchase.getValue(); }

  getAllStatus() {
    const url = `${AppSettings.API_URI}/status/transactions`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getAllMethods() {
    const url = `${AppSettings.API_URI}/channel/sales`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getStatusByBusiness(idBusiness: number) {
    const url = `${AppSettings.API_URI}/status/business/transaction/${idBusiness}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getStatusByEvent(idEvent: number) {
    const url = `${AppSettings.API_URI}/status/event/transaction/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getMaxPriceByCompany(idCompany: number) {
    const url = `${AppSettings.API_URI}/maxprice/business/${idCompany}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getMaxPriceByEvent(idEvent: number) {
    const url = `${AppSettings.API_URI}/maxprice/event/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getStatusPay(orderKey: string) {
    const url = `${AppSettings.API_URI}/check/status/pay/${orderKey}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getStatusTransactionWeb(orderKey: string) {
    const url = `${AppSettings.API_URI}/check/pay/web/${orderKey}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  putStatusPay(orderKey: string, status: string) {
    const payload = {
      payment: orderKey
    };
    const url = `${AppSettings.API_URI}/payment/status/change/${orderKey}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  confirmCheckoutWeb(payload: any) {
    const url = `${AppSettings.API_URI}/confirm/checkout/web`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
