import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FacebookLoginProvider } from "@abacritt/angularx-social-login";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RememberPasswordComponent } from '../remember-password/remember-password.component';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss']
})
export class LoginCardComponent implements OnInit {

  loginForm: FormGroup;
  hide: boolean = true;
  socialUser!: SocialUser;
  social = '';
  @Input() fromSeats: boolean = false;

  @Input() backgroundColor: any = '#F8F8F8';
  @Input() textBtn: string = 'Iniciar sesión';

  @Output() redirectMsg: EventEmitter<string> = new EventEmitter();
  @Output() errorMsg: EventEmitter<boolean> = new EventEmitter();
  @Output() registerBtn: EventEmitter<boolean> = new EventEmitter();
  @Output() correctForm: EventEmitter<boolean> = new EventEmitter();
  @Output() loadingLogin: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private location: Location, private dialog: MatDialog, @Optional() private dialogRef: MatDialogRef<LoginCardComponent>
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]],
    });
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.social != 'facebook' ? this.signInWithG() : this.signInWithFB();
    });
  }

  signInWithFacebook(): void {
    this.social = 'facebook';
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  login() {
    if (this.loginForm.invalid) {
      this.redirectMsg.emit('Rellena todos los campos');
      this.errorMsg.emit(true);
      return;
    }
    this.loadingLogin.emit(true)
    this.authenticationService
      .login(
        this.loginForm.get('username').value,
        this.loginForm.get('password').value
      ).subscribe({
        next: data => {
          if (data.token !== '') {
            this.getCheckUser();
          } else {
            this.redirectMsg.emit('Usuario o contraseña incorrectos, vuelve a intentarlo de nuevo.');
            this.errorMsg.emit(true);
          }
        },
        error: (error) => {
          this.redirectMsg.emit('Credenciales Inválidas');
          this.errorMsg.emit(true);
        },
      });
  }

  getCheckUser() {
    this.authenticationService.check(this.fromSeats).subscribe(usr => {
      if (!usr || !usr.enabled) {
        localStorage.removeItem('jwt_token');
        this.redirectMsg.emit('No has podido acceder, el usuario no está verificado.');
        this.errorMsg.emit(true);
      }
      if (usr && usr.enabled) {
        this.correctForm.emit(true)
      }
      if (this.fromSeats) {
        this.correctForm.emit(true)
        this.dialogRef.close({login: true});
      }
    })
  }

  goToRegister() {
    this.dialog.closeAll();
    this.registerBtn.emit(true);
  }

  openRemember() {
    const dialogRef = this.dialog.open(RememberPasswordComponent, {
      panelClass: 'info-dialog',
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        let msg = res.data ? 'Si el usuario existe recibirás un correo para resetear tu contraseña.' : '¡Vaya! Ha ocurrido un error.';
        let icon = res.data ? false : true;
        this.dialog.open(InfoCardComponent, {
          panelClass: 'info-dialog',
          data: {
            icon: icon,
            text: msg,
            btnCancel: false
          }
        });
      }
    })
  }

  //ToDo FACEBOOK, waiting have an account
  signInWithG(): void {
    this.loadingLogin.emit(true)
    this.social = 'google';
    this.authenticationService.loginGoogle(this.socialUser.id, this.socialUser.idToken).subscribe((data) => {
      this.getCheckUser();
    }, (error) => {
      this.redirectMsg.emit('No has podido acceder, vuelve a intentarlo de nuevo');
      this.errorMsg.emit(true);
    }
    )
  };

  signInWithFB(): void {
    this.loadingLogin.emit(true)
    this.authenticationService.loginFacebook(this.socialUser).subscribe({
      next: (data) => {
        this.getCheckUser();
      },
      error: (error) => {
        this.redirectMsg.emit('No has podido acceder, vuelve a intentarlo de nuevo');
        this.errorMsg.emit(true);
      }
    })
  };
}
