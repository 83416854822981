<div class="d-flex component-generate-map">
    <div class="p-0" [class]="allowCreate?'col-9':'col-12'">
        <div class="content-generate-canvas" id="container-seats">
            <div class="buttons-zoom-map">
                <button (click)="zoomMap(true)">
                    <i class="fa-regular fa-plus"></i>
                </button>
                <button (click)="zoomMap(false)">
                    <i class="fa-regular fa-minus"></i>
                </button>
            </div>
            <canvas id="myCanvas"></canvas>
        </div>
    </div>
    <div class="aside-options" *ngIf="allowCreate">
        <div *ngIf="!activeTab; else editTab">
            <div class="d-flex justify-content-between align-items-center">
                <p class="title-options-map">Mapa de butacas</p>
                <!-- <p>{{capacity}}</p> -->
            </div>
            <div class="options-map">
                <!-- <div class="section-option" (click)="addScenary()" *ngIf="(profile|async)?.admin">
                    <p class="m-0">Escenario</p>
                    <i class="fa-light fa-tv invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div>
                <div class="section-option" (click)="addSeat()" *ngIf="(profile|async)?.admin">
                    <p class="m-0">Butacas asignadas</p>
                    <i class="fa-light fa-loveseat invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div>
                <div class="section-option" (click)="addSectionFree()" *ngIf="(profile|async)?.admin">
                    <p class="m-0">Sección sin numerar</p>
                    <i class="fa-light fa-seat-airline invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div>
                <div class="section-option" (click)="addStandSection()" *ngIf="(profile|async)?.admin">
                    <p class="m-0">Pista</p>
                    <i class="fa-light fa-shoe-prints invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div> -->
                <!-- <div class="section-option" (click)="addSection()">
                    <p class="m-0">Mesas y sillas</p>
                    <i class="fa-light fa-pipe-section invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div> -->
                <!-- <div class="section-option">
                    <p class="m-0">Extras</p>
                    <i class="fa-light fa-grid-2-plus invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div> -->
                <!-- <div class="section-option" (click)="addPoligon()">
                    <p class="m-0">Polígono</p>
                    <i class="fa-light fa-grid-2-plus invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div>
                <div class="section-option" (click)="addForm()">
                    <p class="m-0">Sección</p>
                    <i class="fa-light fa-grid-2-plus invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div>
                <div class="section-option" (click)="addText()" *ngIf="(profile|async)?.admin">
                    <p class="m-0">Texto</p>
                    <i class="fa-light fa-input-text invisible-icon"></i>
                    <i class="fa-light fa-plus add-icon"></i>
                </div>
                <div class="section-option" (click)="openLevelsHidden('level')" *ngIf="(profile|async)?.admin">
                    <p class="m-0">Áreas / Tipos de asiento</p>
                    <i class="fa-light fa-arrow-right"></i>
                </div> -->
                <div class="section-option" (click)="openLevelsHidden('hidden')">
                    <p class="m-0">Bloquear asientos</p>
                    <i class="fa-light fa-arrow-right"></i>
                </div>
            </div>
        </div>
        <ng-template #editTab>
            <div *ngIf="!openEditObject && !openEditSection">
                <p class="icon-primary">EDITOR ACTIVO</p>
                <div class="d-flex align-items-center mb-3" (click)="closeTab()">
                    <i class="fa-light fa-arrow-left pointer-in" style="margin-right: 20px;"></i>
                    <p class="m-0">{{titleTab}}</p>
                </div>
                <div class="options-map" *ngIf="openedLevels">
                    <div>
                        <app-level-types [assignedSeats]="assignedSeats" [selectedSeats]="selectedSeats"
                            [capacity]="capacity" [levels]="levels" (levelAssigned)="activeEditLevel($event)"
                            (changeAssigned)="assignedSeatsLevel($event)"
                            (deleteLevel)="deleteLevel($event)" [fromCreate]="fromCreate"></app-level-types>
                    </div>
                    <div class="section-option" (click)="addLevel()">
                        <p class="m-0">Crear nueva</p>
                        <i class="fa-light fa-layer-plus invisible-icon"></i>
                        <i class="fa-light fa-plus add-icon"></i>
                    </div>
                </div>
                <div class="options-map" *ngIf="openedHidden">
                    <div>
                        <app-hidden-seats [capacity]="capacity" [hiddenTypes]="hiddenTypes"
                            [selectedSeats]="selectedHidden" [assignedHidden]="hiddenSeats"
                            (changeAssigned)="assignedSeatsHidden($event)" (typeAssigned)="activeEditHidden($event)"
                            (deleteType)="deleteHiddenType($event)" [admin]="(profile|async)?.admin" [fromCreate]="fromCreate"></app-hidden-seats>
                    </div>
                    <div class="section-option" (click)="addHiddenType()" *ngIf="(profile|async)?.admin">
                        <p class="m-0">Crear nuevo</p>
                        <i class="fa-light fa-layer-plus invisible-icon"></i>
                        <i class="fa-light fa-plus add-icon"></i>
                    </div>
                </div>
            </div>
            <div class="options-map" *ngIf="openEditObject">
                <app-edit-section [canvas]="canvas" [sectionElement]="objectEditing"
                    [colorDefaultSeat]="colorDefaultSeat" [sitSelect]="sitSelect"
                    [seatsEditSelected]="seatsEditSelected" (clickEdit)="editObject()"
                    [openSeatEditing]="openSeatEditing" (activeEdit)="activeEdit($event)"
                    (updateCanvas)="updateCanvas($event)" (createObj)="createObj($event)"
                    (closeTab)=closeTab()></app-edit-section>
            </div>
            <div class="options-map" *ngIf="openEditSection">
                <app-edit-info-section [canvas]="canvas" [sectionElement]="objectEditing"
                    [colorDefault]="colorDefaultStage" (updateCanvas)="saveColorSection($event)" 
                    (closeTab)=closeTab()></app-edit-info-section>
            </div>
        </ng-template>
        <!-- <div class="delete-link" *ngIf="(profile|async)?.admin">
            <p *ngIf="idMap" (click)="deleteMap()">Eliminar mapa</p>
        </div> -->
    </div>
</div>