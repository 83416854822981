import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-place',
  templateUrl: './dialog-place.component.html',
  styleUrls: ['./dialog-place.component.scss']
})
export class DialogPlaceComponent {

}
