import { Component, EventEmitter, Input, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-menu-venue',
  templateUrl: './menu-venue.component.html',
  styleUrls: ['./menu-venue.component.scss']
})
export class MenuVenueComponent {

  titleTab: string = 'Datos del espacio';
  @Input() tabSelected: any = 0;
  @Input() idVenue!: any;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  changeTabBusiness(emit: any) {
    this.titleTab = emit.name;
    this.changeTab.emit(emit);
  }

}
