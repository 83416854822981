<div class="router-event">
    <div class="d-flex align-items-end justify-content-between">
        <h1 class="title-detail-event m-0">{{title}}</h1>
        <div class="d-flex align-items-center link-open-event" (click)="openLinkEvent()">
            <i class="fal fa-link pointer-in"></i>
            <span>Ver evento en Citylok</span>
        </div>
    </div>
    <mat-divider class="divider-title"></mat-divider>
    <div class="d-flex info-panel page-panel">
        <app-menu-events [idEvent]="event" [draft]="isDraftEvent" (changeTab)="changeMenuEvent($event)"></app-menu-events>
        <div class="card-white-panel">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex title-menu-page">
                    <h2><span>{{nameTab}}</span>
                        <mat-divider [vertical]="true"></mat-divider>
                        <span>{{title}}</span>
                    </h2>
                </div>
                <div class="btns-create">
                    <div class="btn-create btn-action m-0" *ngIf="activePublish" (click)="publishEvent()">
                        <!-- <i class="fal fa-ticket"></i> -->
                        <p class="m-0">Publicar evento</p>
                    </div>
                </div>
                <div *ngIf="mapPage && actionMap && allowCreate" style="margin-right: 20px;">
                    <div class="btns-create">
                        <div class="btn-create btn-action btn-create-border m-0" (click)="changeActionMap(true)">
                            <!-- <i class="fal fa-plus-circle"></i> -->
                            <p class="m-0">Guardar</p>
                        </div>
                        <div class="btn-create btn-action m-0" (click)="changeActionMap(false)">
                            <!-- <i class="fal" [class]="actionMap == 'edit' ? 'fa-floppy-disk' : 'fa-circle-xmark'"></i> -->
                            <p class="m-0">{{actionMap == 'edit' ? 'Editar mapa de butacas' : 'Cancelar'}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="event">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>