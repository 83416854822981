<div class="config-ticket form-new-lok" [class.dialog-new]="fromNew">
    <div class="d-flex btns-close-section align-items-center justify-content-end pointer-in" *ngIf="!fromNew">
        <div class="icon-close" mat-dialog-close>
            <i class="fa-regular fa-times"></i>
        </div>
    </div>
    <!-- Form new ticket -->
    <form [formGroup]="formConfig" class="form-entrada">
        <!-- <mat-divider></mat-divider> -->
        <div *ngIf="allowExternal">
            <!-- Info -->
            <!-- <div class="d-flex align-items-center justify-content-between section-external">
                <p class="title-external">Evento informativo (sin venta de entradas)</p>
                <mat-slide-toggle formControlName="informativo"></mat-slide-toggle>
            </div>
            <mat-divider></mat-divider> -->
            <!-- External -->
            <div class="d-flex align-items-center justify-content-between section-external">
                <p class="title-external">Evento con venta externa</p>
                <mat-slide-toggle formControlName="external"></mat-slide-toggle>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="!allowExternal">
            <!-- Editar publicación automática -->
            <!-- <div class="padding-inputs">
            <div class="section-input d-grid">
                <div class="d-flex align-items-end">
                    <mat-checkbox formControlName="progPublish" class="checkbox" color="primary"
                        style="margin-right: 8px;"></mat-checkbox>
                    <p class="m-0">Publicación programada</p>
                </div>
                <div class="d-flex align-items-center input-border" style="width: fit-content;"
                    *ngIf="formConfig.value.progPublish">
                    <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'auto'"
                        (click)="openDatePicker('auto')">
                        <i class="fa-light fa-calendar"></i>
                        <input placeholder="Fecha de publicación" class="input-date pointer-in" readonly
                            [(ngModel)]="publishStringDate" [ngModelOptions]="{standalone: true}">
                        <i class="icon-down fa-light fa-chevron-down"></i>
                    </div>
                </div>
            </div>
            <div class="section-input" *ngIf="typeDatepicker == 'auto'">
                <div class="calendar-option">
                    <app-default-calendar [selectedDate]="publishDate"
                        (saveDate)="saveDate($event)"></app-default-calendar>
                </div>
            </div>
        </div>
        <div class="info-default">
            <p class="m-0">
                <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>
                Si no se elige la fecha de publicación, se publicará de forma inmediata.
            </p>
        </div>
        <mat-divider></mat-divider> -->
            <!-- Fecha de venta de entradas -->
            <div class="padding-inputs">
                <p>Fecha inicio de venta de entradas</p>
                <div class="section-input">
                    <!-- Fecha inicio venta -->
                    <div class="d-flex align-items-center justify-content-between input-border">
                        <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'start'"
                            (click)="openDatePicker('start')">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Inicio de venta" class="input-date pointer-in" readonly
                                [(ngModel)]="startStringDate" [ngModelOptions]="{standalone: true}">
                            <i class="icon-down fa-light fa-chevron-down" *ngIf="allowCreate"></i>
                        </div>
                    </div>

                    <!-- Hora inicio venta -->
                    <div class="d-flex align-items-center justify-content-center inputs-hours">
                        <div class="input-hour">
                            <input placeholder="20" type="number" min="0" max="23" required [readOnly]="!allowCreate"
                                (ngModelChange)="changeHourInit($event)" [ngModel]="(hourInit|number:'2.0-2')"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                        <span class="points">:</span>
                        <div class="input-hour">
                            <input style="text-align: right;" placeholder="00" type="number" min="0" max="59" required
                                [readOnly]="!allowCreate" (ngModelChange)="changeMinuteInit($event)"
                                [ngModel]="minuteInit|number:'2.0-2'" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>
                <div class="section-input" *ngIf="typeDatepicker == 'start' && allowCreate">
                    <div class="calendar-option" *ngIf="typeDatepicker == 'start'">
                        <app-default-calendar [selectedDate]="startDate"
                            (saveDate)="saveDate($event)"></app-default-calendar>
                    </div>
                </div>
                <p>Fecha fin de venta de entradas</p>
                <div class="section-input">
                    <!-- Fecha fin venta -->
                    <div class="d-flex align-items-center justify-content-between input-border">
                        <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'end'"
                            (click)="openDatePicker('end')">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Fin de venta" class="input-date pointer-in" readonly
                                [(ngModel)]="endStringDate" [ngModelOptions]="{standalone: true}">
                            <i class="icon-down fa-light fa-chevron-down" *ngIf="allowCreate"></i>
                        </div>
                    </div>
                    <!-- Hora fin venta -->
                    <div class="d-flex align-items-center justify-content-center inputs-hours">
                        <div class="input-hour">
                            <input placeholder="20" type="number" min="0" max="23" required [readOnly]="!allowCreate"
                                [ngModel]="hourEnd|number:'2.0-2'" (ngModelChange)="changeHourEnd($event)"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                        <span class="points">:</span>
                        <div class="input-hour">
                            <input style="text-align: right;" placeholder="00" type="number" min="0" max="59" required
                                [readOnly]="!allowCreate" (ngModelChange)="changeMinuteEnd($event)"
                                [ngModel]="minuteEnd|number:'2.0-2'" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>
                <div class="section-input" *ngIf="typeDatepicker == 'end' && allowCreate">
                    <div class="calendar-option" *ngIf="typeDatepicker == 'end'">
                        <app-default-calendar [selectedDate]="endDate"
                            (saveDate)="saveDate($event)"></app-default-calendar>
                    </div>
                </div>
            </div>
            <div class="info-default">
                <p class="m-0">
                    <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>
                    Fechas de inicio y fin de venta de entradas, en caso de no indicarlas se pondrán a la venta cuando
                    se publique el evento y se terminará cuando comience.
                </p>
            </div>
            <mat-divider></mat-divider>
            <!-- Número máximo de entradas -->
            <div class="padding-inputs" *ngIf="!formConfig.value.external">
                <p>Entradas máximas por usuario</p>
                <div class="section-input">
                    <div class="input-border select-style input-ticket">
                        <input placeholder="Entradas" type="number" min="0" formControlName="maxTickets"
                            [readOnly]="!allowCreate" />
                        <i class="fa-light fa-ticket icon-primary"></i>
                    </div>
                </div>
            </div>
            <!-- <mat-divider></mat-divider> -->
            <!--
        <mat-divider></mat-divider>
        <div class="padding-inputs">
            <div class="d-flex align-items-end">
                <mat-checkbox formControlName="assumeFees" class="checkbox" color="primary"
                    style="margin-right: 8px;"></mat-checkbox>
                <p class="m-0">Asumir comisiones</p>
            </div>
        </div>
        <div class="info-default">
            <p class="m-0">
                <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Si no asumes las
                comisiones, este coste se verá reflejado en los gastos de gestión.
            </p>
        </div>
        <mat-divider></mat-divider>
        <div class="padding-inputs">
            <div class="d-flex align-items-end">
                <mat-checkbox formControlName="needValidation" class="checkbox" color="primary"
                    style="margin-right: 8px;"></mat-checkbox>
                <p class="m-0">Se necesita DNI para la compra</p>
            </div>
        </div>
        <div class="info-default">
            <p class="m-0">
                <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Será necesario realizar
                una validación por DNI para realizar la compra. Se utiliza para pedidos limitados a x entradas por
                persona.
            </p>
        </div>
        -->
        </div>
        <div class="item-form-link d-flex" *ngIf="formConfig.value.external && allowExternal">
            <i class="fa-light fa-link icon-primary"></i>
            <div class="content-link w-100">
                <span class="m-0 subtitle-link">Link de venta externa: 'https://www.venta.ejemplo.com' *</span>
                <input class="w-100 input-link-external" type="text" formControlName="url" required
                    [readOnly]="!allowCreate && !allowExternal">
            </div>
        </div>
        <mat-divider class="divider-link" *ngIf="formConfig.value.external"></mat-divider>
    </form>

    <!-- Añadir ticket -->
    <button class="btn-new-form btn-save w-100" *ngIf="allowCreate || allowExternal" (click)="saveConfig()">GUARDAR</button>
</div>