<div class="totem-home-component">
    <div class="home-banner">
        <div class="img-banner"></div>
        <div class="title-home position-absolute w-100">
            <p class="upper-title-totem m-0">eventos</p>
            <h1 class="mb-2 title-totem">{{ title }}</h1>
        </div>
    </div>
    <div class="container container-scroll">
        <app-infinity-scroll [idCreator]="1"></app-infinity-scroll>
    </div>
</div>