<div class="edit-seats-object">
    <div class="title-list-level">
        <p>Asientos seleccionados: {{getCountEdit()}}</p>
    </div>
    <div class="options-edit-section active">
        <div class="section-edit" *ngIf="seatElement?.eventSeat">
            <div class="d-flex align-items-center justify-content-between" *ngIf="getCountEdit() <= 1">
                <p class="m-0">Número de butaca:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Butaca" [(ngModel)]="valueSeat" (change)="editSeatNumber($event)" required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between" *ngIf="getCountEdit() >= 1">
                <p class="m-0">Ocultar:</p>
                <mat-checkbox [(ngModel)]="visibilitySeat" class="checkbox" color="primary"
                    (change)="changeVisibilitySeat()"></mat-checkbox>
            </div>
        </div>
        <div class="section-edit" *ngIf="getCountEdit() == 0">
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Numeración par:</p>
                <mat-checkbox [(ngModel)]="numeracionPar" class="checkbox" color="primary"
                    (change)="changeNumPar()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Numeración impar:</p>
                <mat-checkbox [(ngModel)]="numeracionImpar" class="checkbox" color="primary"
                    (change)="changeNumImpar()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Numeración de filas:</p>
                <mat-checkbox [(ngModel)]="numeracionFilas" class="checkbox" color="primary"
                    (change)="changeNumberRows()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">De izquierda a derecha:</p>
                <mat-checkbox [(ngModel)]="directionRight" class="checkbox" color="primary"
                    (change)="changeDirection()"></mat-checkbox>
            </div>
        </div>
        <div class="section-edit" *ngIf="seatElement && !seatElement?.eventSeat">
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Número de fila:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Fila" [(ngModel)]="valueRow" (change)="editRow()" required>
                </div>
            </div>
        </div>
        <div class="btns-save-apply">
            <div class="section-option w-100" (click)="cancelEdit()">
                <p class="m-0">Cancelar</p>
                <i class="fa-light fa-ban"></i>
            </div>
            <div class="section-option w-100" (click)="applyChanges()">
                <p class="m-0">Aplicar</p>
                <i class="fa-light fa-floppy-disk"></i>
            </div>
        </div>
    </div>
</div>