<nav #nav class="header-totem fixed-top nav-expand p-0">
    <div class="header-wrapper d-flex align-items-center justify-content-between w-100">
        <div class="logo pointer-in" [routerLink]="['/home-totem']">
            <img src="assets/img/citylok_horizontal.svg" alt="Citylok">
        </div>

        <div class="navbar-items justify-content-between">
            <div class="d-flex align-items-center content-shopping-badge" [routerLink]="['/cart-checkout']">
                <div [matBadge]="numTicketsCart" matBadgeSize="medium" [matBadgeHidden]="!(numTicketsCart)"
                    matBadgeOverlap="false" class="barge-cart">
                </div>
                <i class="fa-light fa-cart-shopping icon-menu pointer-in fa-lg" [routerLink]="['/cart-checkout']"></i>
            </div>
            <div class="d-flex align-items-center">
                <i class="fa-light fa-circle-info icon-menu pointer-in fa-lg" [routerLink]="['/terms-conditions']"></i>
            </div>
        </div>
    </div>
</nav>