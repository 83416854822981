import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent implements OnInit {

  nombreUser!: string;
  imagen!: string;
  apiMedia: string = AppSettings.MEDIA_URI + '/images/users/';

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.getUsersMe().subscribe(u => {
      this.nombreUser = u.email;
      this.imagen = this.apiMedia+u.imagen;
    });
  }

  logout(){
    this.authenticationService.logout();
  }

}
