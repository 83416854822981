<div class="new-select-places">
    <div class="main-select-places">
        <div>
            <h1 class="title-select-places">Eventos de abono</h1>
            <p class="info-select-places">Los eventos que selecciones se comprarán junto al abono creado.</p>
            <div class="d-flex align-items-center justify-content-between">
                <h2 class="num-places m-0">Eventos ({{(eventsCompany)?.length}})</h2>
                <div class="buscador-place container-form d-flex align-items-center borderInput">
                    <i class="fal fa-search search-icon pointer-in"></i>
                    <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar un evento"
                        (input)="searchEvents(searchInput.value)">
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="container-table">
            <div #containerScrollDialog (scroll)="isScrolledInfinityView($event)">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z28 w-100">
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox [(ngModel)]="allPlaces" class="checkbox" color="primary"
                                (change)="selectAll()"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let event">
                            <mat-checkbox class="checkbox" [checked]="allPlaces || eventSelected.includes(event[0].id)"
                                color="primary" (change)="changeSelectEvent(event[0])"></mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Nombre</th>
                        <td mat-cell *matCellDef="let event">
                            {{event[0].titulo}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dates">
                        <th mat-header-cell *matHeaderCellDef>Fecha</th>
                        <td mat-cell *matCellDef="let event">
                            <i class="fa-light fa-calendar"></i>
                            {{event[0].datesRange.start | date:'dd MMM y' | titlecase }}
                            <span *ngIf="event[0].hourStart"> | {{event[0].hourStart}}</span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="pagination-component">
                    <div class="d-flex align-items-center justify-content-center pagination-panel pagination-table"
                        *ngIf="loadingPage && !finishScroll">
                        <div>
                            <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                                containerClass="loading-box"></ng-lottie>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <mat-divider></mat-divider>
        <div class="footer-new-manager">
            <div class="btn-new btn-create btn-create-border m-0" (click)="goBackNewBond()">
                <p class="m-0">Volver</p>
            </div>
            <div class="btns-confirm">
                <div class="btn-new btn-create btn-create-border m-0" (click)="closeNew()">
                    <p class="m-0">Cancelar</p>
                </div>
                <div class="btn-new btn-create m-0" (click)="createNewBond()"
                    [class.disabled]="eventSelected.length <= 0">
                    <p class="m-0">{{edit ? 'Guardar' : 'Crear' }}</p>
                </div>
            </div>
        </div>
    </div>
</div>