<div style="background-color: white;" class="main-totem" *ngIf="totemActive; else divWeb">
  <app-header-totem></app-header-totem>
  <div>
    <router-outlet></router-outlet>
  </div>
  <app-footer-totem></app-footer-totem>
  <div class="icon-totem-cart" *ngIf="!cartTotem">
    <div class="container-totem-cart" [routerLink]="['/cart-checkout']">
      <div [matBadge]="numTicketsCart" matBadgeSize="small" [matBadgeHidden]="!(numTicketsCart)"
        matBadgeOverlap="false" class="barge-cart">
        <i class="fa-light fa-cart-shopping fa-2x"></i>
      </div>
    </div>
  </div>
</div>
<ng-template #divWeb>
  <div *ngIf="admin; else divPanel">
    <app-home-admin></app-home-admin>
  </div>
  <ng-template #divPanel>
    <div *ngIf="panelAdmin; else divTemplate">
      <app-dashboard-panel></app-dashboard-panel>
    </div>
    <ng-template #divTemplate>
      <app-header *ngIf="!checkout"></app-header>
      <router-outlet></router-outlet>
      <app-footer *ngIf="!checkout"></app-footer>
    </ng-template>
  </ng-template>
</ng-template>