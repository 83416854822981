import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss']
})
export class RememberPasswordComponent implements OnInit {

  emailUser!: any;

  constructor(private dialogRef: MatDialogRef<RememberPasswordComponent>, private userService: UserService) { }

  ngOnInit(): void {
  }

  rememberPassword() {
    this.userService.sendMailCreatePwd(this.emailUser).subscribe({
      next: (data) => {
        this.dialogRef.close({ data: true });
      },
      error: (err) => {
        this.dialogRef.close({ data: false });
      }
    })
  }

}
