<div class="container">
    <div class="faqs-page">
        <p class="h2 faqs-title">Preguntas frecuentes</p>
        <div class="faqs-accordion">
            <mat-accordion class="faq-item" multi>
                <mat-expansion-panel *ngFor="let faq of faqs | async">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ faq.acf.question }}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <div [innerHTML]="faq.acf.descripcion"></div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>