<div class="map-create-event">
    <div *ngIf="!map && loading; else divInfoEvent"
        class="loading-container-panel h-100 w-100 d-flex justify-content-center">
        <div class="container-lottie">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>
    <ng-template #divInfoEvent>
        <div class="conteiner-map-event container-in-page">
            <div>
                <!-- Aforo -->
                <div style="margin-bottom: 30px;" *ngIf="!map">
                    <div class="input-capacity-event">
                        <div class="title-edit">
                            <i class="fa-light fa-seat-airline"></i>
                            <p class="m-0">Aforo del evento</p>
                        </div>
                        <input class="w-100" type="number" placeholder="Indica el aforo del evento (Opcional)"
                            [(ngModel)]="capacity" [max]="max" (ngModelChange)="changeCapacity()" name="link">
                    </div>
                </div>
                <!-- Mapa de butacas -->
                <div>
                    <div>
                        <div class="title-edit">
                            <i class="fa-light fa-chart-tree-map"></i>
                            <p class="m-0">Mapa de butacas</p>
                        </div>
                        <p *ngIf="!map && !openedCreateMap">Para crear un mapa de butacas, por favor, ponte en contacto
                            con los administradores de
                            Citylok.</p>
                        <p *ngIf="map || openedCreateMap">Se utilizará el mapa de butacas del {{venue?.name}}</p>
                    </div>
                    <div class="btns-create" *ngIf="!map">
                        <div class="btn-create btn-bono m-0" [class.disabled]="!map" (click)="createMap()">
                            <i class="fal fa-plus-circle"></i>
                            <p class="m-0">Crear mapa de butacas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-map-venue" *ngIf="map || openedCreateMap">
            <app-create-map [fromCreate]="true" [fromEvent]="editCreateMap" [idEvent]="idEvent" [idMap]="map?.id"></app-create-map>
        </div>
    </ng-template>
</div>