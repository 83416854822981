import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent {

  @Input() subscriptions!: any;
  @Output() emitSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router) { }

  ngOnInit(): void {
    // console.log(this.subscriptions)
  }

  openPlace(place: any) {
    // this.router.navigate(['/venue/', place.slug]);
  }

  cancelSubscription() {
    
  }
}
