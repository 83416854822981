<div class="container-categories-list">
    <div class="title-dialog-edit d-flex justify-content-between align-items-center">
        <p class="text-center m-0">Selecciona una categoria</p>
        <i class="fa-light fa-times m-0 pointer-in" (click)="closeDialog()"></i>
    </div>
    <div *ngIf="loading; else divCategorias">
        <div class="w-100 h-100 d-flex align-items-center justify-content-center">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>
    <ng-template #divCategorias>
        <div>
            <div class="pointer-in" *ngFor="let categoria of categorias" [value]="categoria.id"
                (click)="selectCategory(categoria)">
                <div class="category-option" [class.category-select]="categorySelect.id == categoria.id">
                    <i class="fa-thin {{ categoria.icon }} icon-primary fa-lg"></i>
                    {{categoria.nombre}}
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
    </ng-template>
    <button class="btn-new-form w-100 btn-save" (click)="saveCategory()">Guardar</button>
</div>