import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss']
})
export class DashboardPanelComponent implements OnInit {

  profile!: any;
  tabActive: number = 1;
  titleTab: string = 'Eventos';

  // Rutas
  activeSubmenu: boolean = true;
  routerDetailEvent: boolean = false;
  routerVenues: boolean = false;
  routerCreateEvent: boolean = false;

  constructor(private auth: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.auth.profileStorage.subscribe({
      next: (user) => {
        if (user) {
          this.profile = this.auth.getMyData().pipe();
        }
      }
    });
    var url = this.router.url;
    this.activeSubmenu = url.indexOf('/detail') == -1 ? true : false;
    this.routerDetailEvent = (url.indexOf('/events/detail') == -1 && url.indexOf('/draft/detail') == -1) ? false : true;
    this.routerVenues = url.indexOf('/config/venues/') == -1 ? false : true;
    this.routerCreateEvent = url.indexOf('/events/new-event/') == -1 ? false : true;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        var url = event.url;
        this.activeSubmenu = url.indexOf('/detail') == -1 ? true : false;
        this.routerDetailEvent = (url.indexOf('/events/detail') == -1 && url.indexOf('/draft/detail') == -1) ? false : true;
        this.routerVenues = url.indexOf('/config/venues/') == -1 ? false : true;
        this.routerCreateEvent = url.indexOf('/events/new-event/') == -1 ? false : true;
      }
    });
  }

  changeTab(emit: any) {
    this.tabActive = emit.tab;
    this.titleTab = emit.name;
  }

}
