import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscribable, Subscriber } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ConfigTicketsComponent } from 'src/app/components/new-lok/config-tickets/config-tickets.component';
import { NewTicketComponent } from 'src/app/components/new-lok/new-ticket/new-ticket.component';
import { CreateEventService } from 'src/app/services/create-event.service';
import { EditEventService } from 'src/app/services/edit-event.service';
import { EventosService } from 'src/app/services/eventos.service';
import { FechasService } from 'src/app/services/fechas.service';
import { PlacesService } from 'src/app/services/places.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { __values } from 'tslib';
import { DialogTicketComponent } from '../../dialogs-event/dialog-ticket/dialog-ticket.component';

@Component({
  selector: 'app-tickets-create',
  templateUrl: './tickets-create.component.html',
  styleUrls: ['./tickets-create.component.scss']
})
export class TicketsCreateComponent {

  @Input() idEvent!: any;
  idVenue: any = 1;
  @Input() venue!: any;

  tickets: any[] = [];
  ticketsBase!: any[];

  displayedColumns: string[] = ['actions', 'section', 'price', 'description'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  loaded: boolean = false;
  loadingPage: boolean = true;

  page = 1;
  event!: any;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  @Input() configTickets!: any;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveTickets: EventEmitter<any> = new EventEmitter<any>();
  changing: boolean = false;

  subscriber!: any;
  resumeConfig!: any;

  // Config
  formConfig!: FormGroup;
  typeDatepicker!: string;
  // Start and End Date
  startStringDate!: any;
  endStringDate!: any;
  startDate!: NgbDate;
  endDate!: NgbDate;

  publishDate!: any;
  publishStringDate!: any;

  hourInit!: any;
  minuteInit!: any;
  hourEnd!: any;
  minuteEnd!: any;

  ticketsApi!: any;
  @Input() numTickets: number = 0;
  @Input() loadingTickets: boolean = false;

  // Dates 
  datesInfo!: any;
  hoursInfo!: any;

  constructor(private ticketService: TicketsService, private dialog: MatDialog, private router: Router, public formatter: NgbDateParserFormatter,
    private activatedRoute: ActivatedRoute, private eventService: EventosService, private createService: CreateEventService,
    private fechasService: FechasService, private editEventService: EditEventService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formConfig = this.formBuilder.group({
      startSales: [],
      endSales: [],
      publishDate: [],
      assumeFees: [true],
      needValidation: [false],
      progPublish: [false],
      maxTickets: [],
    });

    this.formConfig.valueChanges.subscribe((value) => {
      this.editConfig(value);
    });

    this.subscriber = this.ticketService.tickets.subscribe({
      next: (res) => {
        console.log('change tickets', res)
        this.ticketsApi = this.ticketService.getTicketsNum();
        if (res && !this.changing) {
          this.loadingPage = true;
          this.tickets = res || [];
          this.getData();
        } else if (!this.changing) {
          this.tickets = null;
          this.getData();
        }
      }
    });

    let infoEvent = this.createService.getInfoEvent();
    if (infoEvent) {
      this.venue = infoEvent.values?.place;
      this.datesInfo = infoEvent.values?.infoDates;
      this.hoursInfo = infoEvent.values?.infoHours;
    }

    let config = this.ticketService.getTicketsConfig();
    if (config) {
      this.configTickets = config;
      if (this.configTickets?.startSales) {
        if (typeof (this.configTickets.startSales) == 'string') {
          this.configTickets.startSales = new Date(this.configTickets.startSales);
        }
        if (typeof (this.configTickets.endSales) == 'string') {
          this.configTickets.endSales = new Date(this.configTickets.endSales);
        }
        this.startStringDate = formatDate(this.configTickets.startSales, 'dd/MM/yyyy', 'es');
        this.hourInit = this.configTickets.startSales.getHours();
        this.minuteInit = this.configTickets.startSales.getMinutes();
      }
      if (this.configTickets?.endSales) {
        this.endStringDate = formatDate(this.configTickets.endSales, 'dd/MM/yyyy', 'es');
        this.hourEnd = this.configTickets.endSales.getHours();
        this.minuteEnd = this.configTickets.endSales.getMinutes();
      }
      if (this.configTickets.publishDate) {
        this.publishStringDate = formatDate(this.configTickets.publishDate, 'dd/MM/yyyy', 'es');
      }
      this.formConfig.setValue(this.configTickets);
    }
  }

  getData() {
    if (this.tickets?.length <= 0 || this.tickets?.length < 20) {
      this.finishScroll = true;
    }
    this.dataSource = new MatTableDataSource(this.tickets);

    this.loaded = true;
    this.loadingPage = false;
  }

  editTickets() {
    this.changing = true;
    this.ticketService.setTicketsVenue(this.tickets);

  }

  openCreateTicket(ticket: any = true, i?: number) {
    let newTicket = null;
    if (ticket != true) {
      newTicket = ticket
    }
    let dates = this.datesInfo ? [this.datesInfo] : null;
    let levels = this.tickets?.filter(value => value.isSection);
    const dialogRef = this.dialog.open(NewTicketComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { ticket: newTicket, venue: this.venue, levels: levels, map: this.venue?.mainMap?.id, dates: dates, hours: this.hoursInfo }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.tickets) {
          this.tickets = [];
        }
        result.priceTickets.forEach(element => {
          let ticket = {
            name: result.nombre,
            description: result.description,
            specialTime: result.specialTime,
            activo: true,
            dateInit: result.dateInit,
            dateEnd: result.dateEnd,
            price: null,
            level: null,
            open: true,
            ticketsMin: result.ticketsMin,
            ticketsMax: result.ticketsMax,
            priceTickets: result.priceTickets,
            numTickets: this.numTickets,
            onlySubscribers: result.onlySubscribers,
            typeBond: result.typeBond,
            selectedSpecialDates: result.selectedSpecialDates,
            selectedSpecialHours: result.selectedSpecialHours
          };
          if (!element.level) {
            ticket.price = element.price;
          } else {
            ticket.level = element.level;
            ticket.price = element.price;
          }
          if (newTicket) {
            this.tickets[i] = ticket;
          } else {
            this.tickets.push(ticket);
          }
        });
        if(!newTicket) {
          this.numTickets++;
        }
      }
      this.tickets?.sort((a, b) => b.level - a.level);
      this.changing = false;
      this.ticketService.setTicketsVenue(this.tickets);
      this.ticketService.setTicketsNum(this.numTickets);
    });
  }

  deleteTicket(ticket: any, i: any) {
    this.tickets.splice(i, 1);
    this.numTickets--;
    this.ticketService.setTicketsVenue(this.tickets);
    this.ticketService.setTicketsNum(this.numTickets);
  }

  saveDate(event: any) {
    if (event) {
      const date = new Date(this.formatter.format(event));
      date.setHours(0);
      date.setMinutes(0);
      if (this.typeDatepicker == 'start') {
        this.startDate = event;
        this.formConfig.controls['startSales'].setValue(date);
        this.startStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'end') {
        this.endDate = event;
        this.formConfig.controls['endSales'].setValue(date);
        this.endStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'auto') {
        this.publishDate = event;
        this.formConfig.controls['publishDate'].setValue(date);
        this.publishStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
    }
    this.saveConfig();
    this.typeDatepicker = null;
  }

  saveConfig() {
    if (this.hourInit && this.minuteInit != null) {
      const dateInit = this.formConfig.value.startSales;
      if (dateInit) {
        dateInit.setHours(this.hourInit);
        dateInit.setMinutes(this.minuteInit);
        this.formConfig.value.startSales = dateInit;
      }
    }

    if (this.hourEnd && this.minuteEnd != null) {
      const dateEnd = this.formConfig.value.endSales;
      if (dateEnd) {
        dateEnd.setHours(this.hourEnd);
        dateEnd.setMinutes(this.minuteEnd);
        this.formConfig.value.endSales = dateEnd;
      }
    }
  }

  openDatePicker(type: string) {
    if (this.typeDatepicker) {
      this.typeDatepicker = null;
      return;
    }
    this.typeDatepicker = type;
  }

  changeHourInit(event: any) {
    this.hourInit = event;
    this.saveConfig();
  }

  changeMinuteInit(event: any) {
    this.minuteInit = event;
    this.saveConfig();
  }

  changeHourEnd(event: any) {
    this.hourEnd = event;
    this.saveConfig();
  }

  changeMinuteEnd(event: any) {
    this.minuteEnd = event;
    this.saveConfig();
  }

  editConfig(payload: any) {
    this.ticketService.setTicketsConfig(payload);
  }

  closeTicketsLevel(ticket) {
    this.tickets.forEach(value => {
      if (value.level == ticket.id) {
        value.open = !value.open
      }
    });
  }

  nextStep() {
    let step = { tab: 3, name: 'Mapa de butacas', slug: 'map-event' };
    // let step = { tab: 3, name: 'Descuentos/abonos', slug: 'discount-event' };
    this.changeTab.emit(step);
  }

  changePrice(ticket: any) {
    this.editTickets();
  }

  ngOnDestroy() {
    // this.subscriber.unsubscribe();
  }
}
