import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, Input, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-update-general-values',
  templateUrl: './update-general-values.component.html',
  styleUrls: ['./update-general-values.component.scss']
})
export class UpdateGeneralValuesComponent {

  changeForm: FormGroup;
  valueChange: any;
  valueInit: any;
  nameValue: any;
  editHashtags: boolean = false;
  hashtags: any[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  constructor(private formBuilder: FormBuilder, private placeService: PlacesService,
    private dialogRef: MatDialogRef<UpdateGeneralValuesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.nameValue = this.data['name'];
    this.valueChange = this.data['valueChange'];
    this.valueInit = this.data['value'];
    this.hashtags = [].concat(this.valueInit.hashtags) || [];
    this.changeForm = this.formBuilder.group({
      titulo: [this.valueInit.titulo, Validators.required],
      hashtags: [this.valueInit.hashtags],
      extraLink: [this.valueInit.extraLink],
    });
  }

  get value() { return this.changeForm.get(this.valueChange); }

  changeEvent() {
    if (this.changeForm.valid) {
      const value = this.changeForm.value;
      this.dialogRef.close({ status: 'changed', value: value[this.valueChange] });
    }
  }

  removeHashtag(item: string): void {
    const index = this.hashtags.indexOf(item);
    if (index >= 0) {
      this.hashtags.splice(index, 1);
      this.changeForm.controls['hashtags'].setValue(this.hashtags);
    }
  }

  addHashtag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.hashtags.push(value);
      this.changeForm.controls['hashtags'].setValue(this.hashtags);
    }
    event.chipInput!.clear();
  }
}
