import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  getNotificationsUser() {
    const url = `${ AppSettings.API_URI }/prefer_notifications/usuario`;
    return this.http.get<any>( url ).pipe(
      map(data => {
        return data;
      }
    ));
  }

  setNotifications(idNotif: any, form: any) {
    const url = `${ AppSettings.API_URI }/prefer_notifications/${idNotif}`;
    return this.http.put<any>( url, form ).pipe(
      map(data => {
        return data;
      }
    ));
  }

  getSubscriptionsUser() {
    const url = `${ AppSettings.API_URI }/subscriptions_places/user`;
    return this.http.get<any>( url ).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

}
