import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { GooglePlacesService } from 'src/app/services/google-places.service';
import { MapsService } from 'src/app/services/maps.service'
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent implements OnInit {

  lugar: string = '';
  textLocation: string = '';
  location!: any;
  @Input() online: boolean = false;
  @Input() activeSites: boolean = false;
  profile: any = {
    site: true
  };

  @Output() saveLocation = new EventEmitter<any>();
  @Output() saveOnline = new EventEmitter<any>();

  searchResults: any[] = [];
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  place!: any;
  urlImagePlace: string = AppSettings.MEDIA_URI + '/images/users/';
  placeList: any[] = [];
  urlImagesVenues: string = AppSettings.MEDIA_URI + '/images/venues/';
  fromPanel: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data, private placeService: PlacesService,
    @Optional() private dialogRef: MatDialogRef<MapSearchComponent>, private googlePlacesService: GooglePlacesService
  ) { }

  ngOnInit(): void {
    this.googlePlacesService.openMap();
    this.googlePlacesService.mapsLocatorStorage.subscribe({
      next: (data) => {
        if (data) {
          this.lugar = data;
          this.location = data;
        }
      }
    });
    let business = JSON.parse(localStorage.getItem('business') || '{}');
    if (business) {
      this.placeService.getPlacesUser(business.id).subscribe((data) => {
        this.placeList = data;
        if (this.data && this.data?.place) {
          let place = this.placeList.find(value => value.id == this.data?.place.id);
          this.place = place;
          this.activeSites = true;
          this.online = false;
        }
      });
    }
    if (this.data) {
      this.fromPanel = this.data?.fromPanel;
    }
  }

  ngAfterViewInit() {
    if (this.data) {
      this.textLocation = this.data.localizacion;
      this.onSelectTextPlace(this.textLocation);
    }
  }

  onSearchPlaces() {
    if (this.lugar) {
      this.googlePlacesService.searchPlaces(this.lugar).then((results) => {
        this.searchResults = results;
      });
    } else {
      this.searchResults = [];
    }
  }

  onSelectPlace() {
    this.googlePlacesService.selectPlace(this.lugar);
  }

  onSelectTextPlace(text: string) {
    this.googlePlacesService.searchPlaces(text).then((results) => {
      this.autocomplete.closePanel();
      this.lugar = results[0];
      this.onSelectPlace();
    });
  }

  displayFn(location: any): string {
    if (location) {
      return location.description ? location.description : location.formatted_address;
    }
    return '';
  }

  save() {
    const object = {
      online: this.online,
      location: this.location,
      activeSites: this.activeSites,
      place: this.place
    }
    if (this.fromPanel) {
      this.dialogRef.close(object);
      return;
    }
    if (this.location) {
      this.saveLocation.emit(this.location);
      if (this.data) {
        this.dialogRef.close(this.location);
      }
    }
    if (this.online) {
      this.saveOnline.emit(this.online);
      if (this.data) {
        this.dialogRef.close(this.location);
      }
    }
  }

}
