import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPanelComponent } from './header-panel/header-panel.component';
import { DetailEventPanelComponent } from './detail-event-panel/detail-event-panel.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { OrdersPanelComponent } from './orders-panel/orders-panel.component';
import { DetailOrderPanelComponent } from './detail-order-panel/detail-order-panel.component';
import { FormsModule } from '@angular/forms';
import { AdminModule } from '../admin/admin.module';
import { InfoEventComponent } from '../control-panel/events/detail-event/info-event/info-event.component';
import { DetailEventComponent } from './detail-event/detail-event.component';
import { DiscountsEventComponent } from './discounts-event/discounts-event.component';
import { LottieModule } from 'ngx-lottie';
// import { NgFallimgModule } from 'ng-fallimg';



@NgModule({
  declarations: [
    DetailEventPanelComponent,
    HeaderPanelComponent,
    OrdersPanelComponent,
    DetailOrderPanelComponent,
    DetailEventComponent,
    DiscountsEventComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    AdminModule,
    LottieModule,
  ],
  exports: [
    HeaderPanelComponent,
  ]
})
export class PanelAdminModule { }
