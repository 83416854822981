import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { StaticService } from 'src/app/services/static.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  previousRoute = '';
  redirectMsg = '';
  errorLogin: boolean = false;
  fromSeats: boolean = false;
  loadingLogin: boolean = false;

  constructor(
    private staticService: StaticService,
    private router: Router,
    private location: Location,
    private auth: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.seats) {
      this.fromSeats = true;
    }
    this.previousRoute = this.staticService.getFrom();
    switch (this.previousRoute) {
      case 'subir':
        this.redirectMsg =
          '¡Inicia sesión o regístrate para poder subir tu propio lok!';
        break;
      case 'favoritos':
        this.redirectMsg =
          '¡Inicia sesión o regístrate para tener acceso a tus loks favoritos!';
        break;
      case 'cuenta':
        this.redirectMsg =
          '¡Inicia sesión o regístrate para poder acceder a tu cuenta!';
        break;
      default:
        break;
    }
    this.staticService.removeFrom();
  }

  getRedirect(event: any) {
    this.redirectMsg = event;
  }

  getErrorLogin(event: any) {
    this.errorLogin = event;
    this.loadingLogin = false;
  }

  goRegister(event: any) {
    if (event) {
      this.router.navigate(['/signup']);
    }
  }

  validate() {
    this.loadingLogin = false;
  }

  loadingForm() {
    if (!this.fromSeats) {
      this.loadingLogin = true;
    }
  }

}
