<form [formGroup]="registerForm" enctype="application/x-www-form-urlencoded">
    <!-- <div class="bloque-register container-form d-flex align-items-center" [style.background-color]="backgroundColor">
        <i class="fa-regular fa-circle-user icon-primary"></i>
        <input #username class="w-100" type="text" placeholder="Nickname" formControlName="nickname" required
        [class.invalid]="username.invalid && (username.dirty || username.touched)">
    </div> -->
    <div class="bloque-register container-form d-flex align-items-center register-input" [style.background-color]="backgroundColor">
        <i class="fa-regular fa-user icon-primary"></i>
        <input class="w-100" type="text" placeholder="Nombre" formControlName="nombre" required
        [class.invalid]="pass.invalid && (pass.dirty || pass.touched)">
    </div>
    <div class="bloque-register container-form d-flex align-items-center register-input" [style.background-color]="backgroundColor">
        <i class="fa-regular fa-envelope icon-primary"></i>
        <input class="w-100" type="email" placeholder="Email" formControlName="email" required
        [class.invalid]="pass.invalid && (pass.dirty || pass.touched)">
    </div>
    <div class="bloque-register container-form d-flex align-items-center register-input" 
        [class.invalid]="pass.invalid && (pass.dirty || pass.touched)"
        [style.background-color]="backgroundColor">
        <i class="fa-regular fa-key icon-primary"></i>
        <input class="w-100" type="{{ hide ? 'password' : 'text' }}" placeholder="Contraseña" formControlName="textPassword" required>
        <i (click)="hide = !hide" class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
    </div>

    <div class="register-input validate-msg" *ngIf="pass.invalid && (pass.dirty || pass.touched)">
        <div class="d-flex align-items-center">
            <i class="fa-light {{ pass.errors?.['hasSmallCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
            <p>Debe contener minúsculas.</p>
        </div>
        <div class="d-flex align-items-center">
            <i class="fa-light {{ pass.errors?.['hasCapitalCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
            <p>Debe contener mayúsculas.</p>
        </div>
        <div class="d-flex align-items-center">
            <i class="fa-light {{ pass.errors?.['hasNumber'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
            <p>Debe contener números.</p>
        </div>
        <div class="d-flex align-items-center">
            <i class="fa-light {{ pass.errors?.['minlength'] || pass.errors?.['required'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
            <p>Debe contener al menos 8 caracteres.</p>
        </div>
        <p *ngIf="pass.errors?.['maxlength']">No debe tener más de 30 caracteres.</p>
    </div>
    <div class="bloque-register container-form d-flex align-items-center register-input" [style.background-color]="backgroundColor">
        <i class="fa-regular fa-key icon-primary"></i>
        <input class="w-100" type="{{ hideConfirm ? 'password' : 'text' }}" placeholder="Confirmar contraseña" formControlName="confirmPassword" required>
        <i (click)="hideConfirm = !hideConfirm" class="fa-regular {{ hideConfirm ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
    </div>
    <div class="register-input validate-msg" *ngIf="(repPass.dirty || repPass.touched) && registerForm.errors?.['passwordNotMatch']">
        <p *ngIf="registerForm.errors?.['passwordNotMatch']">Las contraseñas no coinciden.</p>
    </div>
    <div class="d-flex register-input align-items-end">
        <mat-checkbox formControlName="condaccept" class="checkbox" color="primary" required>  
        </mat-checkbox>
        <p class="text-checkbox m-0">
            Acepto los <a class="link"  target="_blank" [routerLink]="['/terms-conditions']"> términos y condiciones de uso.</a>
        </p>
    </div>
    <div class="d-flex register-input align-items-start">
        <mat-checkbox formControlName="newsletter" class="checkbox" color="primary">
        </mat-checkbox>
        <p class="text-checkbox m-0">
            También quiero recibir correos electrónicos ocasionales sobre eventos y noticias de tu interés. (Puedo cancelar la suscripción en cualquier momento).
        </p>
    </div>
    <button name="button" class="btn btn-register" [disabled]="registerForm.invalid" (click)="register()">{{ textBtn == 'next' ? 'Siguiente' : 'Regístrarme' }}</button>
    <div class="divider">
        <div class="line"><mat-divider></mat-divider></div>
        <div class="text-divider">o </div>
        <div class="line"><mat-divider></mat-divider></div>
    </div>

    <div class="container-social d-flex align-items-center justify-content-center pointer-in">
        <div class="google-btn d-flex justify-content-center" #customBtn>
            <asl-google-signin-button text="signup_with" (onclick)='signInWithG()'  type='standard' size='medium'></asl-google-signin-button>      
        </div>
        <div class="btn-google-sign bloque-register google-container" [style.background-color]="backgroundColor">
            <img fallimg src="/assets/img/google_icon.png" alt="Google">
            <span>Registrarse con Google</span>
        </div>
    </div>

    <!-- <div class="bloque-register container-social d-flex align-items-center 
    justify-content-center pointer-in" [style.background-color]="backgroundColor">
        <i class="fa-brands fa-facebook icon-facebook"></i>
        <span>Regístrate con Facebook</span>
    </div> -->
    <p class="info-policy">Al {{ textBtn == 'next' ? 'pulsar en siguiente' : 'registrarme' }} entiendo y acepto que Citylok recopile, procese y conserve mis datos de acuerdo a la
        <a class="link" target="_blank" [routerLink]="['/privacy']"> Política de privacidad de Citylok.</a></p>
    <p class="info-register m-0 mt-2">¿Ya tienes una cuenta? <a class="link" (click)="goLogin.emit(true)"> Inicia sesión.</a></p>
</form>