<div class="header-panel d-flex align-items-center justify-content-between">
    <p class="title-panel m-0">{{titlePanel}}</p>
    <div class="d-flex align-items-center items-header-panel">
        <p class="name-profile m-0">Hola <span class="name-link">{{(profile|async)?.nombre}}</span>, Admin.
            {{(profile|async)?.sitio?.nombre}}</p>
        <mat-divider class="divider-panel" [vertical]="true"></mat-divider>
        <div class="icon-header" [routerLink]="['/profile/edit']">
            <i class="fal fa-user-circle icon-panel icon-primary pointer-in fa-lg"></i>
            <p class="m-0">Cambiar</p>
        </div>
        <div class="icon-header" (click)="logout()">
            <i class="fal fa-arrow-right-from-bracket icon-panel icon-primary pointer-in fa-lg"></i>
            <p class="m-0">Salir</p>
        </div>
    </div>
</div>