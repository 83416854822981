import { StaticService } from './../../../services/static.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { NewLokComponent } from '../../new-lok/new-lok/new-lok.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  optioncityOpen: boolean = false;
  optionuserOpen: boolean = false;
  optionlegalOpen: boolean = false;
  optioncontactOpen: boolean = false;

  isLogin: boolean = false;
  now: Date = new Date();

  constructor(private staticService: StaticService, private router: Router, private auth: AuthenticationService,
    private dialog: MatDialog) {
      const usr = this.auth.getProfile();
      if(usr) {this.isLogin=true}
    // this.auth.getUsersMe().subscribe({
    //   next: result => {if(result.email) {this.isLogin=true}},
    //   error: err => this.isLogin = false
    // });
  }

  ngOnInit(): void {}

  redirect(from: string, page: string) {
    if(!this.isLogin) {
      this.staticService.setFrom(from);
    }
    if(page == 'new') {
      if(this.isLogin) {
        const dialogRef = this.dialog.open(NewLokComponent, {
          panelClass: 'new-lok-dialog',
          disableClose: true
        })
      } else {
        this.router.navigate(['/login']);
      }
    } else {
      this.router.navigate([`/${page}`]);
    }
  }

  downloadZIP() {
    const url = AppSettings.MEDIA_URI+'/kit-prensa.zip';
    window.open(url, '_blank');
  }

  openSocialLink(type: string) {
    let url = '';
    if(type == 'twitter') {
      url = 'https://twitter.com/citylok';
    }
    if(type == 'instagram') {
      url = 'https://www.instagram.com/citylokapp/';
    }
    if(type == 'youtube') {
      url = 'https://www.youtube.com/@citylokapp';
    }
    if(type == 'facebook') {
      url = 'https://www.facebook.com/Citylokapp';
    }
    window.open(url, '_blank')
  }
}
