<div class="new-list-maps">
    <div class="d-flex btns-new-map align-items-center"
        [class]="editEvent ? 'justify-content-between' : 'justify-content-end'">
        <div *ngIf="editEvent" class="icon-close" mat-dialog-close>
            <i class="fa-regular fa-arrow-left"></i>
        </div>
        <!-- <button class="btn primary-button solid" (click)="goToCreate()">Crear nuevo mapa</button> -->
    </div>

    <div class="overflow-list-map">
        <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="!(listMaps | async)">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>

        <div class="info-create-map" *ngIf="listMaps | async">
            <p class="m-0">
                <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Puedes utilizar uno de
                los
                mapas ya creados o crear uno nuevo. (Podrás editar los tipos de asientos y butacas bloqueadas en cada
                evento).
            </p>
        </div>

        <div class="card-map-list">
            <!-- Map Item -->
            <mat-radio-group [(ngModel)]="idSelect">
                <div *ngFor="let item of listMaps | async">
                    <div class="option-map">
                        <mat-radio-button [value]="item.id" (change)="select(item)">
                            <span class="title-name-map">{{item.name}}</span>
                        </mat-radio-button>
                        <i class="fa-regular fa-eye" (click)="openImgMap(item)"></i>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </mat-radio-group>
        </div>
    </div>

    <!-- Seleccionar mapa -->
    <button class="btn-new-form btn-save w-100" (click)="saveSelect()">GUARDAR</button>
</div>