<div class="router-event">
    <div class="d-flex align-items-end justify-content-between">
        <h1 class="title-detail-event m-0">Crear evento</h1>
        <div class="d-flex align-items-center link-open-event" (click)="openLinkEvent()">
            <i class="fal fa-eye pointer-in"></i>
            <span>Previsualizar en Citylok</span>
        </div>
    </div>
    <mat-divider class="divider-title"></mat-divider>
    <div class="d-flex info-panel page-panel">
        <app-menu-create [idEvent]="event" (changeTab)="changeMenuEvent($event)"></app-menu-create>
        <div class="margin-steps">
            <div class="card-white-panel" [class.card-step]="(step|async) != 'info-event'">
                <div class="d-flex align-items-center justify-content-between w-100 title-section">
                    <div class="title-menu-page">
                        <h2>Paso 1: Información del evento</h2>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="validInfo">
                        <i class="fal fa-check-circle icon-primary"></i>
                    </div>
                </div>
                <div *ngIf="(step|async) == 'info-event'">
                    <mat-divider></mat-divider>
                    <app-create-event (changePlaceEmit)="changePlace($event)" (changeTab)="changeMenuEvent($event)"></app-create-event>
                </div>
            </div>
            <div class="card-white-panel" [class.card-step]="(step|async) != 'ticket-event'">
                <div class="d-flex align-items-center justify-content-between w-100 title-section">
                    <div class="title-menu-page">
                        <h2>Paso 2: Venta de entradas</h2>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="validTickets">
                        <i class="fal fa-check-circle icon-primary"></i>
                    </div>
                </div>
                <div *ngIf="(step|async) == 'ticket-event'">
                    <mat-divider></mat-divider>
                    <app-tickets-create [loadingTickets]="loadingTickets" [numTickets]="numTickets" (changeTab)="changeMenuEvent($event)"></app-tickets-create>
                </div>
            </div>
            <!-- <div class="card-white-panel" [class.card-step]="(step|async) != 'discount-event'">
                <div class="d-flex align-items-center justify-content-between w-100 title-section">
                    <div class="title-menu-page">
                        <h2>Paso 3: Descuentos/abonos</h2>
                    </div>
                    <div class="d-flex align-items-center">
                        <i class="fal fa-check-circle icon-primary"></i>
                    </div>
                </div>
                <div *ngIf="(step|async) == 'discount-event'">
                    <mat-divider></mat-divider>
                    <app-discounts-create (changeTab)="changeMenuEvent($event)"></app-discounts-create>
                </div>
            </div> -->
            <div class="card-white-panel" [class.card-step]="(step|async) != 'map-event'">
                <div class="d-flex align-items-center justify-content-between w-100 title-section">
                    <div class="title-menu-page">
                        <h2>Paso 3: Aforo/Mapa de butacas</h2>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                        <i class="fal fa-check-circle icon-primary"></i>
                    </div> -->
                </div>
                <div *ngIf="(step|async) == 'map-event'">
                    <mat-divider></mat-divider>
                    <app-map-seats-create [venue]="venue" [useMapVenue]="useMapVenue" [capacity]="venue?.capacity" [idEvent]="idEvent"></app-map-seats-create>
                </div>
            </div>
            <div class="buttons-wrapper">
                <div class="btn-create btn-create-border m-0" (click)="saveEvent()">
                    <i class="fal fa-save"></i>
                    <p class="m-0">Guardar cambios</p>
                </div>
                <div class="btn-create btn-publish m-0" [class.btn-disabled]="!validInfo" (click)="saveEvent(true)">
                    <i class="fal fa-paper-plane-top"></i>
                    <p class="m-0">Publicar evento</p>
                </div>
            </div>
        </div>
    </div>
</div>