import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AreasSeatService } from 'src/app/services/areas-seat.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-level-types',
  templateUrl: './level-types.component.html',
  styleUrls: ['./level-types.component.scss']
})
export class LevelTypesComponent implements OnInit {

  @Input() levels: any[] = [];
  @Output() deleteLevel = new EventEmitter<any>();
  defaultColors: any[] = [];

  @Input() assignedSeats: any[] = [];
  @Input() selectedSeats: any[] = [];
  @Input() capacity: number = 0;
  @Input() fromCreate!: boolean;
  @Output() changeAssigned = new EventEmitter<any>();
  @Output() levelAssigned = new EventEmitter<any>();

  levelEdit!: any;
  levelSeats!: any;
  enabledClickBtns: boolean = true;

  constructor(private areasSeatService: AreasSeatService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.defaultColors = this.areasSeatService.getDefaultColors();
  }

  levelAssignActive(level: any) {
    this.levelSeats = level;
    this.enabledClickBtns = false;
    this.levelAssigned.emit(level);
  }

  assignSeats(result: any) {
    this.enabledClickBtns = true;
    this.levelSeats = null;
    this.changeAssigned.emit(result);
  }

  closeEdit() {
    this.levelEdit = null;
  }

  editItem(level: any, index: number) {
    this.levelEdit = this.levelEdit != null ? null : level;
  }

  deleteItem(item: any) {
    const verifySeats = this.verifyNotSeats(item);
    const verifyTickets = this.verifyNotTickets(item);
    if(verifySeats && verifyTickets) {
      this.deleteLevel.emit(item);
    }
  }

  verifyNotSeats(item: any) {
    const verify = !item.seats || (item.seats != null && item.seats <= 0) ? true : false;
    if(!verify) {
      this.openInfo('¡No puedes eliminar un nivel con asientos asignados!');
    }
    return verify;
  }

  verifyNotTickets(item: any) {
    const verify = !item.tickets || (item.tickets.length <= 0) ? true : false;
    if(!verify) {
      this.openInfo('¡No puedes eliminar un nivel con entradas asignadas, debes eliminarlas antes!');
    }
    return verify;
  }

  openInfo(text: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: true,
        text: text,
        btnCancel: false
      }
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

}
