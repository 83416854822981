<div class="d-grid justify-content-center conditions-info">
    <div class="container d-flex justify-content-center content-img-conditions">
        <img fallimg src="assets/img/citylok_logo.png" alt="Citylok logo">
    </div>
    <div *ngIf="text; else divTermsPage">
        <p class="text-info-card" [innerHTML]="text"></p>
    </div>
    <ng-template #divTermsPage>
        <app-terms-conditions [openDialog]="true"></app-terms-conditions>
    </ng-template>
    <div class="align-items-center d-grid" style="column-gap: 16px;">
        <button class="primary-button" [class.primary-totem]="totemActive" [mat-dialog-close]="true">Aceptar</button>
    </div>
</div>