<div class="page-info" *ngIf="event">
    <div class="information-event">
        <!-- <div class="btns-extra-actions">
        <div class="action-report pointer-in" (click)="downloadReport()">
            <i class="fal fa-chart-line icon-primary"></i>
            <p class="m-0">Descargar informe</p>
        </div>
        <div class="action-orders pointer-in" *ngIf="profile?.admin">
            <i class="fal fa-square-list"></i>
            <p class="m-0">Ver pedidos del evento</p>
        </div>
    </div> -->
        <div class="div-info">
            <!-- Datos del evento -->
            <div class="category-wrapper d-flex align-items-center justify-content-between " (click)="openCategories()">
                <div>
                    <i style="color: var(--color-primary); margin-right: 10px;" [ngClass]="event.categoria?.icon "
                        class="fal"></i>
                    {{event?.categoria?.nombre}}
                </div>
                <i style="color: --color-grey;" class="fal fa-pen pointer-in" *ngIf="allowCreate"></i>
            </div>
            <!-- Abonos -->
            <div *ngIf="abonos?.length > 0">
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Abonos disponibles</p>
                </div>
                <div class="">
                    <p class="m-0 title-event">{{abonos[0]?.name}}</p>
                </div>
            </div>
            <!-- Titulo -->
            <div>
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Título</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate"
                        (click)="openEditGeneral('titulo', 'Título')"></i>
                </div>
                <div class="">
                    <p class="m-0 title-event">{{event?.titulo}}</p>
                </div>
            </div>
            <!-- Descripcion -->
            <div>
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Descripción</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate" (click)="openDescription()"></i>
                </div>
                <div class="section-description-event">
                    <p style="text-align: justify;" [innerHTML]="event.descripcion"></p>
                </div>
            </div>

            <!-- Lugar -->
            <div>
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Lugar</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate && allowCitylok" (click)="openEditPlace()"></i>
                </div>
                <!-- <span *ngIf="event.place">Para cambiar el espacio, por favor, ponte en contacto con nosotros. -->
                <!-- <span class="open-help pointer-in" (click)="openHelp()">aquí.</span> -->
                <!-- </span> -->

                <div class="place-wrapper d-flex align-items-center" style="margin-bottom: 16px;" *ngIf="event.place">
                    <div class="img-wrapper">
                        <img src="{{urlImageVenue+event.place.id+'/'+event.place.image}}">
                    </div>
                    <h3>{{event.place.name}}</h3>
                </div>
                <p class="m-0 title-event" *ngIf="event.place">Localización:</p>
                <div class="section-description-event">
                    <p>{{event.localizacion}}</p>
                </div>
            </div>

            <!-- Fecha y hora -->
            <div class="date-wrapper">
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Fecha y hora</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate && allowCitylok" (click)="openDates()"></i>
                </div>
                <div class="date-event">
                    <i class="fal fa-calendar"></i>
                    <p class="m-0">{{event.datesRange?.start | date:'dd MMM y' | titlecase }} 
                    <span *ngIf="event.datesRange?.end && event.datesRange?.start!=event.datesRange?.end"> - </span>
                    <p class="m-0" *ngIf="event.datesRange?.end && event.datesRange?.start!=event.datesRange?.end">{{event.datesRange?.end | date:'dd MMM y' | titlecase }}</p>
                </div>
                <div class="date-event" *ngIf="event?.hourStart">
                    <i class="fal fa-clock"></i>
                    <div *ngFor="let hour of event?.horarios; let i = index">
                        <p class="m-0"><span class="hour-event">{{hour.horaInicio | date:'HH:mm':'+0000'}}</span></p>
                    </div>
                </div>
            </div>

            <!-- Configuración -->
            <div class="col-info-event col-12 hashtags">
                <div class="date-wrapper">
                    <div class="d-flex align-items-center title-edit">
                        <p class="m-0">Configuración</p>
                        <i class="fal pointer-in" [class]="editConfigEvent?'fa-save':'fa-pen'" [class.icon-save]="editConfigEvent" *ngIf="allowCreate" (click)="editConfig()"></i>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="privateEvent" [disabled]="!editConfigEvent">
                            Evento privado</mat-checkbox>
                    </div>
                    <div class="code-wrapper" *ngIf="privateEvent" style="margin: 10px 0px;">
                        <input class="w-100" [(ngModel)]="accessCodeSales" type="text" [disabled]="!editConfigEvent"
                            placeholder="Código de acceso" name="code" required>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="informativo"
                            [disabled]="onlyTpvSales || external || !editConfigEvent">
                            Evento informativo</mat-checkbox>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="onlyTpvSales"
                            [disabled]="external ||informativo || !editConfigEvent">
                            Solo venta en taquilla</mat-checkbox>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="external"
                            [disabled]="onlyTpvSales || informativo || !editConfigEvent">
                            Evento con venta externa</mat-checkbox>
                    </div>
                    <div class="code-wrapper" *ngIf="external" style="margin: 10px 0px;">
                        <input class="w-100" [(ngModel)]="url" type="text" placeholder="URL Externa" [disabled]="!editConfigEvent"
                            name="url" required>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="hiddenEvent"
                            [disabled]="!editConfigEvent">
                            Evento oculto</mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="col-info-event col-12 hashtags">
                <div class="date-wrapper">
                    <div class="d-flex align-items-center title-edit">
                        <i class="fa-light fa-money-bill-trend-up"></i>
                        <p class="m-0">Facturación</p>
                        <i class="fal pointer-in" [class]="editBillingEvent?'fa-save':'fa-pen'" [class.icon-save]="editBillingEvent" *ngIf="allowCreate" (click)="editConfigBilling()"></i>
                    </div>
                    <!-- <p class="m-0 title-section-create"><i class="fa-light fa-money-bill-trend-up"></i>Facturación</p> -->
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="externalBilling" [disabled]="!editBillingEvent">Facturar a nombre de otra empresa</mat-checkbox>
                    </div>
                    <div class="code-wrapper" *ngIf="externalBilling" style="margin: 10px 0px;">
                        <input class="w-100" [(ngModel)]="cifBusiness" type="text" placeholder="CIF*" [disabled]="!editBillingEvent"
                            name="cif" required>
                    </div>
                    <div class="code-wrapper" *ngIf="externalBilling" style="margin: 10px 0px;">
                        <input class="w-100" [(ngModel)]="nameBusiness" type="text" placeholder="Nombre de empresa*" [disabled]="!editBillingEvent"
                            name="name" required>
                    </div>
                </div>
            </div>

            <div>
                <div class="d-flex align-items-center title-edit">
                    <i class="fal fa-link"></i>
                    <p class="m-0">Link asociado</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate"
                        (click)="openEditGeneral('extraLink', 'Link asociado')"></i>
                </div>
                <div>
                    <a class="m-0 link-extra" *ngIf="event.extraLink" href="{{event?.extraLink}}"
                        target="_blank">{{event?.extraLink}}</a>
                </div>
            </div>

            <div class="col-info-event col-12 link">
                <div class="link-wrapper">
                    <div class="d-flex align-items-center title-edit">
                        <i class="fal fa-image"></i>
                        <p class="m-0">Carrusel de fotos</p>
                        <i class="fal pointer-in" [class]="editExtraImages?'fa-save':'fa-pen'" [class.icon-save]="editExtraImages" *ngIf="allowCreate" (click)="clickEditExtraImages()"></i>
                    </div>
                    <!-- <p class="m-0 title-section-create"><i class="fa-light fa-link"></i>Carrusel de fotos</p> -->
                    <div class="container-extra-images">
                        <div class="images-wrap" *ngIf="event?.extraImages?.length > 0">
                            <div class="content-img-extra" *ngFor="let item of extraImages; let i = index">
                                <div class="item-img-extra">
                                    <img *ngIf="item.image" [src]="urlImages+event?.id+'/extra/'+item.image" alt="Img extra" />
                                    <img *ngIf="item.cropped" [src]="item.cropped" alt="Img extra" />
                                </div>
                                <div class="circle-delete-image" *ngIf="editExtraImages" (click)="deleteExtraImg(i)">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                        <input id="extraImg" #extraImg hidden type="file" accept="image/*" (change)="onExtraImg($event)">
                        <div class="upload-file-style" (click)="extraImg.click()" *ngIf="editExtraImages">
                            <i class="fal fa-arrow-up-from-bracket"></i>
                            <span>Subir fotos</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Carrusel de fotos -->
            <!-- <div class="carousel-wrapper">
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Carrusel de fotos</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate"></i>

                </div>
                <div class="carousel-list w-100">
                    <div class="carousel-img">
                        <div class="carousel-item" *ngFor="let img of event.imagenes">
                            <img fallimg [src]="img">
                        </div>

                    </div>
                </div>
            </div> -->

            <!-- Archivo Extra -->
            <div>
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Archivo descargable</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate" (click)="fileextra.click()"></i>
                </div>
                <div class="d-flex align-items-center file-upload">
                    <div class="upload-file-style" *ngIf="allowCreate" (click)="fileextra.click()">
                        <i class="fal fa-arrow-up-from-bracket"></i>
                        <span>Subir archivo</span>
                    </div>
                    <input id="fileextra" #fileextra hidden type="file" accept="*/application"
                        (change)="onExtraFile($event)">
                    <a [href]="extraFileControl.value" *ngIf="extraFileControl.value" target="_blank">Vista previa</a>
                </div>
                <p class="date-event" style="margin-top: 16px;">*El archivo debe ser un documento en pdf.</p>
            </div>

            <!-- SEO - Metaetiquetas -->
            <!-- <div class="d-flex align-items-center title-edit">
                <p class="m-0">Datos del evento</p>
                <i class="fal fa-pen pointer-in"></i>
            </div> -->
            <!-- Hashtags -->
            <div>
                <div class="d-flex align-items-center title-edit">
                    <p class="m-0">Hashtags</p>
                    <i class="fal fa-pen pointer-in" *ngIf="allowCreate"
                        (click)="openEditGeneral('hashtags', 'Hashtags')"></i>
                </div>
                <div class="grid-chips">
                    <div class="chip-hashtag" *ngFor="let item of event.hashtags">
                        #{{item}}
                    </div>
                </div>
            </div>

        </div>
        <div class="div-img row ">
            <div class="col-12 d-flex justify-content-center title-image">
                <p class="m-0"><i style="margin-right:6px" class="fa-light fa-image"></i>Foto de portada*</p>
            </div>
            <div class="col-12 d-flex justify-content-center pointer-in" style="position: relative;"
                [style]="allowCreate ? '' : 'display: none;'" (click)="file.click()">
                <div style="position: relative;">
                    <div class="edit-btn d-flex justify-content-center align-items-center " *ngIf="allowCreate">
                        <i class="fal fa-pen"></i>
                    </div>
                    <div class="not-image-event">
                        <i class="fa-thin fa-image" *ngIf="!imgControl.value"></i>
                        <img class="img-event" [src]="imgControl.value" alt="Img user" *ngIf="imgControl.value" />
                    </div>
                    <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
                </div>
            </div>
        </div>
    </div>
    <div class="footer-info" *ngIf="allowCreate">
        <mat-divider></mat-divider>
        <div class="d-flex align-items-center btns-desactive" (click)="inactiveEvent()">
            <span>Eliminar evento</span>
            <!-- <span>Eliminar evento</span> -->
        </div>
    </div>
</div>