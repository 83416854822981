<div class="page-discounts-create">
    <p class="info-ticket-venue" *ngIf="venue">Se aplican los tipos de descuentos del {{venue?.name}}</p>

    <div>
        <p class="title-tab">Descuentos para este evento</p>
        <p>Estos abonos serán únicamente los anuales, si quieres crear un abono conjunto con otros eventos lo podrás hacer desde la sección Abonos o desde el panel de Eventos.</p>
    </div>

    <div>
        <p class="title-tab">Abonos anuales</p>
        <p>Estos abonos serán únicamente los anuales, si quieres crear un abono conjunto con otros eventos lo podrás hacer desde la sección Abonos o desde el panel de Eventos.</p>
    </div>

    <div>
        <p class="title-tab">Descuentos base del {{venue?.name}}</p>
        <p>Descuentos generales del espacio.</p>
    </div>

    <div class="btn-create btn-next-step m-0" [routerLink]="['/panel-control/events/new-event/map-event']" (click)="nextStep()">
        <p class="m-0">Siguiente paso</p>
        <i class="fal fa-arrow-right"></i>
    </div>
</div>