import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-discounts-venue',
  templateUrl: './discounts-venue.component.html',
  styleUrls: ['./discounts-venue.component.scss']
})
export class DiscountsVenueComponent {
  @Input() venue!: any;

  discountsPlace!: any[];

  constructor() { }

  ngOnInit(): void {
  }

  openCreateDiscount() {

  }

  openDiscountPlace() {
    
  }
}
