<div *ngIf="!venue; else divInfoBusiness" class="loading-container-panel h-100 w-100 d-flex justify-content-center">
    <div class="container-lottie">
        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
            containerClass="loading-box"></ng-lottie>
    </div>
</div>
<ng-template #divInfoBusiness>
    <div class="info-business">
        <div class="d-flex flex-wrap conteiner-info-venue">
            <div class="container-in-page col-8">
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Nombre del espacio</p>
                        <i class="fal fa-pen pointer-in" (click)="openUpdateVenue('name', 'Nombre')"></i>
                    </div>
                    <div>
                        <p class="m-0 content-business">{{venue?.name}}</p>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Localización</p>
                        <i class="fal fa-pen pointer-in" (click)="openUpdateVenue('location', 'Localización')"></i>
                    </div>
                    <div>
                        <p class="m-0 content-business">{{venue?.location}}</p>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Descripción del espacio</p>
                        <i class="fal fa-pen pointer-in" (click)="openUpdateVenue('description', 'Descripción')"></i>
                    </div>
                    <div>
                        <p class="m-0 content-business">{{venue?.description}}</p>
                    </div>
                </div>
            </div>
            <div class="col-4 col-image-business">
                <div class="d-flex align-items-center justify-content-center" style="gap: 10px; margin-bottom: 5px;">
                    <p class="title-image m-0">FOTO DE PORTADA*</p>
                    <i class="fas fa-circle-info icon-primary pointer-in"
                        matTooltip="Será la imágen que se muestre en la vista del espacio."></i>
                </div>
                <div class="container-image" (click)="file.click()">
                    <div class="not-image-business">
                        <i class="fa-thin fa-image" *ngIf="!imgControl.value"></i>
                        <img [src]="imgControl.value" alt="Img user" *ngIf="imgControl.value" />
                    </div>
                    <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
                    <div class="circle-add-image">
                        <i class="fa-light fa-plus"></i>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="container-in-page">
            <div class="col-info-business col-12">
                <div class="d-flex align-items-center title-info-business">
                    <mat-checkbox [disabled]="true" class="pointer-in" [(ngModel)]="venue.allowSubscription"
                        (change)="updateAllowSubscribers()"></mat-checkbox>
                    <p class="m-0 disabled-title">Permitir suscriptores</p>
                </div>
            </div>
            <div class="col-12" *ngIf="venue.allowSubscription">
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Nombre de subscriptores</p>
                        <i class="fal fa-pen pointer-in"
                            (click)="openUpdateVenue('membershipTitle', 'Descripción')"></i>
                    </div>
                    <div>
                        <p class="m-0 content-business">{{venue?.membershipTitle || membershipTitle}}</p>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Texto de suscripción</p>
                        <i class="fal fa-pen pointer-in"
                            (click)="openUpdateVenue('subscriptionText', 'Descripción')"></i>
                    </div>
                    <div>
                        <p class="m-0 content-business">{{venue?.subscriptionText || subscriptionText}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <mat-divider></mat-divider>
        <div class="container-in-page">
            <div class="btn-create m-0" (click)="updateFile()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Subir programación</p>
            </div>
        </div> -->
    </div>
</ng-template>