<div class="venues-panel">
    <div class="btns-filters">
        <div class="content-search col-4">
            <div class="search-container container-form d-flex align-items-center borderInput search-full">
                <i class="fal fa-search search-icon pointer-in" (click)="searchVenue(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar espacio"
                    [(ngModel)]="searchText" (input)="searchVenue(searchInput.value)">
            </div>
        </div>
        <div class="btns-create">
            <div class="btn-create m-0" (click)="openCreateVenue()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Crear espacio</p>
            </div>
        </div>
    </div>

    <div *ngIf="loadingPage" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>

    <div #containerScroll class="table-venues table-panel multiple-lines" *ngIf="loaded"
        (scroll)="isScrolledInfinityView($event)">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let venue" (click)="openVenue(venue.id)">
                    <!-- <i class="fal fa-ellipsis-vertical icon-option"></i> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Espacio</b></th>
                <td mat-cell *matCellDef="let venue" (click)="openVenue(venue.id)">
                    <img class="image-table" src="{{urlImages+venue.id+'/'+venue.image}}">
                    <span class="link-name">{{venue.name}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Descripción</b></th>
                <td mat-cell *matCellDef="let venue" (click)="openVenue(venue.id)">
                    <span>{{venue.description}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="location">
                <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Localización</b></th>
                <td mat-cell *matCellDef="let venue" (click)="openVenue(venue.id)">
                    <div class="d-flex align-items-center" style="gap:10px;">
                        <i class="fal fa-map-marker-alt"></i>
                        <span>{{venue.location}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="capacity">
                <th mat-header-cell *matHeaderCellDef><b>Aforo</b></th>
                <td mat-cell *matCellDef="let venue" (click)="openVenue(venue.id)">
                    <span>{{venue.capacity || '-'}}</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="pagination-component">
            <div class="d-flex align-items-center pagination-panel pagination-table" *ngIf="!loadingPage && !finishScroll">
                <div>
                    <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                        containerClass="loading-box"></ng-lottie>
                </div>
            </div>
        </div>
    </div>

    <p *ngIf="loaded && !loadingPage && venues?.length <= 0" style="text-align: center; margin-top: 80px;">No hay
        espacios disponibles</p>
</div>