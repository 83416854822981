<div class="aboutus-page">
    <div class="header-about">
        <div class="header-content w-100">
            <div class="container d-flex justify-content-between">
                <div class="header-info col-lg-8 col-xl-6">
                    <p class="title title-aboutus">¿Qué es citylok?</p>
                    <div class="what-citylok">
                        <p>Citylok es el mayor compilador de planes, ocio y eventos geolocalizados. Una agenda de ocio
                            personalizada cuya misión es informar sobre lo que ocurre aquí y ahora o en cualquier
                            momento y lugar del mundo, a través de una experiencia de usuario única. </p>
                        <p>Una herramienta tecnológica al servicio de los promotores, con una plataforma de business
                            intelligence que gracias el big data gestiona la información y convierte a Citylok en el
                            primer compilador de eventos mundial. </p>
                    </div>
                    <button class="primary-button btn-cityblog w-100" (click)="openTodayEvents()">¿Qué vas a hacer
                        hoy?</button>
                </div>
                <div class="header-circles container">
                    <div class="parrallax smile-circle first-smile">
                        <div class="border-smile"></div>
                    </div>
                    <div class="parrallax big-circle">
                        <div class="parrallax smile-circle second-smile">
                            <div class="border-smile"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="whats-lok container">
        <h1>¿Qué puedo descubrir en Citylok?</h1>
        <p class="info-lok">Todos los eventos están en <strong>Citylok</strong>. Eventos deportivos, gastronómicos,
            culturales,
            musicales, empresariales, etc. <br>Cualquier plan tiene cabida en esta plataforma. Aquí encontrarás
            contenidos interesantes y sorprendentes.
        </p>
        <h2 class="m-0">Siempre actualizado</h2>
        <div class="smile-small-primary">
            <div class="parrallax smile-circle first-smile">
                <div class="border-smile"></div>
            </div>
        </div>
    </div>
    <div class="cards-business container">
        <div class="flex-cards">
            <div class="item-card" *ngFor="let item of businessImg">
                <img src="../../../assets/img/business/{{item}}" />
            </div>
        </div>
    </div>
    <div class="whats-lok container">
        <h1>¿Qué es un lok?</h1>
        <p class="info-lok">LOK es el término que utilizamos para denominar los eventos que aparecen en Citylok. Los
            LOKs se estructuran en función del contenido en distintas categorías.</p>
    </div>
    <div class="circles-about-us">
        <div hidden #circlesInfo class="content-circles">
            <p class="h2" style="color: var(--color-primary);">¿Cómo publicar un LOK?</p>
            <p class="info-lok">Tan sencillo como subir una fotografía o hacerla directamente desde la app,
                acompañarla de un pequeño texto, elegir la categoría (o categorías) en las que se enmarca,
                fechar el evento el calendario y situarlo en el mapa. ¿Y si me equivoco? ¡No pasa nada! Siempre puedes
                editar un LOK.</p>
            <p class="h2" style="color: var(--color-primary);">¿Qué son los LOK infinitos?</p>
            <p class="info-lok">Son publicaciones que perduran en el tiempo, no tienen caducidad. Por tanto,
                el usuario siempre puede acceder a ese contenido. No tienen fecha, pero sí están geolocalizados.
                Suele tratarse de publicaciones de lugares físicos interesantes que suben los usuarios a la aplicación.
            </p>
            <p class="h2" style="color: var(--color-primary);">Perfil de usuario</p>
            <p class="info-lok">Los usuarios sois la base de esta aplicación. Subís contenido a Citylok e interactuáis
                con otros usuarios.
                Puedes editar tu perfil, cambiar la foto de usuario o el nombre. Además puedes dar a favorito a los LOKs
                de otros usuarios, además de comentarlos</p>
        </div>
        <app-info-loks [divHtml]="circlesInfo"></app-info-loks>

    </div>
    <div class="loks-dest">
        <app-cards title="Loks destacados" [arrow]="false" [events]="loksDestacados"></app-cards>

    </div>
</div>