import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-menu-create',
  templateUrl: './menu-create.component.html',
  styleUrls: ['./menu-create.component.scss']
})
export class MenuCreateComponent {

  submenuActive: any = 1;
  titleTab: string = 'Información del evento';
  urlPath: string = '/panel-control/events/new-event';
  urlParam: any = '';
  @Input() idEvent: any = '';
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.idEvent) {
      this.urlParam = '/'+this.idEvent;
    }
  }

  changeTabEvent(emit: any) {
    this.submenuActive = emit.tab;
    this.titleTab = emit.name;
    this.changeTab.emit(emit);
  }

}
