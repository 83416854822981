import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageWpService } from 'src/app/services/page-wp.service';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  contentPage!: SafeHtml;

  constructor(private pageWpService: PageWpService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.pageWpService.getStaticBySlug('aviso-legal').subscribe({
      next: (res) => {
        let data = res[0];
        this.contentPage = this.sanitizer.bypassSecurityTrustHtml(
          data.content.rendered
        );
      }
    })
  }

}
