import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-link-email',
  templateUrl: './link-email.component.html',
  styleUrls: ['./link-email.component.scss']
})
export class LinkEmailComponent {

  emailUser!: any;
  ticketPrint!: any;
  idPayment!: any;

  constructor(private dialogRef: MatDialogRef<LinkEmailComponent>, private checkoutService: CheckoutService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.idPayment = data.idPayment;
      this.ticketPrint = data.ticketPrint;
    }

  ngOnInit(): void {
  }

  sendTicket() {
    const payload = {
      email: this.emailUser,
      idPayment: this.idPayment,
      codePdf: this.ticketPrint['hash']
    };
    this.checkoutService.linkTicketToUser(payload).subscribe({
      next: (data) => { 
        this.dialogRef.close({ data: true });
      },
      error: (err) => {
        this.dialogRef.close({ data: false });
      }
    })
  }

}
