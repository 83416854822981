<div class="container">
  <div class="container-suggestions">
    <p class="h2 title-suggestions">Envíanos tu sugerencia o queja</p>
    <app-warning *ngIf="!sent" text="Tu sugerencia se enviará de forma anónima."></app-warning>
    <app-warning *ngIf="!sent && error" text="Comprueba todos los campos."></app-warning>
    <form [formGroup]="suggestionsForm" enctype="application/x-www-form-urlencoded" class="card-suggestions" *ngIf="!sent">
      <div class="bloque-suggestions container-form d-flex align-items-center">
        <i class="fa-regular fa-envelope icon-primary"></i>
        <input class="w-100" type="text" placeholder="Nombre (opcional)" formControlName="name" required="false" />
      </div>
      <div class="bloque-suggestions container-form d-flex align-items-center password-input">
        <i class="fa-light fa-comment-question icon-primary"></i>
        <input class="w-100" type="text" placeholder="Asunto" formControlName="subject" />
      </div>

      <div Class="container-select-border-contact">
        <mat-form-field class="problem-select w-100" appearance="fill">
          <mat-select-trigger matPrefix style="vertical-align: middle;">
              <i class="fa-light fa-comments-question"></i>        
          </mat-select-trigger>
          <mat-select #categorySelect formControlName="problem" class=" text-black select-placeholder"
            placeholder="¿Qué necesitas?">
            <mat-option value="Sugerencia">Sugerencia</mat-option>
            <mat-option value="Queja">Queja</mat-option>
          </mat-select>
          <mat-select-trigger matSuffix style="vertical-align: middle;">
              <i class="fa-light fa-angle-down pointer-in"></i>
          </mat-select-trigger>
        </mat-form-field>
      </div>

      <div class="card-reply">
        <div class="container-form d-flex align-items-center">
          <textarea #textarea class="w-100" type="textarea" formControlName="body"
            placeholder="Por favor, danos más información" maxlength="2500"></textarea>
        </div>
      </div>

      <button type="button" name="button" class="btn btn-suggestions" (click)="send()">Enviar</button>
    </form>
    <app-warning *ngIf="sent" text="¡Gracias! Tu mensaje ha sido enviado correctamente." backgroundColor="#FFFFFF"
      icon="fa-check"></app-warning>
  </div>
</div>