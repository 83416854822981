import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { PlacesService } from 'src/app/services/places.service';
import { UpdateVenueComponent } from '../../update-venue/update-venue.component';

@Component({
  selector: 'app-info-venue',
  templateUrl: './info-venue.component.html',
  styleUrls: ['./info-venue.component.scss']
})
export class InfoVenueComponent {

  @Input() venue!: any;
  urlImages: string = AppSettings.MEDIA_URI + '/images/venues/';
  imgControl: FormControl = new FormControl(null);
  changedImg: boolean = false;

  business = JSON.parse(localStorage.getItem('business') || '{}');

  allowSubscriptions: boolean = false;
  membershipTitle: string = 'Amigos de la casa';
  subscriptionText: string = 'Subscribete a nuestro plan y disfruta de los beneficios que tenemos para ti';

  constructor(private placeService: PlacesService, private dialog: MatDialog, private router: Router) { }

  ngAfterViewInit(): void {
    const place = this.placeService.getPlaceSaved();
    this.venue = place;
    if (this.venue?.image) {
      this.imgControl.setValue(this.urlImages + this.venue.id + '/' + this.venue.image);
    }
    if (!this.venue) {
      let url = location.pathname.split('/');
      let idVenue = +url[url.length - 1];
      this.getVenue(idVenue);
      this.router.events
        .subscribe((event: any) => {
          if (event instanceof NavigationEnd) {
            var url = event.url;
            let idVenue = +url[url.length - 1];
            this.getVenue(idVenue);
          }
        });
    }
  }

  getVenue(idVenue: any) {
    if (idVenue) {
      this.placeService.getPlaceById(idVenue).subscribe({
        next: (res: any) => {
          this.venue = res;
          if (this.venue?.image) {
            this.imgControl.setValue(this.urlImages + this.venue.id + '/' + this.venue.image);
          }
        }
      });
    }
  }

  onFileChange(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (!file.type.match('image.*')) {
        alert('Solo se permite utilizar imagenes');
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        filePath = reader.result as string;
        this.imgControl.setValue(filePath);
        this.changedImg = true;
        this.updateFile();
      }
    }
    if (!event.target.files) {
      if (this.venue.imagen) {
        this.imgControl.setValue(this.urlImages + this.venue.id + '/' + this.venue.image);
      }
    }
  }

  updateFile() {
    this.placeService.updateImage(this.venue.id, { file: this.imgControl.value }).subscribe({
      next: (res) => {
        this.openInfoCard('La imagen se ha actualizado correctamente', false, false);
        this.changedImg = false;
      }, error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al intentar actualizar la imagen', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  openUpdateVenue(valueToChange: any, nameValue: string) {
    const dialogRef = this.dialog.open(UpdateVenueComponent, {
      panelClass: 'info-dialog',
      data: {
        valueChange: valueToChange,
        name: nameValue,
        value: this.venue
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res?.status == 'changed') {
          this.venue = res.venue;
          this.openInfoCard('Los cambios se han guardado correctamente', false, false);
        }
        if (res?.status == 'error') {
          this.openInfoCard('¡Vaya! Ha ocurrido un error.');
        }
      }
    })
  }

  saveSubscribersOptions() {
    const dialogRef = this.dialog.open(UpdateVenueComponent, {
      panelClass: 'info-dialog',
      data: {
        value: this.venue
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res == 'changed') {
          this.openInfoCard('Los cambios se han guardado correctamente', false, false);
        }
        if (res == 'error') {
          this.openInfoCard('¡Vaya! Ha ocurrido un error.');
        }
      }
    })
  }
}
