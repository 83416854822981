import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { NewLokComponent } from '../../new-lok/new-lok/new-lok.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  profile!: any;
  loading: any;
  urlImages: string = AppSettings.MEDIA_URI + '/users';

  city: string = 'Barcelona';

  constructor(private auth: AuthenticationService, private dialog: MatDialog,
    private userService: UserService) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    if (localStorage.getItem('jwt_token')) {
      this.auth.getUsersMe().subscribe({
        next: data => {
          if (data.email) {
            this.userService.getUserbyId(data.id).subscribe(
              user => { this.profile = user }
            )
          }
        },
        error: err => { this.loading = true },
        complete: () => this.loading = true
      });
    }
  }

  newLok() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(NewLokComponent, {
      panelClass: 'new-lok-dialog',
      disableClose: true
    });
  }

  changeCity() {

  }

  logout() {
    this.auth.logout();
  }

}
