import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { saveAs } from 'file-saver';
import { formatDate, getLocaleDayNames } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EventosService } from 'src/app/services/eventos.service';
import { FormControl } from '@angular/forms';
import { UpdateGeneralValuesComponent } from '../../dialogs-event/update-general-values/update-general-values.component';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { MatDialog } from '@angular/material/dialog';
import { DescriptionLokComponent } from 'src/app/components/new-lok/description-lok/description-lok.component';
import { TicketsComponent } from 'src/app/components/new-lok/tickets/tickets.component';
import { DatesHoursComponent } from 'src/app/components/new-lok/dates-hours/dates-hours.component';
import { CropperImageComponent } from 'src/app/components/bloques/cropper-image/cropper-image.component';
import { CategoriesListComponent } from 'src/app/components/bloques/categories-list/categories-list.component';
import { EditEventService } from 'src/app/services/edit-event.service';
import { FechasService } from 'src/app/services/fechas.service';
import { ContactPanelComponent } from '../../../config/contact-panel/contact-panel.component';
import { MapSearchComponent } from 'src/app/components/new-lok/map-search/map-search.component';
import { CiudadesService } from 'src/app/services/ciudades.service';

@Component({
  selector: 'app-info-event',
  templateUrl: './info-event.component.html',
  styleUrls: ['./info-event.component.scss']
})
export class InfoEventComponent implements OnInit {

  @Input() profile!: any;
  @Input() idEvent!: any;
  event!: any;
  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';
  urlImageVenue: string = AppSettings.MEDIA_URI + '/images/venues/';

  place: any = { name: "Teatro Apolo", img: "/assets/img/teatro-apolo.png" };
  icon: string = "fa";

  imgControl: FormControl = new FormControl(null);
  extraFileControl: FormControl = new FormControl(null);
  extraImages: any = [];
  editExtraImages: boolean = false;

  changedImg: boolean = false;

  horarios!: any;
  fechas!: any;
  entradas!: any;

  croppedImage: any = '';
  eventInit: any;
  abonos: any[] = [];

  allowCreate: any = false;
  allowCitylok: boolean = false;
  editConfigEvent: boolean = false;
  editBillingEvent: boolean = false;

  privateEvent!: any;
  accessCodeSales!: any;
  informativo!: any;
  onlyTpvSales!: any;
  external!: any;
  hiddenEvent!: any;
  url!: any;

  // External
  externalBilling!: any;
  cifBusiness!: any;
  nameBusiness!: any;

  constructor(private auth: AuthenticationService, private router: Router, private editEventService: EditEventService,
    private eventosService: EventosService, private activatedRoute: ActivatedRoute, private dialog: MatDialog,
    private fechasService: FechasService, private ciudadService: CiudadesService) { }

  ngOnInit(): void {
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS') {
        this.allowCreate = true;
      }
    });
    this.allowCitylok = this.auth.getProfile()?.citylok;
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.eventosService.getEventByIdAdmin(this.idEvent).subscribe({
          next: (data) => {
            if (!data) {
              this.router.navigate(['/panel-control/events/list']);
            }
            this.event = data;
            this.abonos = data.abonos;
            this.event.fechas = data.fechas?.filter((value) => value.activo);
            this.event.categoria['icon'] = "fa-masks-theater";
            this.eventInit = JSON.parse(JSON.stringify(this.event));
            if (this.event?.imageurl) {
              this.imgControl.setValue(this.urlImages + this.event.id + '/' + this.event.imageurl);
            }
            if (this.event.extraFile) {
              this.extraFileControl.setValue(this.urlImages + this.event.id + '/' + this.event.extraFile);
            }
            this.horarios = data.horarios.filter((value: any) => value.activo);
            this.fechas = data.fechas.filter((value: any) => value.activo);
            this.entradas = data.entradas.filter((value: any) => value.activo);

            this.privateEvent = this.event.privateEvent;
            this.accessCodeSales = this.event.accessCodeSales;
            this.informativo = this.event.informativo;
            this.onlyTpvSales = this.event.onlyTpvSales;
            this.external = this.event.external;
            this.hiddenEvent = this.event.hiddenEvent;
            this.url = this.event.url;
            this.externalBilling = this.event.externalBilling;
            this.cifBusiness = this.event.cifBusiness;
            this.nameBusiness = this.event.nameBusiness;
            this.extraImages = this.event.extraImages;
          }
        });
      }
    });
    this.profile = this.auth.getProfile();
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    let filePath = '';
    if (event.target.files && event.target.files.length) {
      const dialogRef = this.dialog.open(CropperImageComponent, {
        panelClass: 'info-dialog',
        data: event
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.eventosService.putEventoImage(result.object.base64, this.event.id).subscribe({
            next: (err) => {
              this.croppedImage = result.pathUrl;
              this.imgControl.setValue(result.pathUrl);
              this.changedImg = true;
            }
          })
        }
      });

      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          filePath = reader.result as string;
        };
      }
    }
    if (!event.target.files) {
      if (this.event.imagen) {
        this.imgControl.setValue(this.urlImages + this.event.id + '/' + this.event.imageurl);
      }
    }
  }

  onExtraFile(event: any) {
    const reader = new FileReader();
    let filePath = '';
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('text.*|application.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var docSrc = reader.result as string;
          filePath = reader.result as string;
          this.event.extraFile = docSrc;
          this.extraFileControl.setValue(filePath);
          this.editEventService.editExtraFile(this.event.id, { file: filePath }).subscribe({
            next: (err) => {
              this.openInfoCard('Documento subido correctamente', false);
            }
          });
        };
      } else {
        this.openInfoCard('El archivo debe ser un documento', true);
      }
    }
    if (!event.target.files) {
      if (this.event.extraFile) {
        this.extraFileControl.setValue(this.urlImages + this.event.id + '/' + this.event.extraFile);
      }
    }
  }

  clickEditExtraImages() {
    if (this.editExtraImages) {
      // Editar imagenes extra
      let mapImg = this.extraImages.map((value) => value.id).filter(val => val);
      let newItems = this.extraImages.filter(val => val.file);
      let payload = {
        new: newItems,
        ids: mapImg
      }
      this.eventosService.putExtraImagesByEvent(this.event.id, payload).subscribe({
        next: (res) => {
          this.openInfoCard('Los cambios se han guardado correctamente.', false, false);
          this.event.extraImages = res.extraImages;
        },
        error: (err) => {
          this.openInfoCard('¡Vaya! Ha ocurrido un error al guardar los datos, vuelve a intentarlo más tarde.', false, true);
          this.extraImages = this.event?.extraImages;
        }
      })
    }
    this.editExtraImages = !this.editExtraImages;
  }

  onExtraImg(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size >= 20000000) {
        this.openInfoCard('La imagen no puede ocupar más de 2 Mb.');
      } else {
        const dialogRef = this.dialog.open(CropperImageComponent, {
          panelClass: 'info-dialog',
          data: { img: event, extra: true }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            let croppedImage = result.pathUrl;
            this.extraImages.push({ file: result.object.base64, cropped: croppedImage });
          }
        });
      }
    }
  }

  deleteExtraImg(item: number) {
    this.extraImages.splice(item, 1);
  }

  openEditPlace() {
    const dialogRef = this.dialog.open(MapSearchComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { place: this.event.place, localizacion: this.eventInit.localizacion, fromPanel: true }
    });
    dialogRef.afterClosed().subscribe(event => {
      if (event) {
        const obj = { active_place: event.activeSites, place: event.place?.id };
        let place = event.activeSites ? event.place : null;
        this.event.place = place;
        this.event.localizacion = event.location.formatted_address;
        let locateEvent = {
          latitude: event.location.geometry.location.lat(),
          longitude: event.location.geometry.location.lng(),
          localizacion: this.event.localizacion,
          ciudad: null
        }
        const objectCity = event.location.address_components.find(value => value.types.includes('locality'));
        if (objectCity) {
          this.ciudadService.getAllCitieByName(objectCity.long_name).subscribe(res => {
            if (res.length > 0) {
              locateEvent.ciudad = res[0]['@id'];
            }
          });
        }
        this.editPlace(obj, locateEvent);
      }
    });
  }

  editPlace(data: any, location: any) {
    this.eventosService.editLocationEvent(this.event.id, data).subscribe({
      next: (result) => {
        for (let key in data) {
          this.event[key] = result[key];
        }
        this.editEvent(location);
      }, error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al editar los campos, vuelve a intentarlo más tarde.', false, true);
      }
    });
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true, close: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
    if (close) {
      dialogRef.afterClosed().subscribe({
        next: (res) => {
          this.router.navigate(['/panel-control/events']);
        }
      })
    }
  }

  openDescription() {
    const dialogRef = this.dialog.open(DescriptionLokComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { descripcion: this.event.descripcion }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.event.descripcion = result;
        this.editEvent({ descripcion: result });
      }
    });
  }

  editEvent(data: any) {
    this.eventosService.putEventos(this.event.id, data).subscribe({
      next: (result) => {
        for (let key in data) {
          this.event[key] = result[key];
        }
        this.editConfigEvent = false;
        // Editar evento en api
        this.eventosService.putEventBilling(this.event.id, {}).subscribe({
          next: (res) => {
            this.openInfoCard('Los cambios se han guardado correctamente.', false, false);

          }
        })
      }, error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al guardar los datos, vuelve a intentarlo más tarde.', false, true);
      }
    });
  }

  openDates() {
    let auxHours = this.getOptions();
    const dialogRef = this.dialog.open(DatesHoursComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { dates: this.fechas, hours: this.horarios, auxArr: auxHours, map: this.event.mapEvent?.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fechas[0].activo = false;
        this.fechas.unshift(result.fecha);
        let fechaInicio = new Date(result.fecha.fechaInicio);
        this.event.fechaInicio = fechaInicio;
        this.event.datesRange.start = fechaInicio;
        if (result.fecha.fechaFin) {
          let fechaFin = new Date(result.fecha.fechaFin);
          this.event.fechaFin = fechaFin;
          this.event.datesRange.end = fechaFin;
        }
        this.horarios = result.horarios;
        this.editDatesHours({ dates: this.fechas, hours: this.horarios });
      }
    });
  }

  editDatesHours(payload: any) {
    this.fechasService.editDatesHours(this.eventInit.id, payload).subscribe({
      next: (data) => {
        this.eventInit.fechaInicio = this.event.fechaInicio;
        this.eventInit.datesRange.start = this.event.fechaInicio;
        this.eventInit.fechaFin = this.event.fechaFin;
        this.eventInit.datesRange.end = this.event.fechaFin;
        this.openInfoCard('Los cambios se han guardado correctamente.', false, false);
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  publishEvent() {
    this.eventosService.putEventos(this.eventInit.id, { draft: false, activo: true }).subscribe({
      next: (res) => {
        this.eventInit.draft = res.draft;
        this.eventInit.activo = res.activo;
        this.eventInit.activo = res.activo;
        this.eventInit.draft = res.draft;
      }
    });
  }

  getOptions() {
    let auxHours = null;
    if (this.horarios && this.horarios.length > 0) {
      if (this.horarios[0].fixedSchedule) {
        auxHours = this.horarios;
      }
      if (!this.horarios[0].fixedSchedule) {
        let hours = [];
        this.horarios.forEach(hour => {
          let arr = hour.dayWeek.length > 0 ? hour.dayWeek : hour.dayMonth;
          arr.forEach(element => {
            const date = new Date(this.fechas[0].fechaInicio);
            let elHour = hours.findIndex((val) => val.day == date);
            if (elHour != -1) {
              hours[elHour].times.push(hour);
            }
            if (elHour == -1) {
              hours.push({ day: date, times: [hour] });
            }
          });
        });
        auxHours = hours;
      }
    }
    return auxHours;
  }

  openCategories() {
    if (this.allowCreate) {
      const dialogRef = this.dialog.open(CategoriesListComponent, {
        panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
        data: { categorySelect: this.event.categoria }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.editCategory(result);
        }
      });
    }
  }

  editCategory(payload: any) {
    this.editEventService.editCategory(this.event.id, payload).subscribe({
      next: (data) => {
        this.event.categoria = payload[0];
        this.eventInit.categoria = payload[0];
        this.openInfoCard('Los cambios se han guardado correctamente.', false, false);
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  openEditGeneral(valueToChange: any, nameValue: string) {
    const dialogRef = this.dialog.open(UpdateGeneralValuesComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: {
        valueChange: valueToChange,
        name: nameValue,
        value: this.event
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res?.status == 'changed') {
          this.event[valueToChange] = res.value;
          this.eventInit[valueToChange] = res.value;
          this.editEvent({ [valueToChange]: res.value });
        }
      }
    })
  }

  inactiveEvent() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: true,
        text: '¿Estás seguro de que desea eliminar el evento?',
        warning: true,
        btnCancel: true
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.eventosService.putEventos(this.event.id, { activo: false, draft: false }).subscribe({
          next: (data) => {
            this.event.activo = false;
            this.openInfoCard('Los cambios se han guardado correctamente.', false, false, true);
          },
          error: () => {
            this.openInfoCard('¡Vaya! Algo ha fallado, vuelve a intentarlo más tarde.', true, false);
          }
        })
      }
    });
  }

  openHelp() {
    const dialogRef = this.dialog.open(ContactPanelComponent, {
      panelClass: 'info-dialog',
      data: {
        email: this.profile.email
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res?.code == '200') {
          this.openInfoCard('Gracias por contactar con nosotros. En un plazo máximo de 48 horas recibirás una respuesta.', false, false);
        }
        if (res == 'error') {
          this.openInfoCard('¡Vaya! No se ha podido mandar el mensaje. Por favor, inténtalo de nuevo más tarde.');
        }
      }
    })
  }

  editConfig() {
    if (this.editConfigEvent) {
      this.saveConfig();
    } else {
      this.editConfigEvent = !this.editConfigEvent;
    }
  }

  saveConfig() {
    this.editConfigEvent = !this.editConfigEvent;
    let payload = {
      privateEvent: this.privateEvent,
      accessCodeSales: this.accessCodeSales,
      informativo: this.informativo,
      onlyTpvSales: this.onlyTpvSales,
      external: this.external,
      hiddenEvent: this.hiddenEvent,
      url: this.url
    }
    this.editEvent(payload);
  }

  editConfigBilling() {
    if (this.editBillingEvent && this.externalBilling && (!this.cifBusiness || !this.nameBusiness)) {
      this.openInfoCard('Debes introducir el cif y nombre de la empresa', false, true);
    } else {
      if (this.editBillingEvent) {
        let payload = {
          externalBilling: this.externalBilling,
          cifBusiness: this.externalBilling ? this.cifBusiness : null,
          nameBusiness: this.externalBilling ? this.nameBusiness : null
        }
        this.editEvent(payload);
      }
      this.editBillingEvent = !this.editBillingEvent;
    }
  }

}
