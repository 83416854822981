import { Component, Inject, Optional } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-name',
  templateUrl: './change-name.component.html',
  styleUrls: ['./change-name.component.scss']
})
export class ChangeNameComponent {

  changeForm: FormGroup;
  nameUser: any;

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private auth: AuthenticationService, private dialogRef: MatDialogRef<ChangeNameComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.nameUser = this.data.name;
    this.changeForm = this.formBuilder.group({
      name: [this.nameUser, Validators.required]
    });
  }

  get name() { return this.changeForm.get('name'); }

  changeName() {
    if(this.changeForm.valid) {
      const name = this.changeForm.value.name;
      this.userService.putUser(this.auth.user, {nombre: name}).subscribe({
        next: (data) => {
          this.dialogRef.close({status: 'changed', name: name});
        },
        error: (err) => {
          this.dialogRef.close({status: 'error'});
        }
      })
    }
  }

}

