<div class="d-grid justify-content-center card-info">
    <i *ngIf="icon; else divLogo" class="fa-thin fa-circle-info"></i>
    <ng-template #divLogo>
        <img fallimg src="assets/img/citylok_logo.png" alt="Citylok logo">
    </ng-template>
    <p class="text-info-card">
        {{ text }}
    </p>
    <div class="align-items-center" style="column-gap: 16px;" 
        [class]="btnCancel ? 'd-flex justify-content-between' : 'd-grid'"
        [class.warningBtns]="warning">
        <button [class]="warning ? 'primary-button' : 'cancel-button'" *ngIf="btnCancel" [mat-dialog-close]="false">Cancelar</button>
        <button [class]="warning ? 'cancel-button' : 'primary-button'" [mat-dialog-close]="true">Aceptar</button>
    </div>
</div>