import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-hashtags-event',
  templateUrl: './hashtags-event.component.html',
  styleUrls: ['./hashtags-event.component.scss']
})
export class HashtagsEventComponent {

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  editHashtags: boolean = false;
  @Input() hashtags: any[] = [];

  @Output() saveHashtags: EventEmitter<any> = new EventEmitter<any>();

  removeHashtag(item: string): void {
    const index = this.hashtags.indexOf(item);
    if (index >= 0) {
      this.hashtags.splice(index, 1);
    }
    this.saveHashtags.emit(this.hashtags);
  }

  addHashtag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.hashtags.push(value);

    }
    event.chipInput!.clear();
    this.saveHashtags.emit(this.hashtags);
  }

}
