import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
!one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
  ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
!one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
  ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-default-calendar',
  templateUrl: './default-calendar.component.html',
  styleUrls: ['./default-calendar.component.scss']
})
export class DefaultCalendarComponent implements OnInit {

  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  currentMonth!: any;
  currentYear!: any;
  monthString: string;
  @Input() selectedDate!: NgbDateStruct;

  @Output() saveDate: EventEmitter<any> = new EventEmitter();
  @ViewChild('dp') private dp: NgbDatepicker;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit(): void {
    if(!this.minDate) {this.minDate = this.calendar.getToday(); }
    this.currentMonth = this.selectedDate ? this.selectedDate.month : this.minDate.month;
    this.currentYear = this.selectedDate ? this.selectedDate.year : this.minDate.year;
    if(!this.maxDate) {
      this.maxDate = this.calendar.getToday();
      this.maxDate.year++; 
    }
    const date = this.selectedDate || this.calendar.getToday();
    this.monthString = formatDate(new Date(this.formatter.format(date)), 'MMMM', 'es'); 
  }

  // Calendar
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  changeDateSelected(date: NgbDate) {
    if(date.equals(this.minDate) || (date.before(this.maxDate) && date.after(this.minDate)) || date.equals(this.maxDate)) {
      this.selectedDate = date;
      this.saveDate.emit(date);
    }
  }

  prevMonth() {
    if(!this.minDate || (this.minDate.month < this.currentMonth || this.minDate.year < this.currentYear)) {
      if(this.currentMonth == 1) {
        this.currentYear--;
        this.currentMonth = 12;
      } else {
        this.currentMonth--;
      }
      const newDate = {
        year: this.currentYear,
        month: this.currentMonth,
        day: 1
      };
      this.dp.navigateTo(newDate);
      this.monthString = formatDate(new Date(this.formatter.format(newDate)), 'MMMM', 'es'); 
    }
  }

  nextMonth() {
    if(!this.maxDate || (this.maxDate.month > this.currentMonth || this.maxDate.year > this.currentYear)) {
      if(this.currentMonth == 12) {
        this.currentYear++;
        this.currentMonth = 1;
      } else {
        this.currentMonth++;
      }
      const newDate = {
        year: this.currentYear,
        month: this.currentMonth,
        day: 1
      };
      this.dp.navigateTo(newDate);
      this.monthString = formatDate(new Date(this.formatter.format(newDate)), 'MMMM', 'es'); 
    }
  }

}
