import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BondsServices } from 'src/app/services/bonds.service';
import { BusinessService } from 'src/app/services/business.service';
import { EventosService } from 'src/app/services/eventos.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { PlacesService } from 'src/app/services/places.service';
import { NewLokComponent } from '../../../new-lok/new-lok/new-lok.component';
import { CreateBondComponent } from '../create-bond/create-bond.component';
import { SelectEventBondComponent } from '../select-event-bond/select-event-bond.component';

@Component({
  selector: 'app-events-panel',
  templateUrl: './events-panel.component.html',
  styleUrls: ['./events-panel.component.scss']
})
export class EventsPanelComponent implements OnInit {

  business: any;
  place!: any;
  eventos: any[] = [];
  profile!: any;
  searchText!: string;
  now: Date = new Date();
  @Input() idVenue!: any;
  @Input() draft!: any;

  displayedColumns: string[] = ['actions', 'titulo', 'qr', 'location', 'fecha', 'vendidas', 'precio', 'status'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;

  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';
  @Output() changeTag: EventEmitter<any> = new EventEmitter<any>();
  loaded: boolean = false;
  loadingPage: boolean = true;
  allowCreate!: any;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  private searchTerms = new Subject<string>();

  constructor(private panelService: PanelAdminService, private businessService: BusinessService,
    private dialog: MatDialog, private router: Router, private auth: AuthenticationService,
    private bondService: BondsServices, private apiBusiness: ApiBusinessService, private eventosService: EventosService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.now.setHours(0);
    this.now.setMinutes(0);
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    this.allowCreate = rolesProfile?.find((element) => (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS'));
  }

  ngAfterViewInit() {
    this.displayedColumns = !this.draft ?
      ['actions', 'titulo', 'qr','location', 'fecha', 'vendidas', 'precio', 'status'] :
      ['actions', 'titulo', 'qr','location', 'fecha', 'precio', 'status'];

    this.apiBusiness.changed.subscribe({
      next: (value) => {
        this.place = localStorage.getItem('places');
        this.business = JSON.parse(localStorage.getItem('business') || '{}');
        if (value) {
          this.updateEvents();
        }
      }
    })
    this.searchTerms.pipe(
      debounceTime(500), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.searchText = items;
      this.updateEvents();
    });
  }

  getData() {
    let places = [];
    if (this.place) {
      places = [this.place];
    }
    if (this.idVenue) {
      places = [this.idVenue];
    }
    this.businessService.getEventsCompanyFilter(this.business?.id, this.page, this.searchText, this.sorted, places, this.draft).subscribe({
      next: (res) => {
        this.eventos = this.eventos.concat(res);
        if (this.eventos.length <= 0 || this.eventos.length < 20) {
          this.finishScroll = true;
        }
        this.dataSource = new MatTableDataSource(this.eventos);
        this.dataSource.sort = this.sort;

        this.loaded = true;
        this.loadingPage = false;
      }
    })
  }

  updateEvents() {
    this.page = 1;
    this.loadingPage = true;
    this.finishScroll = false;
    this.eventos = [];
    this.getData();
  }

  searchEvents(event) {
    this.searchTerms.next(event);
  }

  openEvent(event: any) {
    const url = this.draft ? 'events/draft' : 'events';
    if (this.idVenue) {
      const urlFront = AppSettings.FRONT + '/panel-control/' + url + '/detail/info-event/' + event[0].id;
      window.open(urlFront, '_blank');
    } else {
      this.router.navigate(['/panel-control/' + url + '/detail/info-event', event[0].id]);
    }
  }

  getStatus(event: any) {
    if (!event[0].activo) {
      return { color: '#7A7A7A', status: 'Desactivado' };
    }
    if (event[0].draft) {
      return { color: '#7A7A7A', status: 'Borrador' };
    }
    if (new Date(event[0].datesRange.start) >= this.now && event[0].datesRange.end && new Date(event[0].datesRange.end) <= this.now) {
      return { color: '#21CB7C', status: 'Activo' };
    }
    if ((new Date(event[0].datesRange.start) < this.now && !event[0].datesRange.end) || (event[0].datesRange.end && new Date(event[0].datesRange.end) < this.now)) {
      return { color: '#B75470', status: 'Terminado' };
    }
    return { color: '#21CB7C', status: 'Disponible' };
  }

  openCreateEvent() {
    // const dialogRef = this.dialog.open(NewLokComponent, {
    //   panelClass: 'new-lok-dialog',
    //   disableClose: true
    // });
    // dialogRef.afterClosed().subscribe({
    //   next: (res) => {  }
    // })
    this.router.navigate(['/panel-control/events/new-event/info-event']);
  }

  // Sort
  sortData(sort: Sort) {
    if (this.eventos.length > 1) {
      const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
      if (sort.direction) {
        this.sorted = { sortedBy: sort.active, sort: isAsc };
      } else {
        this.sorted = null;
      }
      this.updateEvents();
    }
  }

  openCreateBond(object?: any, back?: boolean) {
    let bondRes = object;
    const dialogRef = this.dialog.open(CreateBondComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      disableClose: true,
      width: '750px',
      data: {
        idCompany: this.business.id,
        object: object
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.bond) {
        if (!bondRes) {
          bondRes = { id: null, name: '', type: '', cuantity: 0, events: [] }
        }
        bondRes.name = res.bond.name;
        bondRes.type = res.bond.type;
        bondRes.cuantity = res.bond.cuantity;
        res.bond.events = bondRes?.events;
        this.openSelectEvents(res.bond);
      }
    });
  }

  openSelectEvents(object: any, bond?: any) {
    const dialogRef = this.dialog.open(SelectEventBondComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      width: '750px',
      disableClose: true,
      data: {
        idCompany: this.business.id,
        object: bond
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.back) {
        this.openCreateBond(object, true);
      }
      if (res.events) {
        this.sendCreateBond(object, res.events);
      }
    });
  }

  sendCreateBond(object: any, events: any) {
    let payload = {
      name: object.name,
      type: object.type,
      cuantity: object.cuantity,
      events: events
    };
    this.bondService.createBond(payload).subscribe({
      next: (res) => {
        this.openInfoCard('Abono creado correctamente.');
      },
      error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al crear el abono, vuelve a intentarlo más tarde.');
      }
    });
  }

  openInfoCard(msg: string, disabledClose: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }

  //Download QR
  dowloadQR(event: any) {
    this.eventosService.getQrEvent(event[0].id).subscribe((svgData) => {
      const name = 'name' + '.svg';
      var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
      const svgUrl = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
}
