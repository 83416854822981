<div class="page-edit-lok">
    <div class="container container-page-edit">
        <div class="mb-2 d-flex align-items-center">
            <span class="pointer-in" (click)="goBack()"><i class="fa-light fa-arrow-left fa-lg"></i></span>
            <p class="m-0 title-edit">Editar Evento <span *ngIf="eventInit?.draft">(Borrador)</span></p>
        </div>
        <mat-divider></mat-divider>
        <div class="form-edit">
            <div class="btn-publish w-100 d-flex justify-content-end" *ngIf="eventInit?.draft">
                <button class="btn primary-button solid" (click)="publishEvent()">Publicar evento</button>
            </div>

            <div class="content-img">
                <div class="img-edit" (click)="file.click()">
                    <img fallimg [src]="croppedImage" alt="Imagen evento">
                    <div class="content-camera">
                        <i class="fa-light fa-camera"></i>
                    </div>
                </div>
                <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
            </div>

            <form class="formulario-content" [formGroup]="lokForm" enctype="application/x-www-form-urlencoded">
                <mat-divider></mat-divider>
                <div class="item-form-edit change-open container-form d-flex">
                    <i class="fa-light fa-text icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Título del evento</span>
                        <input class="w-100" type="text" formControlName="titulo" placeholder="Título*"
                            [readonly]="!editTitle">
                    </div>
                    <i class="icon-open fa-light fa-{{editTitle?'floppy-disk':'pen'}} pointer-in"
                        [class.edit-active]="editTitle" (click)="editTitle?saveTitle():changeTitle()"></i>
                </div>
                <mat-divider></mat-divider>
                <div class="item-form-edit container-form d-flex pointer-in" (click)="openDescription()">
                    <i class="fa-light fa-input-text icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Descripción</span>
                        <div class="d-flex justify-content-between align-items-center">
                            <input *ngIf="!textArea" class="pointer-in" style="font-size: 16px;" type="text"
                                placeholder="Descripción*" readonly>
                            <div *ngIf="textArea" class="short-text" [innerHTML]="textArea"></div>
                            <i class="icon-open fa-light fa-chevron-right pointer-in"></i>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item-form-edit change-open container-form d-flex pointer-in" (click)="openCategories()">
                    <i class="fa-light fa-tag icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Categoría</span>
                        <input #desc class="w-100 pointer-in" maxlength="255" type="text" [(ngModel)]="nameCategoria"
                            [ngModelOptions]="{standalone: true}" readonly>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>
                <div class="item-form-edit change-open pointer-in" (click)="openMap()">
                    <i class="icon-primary fa-light fa-map"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Lugar</span>
                        <input #desc class="w-100 pointer-in" maxlength="255" type="text" [(ngModel)]="localizacionName"
                            [ngModelOptions]="{standalone: true}" readonly>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>
                <div class="item-form-edit change-open pointer-in" (click)="openDates()">
                    <i class="icon-primary fa-light fa-calendar"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Fecha y hora</span>
                        <input #desc class="w-100 pointer-in" maxlength="255" type="text" [(ngModel)]="textDates"
                            [ngModelOptions]="{standalone: true}" readonly>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>
                <!-- <div class="item-form-edit change-open pointer-in" [routerLink]="['/edit-event/map', map?.id, eventInit.id]"
                    *ngIf="eventInit?.mapEvent && (profile?.admin || profile?.site)">
                    <i class="icon-primary fa-light fa-loveseat"></i>
                    <div class="content-input w-100">
                        <span class="m-0 title-form">Editar mapa de butacas</span>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider *ngIf="eventInit?.mapEvent && (profile?.admin || profile?.site)"></mat-divider> -->
                <!-- <div class="item-form-edit change-open pointer-in" (click)="openTickets()" *ngIf="profile?.admin || profile?.site">
                    <i class="icon-primary fa-light fa-ticket"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Entradas y precios</span>
                        <input #desc class="w-100 pointer-in" maxlength="255" type="text" [(ngModel)]="textTickets"
                            [ngModelOptions]="{standalone: true}" readonly>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider *ngIf="profile?.admin || profile?.site"></mat-divider> -->
                <div class="item-form-edit change-open pointer-in" (click)="openConfig()">
                    <i class="icon-primary fa-light fa-gear"></i>
                    <div class="content-input w-100">
                        <p class="m-0">Configuración de venta</p>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>
                <!-- <div class="item-form-edit container-form d-flex">
                    <i class="fa-light fa-camera icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Más fotos</span>
                        <input class="w-100" type="text" readonly>
                    </div>
                </div>
                <mat-divider></mat-divider> -->
                <div class="item-form-edit change-open container-form d-flex">
                    <i class="fa-light fa-hashtag icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Hashtags</span>
                        <mat-form-field class="label-title w-100" appearance="fill">
                            <mat-chip-grid #chipList aria-label="#Hashtags">
                                <mat-chip-row *ngFor="let item of hashtags" (removed)="removeHashtag(item)">
                                    #{{item}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input [readonly]="!editHashtags" style="font-size: 16px;" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addHashtag($event)">
                            </mat-chip-grid>
                        </mat-form-field>
                    </div>
                    <i class="icon-open fa-light fa-{{editHashtags?'floppy-disk':'pen'}} pointer-in"
                        [class.edit-active]="editHashtags" (click)="editHashtags?saveHashtags():changeHashtags()"></i>
                </div>


                <mat-divider></mat-divider>
            </form>
        </div>
    </div>
</div>