<div class="dialog-content">
    <div class="container-filter">
        <div class="row m-0 p-0">
            <div class="scroll-filters">
                <!-- <div class="header-mobile-dialog justify-content-between align-items-center">
                <p class="m-0 title-dialog">Buscador avanzado</p>
                <i class="fa-light fa-times fa-xl pointer-in" mat-dialog-close></i>
            </div> -->
                <div class="header-dialog-filter d-flex justify-content-between align-items-center padding-option">
                    <p class="m-0 title-filter">Filtros</p>
                    <i class="fa-light fa-times fa-xl pointer-in" mat-dialog-close></i>
                </div>
                <div class="p-0" style="display: contents;">
                    <!-- Ordenar por -->
                    <mat-divider class="divider-filter"></mat-divider>
                    <div class="d-flex justify-content-between align-items-center pointer-in padding-option"
                        (click)="orderActive = !orderActive">
                        <p class="m-0 title-filter-option">Ordenar por</p>
                        <i class="fa-light {{orderActive ? 'fa-chevron-up' : 'fa-chevron-down'}}"></i>
                    </div>
                    <div class="options-swiper orderBy" [class.optionsShow]="orderActive">
                        <div class="shadow-cat"></div>
                        <swiper class="swiper swiper-custom-filter" [config]="swiperFilterConfig">
                            <ng-template *ngFor="let order of orderByOptions" swiperSlide>
                                <div (click)="orderBy = order"
                                    [class]="order.id == orderBy?.id ? 'filterActive' : 'swiper-filter'">
                                    <p class="m-0">{{order.name}}</p>
                                </div>
                            </ng-template>
                        </swiper>
                    </div>

                    <!-- Fecha -->
                    <mat-divider class="divider-filter"></mat-divider>
                    <div class="d-flex justify-content-between align-items-center pointer-in padding-option"
                        (click)="datesActive = !datesActive">
                        <p class="m-0 title-filter-option">Fecha</p>
                        <i class="fa-light {{datesActive ? 'fa-chevron-up' : 'fa-chevron-down'}}"></i>
                    </div>
                    <div class="options-swiper" [class.optionsHidden]="!datesActive">
                        <div class="shadow-cat"></div>
                        <swiper class="swiper swiper-custom-filter" [config]="swiperFilterConfig">
                            <ng-template *ngFor="let date of datesOptions" swiperSlide>
                                <div (click)="selectDateOption(date)"
                                    [class]="date.id == dateFilter?.id ? 'filterActive' : 'swiper-filter'">
                                    <p class="m-0">{{date.name}}</p>
                                </div>
                            </ng-template>
                        </swiper>
                        <div class="calendar-filter-btn d-flex pointer-in" [class.dateRangeActive]="stringDate"
                            (click)="!stringDate ? (openDatePicker = !openDatePicker) : resetDates()">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Seleccionar rango de fecha" class="input-date" readonly
                                [value]="stringDate">
                            <i *ngIf="!stringDate" class="fa-light fa-chevron-down"></i>
                            <i *ngIf="stringDate" class="fa-light fa-times"></i>
                        </div>
                    </div>
                    <div class="padding-option">
                        <app-custom-calendar [datesRange]="datesRange" [openDatePicker]="openDatePicker"
                            (closeEvent)="openDatePicker = false"
                            (datesEvent)="selectDates($event)"></app-custom-calendar>
                    </div>


                    <!-- Categoria -->
                    <div style="display: contents;" *ngIf="categoryEnabled">
                        <mat-divider class="divider-filter"></mat-divider>
                        <div class="d-flex justify-content-between align-items-center pointer-in padding-option"
                            (click)="categoriesActive = !categoriesActive">
                            <p class="m-0 title-filter-option">Categoría</p>
                            <i class="fa-light {{categoriesActive ? 'fa-chevron-up' : 'fa-chevron-down'}}"></i>
                        </div>
                        <div class="options-swiper options-categories" [class.optionsHidden]="!categoriesActive">
                            <div class="shadow-cat"></div>
                            <swiper class="swiper swiper-custom-filter" [config]="swiperFilterConfig">
                                <ng-template class="template-category" *ngFor="let circle of categories | async"
                                    swiperSlide>
                                    <div (click)="selectCategory(circle)" class="circle-category pointer-in"
                                        [class.catSelected]="isSelectedCategory(circle)"><i
                                            class="fa-thin {{ circle.icon }} icon-primary fa-3x"></i></div>
                                    <p class="name-category pointer-in">{{ circle.nombre }}</p>
                                </ng-template>
                            </swiper>
                        </div>
                    </div>

                    <!-- Precio -->
                    <mat-divider class="divider-filter"></mat-divider>
                    <div class="d-flex justify-content-between align-items-center pointer-in padding-option"
                        (click)="pricesActive = !pricesActive">
                        <p class="m-0 title-filter-option">Precio</p>
                        <i class="fa-light {{pricesActive ? 'fa-chevron-up' : 'fa-chevron-down'}}"></i>
                    </div>
                    <div class="options-prices padding-option" [class.optionsHidden]="!pricesActive">
                        <div #customerSlider class="custom-slider w-100">
                            <ngx-slider [(value)]="lowPrice" [(highValue)]="highPrice" [options]="options"></ngx-slider>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="btns-fixed-save">
            <div class="footer-filter-btns padding-option d-flex justify-content-between align-items-center">
                <button class="btn-clear" (click)="clearFilters()" mat-dialog-close>Limpiar</button>
                <button class="btn-save" (click)="saveFilters()" mat-dialog-close>Ver resultados</button>
            </div>
        </div>
    </div>
</div>