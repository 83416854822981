import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-menu-permission',
  templateUrl: './menu-permission.component.html',
  styleUrls: ['./menu-permission.component.scss']
})
export class MenuPermissionComponent implements OnInit {

  rolesEnable!: Observable<any[]>;
  @Input() submenuActive: any = 0;
  titleTab: string = 'Todos';
  
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor(private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.rolesEnable = this.permissionService.getRolesFunctions().pipe(map((res) => res.data));
  }

  ngAfterViewInit() {
  }

  changeTabRole(emit: any) {
    this.submenuActive = emit.tab;
    this.titleTab = emit.name;
    this.changeTab.emit(emit);
  }

}
