
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { NewLokComponent } from '../../new-lok/new-lok/new-lok.component';
import { BondsServices } from 'src/app/services/bonds.service';
import { BusinessService } from 'src/app/services/business.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { SelectEventBondComponent } from '../events/select-event-bond/select-event-bond.component';
import { CreateBondComponent } from '../events/create-bond/create-bond.component';
import { InfoBondComponent } from '../events/info-bond/info-bond.component';
import { ApiBusinessService } from 'src/app/services/api-business.service';

@Component({
  selector: 'app-bonds',
  templateUrl: './bonds.component.html',
  styleUrls: ['./bonds.component.scss']
})
export class BondsComponent implements OnInit {

  business: any;
  place!: any;
  eventos: any[] = [];
  profile!: any;
  searchText!: string;
  now: Date = new Date();
  @Input() idVenue!: any;

  displayedColumns: string[] = ['actions', 'name', 'events', 'discount', 'sales', 'status'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;

  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';
  @Output() changeTag: EventEmitter<any> = new EventEmitter<any>();
  loaded: boolean = false;
  loadingPage: boolean = true;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  private searchTerms = new Subject<string>();
  allowCreate!: any;

  constructor(private panelService: PanelAdminService, private businessService: BusinessService,
    private dialog: MatDialog, private router: Router, private auth: AuthenticationService,
    private bondService: BondsServices, private apiBusiness: ApiBusinessService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.now.setHours(0);
    this.now.setMinutes(0);
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    this.allowCreate = rolesProfile?.find((element) => (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS'));
  }

  ngAfterViewInit() {
    this.apiBusiness.changed.subscribe({
      next: (value) => {
        this.place = localStorage.getItem('places');
        this.business = JSON.parse(localStorage.getItem('business') || '{}');
        if (value) {
          this.updateBonds();
        }
      }
    })
    this.searchTerms.pipe(
      debounceTime(500), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.searchText = items;
      this.updateBonds();
    });
  }

  getData() {
    let places = [];
    if (this.place) {
      places = [this.place];
    }
    this.bondService.getBonds(this.business?.id, this.page, this.searchText, this.sorted, places).subscribe({
      next: (res) => {
        this.eventos = this.eventos.concat(res);
        if (this.eventos.length <= 0 || this.eventos.length < 20) {
          this.finishScroll = true;
        }
        this.dataSource = new MatTableDataSource(this.eventos);
        this.dataSource.sort = this.sort;

        this.loaded = true;
        this.loadingPage = false;
      }
    })
  }

  updateBonds() {
    this.page = 1;
    this.loadingPage = true;
    this.finishScroll = false;
    this.eventos = [];
    this.getData();
  }

  searchEvents(event) {
    this.searchTerms.next(event);
  }

  openBond(bond: any) {
    const dialogRef = this.dialog.open(InfoBondComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      width: '750px',
      data: {
        idCompany: this.business.id,
        object: bond
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.bond) {
        this.openSelectEvents(res);
      }
    });
  }

  getStatus(bond: any) {
    let events: any[] = bond[0].events;
    let exitDraft = events.find(value => value.draft);
    if (exitDraft) {
      return { color: '#7A7A7A', status: 'Con evento en borrador' };
    }

    let eventFinish = events.find(value => (new Date(value.datesRange.start) < this.now && !value.datesRange.end) || (value.datesRange.end && new Date(value.datesRange.end) < this.now));
    if (eventFinish) {
      return { color: '#B75470', status: 'Abono terminado' };
    }
    // let active = events.find(value => (new Date(value.datesRange.start) >= this.now && value.datesRange.end && new Date(value.datesRange.end) >= this.now));
    // if (active) {
    //   return { color: '#21CB7C', status: 'Abono activo' };
    // }
    return { color: '#21CB7C', status: 'Abono activo' };
  }

  openCreateEvent() {
    this.router.navigate(['/panel-control/events/new-event/info-event']);
  }

  // Sort
  sortData(sort: Sort) {
    if (this.eventos.length > 1) {
      const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
      if (sort.direction) {
        this.sorted = { sortedBy: sort.active, sort: isAsc };
      } else {
        this.sorted = null;
      }
      this.updateBonds();
    }
  }

  openCreateBond(object?: any, edit?: boolean, back?: boolean) {
    let bondRes = object;
    if (edit && !back) {
      bondRes = {
        id: object.id,
        name: object.name,
        type: object.discount.amount ? 'amount' : 'percentage',
        cuantity: object.discount.amount ? object.discount.amount : object.discount.percentage,
        events: object.events
      }
    }
    const dialogRef = this.dialog.open(CreateBondComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      disableClose: true,
      width: '750px',
      data: {
        idCompany: this.business.id,
        object: bondRes,
        edit: edit
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.bond) {
        if (!bondRes) {
          bondRes = { id: null, name: '', type: '', cuantity: 0, events: [] }
        }
        bondRes.name = res.bond.name;
        bondRes.type = res.bond.type;
        bondRes.cuantity = res.bond.cuantity;
        res.bond.events = bondRes?.events;
        this.openSelectEvents(res.bond, bondRes, edit);
      }
    });
  }

  openSelectEvents(object: any, bond?: any, edit?: boolean) {
    const dialogRef = this.dialog.open(SelectEventBondComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      width: '750px',
      disableClose: true,
      data: {
        idCompany: this.business.id,
        edit: edit,
        object: bond
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.back) {
          let objectBack = edit ? bond : object;
          this.openCreateBond(objectBack, edit, true);
        } else if (res.events) {
          let objectBack = edit ? bond : object;
          this.sendCreateBond(objectBack, res.events, edit);
        }
      }
    });
  }

  sendCreateBond(object: any, events: any[], edit) {
    let payload = {
      name: object.name,
      type: object.type,
      cuantity: object.cuantity,
      events: events
    };
    this.bondService.createBond(payload, object.id).subscribe({
      next: (res) => {
        let msg = !object.id ? 'creado' : 'editado';
        this.openInfoCard('Abono ' + msg + ' correctamente.');
        this.updateBonds();
      },
      error: (err) => {
        let msg = !object.id ? 'creado' : 'editado';
        this.openInfoCard('¡Vaya! Ha ocurrido un error al ' + msg + ' el abono, vuelve a intentarlo más tarde.');
      }
    });
  }

  openInfoCard(msg: string, disabledClose: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }

  deleteBond(event: any) {
    if (event.purchases > 0) {

    } else {
      let bond = event[0];
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        disableClose: true,
        data: {
          icon: true,
          text: '¿Estás seguro de que quieres eliminar el abono? Esta acción no podrá deshacerse.',
          btnCancel: true,
          warning: true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.bondService.deleteBond(bond.id).subscribe({
            next: (res) => {
              this.openInfoCard('Abono eliminado correctamente');
              this.updateBonds();
            },
            error: (err) => {
              this.openInfoCard('¡Vaya! Ha ocurrido un error al eliminar el abono: ' + err.error.msg);
            }
          });
        }
      })
    }
  }

}

