<div class="intereses-page">
    <div class="page-wrapper">
        <p class="mb-2 text-center container title-intereses">Mis intereses</p>
    </div>

    <div class="container-intereses container">
        <div *ngFor="let cat of categorias | async" class="cat-pref" [class.active]="categoriasSelected.includes(cat['@id'])" (click)="selectCategory(cat)">
            <i class="fa-thin {{ cat.icon }} icon-primary"></i> 
            <p class="m-0">{{cat.nombre}}</p>
        </div>
    </div>
    <div class="d-flex justify-content-center cont-button">
        <button class="primary-button solid" (click)=savePreferences()>Guardar cambios</button>
    </div>
</div>