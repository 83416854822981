import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient) { }

  getEventsCompanyFilter(idBusiness: number, page: number, searchText: any, sortFilter: any, places: any, draft: boolean = false, listSelect: boolean = false, toDate: any = null, all = true, active = false) {
    let url = `${AppSettings.API_URI}/panel/business/events/${idBusiness}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (sortFilter) {
      url += `&orderBy=${sortFilter.sortedBy}&order=${sortFilter.sort}`;
    }
    if (places) {
      places?.forEach(element => {
        if (element) {
          url += `&places[]=${element}`;
        }
      });
    }
    if (draft) {
      url += `&type=draft`;
    }
    if (listSelect) {
      url += `&list=true`;
    }
    if(toDate) {
      url += `&toDate=${toDate}`;
    }
    if(all) {
      url += `&all=true`;
    }
    if(active) {
      url += `&active=true`
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
