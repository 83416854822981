import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  cookiesAccepted: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem('cookies')) {
      this.cookiesAccepted = true;
    }
  }

  saveCookies() {
    localStorage.setItem('cookies', '1');
    this.cookiesAccepted = true;
  }


}
