<div class="confirm-payment-container">
    <!-- <div class="d-flex justify-content-end">
        <i class="fa-light fa-times fa-lg pointer-in" (click)="goHome()"></i>
    </div> -->
    <p class="info-error" *ngIf="error">{{error}}</p>

    <div class="logo-city">
        <img fallimg src="assets/img/citylok_logo.png" alt="Citylok logo">
    </div>
    <p class="title-confirm">Gracias por confiar en Citylok</p>
    <p class="info-confirm">Tu compra se ha realizado correctamente.</p>

    <button class="btn btn-print" (click)="openPrint()" *ngIf="idDownload">
        <i class="fa-light fa-print"></i>
        Imprimir entradas
    </button>

    <button class="btn btn-confirm-back" (click)="openProfile()">
        <i class="fa-light fa-qrcode"></i>
        Ver compras
    </button>
</div>