<div class="permission-page">
    <div class="d-flex justify-content-between align-items-center">
        <div class="content-search col-6">
            <div class="search-container container-form d-flex align-items-center borderInput search-full" *ngIf="!activeSelectList">
                <i class="fal fa-search search-icon pointer-in" (click)="searchUser(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar usuario"
                    [ngModel]="searchText" (input)="searchUser(searchInput.value)">
            </div>
            <div class="info-selected" *ngIf="activeSelectList">
                <span>Seleccionados ({{selectedItems.length}})</span>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end content-btns col-6" *ngIf="admin">
            <!-- <div class="link-open">
                <i class="fal fa-list-radio"></i>
                <span>Seleccionar varios</span>
            </div> -->
            <div class="link-open" (click)="activeSelecting()" *ngIf="!activeSelectList ">
                <i class="fal fa-list-radio"></i>
                <span>Seleccionar varios</span>
            </div>
            <div class="d-flex" style="gap: 30px;" *ngIf="activeSelectList">
                <div [matMenuTriggerFor]="editOptions"
                    class="btn-create btn-create-border btn-select btn-edit d-flex justify-content-between m-0" [class.disabled]="selectedItems.length <= 0">
                    <p class="m-0">Acciones en grupo</p>
                    <i class="icon-option fal fa-chevron-down"></i>
                </div>
                <mat-menu class="menu-options" #editOptions="matMenu">
                    <button mat-menu-item (click)="deleteUser(selectedItems)">Eliminar</button>
                </mat-menu>
            </div>
            <div class="d-flex align-items-center" *ngIf="activeSelectList">
                <div class="btn-create btn-action m-0" (click)="activeSelecting()">
                    <p class="m-0">Cancelar</p>
                </div>
            </div>
            <div class="btn-create m-0" (click)="openNewUser()" *ngIf="!activeSelectList">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Crear nuevo usuario</p>
            </div>
        </div>
    </div>
    <div class="content-table-orders">
        <div *ngIf="loadingTable" class="container-lottie">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                    containerClass="loading-box"></ng-lottie>
            </div>
        </div>
        <div #containerScroll class="table-permission table-panel multiple-lines" *ngIf="!loadingPage"
            (scroll)="isScrolledInfinityView($event)">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef><b>
                        <div class="d-flex align-items-center" style="gap: 10px;">
                            <mat-checkbox [(ngModel)]="allSelected" class="checkbox" color="primary"
                                (change)="selectAll()" *ngIf="activeSelectList"></mat-checkbox>
                            <span *ngIf="!activeSelectList">Acciones</span>
                        </div>
                    </b></th>
                    <td mat-cell *matCellDef="let user">
                        <mat-checkbox class="checkbox" [checked]="allSelected" color="primary"
                            (change)="changeSelectItem(user)" *ngIf="activeSelectList"></mat-checkbox>

                        <div class="d-flex" style="gap: 20px;"  *ngIf="!activeSelectList">
                            <div [matMenuTriggerFor]="actionOptions" class="pointer-in square-option">
                                <i class="icon-option fal fa-ellipsis-vertical"></i>
                            </div>
                            <mat-menu class="options-menu-control-panel" #actionOptions="matMenu">
                                <button mat-menu-item class="deleteIcon" *ngIf="!user.inviteId" (click)="openUser(user)">Ver<span *ngIf="admin">/Editar</span></button>
                                <button mat-menu-item class="deleteIcon" (click)="deleteUser([user])" *ngIf="admin">Eliminar</button>
                            </mat-menu>
                            <div class="pointer-in square-option" (click)="openUser(user)" *ngIf="!user.inviteId && admin">
                                <i class="icon-option fal fa-pen"></i>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Correo electrónico</b></th>
                    <td [class.invite]="user.inviteId" mat-cell *matCellDef="let user" (click)="openUser(user)">
                        {{user.email||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Nombre y apellidos</b></th>
                    <td [class.invite]="user.inviteId" mat-cell *matCellDef="let user" (click)="openUser(user)">
                        {{user.nombre||'-'}}
                        <p *ngIf="user.inviteId && (user.expirationDate|date:'dd MM y HH:mm') <= (now|date:'dd MM y HH:mm')"
                            class="m-0 invite-info">Invitación caducada</p>
                        <p *ngIf="user.inviteId && (user.expirationDate|date:'dd MM y HH:mm') > (now|date:'dd MM y HH:mm')"
                            class="m-0 invite-info">Se ha enviado la invitación</p>
                        <p *ngIf="user.inviteId"
                            class="m-0 link-send-invite pointer-in" (click)="openSendInvite(user)">Volver a enviar la
                            invitación</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef><b>Función</b></th>
                    <td [class.invite]="user.inviteId" mat-cell *matCellDef="let user" (click)="openUser(user)">
                        {{getRolesUser(user.roles)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="place">
                    <th mat-header-cell *matHeaderCellDef><b>Espacio</b></th>
                    <td [class.invite]="user.inviteId" mat-cell *matCellDef="let user" (click)="openUser(user)">
                        <div class="image-place">
                            <!-- <img *ngIf="place.image" [src]="place.image" [alt]="place.name" class="icon-place" /> -->
                            {{getPlacesUser(user.places)}}
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="pagination-component">
                <div class="d-flex align-items-center pagination-panel pagination-table" *ngIf="!finishScroll || (!loadingPage && loadingTable)">
                    <div>
                        <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                            containerClass="loading-box"></ng-lottie>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="!loadingTable && !loadingPage && (usersManager.length<=0)" style="text-align: center;">No hay resultados disponibles
            con esa búsqueda</p>
    </div>
</div>