import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { text } from 'stream/consumers';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { InputHourComponent } from '../../shared/input-hour/input-hour.component';

@Component({
  selector: 'app-dates-hours',
  templateUrl: './dates-hours.component.html',
  styleUrls: ['./dates-hours.component.scss']
})
export class DatesHoursComponent implements OnInit {

  @Output() saveDates: EventEmitter<any> = new EventEmitter<any>();
  fecha: FormGroup;
  horario: FormGroup;
  @Input() horarios: any[] = [];
  @Input() fechas!: any;
  @Input() auxArr!: any;
  @Input() existsTickets!: boolean;

  // Start and End Date
  startStringDate!: any;
  endStringDate!: any;
  startDate!: NgbDate;
  endDate!: NgbDate;

  typeDatepicker!: string;

  // Checks
  sameHours: boolean = true;
  endRepeat: string = 'date';
  notHours: boolean = false;

  // Options days
  repeatWeek: any[] = [
    { day: 'L', number: 1, text: 'Lunes' },
    { day: 'M', number: 2, text: 'Martes' },
    { day: 'X', number: 3, text: 'Miércoles' },
    { day: 'J', number: 4, text: 'Jueves' },
    { day: 'V', number: 5, text: 'Viernes' },
    { day: 'S', number: 6, text: 'Sábados' },
    { day: 'D', number: 0, text: 'Domingos' }
  ];
  repeatMonth: any[] = [];
  repeatDays: any[] = [];

  selectRepeat: any[] = []; // Selected repeat days
  repeatDate!: NgbDate; // If repeat end on exact date
  repeatStringDate!: string;

  selectedSchedules: any[] = [];
  selectedSchedulesRepeat: any[] = [];
  infoRep: string = '';

  maxType: number = 54;
  profile!: any;
  dateOld!: any;
  now!: any;
  idMap!: any;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog,
    public formatter: NgbDateParserFormatter, private calendar: NgbCalendar,
    private auth: AuthenticationService, @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<DatesHoursComponent>) {
    const today = new Date();
    this.startDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.dateOld = this.startDate;
    this.startStringDate = formatDate(new Date(), 'dd/MM/yyyy', 'es');
    for (let index = 1; index <= 31; index++) {
      this.repeatMonth.push(index);
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.fechas = this.data.dates;
      this.horarios = this.data.hours;
      this.auxArr = this.data.auxArr;
      this.idMap = this.data.map;
    }
    this.auth.getUsersMe().subscribe({
      next: result => { if (result.email) { this.profile = result } }
    });
    this.fecha = this.formBuilder.group({
      fechaInicio: ['', Validators.required],
      fechaFin: [null, [Validators.required]],
      repeat: [false, [Validators.required]],
      typeRepeat: ['week'],
      repeatCount: [1],
      repeatInterval: [[]],
      repeatFin: [null],
      informativo: [false],
      activo: [true]
    });
    this.horario = this.formBuilder.group({
      horaInicio: ['', Validators.required],
      horaFin: [null, [Validators.required]],
      fixedSchedule: [true],
      dayWeek: [[]],
      dayMonth: [[]],
      activo: [true]
    });
    (!this.fechas || this.fechas.length <= 0) ? this.fecha.controls['fechaInicio'].setValue(formatDate(new Date(), 'yyyy-MM-dd', 'es')) : this.inputData();
  }

  inputData() {
    if (this.fechas) {
      this.fechas = {
        fechaInicio: this.fechas[0].fechaInicio,
        fechaFin: this.fechas[0].fechaFin,
        repeat: this.fechas[0].repeat,
        typeRepeat: this.fechas[0].typeRepeat,
        repeatCount: this.fechas[0].repeatCount,
        repeatInterval: this.fechas[0].repeatInterval,
        repeatFin: this.fechas[0].repeatFin,
        informativo: this.data ? this.horarios?.length <= 0 : this.fechas[0].informativo,
        activo: true
      }
      this.now = new Date();
      this.fecha.setValue(this.fechas);
      let fechaInicio = new Date(this.fechas.fechaInicio);
      this.startDate = new NgbDate(fechaInicio.getFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate());
      this.dateOld = this.now >= fechaInicio ? this.startDate : this.dateOld;

      this.startStringDate = formatDate(fechaInicio, 'dd/MM/yyyy', 'es');
      // if (!this.fechas.fechaFin) {
      //   this.fechas.fechaFin = this.fechas.fechaInicio;
      // }
      if (this.fechas.fechaFin) {
        let fechaFin = new Date(this.fechas.fechaFin);
        this.endDate = new NgbDate(fechaFin.getFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate());
        this.endStringDate = formatDate(fechaFin, 'dd/MM/yyyy', 'es');
      }
      if (this.fechas.repeat) {
        this.endRepeat = 'never';
        this.selectRepeat = this.fechas.repeatInterval;
        if (this.fechas.repeatFin) {
          let repeatFin = new Date(this.fechas.repeatFin);
          this.endRepeat = 'date';
          this.repeatDate = new NgbDate(repeatFin.getFullYear(), repeatFin.getMonth() + 1, repeatFin.getDate());
          this.repeatStringDate = formatDate(repeatFin, 'dd/MM/yyyy', 'es');
        }
      }

    }
    if (this.horarios) {
      if (this.horarios[0].fixedSchedule) {
        this.sameHours = true;
        this.selectedSchedules = this.horarios;
      }
      if (!this.horarios[0].fixedSchedule && this.auxArr) {
        this.sameHours = false;
        this.selectedSchedulesRepeat = this.auxArr;
        this.infoPeriod();
      }
    }
  }

  openInputHour(obj?: any) {
    const dialogRef = this.dialog.open(InputHourComponent, {
      panelClass: ['info-dialog', 'not-padding']
    })
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        const aux = this.sameHours ? this.selectedSchedules : obj.times;
        let time = new Date();
        time.setUTCHours(response.hour);
        time.setUTCMinutes(response.minutes);
        const index = aux.findIndex(value => new Date(value.horaInicio).getUTCHours() == time.getUTCHours() && new Date(value.horaInicio).getUTCMinutes() == time.getUTCMinutes())
        if (index == -1) {
          this.horario.controls['activo'].setValue(true);
          this.horario.controls['horaInicio'].setValue(time.toUTCString());
          this.sameHours ? this.addSchedule() : this.addScheduleByDay(obj);
        }
      }
    })
  }

  addSchedule() {
    this.horario.controls['fixedSchedule'].setValue(true);
    this.selectedSchedules.push(this.horario.value);
    this.selectedSchedules = this.selectedSchedules.sort((a: any, b: any) => {
      return new Date(a.horaInicio).getTime() - new Date(b.horaInicio).getTime();
    })
  }

  addScheduleByDay(obj: any) {
    this.horario.controls['fixedSchedule'].setValue(false);
    obj.times.push(this.horario.value);
    obj.times = obj.times.sort((a: any, b: any) => {
      return new Date(a.horaInicio).getTime() - new Date(b.horaInicio).getTime();
    })
  }

  deleteTime(index: any, type?: any, indexTime?: any) {
    type ? this.selectedSchedulesRepeat[index].times.splice(indexTime, 1) : this.selectedSchedules.splice(index, 1);
  }

  openDatePicker(type: string) {
    if (this.typeDatepicker) {
      this.typeDatepicker = null;
      return;
    }
    this.typeDatepicker = type;
  }

  saveDate(event: any) {
    if (event) {
      const date = new Date(this.formatter.format(event));
      if (this.typeDatepicker == 'start') {
        this.startDate = event;
        this.fecha.controls['fechaInicio'].setValue(formatDate(date, 'yyyy-MM-dd', 'es'));
        this.startStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'end') {
        this.endDate = event;
        this.fecha.controls['fechaFin'].setValue(formatDate(date, 'yyyy-MM-dd', 'es'));
        this.endStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }

      // if(this.typeDatepicker == 'repeat') {
      //   this.repeatDate = event;
      //   this.fecha.controls['repeatFin'].setValue(formatDate(date, 'yyyy-MM-dd', 'es'));
      //   this.repeatStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      // }
    }
    this.changeTypeHour();
    this.typeDatepicker = null;
  }

  addDay(opt: any, n?: any) {
    const index = this.selectRepeat.indexOf(opt.number);
    index != -1 ? this.selectRepeat.splice(index, 1) : this.selectRepeat.push(opt.number);
    this.fecha.controls['repeatInterval'].setValue(this.selectRepeat);
    index != -1 ? this.selectedSchedulesRepeat.splice(n, 1) : this.selectedSchedulesRepeat.push({ day: opt.day, times: [] });
    this.infoPeriod();
  }

  changeTypeHour() {
    this.selectedSchedulesRepeat = [];
    this.selectRepeat.forEach(num => {
      this.selectedSchedulesRepeat.push({ day: this.repeatWeek.find(el => el.number == num).day, times: [] });
    });
    if (!this.fecha.controls['repeat'].value) {
      this.selectedSchedulesRepeat = [];
      let arraySelect = [];
      let dateInit: NgbDate = new NgbDate(this.startDate.year, this.startDate.month, this.startDate.day);
      do {
        const date = new Date(this.formatter.format(dateInit));
        arraySelect.push(date);
        dateInit.day++;
        this.selectedSchedulesRepeat.push({ day: date, times: [] });
      } while (this.endDate && (dateInit.before(this.endDate) || dateInit.equals(this.endDate)));
    }
    this.infoPeriod();
  }

  infoPeriod() {
    let text = ''
    const period = this.fecha.controls['typeRepeat'].value;
    const count = this.fecha.controls['repeatCount'].value;
    if (count > 0) {
      if (period == 'week') { text += count > 1 ? (count + ' semanas ') : 'semana '; }
      if (period == 'month') { text += count > 1 ? (count + ' meses ') : 'semana '; }
      if (period == 'year') { text += count > 1 ? (count + ' años ') : 'año '; }
    }
    let arrayDays = this.repeatWeek.filter(value => this.selectRepeat.includes(value.number));
    if (arrayDays.length > 0 && text) {
      arrayDays.length == 7 ? text += 'todos los días ' : (
        text += 'los ',
        arrayDays.forEach((day, index) => {
          if (index == (arrayDays.length - 1) && index != 0) { text += ' y ' };
          text += day.text;
          if ((index != (arrayDays.length - 2)) && (index != (arrayDays.length - 1))) { text += ', ' };
        })
      );
    }
    if (text) {
      text = 'El evento se repetirá cada ' + text;
      if (this.repeatDate) {
        const date = new Date(this.formatter.format(this.repeatDate));
        const day = formatDate(date, 'dd', 'es');
        const month = formatDate(date, 'MMMM', 'es');
        text += ', hasta el ' + day + ' de ' + month + '.';
      }
      this.infoRep = text;
    }
  }

  save() {
    if (!this.fecha.controls['informativo'].value && this.fecha.controls['repeat'].value && (this.fecha.controls['repeatCount'].value <= 0 || this.selectRepeat.length <= 0)) {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: 'Compruebe todos los campos',
          btnCancel: false
        }
      });
      return;
    }

    this.saveFechas();
    this.saveHorario();
    if (this.idMap && !this.fecha.value.informativo && this.horarios.length <= 0) {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: 'Compruebe todos los campos. Debes introducir un horario.',
          btnCancel: false
        }
      });
      return;
    }
    this.sendDates();
  }

  saveFechas() {
    // si no se repite resetear valores
    if (!this.fecha.controls['repeat'].value) {
      this.fecha.controls['typeRepeat'].setValue(null);
      this.fecha.controls['repeatCount'].setValue(null);
      this.fecha.controls['repeatInterval'].setValue([]);
      this.fecha.controls['repeatFin'].reset();
    }

    // Si se repite guardar datos
    if (this.fecha.controls['repeat'].value && this.fecha.controls['typeRepeat'].value == 'week') {
      this.fecha.controls['repeatInterval'].setValue(this.selectRepeat);
    }
    if (this.fecha.controls['repeat'].value && this.fecha.controls['typeRepeat'].value == 'month') {
      // Coger fechas de meses
    }
    if (this.endRepeat == 'never') { this.fecha.controls['repeatFin'].reset(); }
  }

  saveHorario() {
    this.horarios = [];
    if (!this.fecha.value.informativo) {
      this.horarios = this.selectedSchedules;
      // Horario diferentes cada dia
      if (!this.sameHours) {
        this.horarios = [];
        let hours = []; // Guarda las horas iniciales
        let timesArray = [];
        timesArray = this.selectedSchedulesRepeat.map(value => value.times.map(val => {
          let date = new Date()
          let init = new Date(val.horaInicio)
          date.setHours(init.getUTCHours());
          date.setMinutes(init.getUTCMinutes());
          return formatDate(date, 'HH:mm', 'es');
        }));
        timesArray.forEach(element => { hours = hours.concat(...element); });
        hours = hours.filter(function (item, pos) { return hours.indexOf(item) == pos; })
        this.horario.controls['fixedSchedule'].setValue(false);
        // dayWeek o dayMonth -> dependiendo de repeat o no
        hours.forEach(horario => {
          this.horario.controls['horaInicio'].setValue(horario);
          let days = [];
          this.selectedSchedulesRepeat.forEach((element, index) => {
            if (timesArray[index].includes(horario)) {
              let d = element.day;
              let num = this.repeatWeek.find(el => el.day == element.day);
              if (element.day instanceof Date) {
                d = d.getDate();
              }
              num != undefined ? days.push(num.number) : days.push(d);
            }
          });
          if (days.length > 0) {
            let aux = (this.fecha.controls['repeat'].value && this.fecha.controls['typeRepeat'].value == 'week') ? 'dayWeek' : 'dayMonth';
            this.horario.controls[aux].setValue(days);
            this.horarios.push(this.horario.value)
          };
        });
      }
    }
  }

  sendDates() {
    if (this.existsTickets) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: '¿Estás seguro de que desea guardar los cambios? Las entradas con horarios especiales se podrían perder.',
          btnCancel: true
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.emitResult();
        }
      })
    }
    if (!this.existsTickets) {
      this.emitResult();
    }
  }

  emitResult() {
    let informativo = this.fecha.controls['informativo'].value;
    let text = this.startStringDate;
    if (this.endStringDate) { text += ' al ' + this.endStringDate };
    let typeRepeat = this.fecha.value.typeRepeat;
    if (typeRepeat && this.profile?.site) {
      text += typeRepeat == 'week' ? ' - Semanal' : (typeRepeat == 'month' ? ' - Mensual' : ' - Anual');
    }
    let auxHours = this.sameHours ? this.selectedSchedules : this.selectedSchedulesRepeat;
    const result = {
      horarios: !informativo ? this.horarios : [],
      fecha: this.fecha.value,
      textInput: text,
      contHours: !informativo ? auxHours : 0
    };
    this.saveDates.emit(result)
    if (this.data) {
      this.dialogRef.close(result);
    }
  }

}
