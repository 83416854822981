import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutService } from 'src/app/services/checkout.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { IPayPalConfig } from 'ngx-paypal';
import { ComprasService } from 'src/app/services/compras.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { WaitingPayComponent } from '../waiting-pay/waiting-pay.component';
import { AppSettings } from 'src/app/app-settings';
import { ConfirmPayComponent } from '../confirm-pay/confirm-pay.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-step-checkout',
  templateUrl: './step-checkout.component.html',
  styleUrls: ['./step-checkout.component.scss']
})
export class StepCheckoutComponent implements OnInit, OnDestroy {

  public payPalConfig?: IPayPalConfig;
  lineProducts: any[] = [];

  formCredit: FormGroup;
  optionPayment: string = 'stripe';
  optionsYear: any[] = [];
  dataCard?: any;
  tickets: any[];

  redirectPaylands: any = '';

  @Input() dataBuy!: any;
  @Output() sendPayment: EventEmitter<boolean> = new EventEmitter<boolean>();
  message: string = '';
  waiting: boolean = false;
  isAdmin: boolean = false;

  stripe = require('stripe')(AppSettings.KEYS.stripe);

  constructor(private checkoutService: CheckoutService, private formBuilder: FormBuilder,
    private dialog: MatDialog, private auth: AuthenticationService,
    private router: Router, private sanitizer: DomSanitizer) { }

  async ngOnInit(): Promise<void> {
    let profile = this.auth.getProfile();
    this.isAdmin = profile?.roleFunctions?.length > 0;
    this.formCredit = this.formBuilder.group({
      number: new FormControl('', [Validators.required, Validators.maxLength(19), Validators.minLength(15)]),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      cvc: new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)])
    });
    this.dataCard = this.dataBuy;
    this.redirectPaylands = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataBuy.redirect_url);
    this.generateArrayOfYears();
  }

  generateArrayOfYears() {
    var min = new Date().getFullYear();
    var max = min + 15;
    this.optionsYear = [];

    for (var i = min; i <= max; i++) {
      this.optionsYear.push(i)
    }
  }

  async finishPayment() {
    // this.sendPayment.emit(true);
    if (this.formCredit.valid) {
      this.chargeCreditCard();
    }
    if (this.formCredit.invalid) {
      this.infoCorrectPay('Ha ocurrido un error al procesar el pago. Revisa los datos.', false);
    }
  }

  async chargeCreditCard() {
    if (this.waiting == false) {
      this.waiting = true;
      try {
        const card = {
          number: this.formCredit.value.number,
          exp_month: this.formCredit.value.month,
          exp_year: this.formCredit.value.year,
          cvc: this.formCredit.value.cvc,
        };
        const cardElement = await this.stripe.tokens.create({
          card: card,
        });
        const paymentMethod = await this.stripe.paymentMethods.create({
          type: 'card',
          card: card,
        });

        this.dataCard['card'] = cardElement.card.id;
        this.dataCard['invoiceLines'] = this.dataBuy.invoiceLines;
        this.dataCard['bookings'] = this.dataBuy.bookings;
        this.dataCard['descripcion'] = this.dataBuy.eventName;
        this.dataCard['totalPrice'] = this.dataBuy.totalPrice;
        this.dataCard['token'] = cardElement.id;
        this.dataCard['usuario'] = this.auth.user;
        this.dataCard['paymentMethod'] = paymentMethod.id;

        this.checkoutService.chargeStripe(this.dataCard).subscribe({
          next: (data) => {
            if (data.status == "succeeded") {
              this.infoCorrectPay('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o en el apartado Mis compras de tu perfil.', true);
              // if (data.idTransaction) {
              //   this.checkoutService.getTransaction(data.idTransaction).subscribe({
              //     next: (result) => {
              //       result.idOdoo ? this.infoPrintTickets(result.idOdoo) : 
              //       this.infoCorrectPay('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o en el apartado Mis compras de tu perfil.', true);
              //     },
              //     error: () => {
              //       this.infoCorrectPay('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o en el apartado Mis compras de tu perfil.', true);
              //     }
              //   })
              // }
            }
            if (data.status == "requires_action") {
              this.openWaitPayment(data.paymentIntent);
            }
          },
          error: (error) => {
            this.infoCorrectPay('Ha ocurrido un error al procesar el pago. Revisa los datos.', false);
            this.waiting = false;
          },
        })
      } catch (e) {
        this.message = e;
        this.infoCorrectPay('Ha ocurrido un error al procesar el pago. Revisa los datos.', false);
        this.waiting = false;
      }
    }
  }

  infoPrintTickets(idOdoo?: any) {
    if (idOdoo) {
      const dialogRef = this.dialog.open(ConfirmPayComponent, {
        panelClass: 'info-dialog',
        data: {
          idOdoo: idOdoo
        }
      });
      dialogRef.afterClosed().subscribe({
        next: (data) => {
          if (data.print) {
            let url = !this.isAdmin ? '/profile' : '/panel-control/orders/list';
            this.router.navigate([url]).then(() => {
              let text = 'Se han descargado las entradas correctamente.';
              this.infoCorrectPay('Se han descargado las entradas correctamente.', true);
            });
          } else {
            this.infoCorrectPay('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o en el apartado Mis compras de tu perfil.', true);
          }
        }
      })
    } else {
      this.infoCorrectPay('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o el apartado Mis compras de tu perfil.', true);
    }
  }

  infoCorrectPay(msg: string, correct: boolean) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: msg,
        btnCancel: false
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && correct) {
        this.router.navigate(['/profile']);
      }
    })
  }

  openWaitPayment(idPayment: any) {
    const dialogRef = this.dialog.open(WaitingPayComponent, {
      panelClass: 'info-dialog',
      data: {
        idPayment: idPayment,
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res == 'succeeded') {
        this.infoCorrectPay('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o el apartado Mis compras de tu perfil.', true);
      } else {
        this.infoCorrectPay('Ha ocurrido un error al procesar el pago. Revisa los datos.', false);
        this.waiting = false;
      }
    })
  }

  async refundStripe() {
    try {
      const refund = await this.stripe.refunds.create({
        charge: 'ch_3MifAzHT981aDuSS0V6tAzxo', //TODO enviar el charge id de la entrada en cuestion sobre la que se quiere la devolucion
      });
      this.checkoutService.refundStripe(refund).subscribe(
        data => {
          if (data) {
            // console.log(data)
          }
        }
      )

    } catch (e) {
      this.message = e;
      console.log(e)
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('aux');
  }
}
