<div class="container-select-type h-100">
    <div class="title-select d-flex justify-content-between align-items-center">
        <i class="fa-light fa-angle-left m-0 pointer-in" style="visibility: hidden;"></i>
        <p class="text-center m-0">Seleccionar un tipo de diseño</p>
        <i class="fa-light fa-times m-0 pointer-in"></i>
    </div>
    <div class="content-select">
        <div class="option-select" (click)="selectOption('simple')">
            <img src="https://cdn.evbstatic.com/s3-build/prod/1330391-rc2023-07-31_16.04-c707684/django/images/reserved/quick_start/table-chair.png" />
            <p>Mesas y sillas</p>
        </div>
        <div class="option-select" (click)="selectOption('rows')">
            <img src="https://cdn.evbstatic.com/s3-build/prod/1330391-rc2023-07-31_16.04-c707684/django/images/reserved/quick_start/section-row.png" />
            <p>Secciones y filas</p>
        </div>
        <div class="option-select" (click)="selectOption('mix')">
            <img src="https://cdn.evbstatic.com/s3-build/prod/1330391-rc2023-07-31_16.04-c707684/django/images/reserved/quick_start/mixed.png" />
            <p>Butacas mixtas</p>
        </div>
        <div class="option-select" (click)="selectOption('clear')">
            <img src="https://cdn.evbstatic.com/s3-build/prod/1330391-rc2023-07-31_16.04-c707684/django/images/reserved/quick_start/blank.png" />
            <p>En blanco</p>
        </div>
    </div>
</div>