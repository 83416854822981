import { Component } from '@angular/core';

@Component({
  selector: 'app-home-totem',
  templateUrl: './home-totem.component.html',
  styleUrls: ['./home-totem.component.scss']
})
export class HomeTotemComponent {

  title!: string;

  constructor() {
    this.title = 'Teatro ideal';
  }
}
