<div class="container h-100 d-md-block d-flex align-items-center">
    <div class="row m-0 d-flex align-items-center">
        <div class="col-lg-6">
            <img fallimg class="img-download-app" src="assets/img/mobile.png" />
        </div>
        <div class="col-lg-6 info-download-app">
            <p class="title-download-app h1">¡Descarga la app!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit tristique fermentum, 
                accumsan iaculis maecenas dapibus pretium magnis dictum interdum facilisi, 
                fames habitant tortor ultricies elementum malesuada fringilla ad. Penatibus purus orci porttitor aliquet dis Euismod.</p>
            <div class="apps-buttons row">
                <img fallimg class="col" src="assets/img/app_store.png" />
                <img fallimg class="col" src="assets/img/app_google.png" />
            </div>
        </div>
    </div>
</div>