<nav #nav
    class="header-panel navbar navbar-expand-xl fixed-top nav-expand position-absolute headerWhite position-fixed">
    <div class="container">
        <div class="logo-panel">
            <div class="logo pointer-in d-flex" [routerLink]="['/panel-control/events']">
                <img fallimg #imgHeader src="assets/img/citylok_horizontal.svg" alt="Citylok logo">
                <mat-divider [vertical]="true"></mat-divider>
                <div>
                    <p class="m-0">Panel</p>
                    <p class="m-0">De Control</p>
                </div>
            </div>
        </div>

        <div class="collapse navbar-collapse">
            <div class="d-flex justify-content-end w-100">
                <div class="d-flex items-menu">
                    <!-- Empresas -->
                    <div class="item-menu" *ngIf="allowSuperAdmin" (click)="activeTab(1, 'Eventos')"
                        [routerLink]="['/panel-control/list-business']" routerLinkActive="item-active"
                        [routerLinkActiveOptions]="{exact:false}">
                        <i class="fal fa-table-tree pointer-in"></i>
                        <p class="m-0">Empresas</p>
                    </div>
                    <!-- Eventos -->
                    <div class="item-menu" (click)="activeTab(1, 'Eventos')" [routerLink]="['/panel-control/events']"
                        routerLinkActive="item-active" [routerLinkActiveOptions]="{exact:false}">
                        <i class="fal fa-calendar-days pointer-in"></i>
                        <p class="m-0">Eventos</p>
                    </div>
                    <!-- Pedidos -->
                    <div class="item-menu" (click)="activeTab(2, 'Pedidos')"
                        [class.item-desactive]="!admin && !allowOrder && !allowSales"
                        [routerLink]="['/panel-control/orders']" routerLinkActive="item-active"
                        [routerLinkActiveOptions]="{exact:false}">
                        <i class="fal fa-square-list pointer-in"></i>
                        <p class="m-0">Pedidos</p>
                    </div>
                    <!-- Usuarios -->
                    <div class="item-menu" (click)="activeTab(3, 'Usuarios')"
                        [routerLink]="['/panel-control/subscribers']" routerLinkActive="item-active"
                        [routerLinkActiveOptions]="{exact:false}" [class.item-desactive]="!admin && !allowMarketing">
                        <i class="fal fa-user-group-simple pointer-in"></i>
                        <p class="m-0">Usuarios</p>
                    </div>
                    <!-- Taquilla -->
                    <div class="item-menu" (click)="openTicketOffice()" [class.item-desactive]="!admin && !allowSales">
                        <!-- [routerLink]="['/panel-control/ticket-office']" routerLinkActive="item-active"
                        [routerLinkActiveOptions]="{exact:false}" -->
                        <i class="fal fa-ticket pointer-in"></i>
                        <p class="m-0">Taquilla</p>
                    </div>
                    <!-- Informes -->
                    <div class="item-menu" (click)="activeTab(4, 'Informes')"
                        [class.item-desactive]="!admin && !allowOrder && !allowSales"
                        [routerLink]="['/panel-control/reports']">
                        <i class="fal fa-chart-line pointer-in"></i>
                        <p class="m-0">Informes</p>
                    </div>
                    <!-- Configuración -->
                    <div class="item-menu" (click)="activeTab(6, 'Configuración')"
                        [routerLink]="['/panel-control/config']" routerLinkActive="item-active"
                        [routerLinkActiveOptions]="{exact:false}">
                        <i class="fal fa-cog pointer-in"></i>
                        <p class="m-0">Configuración</p>
                    </div>
                </div>
                <div class="selects-panel">
                    <!-- Espacios -->
                    <mat-form-field appearance="fill" class="select-places-panel pointer-in"
                        *ngIf="(placesUser | async)?.length > 1">
                        <mat-select class="select-white-filter text-black" panelClass="selectPanelControl"
                            [disabled]="loadingPlaces || (placesUser | async)?.length <= 0"
                            placeholder="Todos los espacios" [(ngModel)]="placeSelect" disableOptionCentering="false"
                            (ngModelChange)="changePlaceUser()">
                            <mat-option [value]="null">Todos los espacios</mat-option>
                            <mat-option *ngFor="let place of placesUser | async" [value]="place.id">
                                <span>{{place.name}}</span></mat-option>
                        </mat-select>
                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                    <mat-divider class="divider-menu-panel" [vertical]="true"></mat-divider>
                    <!-- Menú organización -->
                    <div>
                        <mat-button class="button-menu-panel pointer-in" disableRipple="false" mat-button
                            [matMenuTriggerFor]="menuCompanies">
                            <div class="d-flex flex-button-menu">
                                <div>
                                    <p class="m-0 name-profile">{{profile?.nombre}}</p>
                                    <p class="m-0 name-company">{{companySelect?.name}}</p>
                                </div>
                                <div>
                                    <i class="fal fa-angle-down pointer-in"></i>
                                </div>
                            </div>
                        </mat-button>
                        <mat-menu class="menu-options" xPosition="before" #menuCompanies="matMenu">
                            <div class="item-edit-profile">
                                <div class="img-user-menu">
                                    <i class="fal fa-user-circle"></i>
                                </div>
                                <div class="d-grid info-profile-menu" (click)="goEditProfile()">
                                    <span>{{profile?.email}}</span>
                                    <span class="edit-link">Editar perfil</span>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div *ngIf="!allowSuperAdmin; else divCompanies">
                                <div *ngFor="let business of allBusiness; let i = index">
                                    <div class="item-select-companie" mat-menu-item
                                        [class.check-item]="business.id==companySelect.id"
                                        (click)="changeBusinessEdit(business)">
                                        <div class="div-icon-check">
                                            <i class="fal fa-circle-check" *ngIf="business.id==companySelect.id"></i>
                                        </div>
                                        <span>{{business?.name}}</span>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
                            </div>
                            <ng-template #divCompanies>
                                <div class="item-select-companie" mat-menu-item (click)="activeTab(1, 'Eventos')"
                                    [routerLink]="['/panel-control/list-business']">
                                    <span style="text-transform: math-auto;">Listado de empresas</span>
                                </div>
                                <mat-divider></mat-divider>
                            </ng-template>
                            <mat-button [disableRipple]="true" mat-menu-item class="menu-logout-panel"
                                (click)="logout()"><i class="fal fa-arrow-right-from-bracket"></i>
                                <span class="edit-link">Cerrar sesión</span>
                            </mat-button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>

        <div class="navbar-toggler">
            <!-- Logo menú -->
        </div>
    </div>
</nav>

<!--
<div class="menu-panel">
    <div class="logo-panel pointer-in" [routerLink]="['/']">
        <img fallimg src="assets/img/logo-panel.svg" alt="Citylok logo">
    </div>

    <div class="item-menu" [class.activeItem]="tabActive == 1" (click)="activeTab(1,'Eventos')"
        [routerLink]="['/panel/events']">
        <i class="fal fa-calendar-days icon-primary"></i>
        <p class="m-0">Eventos</p>
    </div>
    <div class="item-menu" [class.activeItem]="tabActive == 2" (click)="activeTab(2, 'Pedidos')"
        [routerLink]="['/panel/orders']">
        <i class="fal fa-square-list icon-primary"></i>
        <p class="m-0">Pedidos</p>
    </div>
    <div class="item-menu" [class.activeItem]="tabActive == 3" (click)="openVentas()"
        [class.disable-item]="!profile?.organizer">
        <i class="fal fa-ticket icon-primary"></i>
        <p class="m-0">Venta entradas</p>
    </div>
    <div class="item-menu disable-item" [class.activeItem]="tabActive == 4" (click)="activeTab(4, 'Informes')">
        <i class="fal fa-chart-line icon-primary"></i>
        <p class="m-0">Informes</p>
    </div>
    <div class="item-menu disable-item" [class.activeItem]="tabActive == 5" (click)="activeTab(5, 'Configuración')">
        <i class="fal fa-cog icon-primary"></i>
        <p class="m-0">Configuración</p>
    </div>
</div>
-->