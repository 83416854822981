import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { EntradasService } from 'src/app/services/entradas.service';
import { CropperImageComponent } from '../../bloques/cropper-image/cropper-image.component';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-detail-lok',
  templateUrl: './detail-lok.component.html',
  styleUrls: ['./detail-lok.component.scss']
})
export class DetailLokComponent implements OnInit {

  @Input() profile!: any;
  lokForm: FormGroup;

  lugar: any = '';
  fecha: any = '';
  auxFechasHorarios: any;
  hashtags: any[] = [];

  imagenForm: FormGroup;
  imageType: boolean = true;
  filePath!: string;
  croppedImage: any = '';
  extraFiles: any[] = [];

  @Output() optionalChange = new EventEmitter<any>();
  @Output() statusFormChange = new EventEmitter<any>();
  @Input() tabOptional: boolean = false;
  mapSelect!: any;
  configTickets!: any;
  levels!: any;

  existsTickets: boolean = false;
  newTicket!: any;
  indexTicket!: number;
  tabOpen: any = 0;
  tickets: any[] = [];
  textTicket: string = '';

  textArea = '';
  googleOject = [];
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  steps: any[] = [
    { name: 'imageStep', value: false },
    { name: 'titleStep', value: false },
    { name: 'descriptionStep', value: false },
    { name: 'locationStep', value: false },
    { name: 'datesStep', value: false },
    { name: 'ticketsStep', value: false },
    { name: 'configStep', value: false }
  ];

  numSteps: number = 7;
  countSteps: number = 0;

  constructor(private formBuilder: FormBuilder, private ciudadService: CiudadesService,
    private entradasService: EntradasService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.entradasService.tabOpen.subscribe(tab => this.tabOpen = tab);
    this.lokForm = this.formBuilder.group({
      titulo: [''],
      descripcion: [''],
      horarios: [[]],
      fechas: [[]],
      entradas: [[]],
      preciodesde: 0,
      url: [],
      hashtags: [],
      categoria: [],
      activo: [true],
      mapEvent: [],
      draft: [false],
      online: [false],
      configTickets: [],
      levels: []
    });
    this.imagenForm = this.formBuilder.group({
      file: [''],
      fileSource: []
    })
    this.lokForm.statusChanges.subscribe(res => {
      this.changeStatus(res);
    })
    // this.numSteps = this.profile?.site ? 7 : 6;
  }

  removeHashtag(item: string): void {
    const index = this.hashtags.indexOf(item);
    if (index >= 0) {
      this.hashtags.splice(index, 1);
      this.lokForm.controls['hashtags'].setValue(this.hashtags);
    }
  }

  addHashtag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.hashtags.push(value);
      this.lokForm.controls['hashtags'].setValue(this.hashtags);
    }
    event.chipInput!.clear();
  }

  openOptional(option: any) {
    this.entradasService.setTab(option);
    switch (option) {
      case 'description':
        this.optionalChange.emit('Descripción lok');
        break;
      case 'location':
        this.optionalChange.emit('Localización lok');
        break;
      case 'dates':
        this.optionalChange.emit('Fecha y hora');
        break;
      case 'tickets':
        this.optionalChange.emit('Entradas / Tickets');
        break;
      case 'images':
        this.optionalChange.emit('Imágenes lok');
        break;
      case 'add':
        this.optionalChange.emit('Crear entrada');
        break;
      case 'map':
        this.optionalChange.emit('Elegir mapa de asientos');
        break;
      case 'config':
        this.optionalChange.emit('Configuración de venta');
        break;
      default:
        this.optionalChange.emit('Detalles evento');
        this.tabOptional = false;
        break;
    }
  }

  onFileChange(event: any, second?: boolean) {
    this.lokForm.addControl('file', new FormControl('', Validators.required));
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size >= 20000000) {
        this.openDialog('La imagen no puede ocupar más de 2 Mb.');
      } else {
        const dialogRef = this.dialog.open(CropperImageComponent, {
          panelClass: 'info-dialog',
          data: event
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.croppedImage = result.pathUrl;
            if (!this.steps[0].value) {
              this.countSteps++;
            }
            this.steps[0].value = this.croppedImage !== null && this.croppedImage !== '';
            this.lokForm.patchValue({
              file: result.object.base64
            });
            this.imageType = true;
          }
        });
      }
    } else {
      this.imageType = true;
      this.lokForm.controls['file'].reset();
      this.croppedImage = '';
      if (this.steps[0].value) {
        this.countSteps--;
      }
      this.steps[0].value = false;
    }
  }

  uploadMoreFiles(event: any, second?: boolean) {
    let errorType = false;
    if (event.target.files && event.target.files.length) {
      const filesList: FileList = event.target.files;
      if (filesList.length > 10) {
        this.openDialog('No se pueden subir más de 10 imágenes');
      } else {
        let formData = new FormData();
        for (let index = 0; index < filesList.length; index++) {
          const reader = new FileReader();
          const file: File = filesList.item(index);
          formData.append("file", file, file.name);
          if (file.type.match('image.*')) {
            reader.readAsDataURL(file);
            reader.onload = () => {
              var imageSrc = reader.result as string;
              this.extraFiles.push({
                id: index,
                name: file.name,
                file: imageSrc
              });
            };
            this.imageType = true;

          } else {
            errorType = true;
          }
        }
        if (errorType) {
          this.openDialog('Únicamente se permite subir imágenes');
        }
      }
    }
  }

  openDialog(text: string) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: text,
        btnCancel: false
      }
    });
  }

  deleteExtraImg(index: number) {
    this.extraFiles.splice(index, 1);
  }

  changeStatus(event?: any) {
    let res = event;
    if (!res) {
      res = this.lokForm.valid;
    }
    this.statusFormChange.emit({ valid: res == 'VALID', form: this.lokForm.value });
  }

  openCreateTicket(event) {
    if (event && event.ticket != true) { this.newTicket = event.ticket; this.indexTicket = event.index };
    event ? this.openOptional('add') : this.openOptional('tickets');
  }

  openMapList(event: any) {
    if (event) { this.mapSelect = event.map; };
    event.select ? this.openOptional('map') : this.openOptional('tickets');
  }

  saveTitle(event: any) {
    let isTitle = event.target.value !== null && event.target.value !== '';
    if (isTitle && !this.steps[1].value) {
      this.countSteps++;
    } else if (!isTitle && this.steps[1].value) {
      this.countSteps--;
    }
    this.steps[1].value = isTitle;
  }

  saveDescription(event: any) {
    if (event != null) {
      this.textArea = event;
      this.lokForm.controls['descripcion'].setValue(event)
    }
    if (event && !this.steps[2].value) {
      this.countSteps++;
    } else if (!event && this.steps[2].value) {
      this.countSteps--;
    }
    this.steps[2].value = event !== null && event !== '';
    this.optionalChange.emit(false);
  }

  saveLocation(event: any) {
    if (event) {
      this.lokForm.controls['online'].setValue(false);
      this.lugar = event.formatted_address;
      this.googleOject.push(event);
      this.lokForm.addControl('latitude', new FormControl(event.geometry.location.lat()));
      this.lokForm.addControl('longitude', new FormControl(event.geometry.location.lng()));
      this.lokForm.addControl('localizacion', new FormControl(this.lugar));

      const objectCity = event.address_components.find(value => value.types.includes('locality'));
      if (objectCity) {
        this.ciudadService.getAllCitieByName(objectCity.long_name).subscribe(res => {
          if (res.length > 0) {
            this.lokForm.addControl('ciudad', new FormControl(res[0]['id']));
          } else {
            this.lokForm.addControl('ciudad', new FormControl(null));
          }
        });
      }
    }
    if (event && !this.steps[3].value) {
      this.countSteps++;
    } else if (!event && this.steps[3].value) {
      this.countSteps--;
    }
    this.steps[3].value = event !== null && event !== ' ';
    this.optionalChange.emit(false);
  }

  saveOnline(event: any) {
    if (this.lugar && this.lugar != 'Online') {
      this.lokForm.controls['latitude'].setValue(null);
      this.lokForm.controls['longitude'].setValue(null);
      this.lokForm.controls['localizacion'].setValue(null);
      this.lokForm.controls['ciudad'].setValue(null);
      this.googleOject = [];
    }
    this.lokForm.controls['online'].setValue(event);
    this.lugar = 'Online';
    if (event) {
      this.mapSelect = null;
      this.tickets = [];
    }
    if (event && !this.steps[3].value) {
      this.countSteps++;
    } else if (!event && this.steps[3].value) {
      this.countSteps--;
    }
    this.optionalChange.emit(false);
  }

  saveDates(event: any) {
    if (event) {
      if (event.horarios) {
        this.lokForm.controls['horarios'].setValue(event.horarios);
      }
      if (event.fecha) {
        this.lokForm.controls['fechas'].setValue([event.fecha]);
      }
      this.fecha = event.textInput;
      this.auxFechasHorarios = event.contHours;
    }
    if (event && !this.steps[4].value) {
      this.countSteps++;
    } else if (!event && this.steps[4].value) {
      this.countSteps--;
    }
    this.steps[4].value = event !== null && event !== ' ';
    this.optionalChange.emit(false);
  }

  saveTicket(event: any) {
    if (event) {
      if (!event.edit) {
        this.tickets.push(event.ticket);
      }
      if (event.edit && this.indexTicket != null) {
        this.tickets[this.indexTicket] = event.ticket;
      }
    }
    this.newTicket = null;
    this.indexTicket = null;
    this.openOptional('tickets');
  }

  saveMap(event: any) {
    if (event) {
      if (event.map) {
        this.mapSelect = event.map;
      }
    }
    this.openOptional('tickets');
  }

  saveAllTickets(event: any) {
    if (event) {
      let preciodesde = 0;
      this.tickets = [];
      if (!event.free) {
        this.textTicket = '';
        this.tickets = event.tickets;
        if (this.tickets.length == 1) {
          this.textTicket = this.tickets[0].nombre + ' - ' + this.tickets[0].precio + '€';
          preciodesde = this.tickets[0].precio;
        }
        if (this.tickets.length > 1) {
          this.textTicket = this.tickets.length + ' tipos de entradas';
          // let order = event.sort((a, b) => a.precio - b.precio);
          // preciodesde = order[0].precio;
        }
      }
      if (event.free) {
        this.textTicket = 'Gratuito';
      }
      this.mapSelect = event.map;
      this.levels = event.levels;
      this.lokForm.controls['mapEvent'].setValue(this.mapSelect);
      this.lokForm.controls['levels'].setValue(this.levels);
      this.lokForm.controls['preciodesde'].setValue(preciodesde);
      this.lokForm.controls['entradas'].setValue(this.tickets);
    }
    if (event && !this.steps[5].value) {
      this.countSteps++;
    } else if (!event && this.steps[5].value) {
      this.countSteps--;
    }
    this.steps[5].value = event !== null && event !== ' ';
    this.optionalChange.emit(false);
  }

  saveConfig(event: any) {
    if (event) {
      this.configTickets = event;
      this.lokForm.controls['configTickets'].setValue(this.configTickets);
    }
    if (event && !this.steps[6].value) {
      this.countSteps++;
    } else if (!event && this.steps[6].value) {
      this.countSteps--;
    }
    this.steps[6].value = event !== null && event !== ' ';
    this.optionalChange.emit(false);
  }

}
