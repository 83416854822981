import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { LoginComponent } from '../shared/login/login.component';

const bcrypt = require('bcryptjs');

@Component({
  selector: 'app-subscriber-front',
  templateUrl: './subscriber-front.component.html',
  styleUrls: ['./subscriber-front.component.scss']
})
export class SubscriberFrontComponent implements OnInit {

  subscriber: FormGroup = new FormGroup({});
  idPlace!: number;
  place!: any;

  hide: boolean = true;
  hideRepeat: boolean = true;
  isUser: any = true;

  salt = bcrypt.genSaltSync(10);

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<SubscriberFrontComponent>, private formBuilder: FormBuilder,
    private subscriberService: SubscriberService, private dialog: MatDialog, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.data ? this.idPlace = this.data.idPlace : null;
    this.place = this.data?.place ? this.data.place : null;
    this.isUser = this.auth?.getProfile() ? true : false;
    this.getForm();
  }

  getForm() {
    let path: any = {
      acceptConditions: [false, Validators.requiredTrue]
    };
    if (!this.isUser) {
      path = {
        name: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        textPassword: ['', Validators.compose([
          Validators.required,
          this.patternValidator(/\d/, { hasNumber: true }),
          this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          this.patternValidator(/[a-z]/, { hasSmallCase: true }),
          Validators.minLength(8),
          Validators.maxLength(30)
        ])],
        confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
        acceptTerms: [false, Validators.requiredTrue],
        acceptConditions: [false, Validators.requiredTrue]
      };
    }
    this.subscriber = this.formBuilder.group(path, {
      validators: this.password.bind(this)
    });
  }

  // Validacion contraseña
  password(formGroup: FormGroup) {
    if (this.isUser) return null;
    const { value: password } = formGroup.controls['textPassword'];
    const { value: confirmPassword } = formGroup.controls['confirmPassword'];
    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordNotMatch: true };
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = regex.test(control.value);
      if (valid) {
        return null;
      } else {
        return error;
      }
    };
  }

  get pass() { return this.subscriber.get('textPassword'); }
  get repPass() { return this.subscriber.get('confirmPassword'); }
  get name() { return this.subscriber.get('name'); }
  get lastname() { return this.subscriber.get('lastname'); }
  get email() { return this.subscriber.get('email'); }

  cancelNew() {
    this.dialogRef.close();
  }

  requestSubscription() {
    if (!this.isUser) {
      var password = this.subscriber.controls['textPassword'].value;
      var hash = bcrypt.hashSync(password, this.salt);
    }

    if (this.subscriber.valid) {
      let payload = this.subscriber.value;
      if (!this.isUser) {
        payload = {
          name: this.subscriber.controls['name'].value,
          lastname: this.subscriber.controls['lastname'].value,
          email: this.subscriber.controls['email'].value,
          password: hash,
          acceptTerms: this.subscriber.controls['acceptTerms'].value,
          acceptConditions: this.subscriber.controls['acceptConditions'].value
        };
      }
      this.subscriberService.subscriberFromUser(this.idPlace, payload).subscribe({
        next: (res) => {
          let msg = this.isUser ? 'Gracias por hacerte miembro, en breve recibirás un correo de confirmación.' : 'Gracias por hacerte miembro, en breve recibirás un correo para confirmar tu correo.';
          this.dialogRef.close({ msg: msg });
        },
        error: (err) => {
          if (err.status === 409) {
            this.dialogRef.close({ msg: 'El email introducido ya existe, si no has recibido el correo de invitación ponte en contacto con nosotros atrvés del formulario de contacto.' });
          } else {
            this.dialogRef.close({ msg: 'Se ha producido un error al realizar la suscripción, vuelve a intentarlo más tarde.' });
          }
        }
      });
    }
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: ['info-dialog', 'dialog-login'],
      data: {
        exit: false,
        seats: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isUser = result?.login ? true : false;
      this.getForm();
    });
  }
}
