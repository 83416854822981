<div class="float-container d-grid">
  <p class="price-text" *ngIf="event.preciodesde > 0 && !event?.informativo && !event?.external">Desde<span
      class="price">{{
      event.preciodesde }}€</span></p>
  <p class="price-text"
    *ngIf="event.preciodesde == 0 && !event.imageurl.includes('http') && !event?.informativo && !event?.external"><span
      class="price">GRATUITO</span></p>
  <p class="price-text"
    *ngIf="(event.preciodesde <= 0 && event.imageurl.includes('http')) || event?.informativo || event?.external"><span
      class="price">+INFO</span></p>
  <p class="title-float-card">{{ event.titulo }}</p>
  <div class="info-float-event">
    <div *ngIf="!repeat" class="d-flex"><span><i class="fa-light icon-float fa-calendar"></i></span>
      <p class="m-0">{{ convertDateToStringFormat(event.datesRange?.next, 'dd') + ' de ' +
        (convertDateToStringFormat(event.datesRange?.next, 'MMMM') | titlecase) }}</p>
    </div>
    <div class="d-flex" *ngIf="event.hourStart">
      <span><i class="fa-light icon-float fa-clock fa-md"></i></span>
      <div class="d-flex columns-hours flex-wrap">
        <div class="d-flex columns-hours" *ngFor="let item of optionsHour; let i = index">
          <p class="m-0">
            {{ item.horaInicio | date:'HH:mm':'+0000' }}
          </p>
          <mat-divider [vertical]="true" *ngIf="i != optionsHour.length-1"></mat-divider>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex" *ngIf="event.extraLink">
      <span><i class="fa-light icon-float fa-link fa-md"></i></span>
      <a href="{{event?.extraLink}}" class="external-link" target="_blank">{{event?.extraLink}}</a>
    </div> -->
    <div class="d-flex">
      <span><i class="fa-light icon-float fa-location-dot"></i></span>
      <div>
        <p class="m-0">{{event?.localizacion}}</p>
        <a class="link-map m-0" *ngIf="!viewTotem" (click)="goToScrollPage('map')">Ver mapa</a>
      </div>
    </div>
  </div>
  <div *ngIf="!event?.informativo && !viewTotem">
    <div *ngIf="event.soldOut; else divCompleteTemplate">
      <div class="container-btns-select">
        <button class="btn-buy-event disabledBtn">Aforo completo</button>
      </div>
    </div>
    <ng-template #divCompleteTemplate>
      <div *ngIf="event.onlyTpvSales; else divTemplate">
        <div class="container-btns-select">
          <button class="btn-buy-event disabledBtn">Venta en taquilla</button>
        </div>
      </div>
      <!-- El evento oculto no se mostrará en las páginas principales pero si tienes el enlace si que permitirá comprar -->
      <ng-template #divTemplate>
        <!-- <div *ngIf="event?.hiddenEvent else divActive">
        <div class="container-btns-select">
          <button class="btn-buy-event disabledBtn">Venta no disponible</button>
        </div>
      </div>
      <ng-template #divActive> -->
        <div *ngIf="(!event?.informativo || !event.external || (event.external && event.url))">
          <div class="container-btns-select" *ngIf="!event.external && !pastEvent && disabledSales; else divEnabled">
            <button class="btn-buy-event disabledBtn">Próximamente</button>
          </div>
          <ng-template #divEnabled>
            <div class="container-btns-select" *ngIf="(!endSales) && !disabledSales; else divFinal">
              <button class="btn-buy-event pointer-in" *ngIf="!event?.external || event?.url"
                (click)="openEvent(event?.url)" [class.btn-event-totem]="viewTotem">
                {{ event?.external ? 'Quiero asistir' : 'Seleccionar entradas' }}</button>
              <button class="btn-buy-event btn-buy-bono pointer-in" [class.btn-event-totem]="viewTotem"
                *ngIf="!event?.external && !event.imageurl.includes('http') && enableAbono" (click)="buyAbono()">
                Comprar Abono</button>
              <!-- Comprobar si tiene entradas tipo festival -->
              <button class="btn-buy-event btn-buy-bono pointer-in" *ngIf="!enableAbono && ticketsBondEnabled"
                (click)="openTicketsBond()" [class.btn-event-totem]="viewTotem">
                Comprar Abono</button>
            </div>
            <ng-template #divFinal>
              <div class="container-btns-select" *ngIf="endSales">
                <button class="btn-buy-event" [class.btn-event-totem]="viewTotem" style="cursor: default;">
                  Finalizado</button>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </ng-template>
      <!-- </ng-template> -->
    </ng-template>
  </div>
</div>