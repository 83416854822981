import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatDialogModule} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { Location } from '@angular/common';
import { AppSettings } from '../../../../app-settings';
import { InfoCardComponent } from '../../../shared/info-card/info-card.component';
import { WarningAdminComponent } from '../../shared/warning-admin/warning-admin.component';


const bcrypt = require('bcryptjs');

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  user: FormGroup;
  userImage: FormGroup;
  salt = bcrypt.genSaltSync(10);
  imgVacia = false;
  message = '';
  apiMedia: string = AppSettings.MEDIA_URI;
  filePath = '';
  test = true;
  idU = null;

  constructor( private location: Location,
    private formBuilder: FormBuilder, private userService: UserService,
    private activatedRoute: ActivatedRoute,private dialog: MatDialog)  {

    this.user = this.formBuilder.group({
      email: ['',[Validators.required,Validators.email]],
      nombre: ['',Validators.required],
      password: this.generaPass(8),
      dni: '',
      movil: ['',[Validators.minLength(11),Validators.pattern('[0-9]{3}[ ][0-9]{3}[ ][0-9]{3}')]],
      fnacimiento:[''],
      oficial: false,
      nickname: ['',Validators.required],
      imagen: '',
      condaccept:false,
    })

    }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(u => this.idU = u.id)
    this.getData();
  }

  getData(){
    if(this.idU) {
      this.userService.getUserbyId(this.idU).subscribe(u => {
        if(u !=null){
          if(u.imagen){
            this.filePath = this.apiMedia + '/users/' + u.imagen;
          }
          this.user = this.formBuilder.group({
            email: [u.email,[Validators.required,Validators.email]],
            nombre: [u.nombre,Validators.required],
            dni: u.dni,
            movil: [u.movil,[Validators.minLength(11),Validators.pattern('[0-9]{3}[ ][0-9]{3}[ ][0-9]{3}')]],
            fnacimiento: u.fnacimiento,
            oficial: u.oficial,
            nickname: [u.nickname,Validators.required],
            imagen: u.imagen,
          })
        }
      })
    }

  }

  submit(){
    if(this.user.valid){
      if(this.idU){
         this.userService.putUser(this.idU,this.user.value).subscribe(data => {
          if (!data.status) { 
            if( this.filePath !== this.apiMedia +'/users/'+ this.user.value.imagen){
              this.userImage = this.formBuilder.group({
                userId: data.id,
                file: this.filePath
              })
              this.userService.uploadFilePut(this.userImage.value).subscribe()
            }
          } 
          if(data['@id']){
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Usuario editado correctamente",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();
          });
        }else{
          const dialogRef =  this.dialog.open(WarningAdminComponent, {
            width: '400px',
            height: '350px',
            data: {
              text: "Error al editar el usuario",
              icon: "fas fa-times-circle",
              confirm:"true"
            },
          })
          dialogRef.afterClosed().subscribe(result => {
            this.location.back();
          });

        }
      })
    }else{
        this.userService.postUser(this.user.value).subscribe(data =>{
          if(!data.status){
            if(this.filePath !== ''){
              this.userImage = this.formBuilder.group({
                userId: data.id,
                file: this.filePath
              })
              this.userService.uploadFilePost(this.userImage.value).subscribe();
            }
          }
          if(data.code == '200'){
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Usuario creado correctamente",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();
            });
          }else{
 
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Error al crear el usuario",
                icon: "fas fa-times-circle",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();

        });

    }
  })
      }
    }
    else {
      this.message = "¡ Rellena correctamente los campos !";
    }
  }

  openDialog(): void {
    this.location.back();
  }


  //Imagen
  onImageChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.user.addControl('file', new FormControl(''));
      const reader = new FileReader();
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          this.filePath = imageSrc;
          this.user.patchValue({
            file: imageSrc

          });
        };
      }
    }
  }

  //Generación aleatoria contraseña para crear usuario.
  generaPass(n:number){
    let pass = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789()[]{}@*`_-<>,.:;#=/"&$%'
    for (let i = 0; i < n; i++){
      pass += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    var hash = bcrypt.hashSync(pass, this.salt);
    return hash;
  }

  phoneM(event){
    var aux = (event.target as HTMLInputElement).value;
    if(aux.length == 3 || aux.length == 7){
      this.user.controls.movil.setValue(aux + ' ')
    }
  }

}
