<div class="form-tickets-lok">
    <div class="container-tickets">
        <!-- Free -->
        <!-- <div class="d-flex align-items-center justify-content-between section-free">
            <p class="title-free">Entrada libre</p>
            <mat-slide-toggle [(ngModel)]="free"></mat-slide-toggle>
        </div> -->
        <mat-divider></mat-divider>
        <!-- Precio desde de entradas -->
        <div class="input-price section-free" *ngIf="!free">
            <p class="title-free">Precio desde</p>
            <div class="input-border input-ticket">
                <input placeholder="Precio desde" type="number" min="0" [(ngModel)]="minPrice" />
                <i class="fa-light fa-euro-sign icon-primary"></i>
            </div>
            <div class="info-send-tickets">
                <p class="m-0">
                    <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>
                    El precio desde es el precio mínimo que se mostrará en la web, en caso de no indicarlo será el
                    de la
                    entrada más económica.
                </p>
            </div>
        </div>
        <mat-divider *ngIf="!free"></mat-divider>
        <div *ngIf="!free">
            <!-- Select map -->
            <!-- Info de evento online -->
            <div class="info-section-map mb-0" *ngIf="online && profile?.admin">
                <p class="m-0">
                    <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Para poder asignar un
                    Mapa de butacas deberá asignar un lugar.
                </p>
            </div>
            <div *ngIf="(profile?.site || profile?.admin) && ((mapSelect && !editEvent) || !mapSelect)" [class.disableOnline]="online">
                <div class="d-flex align-items-center justify-content-between section-free pointer-in"
                    (click)="openMapList()">
                    <div>
                        <p class="title-free">{{ mapSelect ? 'Mapa:' : 'Seleccionar mapa de asientos' }} <span
                                class="name-map" *ngIf="mapSelect">{{mapSelect.name}}</span></p>
                        <p class="m-0 info-send-tickets" *ngIf="mapSelect">Puedes editar el mapa desde la sección
                            "Editar mapa de butacas".</p>
                    </div>
                    <i class="fa-light fa-angle-right"></i>
                </div>
            </div>
            <mat-divider></mat-divider>
            <!-- <div>
                <div class="d-flex align-items-center justify-content-between section-free pointer-in"
                    (click)="openListTickets()">
                    <div>
                        <p class="title-free">Crear entradas</p>
                        <p class="m-0 info-send-tickets">Configura la fecha de inicio o el máximo número de entradas</p>
                    </div>
                    <i class="fa-light fa-angle-right"></i>
                </div>
                <mat-divider></mat-divider>
            </div> -->
            <div style="margin-bottom: 20px;">
                <div class="d-flex align-items-center justify-content-between add-section" style="margin-top: 50px;">
                    <p class="title-price m-0">Entradas:</p>
                    <div>
                        <button *ngIf="(profile?.admin || profile?.site) && mapSelect" class="btn primary-button solid"
                        (click)="mapSelect ? openEdit() : addSection()">Crear {{
                        mapSelect ? 'entrada' : 'nivel' }}</button>
                        <!-- <button *ngIf="(profile?.admin || profile?.site) && mapSelect" class="btn primary-button solid"
                            (click)="mapSelect ? openEdit() : addSection()">Crear {{ mapSelect ? 'entrada' : 'nivel'
                            }}</button> -->
                    </div>
                </div>
                <!-- Info de lo que son secciones -->
                <div class="info-section-map" *ngIf="profile?.admin">
                    <p class="m-0" *ngIf="!mapSelect">
                        <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Podrás crear
                        niveles para diferenciar las entradas de cada una, como Patio de butacas, Platea o 1º
                        Anfiteatro.
                    </p>
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="!loadingTickets; else divLottie">
                    <div *ngFor="let section of sectionTickets">
                        <div *ngIf="section.name">
                            <div class="section-item">
                                <div class="color-section" [style.background]="section.color"></div>
                                <p class="m-0">{{section.name}}</p>
                            </div>
                            <mat-divider></mat-divider>
                        </div>

                        <div *ngFor="let ticket of entradas; let i = index">
                            <div *ngFor="let priceTicket of getPriceTickets(ticket, section)">
                                <div class="d-grid align-items-center section-ticket pointer-in"
                                    (click)="openEdit(ticket, i)">
                                    <div class="d-flex align-items-center">
                                        <i class="fa-solid fa-circle icon-circle"
                                            [class.specialTime]="!ticket.specialTime"></i>
                                        <i class="fa-light fa-square-check icon-check"></i>
                                    </div>
                                    <span class="name-ticket">{{ ticket.nombre }}</span>
                                    <span class="price-col">{{ priceTicket.price }}€</span>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="sectionTickets.length <= 0">
                        <div *ngFor="let ticket of entradas; let i = index">
                            <div *ngFor="let priceTicket of getPriceTickets(ticket, section)">
                                <div class="d-grid align-items-center section-ticket pointer-in"
                                    (click)="openEdit(ticket, i)">
                                    <div class="d-flex align-items-center">
                                        <i class="fa-solid fa-circle icon-circle"
                                            [class.specialTime]="!ticket.specialTime"></i>
                                        <i class="fa-light fa-square-check icon-check"></i>
                                    </div>
                                    <span class="name-ticket">{{ ticket.nombre }}</span>
                                    <span class="price-col">{{ priceTicket.price }}€</span>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                    </div>

                    <!-- Nueva -->
                    <div *ngIf="sectionTickets.length <= 0 || !mapSelect">
                        <div class="d-flex align-items-center justify-content-center add-ticket pointer-in"
                            (click)="openEdit()">
                            <p class="title-add">Añadir entrada</p>
                            <div class="btn-add-ticket pointer-in">
                                <i class="fa-regular fa-plus"></i>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <ng-template #divLottie>
                    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                            containerClass="loading-box"></ng-lottie>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <button class="btn-new-form btn-save w-100" (click)="save()">GUARDAR</button>
</div>