import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { EventosService } from 'src/app/services/eventos.service';
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Observable } from 'rxjs';
import { ComentariosService } from 'src/app/services/comentarios.service';
import { MapsService } from 'src/app/services/maps.service'
import { element } from 'protractor';
import { HoursDatesEventComponent } from '../bloques/hours-dates-event/hours-dates-event.component';
import { MatDialog } from '@angular/material/dialog';
import { FechasService } from 'src/app/services/fechas.service';
import { FavoritosService } from 'src/app/services/favoritos.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from '../shared/info-card/info-card.component';
import { TicketsService } from 'src/app/services/tickets.service';
import { SubscriberFrontComponent } from '../subscriber-front/subscriber-front.component';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})

export class EventComponent implements OnInit {

  event!: any;
  slug!: string;
  fragment!: string;
  selectLugar: string;
  eventosRelacionados: Observable<any[]>;
  loaded: boolean = false;
  owner!: any;
  external!: boolean;

  // Aux
  today: any = new Date();
  imagesSwiper = [];
  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';
  urlFiles: string = AppSettings.MEDIA_URI + '/images/events/';

  // Comments
  totalItems: number = 0;

  hashtags: any[] = [];
  sharedSupported: boolean = false;

  profile!: any;
  isAdmin: boolean = false;
  adminCitylok: boolean = false;
  isGestor: boolean = false;
  isWishlist: boolean = false;
  isLite: boolean = false;
  openExternal: boolean = false;
  widthContainer!: any;
  loading: boolean = true;
  imagenUrl: string = '../../../assets/img/image_default.png';
  pastEvent: boolean = false;
  organizer: boolean = false;
  userMe!: any;
  createdBy!: any;
  isPlace: boolean = false;
  venuesUser: any[] = [];
  isMember: boolean = false;
  isDraft: boolean = false;
  imageUrl: string = '';

  repeatEvent: boolean = false;
  viewGoogleMap: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private eventosService: EventosService, private meta: Meta, private title: Title,
    private router: Router, private comentarios: ComentariosService, private mapas: MapsService, private sanitizer: DomSanitizer,
    private dialog: MatDialog, private fechasService: FechasService, private ticketsService: TicketsService,
    private favoritos: FavoritosService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.widthContainer = window.innerWidth;
    const navigator = window.navigator as any;
    if (navigator.share) {
      this.sharedSupported = true;
    }
    this.profile = this.auth.profileStorage.pipe();
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe(params => {
      let slug: string = params.slugEvent;
      if (!slug && location.href.indexOf('teatroideal') != -1) {
        slug = params.shortUrl;
      }
      if (!slug) {
        this.router.navigate(['/not-found']);
      }
      this.slug = slug;
      const firstIndex = slug.indexOf('-');
      let idEvent = slug.substring(0, firstIndex);

      this.eventosService.getEventById(idEvent).subscribe({
        next: (data) => {
          if (!data) {
            this.router.navigate(['/not-found'])
          }
          this.event = data;
          this.createdBy = data.company;
          this.auth.profileStorage.subscribe({
            next: (data) => {
              if (data) {
                this.venuesUser = data.venues?.map(value => value.place.id);
                if (this.venuesUser.length > 0 && this.venuesUser.includes(this.event?.place?.id)) {
                  this.isMember = true;
                }
              }
            }
          });
          if (this.event.imageurl && this.event.external && this.event.imageurl.includes('http')) {
            this.imageUrl = this.event.imageurl;
          } else {
            this.imageUrl = this.urlImages + this.event.id + '/' + this.event.imageurl
          }

          // Metaetiquetas
          this.title.setTitle('Citylok - ' + this.event.titulo);
          this.meta.updateTag({ name: 'description', content: this.event.descripcion });
          this.meta.updateTag({ property: 'og:title', content: 'Citylok - ' + this.event.titulo });

          this.meta.updateTag({ property: 'og:description', content: 'Disfruta del evento "' + this.event.titulo + '". ¡No te lo pierdas!' });
          this.meta.updateTag({ property: 'og:image', content: this.imageUrl });
          this.meta.updateTag({ property: 'og:url', content: location.href });
          this.meta.updateTag({ property: 'og:type', content: 'event' });

          this.isPlace = data.place != null;
          let now = new Date();
          let next = new Date(this.event.datesRange.next);
          let end = null;
          if (this.event.datesRange.end) {
            end = new Date(this.event.datesRange.end);
          }
          if (this.event.hourStart) {
            let indexPoint = this.event.hourStart.indexOf(':');
            let hour = this.event.hourStart.substring(0, indexPoint);
            let minutes = this.event.hourStart.substring(indexPoint + 1);
            next.setHours(+hour);
            next.setMinutes(+minutes);
            if (end) {
              end.setHours(+hour);
              end.setMinutes(+minutes);
            }
          } else {
            next.setHours(23);
            next.setMinutes(59);
            if (end) {
              end.setHours(23);
              end.setMinutes(59);
            }
          }
          if ((now >= next && !end) || (end && now >= end)) {
            this.pastEvent = true;
          }
          this.isAdmin = this.auth.getProfile()?.admin;
          this.adminCitylok = this.auth.getProfile()?.citylok;
          this.isLite = this.auth.getProfile()?.lite;
          // this.isGestor = this.auth.getProfile()?.gestor;
          this.isGestor = this.auth.getProfile()?.roleFunctions.find((value) => value.company.id == this.event.company?.id);

          let pathUrl = window.location.href;
          this.isDraft = pathUrl.indexOf('draft') != -1;
          if (!this.isDraft && !this.event.activo && (!this.auth.getProfile() || this.auth.getProfile().id != this.event.usuario.id) && !this.auth?.getProfile().citylok && !this.isGestor) {
            this.router.navigate(['/not-found'])
          }
          if (data.external && data.imageurl) {
            this.imagenUrl = data.imageurl;
          }
          if (!data.external && data.imageurl) {
            this.imagenUrl = AppSettings.MEDIA_URI + '/images/events/' + data.id + '/' + data.imageurl;
          }
          this.hashtags = data.hashtags;
          window.scrollTo(0, 0);
          this.urlImages += data.id + '/' + data.imageurl;
          let user = this.auth.getProfile();
          let index = data.favoritos?.findIndex(value => value.id = user?.id);
          if (index != -1) {
            this.isWishlist = true;
          }

          const fechasActivas: any[] = data.fechas.filter(value => value.activo);
          if (fechasActivas.length > 0) {
            const horarios = data.horarios.filter(value => value.activo);
            this.fechasService.getFechasHours(fechasActivas[0], horarios);
            this.repeatEvent = fechasActivas[0].repeat;
          }

          this.external = data.external == false ? false : true;
          this.owner = data.usuario;
          this.imagesSwiper = data.imagenes;
          const idCategory = data.categoria?.id;
          const idCity = data.ciudad?.id;
          this.comentarios.getComentarios(this.event.id).subscribe(res => this.totalItems = res?.totalItems);
          this.eventosRelacionados = this.eventosService.getEventsByIds(idCategory, idCity).pipe();
          if (this.event.usuario.id == this.auth.getProfile()?.id) {
            this.organizer = true;
          } else {
            this.auth.getMyData().subscribe({
              next: (data) => {
                let organizerEvents = data.organizerEvents?.map(value => value.id);
                if (organizerEvents?.includes(+idEvent)) {
                  this.organizer = true;
                }
              }
            });
          }

          this.meta.updateTag({ property: 'event:start_time', content: String(next) });
          this.meta.updateTag({ property: 'event:end_time', content: String(end) });
          this.meta.updateTag({ property: 'event:location', content: this.event.localizacion });
          this.meta.updateTag({ property: 'event:description', content: this.event.descripcion });

          this.loading = false;
        },
        error: (e) => this.router.navigate(['/not-found'])
      })

      const commentarios: string = params.comments;
      if (commentarios) {
        setTimeout(() => {
          document.getElementsByClassName('content-comments')[0].scrollIntoView({ behavior: "smooth" })
        }, 1000);
      }
    });
  }

  ngAfterContentChecked() {
    if (this.fragment) {
      this.goToScrollPage(this.fragment);
    }
  }

  editActiveEvent() {
    if (this.isAdmin || this.adminCitylok) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: true,
          text: '¿Estás seguro de que desea desactivar el evento?',
          warning: true,
          btnCancel: true
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.eventosService.putEventos(this.event.id, { activo: !this.event.activo }).subscribe({
            next: (data) => {
              this.event.activo = !this.event.activo;
              this.openInfoDialog('Los cambios se han guardado correctamente.', false, false);
            },
            error: () => {
              this.openInfoDialog('¡Vaya! Algo ha fallado, vuelve a intentarlo más tarde.', true, false);
            }
          })
        }
      })
    }
  }

  openInfoDialog(msg: string, icon: boolean, btnCancel: boolean) {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: btnCancel
      }
    });
  }

  openNewSubscriber() {
    if (this.venuesUser.length > 0 && this.venuesUser.includes(this.createdBy.id)) {
      this.openInfoDialog('Ya eres miembro de este espacio.', false, false);
      return;
    }
    this.dialog.open(SubscriberFrontComponent, {
      panelClass: ['info-dialog', 'not-padding', 'dialog-subscribers'],
      data: {
        idPlace: this.event.place?.id,
        user: this.profile,
        place: this.event.place,
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.openInfoDialog(res.msg, false, false);
      }
    });
  }

  getIds(url: string) {
    if (url) {
      const index = url.lastIndexOf('/');
      return url.substring(index + 1);
    }
    return '';
  }

  // Links
  async shareEvent() {
    if (this.sharedSupported) {
      let shareData: any = {
        title: this.event.titulo,
        text: 'Mira el evento que he encontrado',
        url: AppSettings.FRONT + '/event/' + this.event.id + '-' + this.event.titulo.replaceAll(' ', '-')
      };

      navigator.share(shareData).catch((error) => {
        if (AppSettings.DEBUG) {
          console.log('Error sharing', error)
        }
      });
    }
  }

  goToScrollPage(idPage: string) {
    var height = document.getElementById(idPage).offsetTop - 30;
    window.scrollTo(0, height);
  }

  @HostListener('window:resize')
  getWidthContainer() {
    this.widthContainer = window.innerWidth;
    if (this.widthContainer < 1200) {
      this.openExternal = false;
    }
  }

  openMapEvent() {
    this.router.navigate(['/edit-event/map', this.event.mapEvent?.id, this.event.id]);
  }

  openHoursEvent(emit: boolean) {
    if (window.innerWidth > 1200) {
      this.openExternal = emit;
    } else {
      if (emit) {
        const dialogRef = this.dialog.open(HoursDatesEventComponent, {
          panelClass: 'hours-lok-dialog',
          disableClose: true,
          data: { event: this.event, organizer: this.organizer }
        })
      } else {
        this.openExternal = emit;
        this.dialog.closeAll();
      }
    }
  }

  // Dates and tags Strings
  tagString(card: any) {
    return (this.eventosService.getTagString(card));
  }

  tagIcon(card: any) {
    return (this.eventosService.getTagIcon(card));
  }

  datesEquals(card: any) {
    let fechaCard = card.fechaInicio;
    if (card.datesRange) {
      fechaCard = card.datesRange.next || card.datesRange.start;
    }
    return (this.eventosService.datesEquals(fechaCard));
  }

  getDateString() {
    let fecha = this.event.datesRange?.start;
    let stringDate = 'Hoy';
    if (this.repeatEvent) {
      stringDate = 'Varias fechas disponibles';
    } else {
      if (!this.datesEquals(fecha)) {
        stringDate = this.eventosService.ConvertDateToStringFormat(fecha);
      }
      if (this.event.hourStart) {
        let hoursEvent = this.event.horarios.filter(hor => hor.activo == true);
        let optionsHour = hoursEvent.sort((a: any, b: any) => {
          return new Date(a.horaInicio).getTime() - new Date(b.horaInicio).getTime();
        })
        // stringDate += ' | ' + this.event.hourStart + 'h';
        optionsHour.forEach(element => {
          let date = new Date(element.horaInicio);
          let hour = formatDate(date, 'HH:mm', 'es-ES', '+0000')
          stringDate += ' | ' + hour + 'h';
        });
      }
    }
    return stringDate;
  }

  editFavorito() {
    this.isWishlist ? this.deleteFavorito() : this.addFavorito();
  }

  addFavorito() {
    if (this.profile) {
      this.favoritos.postFavoritos(this.event.id).subscribe(res => {
        if (res) {
          this.isWishlist = true;
        }
      })
    } else {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Inicia sesión y empieza a guardar tus eventos favoritos',
          btnCancel: false
        }
      });
    }
  }

  deleteFavorito() {
    if (this.profile) {
      this.favoritos.deleteFavorito(this.event.id).subscribe(res => {
        if (res) {
          this.isWishlist = false;
        }
      })
    } else {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Inicia sesión y empieza a guardar tus eventos favoritos',
          btnCancel: false
        }
      });
    }
  }

  shareSocialEvent(type: string) {
    const href = location.href;
    let typeOpen = '_blank';
    let url = ``;
    switch (type) {
      case 'twitter':
        url += `https://www.twitter.com/intent/tweet?text=${this.event.titulo}&url=${href}&hashtags=${this.event.hashtags}`;
        break;
      case 'linkedin':
        url += `https://www.linkedin.com/sharing/share-offsite/?url=${href}`;
        break;
      case 'facebook':
        url += `https://www.facebook.com/sharer/share.php?u=${href}`;
        break;
      case 'envelope':
        url += `mailto:hola@citylok.com?subject=${this.event.titulo}&body=${this.event.descripcion}`;
        typeOpen = '_self';
        break;
      case 'default':
        this.shareEvent();
        break;
    }
    window.open(url, typeOpen)

  }

  showGoogleMap() {
    this.viewGoogleMap = true;
    setTimeout(() => {
      this.mapas.loadMaps(this.event.latitude, this.event.longitude, this.event.localizacion).then(() => {
        this.loading = false;
      });
    }, 500);
  }

}
