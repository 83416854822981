import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-map-venue',
  templateUrl: './map-venue.component.html',
  styleUrls: ['./map-venue.component.scss']
})
export class MapVenueComponent {

  @Input() venue!: any;
  map!: any;
  capacity!: any;
  business = JSON.parse(localStorage.getItem('business') || '{}');

  openedCreateMap: boolean = false;
  editCapacity: boolean = false;

  constructor(private placeService: PlacesService, private dialog: MatDialog) { }

  ngAfterViewInit(): void {
    let url = location.pathname.split('/');
    let idVenue = +url[url.length - 1];
    this.placeService.getPlaceById(idVenue).subscribe({
      next: (res: any) => {
        this.venue = res;
        this.capacity = res.capacity;
        this.map = res.mainMap;
      }
    });
  }

  createMap() {
    this.openedCreateMap = !this.openedCreateMap;
    let action = !this.openedCreateMap ? 'close' : 'edit';
    this.placeService.setActionMap({ action: action });
  }

  ngOnDestroy(): void {
    this.placeService.setActionMap(null);
  }

  changeCapacity() {
    if (this.editCapacity) {
      this.placeService.updateVenue(this.venue.id, { capacity: this.capacity }).subscribe({
        next: (result) => {
          this.openInfoCard('Los cambios se han guardado correctamente.', false);
        },
        error: (err) => {
          this.openInfoCard('¡Vaya! No se han podido guardar los cambios, inténtalo de nuevo más tarde.');
        }
      });
    }
    this.editCapacity = !this.editCapacity;
  }

  openInfoCard(msg: string, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

}
