<div class="card-change-business">
    <div class="head-dialog-change title-dialog-edit d-flex justify-content-between align-items-center">
        <p class="title-change m-0">Actualizar Evento - {{nameValue}}</p>
        <div class="icon-close" mat-dialog-close="true">
            <i class="fal fa-times"></i>
        </div>
    </div>

    <div class="body-change-lok">
        <form [formGroup]="changeForm">
            <div class="bloque-change-input container-form d-flex align-items-center password-input"
                *ngIf="valueChange == 'titulo'">
                <input class="w-100" type="text" placeholder="Título" formControlName="titulo" required>
            </div>
            <div class="bloque-change-input container-form d-flex align-items-center password-input"
                *ngIf="valueChange == 'extraLink'">
                <input class="w-100" type="text" placeholder="Link" formControlName="extraLink">
            </div>
            <div class="content-input w-100" *ngIf="valueChange == 'hashtags'">
                <span class="m-0 subtitle-form">Hashtags</span>
                <mat-form-field class="label-title w-100" appearance="fill">
                    <mat-chip-grid #chipList aria-label="#Hashtags">
                        <mat-chip-row *ngFor="let item of hashtags" (removed)="removeHashtag(item)">
                            #{{item}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>

                    <div class="bloque-change-input container-form d-flex align-items-center password-input">
                        <input style="font-size: 16px;" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addHashtag($event)">
                    </div>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="d-flex justify-content-center btn-change">
        <button class="btn-new-form btn-save w-100" (click)="changeEvent()">Actualizar</button>
    </div>
</div>