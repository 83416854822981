<div class="card-change-password">
    <p class="title-change">Cambiar contraseña</p>

    <div>
        <form [formGroup]="changeForm">
            <div class="bloque-change-password container-form d-flex align-items-center password-input">
                <input class="w-100" type="{{ hideActual ? 'password' : 'text' }}" placeholder="Contraseña actual"
                    formControlName="password" required>
                <i (click)="hideActual = !hideActual"
                    class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
            </div>

            <div class="bloque-change-password container-form d-flex align-items-center pass-new"
                [class.invalid]="pass.invalid && (pass.dirty || pass.touched)">
                <input class="w-100" type="{{ hide ? 'password' : 'text' }}" placeholder="Contraseña"
                    formControlName="textPassword" required>
                <i (click)="hide = !hide"
                    class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
            </div>

            <div class="validate-msg" *ngIf="pass.invalid && (pass.dirty || pass.touched)">
                <div class="d-flex align-items-center">
                    <i class="fa-light {{ pass.errors?.['hasSmallCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener minúsculas.</p>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa-light {{ pass.errors?.['hasCapitalCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener mayúsculas.</p>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa-light {{ pass.errors?.['hasNumber'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener números.</p>
                </div>
                <div class="d-flex align-items-center">
                    <i
                        class="fa-light {{ pass.errors?.['minlength'] || pass.errors?.['required'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener al menos 8 caracteres.</p>
                </div>
                <p *ngIf="pass.errors?.['maxlength']">No debe tener más de 30 caracteres.</p>
            </div>

            <div class="bloque-change-password container-form d-flex align-items-center">
                <input class="w-100" type="{{ hideConfirm ? 'password' : 'text' }}" placeholder="Confirmar contraseña"
                    formControlName="confirmPassword" required>
                <i (click)="hideConfirm = !hideConfirm"
                    class="fa-regular {{ hideConfirm ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
            </div>
            <div class="validate-msg"
                *ngIf="(repPass.dirty || repPass.touched) && changeForm.errors?.['passwordNotMatch']">
                <p *ngIf="changeForm.errors?.['passwordNotMatch']">Las contraseñas no coinciden.</p>
            </div>

            <div class="d-flex justify-content-center btn-change">
                <button class="primary-button solid w-100" style="min-height: 40px;"
                    (click)=changePassword()>Actualizar</button>
            </div>
        </form>
    </div>
</div>