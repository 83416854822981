import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { CardValidateComponent } from '../../card-validate/card-validate.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  urlImages: string = AppSettings.MEDIA_URI + '/images/users/';
  profile!: any;
  userForm: FormGroup;
  valueInit: any;

  hidden: boolean = false;
  hidden2: boolean = false;

  imgControl: FormControl = new FormControl(null);
  imgName: string = '';
  changed: boolean = false;
  changedEmail: boolean = false;
  changedImg: boolean = false;
  enabledPassword: boolean = true;

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private auth: AuthenticationService, private location: Location,
    private dialog: MatDialog) {
    this.userForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      descripcion: [],
      email: [],
      movil: [],
      fnacimiento: []
    })
  }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.enabledPassword = !this.profile?.organizer;
    this.userService.getUserbyId(this.auth.user).subscribe({
      next: (usr) => {
        this.profile = usr;
        this.valueInit = {
          'nombre': this.profile.nombre,
          'descripcion': this.profile.descripcion,
          'email': this.profile.username,
          'movil': this.profile.movil,
          'fnacimiento': this.profile.fnacimiento
        };
        this.userForm.setValue(this.valueInit);
        if (usr.imagen) {
          this.imgControl.setValue(this.urlImages + usr.imagen);
        }
        this.userForm.valueChanges.subscribe((data) => {
          this.changeEmail();
          this.changedEmail = this.userForm.controls['email'].value != this.profile.username
          this.changed = JSON.stringify(data) != JSON.stringify(this.valueInit) || this.changedImg;
        })
      }
    });
  }

  goBack() {
    this.location.back();
  }

  onFileChange(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (!file.type.match('image.*')) {
        alert('Solo se permite utilizar imagenes');
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        filePath = reader.result as string;
        this.imgName = file.name;
        this.imgControl.setValue(filePath);
        this.changedImg = true;
        this.changed = true;
      }
    }
    if (!event.target.files) {
      if (this.profile.imagen) {
        this.imgControl.setValue(this.urlImages + '/' + this.profile.imagen);
      }
    }
  }

  saveImg() {
    this.userService.uploadFilePut({ user: this.profile.id, file: this.imgControl.value }).subscribe({
      next: (res) => {
        res ? this.openInfoCard() : this.openError();
      },
    })
  }

  openChangePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'info-dialog',
      data: {
        user: this.profile
      }
    })
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if(res == 'changed') {
          this.openInfoCard();
        } 
        if(res == 'error') {
          this.dialog.open(InfoCardComponent, {
            panelClass: 'info-dialog',
            data: {
              icon: true,
              text: '¡Vaya! Ha ocurrido un error.',
              btnCancel: false
            }
          });
        }
      }
    })
  }

  changeEmail() {
    if (this.userForm.controls['email'].value != this.profile.username && !this.changedEmail) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        disableClose: true,
        data: {
          icon: true,
          text: '¿Estás seguro de que quieres actualizar el correo electrónico? Se cerrará sesión al guardar los cambios.',
          btnCancel: true
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        this.changedEmail = res;
        if (!res) {
          this.userForm.controls['email'] = this.profile.username;
        }
      })
    }
  }

  saveChanges() {
    if (this.userForm.valid) {
      this.userService.putUser(this.profile.id, this.userForm.value).subscribe({
        next: (data) => {
          if (data) {
            this.changedImg ? this.saveImg() : this.openInfoCard();
          }
        }
      })
    }
  }

  openInfoCard() {
    this.changedImg = false;
    this.changed = false;
    this.changedEmail = false;
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: 'Los cambios se han guardado correctamente',
        btnCancel: false
      }
    });
  }

  openError() {
    this.userService.putUser(this.profile.id, this.valueInit).subscribe({
      next: (data) => {
        this.userForm.setValue(this.valueInit);
        this.imgControl.reset();
        if (this.profile.imagen) {
          this.imgControl.setValue(this.urlImages + '/' + this.profile.imagen);
        }
        this.changedImg = false;
        this.changed = false;
        this.changedEmail = false;
      }
    })
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¡Vaya! Ha ocurrido un error inesperado, vuelva a intentarlo más tarde',
        btnCancel: false
      }
    });
  }

  openValidatePhone() {
    const dialogRef = this.dialog.open(CardValidateComponent, {
      panelClass: 'info-dialog',
      data: {
        title: 'Cambiar número de teléfono'
      }
    });
    dialogRef.afterClosed().subscribe({
      next: res => {
        if (res && res == 'validate') {
          const dialogConfirm = this.dialog.open(InfoCardComponent, {
            panelClass: 'info-dialog',
            data: {
              icon: false,
              text: 'El teléfono se ha verificado correctamente.',
              btnCancel: false
            }
          });
          dialogConfirm.afterClosed().subscribe(() => location.reload());
        }
      }
    })
  }

}
