import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxPayPalModule } from 'ngx-paypal';
// import { NgFallimgModule } from 'ng-fallimg';

/**
 * JWT
 */
import { JwtInterceptor } from './helpers/jwt.interceptor';

/**
 * Components
 */
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { MaterialModule } from './material/material.module';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { LoginComponent } from './components/shared/login/login.component';
import { RegisterComponent } from './components/shared/register/register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardsModule } from './components/bloques/cards.module';
import { EventComponent } from './components/event/event.component';
import { SwiperModule } from 'swiper/angular';
import { SearchComponent } from './components/search/search.component';
import { CategoryPageComponent } from './components/categories/category-page/category-page.component';
import { NotFoundPageComponent } from './components/shared/not-found-page/not-found-page.component';
import { LottieModule } from 'ngx-lottie';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import localeEs from '@angular/common/locales/es';
import { PaginatorResultsComponent } from './components/shared/paginator-results/paginator-results.component';
import { PrivacyComponent } from './components/shared/static-pages/privacy/privacy.component';
import { CookiesComponent } from './components/shared/cookies/cookies.component';
import { FiltersComponent } from './components/shared/filters/filters.component';
import { CustomCalendarComponent } from './components/shared/custom-calendar/custom-calendar.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PageOwnerComponent } from './components/page-owner/page-owner.component';
import { AdminModule } from './components/admin/admin.module';

import { ContactFormComponent } from './components/shared/static-pages/contact-form/contact-form.component';
import { SuggestionsComponent } from './components/shared/static-pages/suggestions/suggestions.component';
import { WarningComponent } from './components/shared/warning/warning.component';
import { TermsConditionsComponent } from './components/shared/static-pages/terms-conditions/terms-conditions.component';
import { FaqsComponent } from './components/shared/static-pages/faqs/faqs.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CityblogComponent } from './components/blog/cityblog/cityblog.component';
import { DetailPodsComponent } from './components/blog/detail-pods/detail-pods.component';
import { LastPostsComponent } from './components/blog/last-posts/last-posts.component';
import { ListMapComponent } from './components/list-map/list-map.component';
import { NewLokComponent } from './components/new-lok/new-lok/new-lok.component';
import { InfoCardComponent } from './components/shared/info-card/info-card.component';
import { DetailLokComponent } from './components/new-lok/detail-lok/detail-lok.component';
import { MapSearchComponent } from './components/new-lok/map-search/map-search.component';
import { DescriptionLokComponent } from './components/new-lok/description-lok/description-lok.component';
import { MainMenuComponent } from './components/shared/main-menu/main-menu.component';
import { StepCheckoutComponent } from './components/checkout/step-checkout/step-checkout.component';
import { ConfirmPayComponent } from './components/checkout/confirm-pay/confirm-pay.component';
import { WaitingPayComponent } from './components/checkout/waiting-pay/waiting-pay.component';
import { CheckoutPageComponent } from './components/checkout/checkout-page/checkout-page.component';
import { DatesHoursComponent } from './components/new-lok/dates-hours/dates-hours.component';
import { TicketsComponent } from './components/new-lok/tickets/tickets.component';
import { NewTicketComponent } from './components/new-lok/new-ticket/new-ticket.component';
import { ProfileComponent } from './components/profile/profile/profile.component';
import { FavoritesComponent } from './components/profile/favorites/favorites.component';
import { ProfileFavComponent } from './components/profile/profile-fav/profile-fav.component';
import { EventsFavComponent } from './components/profile/events-fav/events-fav.component';
import { InteresesComponent } from './components/profile/config/intereses/intereses.component';
import { EditProfileComponent } from './components/profile/config/edit-profile/edit-profile.component';
import { NotificationsComponent } from './components/profile/config/notifications/notifications.component';
import { TicketsProfileComponent } from './components/profile/config/tickets-profile/tickets-profile.component';
import { TicketsListComponent } from './components/profile/config/tickets-list/tickets-list.component';
import { EventsListComponent } from './components/profile/config/events-list/events-list.component';
import { ChangePasswordComponent } from './components/profile/config/change-password/change-password.component';
import { InputHourComponent } from './components/shared/input-hour/input-hour.component';
import { DefaultCalendarComponent } from './components/new-lok/default-calendar/default-calendar.component';
import { EditLokComponent } from './components/edit-lok/edit-lok.component';
import { CardValidateComponent } from './components/profile/card-validate/card-validate.component';
// import { HideEventComponent } from './components/shared/hide-event/hide-event.component';
import { LegalComponent } from './components/shared/static-pages/legal/legal.component';
import { CookiesTermsComponent } from './components/shared/static-pages/cookies-terms/cookies-terms.component';
import { SeatMapModule } from './components/seat-map/seat-map.module';
import { ProfileMapsComponent } from './components/profile/profile-maps/profile-maps.component';
import { MapsListComponent } from './components/new-lok/maps-list/maps-list.component';
import { ImageMapComponent } from './components/shared/image-map/image-map.component';
import { CartCheckoutComponent } from './components/profile/cart-checkout/cart-checkout.component';
import { NewSectionComponent } from './components/new-lok/new-section/new-section.component';
import { EventsDraftComponent } from './components/profile/events-draft/events-draft.component';
import { ConfigTicketsComponent } from './components/new-lok/config-tickets/config-tickets.component';
import { PanelAdminModule } from './components/panel-admin/panel-admin.module';
import { NewPasswordComponent } from './components/shared/new-password/new-password.component';
import { ControlPanelModule } from './components/control-panel/control-panel.module';
import { SubscriberFrontComponent } from './components/subscriber-front/subscriber-front.component';
import { ChangeNameComponent } from './components/profile/config/change-name/change-name.component';
import { StatusPayComponent } from './components/checkout/status-pay/status-pay.component';
import { SubscriptionsComponent } from './components/profile/config/subscriptions/subscriptions.component';
import { PaylandsTemplateComponent } from './components/checkout/paylands-template/paylands-template.component';
import { CreateEventComponent } from './components/control-panel/events/create-event/create-event.component';
import { PaymentSuccessComponent } from './components/checkout/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/checkout/payment-error/payment-error.component';
import { ChatbotComponent } from './components/shared/chatbot/chatbot.component';
import { InfoConditionsComponent } from './components/profile/info-conditions/info-conditions.component';
import { TotemModule } from './components/totem/totem.module';
import { SharedModule } from './components/shared/shared.module';
registerLocaleData(localeEs);

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    RegisterComponent,
    EventComponent,
    SearchComponent,
    CategoryPageComponent,
    NotFoundPageComponent,
    PaginatorResultsComponent,
    PrivacyComponent,
    CookiesComponent,
    FiltersComponent,
    CustomCalendarComponent,
    PageOwnerComponent,
    ContactFormComponent,
    SuggestionsComponent,
    WarningComponent,
    TermsConditionsComponent,
    FaqsComponent,
    AboutUsComponent,
    CityblogComponent,
    DetailPodsComponent,
    LastPostsComponent,
    ListMapComponent,
    NewLokComponent,
    InfoCardComponent,
    DetailLokComponent,
    DescriptionLokComponent,
    MainMenuComponent,
    StepCheckoutComponent,
    ConfirmPayComponent,
    WaitingPayComponent,
    CheckoutPageComponent,
    DatesHoursComponent,
    TicketsComponent,
    NewTicketComponent,
    ProfileComponent,
    FavoritesComponent,
    ProfileFavComponent,
    EventsFavComponent,
    InteresesComponent,
    EditProfileComponent,
    NotificationsComponent,
    TicketsProfileComponent,
    TicketsListComponent,
    EventsListComponent,
    ChangePasswordComponent,
    InputHourComponent,
    DefaultCalendarComponent,
    EditLokComponent,
    CardValidateComponent,
    // HideEventComponent,
    LegalComponent,
    CookiesTermsComponent,
    ProfileMapsComponent,
    MapsListComponent,
    ImageMapComponent,
    CartCheckoutComponent,
    NewSectionComponent,
    EventsDraftComponent,
    ConfigTicketsComponent,
    NewPasswordComponent,
    SubscriberFrontComponent,
    ChangeNameComponent,
    StatusPayComponent,
    SubscriptionsComponent,
    PaylandsTemplateComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    ChatbotComponent,
    InfoConditionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CardsModule,
    SwiperModule,
    PickerModule,
    AngularEditorModule,
    LottieModule.forRoot({ player: playerFactory }),
    AdminModule,
    NgxPayPalModule,
    NgxSkeletonLoaderModule,
    PanelAdminModule,
    SeatMapModule,
    ControlPanelModule,
    TotemModule,
    SharedModule,
    HammerModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent],
  exports: [
    CommonModule,
    MaterialModule,
    NgbModule,
    WarningComponent
  ]
})
export class AppModule { }
