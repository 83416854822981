import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  icon: boolean = true;
  text: string = "¿Estás seguro de que deseas cancelar? Se perderán todos los datos introducidos.";
  btnCancel: boolean = true;
  warning: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data) { 
    if(data) {
      this.icon = data.icon;
      this.text = data.text;
      this.btnCancel = data.btnCancel;
      this.warning = data.warning;
    }
  }

  ngOnInit(): void {
  }

}
