<div class="container" #container>
    <div class="title-cards d-flex pointer-in align-items-center" [class.color-white]="titleWhite"
        [class.pointer-in]="link" (click)="goToLink()">
        <p [class]="sizeTitle == 'small' ? 'h2' : 'h1' ">{{ title }}</p>
        <i *ngIf="arrow" class="fal fa-long-arrow-right icon-title fa-2x"></i>
    </div>
</div>

<div class="container-cards">
    <swiper #swiper class="swiper" [config]="small ? swiperSmallConfig : swiperBigConfig">
        <ng-template class="swiper-slide" [class]="small ? 'smallCards' : 'bigCards'"
            *ngFor="let n of arraySkeleton | async; let i = index" swiperSlide>
            <div class="container-card">
                <div class="container-img-skeleton">
                    <ngx-skeleton-loader [theme]="{height: '100%'}"></ngx-skeleton-loader>
                </div>
                <div class="info-card-skeleton d-grid">
                    <div class="d-flex align-items-center justify-content-between">
                        <ngx-skeleton-loader [theme]="{width: '25px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{width: '25px'}"></ngx-skeleton-loader>
                    </div>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </div>
            </div>
        </ng-template>
        <ng-template class="swiper-slide" [class]="small ? 'smallCards' : 'bigCards'"
            *ngFor="let card of events | async; index as i" swiperSlide>
            <app-card (mouseleave)="outHoverCard()" (mouseenter)="hoverCard(i)" [card]="card"></app-card>
        </ng-template>
    </swiper>
</div>