import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EntradasService } from 'src/app/services/entradas.service';
import { SeatMapService } from 'src/app/services/seat-map.service';
import { MapsListComponent } from '../maps-list/maps-list.component';
import { NewSectionComponent } from '../new-section/new-section.component';
import { NewTicketComponent } from '../new-ticket/new-ticket.component';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  @Output() saveTickets = new EventEmitter<any>();
  @Output() addTicket = new EventEmitter<any>();
  @Output() selectMap = new EventEmitter<any>();

  @Input() entradas: any[] = [];
  @Input() online: boolean = false;
  @Input() profile!: any;

  free: boolean = false;
  levelsMap!: any;
  @Input() idMap!: any;
  mapSelect!: any;
  editEvent: boolean = false;
  map!: any;

  sectionTickets: any[] = [];
  loadingTickets: boolean = true;
  minPrice!: any;

  constructor(private entradasService: EntradasService, @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<TicketsComponent>, private dialog: MatDialog,
    private seatMapService: SeatMapService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    if (this.data) {
      this.entradas = this.data.entradas;
      this.idMap = this.data.map?.id;
      this.map = this.data.map;
      this.editEvent = this.data.edit;
      this.online = this.data.online;
      this.profile = this.data.profile;
      this.minPrice = this.data.preciodesde;
    }
    if (this.idMap) {
      this.getMap();
    } else {
      this.loadingTickets = false;
    }
  }

  saveSpecialTimes() {

  }

  openEdit(ticket: any = true, i?: number) {
    let newTicket = null;
    if (ticket != true) {
      newTicket = ticket
    }
    const dialogRef = this.dialog.open(NewTicketComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { ticket: newTicket, levels: this.sectionTickets, map: this.mapSelect, fechas: this.data?.fechas, horarios: this.data?.horarios, optionsTime: this.data?.optionsTime }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (i != null) {
          this.entradas[i] = result;
        } else {
          this.entradas.push(result);
        }
      }
    });
  }

  openMapList() {
    if (!this.online) {
      this.selectMap.emit({ map: this.idMap, select: true, mapObject: this.map });
      if (this.data) {
        const dialogRef = this.dialog.open(MapsListComponent, {
          panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
          data: { map: this.idMap, select: true, mapObject: this.map }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.idMap = result.map;
            this.mapSelect = result.mapObject;
            this.getMap();
          }
        });
      }
    }
  }

  openListTickets() {

  }

  getPriceTickets(ticket, section) {
    let prices = ticket.priceTickets;
    if(this.sectionTickets.length > 0) {
      prices = ticket.priceTickets?.filter(value => value.level == section.id || value.level?.id == section.id);
    }
    return prices;
  }

  getMap() {
    this.seatMapService.getMap(this.idMap).subscribe({
      next: (data) => {
        this.mapSelect = data;
        this.sectionTickets = data.level_maps;
        this.loadingTickets = false;
      }
    })
  }

  addSection() {
    const dialogRef = this.dialog.open(NewSectionComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { map: this.idMap }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sectionTickets.push({ name: result.name, color: result.color, tickets: [] });
      }
    });
  }

  editSection(index: any) {
    const dialogRef = this.dialog.open(NewSectionComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { map: this.idMap, section: this.sectionTickets[index] }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sectionTickets[index].name = result.name;
        this.sectionTickets[index].color = result.color;
      }
    });
  }

  deleteSection(index: any) {
    if (!this.idMap) {
      this.sectionTickets.splice(index, 1);
    }
  }

  save() {
    const result = this.free ? { free: true, map: null } : { tickets: this.entradas, map: this.idMap, levels: this.sectionTickets, preciodesde: this.minPrice, mapObject: this.map };
    this.saveTickets.emit(result);
    if (this.data) {      
      this.dialogRef.close(result);
    }
  }

}
