<mat-form-field class="label-title w-100" appearance="fill">
    <div class="hashtags-input">
        <input style="font-size: 16px;" placeholder="Escribe un Hashtag y dale al intro" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addHashtag($event)">
    </div>
    <mat-chip-grid #chipList aria-label="#Hashtags">
        <mat-chip-row *ngFor="let item of hashtags" (removed)="removeHashtag(item)">
            #{{item}}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
</mat-form-field>