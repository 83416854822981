<div class="card-name-map">
    <p class="title-name-map">{{editMap ? 'Nombre del mapa de asientos' : 'Crear mapa' }}</p>
    <i class="fa-light fa-times icon-close" mat-dialog-close></i>

    <div class="bloque-change-name container-form d-flex align-items-center">
        <input class="w-100" type="text" placeholder="Nombre del mapa" [(ngModel)]="name" required>
    </div>
    <p *ngIf="!editMap">Antes de guardar el mapa es necesario asignar un nombre.</p>

    <div class="d-flex justify-content-center btns-save">
        <button class="primary-button solid w-100" style="min-height: 40px;" (click)=saveName()>{{editMap?'Aceptar':'Guardar'}}</button>
        <button class="cancel-button solid w-100" style="min-height: 40px;" mat-dialog-close>Cancelar</button>
    </div>
</div>