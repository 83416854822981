import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UserService } from 'src/app/services/user.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { CardValidateComponent } from '../card-validate/card-validate.component';
import jwt_decode from "jwt-decode";
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionService } from 'src/app/services/permission.service';
import { ApiBusinessService } from 'src/app/services/api-business.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile!: any;
  profileMe!: any;
  urlImages: string = AppSettings.MEDIA_URI + '/images/users';
  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };

  infoOptions: any[] = [
    // {
    //   icon: '',
    //   option: 'Sobre Citylok',
    //   link: '/about-us'
    // },
    // {
    //   icon: 'newspaper',
    //   option: 'Blog',
    //   link: '/cityblog'
    // },
    {
      icon: 'circle-question',
      option: 'Ayuda / FAQs',
      link: '/faqs'
    },
    // {
    //   icon: 'list-ul',
    //   option: 'Sugerencias',
    //   link: '/suggestions'
    // },
    {
      icon: 'comments',
      option: 'Contacta con Citylok',
      link: '/contact'
    },
    // {
    //   icon: 'gavel',
    //   option: 'Legales',
    //   link: '/privacy'
    // }
  ]

  openTab: boolean = false;
  titleTab!: string;
  notifications: any = { newsletter: false };
  subscriptions: any = [];
  profileAdmin: boolean = false;
  openedMapList!: boolean;
  openDraftsList!: boolean;

  constructor(private users: UserService, private auth: AuthenticationService,
    private dialog: MatDialog, private notificationsService: NotificationsService,
    private router: Router, private permissionService: PermissionService, private apiBusiness: ApiBusinessService) {
    let profile = this.auth.getProfile();
    let isAdmin = profile?.roleFunctions?.length > 0;
    if (isAdmin) {
      let url = '/panel-control/events';
      this.router.navigate([url]);
    }
  }

  ngAfterViewInit() {
    if (this.router.url.indexOf('seat-maps') != -1) {
      this.openTab = true;
      this.titleTab = 'Mapas de asientos';
      this.openedMapList = true;
    }
  }

  ngOnInit(): void {
    this.getData();
    var decoded: any = jwt_decode(localStorage.getItem('jwt_token') || '');
    if (decoded.roles?.includes("ROLE_ADMIN")) {
      this.profileAdmin = true;
    }
    this.notificationsService.getNotificationsUser().subscribe({
      next: (data) => {
        this.notifications = data;
      }
    })
    this.notificationsService.getSubscriptionsUser().subscribe({
      next: (data) => {
        if (data) {
          this.subscriptions = [data];
        }
      }
    })
  }

  getData() {
    const idUser = this.auth.user;
    this.profileMe = this.auth.getProfile();
    this.users.getUserbyId(idUser).subscribe(usr => {
      if (usr) {
        this.profile = usr;
      }
    });
  }

  deleteAccount() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que deseas eliminar tu cuenta? Todos tus datos se perderán.',
        btnCancel: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // alert('Eliminar');
        this.dialog.closeAll();
      }
    })
  }

  openTabSection(openTab: any) {
    this.getData();
    this.openTab = openTab;
    if (openTab == 3) {
      this.titleTab = 'Suscripciones';
      return;
    }
    this.titleTab = openTab == 1 ? 'Notificaciones' : 'Atrás';
  }

  openMapList() {
    this.openTab = true;
    this.openedMapList = true;
    this.titleTab = 'Mapas de asientos';
  }

  openListDrafts() {
    this.openTab = true;
    this.openDraftsList = true;
    this.titleTab = 'Eventos sin publicar';
  }

  backTab() {
    this.openTab = false;
    this.openedMapList = false;
    this.openDraftsList = false;
    this.openedMapList ? this.router.navigate(['/profile']) : this.saveNotifications(false);
  }

  saveNotifications(notifications: any) {
    this.openTab = false;
    if (notifications) {
      this.notifications = notifications;
      this.sendNotifications(notifications);
    }
  }

  saveNewsLetter(event: any) {
    this.notifications.newsletter = event;
    this.sendNotifications(this.notifications);
  }

  sendNotifications(notifications: any) {
    this.notificationsService.setNotifications(notifications.id, notifications).subscribe({
      next: () => {
        this.openInfoSave();
      }
    });
  }

  logout() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres cerrar sesión?',
        btnCancel: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auth.logout();
      }
    })
  }

  openInfoSave() {
    this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: false,
        text: 'Se han guardado los cambios correctamente',
        btnCancel: false
      }
    });
  }

  openValidate() {
    const dialogRef = this.dialog.open(CardValidateComponent, {
      panelClass: 'info-dialog'
    });
    dialogRef.afterClosed().subscribe({
      next: res => {
        if (res && res == 'validate') {
          const dialogConfirm = this.dialog.open(InfoCardComponent, {
            panelClass: 'info-dialog',
            data: {
              icon: false,
              text: 'El teléfono se ha verificado correctamente. ¡Ya puedes empezar a subir tus loks!',
              btnCancel: false
            }
          });
          dialogConfirm.afterClosed().subscribe(() => location.reload());
        }
      }
    })
  }

  openAdmin() {
    this.router.navigate(['/admin'])
  }

  openPanel() {
    if (this.profileMe.roleFunctions?.length > 0) {
      const business = JSON.parse(localStorage.getItem('business') || '{}');
      if (!business?.id) {
        this.apiBusiness.setBusinessStorage(this.profileMe.roleFunctions[0].company);
      }
      this.router.navigate(['panel-control/events/list']);
    }
  }

}
