import { Component, Inject, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ComunicationsService } from 'src/app/services/comunications.service';
import { NewSubscriberComponent } from '../new-subscriber/new-subscriber.component';

@Component({
  selector: 'app-new-comunication',
  templateUrl: './new-comunication.component.html',
  styleUrls: ['./new-comunication.component.scss']
})
export class NewComunicationComponent implements OnInit {

  emailSubscription: FormGroup = new FormGroup({});
  idCompany!: number;

  // editorConfig!: AngularEditorConfig;
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí el mensaje',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '2',
    fonts: [
      { class: 'arial', name: 'Arial' },
    ],
    // uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'fontName'
      ],
      [
        'textColor',
        'backgroundColor',
        'unlink',
        'insertVideo',
        'removeFormat',
      ]
    ]
  };

  @ViewChild('editor') editor;

  filePath = '';
  nameFile!: string;
  emailSend: boolean = false;
  files: any[] = [];
  isSizeExceeded: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<NewSubscriberComponent>, private formBuilder: FormBuilder,
    private comunicationService: ComunicationsService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.data ? this.idCompany = this.data.idCompany : null;
    this.emailSubscription = this.formBuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required],
      files: [null]
    });
    // this.editorConfig = this.comunicationService.editorConfig;
  }

  cancelSend() {
    this.dialogRef.close();
  }

  sendEmail() {
    this.emailSend = true;
    let payload = this.emailSubscription.value;

    // Usamos el body directamente sin sanitización
    let body = payload.body;

    if (this.emailSubscription.valid) {
      // Crear un nuevo FormData para enviar
      let payloadForm = {
        subject: payload.subject,
        body: body,
        files: []
      };

      // Verificar si hay archivos y convertirlos a base64
      if (payload.files && payload.files.length > 0) {
        let filesBase64 = [];  // Aquí almacenaremos los archivos convertidos a base64

        let processedFiles = 0; // Para contar cuántos archivos hemos procesado
        const totalFiles = payload.files.length; // Total de archivos a procesar

        // Procesar cada archivo
        payload.files.forEach((file: File) => {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            // Almacenar la representación base64 del archivo
            const fileBase64 = reader.result as string;
            filesBase64.push({
              name: file.name,
              base64: fileBase64
            });

            processedFiles++; // Incrementamos el contador

            // Cuando todos los archivos hayan sido procesados, los agregamos al FormData
            if (processedFiles === totalFiles) {
              // Agregar los archivos en base64 al FormData
              filesBase64.forEach(fileData => {
                payloadForm.files.push({name: fileData.name, file: fileData.base64});  // Agregar el archivo en base64
              });

              // Enviar el FormData con los archivos
              this.comunicationService.sendSubscribersEmail(this.idCompany, payloadForm).subscribe({
                next: (res) => {
                  this.dialogRef.close({ result: res });
                },
                error: (err) => {
                  this.emailSend = false;
                  this.dialogRef.close({ err: 'Se ha producido un error al mandar el mensaje.' });
                }
              });
            }
          };

          reader.onerror = (error) => {
            console.error("Error al leer el archivo", error);
            this.emailSend = false;
          };
        });
      } else {
        // Si no hay archivos, solo enviamos el resto del FormData
        this.comunicationService.sendSubscribersEmail(this.idCompany, payloadForm).subscribe({
          next: (res) => {
            this.dialogRef.close({ result: res });
          },
          error: (err) => {
            this.emailSend = false;
            this.dialogRef.close({ err: 'Se ha producido un error al mandar el mensaje.' });
          }
        });
      }
    }
  }


  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;
      let formData = new FormData();
      let fileArray = [];
      let totalSize = 0;

      // Iterar sobre los archivos y calcular el tamaño total
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        totalSize += file.size;  // Sumar el tamaño de cada archivo en bytes
        formData.append("files[]", file, file.name);  // Aquí se asegura de que el archivo sea tipo 'File'
        fileArray.push(file);
      }

      // Validar que el tamaño total no exceda los 7.5MB (7.5MB = 7.5 * 1024 * 1024 bytes)
      const maxSize = 7.5 * 1024 * 1024;  // 7.5MB en bytes
      if (totalSize > maxSize) {
        // Si el tamaño total supera el límite, mostrar un mensaje o desactivar el botón
        alert('El tamaño total de los archivos no puede superar los 7.5MB');
        this.isSizeExceeded = true;  // Activar el estado de error
        this.files = [];
        this.emailSubscription.patchValue({
          files: []
        });
        return;  // Detener la carga de archivos
      }

      // Si todo es correcto, guardar los archivos y actualizar el formulario
      this.files = fileArray;
      this.isSizeExceeded = false;  // Si el tamaño es correcto, resetear el estado
      this.emailSubscription.patchValue({
        files: fileArray
      });
    }
  }

}
