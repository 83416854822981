<div class="orders-page">
    <div class="d-flex justify-content-between align-items-center">
        <div class="content-search col-6">
            <div class="search-container container-form d-flex align-items-center borderInput search-full"
                [class.search-event]="idEvent">
                <i class="fal fa-search search-icon pointer-in" (click)="searchOrder(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar pedido"
                    [(ngModel)]="searchText" (input)="searchOrder(searchInput.value)">
            </div>
            <div class="btn-create btn-filter m-0" [class.btn-filter-active]="activeFilters" (click)="openFilters()">
                <i class="fal fa-sliders"></i>
                <p class="m-0">Filtrar</p>
                <i class="fal fa-times" *ngIf="activeFilters"></i>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end content-btns col-6">
            <div class="info-selected" *ngIf="activeSelectList">
                <span>Seleccionados ({{selectedItems.length}})</span>
            </div>
            <div class="d-flex" style="gap: 30px;" *ngIf="activeSelectList">
                <div [matMenuTriggerFor]="editOptions"
                    class="btn-create btn-create-border btn-select btn-edit d-flex justify-content-between m-0"
                    [class.disabled]="selectedItems.length <= 0">
                    <p class="m-0">Acciones en grupo</p>
                    <i class="icon-option fal fa-chevron-down"></i>
                </div>
                <mat-menu class="menu-options" #editOptions="matMenu">
                    <!-- <button mat-menu-item *ngIf="containsBlocked()" (click)="returnAll()">Devolver</button> -->
                    <button mat-menu-item (click)="printOrders('a4')">Imprimir A4</button>
                    <button mat-menu-item (click)="printOrders('ticket')">Imprimir ticket</button>
                </mat-menu>
            </div>
            <!-- <div class="link-open" (click)="activeSelecting()" *ngIf="!activeSelectList">
                <i class="fal fa-list-radio"></i>
                <span>Seleccionar varios</span>
            </div> -->
            <!-- Boton para devolver todas las entradas de un evento -->
            <div class="d-flex align-items-center" *ngIf="activeSelectList">
                <div class="btn-create btn-action m-0" (click)="activeSelecting()">
                    <p class="m-0">Cancelar</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-filters" *ngIf="activeFilters">
        <!-- Fecha -->
        <div>
            <mat-form-field appearance="fill" class="select-filter-panel pointer-in"
                (click)="openDatePicker=!openDatePicker; openPriceRange=false">
                <input matInput placeholder="Fecha" class="input-date pointer-in" readonly [(ngModel)]="stringDate">
                <mat-select-trigger matSuffix style="vertical-align: middle;">
                    <i class="fal fa-angle-down pointer-in"></i>
                </mat-select-trigger>
            </mat-form-field>
            <div class="padding-option">
                <app-custom-calendar-panel [datesRange]="datesRange" [openDatePicker]="openDatePicker"
                    (closeEvent)="openDatePicker = false"
                    (datesEvent)="selectDates($event)"></app-custom-calendar-panel>
            </div>
        </div>
        <!-- Rango de precios -->
        <div>
            <mat-form-field appearance="fill" class="select-filter-panel pointer-in">
                <input matInput placeholder="Precio del pedido" class="input-date pointer-in" readonly
                    [(ngModel)]="selectedPriceRange" (click)="openPriceRange=!openPriceRange">
                <mat-select-trigger matSuffix style="vertical-align: middle;">
                    <i class="fal fa-angle-down pointer-in"></i>
                </mat-select-trigger>
            </mat-form-field>
            <div class="padding-option dialog-price-range" *ngIf="openPriceRange">
                <div class="slider-filter">
                    <span>Selecciona un rango de precios:</span>
                    <mat-slider min="0" [max]="maxPriceEnabled" discrete="false" (change)="changeValuesPrice()">
                        <input value="0" [(ngModel)]="priceFilter.min" matSliderStartThumb>
                        <input [value]="maxPriceEnabled" [(ngModel)]="priceFilter.max" matSliderEndThumb>
                    </mat-slider>
                    <div class="d-flex justify-content-between ">
                        <span>{{priceFilter.min}}€</span>
                        <span>{{priceFilter.max}}€</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Estado -->
        <mat-form-field appearance="fill" class="select-filter-panel" (click)="openPriceRange=false">
            <mat-select #selectStatus class="text-black" panelClass="selectPanelControl" placeholder="Estado" multiple
                [disabled]="loadingFilter" [(ngModel)]="statusFilter"
                disableOptionCentering="false" (ngModelChange)="changeStatus=true" (closed)="updateStatus()">
                <mat-option *ngFor="let status of statusOptions | async" [value]="status.id">
                    <span>{{status.name}}</span></mat-option>
            </mat-select>
            <mat-select-trigger matSuffix style="vertical-align: middle;">
                <i class="fal fa-angle-down pointer-in"></i>
            </mat-select-trigger>
        </mat-form-field>

        <!-- Metodo de pago -->
        <mat-form-field appearance="fill" class="select-filter-panel" (click)="openPriceRange=false">
            <mat-select #selectPay class="text-black" panelClass="selectPanelControl" placeholder="Método de pago"
                multiple [disabled]="loadingFilter" [(ngModel)]="methodFilter"
                disableOptionCentering="false" (ngModelChange)="changeMethod=true" (closed)="updateMethod()">
                <mat-option *ngFor="let method of methodsOptions | async" [value]="method.id">
                    <span>{{method.name}}</span>
                </mat-option>
            </mat-select>
            <mat-select-trigger matSuffix style="vertical-align: middle;">
                <i class="fal fa-angle-down pointer-in"></i>
            </mat-select-trigger>
        </mat-form-field>

        <!-- Metodo de pago -->
        <mat-form-field appearance="fill" class="select-filter-panel" (click)="openPriceRange=false" *ngIf="(schedulesEvent|async)?.length > 1">
            <mat-select #selectSchedule class="text-black" panelClass="selectPanelControl" placeholder="Sesión del evento"
                multiple [disabled]="loadingFilter" [(ngModel)]="scheduleSelected"
                disableOptionCentering="false"  (ngModelChange)="changedSchedule=true" (closed)="updateSchedule()">
                <mat-option *ngFor="let schedule of schedulesEvent | async" [value]="schedule.id">
                    <span>{{ schedule.startDate | date:"dd 'de' MMMM - HH:mm" : 'UTC' }}</span>
                </mat-option>
            </mat-select>
            <mat-select-trigger matSuffix style="vertical-align: middle;">
                <i class="fal fa-angle-down pointer-in"></i>
            </mat-select-trigger>
        </mat-form-field>

        <!-- Eliminar filtros -->
        <!-- <div class="btn-clear-filter m-0" (click)="clearFilters()" *ngIf="filterActive">
            <i class="fal fa-times"></i>
            <p class="m-0">Limpiar</p>
        </div> -->
    </div>
    <div class="content-table-orders">
        <app-orders-table [activeSelectList]="activeSelectList" [selectedItems]="selectedItems" [business]="business"
            (selectedItemsChange)="selectedItemsChange($event)" [idEvent]="idEvent"></app-orders-table>
    </div>
</div>