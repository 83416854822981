import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventScheduleService {

    constructor(private readonly http: HttpClient) { }

    getEventSchedules(idEvent: number) {
        const url = `${AppSettings.API_URI}/session/enabled/event/${idEvent}`;
        return this.http.get(url).pipe(
            map(data => {
                return data;
            }), catchError(err => { return throwError(() => err); })
        );
    }
}