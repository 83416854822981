import { Component, Inject, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewSectionComponent } from 'src/app/components/new-lok/new-section/new-section.component';
import { NewTicketComponent } from 'src/app/components/new-lok/new-ticket/new-ticket.component';
import { EntradasService } from 'src/app/services/entradas.service';
import { SeatMapService } from 'src/app/services/seat-map.service';

@Component({
  selector: 'app-dialog-ticket',
  templateUrl: './dialog-ticket.component.html',
  styleUrls: ['./dialog-ticket.component.scss']
})
export class DialogTicketComponent {

  entradas: any[] = [];
  objectEdit: any;
  level: any;
  online: boolean = false;

  free: boolean = false;
  levelsMap!: any;
  idMap!: any;
  mapSelect!: any;
  editEvent: boolean = false;

  sectionTickets: any[] = [];
  loadingTickets: boolean = true;
  minPrice!: any;

  constructor(private entradasService: EntradasService, @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogTicketComponent>, private dialog: MatDialog,
    private seatMapService: SeatMapService) { }

  ngOnInit(): void {
    if (this.data) {
      this.objectEdit = this.data.tickets;
      this.level = this.objectEdit;
      this.idMap = this.data.idMap;
      if(this.idMap) {
        this.sectionTickets = this.objectEdit.priceTickets;
      } else {
        this.sectionTickets = [this.objectEdit];
      }
      this.editEvent = this.data.edit;
    }
    this.loadingTickets = false;

    // if (this.idMap) {
    //   this.getMap();
    // } else {
    //   this.loadingTickets = false;
    // }
  }

  createTicket(ticket: any = true, i?: number) {
    let newTicket = null;
    if (ticket != true) {
      newTicket = ticket
    }
    const dialogRef = this.dialog.open(NewTicketComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { ticket: newTicket, level: this.level }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (i != null) {
          this.entradas[i] = result;
        } else {
          this.entradas.push(result);
        }
      }
    });
  }

  addPriceTicket(event: any, ticket: any) {
    // let indexPrice = -1;
    // if (indexPrice == -1) {
    //   indexPrice = this.priceTickets.push({ price: 0, level: level?.id, orderBuy: level?.orderBuy }) - 1;
    // }
    // event.target.value ? this.priceTickets[indexPrice].price = +event.target.value : this.priceTickets.splice(indexPrice, 1);
  }

  getPriceTickets(ticket, section) {
    let prices = ticket.priceTickets;
    if(this.sectionTickets.length > 0) {
      prices = ticket.priceTickets?.filter(value => value.level == section.id || value.level?.id == section.id);
    }
    return prices;
  }

  getMap() {
    this.seatMapService.getMap(this.idMap).subscribe({
      next: (data) => {
        this.mapSelect = data;
        this.sectionTickets = data.level_maps;
        this.loadingTickets = false;
      }
    })
  }

  addSection() {
    const dialogRef = this.dialog.open(NewSectionComponent, {
      panelClass: ['info-dialog', 'not-padding'],
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sectionTickets.push({ name: result.name, color: result.color, tickets: [] });
      }
    });
  }

  editSection(index: any) {
    const dialogRef = this.dialog.open(NewSectionComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { section: this.sectionTickets[index] }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sectionTickets[index].name = result.name;
        this.sectionTickets[index].color = result.color;
      }
    });
  }

  deleteSection(index: any) {
    if (!this.idMap) {
      this.sectionTickets.splice(index, 1);
    }
  }

  save() {
    if(this.idMap) {
      this.level.priceTickets = this.sectionTickets;
      const result = { result: this.level };
      this.dialogRef.close(result);
    } else {
      let item = this.sectionTickets[0];
      item.priceTickets[0].price = item.price;
      const result = { result: this.sectionTickets[0] };
      this.dialogRef.close(result);
    }
  }

}

