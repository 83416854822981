import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormulariosService } from 'src/app/services/formularios.service';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {

  public sent: boolean = false;
  suggestionsForm: FormGroup;

  error: boolean = false;

  constructor(private formBuilder: FormBuilder, private formularioService:FormulariosService) { }

  ngOnInit(): void {
    this.suggestionsForm = this.formBuilder.group({
      name: [''],
      subject: ['', [Validators.required]],
      problem: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
  }

  send(){
    if(this.suggestionsForm.invalid) {
      this.error = true;
    }
    if(this.suggestionsForm.valid){
      var suggestion = JSON.stringify(this.suggestionsForm.value);
      this.formularioService.sendSuggestion(suggestion).subscribe(
        (response: any)=> {
          if(response && response.code === '200'){
              this.sent = true;
          }
        }
      );
    }
   
  }
}
