import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginator-results',
  templateUrl: './paginator-results.component.html',
  styleUrls: ['./paginator-results.component.scss']
})
export class PaginatorResultsComponent implements OnInit {

  @Output() page: EventEmitter<number> = new EventEmitter();
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;

  numbersPages: number[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.totalPages != 1) {
      this.numbersPages = [];
      let inicio = 0;
      if(this.totalPages - this.currentPage < 3) {
        inicio = -3 + (this.totalPages - this.currentPage); 
      } else if(this.currentPage != 1) { 
        inicio = -1; 
      }
      for (let index = inicio; this.currentPage+index <= this.totalPages && index < 3; index++) {
        if(this.currentPage+index > 0) {
          this.numbersPages.push(this.currentPage+index);
        }
      }
    }
  }

  setPage(page: number) {
    if(page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.page.emit(page);
    }
  }

  nextPage() {
    if(this.currentPage < this.totalPages) {
      this.currentPage++;
      this.page.emit(this.currentPage);
    }
  }

  prevPage() {
    if(this.currentPage > 0) {
      this.currentPage--;
      this.page.emit(this.currentPage);
    }
  }

}
