import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {

  private entradasArray = new BehaviorSubject<any[]>([
    {
      nombre: 'Entrada general',
      precio: 32.9,
      descuento: 0,
      specialTime: true,
      activo: true
    }
  ]);
  public entradas = this.entradasArray.asObservable();

  private tabSelected = new BehaviorSubject<any>(0);
  public tabOpen = this.tabSelected.asObservable();

  constructor() { }

  setTab(tab: any) {
    this.tabSelected.next(tab);
  }

  getEntradas() {
    this.entradasArray.value;
  }

  setEntradas(entradas: any[]) {
    this.entradasArray.next(entradas);
  }

  addEntrada(ticket: any) {
    let tickets = this.entradasArray.value;
    tickets.push(ticket);
    this.setEntradas(tickets);
  }

  setEntrada(ticket: any, index: any) {
    let tickets = this.entradasArray.value;
    if (tickets[index]) {
      tickets[index] = ticket;
      this.setEntradas(tickets);
      return ticket;
    } else {
      return false;
    }
  }

  removeEntrada(ticket: any) {
    let tickets = this.entradasArray.value;
    const index = tickets.findIndex(value => value==ticket)
    if(index) {
      tickets.splice(index, 1);
      this.setEntradas(tickets);
    }
  }
}
