import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SubscriberService } from 'src/app/services/subscriber.service';

@Component({
  selector: 'app-new-subscriber',
  templateUrl: './new-subscriber.component.html',
  styleUrls: ['./new-subscriber.component.scss']
})
export class NewSubscriberComponent implements OnInit {

  subscriber: FormGroup = new FormGroup({});
  idCompany!: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<NewSubscriberComponent>, private formBuilder: FormBuilder,
    private subscriberService: SubscriberService) { }

  ngOnInit(): void {
    this.data ? this.idCompany = this.data.idCompany : null;
    this.subscriber = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.required]]
    });
  }

  cancelNew() {
    this.dialogRef.close();
  }

  sendInvitation() {
    if (this.subscriber.valid) {
      this.subscriberService.sendInvitation(this.idCompany, this.subscriber.value).subscribe({
        next: (res) => {
          this.dialogRef.close({ user: res });
        },
        error: (err) => {
          if(err.status === 409) {
            this.dialogRef.close({ err: 'El email introducido ya existe en el listado de usuarios.' });
          } else {
            this.dialogRef.close({ err: 'Se ha producido un error al añadir al ususario, vuelve a intentarlo más tarde.' });
          }
        }
      });
    }
  }

}
