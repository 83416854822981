import { Component } from '@angular/core';

@Component({
  selector: 'app-status-pay',
  templateUrl: './status-pay.component.html',
  styleUrls: ['./status-pay.component.scss']
})
export class StatusPayComponent {

}
