<div class="favorites-page">
    <div class="page-wrapper">
        <p class="mb-2 text-center container title-favorites">Mis favoritos</p>
    </div>

    <!-- Tab bar -->
    <div class="tab-favorites container loading-height">
        <mat-tab-group (selectedTabChange)="changeTab($event)" dynamicHeight mat-stretch-tabs fitInkBarToContent color="primary" class="selected-tab-primary tab-color" animationDuration="10ms">
            <mat-tab label="Eventos">
                <div *ngIf="index == 0">
                    <app-events-fav [last]="false"></app-events-fav>
                </div>
            </mat-tab>
            <mat-tab label="Eventos pasados">
                <div *ngIf="index == 1">
                    <app-events-fav [last]="true"></app-events-fav>
                </div>
            </mat-tab>
            <mat-tab label="Sitios">
                <div *ngIf="index == 2">
                    <app-profile-fav [isSitio]="true"></app-profile-fav>
                </div>
            </mat-tab>
            <mat-tab label="Usuarios">
                <div *ngIf="index == 3">
                    <app-profile-fav [isSitio]="false"></app-profile-fav>
                </div>         
            </mat-tab>
        </mat-tab-group>
    </div>
</div>