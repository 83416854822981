import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormulariosService } from 'src/app/services/formularios.service';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  public sent: boolean = false;
  contactForm: FormGroup;

  error: boolean = false;

  constructor(private formBuilder: FormBuilder, private formularioService: FormulariosService) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      event: [''],
      problem: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
  }


  send() {
    if (this.contactForm.invalid) {
      this.error = true;
    }
    if (this.contactForm.value.problem == 'Problema con mi entrada' && !this.contactForm.value.event) {
      this.error = true;
    } else if (this.contactForm.valid) {
      var message = JSON.stringify(this.contactForm.value);
      this.formularioService.sendMessage(message).subscribe(
        (response: any) => {
          if (response && response.code === '200') {
            this.sent = true;
          }
        }
      );
    }
  }
}
