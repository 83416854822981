import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EventosService } from 'src/app/services/eventos.service';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  loksDestacados: Observable<any[]>;
  businessImg: string[] = ['Arnedo.png', 'Calahorra.png', 'entradas.png', 'eventbrite.png', 'Fever.png', 'LaRioja_turismo.png', 'TeatroBreton.png', 'ticketmaster-5.svg', 'Voces_de_la_lengua.png'];

  constructor(private eventosService: EventosService, private filterService: FilterService, private router: Router) { }

  ngOnInit(): void {
    this.loksDestacados = this.eventosService.getEventsBest().pipe();
  }

  @HostListener('window:scroll')
  onScrollParrallax() {
    if (window.scrollY > 0) {
      document.documentElement.style.setProperty('--dynamic-parrallax', `${window.scrollY}`);
    }
  }

  openTodayEvents() {
    this.filterService.selectDateToday();
    this.router.navigate(['/results']);
  }

}
