import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-last-posts',
  templateUrl: './last-posts.component.html',
  styleUrls: ['./last-posts.component.scss']
})
export class LastPostsComponent implements OnInit {

  lastBlogs: Observable<any[]>;

  constructor(private blogService: BlogService) { }

  ngOnInit(): void {
    this.lastBlogs = this.blogService.getPostsPagination(1,3).pipe();
  }

}
