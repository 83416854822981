import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Ciudad } from 'src/app/interfaces/ciudad';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { CommonService } from 'src/app/services/common.service';
import { CategoriasService } from 'src/app/services/categorias.service';
import { AppSettings } from 'src/app/app-settings';
import { MatDialog } from '@angular/material/dialog';
import { FiltersComponent } from '../../shared/filters/filters.component';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit, OnDestroy {

  category!: any;
  slugCategory!: string;
  slugEvent: BehaviorSubject<string> = new BehaviorSubject<string>('');

  viewType: string = 'grid';
  city!: any;
  ciudades: Observable<Ciudad[]>;

  @ViewChild('container', {static: false}) containerElement: ElementRef;
  imagen: string = '';

  // Infinity scroll
  filtros: Observable<any[]>;
  results: boolean = true;
  citySelected!: any;

  constructor(private title: Title, private activatedRoute: ActivatedRoute, private commonService: CommonService,
      private route: Router, private ciudadesService: CiudadesService, private dialog: MatDialog,
      private categoryService: CategoriasService, private filterService: FilterService) {
      this.ciudades = this.ciudadesService.getCiudades().pipe();
    }

  ngOnInit(): void {
    this.filtros = this.filterService.filter;
    this.activatedRoute.params.subscribe(params => {
      this.slugCategory = params.nameCategory;
      if(this.slugCategory) {
        this.results = true;
        this.slugEvent.next(this.slugCategory);
        this.commonService.cityStorage.subscribe({
          next: (idCity) => {
            if(idCity) {
              this.ciudadesService.getCiudadbyId(idCity).subscribe({
                next: (city) => {
                  this.citySelected = city?.nombre;
                }
              })
            } else {
              this.citySelected = null;
            }
          }
        })
        this.getCategory();
      } else {
        this.route.navigate([this.slugCategory])
      }
    })
  }

  getCategory() {
    this.category = this.categoryService.getCategoriaBySlug(this.slugCategory).subscribe(cat => {
      if(cat[0]) {
        this.category = cat[0];
        this.commonService.setCategory(this.category.id)
        this.title.setTitle('Citylok - '+this.category.nombre)
        this.imagen = AppSettings.MEDIA_URI + '/categoria/' + this.category.imagen;
      } else {
        this.filterService.resetAllFilters();
        this.route.navigate([this.slugCategory])
      }
    });
  }

  changeCity(city) {
    this.commonService.setCity(city);
  }

  deleteFilter(filter: any) {
    this.filterService.removeFilter(filter);
  }

  existsResults(exists: any) {
    this.results = exists;
  }

  ngOnDestroy() {
    this.commonService.setCategory(null);
    localStorage.removeItem('search');
    this.filterService.resetAllFilters();
  }

  // Abre los filtros
  openFilterDialog() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      width: '570px',
      panelClass: ['panel-dialog', 'animation-right-dialog'],
      position: {top: '0px', right: '0px'}
    })
  }

}
