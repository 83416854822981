<div class="form-tickets-lok">
    <div class="container-tickets">
        <div>
            <!-- Info de evento online -->
            <div style="margin-bottom: 20px;">
                <div class="d-flex align-items-center justify-content-between add-section" style="margin-top: 50px;">
                    <p class="title-price m-0">Entradas {{level.name}}:</p>
                </div>
                <!-- Info de lo que son secciones -->
                <mat-divider></mat-divider>
                <div *ngIf="!loadingTickets; else divLottie">
                    <div *ngFor="let item of sectionTickets; let i = index">
                        <div class="d-grid align-items-center justify-content-between section-ticket pointer-in">
                            <div class="section-item">
                                <i class="fa-light fa-square-check icon-check"></i>
                                <span class="name-ticket">{{ idMap ? item.ticket.nombre : item.nombre }}</span>
                            </div>
                            <div class="input-border input-ticket">
                                <input placeholder="Precio" type="number" min="0" [(ngModel)]="item.price"
                                    (change)="addPriceTicket($event, level, i)" />
                                <i class="fa-light fa-euro-sign icon-primary"></i>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </div>

                    <!-- Nueva -->
                    <!-- <div>
                        <div class="d-flex align-items-center justify-content-center add-ticket pointer-in"
                            (click)="createTicket()">
                            <p class="title-add">Añadir entrada</p>
                            <div class="btn-add-ticket pointer-in">
                                <i class="fa-regular fa-plus"></i>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </div> -->
                </div>
                <ng-template #divLottie>
                    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                            containerClass="loading-box"></ng-lottie>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <button class="btn-new-form btn-save w-100" (click)="save()">GUARDAR</button>
</div>