import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfoCardComponent } from '../info-card/info-card.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  message!: any;

  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
  }

  changeMsg(event: any) {
    this.message = event;
  }

  registeredUser(event: any) {
    if(event) {
      this.message = '';
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Te has registrado correctamente. Verifica tu correo electrónico.',
          btnCancel: false
      }});
      dialogRef.afterClosed().subscribe({
        next: (res) => {
          this.router.navigate(['/']);
        }
      })
    }
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

}
