<div class="container">
  <div class="container-contact">
    <p class="h2 title-contact">Ponte en contacto con nosotros</p>
    <app-warning *ngIf="!sent && error" text="Comprueba todos los campos."></app-warning>
    <form [formGroup]="contactForm" enctype="application/x-www-form-urlencoded" class="card-contact" *ngIf="!sent">
      <div class="bloque-contact container-form d-flex align-items-center">
        <i class="fa-regular fa-envelope icon-primary"></i>
        <input class="w-100" type="email" placeholder="Email" formControlName="email"  />
      </div>
      <div class="bloque-contact container-form d-flex align-items-center password-input">
        <i class="fa-light fa-comment-question icon-primary"></i>
        <input class="w-100" type="text" placeholder="Asunto" formControlName="subject" />
      </div>

      <div Class="container-select-border-contact">
        <mat-form-field class="problem-select w-100" appearance="fill">
          <mat-select-trigger matPrefix style="vertical-align: middle;">
            <i class="fa-light fa-comments-question"></i>        
          </mat-select-trigger>
          <mat-select #categorySelect class=" text-black select-placeholder" placeholder="¿Qué necesitas?" formControlName="problem" >
            <mat-option value="Nuevo lok">Tengo problemas al añadir un lok</mat-option>
            <mat-option value="No encuentro informacion">No encuentro la información que busco</mat-option>
            <mat-option value="Inicio de sesión">No puedo iniciar sesión</mat-option>
            <mat-option value="Favoritos perdidos">He perdido mis loks favoritos</mat-option>
            <mat-option value="Suscripción">Tengo un problema con una suscripción</mat-option>
            <mat-option value="Otros">Otros problemas</mat-option>
          </mat-select>
          <mat-select-trigger matSuffix style="vertical-align: middle;">
            <i class="fa-light fa-angle-down pointer-in"></i>
          </mat-select-trigger>
        </mat-form-field>
      </div>

      <div class="card-reply">
        <div class="container-form d-flex align-items-center">
          <textarea #textarea class="w-100" type="textarea" placeholder="Por favor, danos mas información"
            maxlength="2500" formControlName="body" ></textarea>
        </div>
      </div>

      <button name="button" class="btn btn-contact" (click)="send()">Enviar</button>
    </form>
    <app-warning *ngIf="sent" text="¡Gracias! Tu mensaje ha sido enviado correctamente." backgroundColor="#FFFFFF"
      icon="fa-check"></app-warning>
  </div>
</div>