<div class="container content-terms">
    <h3 class="title-terms" style="color: var(--color-primary); ">Política de Cookies</h3>
    <p>Utilizamos cookies para facilitar el uso de nuestra página web. Las cookies son pequeños ficheros de información
        que nos permiten comparar y entender cómo nuestros usuarios navegan a través de nuestra página web, y de esta
        manera poder mejorar consecuentemente el proceso de navegación. Las cookies que utilizamos no almacenan dato
        personal alguno, ni ningún tipo de información que pueda identificarle. En caso de no querer recibir cookies,
        por favor configure su navegador de Internet para que las borre del disco duro de su ordenador, las bloquee o le
        avise en caso de instalación de las mismas. Para continuar sin cambios en la configuración de las cookies,
        simplemente continúe en la página web. Puedes obtener más información sobre las cookies y su uso en
        <a href="www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>
    </p>
    <p class="sub-title-terms">1. Cookies estrictamente necesarias:</p>
    <p>
        Estas cookies son necesarias para el correcto uso de la página web, permitiendo el acceso a secciones que
        cuentan con filtros de seguridad. Sin estas cookies, muchos de los servicios disponibles no estarían
        operativos.
    </p>
    <p class="sub-title-terms">2. Cookies de Navegación: </p>
    <p>
        Estas cookies recogen información sobre el uso que las visitas hacen de la web, por ejemplo, páginas vistas,
        errores de carga… Es información genérica y anónima, donde no se incluyen datos personales, ni se recoge
        información que identifique a los visitantes; siendo el objetivo último mejorar el funcionamiento de la web. Al
        visitar nuestra página web, acepta la instalación de estas cookies en su dispositivo.
    </p>
    <p class="sub-title-terms">3. Cookies Funcionales: </p>
    <p>Estas cookies permiten recordar información (como su nombre de usuario, idioma o la región en la que se
        encuentra) y características más personales. Por ejemplo, la posibilidad de ofrecer contenido personalizado
        basado en la información y criterios que hayas proporcionado voluntariamente. Estas cookies también pueden
        utilizarse para recordar los cambios realizados en el tamaño del texto, fuentes y otras partes personalizables
        de la página web. También se utilizan para ofrecer algunos servicios solicitados, como ver un video o comentar
        en un blog. La información que recopilan estas cookies puede ser anónima y no podrá ser seguida su actividad en
        otras páginas web. Al visitar nuestra página web, aceptas la instalación de estas cookies en tu dispositivo.
    </p>

    <p class="sub-title-terms mt-2">Cómo administrar las cookies en los ordenadores</p>
    <p>Si quieres permitir el uso de cookies de nuestro site, por favor sigue las siguientes instrucciones.</p>
    <p>Google Chrome
        <li>1. Al abrir el navegador, pincha “herramientas” en la parte superior y selecciona la pestaña de “opciones”.
        </li>
        <li>2. Dentro de opciones, pincha “privacidad”. </li>
        <li>3. Marca “permitir la administración de cookies”.</li>
    </p>
    <p>Microsoft Internet Explorer 6.0, 7.0, 8.0, 9.0
        <li>1. Al abrir el navegador, pincha “herramientas” en la parte superior y selecciona la pestaña de “opciones”.
        </li>
        <li>2. Revisa la pestaña de “Privacidad” asegurándote está configurada con un nivel de seguridad medio o
            inferior. </li>
        <li>3. Si la configuración de Internet no es media se estarán bloqueando las cookies.</li>
    </p>
    <p>Mozilla Firefox
        <li>1. Al abrir el navegador, pincha “herramientas” en la parte superior y selecciona la pestaña de “opciones”.
        </li>
        <li>2. Selecciona el icono de Privacidad. </li>
        <li>3. Pincha en cookies, y marca: “permitir la instalación de cookies”.</li>
    </p>
    <p>Safari
        <li>1. Al abrir el navegador, pincha “herramientas” en la parte superior y selecciona la pestaña de “opciones”.
        </li>
        <li>2. Pincha en la pestaña de “Seguridad” y revisa si la opción “Bloquear el acceso de cookies de terceros”
            está
            marcada o no.</li>
        <li>3. Pincha en “guardar”.</li>


    <p class="sub-title-terms mt-2">La instalación de cookies en Mac:</p>
    <p> Si tienes un Mac y quieres permitir el acceso de nuestras cookies en tu ordenador, por favor sigue las
        siguientes instrucciones:
    </p>
    <p>Microsoft Internet Explorer 5.0 en OSX
        <li>1. Entra en “Explorer” y selecciona “Preferencias” en la barra de navegación.</li>
        <li>2. Haz scroll hacia abajo hasta que veas “Cookies” justo debajo de archivos recibidos.</li>
        <li>3. Marca “No volver a preguntar”.</li>
    </p>
    <p>Safari en OSX
        <li>1. Entra en Safari y selecciona “Preferencias” en la barra de navegación.</li>
        <li>2. Pincha en la pestaña de “Seguridad” y marca la opción “aceptar cookies”.</li>
        <li>3. Selecciona la opción: “Solo desde el site actual en que estoy navegando”.</li>
    </p>
    <p>Mozilla y Netscape en OSX
        <li>1. Entra en “Mozilla” o “Netscape” y en la parte superior de tu navegador, marca la opción de
            “Preferencias”.</li>
        <li>2. Haz scroll hacia abajo hasta que veas “Cookies” justo debajo de “Privacidad y Seguridad”.</li>
        <li>3. Marca la opción “Permitir el acceso de cookies solo desde el site actual”.</li>
    </p>
    <p>Opera
        <li>1. Entra en “Opera” y selecciona “Menu” y “Ajustes” en la barra de navegación.</li>
        <li>2. Selecciona “Preferencias” y pincha en la pestaña de “Avanzado”.</li>
        <li>3. Marca la opción “Aceptar cookies”.</li>
    </p>


</div>