import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class PageWpService {

  private allFields: string = `_fields[]=id&_fields[]=slug&_fields[]=date&_fields[]=date_gmt&_fields[]=title&_fields[]=content&_fields[]=acf&acf_format=standard`;
  private fields: string = `_fields[]=id&_fields[]=slug&_fields[]=date&_fields[]=date_gmt&_fields[]=title&_fields[]=excerpt&_fields[]=acf&acf_format=standard`;

  constructor(private common: CommonService) { }

  getStaticBySlug(slug: string) {
    const url = `${AppSettings.WP_API}/wp-json/wp/v2/pages?slug=${slug}`;
    return this.common.getData(url);
  }
}
