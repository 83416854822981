import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-update-venue',
  templateUrl: './update-venue.component.html',
  styleUrls: ['./update-venue.component.scss']
})
export class UpdateVenueComponent {

  changeForm: FormGroup;
  valueChange: any;
  valueInit: any;
  nameValue: any;

  constructor(private formBuilder: FormBuilder, private placeService: PlacesService,
    private dialogRef: MatDialogRef<UpdateVenueComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.nameValue = this.data['name'];
    this.valueChange = this.data['valueChange'];
    this.valueInit = this.data['value'];
    this.changeForm = this.formBuilder.group({
      name: [this.valueInit.name, Validators.required],
      description: [this.valueInit.description, Validators.required],
      location: [this.valueInit.location],
      latitude: [this.valueInit.latitude],
      longitude: [this.valueInit.longitude],
      membershipTitle: [this.valueInit.membershipTitle],
      subscriptionText: [this.valueInit.subscriptionText],
      // city: [this.valueInit.city], // Se relaciona como 
    });
  }

  get value() { return this.changeForm.get(this.valueChange); }

  changeBusiness() {
    if (this.changeForm.valid) {
      const value = this.changeForm.value;
      this.valueInit.name = value.name;
      this.valueInit.description = value.description;
      this.valueInit.location = value.location;
      this.valueInit.latitude = value.latitude;
      this.valueInit.longitude = value.longitude;
      this.valueInit.membershipTitle = value.membershipTitle;
      this.valueInit.subscriptionText = value.subscriptionText;

      this.placeService.updateVenue(this.valueInit.id, value).subscribe({
        next: (data) => {
          this.dialogRef.close({ status: 'changed', venue: this.valueInit });
        },
        error: (err) => {
          this.dialogRef.close({ status: 'error' });
        }
      });
    }
  }
}
