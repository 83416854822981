<div class="section-form custom-calendar-comp">
    <input name="datepicker" class="input-date-hidden" ngbDatepicker #datepicker="ngbDatepicker" autoClose="outside"
        (dateSelect)="changeDate($event)" [dayTemplate]="t" [firstDayOfWeek]="firstDayOfWeek" outsideDays="'visible'"
        [minDate]="minDate" (navigate)="dateNavigate($event)" tabindex="-1" [startDate]="fromDate!"
        (closed)="closeDatePicker()">
    <div class="dp-hidden">
        <div class="input-group">
            <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="fromDate != null && hoveredDate != null"
                    [class.first-select]="isFromDate(date)" [class.last-select]="isToDate(date)"
                    [class.faded]="isHovered(date) || isInside(date)" [class.border-monday]="isMonday(date)"
                    [class.border-sunday]="isSunday(date)" [class.range]="isRange(date)"
                    [class.day-disabled]="isDisabled(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    <span class="inside-day" [class.weekend]="isWeekend(date)">{{ date.day }}</span>
                </span>
            </ng-template>
        </div>
    </div>
</div>