import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { GooglePlacesService } from 'src/app/services/google-places.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-create-venue',
  templateUrl: './create-venue.component.html',
  styleUrls: ['./create-venue.component.scss']
})
export class CreateVenueComponent {

  venueForm!: FormGroup;
  urlImages: string = AppSettings.MEDIA_URI + '/images/venues/';
  imgControl: FormControl = new FormControl(null);
  changedImg: boolean = false;

  business = JSON.parse(localStorage.getItem('business') || '{}');
  lugar: string = '';
  searchResults: any[] = [];

  constructor(private placeService: PlacesService, private dialog: MatDialog, private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService, private ciudadService: CiudadesService, private location: Location) {
    this.venueForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', [Validators.required]],
      file: ['', [Validators.required]],
      location: [],
      latitude: [],
      longitude: [],
      capacity: [],
      allowSubscription: [],
      membershipTitle: [],
      subscriptionText: []
    });
  }

  ngAfterViewInit(): void {
    this.googlePlacesService.openMap();
    this.googlePlacesService.mapsLocatorStorage.subscribe({
      next: (data) => {
        if (data) {
          this.lugar = data;
          this.venueForm.controls['latitude'].setValue(data.geometry?.location.lat());
          this.venueForm.controls['longitude'].setValue(data.geometry?.location.lng());
          this.venueForm.controls['location'].setValue(data.formatted_address);

          const objectCity = data.address_components.find(value => value.types.includes('locality'));
          if (objectCity) {
            this.ciudadService.getAllCitieByName(objectCity.long_name).subscribe(res => {
              if (res.length > 0) {
                this.venueForm.addControl('ciudad', new FormControl(res[0]['id']));
              } else {
                this.venueForm.addControl('ciudad', new FormControl(null));
              }
            });
          }
        }
      }
    });
  }

  onFileChange(event: any) {
    let filePath = '';
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (!file.type.match('image.*')) {
        alert('Solo se permite utilizar imagenes');
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        filePath = reader.result as string;
        this.imgControl.setValue(filePath);
        this.venueForm.patchValue({
          file: filePath
        });
        this.changedImg = true;
      }
    }
    if (!event.target.files) {
      this.imgControl.setValue(null);
      this.venueForm.patchValue({
        file: null
      });
      this.changedImg = false;
    }
  }

  updateFile() {

  }

  onSearchPlaces() {
    if (this.lugar) {
      this.googlePlacesService.searchPlaces(this.lugar).then((results) => {
        this.searchResults = results;
      });
    } else {
      this.searchResults = [];
    }
  }

  onSelectPlace(text: string) {
    this.googlePlacesService.searchPlaces(text).then((results) => {
      this.lugar = results[0];
      this.googlePlacesService.getDetail(results[0]);
    });
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true, close?: boolean) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
    if (close) {
      dialogRef.afterClosed().subscribe({
        next: (res) => {
          this.location.back();
        }
      })
    }
  }

  displayFn(location: any): string {
    if (location) {
      return location.description ? location.description : location.formatted_address;
    }
    return '';
  }

  saveVenue() {
    if (!this.venueForm.valid) {
      this.openInfoCard('Por favor, completa los campos obligatorios.')
      return;
    }
    const payload = this.venueForm.value;
    this.placeService.createVenue(this.business.id, payload).subscribe({
      next: (value) => {
        this.openInfoCard('Espacio creado correctamente', true, false, true)
      },
      error: (err) => {
        this.openInfoCard('¡Vaya! Ha ocurrido un error al crear el espacio, vuelve a intentarlo más tarde', false, true)
      },
    })
  }
}
