import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatDialogModule} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { Location } from '@angular/common';
import { AppSettings } from '../../../../app-settings';
import { InfoCardComponent } from '../../../shared/info-card/info-card.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { EventosService } from '../../../../services/eventos.service';



@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})

export class ViewUserComponent implements OnInit {

  userId!:number
  userData!:any;
  message!:string;
  oficial:Boolean = false;
  apiMedia: string = AppSettings.MEDIA_URI;
  profile!: any;
  profileImage = '';
  displayedColumnsEventos: string[] = [ 'imagen', 'titulo','fecha', 'comentarios', 'localizacion', 'ciudad', 'precio','options'];
  displayedColumnsCompras: string[] = [ 'imagen', 'evento', 'fecha','entradas','total', 'pagado'];
  dataSourceEventos;
  dataSourceCompras;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)sort: MatSort = new MatSort;
  sorted!: string;
  page = 1;
  totalPages = 1;

  numItems = 0;
  items = 10;
  totalPagesCompras =1;
  numItemsCompras=0;
  itemsCompras = 10;
  constructor(private location: Location,
    private formBuilder: FormBuilder, private userService: UserService,
    private activatedRoute: ActivatedRoute,private eventosService: EventosService) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this.activatedRoute.params.subscribe(params => {
      this.userId = params.id;
      if(this.userId){
        this.userService.getUserbyId(this.userId).subscribe(user => {
          if(user){
            this.userData=user
            this.userData.oficial ==true? this.oficial = true:''
            if(user.imagen){
              this.profileImage = this.apiMedia + '/users/' + user.imagen;
            }
          }
        })
        this.userService.getAllEventsByUser(this.userId, this.page, this.items).subscribe(userevents => {
          if(userevents){
            userevents['hydra:member'].forEach(element => {
              element.comentario = element.comentario.length;
               if(element.ciudad[0]){
                var city = element.ciudad[0]['@id'].split("/").pop()
                this.eventosService.getCity(city).subscribe(city => {
                  if(city){
                    element.ciudad = city.nombre
                  }
                })
              }else{
                element.ciudad = "Sin Ubicación"
              }
            });
            this.dataSourceEventos = userevents['hydra:member'];
            this.dataSourceEventos.sort = this.sort;
            this.numItems = userevents['hydra:totalItems'];
            this.totalPages = Math.ceil(this.numItems/this.items);
            this.userService.getAllBuysByUser(this.userId, this.page, this.itemsCompras).subscribe(compras => { 
              if(compras){
                compras['hydra:member'].forEach(element => {
                  var evento = element.eventos.split("/").pop()
                    this.eventosService.getEventById(evento).subscribe(evento=> {
                      element.evento = evento;
                    })
                })
              }
              this.dataSourceCompras = compras['hydra:member'];
              this.dataSourceCompras.sort = this.sort;
              this.numItemsCompras = compras['hydra:totalItems'];
              this.totalPagesCompras = Math.ceil(this.numItemsCompras/this.itemsCompras);
            });
          }
        })
      }
    })
  }

  pageChange(event:any) {
    this.page = event;
    this.getData();
  }

  itemsChange(event:any) {
    this.items = event;
    this.getData();
  }

  OpenEvent(id:number,name:string,type:number){
    if (type == 0){
      let url= 'http://localhost:4200/event/'+id+'-'+name.replaceAll(' ','-')
      window.open(url, "_blank");
    }else if( type == 1){
      let url= 'http://localhost:4200/event/'+id+'-'+name.replaceAll(' ','-')+'/comments'
      window.open(url, "_blank");
    }
  }

  openDialog(): void {
    this.location.back();
  }
}
