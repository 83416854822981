<div class="card-verify-sms">
    <p class="title-verify">Verifica tu número de telefono</p>

    <div *ngIf="!isSended; else divValidate">
        <div class="item-form container-verify d-flex align-items-center">
            <i class="fa-light fa-phone icon-primary"></i>
            <div class="w-100">
                <input class="input-custom" type="tel" [(ngModel)]="movilUser">
            </div>
        </div>
        <div class="d-flex justify-content-center btn-verify">
            <button class="primary-button solid w-100" style="min-height: 40px;"
                (click)=validateNumber()>Enviar SMS</button>
        </div>
    </div>

    <ng-template #divValidate>
        <div class="item-form container-verify d-flex align-items-center">
            <i class="fa-light fa-message-code icon-primary"></i>
            <div class="w-100">
                <input class="input-custom" type="tel" [(ngModel)]="codeSMS">
            </div>
        </div>
        <div class="d-flex justify-content-center btn-verify">
            <button class="primary-button solid w-100" style="min-height: 40px;"
                (click)=validateCode()>Verificar</button>
        </div>
    </ng-template>
</div>