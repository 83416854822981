<div class="template-admin-card">
  <div w-100 class="row align-items-center justify-content-between title-table">
      <h2 class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 title-h2">Usuarios</h2>
      <div class="col col-lg-9 col-md-9 col-sm-12 col-xs-12 div-buttons" style="text-align: end">
        <button class="mat-raised-button" [routerLink]="['/admin/usuarios/new-user']">
          <i class="new far fa-plus"></i>Nuevo usuario
        </button>
        <div class="btn-filter d-flex align-items-center">
          <i class="far fa-search icon-primary"></i>           
          <input class="input-search" placeholder='Buscar' #input (keyup)="applySearch($event)">
        </div>
      </div>
  </div>
  <div class="platform-card">
    <mat-card appearance="outlined" class="mt-1">
      <mat-card-content class="platform-card-description" class="content-table">
        <div class="container-table table-default">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="imagen">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let user">
                <img fallimg *ngIf="user.imagen == ''" class="imgUser" src="{{ apiMedia }}/users/defaultUser.png">
                <img fallimg *ngIf="user.imagen !== ''" class="imgUser" src="{{ apiMedia }}/users/{{user.imagen}}">
              </td>
            </ng-container>
            <ng-container matColumnDef="nickname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nickname</b></th>
              <td mat-cell *matCellDef="let user">{{user.nickname}}</td>
            </ng-container>
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nombre</b></th>
              <td mat-cell *matCellDef="let user">{{user.nombre | titlecase}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Email</b></th>
              <td mat-cell *matCellDef="let user">{{user.email}}</td>
            </ng-container>
            <ng-container matColumnDef="movil">
              <th mat-header-cell *matHeaderCellDef><b>Movil</b></th>
              <td mat-cell *matCellDef="let user">{{user.movil}}</td>
            </ng-container>
            <ng-container matColumnDef="sms_validate">
              <th mat-header-cell *matHeaderCellDef><b>Verificado</b></th>
              <td mat-cell *matCellDef="let user">{{user.sms_validate}}</td>
            </ng-container>
            <ng-container matColumnDef="oficial">
              <th mat-header-cell *matHeaderCellDef><b>Oficial</b></th>
              <td mat-cell *matCellDef="let user">{{user.oficial}}</td>
            </ng-container>
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let user">
                <button mat-button [matMenuTriggerFor]="menuOptions" class="menu-table">
                  <i class="fas fa-ellipsis-v pointsIcon"></i>
                </button>
                <mat-menu class="menu-options" #menuOptions="matMenu">
                    <button mat-menu-item [routerLink]="['/admin/usuarios/view-user',user.id]" class="viewIcon"><i class="far fa-eye"></i> Ver</button>
                    <button mat-menu-item [routerLink]="['/admin/usuarios/new-user',user.id]" class="editIcon"><i class="fal fa-edit"></i> Editar</button>
                    <button mat-menu-item class="deleteIcon" (click)="openDelete(user.id)"><i class="far fa-trash-alt"></i> Eliminar</button>
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <app-pagination-page [totalItems]="numItems" (items)="itemsChange($event)" [numPages]="totalPages" (page)="pageChange($event)"></app-pagination-page>
      </mat-card-content>
    </mat-card>
  </div>
</div>
