<!-- <ion-content> -->
  <div class="container-qr-code">
    <p class="text-locator">
      Localizador
    </p>
    <qrcode
    [qrdata]="localizador"
    [allowEmptyString]="true"
    [cssClass]="'center'"
    [colorDark]="'#ff4d80'"
    [colorLight]="'#ffffffff'"
    [elementType]="'canvas'"
    [errorCorrectionLevel]="'M'"
    [imageHeight]="75"
    [imageWidth]="75"
    [margin]="4"
    [scale]="1"
    [title]="'Localizador evento'"
    [width]="300"
  ></qrcode>   
</div>
<!-- </ion-content> -->
