import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { BannersService } from '../../../../../services/banners.service';
import { Location } from '@angular/common';
import { InfoCardComponent } from '../../../../shared/info-card/info-card.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-new-banner',
  templateUrl: './new-banner.component.html',
  styleUrls: ['./new-banner.component.scss']
})
export class NewBannerComponent implements OnInit {

  banner: FormGroup;
  bannerImage: FormGroup;

  apiMedia: string = AppSettings.MEDIA_URI;

  message = '';

  filePath = '';

  idB;

  constructor(private dialog: MatDialog, private location: Location,
              private formBuilder: FormBuilder, private bannersService: BannersService,
              private activatedRoute: ActivatedRoute) {

    this.banner = this.formBuilder.group({
      title: '',
      description: '',
      image: '',
      imgIsright: false,
      showhome: false,
      button: '',

    })
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(p=> this.idB = p.id)
    this.getData();
  }

  getData(){
    if(this.idB){
      this.bannersService.getBannnerById(this.idB).subscribe(b => {
        if(b !=null){
          if(b.imagen){
            this.filePath = this.apiMedia + '/categoria/' + b.imagen;
          }
          this.banner = this.formBuilder.group({
            title: b.title,
            description: b.description,
            image: b.image,
            imgIsright: b.imgIsright,
            showhome: b.showhome,
            button: b.button,

          })
        }
      });
    }
  }

  submit(){
    if(this.banner.valid){
      if(this.idB){
        //PUT
        this.bannersService.putBanner(this.idB,this.banner.value).subscribe(data => {
          if (data) {
            if(this.filePath !== this.apiMedia +'/categoria/'+ this.banner.value.imagen){
              this.bannerImage = this.formBuilder.group({
                categoriaId: this.idB,
                file: this.filePath
              })
              this.bannersService.uploadFilePut(this.bannerImage.value).subscribe()
            }
            this.goBack()
          } else {
            this.message = "No se ha podido modificar la categoría";
          }
        });
      } else{
        //POST
        this.bannersService.postBanner(this.banner.value).subscribe(data => {
          if (data) {
            if(this.filePath !== ''){
              this.bannerImage = this.formBuilder.group({
                categoriaId: data.id,
                file: this.filePath
              })
              this.bannersService.uploadFilePut(this.bannerImage.value).subscribe()
            }
            this.goBack()
          } else {
            this.message = "No se ha podido añadir la categoría";
          }
        });
      }
    } else {
      this.message = "¡ Rellena los campos correctamente !";
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InfoCardComponent, {

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.goBack();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  //Imagen
  onImageChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];

      this.banner.addControl('file', new FormControl(''));

      const reader = new FileReader();
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          this.filePath = imageSrc;
          this.banner.patchValue({
            file: imageSrc

          });

        };
      }
    }
  }

  //Desc
  textArea: any = '';
  @Output() emitDescription = new EventEmitter<any>();

  public isEmojiPickerVisible: boolean;

  public addEmoji(event) {
    this.isEmojiPickerVisible = false;
    return event.emoji.native;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí la descripción del evento',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };


}
