import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { EventosService } from '../../../services/eventos.service';
import { UserService } from '../../../services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { AppSettings } from 'src/app/app-settings';
import { MatDialog } from '@angular/material/dialog';
import { DeletesComponent } from '../shared/deletes/deletes.component';
import { Router } from '@angular/router';
// import { WarningAdminComponent } from '../shared/warning-admin/warning-admin.component';


@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})

export class EventosComponent implements OnInit {

  // displayedColumns: string[] = [ 'imagen', 'titulo', 'descripcion','fecha',  'localizacion', 'precio','options'];
  displayedColumns: string[] = ['imagen', 'titulo', 'fecha', 'fechaFin', 'ciudad', 'localizacion', 'precio', 'options'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  page = 1;
  totalPages = 1;
  numItems = 0;
  items = 10;

  urlImages: string = AppSettings.MEDIA_URI + '/images/';

  search!: string;
  sorted!: string;

  constructor(private eventosService: EventosService, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.eventosService.getAllEvents(this.page, this.items).subscribe(data => {
      this.dataSource = data['hydra:member'];
      this.dataSource.sort = this.sort;
      this.numItems = data['hydra:totalItems'];
      // const urlLastPage: string = data['hydra:view']['hydra:last'] || '';
      // const equal = urlLastPage.lastIndexOf('=');
      // this.totalPages = +urlLastPage.substring(equal + 1);
      this.totalPages = Math.ceil(this.numItems / this.items);
    })
  }

  getFilterOrderData() {
    this.eventosService.getAllEvents(this.page, this.items, this.sorted, this.search).subscribe(data => {
      this.dataSource = data['hydra:member'];
      this.dataSource.sort = this.sort;
      this.numItems = data['hydra:totalItems'];
      this.totalPages = Math.ceil(this.numItems / this.items);
    });
  }

  applySearch(event) {
    this.search = (event.target as HTMLInputElement).value.trim();
    this.getFilterOrderData();
  }

  // Delete
  openDelete(eventoId: number) {
    const dialogRef = this.dialog.open(DeletesComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteEvent(eventoId);
      }
    });
  }

  deleteEvent(eventoId: number) {
    this.eventosService.deleteEvento(eventoId).subscribe(data => {
      this.getData();
    })
  }

  //Pagination
  pageChange(event: any) {
    this.page = event;
    this.getData();
  }

  itemsChange(event: any) {
    this.items = event;
    if (this.sorted != null) {
      this.getFilterOrderData();
    } else {
      this.getData();
    }
  }

  // Sort
  sortData(sort: Sort) {
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      if (sort.active == 'titulo') {
        this.sorted = `order[titulo]=${isAsc}`;
      } else if (sort.active == 'fecha') {
        this.sorted = `order[fechas.fechaInifio]=${isAsc}`;
      } else if (sort.active == 'fechaFin') {
        this.sorted = `order[fechas.fechaFin]=${isAsc}`;
      } else if (sort.active == 'localizacion') {
        this.sorted = `order[localizacion]=${isAsc}`;
      } else if (sort.active == 'precio') {
        this.sorted = `order[preciodesde]=${isAsc}`;
      } else if (sort.active == 'ciudad') {
        this.sorted = `order[ciudad.nombre]=${isAsc}`;
      } else {
        this.sorted = `order[${sort.active}]=${isAsc}`;
      }
      this.getFilterOrderData();
    } else {
      this.sorted = null;
      this.getData();
    }
  }


  openEvent(id: number, name: string, fragment?: string) {
    //fragment="Test"
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/event/${id}-${name.replaceAll(' ','-')}`], {fragment: fragment})
    );
  
    window.open(url, '_blank');

    // let url = 'http://localhost:4200/event/' + id + '-' + name.replaceAll(' ', '-')
    // window.open(url, "_blank");
  }
}
