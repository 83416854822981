<div class="template-admin-card">

  <div>
      <h2>Selección pagina principal</h2>
  </div>

  <div class="platform-card">

    <div w-100 class="d-flex align-items-center btns">
      <button class="mat-stroked-button" [routerLink]="['/admin/config/selection/add-banner']">Añadir banners</button>
      <button class="mat-stroked-button" [routerLink]="['/admin/config/selection/add-categoria']">Añadir categorias</button>
    </div>
    <div class="container-table">
      <div cdkDropList class="selection-list" (cdkDropListDropped)="drop($event)">
        <div class="selection-box" *ngFor="let sel of selection" cdkDrag>
          <div class="elements" *cdkDragPlaceholder></div>
            <div *ngIf="sel.icon" class="cat">
              <i [class]="sel.icon"></i>
              {{sel.nombre}}
            </div>
            <div *ngIf="!sel.icon" class="banner">
              {{sel.title}}
            </div>
            <button mat-button class="add" (click)="delete(sel.id)">
              <i class="fas fa-solid fa-x"></i>
            </button>
        </div>
      </div>
    </div>


  </div>

</div>
