import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ComprasService {

  constructor(private http: HttpClient, private common: CommonService) { }

  getCompras(page: number, items: number) {
    const url = `${ AppSettings.API_URI }/compras/usuario?page=${page}&items=${items}`;
    return this.common.getData(url);
  }

  getComprabyId(id:number) {
    const url = `${ AppSettings.API_URI }/compras/${id}`;
    return this.common.getData(url);
  }

  postCompras(body: any) {
    const url = `${ AppSettings.API_URI }/compras`;
    return this.common.postData(url, body);
  }

  putCompras(compraId: number, body: any) {
    const url = `${ AppSettings.API_URI }/compras/${ compraId }`;
    return this.http.put<any>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  refundTicket(ticket: any) {
    // return this.putCompras(ticket.id,{status: "refunded"});
    return this.getCompras(1,12);
  }

}

