<div class="content-waiting">
  <div class="validate-payment-modal">
    <p class="title-pay">El pago se está procesando...</p>
    <div><span id="time" *ngIf="waiting">
        <span>{{minutes.toString().length<2 ? '0' +minutes : minutes}}</span> : <span>{{seconds.toString().length<2
                ? '0' +seconds : seconds}}</span></span>
    </div>
    <div class="lottie">
      <ng-lottie [options]="{path:'assets/lotties/loading.json'}" containerClass="lottie-box"></ng-lottie>
    </div>
    <p *ngIf="waiting">Si ya lo has validado y no se cierra la ventana, <span class="link-pay" (click)="checkValid()">pulsa aquí</span>.</p>
  </div>
</div>