<div class="container-list-events">
    <mat-divider></mat-divider>
    <div *ngFor="let opt of compras" class="group-list">
        <div class="list-container align-items-center" [class.refund]="opt.status == 'refunded'">
            <div class="event-container align-items-center">
                <!-- <div class="div-img pointer-in">
                    <img fallimg class="img-event" *ngIf="opt.entradas[0]?.entrada.evento.imagen; else divImageDefault"
                        src="{{urlImages}}/{{opt.entradas[0].entrada.evento.imagen}}" />
                    <ng-template #divImageDefault>
                        <img fallimg class="img-event" src="{{urlEvents+'/'+opt.entradas[0]?.entrada.evento.imageurl}}" />
                    </ng-template>
                </div> -->
                <div class="info-event">
                    <div class="pointer-in">
                        <div class="d-flex align-items-center date-event" [class.primary]="!type"
                            (click)="openTicketsEvent(opt)">
                            <i class="fa-light fa-calendar"></i>
                            <span>{{ (opt.createdAt | date: 'dd MMM':'+0000') }}</span>
                            <span *ngIf="opt.time"> | </span>
                            <span *ngIf="opt.time">{{ (opt.time | date: 'HH:mm':'+0000') }}h</span>
                        </div>
                        <p class="title-event m-0" (click)="openTicketsEvent(opt)">Compra de {{ opt.entradas.length }}
                            entrada{{opt.entradas.length>1?'s':''}}</p>
                        <!-- <p class="category-event">{{ opt.entradas[0].entrada.evento.categoria.nombre }}</p>
                    <span class="title-event" [routerLink]="['/event', opt.entradas[0].entrada.evento?.id+'-'+(opt.entradas[0].entrada.evento?.titulo.replaceAll(' ','-'))]">
                        {{ opt.entradas[0].entrada.evento.titulo }}
                    </span> -->
                        <div class="d-flex align-items-center tickets-info" (click)="openTicketsEvent(opt)">
                            <span class="number">Total:</span>
                            <span class="price">{{ (opt.payment.price) | number:'2.2-2' }}€</span>
                            <!-- <span class="number">x{{ opt.entradas.length }} Entradas</span> -->
                        </div>
                        <!-- <p class="m-0 link-download" *ngIf="opt.idOdoo" (click)="downloadTickets(opt.idOdoo)">Descargar entradas</p> -->
                    </div>
                    <!-- <div *ngIf="(!type && opt.status != 'refunded'); else divRefunded">
                        <span class="refund-info active" (click)="refundTickets(opt)"
                            *ngIf="opt.entradas[0]?.entrada.evento.refundActive; else refundInfo">Devolver
                            entradas</span>
                        <ng-template #refundInfo>
                            <span class="refund-info">Este evento no permite devoluciones</span>
                        </ng-template>
                    </div>
                    <ng-template #divRefunded>
                        <div *ngIf="opt.status == 'refunded'">
                            <span class="refund-info">Devolución realizada correctamente</span>
                        </div>
                    </ng-template> -->
                </div>
            </div>
            <div class="icons-list-events">
                <div (click)="downloadTickets(opt)" class="pointer-in">
                    <i class="icon-open fa-light fa-download"></i>
                    <span>Descargar entradas</span>
                </div>
                <div (click)="openTicketsEvent(opt)" class="pointer-in">
                    <i class="icon-open fa-light fa-chevron-right"></i>
                    <span>Ver entradas</span>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
</div>