import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private http: HttpClient) { }

  getSubscribers(idCompany: number, page: number, searchText: string, sorted: any) {
    let url = `${AppSettings.API_URI}/subscribers/all/company/${idCompany}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (sorted) {
      url += `&orderBy=${sorted.sortedBy}&order=${sorted.sort}`;
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  editSubscription(idSubscription: number, data: any) {
    const url = `${AppSettings.API_URI}/subscribers/edit/${idSubscription}`;
    return this.http.put<any>(url, data).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  editListSubscriptions(data: any) {
    const url = `${AppSettings.API_URI}/edit/list/subscribers`;
    return this.http.put<any>(url, data).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  deleteSubscription(idSubscription: number) {
    const url = `${AppSettings.API_URI}/subscribers/delete/${idSubscription}`;
    return this.http.delete<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  sendInvitation(idCompany: number, data: any) {
    const url = `${AppSettings.API_URI}/subscribers/add/${idCompany}`;
    return this.http.post<any>(url, data).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  subscriberFromUser(idCompany: number, payload: any) {
    const url = `${AppSettings.API_URI}/subscribers/request/${idCompany}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

}
