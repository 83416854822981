<div class="menu-list-maps">
    <div class="info-maps" *ngIf="listMaps | async">
        <p class="m-0">
            <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Ahorra tiempo y crea mapas de
            butacas base, que se podrá utilizar en cada evento que crees. Para editar el mapa de un evento concreto deberás hacerlo editando el evento.
        </p>
    </div>
    <div class="card-map-list">
        <!-- Map Item -->
        <div [routerLink]="['/edit-map', item.id]" *ngFor="let item of listMaps | async">
            <div class="option">
                <i class="icon-section fa-light fa-pipe-section"></i>
                <p class="m-0 option-section">{{item.name}}</p>
                <i class="icon-open fa-light fa-pen"></i>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="d-flex btns-save-map justify-content-end">
        <button class="btn primary-button solid" (click)="createMap()">Crear nuevo mapa</button>
    </div>
</div>