<div class="card-abono">
    <div class="d-flex justify-content-end container-close" (click)="close()">
        <i class="fa-light fa-times pointer-in"></i>
    </div>
    <div class="container-abono-panel">
        <div *ngIf="!loaded || openingMap" class="loading-container" [class.lottieOpened]="openingMap">
            <div class="container-lottie">
                <ng-lottie class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
            </div>
        </div>
        <div *ngIf="loaded" class="grid-container">
            <div class="container-abono">
                <p class="name-event">{{abono?.name}}</p>
                <p>Existe un Abono para los siguientes eventos. Es necesario comprar una entrada de cada uno de ellos para aprovechar el descuento<span class="icon-primary">{{abono?.discount.amount ? ' de
                        '+abono.discount.amount+'€' : ' del '+abono?.discount.percentage + '%'}}</span>.
                </p>
                <p class="m-0 info-disable">*Si alguno de los eventos ha finalizado no se podrá comprar el abono.</p>
                <!-- <div class="info-msg"> -->
                <!-- <p style="text-align: center;" class="code-discount m-0">¡Código <span class="icon-primary">{{abono?.discount.discountCode}}</span>!</p> -->
                <!-- </div> -->
                <div class="list-events">
                    <div *ngFor="let event of listEvents" class="container-event">
                        <div class="item-event" [class.pointer-in]="!isPastEvent(event)" [class.inactiveEvent]="isPastEvent(event)" (click)="openSelectEvent(event)">
                            <div class="img-event">
                                <img fallimg class="img-card-event" [src]="urlImages+'/'+event.id+'/'+event.imageurl" />
                            </div>
                            <div class="info-event">
                                <p class="title-event m-0">{{event.titulo}}</p>
                                <div class="date-event pointer-in"
                                    *ngIf="!event.fechaInicio && (!event.datesRange?.end || event.datesRange?.end == event.datesRange?.start)">
                                    <i class="fa-light fa-calendar icon-primary"></i>
                                    <span>{{ (event.datesRange?.next | date: 'dd MMM') == (today | date: 'dd MMM') ?
                                        'Hoy' :
                                        (event.datesRange?.next | date: 'EEEE, dd MMMM') | titlecase }}
                                        {{event.hourStart ?
                                        ' - '+event.hourStart : ''}}</span>
                                </div>
                                <div class="ticket-event pointer-in">
                                    <i [class]="event.ticket ? 'fa-solid icon-green fa-ticket' : 'fa-light icon-primary fa-ticket'" *ngIf="!isPastEvent(event)"></i>
                                    <div class="d-grid">
                                        <div *ngIf="!event.ticket" class="hand-select">
                                            <span>{{isPastEvent(event) ? 'Evento finalizado' : 'Selecciona una entrada'}}</span>
                                            <i class="fa-light fa-hand-pointer icon-primary" *ngIf="!isPastEvent(event)"></i>
                                        </div>
                                        <div *ngIf="event.ticket">
                                            <span>{{event.ticket?.option.ticket?.nombre}}</span>
                                        </div>
                                        <span *ngIf="event.ticket">{{
                                            event.ticket?.section.name + ', '}}{{event.ticket.showRow ? 'Fila:'+event.ticket.row + ', ' : ''}} {{'Butaca: ' +
                                            event.ticket?.seat}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div (click)="openSelectEvent(event)">
                            <i class="fa-light fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <!-- <div class="info-msg">
        <i class="fa-light fa-circle-info icon-primary"></i>
        <span>Recuerda introducir el código en el carrito antes de finalizar la compra.</span>
    </div> -->
            </div>
            <div class="container-btn-abono">
                <button class="primary-button btn-continue w-100" (click)="goPay(false)"
                    [class.disabled]="ticketsLength != listEvents.length || openingMap">Comprar otro abono</button>
                <button class="primary-button btn-other w-100" (click)="goPay(true)"
                    [class.disabled]="ticketsLength != listEvents.length || openingMap">Añadir al carrito</button>
            </div>
        </div>
    </div>
</div>