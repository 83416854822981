import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private common: CommonService, private http: HttpClient) { }

  getUsers(page: number = null, items: number = null, sorted: string = null, search: String = '') {
    var urlFilter = '';
    if (search != null) { urlFilter += `email=${search}` }
    if (sorted != null) { urlFilter += `&${sorted}` }
    const url = `${AppSettings.API_URI}/users?${urlFilter}&items=${items}&page=${page}`;
    return this.common.getData(url);
  }

  getUserbyId(id: any) {
    const url = `${AppSettings.API_URI}/users/${id}`;
    return this.common.getData(url, false, 0);
  }

  getPreferencias() {
    const url = `${AppSettings.API_URI}/preferencias`;
    return this.common.getData(url, false, 0);
  }

  getEventosUser(idUser: number, page: number = 1, items: number = 12, date: string = 'after') {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?usuario.id=${idUser}&activo=true&order[fechas.fechaInicio]=asc&fechas.fechaFin[${date}]=${now}&page=${page}&items=${items}`;
    return this.common.getData(url);
  }

  getEventsOwner(idUser: number, page: number = 1, items: number = 12, type: string = 'active') {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos/list/${type}?usuario=${idUser}&page=${page}&items=${items}`;
    return this.common.getData(url);
  }

  getEventsCreator(isBusiness: boolean, idCreator: number, page: number = 1, items: number = 12) {
    let url = `${AppSettings.API_URI}/web/events/list/${isBusiness ? 'business' : 'venues'}/${idCreator}?page=${page}&items=${items}`;
    return this.common.getData(url);
  }

  getDraftsEvents(page: number) {
    let url = `${AppSettings.API_URI}/draft/events?items=12&page=${page}`;
    return this.common.getData(url);
  }

  getAllEventsByUser(userId: number, page: number = 1, items: number = 12) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/eventos?usuario.id=${userId}&page=${page}&items=${items}`;
    return this.http.get<any>(url).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(err);
      })
    );
  }

  getAllBuysByUser(userId: number, page: number = 1, items: number = 12) {
    const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    let url = `${AppSettings.API_URI}/buyticket?usuarios.id=${userId}&page=${page}&items=${items}`;
    return this.http.get<any>(url).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(err);
      })
    );
  }

  putUser(userId: number, formUser: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/users/${userId}`;
    return this.http.put<any>(url, formUser, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(err);
      })
    );
  }

  postUser(user) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/user_register`;
    return this.http.post<any>(url, user, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(err);
      })
    );
  }

  deleteUser(userId: number) {
    const url = `${AppSettings.API_URI}/users/${userId}`;
    return this.http.delete<any>(url).pipe(
      map(data => {
        if (data) {
          return of(true);
        } else {
          return of(false);
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }

  uploadFilePut(userImage) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/user_image`;
    return this.http.put<any>(url, userImage, formHeaders).pipe(
      map(data => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
  uploadFilePost(userImage) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/user_image`;
    return this.http.post<any>(url, userImage, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  sendMailCreatePwd(user) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/create_pwd`;
    return this.http.post<any>(url, { email: user }, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  sendSMS(movil: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/sms_verification`;
    return this.http.post<any>(url, { movil: movil }, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        }
      }), catchError(err => {
        return err;
      })
    );
  }

  validateSMS(formData: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/smscode_verify`;
    return this.http.post<any>(url, formData, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        }
      }), catchError(err => {
        return err;
      })
    );
  }

  getSavedCardsUser() {
    const url = `${AppSettings.API_URI}/billing/cards/user`;
    return this.http.get<any>(url).pipe(
      map((data) => { return data }),
      catchError(err => { return throwError(() => err); })
    );
  }

  deleteSavedCardUser(idCard: number) {
    const url = `${AppSettings.API_URI}/billing/card/${idCard}`;
    return this.http.delete<any>(url).pipe(
      map((data) => { return data }),
      catchError(err => { return throwError(() => err); })
    );
  }

}
