<div class="btn-fixed-description">
  <div class="description-container">
    <div class="d-flex justify-content-between align-items-center desc-header">
        <p class="m-0">Descripción*</p>
        <span class="count-number">{{ 8000 - textArea.length }}</span>
    </div>
    <mat-divider></mat-divider>
    
    <angular-editor #edit id="1" [(ngModel)]="textArea" [config]="editorConfig">
        <ng-template #customButtons let-executeCommandFn="executeCommandFn">
          <ae-toolbar-set class="button-styles-text" tabindex="">
            <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" [enableSearch]="false" [showPreview]="false" (emojiSelect)="executeCommandFn('insertHtml', addEmoji($event))"></emoji-mart>
            <button class="btn-style btn-bold" (click)="executeCommandFn('bold')"><strong>N</strong></button>
            <button class="btn-style btn-italic" (click)="executeCommandFn('italic')"><i>K</i></button>
            <button class="btn-style btn-underline" (click)="executeCommandFn('underline')"><u>S</u></button>
            <button class="btn-emoji pointer-in" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;"><i class="fa-light fa-face-smile"></i></button>
          </ae-toolbar-set>
        </ng-template>
    </angular-editor>
  </div>
  <button class="btn-new-form btn-save w-100" (click)="save()">GUARDAR DESCRIPCIÓN</button>
</div>