import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { of, throwError } from 'rxjs';
import { ICreateOrderRequest } from 'ngx-paypal';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient) { }

  chackoutTicket(jsonTickets: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/checkout/tickets`;
    return this.http.post<any>(url, jsonTickets, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  chargeStripe(striperesponse: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/stripepayments`;
    return this.http.post<any>(url, striperesponse, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } 
        return of(false);
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getTransaction(idTransaction: number) {
    const url = `${AppSettings.API_URI}/user/transaction/${idTransaction}`;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      }), 
      catchError(err => { return throwError(() => err); })
    );
  }

  getPriceCart(business: number, bookings: any, discount: any) {
    const url = `${AppSettings.API_URI}/price/checkout/web`;
    const payload = {
      business: business,
      bookings: bookings,
      discount: discount
    };
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  chargePayWeb(business: number, bookings: any, discount: any, type: string) {
    const url = `${AppSettings.API_URI}/checkout/${type}`;
    const payload = {
      business: business,
      bookings: bookings,
      discount: discount
    };
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  postUrlRedsys(urlRedsys: any, payload: any) {
    const url = `${urlRedsys}`;
    return this.http.post<any>(url, payload).pipe(
      map(data => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  changeProtocolo(payload: any) {
    const url = `${AppSettings.API_URI}/checkout/protocol`;
    return this.http.post<any>(url, payload).pipe(
      map(data => {
        return data;
      }), 
      catchError(err => { return throwError(() => err); })
    );
  }

  checkStripe(idPayment: any) {
    const url = `${AppSettings.API_URI}/checkPay/${idPayment}`;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      }), 
      catchError(err => { return throwError(() => err); })
    );
  }

  refundStripe(striperesponse: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/refundstripe`;
    return this.http.post<any>(url, striperesponse, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  initConfig(data: any): any {
    const payPalConfig = {
      currency: "EUR",
      clientId: "sb",
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '9.99',
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: '9.99'
                }
              }
            },
            items: [{
              name: 'Entrada ZurracapoteConf 2023',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'EUR',
                value: '9.99',
              },
            }]
          }]
      },
      advanced: {
        commit: 'false'
      },
      style: {
        label: 'paypal',
        layout: 'horizontal',
        color: 'white',
        shape: 'pill',
        tagline: false
      },
      onApprove: (data, actions) => {
        if(AppSettings.DEBUG) {
          console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then(details => {
            console.log('onApprove - you can get full order details inside onApprove: ', details);
            //Llamar al servicio Back
          });
        }
      },
      onClientAuthorization: (data) => {
        if(AppSettings.DEBUG) {
          console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        }
      },
      onCancel: (data, actions) => {
        if(AppSettings.DEBUG) {
          console.log('OnCancel', data, actions);
        }
        //cancel by user
      },
      onError: err => {
        if(AppSettings.DEBUG) {
          console.log('OnError', err);
        }
        //error in transaction
      },
      onClick: (data, actions) => {
        if(AppSettings.DEBUG) {
          console.log("onClick", data, actions);
        }
      }
    };
    return payPalConfig;
  }
}
