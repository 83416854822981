<div id="header" class="d-flex align-items-center justify-content-between header-admin">
    <div class="logo">
        <a [routerLink]="['/']"><img src="/assets/img/citylok_horizontal.svg" alt="Img Citylok" class="img-citylok"></a>
    </div>
    <!-- <div class="menu-admin" *ngIf="nombreUser" mat-button [matMenuTriggerFor]="menu" matRipple> -->
    <div *ngIf="nombreUser" class="menu-admin" mat-button [routerLink]="['/profile']">
        <div class="d-flex align-items-center btn-panel">
            <img mat-card-avatar fallimg class="perfil-card-image" [src]="imagen">
            <p class="name-admin mb-0">{{ nombreUser }}</p>
            <span class="icon-arrow-down"><i class="fa-light fa-right-from-line fa-lg"></i></span>
        </div>
        <!-- <mat-menu #menu="matMenu" class="admin-panel">
        <button [routerLink]="['']" mat-menu-item><i class="fal fa-user-alt"></i> Perfil</button>
        <button [routerLink]="['/admin']" mat-menu-item><i class="fal fa-key"></i> Panel Administrador</button>
        <button (click)="logout()" mat-menu-item><i class="fal fa-sign-out-alt"></i> Cerrar sesión</button>
    </mat-menu> -->
    </div>
</div>