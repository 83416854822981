import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-name-map',
  templateUrl: './name-map.component.html',
  styleUrls: ['./name-map.component.scss']
})
export class NameMapComponent implements OnInit {

  name: string = '';
  editMap: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data, @Optional() private dialogRef: MatDialogRef<NameMapComponent>) { }

  ngOnInit(): void {
    this.editMap = this.data.edit;
    if(this.data.name) {
      this.name = this.data.name;
    }
  }

  saveName() {
    this.dialogRef.close({edit: true, name: this.name});
  }

}
