import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CategoriasService } from 'src/app/services/categorias.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  categorias: Observable<any[]>;
  categorySelect!: any;
  listCategories: any[] = [];

  titleDialog = 'Selecciona una categoria';
  loading: boolean = true;

  constructor(private categoriasService: CategoriasService, private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CategoriesListComponent>) { }

  ngOnInit(): void {
    this.categoriasService.getAllCategories().subscribe({
      next: (data) => {
        this.categorias = data;
        this.loading = false;
      }
    })
    this.categorySelect = this.data.categorySelect;
    this.listCategories.push(this.categorySelect);
  }

  selectCategory(categoria: any) {
    this.categorySelect = categoria;
    let indexCategory = this.listCategories.indexOf(categoria);
    if(indexCategory > -1) {
      // this.listCategories.splice(indexCategory, 1);
    } else {
      this.listCategories = [categoria];
    }
  }

  closeDialog() {        
    this.dialog.closeAll();
  }

  saveCategory() {
    this.dialogRef.close(this.listCategories);
  }

}
