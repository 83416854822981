import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class AreasSeatService {

  defaultColors: any[] = ['#d7d71e', '#3bc5ff', '#f85050', '#d870f9', '#36bc3c', '#ffab6e', '#5622ff'];
  defaultColorsHidden: any[] = ['#010012', '#26239b', '#6e00db', '#006d7e', '#055302', '#726000'];

  // Default colors
  colorDefaultSeat: string = '#ffffff';
  colorDefaultStand: string = '#d8e1d7';
  colorDefaultFree: string = '#d8e1d7';
  colorDefaultStage: string = '#bae4ff';
  colorDefaultHidden: string = '#4d4d4d';
  colorDefaultSelect: string = '#FF4D80';
  colorDefaultDisplay: string = '#ffaec5';
  colorDefaultVisibility: string = '#aaaaaa';
  colorDefaultMovility: string = '#1e00ff';
  colorDefaultBasic: string = '#E8E8E8';

  arrayParams: any[] = ['eventSeat', 'seat', 'row', 'column', 'groupSeat', 'section', 'isSection', 'level', 'hidden', 'rows', 'columns', 'isVisible', 'showRows', 'hiddenType', 'showRows', 'isInfoSection', 'area', 'name'];

  constructor(private http: HttpClient) { }

  getDefaultColors() {
    return this.defaultColors;
  }

  getDefaultColorsHidden() {
    return this.defaultColorsHidden;
  }

  getIndexColor(index: any) {
    return this.defaultColors[index];
  }

  getIndexColorHidden(index: any) {
    return this.defaultColorsHidden[index];
  }

  getLevelsMap(idMap: number) {
    const url = `${AppSettings.API_URI}/levels?map.id=${idMap}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  getTicketsLevel(level: any, mapId: any, payload: any) {
    const url = `${AppSettings.API_URI}/tickets/level/${level.orderBuy}/${mapId}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      })
    );
  }

}
