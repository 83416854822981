<div class="template-admin-card">

  <div w-100 class="row align-items-center justify-content-between title-table">
      <h2 class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 title-h2">Banners</h2>
      <div class="col col-lg-9 col-md-9 col-sm-12 col-xs-12 div-buttons" style="text-align: end">
      </div>
  </div>
  <div class="platform-card">

    <mat-card appearance="outlined" class="mt-1">
      <mat-card-content class="platform-card-description" class="content-table">

        <div w-100 class="d-flex align-items-center justify-content-between title-table">
          <button class="mat-stroked-button" [routerLink]="['/admin/config/banner/new-banner']"><i class="new far fa-plus"></i>Nuevo banner</button>
        </div>

        <div class="container-table">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z28">
            <ng-container matColumnDef="imagen">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let banner">
                <img fallimg class="imgBanner" src="{{ apiMedia }}/users/defaultUser.png">
                <!-- <img *ngIf="banner.image == ''" class="imgBanner" src="{{ apiMedia }}/users/defaultUser.png">
                <img *ngIf="banner.image !== ''" class="imgBanner" src="{{ apiMedia }}/users/{{user.imagen}}"> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="titulo">
              <th mat-header-cell *matHeaderCellDef><b>Titulo</b></th>
              <td mat-cell *matCellDef="let banner">{{banner.title}}</td>
            </ng-container>
            <ng-container matColumnDef="showhome">
              <th mat-header-cell *matHeaderCellDef><b>Show home</b></th>
              <td mat-cell *matCellDef="let banner">
                <i *ngIf="banner.showhome" class="fa-solid fa-check"></i>
                <i *ngIf="!banner.showhome" class="fa-solid fa-x"></i>
              </td>
            </ng-container>
            <ng-container matColumnDef="isRight">
              <th mat-header-cell *matHeaderCellDef><b>isRight</b></th>
              <td mat-cell *matCellDef="let banner">
                <i *ngIf="banner.imgIsright" class="fa-solid fa-check"></i>
                <i *ngIf="!banner.imgIsright" class="fa-solid fa-x"></i>
              </td>
            </ng-container>

            <!-- Eliminar/Editar -->
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let banner">
                <button mat-button [matMenuTriggerFor]="menuOptions" class="menu-table">
                  <i class="fas fa-ellipsis-v pointsIcon"></i>
                </button>
                <mat-menu class="menu-options" #menuOptions="matMenu">
                    <button mat-menu-item [routerLink]="['/admin/config/banner/new-banner',banner.id]" class="editIcon"><i class="fal fa-edit"></i> Editar</button>
                    <button mat-menu-item class="deleteIcon" (click)="openDelete(banner.id)"><i class="far fa-trash-alt"></i> Eliminar</button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

      </mat-card-content>
    </mat-card>

  </div>

</div>
