import { AfterViewInit, Component, ElementRef, HostListener, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements AfterViewInit {

  @Input() sizeTitle: string = 'small';
  @Input() title: string = 'Explorar';
  @Input() titleWhite: boolean = false;
  @Input() events: Observable<any[]>;
  @Input() small: boolean = true;
  @Input() arrow: boolean = true;
  @Input() link: string = '/results';
  loading: boolean = true;
  // Category
  private skeleton = new BehaviorSubject<any[]>(Array(8));
  public arraySkeleton = this.skeleton.asObservable();

  @ViewChild('container') containerElement: any;
  @ViewChild('swiper') swiperElement: any;
  smallCards: boolean = true;
  offsetLeft = new BehaviorSubject<number>(60);
  offsetLeftValue = this.offsetLeft.asObservable();
  hovered: boolean = false;

  swiperSmallConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    slidesOffsetAfter: 12,
    updateOnWindowResize: true,
  }

  swiperBigConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 48,
    slidesOffsetAfter: 0,
    updateOnWindowResize: true,
    pagination: {
      bulletClass: `swiper-pagination-bullet pagination-custom`,
      enabled: false
    },
    breakpoints: {
      500: {
        pagination: { enabled: false },
        slidesOffsetAfter: 12,
      }
    }
  }

  constructor(private router: Router, private commonService: CommonService) { }

  ngAfterViewInit() {
    this.offsetLeft.next(this.containerElement.nativeElement.offsetLeft + 12);
    this.offsetLeftValue.subscribe(res => {
      if (this.small || (!this.small && window.innerWidth > 500)) {
        this.swiperElement.elementRef.nativeElement.swiper.params.slidesOffsetBefore = res;
      }
    })
    this.commonService.cityStorage.subscribe(
      currentCity => {
        this.skeleton.next(Array(8));
        setTimeout(() => {
          this.skeleton.next(Array(0));
        }, 200);
      })
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.offsetLeft.next(this.containerElement.nativeElement.offsetLeft + 12);
  }

  goToLink() {
    if (this.link) {
      if (this.link == '/destacados') {
        this.router.navigate(['/results'], { state: { filters: 'Destacados' } });
      } else {
        this.router.navigate([this.link]);
      }
    }
  }

  hoverCard(index: any) {
    const arr: any[] = this.swiperElement.elementRef.nativeElement.swiper.slides;
    for (let i = 0; i < index; i++) {
      const element: HTMLElement = arr[i];
      element?.classList.remove('swiper-hover-next');
      element?.classList.add('swiper-hover-prev');
    }
    this.swiperElement.elementRef.nativeElement.swiper.activeIndex = index;
    for (let i = index + 1; i < arr.length; i++) {
      const element: HTMLElement = arr[i];
      element?.classList.remove('swiper-hover-prev');
      element?.classList.add('swiper-hover-next');
    }
    this.swiperElement.elementRef.nativeElement.swiper.updateSlidesClasses();
  }

  outHoverCard() {
    const arr: any[] = this.swiperElement.elementRef.nativeElement.swiper.slides;
    for (let i = 0; i < arr.length; i++) {
      const element: HTMLElement = arr[i];
      element?.classList.remove('swiper-hover-next');
      element?.classList.remove('swiper-hover-prev');
    }
    this.swiperElement.elementRef.nativeElement.swiper.updateSlidesClasses();
  }


}
