import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-router-venue',
  templateUrl: './router-venue.component.html',
  styleUrls: ['./router-venue.component.scss']
})
export class RouterVenueComponent {

  idVenue!: any;
  venue!: any;

  pageActive!: any;
  tabSelected: number = 0;
  titleTab: string = 'Datos del espacio';

  business: any;
  owner!: any;
  businessSelect!: any;

  mapPage: boolean = false;
  actionMap!: any;

  constructor(private router: Router, private apiPlaces: PlacesService, private apiBusiness: ApiBusinessService) { }

  ngOnInit(): void {
    this.apiBusiness.changed.subscribe({
      next: (value) => {
        // console.log(value)
        if(value && value != 'place' && !value.id) {
          this.idVenue = value;
          this.router.navigate(['/panel-control/config/venues/info-venue', this.idVenue]).then((value) => this.getDataVenue());
        }
        if(value == 'place') {
          this.router.navigate(['/panel-control/config/venues']);
        }
      }
    });
    let url = location.pathname.split('/');
    this.mapPage = location.pathname.indexOf('map-venue') != -1;
   
    if (!this.idVenue) {
      this.idVenue = url[url.length - 1];
      this.getDataVenue();
    }
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          var url = event.url;
          this.mapPage = url.indexOf('map-venue') != -1;
        }
      });
    this.apiPlaces.mapActionObservable.subscribe({
      next: (data) => {
        this.actionMap = data;
      }
    });
  }

  changeMenuEvent(event: any) {
    this.titleTab = event.name;
  }

  getDataVenue() {
    this.apiPlaces.getPlaceById(this.idVenue).subscribe({
      next: (res: any) => {
        this.venue = res;
      }
    });
  }
}
