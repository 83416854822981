import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-orders-panel',
  templateUrl: './orders-panel.component.html',
  styleUrls: ['./orders-panel.component.scss']
})
export class OrdersPanelComponent implements OnInit {

  orders!: any[];
  searchText!: string;

  displayedColumns: string[] = ['options', 'id', 'name', 'email', 'canal', 'date', 'price'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  page = 1;
  totalPages = 1;

  sorted!: any;
  loaded: boolean = false;
  loadingPage: boolean = true;

  constructor(private panelService: PanelAdminService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
    this.panelService.getOrdersOrganizer(this.page, this.searchText, null).subscribe({
      next: (res) => {
        this.orders = res.data;
        this.dataSource = res.data;
        this.dataSource.sort = this.sort;
        this.totalPages = res.last_page;
        this.loaded = true;
        this.loadingPage = false;
      }
    })
  }

  searchOrder(event) {
    this.searchText = event;
    this.page = 1;
    this.loadingPage = true;
    this.getData();
  }

  openOrder(id: number, name: string, fragment?: string) {
  }

  getFilterOrderData() {
    // this.panelService.getAllEvents(this.page, this.sorted, this.searchText).subscribe(data => {
    //   this.dataSource = data['hydra:member'];
    //   this.dataSource.sort = this.sort;
    //   this.numItems = data['hydra:totalItems'];
    //   this.totalPages = Math.ceil(this.numItems / this.items);
    // });
  }

  getStatus(event: any) {
    return 'Disponible';
  }

  downloadOrder(channel: string, idOrder: number) {
    this.panelService.downloadTicket(channel, idOrder).subscribe({
      next: (url) => {
        window.open(url, '_blank');
        this.dialog.open(InfoCardComponent, {
          panelClass: 'info-dialog',
          data: {
            icon: false,
            text: 'Se ha descargado el pedido correctamente',
            btnCancel: false
          }
        });
      }
    })
  }

  downloadTicketOrder(channel, idOrder: number) {
    this.panelService.downloadPrintTicket(channel, idOrder).subscribe({
      next: (url) => {
        window.open(url, '_blank');
        this.dialog.open(InfoCardComponent, {
          panelClass: 'info-dialog',
          data: {
            icon: false,
            text: 'Se ha descargado el ticket correctamente',
            btnCancel: false
          }
        });
      }
    })
  }

  // Sort
  sortData(sort: Sort) {
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      this.sorted = { sortedBy: sort.active, sort: isAsc };
      this.getFilterOrderData();
    } else {
      this.sorted = null;
      this.getData();
    }
  }

  //Pagination
  pageChange(event: any) {
    this.page = event;
    // this.getData();
  }

  prevPage() {
    if (this.page > 1) {
      this.page--;
      this.loadingPage = true;
      window.scrollTo(0,0);
      this.getData();
    }
  }

  nextPag() {
    if (this.page < this.totalPages) {
      this.page++;
      this.loadingPage = true;
      window.scrollTo(0,0);
      this.getData();
    }
  }

}
