<div class="discounts-event">
    <div class="d-flex align-items-center title-edit">
        <p class="m-0">Descuentos</p>
        <i class="fal fa-pen pointer-in" *ngIf="profile?.admin"></i>
    </div>
    <p *ngIf="(!abonos || abonos?.length <= 0) && (!discounts || discounts?.length <= 0)">No hay descuentos disponibles</p>
    <div class="section-discounts table-discounts table-panel" *ngIf="discounts?.length > 0">
        <table mat-table [dataSource]="dataSourceDiscounts" class="mat-elevation-z28">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef><b>Nombre del descuento</b></th>
                <td mat-cell *matCellDef="let discount">{{discount.name}}</td>
            </ng-container>
            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef><b>Descuento</b></th>
                <td mat-cell *matCellDef="let discount">{{discount.amount ? discount.amount+'€' :
                    discount.percentage+'%'}}</td>
            </ng-container>
            <ng-container matColumnDef="numMax">
                <th mat-header-cell *matHeaderCellDef><b>Nº de usos</b></th>
                <td mat-cell *matCellDef="let discount">{{discount.numMax}}</td>
            </ng-container>
            <!-- <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef><b>Descripción</b></th>
                <td mat-cell *matCellDef="let discount"></td>
            </ng-container> -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef><b>CÓDIGO</b></th>
                <td mat-cell *matCellDef="let discount" (click)="copyCode(discount.discountCode)">{{discount.discountCode}}</td>
            </ng-container>
            <ng-container matColumnDef="dates">
                <th mat-header-cell *matHeaderCellDef><b>Inicio/fin</b></th>
                <td mat-cell *matCellDef="let discount">{{discount.dateStart|date:'d/M/yyyy'}}{{' -
                    '+(discount.dateEnd|date:'d/M/yyyy')}}</td>
            </ng-container>
            <ng-container matColumnDef="orders">
                <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
                <td mat-cell *matCellDef="let discount">-</td>
            </ng-container>
            <ng-container matColumnDef="hiddenSeat">
                <th mat-header-cell *matHeaderCellDef><b>Desbloquea asientos</b></th>
                <td mat-cell *matCellDef="let discount">{{discount.hiddenSeat}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDiscounts"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDiscounts;"></tr>
        </table>
    </div>
    <div *ngIf="abonos?.length > 0">
        <div class="d-flex align-items-center title-edit">
            <p class="m-0">Abonos</p>
            <i class="fal fa-pen pointer-in"></i>
        </div>
        <div class="section-abonos table-discounts">
            <table mat-table [dataSource]="dataSourceAbonos" class="mat-elevation-z28">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef><b>Nombre del descuento</b></th>
                    <td mat-cell *matCellDef="let abono">{{abono.name}}</td>
                </ng-container>
                <ng-container matColumnDef="discount">
                    <th mat-header-cell *matHeaderCellDef><b>Descuento</b></th>
                    <td mat-cell *matCellDef="let abono">{{abono.amount != null ? abono.amount+'€' : abono.percentage+'%'}}</td>
                </ng-container>
                <ng-container matColumnDef="events">
                    <th mat-header-cell *matHeaderCellDef><b>Eventos asociados</b></th>
                    <td mat-cell *matCellDef="let abono"></td>
                </ng-container>
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef><b>CÓDIGO</b></th>
                    <td mat-cell *matCellDef="let abono" (click)="copyCode(abono.discountCode)">{{abono.discountCode}}</td>
                </ng-container>
                <ng-container matColumnDef="dates">
                    <th mat-header-cell *matHeaderCellDef><b>Inicio/fin</b></th>
                    <td mat-cell *matCellDef="let abono">{{abono.dateStart|date:'d/M/yyyy'}}{{' -
                        '+(abono.dateEnd|date:'d/M/yyyy')}}</td>
                </ng-container>
                <ng-container matColumnDef="orders">
                    <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
                    <td mat-cell *matCellDef="let abono">-</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAbonos"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAbonos;"></tr>
            </table>
        </div>
    </div>
</div>