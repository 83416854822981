import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-docs-venue',
  templateUrl: './docs-venue.component.html',
  styleUrls: ['./docs-venue.component.scss']
})
export class DocsVenueComponent {

  business: any;
  searchText!: string;
  now: Date = new Date();

  displayedColumns: string[] = ['actions', 'name', 'sendBy', 'createdAt', 'status', 'expirationDate'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  sorted!: any;

  page = 1;
  totalPages = 1;
  items = 0;

  urlImages: string = AppSettings.MEDIA_URI + '/images/events/';
  loaded: boolean = false;
  loadingPage: boolean = true;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  private searchTerms = new Subject<string>();

  constructor(private panelService: PanelAdminService, private placeService: PlacesService, private router: Router, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.now.setHours(0);
    this.now.setMinutes(0);
  }

  ngAfterViewInit() {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');

    this.getData();
    this.searchTerms.pipe(
      debounceTime(300), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.updateDoc();
    });
  }

  getData() {
    // this.placeService.getListFilterPlaces(this.business?.id, this.page, this.searchText, this.sorted).subscribe({
    //   next: (res) => {
    //     this.venues = this.venues.concat(res);

    //     if (this.venues.length <= 0 || this.venues.length < 20) {
    //       this.finishScroll = true;
    //     }
    //     this.dataSource = new MatTableDataSource(this.venues);
    //     this.dataSource.sort = this.sort;

    //     this.loaded = true;
    //     this.loadingPage = false;
    //   }
    // });
  }

  updateDoc() {
    // this.page = 1;
    // this.loadingPage = true;
    // this.venues = [];
    // this.getData();
  }

  searchDoc(event) {
    this.searchTerms.next(event);
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }

}
