import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-bond',
  templateUrl: './create-bond.component.html',
  styleUrls: ['./create-bond.component.scss']
})
export class CreateBondComponent {

  idCompany: any = 1;
  formNewManager: FormGroup = new FormGroup({});
  formType: string = 'amount';
  editBond: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CreateBondComponent>,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.data ? this.idCompany = this.data.idCompany : null;
    this.editBond = this.data?.edit;
    this.formNewManager = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['amount', Validators.required],
      cuantity: ['', Validators.required],
    });
    if(this.data.object) {
      this.formNewManager.patchValue(this.data.object);
    }
  }

  ngAfterViewInit() {
  }

  cancelNew() {
    this.dialogRef.close();
  }

  nextStep() {
    if (this.formNewManager.valid) {
      // Indicar abrir select place
      this.dialogRef.close({ bond: this.formNewManager.value });
    }
  }

}

