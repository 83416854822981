<!-- <div class="hidden-seats">
    <p class="info-hidden">*En esta vista no se puede modificar la estructura del mapa, selecciona asientos para ocultarlos y que no se puedan reservar.</p>
    <div class="titles-hidden-seats">
        <p>Capacidad total: {{capacity}}</p>
        <p>Asientos ocultos: {{hiddenSeats.length}}</p>
    </div>
    <div class="section-option btn-hidden" (click)="changeHidden(false)">
        <p class="m-0">Desmarcar todos</p>
        <i class="fa-light fa-eye-slash"></i>
    </div>
</div> -->

<div class="hidden-seats">
    <p class="info-hidden" *ngIf="!fromCreate">*En esta vista no se puede modificar la estructura del mapa, selecciona asientos para
        ocultarlos y que no se puedan reservar.</p>
    <p class="info-hidden" *ngIf="fromCreate">*Termina de editar los cambios antes de guardar el evento</p>
    <div class="title-hidden-seats">
        <!-- <p>Capacidad total: {{capacity}}</p> -->
        <p>Asientos ocultos: {{assignedHidden.length}}</p>
        <p>Asientos seleccionados: {{selectedSeats.length}}</p>
    </div>
    <div cdkDropList #levelsList="cdkDropList" [cdkDropListData]="hiddenTypes" class="drag-level-list"
        (cdkDropListDropped)="drop($event)">
        <div class="card-level" *ngFor="let type of hiddenTypes; let i = index" cdkDrag>
            <div class="d-flex w-100" style="gap: 20px;">
                <div class="icon-move" cdkDragHandle>
                    <i class="fa-light fa-arrows-from-dotted-line"></i>
                </div>
                <div class="d-flex justify-content-between w-100">
                    <div class="info-level">
                        <p>{{type.name}}</p>
                        <p>Asientos: {{type.seats || 0}}</p>
                    </div>
                    <div class="color-level"
                        [style.background-color]="type.color ? type.color : defaultColors[type.orderBuy-1]">
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="hiddenEdit?.orderBuy == type.orderBuy" class="edit-level-forms">
                <div class="bloque-form-input container-form d-flex align-items-center">
                    <input class="w-100" type="text" placeholder="Nombre" [(ngModel)]="type.name">
                </div>
                <div class="bloque-form-input container-form d-flex align-items-center">
                    <input class="w-100" type="text" placeholder="#Hex" [(ngModel)]="type.color">
                    <div class="block-color">
                        <input type="color" [(ngModel)]="type.color">
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>

            <div class="d-flex justify-content-between icon-edit-level">
                <div class="d-flex align-items-center item-edit"
                    (click)="hiddenSeats?.orderBuy != type.orderBuy ? typeAssignActive(type) : assignSeats(true)"
                    [class.disabled-clicks]="!enabledClickBtns"
                    [class.save-styles]="hiddenSeats?.orderBuy == type.orderBuy">
                    <i class="fa-light fa-{{hiddenSeats?.orderBuy == type.orderBuy ?'floppy-disk':'circle-plus'}}"></i>
                    <p>{{hiddenSeats?.orderBuy == type.orderBuy?'Guardar':'Modificar'}}</p>
                </div>
                <div class="d-flex align-items-center item-edit" [class.disabled-clicks]="!enabledClickBtns"
                    (click)="hiddenEdit?.orderBuy == type.orderBuy ? closeEdit() : editItem(type, i)" *ngIf="admin">
                    <i class="fa-light fa-pen"></i>
                    <p>{{hiddenEdit?.orderBuy == type.orderBuy ? 'Cerrar' : 'Editar' }}</p>
                </div>
                <div class="d-flex align-items-center item-delete"
                    [class.save-styles]="hiddenSeats?.orderBuy == type.orderBuy"
                    [class.disabled-clicks]="!enabledClickBtns"
                    (click)="hiddenSeats?.orderBuy == type.orderBuy ? assignSeats(false) : deleteItem(type)"
                    *ngIf="admin">
                    <i class="fa-light fa-{{hiddenSeats?.orderBuy == type.orderBuy?'ban':'trash-can'}}"></i>
                    <p>{{hiddenSeats?.orderBuy == type.orderBuy ? 'Cancelar' : 'Eliminar'}}</p>
                </div>
                <ng-template #divCancel>
                    <div class="d-flex align-items-center item-delete save-styles"
                        [class.disabled-clicks]="!enabledClickBtns"
                        (click)="assignSeats(false)" *ngIf="hiddenSeats?.orderBuy == type.orderBuy">
                        <i class="fa-light fa-ban"></i>
                        <p>Cancelar</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>