import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettings } from 'src/app/app-settings';
import { BannersService } from 'src/app/services/banners.service';
import { Location } from '@angular/common';
import { InfoCardComponent } from '../../../../shared/info-card/info-card.component';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {

  apiMedia: string = AppSettings.MEDIA_URI;

  displayedColumns: string[] = ['imagen', 'titulo', 'isRight','showhome','options'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  bannSinmostrar = []
  idBann;

  constructor(private bannersService: BannersService,private dialog: MatDialog, private location: Location) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.bannersService.getBanners().subscribe(b => {
      b['hydra:member'].forEach(element => {
        if(!element.showhome){
          this.bannSinmostrar.push(element);
        }
      });
      this.dataSource.data = this.bannSinmostrar;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InfoCardComponent, {

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.goBack();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  addCat(idBann){
    var bann;

    this.bannersService.getBannnerById(idBann).subscribe(b => {
      bann = b;
      bann.showhome = true;

      this.bannersService.putBanner(idBann,bann).subscribe()

      this.goBack();
    });
  }

}
