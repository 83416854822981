import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ComprasService } from 'src/app/services/compras.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {

  @Input() compras: any[] = [];
  @Input() type: number = 0;
  urlImages: string = AppSettings.MEDIA_URI + '/images/users';
  urlEvents: string = AppSettings.MEDIA_URI + '/images/events';
  @Output() emitAction: EventEmitter<boolean> = new EventEmitter();
  @Output() openAction: EventEmitter<boolean> = new EventEmitter();

  constructor(private dialog: MatDialog, private comprasService: ComprasService,
    private panelService: PanelAdminService, private reportService: ReportsService) { }

  ngOnInit(): void {
  }

  totalTickets(entradas: any[]) {
    let total = 0;
    entradas.forEach(element => {
      total+=element.count;
    });
    return total;
  }

  openTicketsEvent(event: any) {
    this.openAction.emit(event);
  }

  downloadTickets(opt: any) {
    this.reportService.getUrlsDownload(opt.idOdoo).subscribe({
      next: (url) => {
        let uriDownload = url['ticket_a4'];
        window.open(uriDownload, '_blank');
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  refundTickets(compra: any) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres devolver las entradas? Esta acción no podrá deshacerse.',
        btnCancel: true,
        warning: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.comprasService.refundTicket(compra).subscribe({
          next: () => {
            this.emitAction.emit(true);
            compra.status = 'refunded';
          }
        })
      }
    })
  }

}
