<div class="draft-list">
    <div class="card-draft-list">
        <!-- Map Item -->
        <div (click)="editEvent(item.id)" *ngFor="let item of listEvents | async">
            <div class="option">
                <div class="img-event-draft">
                    <img fallimg class="img-card-event" [src]="urlImages+'/'+item.id+'/'+item.imageurl" />                        
                </div>
                <p class="m-0 option-section">{{item.titulo}}</p>
                <div>
                    <i class="icon-open fa-light fa-pen"></i>
                    <i class="icon-open fa-light fa-stash"></i>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="d-flex btns-save-map justify-content-end">
        <!-- <app-paginator-results [currentPage]="page" [totalPages]="totalPages" (page)="goToPage($event)"></app-paginator-results> -->
    </div>
</div>