import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import jwt_decode from "jwt-decode";
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuard implements CanActivate {

  private dataBuy!: any;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  hasError() {
    this.router.navigate(['/checkout/validate'], {state: this.dataBuy}).then(() => this.authenticationService.logout());
    return true;
  }

  hasPermission() {
    this.router.navigate(['/checkout/payment'], {state: this.dataBuy});
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let aux = localStorage.getItem('aux');
      this.dataBuy = this.router.getCurrentNavigation()?.extras.state || (aux && JSON.parse(localStorage.getItem('aux'))||'');
      if(!this.dataBuy) {
        return false;
      }
      var token = localStorage.getItem('jwt_token');
      if(token === null && state.url == '/checkout/validate') {
        return true;
      }

      if ((token === null) && state.url == '/checkout/payment') {
        return this.hasError();
      }
      var decoded: any = jwt_decode(token);
      if(decoded && decoded.username) {
        return this.authenticationService.getUsersMe().pipe(map((response: any) => {
          if(response.email == decoded.username && this.authenticationService.user == response.id) {
            if(state.url == '/checkout/validate') {
              return this.hasPermission();
            }
            return true;
          }
          return this.hasError();
        }), catchError(err => {return of(this.hasError())}));
      } else {
        return state.url == '/checkout/payment' ? this.hasError() : true;
      }
  }
  
}
