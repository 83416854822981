import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class OrdersEventService {

  // Search Order Panel
  private searchOrder = new BehaviorSubject<string>(null);
  public searchOrderStorage = this.searchOrder.asObservable();

  // LoadingTable
  private loadingTable = new BehaviorSubject<boolean>(null);
  public loadingTableStorage = this.loadingTable.asObservable();

  // Filters Order Panel
  private filtersOrder = new BehaviorSubject<any>(null);
  public filtersOrderStorage = this.filtersOrder.asObservable();

  constructor(private http: HttpClient) { }

  setFilters(dateFilter: any, priceFilter: any, statusFilter: any, methodFilter: any, scheduleFilter?: any) {
    let filters = { dateFilter, priceFilter, statusFilter, methodFilter, scheduleFilter };
    let actualValue = this.filtersOrder.getValue() || { searchOrder: null, filterOrders: null };
    actualValue.filterOrders = filters;
    this.filtersOrder.next(actualValue);
  }

  setSearchOrder(searchText: string) {
    this.searchOrder.next(searchText);
    let actualValue = this.filtersOrder.getValue() || { searchOrder: null, filterOrders: null };
    actualValue.searchOrder = searchText;
    this.filtersOrder.next(actualValue);
  }

  setLoadingTable(loadingTable: boolean) {
    this.loadingTable.next(loadingTable);
  }

  getReportsByBusiness(idBusiness: number, page: number, searchText: any, filtersOrders: any, orderFilters: any, places: any) {
    let url = `${AppSettings.API_URI}/reports/business/${idBusiness}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (filtersOrders) {
      if (filtersOrders.dateFilter) {
        url += `&dateStart=${filtersOrders.dateFilter.date_from}`;
        if (filtersOrders.dateFilter.date_to) {
          url += `&dateEnd=${filtersOrders.dateFilter.date_to}`;
        }
      }
      if (filtersOrders.priceFilter) {
        url += `&priceFrom=${filtersOrders.priceFilter.min}&priceTo=${filtersOrders.priceFilter.max}`;
      }
      if (filtersOrders.statusFilter) {
        filtersOrders.statusFilter.forEach(element => {
          url += `&status[]=${element}`;
        });
      }
      if (filtersOrders.methodFilter) {
        filtersOrders.methodFilter.forEach(element => {
          url += `&channel[]=${element}`;
        });
      }
    }
    if (places) {
      places?.forEach(element => {
        if (element && element != 'null') {
          url += `&places[]=${element}`;
        }
      });
    }
    if (orderFilters) {
      url += `&orderBy=${orderFilters.orderBy}&order=${orderFilters.order}`;
    }
    // console.log(url)
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }), catchError(err => { return throwError(() => err); })
    );

  }

  getOrdersByEvent(event: number, page: number, searchText: any, filtersOrders: any, orderFilters: any) {
    let url = `${AppSettings.API_URI}/all/orders/event/${event}?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (filtersOrders) {
      if (filtersOrders.dateFilter) {
        url += `&dateStart=${filtersOrders.dateFilter.date_from}`;
        if (filtersOrders.dateFilter.date_to) {
          url += `&dateEnd=${filtersOrders.dateFilter.date_to}`;
        }
      }
      if (filtersOrders.priceFilter) {
        url += `&priceFrom=${filtersOrders.priceFilter.min}&priceTo=${filtersOrders.priceFilter.max}`;
      }
      if (filtersOrders.statusFilter) {
        filtersOrders.statusFilter.forEach(element => {
          url += `&status[]=${element}`;
        });
      }
      if (filtersOrders.methodFilter) {
        filtersOrders.methodFilter.forEach(element => {
          url += `&channel[]=${element}`;
        });
      }
      if (filtersOrders.scheduleFilter) {
        filtersOrders.scheduleFilter.forEach(element => {
          url += `&schedule[]=${element}`;
        });
      }
    }
    if (orderFilters) {
      url += `&orderBy=${orderFilters.orderBy}&order=${orderFilters.order}`;
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }), catchError(err => { return throwError(() => err); })
    );
  }

  getOrdersById(idOrder: number) {
    const url = `${AppSettings.API_URI}/orders/${idOrder}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  getCountTickets(idEvent: number) {
    const url = `${AppSettings.API_URI}/count/tickets/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
