<div class="list-levels">
    <p class="info-view" *ngIf="!fromCreate">*En esta vista no se puede modificar la estructura del mapa, crea áreas y
        asignales asientos. Podrás crear entradas para áreas concretas en los eventos.</p>
    <p class="info-view" *ngIf="fromCreate">*Termina de editar los cambios antes de guardar el evento</p>
    <div class="title-list-level">
        <p>Asientos asignados: {{assignedSeats?.length}} / {{capacity}}</p>
        <p>Asientos seleccionados: {{selectedSeats.length}}</p>
    </div>
    <div cdkDropList #levelsList="cdkDropList" [cdkDropListData]="levels" class="drag-level-list"
        (cdkDropListDropped)="drop($event)">
        <div class="card-level" *ngFor="let level of levels; let i = index" cdkDrag>
            <div class="d-flex w-100" style="gap: 20px;">
                <div class="icon-move" cdkDragHandle>
                    <i class="fa-light fa-arrows-from-dotted-line"></i>
                </div>
                <div class="d-flex justify-content-between w-100">
                    <div class="info-level">
                        <p>{{level.name}}</p>
                        <p>Asientos: {{level.seats || 0}}</p>
                    </div>
                    <div class="color-level"
                        [style.background-color]="level.color ? level.color : defaultColors[level.orderBuy-1]">
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="levelEdit?.orderBuy == level.orderBuy" class="edit-level-forms">
                <div class="bloque-form-input container-form d-flex align-items-center">
                    <input class="w-100" type="text" placeholder="Nombre" [(ngModel)]="level.name">
                </div>
                <div class="bloque-form-input container-form d-flex align-items-center">
                    <input class="w-100" type="text" placeholder="#Hex" [(ngModel)]="level.color">
                    <div class="block-color">
                        <input type="color" [(ngModel)]="level.color">
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>

            <div class="d-flex justify-content-between icon-edit-level">
                <div class="d-flex align-items-center item-edit"
                    (click)="levelSeats?.orderBuy != level.orderBuy ? levelAssignActive(level) : assignSeats(true,level)"
                    [class.disabled-clicks]="!enabledClickBtns"
                    [class.save-styles]="levelSeats?.orderBuy == level.orderBuy">
                    <i class="fa-light fa-{{levelSeats?.orderBuy == level.orderBuy ?'floppy-disk':'circle-plus'}}"></i>
                    <p>{{levelSeats?.orderBuy == level.orderBuy?'Guardar':'Asignar'}}</p>
                </div>
                <div class="d-flex align-items-center item-edit" [class.disabled-clicks]="!enabledClickBtns"
                    (click)="levelEdit?.orderBuy == level.orderBuy ? closeEdit() : editItem(level, i)">
                    <i class="fa-light fa-pen"></i>
                    <p>{{levelEdit?.orderBuy == level.orderBuy ? 'Cerrar' : 'Editar' }}</p>
                </div>
                <div class="d-flex align-items-center item-delete"
                    [class.save-styles]="levelSeats?.orderBuy == level.orderBuy"
                    [class.disabled-clicks]="!enabledClickBtns"
                    (click)="levelSeats?.orderBuy == level.orderBuy ? assignSeats(false) : deleteItem(level)">
                    <i class="fa-light fa-{{levelSeats?.orderBuy == level.orderBuy?'ban':'trash-can'}}"></i>
                    <p>{{levelSeats?.orderBuy == level.orderBuy ? 'Cancelar' : 'Eliminar'}}</p>
                </div>
            </div>
        </div>
    </div>
</div>