import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

  constructor() { }

  getFrom() {
    let route = localStorage.getItem('from');
    //localStorage.removeItem('from');
    return route;
  }

  setFrom(route: any) {
    localStorage.setItem('from', route);
  }

  removeFrom(){
    localStorage.removeItem('from');
  }
}
