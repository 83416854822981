<div style="position: relative;" class="o-div">
    <div class="d-flex container-circles-whites" style="position: absolute;">
        <div class="circle-white circle-0"></div>
        <div class="circle-white circle-1"></div>
        <div class="circle-white circle-2"></div>
        <div class="circle-white circle-3"></div>
    </div>
    <div class="container d-xl-flex" style="position: inherit;">
        <div id="contentInfo" class="container-info-loks col-xl-3">
            <h1 class="h1" style="color: var(--color-primary);">¿Qué es citylok?</h1>
            <p class="info-lok">Citylok es el mayor compilador de <span style="font-weight: bold;">planes, ocio y eventos</span> geolocalizados. 
                Una agenda de ocio personalizada cuya misión es informar sobre lo que ocurre aquí y 
                ahora o en cualquier momento y lugar del mundo, a través de una experiencia de usuario única.</p>
            <h2 style="color: var(--color-primary);">Loks</h2>
            <p class="info-lok">LOK, es término que utilizamos para denominar cada publicación que aparece en Citylok. ¡Navega por los loks!</p>
            <button class="primary-button btn-lok">Conoce Citylok</button>
        </div>
        <div class="circles-info-loks d-flex">
            <div class="col-6 d-grid position-lg-relative circlesHeight">
                <div id="circle-a" class="circle-btn-animation pointer-in d-grid justify-content-center">
                    <div class="circle-in">
                        <span>Ver loks</span>
                    </div>
                    <img fallimg class="img-circle" src="assets/img/ver-loks.svg" alt="Ver Loks" />                        
                </div>
                <div id="animation-a" class="options-info row">
                    <div class="col d-grid justify-content-center animation-option first-animation p-0">
                        <i class="fa-thin fa-heart fa-2x"></i>
                        <p class="title-option-circle">Guarda</p>
                        <p class="desc-option-circle m-0">Guarda en tus favoritos los loks que más te gusten para que no te los pierdas</p>
                    </div>
                    <div class="col d-grid justify-content-center animation-option second-animation p-0">
                        <i class="fa-thin fa-share-nodes fa-2x"></i>                        
                        <p class="title-option-circle">Comparte</p>
                        <p class="desc-option-circle m-0">Comparte los loks con tus amigos o pareja para crear momentos mágicos</p>
                    </div>
                    <div class="col d-grid justify-content-center animation-option third-animation p-0">
                        <i class="fa-thin fa-face-smile-hearts fa-2x"></i>
                        <p class="title-option-circle">¡Asiste!</p>
                        <p class="desc-option-circle m-0">Disfruta de los planes que ofrece tu ciudad</p>
                    </div>
                </div>
            </div>
            <div class="col-6 d-grid position-lg-relative circlesHeight">
                <div id="circle-b" class="circle-btn-animation pointer-in d-grid justify-content-center">
                    <div class="circle-in">
                        <span>Crear loks</span>
                    </div>
                    <img fallimg class="img-circle" src="assets/img/crear-loks.svg" alt="Ver Loks" />                        
                </div>
                <div id="animation-b" class="options-info row">
                    <div class="col d-grid justify-content-center animation-option first-animation p-0">
                        <i class="fa-thin fa-circle-user fa-2x"></i>
                        <p class="title-option-circle">Regístrate</p>
                        <p class="desc-option-circle m-0">Crea una cuenta en Citylok para disfrutar de todos los planes</p>
                    </div>
                    <div class="col d-grid justify-content-center animation-option second-animation p-0">
                        <i class="fa-thin fa-camera-viewfinder fa-2x"></i>                        
                        <p class="title-option-circle">Sube un lok</p>
                        <p class="desc-option-circle m-0">Verifica tu cuenta y sube una publicación con tu evento ¡es muy fácil!</p>
                    </div>
                    <div class="col d-grid justify-content-center animation-option third-animation p-0">
                        <i class="fa-thin fa-wand-magic-sparkles fa-2x"></i>                        
                        <p class="title-option-circle">¡Hazlo brillar!</p>
                        <p class="desc-option-circle m-0">Comparte el evento con tus conocidos y llévalo al siguiente nivel</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>