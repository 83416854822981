import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { EventosService } from 'src/app/services/eventos.service';
import { FilterService } from 'src/app/services/filter.service';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  loader = new Loader({
    apiKey: "AIzaSyANyPC9PxHn5FWztGErqjb2brt-jrGlOiw",
    version: "weekly",
    libraries: ["places"]
  });
  map: google.maps.Map;
  private geocoder: any;
  markers = []
  public maxLat: any
  public minLat: any
  public maxLon: any
  public minLon: any
  private mapsObject = new BehaviorSubject<any>('');
  public mapsObjectStorage = this.mapsObject.asObservable();
  public nombreCiudad
  public show: Boolean = false;
  public iconNormal = "../../../assets/img/mark.png";
  public iconBig = "../../../assets/img/mark_big.png";
  locationSave!: any;

  public mapsSearch = new BehaviorSubject<any>([]);
  public mapsSearchtorage = this.mapsObject.asObservable();

  constructor(public filterService: FilterService, private eventosService: EventosService) { }

  setLocationSave(object) {
    this.locationSave = object;
  }

  async loadMaps(latitude?, longitude?, localizacion?) {

    if (latitude && longitude) {

      latitude = parseFloat(latitude);
      longitude = parseFloat(longitude);
      const mapOptions = {
        center: {
          lat: latitude,
          lng: longitude
        },
        zoom: 15
      };

      this.loader.load().then((google) => {
        this.map = new google.maps.Map(document.getElementById("maps"), mapOptions);
        new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: this.map,
          title: localizacion //ToDo Cambiar por lo que se quiera mostrar de forma dinamica, dirección, nombre del evento, etc
        })
      }).catch(e => {
        if (AppSettings.DEBUG) {
          console.log(e);
        }
      });
    } else if (localizacion && !latitude) {
      const mapOptions = {
        zoom: 15
      };
      this.loader.load().then((google) => {
        this.map = new google.maps.Map(document.getElementById('maps'), mapOptions)
        this.geocoder = new google.maps.Geocoder();
        this.codeAddress(this.geocoder, this.map, localizacion);
      })
    }

  }

  codeAddress(geocoder, map, address) {

    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === 'OK') {
        map.setCenter(results[0].geometry.location);

        new google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          title: address//ToDo Cambiar por lo que se quiera mostrar de forma dinamica, dirección, nombre del evento, etc
        });

      } else {
        if (AppSettings.DEBUG) {
          console.log('Error ' + status);
        }
      }
    });
  }


  async searchMaps() {
    this.loader.load().then((google) => {
      let idCity = localStorage.getItem('city');
      if (idCity) {
        this.eventosService.getCity(parseInt(idCity)).subscribe({
          next: (data) => {
            this.nombreCiudad = data.nombre
            this.geocoder = new google.maps.Geocoder();
            setTimeout(() => {
              this.codingAddress(this.geocoder, this.map, this.nombreCiudad);
            }, 2000)
          }
        });
      }

      if (idCity) {
        this.map = new google.maps.Map(document.getElementById("maps1") as HTMLElement, {
          zoom: 10,
        });
      } else {
        const filter = this.filterService.getFiltersValue()?.find(value => value.type == 'location');
        let lat = 39.5854434;
        let lng = -4.4250326;
        let zoom = 6;
        if (filter) {
          lat = filter.minLat;
          lng = filter.maxLon;
          zoom = filter.zoom;
        }
        this.map = new google.maps.Map(document.getElementById("maps1") as HTMLElement, {
          center: new google.maps.LatLng(lat, lng),
          zoom: zoom,
        });
      }
    }).catch(e => {
      if (AppSettings.DEBUG) {
        console.log(e);
      }
    });

  }

  async markEvents(eventsData: Array<any>) {
    for (let i = 0; i < eventsData.length; i++) {

      let latitude = parseFloat(eventsData[i].latitude);
      let longitude = parseFloat(eventsData[i].longitude);
      var content = '<div class="card-map-event"><div class="title-map-card"></b>' + eventsData[i].titulo + '</b></div><br>' + '<div class="body-map-card" id="bodyContent">' + eventsData[i].localizacion + '</div> <br>'
        + '<a class="link-map-card" href="' + AppSettings.FRONT + '/event/' + eventsData[i].id + '-' + eventsData[i].titulo.replaceAll(' ', '-') + '">Ver este evento </a><div>'

      const infowindow = new google.maps.InfoWindow({
        content: content
        // ariaLabel: eventsData[i].titulo,
      });

      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        icon: this.iconNormal,
      });
      this.markers.push(marker);

      marker.addListener("click", () => {
        if (!this.show) {
          this.show = true;
          infowindow.open({
            anchor: this.markers[i],
            map: this.map,
          });
        }
      });

    }

    var event: number = 2

    google.maps.event.addListener(this.map, "idle", () => {

      if (this.map.getBounds() != null) {
        event--
        let bounds = this.map.getBounds();
        var rectangle = new google.maps.Rectangle({
          strokeOpacity: 0.0,
          fillOpacity: 0.0,
          map: this.map,
          bounds: {
            north: bounds.getNorthEast().lat(),
            south: bounds.getSouthWest().lat(),
            east: bounds.getNorthEast().lng(),
            west: bounds.getSouthWest().lng(),
          },
        });

        this.maxLat = Math.max(rectangle.getBounds().getNorthEast().lat(), rectangle.getBounds().getSouthWest().lat())
        this.minLat = Math.min(rectangle.getBounds().getNorthEast().lat(), rectangle.getBounds().getSouthWest().lat())
        this.maxLon = Math.max(rectangle.getBounds().getNorthEast().lng(), rectangle.getBounds().getSouthWest().lng())
        this.minLon = Math.min(rectangle.getBounds().getNorthEast().lng(), rectangle.getBounds().getSouthWest().lng())

        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><defs><style>.fa-secondary{opacity:.4}</style></defs><path class="fa-primary" d="M256 160a96 96 0 1 0 0 192 96 96 0 1 0 0-192z"/><path class="fa-secondary" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>

        this.markers.forEach(element => {
          if ((checkMarker(rectangle, element.getPosition()) === true)) {
            element.setVisible(true);
          } else {
            element.setVisible(false);
          }
        });
        if (event == 0) {
          // event=4 
          this.filterService.setLocation({ id: 8, type: 'location', minLat: this.minLat, maxLat: this.maxLat, minLon: this.minLon, maxLon: this.maxLon, zoom: this.map.getZoom(), lat: this.map.getCenter().lat, lng: this.map.getCenter().lng });
        }
      }
    });

    const renderer = {
      render: ({ count, position }) =>
        new google.maps.Marker({

          title: String(count) + " Loks",
          position,
          icon: this.iconBig,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
    };

    new MarkerClusterer({
      map: this.map,
      markers: this.markers,
      renderer,
    });

  }

  jumpMarker(latitude, longitude) {
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.iconNormal,
    });
    marker.setMap(this.map)
    marker.setAnimation(google.maps.Animation.BOUNCE);
    setTimeout(function () { marker.setAnimation(); }, 800);
  }


  removeMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  customIcon(opts) {
    return Object.assign({
      path: 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z',
      fillColor: '#34495e',
      fillOpacity: 10,
      strokeWeight: 0.1,
      scale: 0.050,
    }, opts);
  }

  markerIcon(opts) {
    return Object.assign({
      path: 'M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z',
      // path: 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z',
      fillColor: '#34495e',
      fillOpacity: 5,
      strokeWeight: 0.1,
      scale: 0.050,
    }, opts);
  }

  selectLocationSearch(option: any) {
    this.loader.load().then((google) => {
      const map = new google.maps.Map(
        document.getElementById("maplock") as HTMLElement,
        {
          center: {
            lat: 39.5854434, lng: -4.4250326
          },
          zoom: 6,
          mapTypeId: "roadmap",
          streetViewControl: false,
        }
      );
      const bounds = new google.maps.LatLngBounds();
      let markers: google.maps.Marker[] = [];
      var icon = this.iconNormal;

      map.setCenter(option.geometry.location);
      markers = [];
      markers.push(
        new google.maps.Marker({
          map,
          icon: icon,
          title: option.name,
          position: option.geometry.location,
        })
      );
      if (option.geometry.viewport) {
        bounds.union(option.geometry.viewport);
      } else {
        bounds.extend(option.geometry.location);
      }
      map.fitBounds(bounds);
    });
  }

  searchLocation() {
    // This example adds a search box to a map, using the Google Place Autocomplete
    // feature. People can enter geographical searches. The search box will return 
    // a pick list containing a mix of places and predicted search terms.

    // This example requires the Places library. Include the libraries=places
    // parameter when you first load the API. For example:
    // <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">

    // function initAutocomplete() {
    this.loader.load().then((google) => {
      const map = new google.maps.Map(
        document.getElementById("maplock") as HTMLElement,
        {
          center: {
            lat: 39.5854434, lng: -4.4250326
          },
          zoom: 6,
          mapTypeId: "roadmap",
          streetViewControl: false,
        }
      );
      const bounds = new google.maps.LatLngBounds();
      let markers: google.maps.Marker[] = [];

      if (this.locationSave) {
        var request = {
          query: this.locationSave,
          fields: ['name', 'geometry'],
        };
        var icon = this.iconNormal;

        var service = new google.maps.places.PlacesService(map);

        service.findPlaceFromQuery(request, function (results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            map.setCenter(results[0].geometry.location);
            markers = [];
            markers.push(
              new google.maps.Marker({
                map,
                icon: icon,
                title: results[0].name,
                position: results[0].geometry.location,
              })
            );
            if (results[0].geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(results[0].geometry.viewport);
            } else {
              bounds.extend(results[0].geometry.location);
            }
            map.fitBounds(bounds);
          }
        });
      }


      // Create the search box and link it to the UI element.
      const input = document.getElementById("searchMap") as HTMLInputElement;
      const searchBox = new google.maps.places.Autocomplete(input);
      // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const place = searchBox.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map,
            icon: this.iconNormal,
            title: place.name,
            position: place.geometry.location,
          })
        );

        this.mapsObject.next(place);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        map.fitBounds(bounds);
      });
    });
  }

  searchLocation2() {

    this.loader.load().then((google) => {

      const map = new google.maps.Map(
        document.getElementById("maplock") as HTMLElement,
        {
          center: {
            lat: 39.5854434, lng: -4.4250326
          },
          zoom: 6,
          mapTypeId: "roadmap",
          streetViewControl: false,
        }
      );

      const input = document.getElementById("searchMap") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      let markers: google.maps.Marker[] = [];

      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            if (AppSettings.DEBUG) {
              console.log("El sitio no contiene geometría");
            }
            return;
          }

          const icon = {
            url: place.icon as string,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          markers.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {

            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);


        const geocoder = new google.maps.Geocoder();
        const infowindow = new google.maps.InfoWindow();
        var self = this;
        google.maps.event.addListener(map, 'click', function (e) {
          markers.forEach((marker) => {
            marker.setMap(null);

          });

          markers = [];
          var marker = new google.maps.Marker({
            position: e.latLng,
            map: map,
          })
          markers.push(marker);
          map.panTo(e.latLng);
          var coordenates = markers[0].getPosition()
          geocoder
            .geocode({ location: coordenates })
            .then((response) => {

              if (response.results[0]) {

                infowindow.setContent(response.results[0].formatted_address);
                infowindow.open(map, markers[0]);
                self.mapsObject.next(response.results[0])
              }
            })

        });

      });


    })
  }

  codingAddress(geocoder, map, address) {

    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === 'OK') {

        map.setCenter(results[0].geometry.location);

      } else {
        if (AppSettings.DEBUG) {
          console.log('Error ' + status);
        }
      }
    });
  }
}


function checkMarker(rectangle, marker) {

  var insideRectangle = false;
  if (rectangle && rectangle.getBounds && marker && marker) {
    insideRectangle = rectangle.getBounds().contains(marker);
  }
  return insideRectangle;
}

