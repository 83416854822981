<div class="orders-panel">
    <div class="btns-filters">
        <div class="buscador-order container-form d-flex align-items-center borderInput">
            <i class="fal fa-search search-icon pointer-in" (click)="searchOrder(searchInput.value)"></i>
            <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar un pedido"
                [(ngModel)]="searchText" (keyup.enter)="searchOrder(searchInput.value)">
        </div>
        <div class="info-search m-0">
            <i class="fal fa-circle-info icon-primary"></i>
            <p class="m-0">Busca por número de pedido o correo electrónico.</p>
        </div>
    </div>
    <p *ngIf="loaded && orders?.length <= 0" style="text-align: center;">No hay pedidos disponibles</p>

    <div *ngIf="loadingPage" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>

    <div class="table-orders table-panel" *ngIf="loaded && orders?.length > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef><b>Entradas</b></th>
                <td mat-cell *matCellDef="let order">
                    <div class="d-flex" style="gap: 30px;">
                        <!-- <i class="icon-option fal fa-ellipsis-vertical pointer-in"></i> -->
                        <i class="icon-option fal fa-print icon-primary pointer-in"
                            (click)="downloadOrder(order.channel,order.id)"></i>
                        <i class="icon-option fal fa-ticket icon-primary pointer-in"
                            (click)="downloadTicketOrder(order.channel,order.id)"></i>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef><b>ID Pedido</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(event.id,event.titulo)">
                    {{order.num_odoo}}
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef><b>Nombre y apellidos</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(event.id,event.titulo)">
                    {{order.name_citylok||''}}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef><b>Correo electrónico</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(event.id,event.titulo)">
                    {{order.email_citylok||''}}
                </td>
            </ng-container>
            <ng-container matColumnDef="canal">
                <th mat-header-cell *matHeaderCellDef><b>Canal</b></th>
                <td mat-cell *matCellDef="let order" (click)="openEvent(event.id,event.titulo)">
                    {{order.channel}}
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef><b>Fecha y hora trans.</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(event.id,event.titulo)">
                    {{order.created_at | date: 'dd/MM/yyyy HH:mm'}} h
                </td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Precio</b></th>
                <td mat-cell *matCellDef="let order" (click)="openOrder(event.id,event.titulo)">
                    {{order.total_order | number:'1.2-2'}} €
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="pagination-component">
            <div class="d-flex align-items-center pagination-panel pagination-table">
                <button class="btn-prev" type="button" [class.disabled]="page == 1" (click)="prevPage()"><i
                        class="fas fa-chevron-left"></i></button>
                <button class="page active">
                    {{page}}/{{totalPages}}</button>
                <button class="btn-next" type="button" [class.disabled]="page == totalPages" (click)="nextPag()"><i
                        class="fas fa-chevron-right"></i></button>
            </div>
        </div>
    </div>
</div>