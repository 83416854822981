<div class="template-admin-card">
  <div w-100 class="row align-items-center justify-content-between title-table">
      <div class="col col-lg-9 col-md-9 col-sm-12 col-xs-12 div-buttons" style="text-align: end">
          <button class="mat-raised-button" [routerLink]="['/admin/eventos/new-evento']">
            <i class="new far fa-plus"></i>Nuevo evento
          </button>
          <div class="btn-filter d-flex align-items-center">
            <i class="far fa-search icon-primary"></i>           
            <input class="input-search" placeholder='Buscar' #input (keyup)="applySearch($event)">
          </div>
      </div>
  </div>
  <div class="platform-card">
    <mat-card class="mt-1">
      <mat-card-content class="platform-card-description">
        <div class="container-table table-default">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="imagen">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let event"><img fallimg class="imgEvent" src="{{event.imageurl}}"></td>
            </ng-container>
            <ng-container matColumnDef="titulo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Título</b></th>
              <td mat-cell *matCellDef="let event"><b><a (click)="openEvent(event.id,event.titulo)"> {{event.titulo}}</a></b></td>
            </ng-container>
            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha Inicio</b></th>
              <td mat-cell *matCellDef="let event">{{event.datesRange?.start | date:'dd MMM y' | titlecase }}</td>
            </ng-container>
            <ng-container matColumnDef="fechaFin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Fecha Fin</b></th>
              <td mat-cell *matCellDef="let event">{{event.datesRange?.end | date:'dd MMM y' | titlecase }}</td>
            </ng-container>
            <ng-container matColumnDef="ciudad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Ciudad</b></th>
              <td mat-cell *matCellDef="let event">{{event?.ciudad?.nombre}}</td>
            </ng-container>
            <ng-container matColumnDef="localizacion">
              <th mat-header-cell *matHeaderCellDef><b>Ubicación</b></th>
              <td mat-cell *matCellDef="let event">
                <span (click)="openEvent(event.id,event.titulo, 'map')">
                  <i class="fa-duotone fa-map-location-dot pointer-in fa-lg" style="--fa-primary-color: #ff4d80; --fa-secondary-color: #532d37; --fa-secondary-opacity: 0.2;"></i>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="precio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Desde</b></th>
              <td mat-cell *matCellDef="let event">{{event.preciodesde != 0 ? (event.preciodesde | number:'1.2-2')+' €' : 'Gratuito'}}</td>
            </ng-container>
            <ng-container matColumnDef="external">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Externo</b></th>
              <td mat-cell *matCellDef="let event">{{event.external}} &euro;</td>
            </ng-container>
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let event">
                <button mat-button [matMenuTriggerFor]="menuOptions" class="menu-table">
                  <i class="fas fa-ellipsis-v blue-icon"></i>
                </button>
                <mat-menu class="menu-options" #menuOptions="matMenu">
                    <button mat-menu-item (click)="openEvent(event.id,event.titulo)" class="viewIcon"><i class="far fa-eye"></i> Ver</button>
                    <!-- <button mat-menu-item [routerLink]="['/admin/eventos/view-evento',event.id]" class="viewIcon"><i class="far fa-eye"></i> Ver</button> -->
                    <button mat-menu-item class="editIcon" [routerLink]="['/admin/eventos/new-evento',event.id]"><i class="fal fa-edit"></i> Editar</button>
                    <button mat-menu-item class="deleteIcon" (click)="openDelete(event.id)"><i class="far fa-trash-alt"></i> Eliminar</button>
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <app-pagination-page [totalItems]="numItems" (items)="itemsChange($event)" [numPages]="totalPages" (page)="pageChange($event)"></app-pagination-page>
      </mat-card-content>
    </mat-card>
  </div>
</div>
