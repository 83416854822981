import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeguidoresService {

  constructor(private http: HttpClient) { }

  getSiguiendo(items: any = 12, page: any = 1) {
    const url = `${ AppSettings.API_URI }/siguiendo?items=${items}&page=${page}`;
    return this.http.get<any>( url ).pipe(
      map(data => {
        return data;
      }
    ));
  }

  getSitiosFav(items: any = 12, page: any = 1) {
    const url = `${ AppSettings.API_URI }/siguiendo/sitios?items=${items}&page=${page}`;
    return this.http.get<any>( url ).pipe(
      map(data => {
        return data;
      }
    ));
  }

  postSiguiendo(userId: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/siguiendo`;
    return this.http.post<any>(url, {usuario: userId}, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        }
        return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  deleteSiguiendo(usuarioId: number) {
    const url = `${ AppSettings.API_URI }/siguiendo/${ usuarioId }`;
    return this.http.delete<any>( url ).pipe(
      map(data => {
        if(data) {
          return of(true);
        }
        return of(false);
      }), catchError(err => {
        return of(false);
      })
    );
  }
}

