import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-seats-with-number',
  templateUrl: './edit-seats-with-number.component.html',
  styleUrls: ['./edit-seats-with-number.component.scss']
})
export class EditSeatsWithNumberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
