import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { PlacesService } from 'src/app/services/places.service';

@Component({
  selector: 'app-venues-list',
  templateUrl: './venues-list.component.html',
  styleUrls: ['./venues-list.component.scss']
})
export class VenuesListComponent {

  business: any;
  venues: any[] = [];
  profile!: any;
  searchText!: string;
  now: Date = new Date();

  displayedColumns: string[] = ['actions', 'name', 'description', 'location', 'capacity'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;
  totalPages = 1;
  items = 0;

  urlImages: string = AppSettings.MEDIA_URI + '/images/venues/';
  @Output() changeTag: EventEmitter<any> = new EventEmitter<any>();
  loaded: boolean = false;
  loadingPage: boolean = true;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  private searchTerms = new Subject<string>();

  constructor(private panelService: PanelAdminService, private placeService: PlacesService, private apiBusiness: ApiBusinessService,
    private dialog: MatDialog, private router: Router, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.now.setHours(0);
    this.now.setMinutes(0);
    this.apiBusiness.changed.subscribe({
      next: (value) => {
        if(value && value != 'place' && !value.places) {
          this.openVenue(value);
        }
      }
    })
  }

  ngAfterViewInit() {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.getData();
    this.searchTerms.pipe(
      debounceTime(500), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.updateVenues();
    });
  }

  getData() {
    this.placeService.getListFilterPlaces(this.business?.id, this.page, this.searchText, this.sorted).subscribe({
      next: (res) => {
        this.venues = this.venues.concat(res);

        if (this.venues.length <= 0 || this.venues.length < 20) {
          this.finishScroll = true;
        }
        this.dataSource = new MatTableDataSource(this.venues);
        this.dataSource.sort = this.sort;

        this.loaded = true;
        this.loadingPage = false;
      }
    });
  }

  updateVenues() {
    this.page = 1;
    this.loadingPage = true;
    this.finishScroll = false;
    this.venues = [];
    this.getData();
  }

  searchVenue(event) {
    this.searchTerms.next(event);
  }

  openVenue(id: number) {
    this.router.navigate(['/panel-control/config/venues/info-venue', id]);
  }

  // Sort
  sortData(sort: Sort) {
    if (this.venues.length > 1) {
      const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
      if (sort.direction) {
        this.sorted = { sortedBy: sort.active, sort: isAsc };
      } else {
        this.sorted = null;
      }
      this.updateVenues();
    }
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingPage) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingPage = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
        // }
      } else {
        this.finishScroll = true;
      }
    }
  }

  openCreateVenue() {
    this.router.navigate(['/panel-control/config/create-venue']);
  }

}
