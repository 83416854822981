<header>
  <app-header-admin></app-header-admin>
</header>
<main class="main">
  <mat-drawer-container autosize class="container-admin">
    <mat-drawer #drawer class="admin-sidenav" mode="side" opened style="width:220px;">
      <mat-sidenav mode="side" opened class="bigC container">
        <button (click)="drawer.toggle()" class="menu">
          <span><i class="fa-light fa-angles-left fa-lg m-0"></i></span>
        </button>
        <button mat-tab active [routerLink]="['/admin/eventos']" routerLinkActive="menu-active">
          <i class="fa-light fa-calendar-days"></i>
          <span>Eventos</span>
        </button>
        <button mat-tab [routerLink]="['/admin/categorias']" routerLinkActive="menu-active">
          <i class="fa-light fa-sparkles"></i>
          <span>Categorías</span>
        </button>
        <button mat-tab [routerLink]="['/admin/usuarios']" routerLinkActive="menu-active">
          <i class="fa-light fa-users"></i>
          <span>Usuarios</span>
        </button>
        <hr>
        <!-- <button mat-tab [routerLink]="['/admin/config']" routerLinkActive="menu-active" (click)="drawer.toggle()">
        <i class="fa-light fa-gear"></i>
        <span>Configuración</span>
      </button>
      <button mat-tab [routerLink]="['/admin/config/selection']" routerLinkActive="menu-active" (click)="drawer.toggle()">
        <span>Seleccion</span>
      </button>
      <button mat-tab [routerLink]="['/admin/config/banner']" routerLinkActive="menu-active" (click)="drawer.toggle()">
        <span>Banners</span>
      </button> -->
      </mat-sidenav>
    </mat-drawer>
    <div class="example-sidenav-content">
      <mat-sidenav-container>
        <mat-sidenav-content>
          <button (click)="drawer.toggle()" *ngIf="!drawer.opened" class="menu-admin-sidenav">
            <span><i class="fa-light fa-angles-right fa-lg"></i></span>
          </button>
          <router-outlet class="ml-2"></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </mat-drawer-container>

</main>