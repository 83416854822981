import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PanelAdminService } from 'src/app/services/panel-admin.service';

@Component({
  selector: 'app-detail-order-panel',
  templateUrl: './detail-order-panel.component.html',
  styleUrls: ['./detail-order-panel.component.scss']
})
export class DetailOrderPanelComponent implements OnInit {

  orders: any[] = [];

  constructor(private panelService: PanelAdminService) { }

  ngOnInit(): void {
    
  }

}
