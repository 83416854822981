import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfinityScrollComponent } from './infinity-scroll/infinity-scroll.component';
import { CardsModule } from '../bloques/cards.module';
import { LottieModule } from 'ngx-lottie';



@NgModule({
  declarations: [InfinityScrollComponent],
  imports: [
    CommonModule,
    CardsModule,
    LottieModule
  ],
  exports: [
    InfinityScrollComponent
  ]
})
export class SharedModule { }
