<div class="pagination-posts d-flex justify-content-center align-items-center">
    <div *ngIf="currentPage != 1 && currentPage > 2 && totalPages > 4" (click)="setPage(1)"><i class="fa-light fa-chevrons-left"></i></div>
    <div *ngIf="currentPage != 1">
        <i class="fa-light fa-chevron-left arrow" (click)="prevPage()"></i>
        <!-- <span>...</span> -->
    </div>
    <div class="options-pagination d-flex">
        <span class="page" [class.current-page]="currentPage == n" (click)="setPage(n)"
        *ngFor="let n of numbersPages; let i = index">{{ n }}</span>
    </div>
    <div *ngIf="currentPage != totalPages">
        <!-- <span>...</span> -->
        <i class="fa-light fa-chevron-right arrow" (click)="nextPage()"></i>
    </div>
    <div class="arrow" *ngIf="numbersPages[numbersPages.length-1] !== totalPages" (click)="setPage(totalPages)"><i class="fa-light fa-chevrons-right"></i></div>
</div>