<div class="info-user-page">
    <div class="user-container">
        <div class="title-user-info d-flex justify-content-between align-items-center">
            <p class="m-0">Mi perfil</p>
            <div class="btn-create btn-save m-0" *ngIf="changedUser" (click)="saveProfile()">
                <p class="m-0">Guardar</p>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="d-flex">
            <div class="col-8">
                <div class="info-personal d-flex info-padding">
                    <div class="col-4">
                        <div class="d-flex align-items-center" style="gap: 10px;">
                            <p class="m-0 title-section">Cuenta</p>
                            <!-- <i class="fal fa-pen pointer-in"></i> -->
                        </div>
                        <p class="info-text">{{user?.email}}</p>
                    </div>
                    <div class="col-4">
                        <div class="d-flex align-items-center" style="gap: 10px;" (click)="openChangeName()">
                            <p class="m-0 title-section">Nombre y apellidos</p>
                            <i class="fal fa-pen pointer-in"></i>
                        </div>
                        <p class="info-text">{{user?.nombre}}</p>
                    </div>
                </div>
                <div class="info-funtions info-padding">
                    <div class="d-flex align-items-center" style="gap: 10px;" (click)="openChangePassword()">
                        <p class="m-0 title-section">Contraseña</p>
                        <i class="fal fa-pen pointer-in"></i>
                    </div>
                    <div>
                        <span class="info-text">xxxxxxxxxxx</span>
                    </div>
                </div>
                <div class="info-funtions info-padding">
                    <p class="title-section">Funciones</p>
                    <div class="checks-column-two">
                        <div *ngFor="let function of rolesUser">
                            <span class="info-text">{{function.name}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="info-active info-padding">
                    <p class="title-section">Permisos</p>
                    <div class="checks-column-two">
                        <div *ngFor="let addition of permissionExtra">
                            <p class="m-0">{{addition.name}}</p>
                            <mat-checkbox color="primary">{{addition.name}}</mat-checkbox>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="placesUser?.length > 0">
                    <div class="places info-padding">
                        <p class="title-section">Espacios</p>
                        <span class="info-text" *ngFor="let place of placesUser; let i = index">
                            {{place.name}}<span *ngIf="i!=placesUser.length-1">, </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-4 col-image-user">
                <p class="title-image">IMAGEN</p>
                <div class="container-image" (click)="file.click()">
                    <div class="not-image-user">
                        <i class="fa-thin fa-image" *ngIf="!imgControl.value"></i>
                        <img [src]="imgControl.value" alt="Img user" *ngIf="imgControl.value" />
                    </div>
                    <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
                    <div class="circle-add-image">
                        <i class="fa-light fa-plus"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>