<div class="header-template-admin"><i class="icon-page far fa-times" (click)="openDialog()"></i><span>Añadir categorías</span></div>
<div class="template-admin-card">

  <div w-100 class="row align-items-center justify-content-between title-table">
      <h2 class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 title-h2">Categorías</h2>
      <div class="col col-lg-9 col-md-9 col-sm-12 col-xs-12 div-buttons" style="text-align: end">
      </div>
  </div>
  <div class="platform-card">

    <mat-card appearance="outlined" class="mt-1">
      <mat-card-content class="platform-card-description" class="content-table">

        <div w-100 class="d-flex align-items-center justify-content-between title-table">
          <h4>Categorías sin mostrar</h4>
        </div>

        <div class="container-table">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z28">
            <ng-container matColumnDef="icono">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let cat"><i [class]="cat.icon"></i></td>
            </ng-container>
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef><b>Nombre</b></th>
              <td mat-cell *matCellDef="let cat">{{cat.nombre}}</td>
            </ng-container>
            <ng-container matColumnDef="slug">
              <th mat-header-cell *matHeaderCellDef><b>Slug</b></th>
              <td mat-cell *matCellDef="let cat">{{cat.slug}}</td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let cat">
                <button mat-button class="add" (click)="addCat(cat.id)">
                  <i class="fas fa-solid fa-plus"></i>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

      </mat-card-content>
    </mat-card>

  </div>

</div>
