import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChatbotService } from 'src/app/services/chatbot.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {

  message: any = new FormControl('');
  conversation: any[] = [];
  loading: boolean = true;
  idChat!: number;
  idUser!: number;

  constructor(private chatService: ChatbotService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.initChat();
  }

  initChat() {
    const idChatbot = localStorage.getItem('id_chatbot');
    // Coger id de chatbot localstorage
    this.chatService.getChatbot(idChatbot).subscribe((res: any) => {
      this.idChat = res.id;
      localStorage.setItem('id_chatbot', String(this.idChat));
      res?.messages.forEach(element => {
        this.conversation.push({ type: element.type, content: element.body.content });
      });
      this.loading = false;
    });
    let user = this.auth.getProfile();
    if (user) {
      this.idUser = user.id;
    }
  }

  sendMessage() {
    if (this.message.value) {
      this.loading = true;
      const content = this.message.value;
      this.message.setValue('');
      this.conversation.push({ type: 'request', content: content });
      this.chatService.sendMessage(this.idChat, content, this.idUser).subscribe({
        next: (response: any) => {
          response?.messages.forEach(element => {
            this.conversation.push({ type: element.type, content: element.body.content });
          });
          this.goToBottom();
          this.loading = false;
        }, error: () => {
          this.conversation = [];
          this.initChat();
        }
      })
    }
  }

  goToBottom() {
    const element = document.getElementById('contenedorChat')
    var height = document.getElementById('contenedorChat').offsetHeight;
    element.scrollHeight;
  }

}
