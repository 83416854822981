<div class="container">
    <div class="container-register">
        <p class="h2 title-register">Regístrate para continuar</p>
        <app-warning *ngIf="message" [text]="message"></app-warning>

        <p class="info-all-fields">*Todos los campos son obligatorios</p>
        <div class="card-register">
            <app-register-card (msgError)="changeMsg($event)" (goLogin)="goToLogin()" (correctForm)="registeredUser($event)"></app-register-card>
        </div>
        <p class="info-publish"><i class="fa-light fa-lock"></i>Nunca publicaremos nada sin tu permiso</p>
    </div>
</div>
