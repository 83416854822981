<ul class="nav nav-pills mt-2 pagination-table" *ngIf="getPages().length > 0">
  <button class="nav-link" type="button" [class.disabled]="currentPage == 1" (click)="prevPage()"><i class="fas fa-chevron-left"></i></button>
  <button class="nav-link page active" (click)="setPage(i)" [class.page]="i !== currentPage" [class.active]=" i === currentPage">
      {{currentPage}}/{{numPages}}</button>

  <button class="nav-link" type="button" [class.disabled]="currentPage == numPages" (click)="nextPag()"><i class="fas fa-chevron-right"></i></button>

  <select class="nav-link" [formControl]="itemsByPage" (ngModelChange)="setItems()" *ngIf="totalItems">
      <option [value]="10">10</option>
      <option [disabled]="totalItems < 10" [value]="20">20</option>
      <option [disabled]="totalItems < 20" [value]="30">30</option>
      <option [disabled]="totalItems < 30" [value]="40">40</option>
  </select>
</ul>
