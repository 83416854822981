<div class="container-new-lok h-100">
    <!-- First Step - Category -->
    <div class="title-new-form d-flex justify-content-between align-items-center">
        <i class="fa-light fa-angle-left m-0 pointer-in" [class.invisible]="stepper.selectedIndex == 0"
            (click)="tabOptional ? tabOptionalChange(false) : stepper.previous()"></i>
        <p class="text-center m-0">{{ titleDialog | uppercase }}</p>
        <i class="fa-light fa-times m-0 pointer-in" (click)="closeDialog()"></i>
    </div>
    <div class="container-form">
        <mat-stepper #stepper (selectionChange)="selectStep($event)" linear="false">
            <!-- First Step - Category Selected -->
            <mat-step label="Selecciona una categoria">
                <div *ngIf="loadingCategories; else divCategorias"
                    class="w-100 h-100 d-flex align-items-center justify-content-center">
                    <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                        containerClass="loading-box"></ng-lottie>
                </div>
                <ng-template #divCategorias>
                    <div class="pointer-in" *ngFor="let categoria of categorias" [value]="categoria.id"
                        (click)="selectCategory(categoria)">
                        <div class="category-option" [class.category-select]="categorySelect == categoria">
                            <i class="fa-thin {{ categoria.icon }} icon-primary fa-lg"></i>
                            {{categoria.nombre}}
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <!-- <button class="btn-new-form w-100" matStepperNext>Siguiente</button> -->
                </ng-template>
            </mat-step>
            <!-- Second Step - General Attributes -->
            <mat-step #step [label]="labelDetail">
                <app-detail-lok [profile]="profile" [tabOptional]="tabOptional"
                    (statusFormChange)="statusFormChange($event)"
                    (optionalChange)="tabOptionalChange($event)"></app-detail-lok>
                <!-- <app-edit-lok [newLok]="true"></app-edit-lok> -->
            </mat-step>

            <!-- Four Step - Resume -->
            <mat-step label="Resumen del lok">
                <div class="w-100">
                    <mat-divider></mat-divider>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
    <button *ngIf="!tabOptional && stepper.selectedIndex != 1"
        [class.disabled-btn]="!isNextActivated(stepper.selectedIndex)" class="btn-new-form btn-save w-100"
        (click)="stepper.next()">Siguiente</button>
    <div class="d-flex" *ngIf="!tabOptional && stepper.selectedIndex == 1">
        <button class="btn-new-form btn-save btn-draft w-100"
            [class.disabled-btn]="creating" (click)="createLok(false)"><span class="d-none d-sm-inline">Guardar </span>Borrador</button>
        <button class="btn-new-form btn-save w-100"
            [class.disabled-btn]="creating" (click)="createLok(true)">Subir Lok</button>
    </div>
</div>