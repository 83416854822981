<div class="header-template-admin"><i class="icon-page far fa-times" (click)="openDialog()"></i><span>Datos del Evento</span></div>

<mat-divider></mat-divider>
<div class="template-new">
  <div style="display:inline-block;margin-right:10px">
  <h3 *ngIf="idE">Editar Evento</h3>
  <h3 *ngIf="!idE">Nuevo Evento</h3>
</div>
  <div style="display:inline-block; float: right;">
  <button class="w-20 mr-20 btn btn-cancel" type="button" (click)="openDialog()">Cancelar</button>
</div>
  <form [formGroup]="event" (ngSubmit)="submit()">
    <app-warning-admin *ngIf="message" [text]="message"></app-warning-admin>
    <mat-tab-group #tabGroup [selectedIndex]="selectedIndex">
      <mat-tab label="Datos Generales *">
        <div class="row row-eq-height">
          <div class="col-lg-4 col-md-4 mb-4 mt-4">
            <p>Imagen principal</p>
            <div *ngIf="filePath && filePath !== ''">
                <img fallimg (click)="file.click()" class="img-new img-principal" [class.errorfile]="errorfile" [src]="filePath" alt="Imagen Principal">
            </div>
            <div *ngIf="!filePath || filePath == ''" (click)="file.click()" class="main-img mb-2 mt-2 pointer-in" [class.error-image]="errorImage">
                <i class="fa-thin fa-plus m-0"></i>
            </div>
            <input id="file" hidden #file type="file" accept="image/*" (change)="onImageChange($event)">
            <mat-hint class="light-text">Recomendado: X x X px (Max: XMb)</mat-hint>
          </div>
          <div class="col-lg-8 col-md-8 mb-4 mt-4">
            <p>Imagenenes Extra</p>
            <div (click)="files.click()" class="main-img mb-2 mt-2 pointer-in" >
                <i class="fa-thin fa-plus m-0"></i>
            </div>
            <input id="file" hidden #files type="file" accept="image/*" (change)="extraImages($event)">
            <div *ngIf="!idE">
              <img fallimg *ngFor='let file of filesPath' style="width: 106px; height: 108px; object-fit: cover;object-position: center;"[src]="file.file" class="file">
            </div>
            <div *ngIf="idE && filesPath.length > 0">
              <img fallimg *ngFor='let file of filesPath' style="width: 106px; height: 108px;object-fit: cover;object-position: center;" src="{{ apiMedia }}/events/{{file.imagen}}" class="file" >
            </div>
          </div>
        </div>
        <div class="row col-xl-10 col-xxl-10 col-lg-12 mt-4">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 column-form">
            <div class="col-12">
              <p>Título</p>
              <mat-form-field class="w-100 mb-1" appearance="outline">
                <input matInput placeholder="Titulo *" formControlName="titulo">
              </mat-form-field>
              </div>
          </div>
          <div class="row row-eq-height">
          <div  class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
            <div class="col-12">
              <p>Ciudad</p>
              <mat-form-field class="w-100 mb-1" appearance="outline">
                  <mat-select  [value]="selected" placeholder="Elige la Ciudad" formControlName="ciudad" >
                      <mat-option *ngFor="let ciudades of cityOptions" [value]="ciudades.uri">
                          {{ciudades.nombre}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
          </div>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 column-form">
            <div class="col-12 aux">
              <p>Hashtags</p>
              <mat-form-field class="w-100 mb-1" appearance="outline">
                <input matInput placeholder="Hashtag1,Hashtag2,Hashtag3,etc" formControlName="hashtags">
              </mat-form-field>
            </div>
          </div>

        <p>Categoria</p>
        <mat-form-field class="w-100 mb-1" appearance="outline">
            <mat-select  placeholder="Elige las categorias" formControlName="categoria">
                <mat-option *ngFor="let categoria of listaCategorias" [value]="categoria.uri" >
                    {{categorias.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 column-form">
          <div style="padding-top: 40px;" class="col-12">
            <mat-checkbox style="padding-left: 15px;" formControlName="activo" color="primary">Activo</mat-checkbox>
            <mat-checkbox  style="padding-left: 15px;"  [(ngModel)]="informativo"  formControlName="informativo" color="primary">Informativo</mat-checkbox>
            <mat-checkbox   style="padding-left: 15px;" *ngIf="idE && ext === true" formControlName="external" color="primary"  [disabled]="true">Externo</mat-checkbox>
          </div>
        </div>
        </div>
      </mat-tab>

      <mat-tab label="Fechas / Horarios *">
        <p><button [disabled]="!enableDatesButton" type="button" class="w-20 m-0 btn btn-primary btn-ok" (click)="addFechas()"><i class="icon-input far fa-plus fa-md" style="color: white;"></i>Añadir Fechas/horarios</button></p>
        <div *ngFor="let fechaPrecio of fechasPrecios; let i = index; "> 
            <mat-form-field class="w-30 m-0" appearance="outline">
                  <input matInput placeholder="Fecha Inicio MM/DD/YYYY" [matDatepicker]="picker1" [(ngModel)]="fechaPrecio.fechainicio" [ngModelOptions]="{standalone: true}">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                
            </mat-form-field>
            <mat-form-field style="padding-left: 10px;"class="w-30 m-0" appearance="outline">
              <input matInput placeholder="Fecha Fin MM/DD/YYYY"  [matDatepicker]="picker2" [(ngModel)]="fechaPrecio.fechahasta" [ngModelOptions]="{standalone: true}">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <mat-form-field  style="padding-left: 10px;" class="w-30 m-0" appearance="outline">
              <input matInput class="hi" placeholder="HoraInicio" type="time"  onfocus="this.showPicker()" [(ngModel)]="fechaPrecio.horainicio" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field style="padding-left: 10px;"class="w-30 m-0" appearance="outline">
              <input matInput class="hf" placeholder="HoraFin" type="time"  onfocus="this.showPicker()" [(ngModel)]="fechaPrecio.horafin" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <i *ngIf="saved ==i"  class="icon-input far fa-save fa-1x" (click)="updateFechasHorarios(i,fechaPrecio.fechainicio,fechaPrecio.fechahasta,fechaPrecio.horainicio,fechaPrecio.horafin)" style="color:#FF4D80; padding-left: 20px;"></i>
            <i *ngIf="saved != i" class="icon-input fas fa-check fa-1x"  style="color: green; padding-left: 20px;"></i>
            <i *ngIf="saved != i" class="icon-input fas fa-trash-alt fa-1x"  (click)="deleteFechas(i)" style="color: red; padding-left: 20px;"></i>
        </div>
      </mat-tab>

      <mat-tab *ngIf="!informativo" label="Precios *">
        <div style="padding-top: 20px;" *ngFor="let fechaPrecio of fechasPrecios; let i = index; "> 
          <mat-form-field class="w-30 m-0" appearance="outline">
                <input matInput placeholder="Fecha Inicio MM/DD/YYYY" [matDatepicker]="picker1" [(ngModel)]="fechaPrecio.fechainicio" [ngModelOptions]="{standalone: true}"[readonly]="true">
                <mat-datepicker-toggle [disabled]="true" matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="padding-left: 10px;"class="w-30 m-0" appearance="outline">
            <input matInput placeholder="Fecha Fin MM/DD/YYYY"  [matDatepicker]="picker2" [(ngModel)]="fechaPrecio.fechahasta" [ngModelOptions]="{standalone: true}"[readonly]="true">
            <mat-datepicker-toggle [disabled]="true" matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="padding-left: 10px;" class="w-30 m-0" appearance="outline">
            <input matInput class="hi" placeholder="HoraInicio" type="time"   [(ngModel)]="fechaPrecio.horainicio" [ngModelOptions]="{standalone: true}"[readonly]="true">
          </mat-form-field>
          <mat-form-field style="padding-left: 10px;" class="w-30 m-0" appearance="outline">
            <input matInput class="hf" placeholder="HoraFin" type="time"   [(ngModel)]="fechaPrecio.horafin" [ngModelOptions]="{standalone: true}"[readonly]="true">
          </mat-form-field>
          <mat-form-field style="padding-left: 10px;" class="w-30 m-0" appearance="outline">
            <input matInput class="hi" placeholder="Precio" type="number"  [(ngModel)]="fechaPrecio.precio" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          <i *ngIf="savedPrice[i]?.value == i" class="icon-input far fa-save fa-1x" (click)="updatePrecio(i,fechaPrecio.precio)" style="color:#FF4D80;padding-left: 20px;"></i>
          <i *ngIf="savedPrice[i]?.value != i" class="icon-input fas fa-check fa-1x"  style="color: green; padding-left: 20px;"></i>
        </div>
      </mat-tab>

      <mat-tab label="Descripción *">
        <div class="btn-fixed-description">
          <div class="description-container">
            <div class="d-flex justify-content-between align-items-center desc-header">
                <p class="m-0">Descripción*</p>
                <span class="count-number">{{ 2000 - textArea.length }}</span>
            </div>
            <mat-divider></mat-divider>
            <angular-editor #edit id="1" [(ngModel)]="textArea" [config]="editorConfig" formControlName="descripcion">
                <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                  <ae-toolbar-set class="button-styles-text" tabindex="">
                    <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" [enableSearch]="false" [showPreview]="false" (emojiSelect)="executeCommandFn('insertHtml', addEmoji($event))"></emoji-mart>
                    <button class="btn-style btn-bold" (click)="executeCommandFn('bold')"><strong>N</strong></button>
                    <button class="btn-style btn-italic" (click)="executeCommandFn('italic')"><i>K</i></button>
                    <button class="btn-style btn-underline" (click)="executeCommandFn('underline')"><u>S</u></button>
                    <button class="btn-emoji pointer-in" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;"><i class="fa-light fa-face-smile"></i></button>
                  </ae-toolbar-set>
                </ng-template>
            </angular-editor>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Localización *">
        <div class="row col-xl-10 col-xxl-10 col-lg-12 mt-4">
          <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12 column-form mt-4">
            <div class="col-12">
              <p>Dirección</p>
              <mat-form-field class="w-100 mb-1" appearance="outline">
                <input matInput placeholder="Dirección completa Ejemplo:Gran Via de les Corts Catalanes 668, Barcelona" formControlName="localizacion">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div *ngIf="selectedIndex !=4;else guardar" style="float:right;width:170px;">
        <div style="float:left;width:80px;">
          <button type="button"  *ngIf="selectedIndex !=0" class="w-20 m-0 btn btn-primary btn-ok" (click)="previousStep()">
            Anterior
          </button>
        </div>
        <div  style="float:right;width:80px;">
            <button [disabled]="!enableAdd" type="button" class="w-20 m-0 btn btn-primary btn-ok" (click)="nextStep()">
              Siguiente  
            </button>
        </div>
    </div>
    
    <ng-template  #guardar>
       <div style="float:right;float:right;width:170px;">
        <div style="float:left;width:80px;">
          <button type="button" class="w-20 m-0 btn btn-primary btn-ok" (click)="previousStep()">
            Anterior
          </button>
        </div>
        <div  style="float:right;width:80px;">
          <button class="w-20 m-0 btn btn-primary btn-ok" type="submit">Guardar</button>
        </div>
      </div>
    </ng-template>
  </form>
</div>
