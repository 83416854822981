import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-info-loks',
  templateUrl: './info-loks.component.html',
  styleUrls: ['./info-loks.component.scss']
})
export class InfoLoksComponent implements OnInit {

  @Input() divHtml!: HTMLDivElement;

  constructor() { }

  ngOnInit(): void {
    if(this.divHtml) {
      var boxInfo = document.getElementById("contentInfo");
      boxInfo.innerHTML = '';
      boxInfo.innerHTML = (this.divHtml.innerHTML)
    }
    
  }

}
