import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DeletesComponent } from '../../shared/deletes/deletes.component';
import { BannersService } from '../../../../services/banners.service';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-config-banner',
  templateUrl: './config-banner.component.html',
  styleUrls: ['./config-banner.component.scss']
})
export class ConfigBannerComponent implements OnInit {

  apiMedia: string = AppSettings.MEDIA_URI;

  displayedColumns: string[] = ['imagen', 'titulo', 'isRight','showhome','options'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  idCat;

  constructor(private bannersService: BannersService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.bannersService.getBanners().subscribe(b => this.dataSource.data = b['hydra:member']);
  }

  // Delete
  openDelete(bannerId: number) {
    const dialogRef = this.dialog.open(DeletesComponent, {

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCat(bannerId);
      }
    });
  }

  deleteCat(bannerId: number){
    this.bannersService.deleteBanner(bannerId).subscribe(data => {
        this.getData();
    })
  }

}
