import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pagination-page',
  templateUrl: './pagination-page.component.html',
  styleUrls: ['./pagination-page.component.scss']
})
export class PaginationPageComponent implements OnInit {

  @Output() page: EventEmitter<number> = new EventEmitter();
  @Output() items: EventEmitter<number> = new EventEmitter();

  currentPage: number = 1;
  @Input() numPages: number = 1;
  @Input() totalItems: number = 0;

  pageSelect = new FormControl(1);
  itemsByPage = new FormControl(10);

  constructor() { }

  ngOnInit(): void {
  }

  setPage() {
    var page = this.pageSelect.value;
    this.currentPage = page;
    this.page.emit(page);
  }

  setItems() {
    var items = this.itemsByPage.value;
    this.items.emit(items);
  }

  prevPage() {
    if(this.currentPage > 1) {
      this.page.emit(this.currentPage-1);
      this.currentPage--;
    }
  }

  nextPag() {
    if(this.currentPage < this.numPages) {
      this.page.emit(this.currentPage+1);
      this.currentPage++;
    }
  }

  getPages(): Array<any> {
    if(this.currentPage > this.numPages) {
      this.prevPage();
    }
    return new Array(this.numPages);
  }

}
