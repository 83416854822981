import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-ticket',
  templateUrl: './info-ticket.component.html',
  styleUrls: ['./info-ticket.component.scss']
})
export class InfoTicketComponent {

  idTicket!: string;
  buyTickets: any[] = [];

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<InfoTicketComponent>) { }

  ngOnInit(): void {
    this.idTicket = this.data.idTicket;
    this.buyTickets = this.data.buyTickets;
  }
}
