import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-input-hour',
  templateUrl: './input-hour.component.html',
  styleUrls: ['./input-hour.component.scss']
})
export class InputHourComponent implements OnInit {

  schedule: any;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<InputHourComponent>, private formBuilder: FormBuilder) {
    this.schedule = this.formBuilder.group({
      hour: ['20', [Validators.required, Validators.max(24)]],
      minutes: ['00', [Validators.required, Validators.max(59)]]
    })
  }

  ngOnInit(): void {
  }

  saveHour() {
    if(this.schedule.valid) {
      this.dialogRef.close(this.schedule.value);
    }
  }

}
