import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { BannersService } from '../../../../services/banners.service';
import { CategoriasService } from '../../../../services/categorias.service';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {

  selection: any[] = [];
  categoriasSel: any[] = [];

  constructor(private bannersService: BannersService, private categoriasService: CategoriasService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    //Categorias
    this.categoriasService.getAllCategories().subscribe(c => {
      this.categoriasSel = c.map(value => value.showhome)
    });
    //Bannners
    this.bannersService.getBanners().subscribe(b => {
      this.selection = b['hydra:member'].map(value => value.showhome);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selection, event.previousIndex, event.currentIndex);
  }

  delete(id){
    this.selection.forEach(s => {

        if(s.icon){
          if(s.id == id){
            var cat;
            this.categoriasService.getCategoriabyId(s.id).subscribe(c => {
              cat = c;
              cat.showhome = false;

              this.categoriasService.putCategoria(s.id,cat).subscribe();
            });
            var index = this.selection.indexOf(s)
            this.selection.splice(index,1)
          }
        }
        else if(!s.icon){
          if(s.id == id){
            var bann;

            this.bannersService.getBannnerById(s.id).subscribe(b => {
              bann = b;
              bann.showhome = false;

              this.bannersService.putBanner(s.id,bann).subscribe();
          });
            var index = this.selection.indexOf(s)
            this.selection.splice(index,1)
          }
        }

    })
  }

}
