<div class="form-new-lok map-new-lok">
    <!-- <div class="d-flex justify-content-end align-items-center" style="gap: 10px;">
        <mat-checkbox color="primary" [(ngModel)]="online"></mat-checkbox>
        <p class="m-0">Evento online</p>
    </div> -->
    <!-- Espacio -->
    <div class="d-flex align-items-center justify-content-between section-external">
        <p class="title-external">Asociar a un sitio</p>
        <mat-slide-toggle [(ngModel)]="activeSites" [disabled]="!profile.site"></mat-slide-toggle>
    </div>
    <div *ngIf="activeSites" class="p-2 m-2">
        <!-- Select Place -->
        <mat-form-field appearance="fill" class="filter-place">
            <mat-select #placeSelect panelClass="selectPlaces" placeholder="Seleccionar sitio asociado a este evento"
                [(ngModel)]="place" disableOptionCentering="false">
                <mat-option class="option-place" *ngFor="let itemPlace of placeList" [value]="itemPlace">
                    <div class="image-place">
                        <img (error)="'../../../../assets/img/image_default.png'" src="{{urlImagesVenues+itemPlace.id+'/'+itemPlace.image}}" [alt]="itemPlace.name">
                    </div>
                    <span class="name-place">{{itemPlace.name}}</span>
                </mat-option>
            </mat-select>
            <mat-select-trigger matSuffix style="vertical-align: middle;">
                <i class="fal fa-angle-down pointer-in"></i>
            </mat-select-trigger>
        </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="!activeSites">
        <!-- Online -->
        <div class="d-flex align-items-center justify-content-between section-external">
            <p class="title-external">Evento online</p>
            <mat-slide-toggle [(ngModel)]="online" [disabled]="!profile.site"></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div [class.disabledMap]="online" class="section-search-map">
        <form (submit)="onSelectTextPlace(search.value)">
            <mat-form-field appearence="fill" class="w-100 label-title pointer-in">
                <input id="searchMap" #search matInput class="pointer-in" type="text"
                    placeholder="Buscar localización..." [(ngModel)]="lugar" name="location" (input)="onSearchPlaces()"
                    [matAutocomplete]="auto">
                <mat-autocomplete (optionSelected)="onSelectPlace()" #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of searchResults" [value]="option" class="item-option-map">
                        <div class="item-option">
                            <i class="fa-solid fa-location-dot"></i>
                            <span class="">{{option.description}}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-divider class="divider-focused" style="margin-bottom: 10px;"></mat-divider>
            <div id="maplock"></div>
        </form>
    </div>
</div>

<button class="btn-new-form btn-save w-100" [disabled]="(!lugar && !online) || (activeSites && !place)" (click)="save()">
    GUARDAR <span *ngIf="!online">LOCALIZACIÓN</span>
</button>