<div class="card-ticket-totem">
    <!-- <div class="logo-city">
        <img fallimg src="assets/img/citylok_logo.png" alt="Citylok logo">
    </div> -->
    <p class="info-ticket">Ticket: {{idTicket}}</p>
    <div class="msg-info-ticket">
        <p class="m-0">
            <i class="fa-light fa-circle-info icon-primary" style="margin-right: 8px;"></i>Puedes hacer una fotografía de esta información si no quieres imprimir tus entradas.
        </p>
    </div>
    <div *ngFor="let buy of buyTickets" class="container-event">
        <div class="item-event">
            <div class="img-event">
                <img (error)="'../../../../assets/img/image_default.png'" class="img-card-event" [src]="buy.url_image" />
            </div>
            <div class="info-event">
                <p class="title-event m-0">{{buy.name_event}}</p>
                <div class="date-event pointer-in">
                    <i class="fa-light fa-calendar icon-primary"></i>
                    <span>{{(buy.date_event | date: 'dd MMM':'+0100' )}} | {{(buy.hour_event)}}</span>
                </div>
                <div class="ticket-event pointer-in">
                    <i class="fa-solid icon-green fa-ticket"></i>
                    <div class="d-grid">
                        <div>
                            <span>{{buy.ticket_type}}</span>
                        </div>
                        <span *ngIf="buy.position?.section">{{ buy.position.section }}
                            {{buy.position.showRow != "0" ? 'Fila:' + buy.position.row + ', ' : ''}}
                            {{'Butaca: ' + buy.position.seat}}</span>
                    </div>
                </div>
            </div>
            <div class="container-qr-code">
                <qrcode [qrdata]="buy.token" [allowEmptyString]="true" [cssClass]="'center'" [colorDark]="'#ff4d80'"
                    [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'M'" [imageHeight]="75"
                    [imageWidth]="75" [margin]="4" [scale]="1" [title]="'Localizador evento'" [width]="150"></qrcode>
            </div>
        </div>
    </div>
</div>