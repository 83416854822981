import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fabric } from 'fabric';
import { max } from 'rxjs';

@Component({
  selector: 'app-image-map',
  templateUrl: './image-map.component.html',
  styleUrls: ['./image-map.component.scss']
})
export class ImageMapComponent implements OnInit {

  map!: any;
  titleMap!: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.map = this.data.map;
    this.titleMap = this.data.name;
    if (this.map) {
      let imageDoc: any = document.getElementById("imageMap");
      let canvas = new fabric.Canvas('imageCanvas', {
        selection: false,
      });
      const json = JSON.parse(this.map);
      let maxHeight = 600;
      let maxWidth = 1000;
      canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), function (o, object) {
        maxHeight = Math.max(maxHeight, Math.abs(o.top+o.height));
        maxWidth = Math.max(maxWidth, Math.abs(o.left+o.width));
      });
      canvas.setDimensions({ width: maxWidth+50, height: maxHeight+50 });
      canvas.renderAll();

      imageDoc.src = canvas.toDataURL('image/png');
    }
  }

  zoomMap(plus: boolean) {

  }

  fullscreeenMap() {

  }

}
