<div class="notifications-page">
    <div class="d-flex justify-content-between align-items-center">
        <div class="content-search col-6">
            <div class="search-container container-form d-flex align-items-center borderInput search-full">
                <i class="fal fa-search search-icon pointer-in" (click)="searchNotification(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar asunto..."
                    [(ngModel)]="searchText" (input)="searchNotification(searchInput.value)">
            </div>
        </div>
    </div>
    <div class="content-table-subscribers">
        <div *ngIf="loadingTable" class="container-lottie">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                    containerClass="loading-box"></ng-lottie>
            </div>
        </div>
        <div #containerScroll class="table-subscribers table-panel multiple-lines" *ngIf="loaded"
            (scroll)="isScrolledInfinityView($event)">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef><b></b></th>
                    <td mat-cell *matCellDef="let subscription"></td>
                </ng-container>
                <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef><b>Asunto</b></th>
                    <td mat-cell *matCellDef="let subscription">
                        {{subscription.subscriber.nombre||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef><b>Correo electrónico</b></th>
                    <td mat-cell *matCellDef="let subscription">
                        {{subscription.subscriber.email||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="attachment">
                    <th mat-header-cell *matHeaderCellDef><b>Adjunto</b></th>
                    <td mat-cell *matCellDef="let subscription">
                        {{subscription.createdAt | date: 'dd-MM-y'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef><b>Fecha de envío</b></th>
                    <td mat-cell *matCellDef="let subscription" [style.color]="getStatusColor(subscription.status)">
                        <div class="d-flex align-items-center" style="gap: 5px;">
                            <i class="fas fa-circle" [style.color]="getStatusColor(subscription.status)"></i>
                            {{getStatus(subscription.status)||'-'}}
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.blocked]="row.status=='blocked'">
                </tr>
            </table>
            <div class="pagination-component">
                <div class="d-flex align-items-center pagination-panel pagination-table" *ngIf="!loadingPage && !finishScroll">
                    <div>
                        <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                            containerClass="loading-box"></ng-lottie>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="loaded && !loadingTable && (subscribers.length<=0)" style="text-align: center;">No hay
            correos disponibles.
        </p>
    </div>
</div>