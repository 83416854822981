import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { BlogService } from 'src/app/services/blog.service';
import { EventosService } from 'src/app/services/eventos.service';

@Component({
  selector: 'app-detail-pods',
  templateUrl: './detail-pods.component.html',
  styleUrls: ['./detail-pods.component.scss']
})
export class DetailPodsComponent implements OnInit {

  post!: any;
  tags: any[] = [];
  eventosDestacados: Observable<any[]>;

  constructor(private activatedRoute: ActivatedRoute, private title: Title,
    private blogService: BlogService, private eventosService: EventosService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.slug) {
        this.blogService.getPostBySlug(params.slug).subscribe({
          next: (data) => {
            this.post = data;
            this.title.setTitle('Cityblog - ' + 'Nombre')
            this.blogService.getTagsByPost(data.id).subscribe(tags => this.tags = tags.map(s => s.name));
            const idCity = +localStorage.getItem('city');
            this.eventosDestacados = this.eventosService.getEventsBest(idCity).pipe();
            const destacadosCerca = this.eventosService.getEventsBest(idCity, true).pipe();
            forkJoin([this.eventosDestacados, destacadosCerca]).subscribe(results => {
              this.eventosDestacados = of(results[0].concat(results[1]));
            });
          }
        })
      }
    });
  }

}
