import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fabric } from 'fabric';

@Component({
  selector: 'app-edit-info-section',
  templateUrl: './edit-info-section.component.html',
  styleUrls: ['./edit-info-section.component.scss']
})
export class EditInfoSectionComponent implements OnInit {

  @Input() sectionElement!: any;
  @Input() canvas!: any;
  @Input() cancel!: any;
  @Input() colorDefault: string = '#d3d3d3';
  @Input() colorBorde: string = '#d3d3d3';

  sectionNumber: number = 0;
  sectionName: string = '';

  @Output() updateCanvas = new EventEmitter<any>();
  @Output() copyObject = new EventEmitter<any>();
  @Output() closeTab = new EventEmitter<any>();

  firstTime: boolean = false;
  editForm: boolean = false;
  hasBorder: boolean = true;
  hasBackground: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.colorDefault = this.sectionElement.fill;
    this.colorBorde = this.sectionElement.stroke;
    this.hasBorder = this.sectionElement.strokeWidth != 0;
    this.sectionNumber = this.sectionElement.area;
    this.sectionName = this.sectionElement.name;
  }

  editColor() {
    if(this.hasBackground) {
      this.sectionElement.set('fill', this.colorDefault);
    }
    if(this.hasBorder) {
      this.sectionElement.set('stroke', this.colorBorde);
    }
  }

  editSection() {
    this.sectionElement.set('area', this.sectionNumber);
    this.updateCanvas.emit();
  }

  editNameSection() {
    this.sectionElement.set('name', this.sectionName);
    this.updateCanvas.emit();
  }

  activeEditing() {
  }

  editFormSection() {
    if (!this.editForm) {
      this.cancelEditForm();
    } else {
      var lastControl = this.sectionElement.points.length - 1;
      this.sectionElement.set("edit", true);
      this.sectionElement.set("objectCaching", false);
      this.sectionElement.set("cornerStyle", 'circle');
      this.sectionElement.set("cornerColor", 'blue');
      this.sectionElement.set("transparentCorners", false);
      this.sectionElement.controls = this.sectionElement.points.reduce(function (acc, point, index) {
        acc['p' + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
          actionName: 'modifyPolygon',
          pointIndex: index
        });
        return acc;
      }, {});
      this.sectionElement.set("hasBorders", false);
      this.updateCanvas.emit();
    }
  }

  editBackground() {
    var colorBackground = this.hasBackground ? this.colorDefault : 'transparent';
    this.sectionElement.set('fill', colorBackground);
    this.updateCanvas.emit();
  }

  editBorder() {
    if (!this.hasBorder) {
      this.sectionElement.set("strokeWidth", 0);
    } else {
      this.sectionElement.set("strokeWidth", 1);
    }
    this.updateCanvas.emit();
  }

  copyForm() {
    this.copyObject.emit();
  }

  cancelEditForm() {
    this.editForm = false;
    this.sectionElement.set("edit", false);
    this.sectionElement.set("cornerColor", "rgba(0,0,255,0.5)");
    this.sectionElement.set("objectCaching", true);
    this.sectionElement.set("cornerStyle", 'rect');
    this.sectionElement.set("transparentCorners", true);
    this.sectionElement.set("controls", fabric.Object.prototype.controls);
    this.sectionElement.set("hasBorders", true);
    this.updateCanvas.emit();
  }

  cancelEdit(exit?: boolean) {
    this.cancelEditForm();
    this.closeTab.emit(true);
  }

  editRotation(value: any) {
    this.sectionElement.set('angle', parseInt(value, 10)).setCoords();
    this.updateCanvas.emit();
  }

  applyChanges() {
    this.editColor();
    this.cancelEditForm();
    this.editForm = false;
    this.updateCanvas.emit();
  }

  addText() {
    const text = new fabric.Textbox('Escribe un texto...', {
      width: 200,
      height: 100,
      fontSize: 24,
      // fontFamily: 'Satoshi-Regular',
      cursorColor: '#FF4D80',
      left: 50,
      top: 50,
      eventSeat: false
    });
    this.canvas.add(text);
  }

  createTextComponent(text: any, fontsize: number, top: number = 0) {
    let textComponent = new fabric.Text(text, {
      // fontFamily: 'Satoshi-Regular',
      fontSize: fontsize,
      fill: 'black',
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      eventSeat: false,
      top: top
    });
    return textComponent;
  }

}


function getObjectSizeWithStroke(object) {
  var stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

function actionHandler(eventData, transform, x, y) {
  var polygon = transform.target,
    currentControl = polygon.controls[polygon.__corner],
    mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
    polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    finalPointPosition = {
      x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
      y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
    };
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

function anchorWrapper(anchorIndex, fn) {
  return function (eventData, transform, x, y) {
    var fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint({
        x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
        y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
      }, fabricObject.calcTransformMatrix()),
      actionPerformed = fn(eventData, transform, x, y),
      newDim = fabricObject._setPositionDimensions({}),
      polygonBaseSize = getObjectSizeWithStroke(fabricObject),
      newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
      newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  }
}

function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
    y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
  return fabric.util.transformPoint(
    { x: x, y: y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  );
}