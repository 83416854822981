import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fabric } from 'fabric';
import { element } from 'protractor';
import { AreasSeatService } from 'src/app/services/areas-seat.service';

@Component({
  selector: 'app-edit-seats',
  templateUrl: './edit-seats.component.html',
  styleUrls: ['./edit-seats.component.scss']
})
export class EditSeatsComponent implements OnInit {

  @Input() canvas!: any;
  @Input() seatElement!: any;
  @Input() seatsEditSelected!: any;
  @Input() section!: any;

  @Output() editSeatParams = new EventEmitter<any>();

  visibilitySeat: boolean = false;
  valueSeat!: any;
  valueRow!: any;
  numeracionImpar: boolean = false;
  numeracionFilas: boolean = false;
  numeracionPar: boolean = false;
  directionRight: boolean = true;

  colorDefaultSeat = this.areaService.colorDefaultSeat;
  colorDefaultDisplay = this.areaService.colorDefaultDisplay;
  colorDefaultVisibility = this.areaService.colorDefaultVisibility;

  constructor(private areaService: AreasSeatService) {
  }

  ngOnInit(): void {
    this.section._objects.forEach((seatElement) => {
      if (seatElement.eventSeat) {
        seatElement.visible = true;
        let color = seatElement.isVisible ? this.colorDefaultSeat : this.colorDefaultVisibility;
        changeSeatColor(seatElement, color);
      }
    });
    this.valueSeat = this.seatElement?.seat;
  }

  getCountEdit() {
    const array = this.seatsEditSelected.filter(value => value != null);
    return array?.length;
  }

  applyChanges() {
    this.section._objects.forEach((seatElement) => {
      if (seatElement.eventSeat) {
        seatElement.visible = seatElement.isVisible;
        changeSeatColor(seatElement, this.colorDefaultSeat);
      }
    });
    this.seatsEditSelected.forEach((element) => {
      element = null
    });
    this.editSeatParams.emit(false);
  }

  changeVisibilitySeat() {
    this.section._objects.forEach((seatElement) => {
      if (seatElement.indexEditing != null) {
        seatElement.isVisible = seatElement.editing ? !this.visibilitySeat : true;
        let color = seatElement.isVisible ? this.colorDefaultDisplay : this.colorDefaultVisibility;
        changeSeatColor(seatElement, color);
      }
    });
    this.editSeatParams.emit(true);
  }

  changeNumberRows() {
    this.section._objects.forEach((seatElement) => {
      if (seatElement.indexEditing != null) {
        seatElement.isVisible = seatElement.editing ? !this.visibilitySeat : true;
        let color = seatElement.isVisible ? this.colorDefaultDisplay : this.colorDefaultVisibility;
        changeSeatColor(seatElement, color);
      }
    });
    this.editSeatParams.emit(true);
  }

  editSeatNumber() {
    this.seatElement.set('seat', +this.valueSeat);
    let elementText = this.seatElement._objects.find(value => value.type == 'text');
    elementText.set('text', this.valueSeat);
    this.editSeatParams.emit(true);
  }

  editRow() {

  }

  changeNumPar() {
    this.numeracionImpar = false;
    let initAux = 2;
    const columns = this.section.columns;
    this.section._objects.forEach(seatElement => {
      if (seatElement.eventSeat) {
        let column = this.directionRight ? seatElement.column : (columns - seatElement.column + 1);
        let value = +column * initAux;
        seatElement.set('seat', +value);
        let elementText = seatElement._objects.find(value => value.type == 'text');
        elementText.set('text', value.toString());
      }
    });
    this.editSeatParams.emit(true);
  }

  changeNumImpar() {
    this.numeracionPar = false;
    const columns = this.section.columns;
    this.section._objects.forEach(seatElement => {
      if (seatElement.eventSeat) {
        let column = this.directionRight ? seatElement.column : (columns - seatElement.column + 1);
        let value = column + (column - 1);
        seatElement.set('seat', value);
        let elementText = seatElement._objects.find(value => value.type == 'text');
        elementText.set('text', value.toString());
      }
    });
    this.editSeatParams.emit(true);
  }

  cancelEdit(event?: any) {
    this.section._objects.forEach((seatElement) => {
      if (seatElement.eventSeat) {
        seatElement.visible = seatElement.isVisible;
        changeSeatColor(seatElement, this.colorDefaultSeat);
      }
    });
    this.editSeatParams.emit(false);
  }

  changeDirection() {
    const columns = this.section.columns;
    this.section._objects.forEach(seatElement => {
      if (seatElement.eventSeat) {
        let column = this.directionRight ? seatElement.column : (columns - seatElement.column + 1);
        let value = column;
        if (this.numeracionImpar) {
          value = column + (column - 1);
        }
        if (this.numeracionPar) {
          value = +column * 2;
        }
        seatElement.set('seat', value);
        let elementText = seatElement._objects.find(value => value.type == 'text');
        elementText.set('text', value.toString());
      }
    });
    this.editSeatParams.emit(true);
  }
}

function changeSeatColor(elementSeat, color) {
  let elementStroke = elementSeat._objects.find(value => value.type != 'text');
  elementStroke.set('fill', color);
  let rgb = hexToRgb(color);
  let textColor = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? '#000' : '#fff'
  let elementText = elementSeat._objects.find(value => value.type == 'text');
  elementText.set('fill', textColor);
  return elementSeat;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}