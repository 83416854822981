import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageWpService } from 'src/app/services/page-wp.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  @Input() openDialog: boolean = false;
  
  contentPage!: SafeHtml;

  constructor(private pageWpService: PageWpService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.pageWpService.getStaticBySlug('terminos-y-condiciones').subscribe({
      next: (res) => {
        let data = res[0];
        this.contentPage = this.sanitizer.bypassSecurityTrustHtml(
          data.content.rendered
        );
      }
    })
  }

}
