import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
const bcrypt = require('bcryptjs');

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changeForm: FormGroup;
  user: any;

  hideActual: boolean = true;
  hide: boolean = true;
  hideConfirm: boolean = true;

  salt = bcrypt.genSaltSync(10);

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private auth: AuthenticationService, private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.user = this.data.user;
    this.changeForm = this.formBuilder.group({
      password: ['', Validators.required],
      textPassword: ['', Validators.compose([
        Validators.required,
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
        Validators.maxLength(30)
      ])],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
    }, { 
      validators: this.password.bind(this)
    });
  }

  // Validacion contraseña
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.controls['textPassword'];
    const { value: confirmPassword } = formGroup.controls['confirmPassword'];
    if(password === confirmPassword) {
      return null;
    } else {
      return { passwordNotMatch: true };
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = regex.test(control.value);
      if(valid) {
        return null;
      } else {
        return error;
      }
    };
  }

  get pass() { return this.changeForm.get('textPassword'); }
  get repPass() { return this.changeForm.get('confirmPassword'); }

  changePassword() {
    if(this.changeForm.valid) {
      var password = this.changeForm.controls['textPassword'].value;
      var hash = bcrypt.hashSync(password, this.salt);

      this.auth.login(this.user.email, this.changeForm.value.password).subscribe({
        next: (data) => {
          if(data.token) {
            this.userService.putUser(this.auth.user, {password: hash}).subscribe({
              next: (data) => {
                this.dialogRef.close('changed');
              },
              error: (err) => {
                this.dialogRef.close('error');
              }
            })
          }
        }, error: (err) => {
          this.dialogRef.close('error');
        }
      })
    }
  }

}
