import { Component, OnInit } from '@angular/core';
import { CategoriasService } from '../../../services/categorias.service';
import { MatTableDataSource } from '@angular/material/table';
import { DeletesComponent } from '../shared/deletes/deletes.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {

  displayedColumns: string[] = ['icono', 'nombre','eventos','showhome','options'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  idCat!: number;

  constructor(private categoriasService: CategoriasService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.categoriasService.getAllCategories().subscribe(data => {
      if(data){
        this.dataSource =data;
      }
    })
  }

  // Delete
  openDelete(cat: any) {
    if(cat.countEvents == 0) {
      const dialogRef = this.dialog.open(DeletesComponent, {});
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteCat(cat.id);
        }
      });
    }
  }

  deleteCat(catId: number){
    this.categoriasService.deleteCategoria(catId).subscribe(data => {
        this.getData();
    })
  }

}
