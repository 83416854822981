import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  permissionLocaton = new BehaviorSubject<string>(null);
  public permision = this.permissionLocaton.asObservable();

  constructor(private commonService: CommonService) { }

  async getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        },
        {enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
    });
  }

  getCityByPosition(object: any) {
    let lang = 'es';
    const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${ object.lat }&longitude=${ object.lng }&localityLanguage=${lang}`
    return this.commonService.getData(url);
  }

}
