<div class="detail-event-panel">
    <div *ngIf="loadingEvent" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>
    <div class="tabs-group" *ngIf="loaded">
        <mat-tab-group (selectedTabChange)="changeTab($event)" [selectedIndex]="index" dynamicHeight mat-stretch-tabs
            fitInkBarToContent color="primary" class="selected-tab-primary tab-color"
            animationDuration="10ms">
            <mat-tab label="Información">
                <div class="section-tab">
                    <app-info-event [profile]="profile" [event]="event"></app-info-event>
                </div>
            </mat-tab>
            <mat-tab label="Detalle del evento">
                <div class="section-tab">
                    <app-detail-event [levels]="levels" [profile]="profile" [event]="event"></app-detail-event>
                </div>
            </mat-tab>
            <mat-tab label="Mapa de butacas" *ngIf="mapId">
                <div class="section-tab map-event">
                    <button class="btn-editor" (click)="openEditMap()">Abrir editor</button>
                </div>
            </mat-tab>
            <mat-tab label="Descuentos y abonos" disabled>
                <div class="section-tab">
                    <app-discounts-event [discounts]="discounts" [abonos]="abonos" [profile]="profile" [event]="event"></app-discounts-event>
                </div>
            </mat-tab>
            <mat-tab label="Configuración de entradas" disabled>
                <div class="section-tab config-event">

                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>