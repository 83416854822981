import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-type',
  templateUrl: './select-type.component.html',
  styleUrls: ['./select-type.component.scss']
})
export class SelectTypeComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,private dialogRef: MatDialogRef<SelectTypeComponent>) {
  }

  ngOnInit(): void {
  }

  selectOption(option: any) {
    this.dialogRef.close(option);
  }

}
