import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // City
  private cityPage = new BehaviorSubject<number>(null);
  public cityStorage = this.cityPage.asObservable();

  public user: any = false;
  private profile!: any;

  private profileObject = new BehaviorSubject<any>(null);
  public profileStorage = this.profileObject.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    if (localStorage.getItem('jwt_token')) {
      this.getUsersMe().subscribe(res => { if (res) { this.user = res.id } })
    }
  }

  getRoles() {
    let profile = this.getProfile();
    return profile?.roleFunctions.map((role: any) => role.role.code);
  }

  setProfile(user: any) {
    this.profile = user;
    this.profileObject.next(user);
  }

  getProfile() {
    return this.profile;
  }

  getMyData() {
    if (this.profile) {
      const url = `${AppSettings.API_URI}/users/${this.profile?.id}`;
      return this.http.get<any>(url).pipe(
        map(data => {
          return data;
        })
      );
    } else {
      return of(false);
    }
  }

  login(username: string, password: string) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(AppSettings.API_LOGIN + '/login', { username: username, password: password }, formHeaders).pipe(
      map(jwt => {
        if (jwt.token) {
          localStorage.setItem('jwt_token', jwt.token);
          localStorage.setItem('refresh', jwt.refresh_token);
        }

        return jwt;
      })
    );
  }

  check(fromSeats: boolean = false) {
    return this.http.get<any>(AppSettings.API_URI + '/users/me').pipe(
      map(data => {
        if (data.enabled && data.id != null) {
          if (data.ciudad) {
            this.setCity(data.ciudad);
          }
          this.user = data.id;
          this.setProfile(data);
          if (data.organizer) {
            this.router.navigate(['/panel-control']);
          } else if (!fromSeats) {
            this.router.navigate(['/']);
          }
          return data;
        }
        return false;
      }, error => {
        return false;
      })
    );
  }

  register(jsonForm: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(AppSettings.API_URI + '/user_register', jsonForm, formHeaders).pipe(
      map(register => {
        return register;
      })
    );
  }

  logout() {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('refresh');
    let cookies = localStorage.getItem('cookies');
    localStorage.clear();
    localStorage.setItem('cookies', cookies);
    this.profileObject.next(null);
    this.profile = null;
    this.user = false;
    this.router.navigate(['/']).then(() => location.reload());
  }

  setCity(city: number) {
    this.cityPage.next(city);
  }

  validateEmail(email: string, token: string) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/user_check`;
    return this.http.post<any>(url, { email: email, token: token }, formHeaders).pipe(
      map(
        data => {
          return data;
        }
      )
    );
  }

  resetPassword(payload: any) {
    return this.http.post<any>(AppSettings.API_URI + '/reset/password', payload).pipe(
      map(data => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  loginGoogle(id: string, token: string) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(AppSettings.API_URI + '/social_verify', { id: id, idToken: token }, formHeaders).pipe(
      map(jwt => {
        if (jwt.token) {
          localStorage.setItem('jwt_token', jwt.token);
          localStorage.setItem('refresh', jwt.refresh_token);
        }
        return jwt;
      })
    );
  }

  loginFacebook(payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(AppSettings.API_URI + '/social_verify', { user: payload, fb: true }, formHeaders).pipe(
      map(jwt => {
        if (jwt.token) {
          localStorage.setItem('jwt_token', jwt.token);
          localStorage.setItem('refresh', jwt.refresh_token);
        }
        return jwt;
      })
    );
  }

  getUsersMe() {
    const url = `${AppSettings.API_URI}/users/me`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        this.setProfile(data);
        return data;
      })
    );
  }

}
