<div class="events-panel">
    <div class="btns-filters">
        <div class="buscador-event container-form d-flex align-items-center borderInput">
            <i class="fal fa-search search-icon pointer-in" (click)="searchEvents(searchInput.value)"></i>
            <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar evento"
                [ngModel]="searchText" (input)="searchEvents(searchInput.value)">
        </div>
        <div class="btns-create" *ngIf="!idVenue && allowCreate">
            <div class="btn-create btn-create-border btn-bono m-0" (click)="openCreateBond()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Crear abono</p>
            </div>
            <div class="btn-create m-0" (click)="openCreateEvent()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Crear evento</p>
            </div>
        </div>
    </div>

    <div *ngIf="loadingPage" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>

    <div #containerScroll class="table-events table-panel multiple-lines" *ngIf="loaded"
        (scroll)="isScrolledInfinityView($event)">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let event">
                    <i class="fal fa-ellipsis-vertical icon-option" (click)="openEvent(event)"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="titulo">
                <th mat-header-cell *matHeaderCellDef><b>Evento</b></th>
                <td mat-cell *matCellDef="let event" (click)="openEvent(event)">
                    <img fallimg class="image-table" src="{{urlImages+'/'+event[0].id+'/'+event[0].imageurl}}">
                    <span class="link-titulo">{{event[0].titulo}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="qr">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let event">
                    <i class="fal fa-qrcode icon-option icon-qr" (click)="dowloadQR(event)"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef><b>Lugar</b></th>
                <td mat-cell *matCellDef="let event" (click)="openEvent(event)">
                    <span [class.placeText]="event[0].place">{{event[0].place ? event[0].place?.name :
                        event[0].localizacion}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef><b>Fecha y hora</b></th>
                <td mat-cell *matCellDef="let event" (click)="openEvent(event)">
                    <i class="fa-light fa-calendar"></i>
                    {{event[0].datesRange.start | date:'dd MMM y' | titlecase }}
                    <span *ngIf="event[0].hourStart"> | {{event[0].hourStart}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="vendidas" *ngIf="!draft">
                <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
                <td mat-cell *matCellDef="let event" (click)="openEvent(event)">
                    <div *ngIf="!event[0].draft">
                        <span>{{event.purchases}}</span>
                        <span *ngIf="event[0].place">{{event[0].capacity?' / '+event[0].capacity:''}}</span>
                        <span
                            *ngIf="!event[0].place && (!event[0].datesRange.end || event[0].datesRange.start == event[0].datesRange.end)">{{event[0].capacity?'
                            / '+event[0].capacity:''}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="precio">
                <th mat-header-cell *matHeaderCellDef><b>Desde</b></th>
                <td mat-cell *matCellDef="let event" (click)="openEvent(event)">
                    {{event[0].preciodesde}} €
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><b>Estado</b></th>
                <td mat-cell *matCellDef="let event" (click)="openEvent(event)">
                    <div class="d-flex align-items-center status-event" [style.color]="getStatus(event).color">
                        <i class="fa-solid fa-circle"></i>
                        <span>{{getStatus(event).status}}</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="pagination-component">
            <div class="d-flex align-items-center pagination-panel pagination-table"
                *ngIf="!loadingPage && !finishScroll">
                <div>
                    <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                        containerClass="loading-box"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
    <p *ngIf="loaded && !loadingPage && eventos?.length <= 0" style="text-align: center; margin-top: 80px;">No hay
        {{draft ? 'borradores' : 'eventos'}} disponibles</p>
</div>
