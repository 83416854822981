import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AppSettings } from 'src/app/app-settings';
import { SeguidoresService } from 'src/app/services/seguidores.service';

@Component({
  selector: 'app-profile-fav',
  templateUrl: './profile-fav.component.html',
  styleUrls: ['./profile-fav.component.scss']
})
export class ProfileFavComponent implements OnInit {

  favs: any[] = [];
  urlImages: string = AppSettings.MEDIA_URI + '/images/users';
  @Input() isSitio: boolean = false;

  loadingPage: boolean = true;
  finishScroll: boolean = false;

  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };

  items: number = 10;
  page: number = 1;
  @ViewChild('containerFavs', {static: false}) containerFavs: ElementRef;

  constructor(private seguidoresService: SeguidoresService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if(this.isSitio) {
      this.seguidoresService.getSitiosFav(this.items, this.page).subscribe(fav => {
        if(fav) {
          this.favs = this.favs.concat(fav);
        }
        this.finishScroll = fav?.length < this.items
        this.loadingPage = false;
      });
    } else {
      this.seguidoresService.getSiguiendo(this.items, this.page).subscribe(fav => {
        if(fav) {
          this.favs = this.favs.concat(fav);
        }        
        this.finishScroll = fav?.length < this.items
        this.loadingPage = false;
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView() {
    if(!this.finishScroll && !this.loadingPage) {
      const element = this.containerFavs.nativeElement.getBoundingClientRect();
      let bottomShown = element.bottom <= window.innerHeight;
      if(bottomShown) {
        this.page++;
        this.loadingPage = true;
        setTimeout(() => {
          this.getData();
        }, 3000);
      }
    }
  }

  deleteFavorito(favorite: any) {
    this.seguidoresService.deleteSiguiendo(favorite.id).subscribe(res => {
      if(res) {
        const index = this.favs.findIndex(value => value.id == favorite.id);
        if (index != -1) { this.favs.splice(index, 1)}
      }
    })
  }

  onScrollToTop() {
    window.scrollTo(0,0);
  }

}
