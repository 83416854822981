<div class="header-template-admin"><i class="icon-page far fa-times" (click)="openDialog()"></i><span>Datos del banner</span></div>
<mat-divider></mat-divider>
<div class="template-new">
  <h3 *ngIf="idB">Editar Banner</h3>
  <h3 *ngIf="!idB">Nuevo Banner</h3>
  <form [formGroup]="banner" class="row col-xl-10 col-xxl-10 col-lg-12">

    <div class="mb-2" style="color: red; padding: 10px 0 0 10px;">{{ message }}</div>

    <div class="col-lg-10 col-md-12 mb-4 p-1">
      <p>Imagen para el Banner</p>

      <div *ngIf="filePath && filePath !== ''" >
          <img fallimg (click)="file.click()" class="img-new img-principal" [class.errorfile]="errorfile" [src]="filePath" alt="Imagen Principal">
      </div>

      <div *ngIf="!filePath || filePath == ''" (click)="file.click()" class="main-img pointer-in mb-2 mt-2" [class.error-image]="errorImage">
        <i class="fa-thin fa-plus m-0"></i>
      </div>
      <input id="file" hidden #file type="file" accept="image/*" (change)="onImageChange($event)">
      <mat-hint class="light-text">Recomendado: X x X px (Max: XMb)</mat-hint>
    </div>

    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <!-- Titulo -->
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Titulo" formControlName="title">
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <!-- Boton -->
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Botón" formControlName="button">
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <!-- Descripcion -->
        <div class="btn-fixed-description">
          <div class="description-container">
            <div class="d-flex justify-content-between align-items-center desc-header">
                <p class="m-0">Descripción*</p>
                <span class="count-number">{{ 2000 - textArea.length }}</span>
            </div>
            <mat-divider></mat-divider>

            <angular-editor #edit id="1" [(ngModel)]="textArea" [config]="editorConfig" formControlName="description">
                <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                  <ae-toolbar-set class="button-styles-text" tabindex="">
                    <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" [enableSearch]="false" [showPreview]="false" (emojiSelect)="executeCommandFn('insertHtml', addEmoji($event))"></emoji-mart>
                    <button class="btn-style btn-bold" (click)="executeCommandFn('bold')"><strong>N</strong></button>
                    <button class="btn-style btn-italic" (click)="executeCommandFn('italic')"><i>K</i></button>
                    <button class="btn-style btn-underline" (click)="executeCommandFn('underline')"><u>S</u></button>
                    <button class="btn-emoji pointer-in" (click)="isEmojiPickerVisible = !isEmojiPickerVisible;"><i class="fa-light fa-face-smile"></i></button>
                  </ae-toolbar-set>
                </ng-template>
            </angular-editor>
          </div>
        </div>
        <!-- <mat-form-field class="w-100 mb-1" appearance="outline">
          <textarea matInput placeholder="Descripción" formControlName="description"></textarea>
        </mat-form-field> -->
      </div>

    </div>

    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
      <!-- ShowHome -->
      <div class="col-12">
        <mat-checkbox formControlName="showhome" color="primary">Show home</mat-checkbox>
      </div>
    </div>

    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
      <!-- imgIsright -->
      <div class="col-12">
        <mat-checkbox formControlName="imgIsright" color="primary">¿Imagen a la derecha?</mat-checkbox>
      </div>
    </div>

    <div>
      <button class="w-20 mr-20 btn btn-cancel" type="button" (click)="openDialog()">Cancelar</button>
      <button class="w-20 m-0 btn btn-primary btn-ok"
        type="submit" (click)="submit()">Guardar Cambios</button>
    </div>
    </form>
</div>
