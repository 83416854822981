<div class="seat-panel-component" [class.from-abono-styles]="fromAbono" [class.seats-totem]="totemActive">
    <div class="content-map-select">
        <div *ngIf="!loaded" class="container-lottie">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                    containerClass="loading-box"></ng-lottie>
            </div>
        </div>
        <div class="w-100 close-small" [class.close-from-abono]="fromAbono || totemActive"
            [class.title-seats-totem]="totemActive" [class.item-small]="!fromAbono">
            <div *ngIf="totemActive">
                <p class="m-0">Seleccionar asientos</p>
            </div>
            <i class="btn-close-map fa-times" [class]="totemActive?'fa-light':'fa-regular'" mat-dialog-close></i>
        </div>
        <div class="info-areas" *ngIf="areas.length>0">
            <!-- <div class="d-flex justify-content-center gap-items">
                <div class="area-item" *ngFor="let area of areas">
                    <div class="color-area" [style.background-color]="area.color"></div>
                    <p class="m-0">{{area.name}}</p>
                </div>
            </div> -->
            <div>
                <mat-form-field appearance="fill" class="w-100 filter-areas-seats">
                    <mat-select-trigger matPrefix style="vertical-align: middle;" class="prefix-areas">
                        <div class="color-area" [style.background-color]="infoSectionActive?.color"></div>
                    </mat-select-trigger>
                    <mat-select panelClass="selectAreas" placeholder="Selecciona un Área"
                        [(ngModel)]="infoSectionActive" (ngModelChange)="selectArea()">
                        <mat-option class="area-item option-area" *ngFor="let area of areas" [value]="area">
                            <div class="color-area" [style.background-color]="area.color"></div>
                            <p class="m-0">{{area.name}}</p>
                        </mat-option>
                    </mat-select>
                    <mat-select-trigger matSuffix style="vertical-align: middle;">
                        <i class="fal fa-angle-down pointer-in"></i>
                    </mat-select-trigger>
                </mat-form-field>
            </div>
        </div>
        <div class="select-hidden-code" *ngIf="(profile?.protocolo)">
            <!-- <p class="m-0 info-hidden-code"><i
                class="fa-light fa-circle-exclamation icon-primary icon-save"></i>¿Quieres
            comprar asientos reservados? Introduce tu código </p> -->

            <p class="m-0 info-hidden-code"><i
                    class="fa-light fa-circle-exclamation icon-primary icon-save"></i>¿Quieres
                comprar asientos reservados? Introduce tu código <span (click)="openFormCode()"
                    class="link-open pointer-in">aquí.</span></p>
            <div class="hidden-code container-form d-flex align-items-center" [class.savedCode]="savedCode"
                *ngIf="openFormDiscount">
                <input class="w-100" type="text" placeholder="Código de desbloqueo" [(ngModel)]="hiddenCode"
                    [readonly]="savedCode">
                <button class="btn-discount cancel-button" [class.active]="hiddenCode" *ngIf="!savedCode"
                    (click)="applyCode()">Aplicar</button>
                <button *ngIf="savedCode" class="btn-discount cancel-button" [class.active]="hiddenCode"
                    (click)="deleteCode()"><i class="fa-light fa-trash-can icon-primary icon-save"></i>Eliminar</button>
            </div>
            <p *ngIf="savedCode" class="info-correct"><i class="fa-light fa-circle-check icon-primary icon-save"></i>Se
                han desbloqueado los asientos correspondientes: {{discountObject.hiddenSeat.name}}</p>
            <p *ngIf="errorCode && openFormDiscount" class="error-discount"><i
                    class="fa-light fa-circle-xmark icon-red icon-error"></i>
                El descuento no existe o no se ha podido aplicar.</p>
        </div>
        <div class="buttons-zoom-map">
            <button (click)="zoomMap(true, $event)">
                <i class="fa-regular fa-plus"></i>
            </button>
            <button (click)="zoomMap(false, $event)">
                <i class="fa-regular fa-minus"></i>
            </button>
        </div>
        <div class="legend-icon" [class.activeLegend]="openLegend" (click)="openedLegend()">
            <i class="fa-light fa-circle-info icon-primary icon-save"></i>
        </div>
        <div class="legend-map" [class.activeLegend]="openLegend" (click)="openedLegend()">
            <div class="d-flex align-items-center justify-content-between item-legend">
                <p class="name-legend">Disponibles: </p>
                <div class="name-legend">{{enabled}}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between item-legend">
                <p class="name-legend">Movilidad reducida: </p>
                <div class="color-legend" [style.background]="colorDefaultMovility"></div>
            </div>
            <div class="d-flex align-items-center justify-content-between item-legend" *ngIf="event.company.id == 3">
                <p class="name-legend">Sillas: </p>
                <div class="color-legend" [style.background]="colorDefaultSpecial"></div>
            </div>
            <div class="d-flex align-items-center justify-content-between item-legend">
                <p class="name-legend">No disponibles: </p>
                <div class="color-legend" [style.background]="colorDefaultHidden"></div>
            </div>
            <div class="d-flex align-items-center justify-content-between item-legend">
                <p class="name-legend">Seleccionadas: </p>
                <div class="color-legend" [style.background]="colorDefaultSelect"></div>
            </div>
        </div>
        <div id="canvas-container" class="canvas-container">
            <canvas id="myCanvas"></canvas>
        </div>
        <div class="actived-seat w-100" *ngIf="activedSeat">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="name-section m-0">{{activedSeat?.level?.name}}</p>
                <i class="btn-close-map fa-regular fa-times pointer-in" (click)="closeActived()"></i>
            </div>
            <div class="options-price">
                <div class="d-flex" *ngIf="checkInvite">
                    <i class="fa-light fa-circle-check icon-invite"></i>
                    <p class="m-0 info-invite">Todas las entradas cuentan con un descuento del 100%.</p>
                </div>
                <div class="d-flex" style="gap: 8px;" *ngIf="fromAbono">
                    <i class="fa-light fa-circle-info icon-primary"></i>
                    <p class="m-0">Recuerda que la reserva del asiento se realizará al añadirlo al carrito.</p>
                </div>
                <div *ngIf="!optionSection || optionSection.length <= 0">
                    <p>Cargando...</p>
                </div>
                <div class="d-flex align-items-center justify-content-between type-ticket"
                    *ngFor="let option of optionSection; let i = index">
                    <div class="d-grid">
                        <span>{{option.ticket.nombre}}</span>
                        <span>{{option.price | number:'1.2-2'}} €</span>
                        <span class="description-ticket"
                            *ngIf="option.ticket.description">*{{option.ticket.description}}</span>
                    </div>
                    <button class="w-100 primary-button solid btn-select-ticket" (click)="addTicket(option)">
                        Seleccionar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="info-select-map" [class.openedActived]="activedSeat" *ngIf="!fromAbono">
        <div class="w-100 justify-content-end close-big" *ngIf="!totemActive">
            <i class="btn-close-map fa-regular fa-times" mat-dialog-close></i>
        </div>
        <p class="title-selected-ticket">Entradas seleccionadas</p>
        <div class="list-selected" *ngIf="openDetail">
            <div class="info-ticket d-flex justify-content-between align-items-center"
                *ngFor="let item of selectedSeats; let i = index">
                <div>
                    <p class="m-0 name-ticket">{{item.option.ticket.nombre}}</p>
                    <div class="info-seats">
                        <span>{{item.section.name}}</span>
                        <span *ngIf="item.row != null && item.showRow">Fila: {{item.row}}</span>
                        <span *ngIf="item.seat != null">Asiento: {{item.seat}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center col-price-panel">
                    <p class="m-0 text-price">{{invite ? 0 : item.option.price}} €</p>
                    <div class="delete-ticketd-flex align-items-center justify-content-end"
                        (click)="deleteTicket(item, i)">
                        <i class="far fa-trash-alt icon-primary"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="resume-buy">
            <p class="number-tickets">x {{selectedSeats.length}} entradas</p>
            <div class="total-price align-items-center justify-content-between">
                <p class="title-price m-0">Subtotal:</p>
                <p class="number-price m-0">{{totalPrice | number:'1.2-2'}} €</p>
            </div>
            <div class="align-items-center pointer-in item-small" (click)="openDetail=!openDetail" style="gap: 10px;">
                <i class="fa-light icon-primary" [class]="openDetail ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
                <p class="m-0">{{openDetail ? 'Ocultar' : 'Mostrar'}} detalles</p>
            </div>
            <button class="w-100 primary-button solid btn-finish" (click)="goBuyTickets()"
                [class.disabled]="selectedSeats.length <= 0">
                Añadir <span class="info-ticket-btn"> {{selectedSeats.length}} entradas por {{totalPrice |
                    number:'1.2-2'}} €</span>
            </button>
            <p class="info-discount"><i class="fa-light fa-circle-info icon-primary icon-save"></i>
                ¿Tienes un código de descuento? Introdúcelo en la siguiente pantalla.</p>
        </div>
    </div>
</div>