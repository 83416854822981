<div class="dialog-content-menu">
    <div class="h-100" *ngIf="loading">
        <div class="header-dialog-menu d-flex align-items-center" [class]="loading && profile ? 'justify-content-between' : 'justify-content-end'">
            <div class="user-info d-flex" *ngIf="loading && profile">
                <div class="img-user" *ngIf="profile.imagen"><img fallimg src="{{urlImages}}/{{profile.imagen}}" alt="Img user" /></div>
                <div class="name-user">
                    <p class="m-0">{{ profile.nombre }}</p>
                    <div class="verify-user d-flex align-items-center" *ngIf="!profile.oficial">
                        <div class="d-flex align-items-center justify-content-center pointer-in icon-verify">
                            <i class="fa-light fa-circle-check"></i>
                        </div>
                        <span>Usuario verificado</span>
                    </div>
                </div>
            </div>
            <i class="fa-regular fa-times fa-lg pointer-in icon-primary" mat-dialog-close></i>
        </div>
        <div class="options-menu">
            <p class="title-option m-0">Eventos</p>
            <!-- Cambiar de ciudad -->
            <mat-divider></mat-divider>
            <div class="option d-flex align-items-center w-100" (click)="changeCity()">
                <i class="fa-light fa-city icon-option"></i>
                <div class="divider-option w-100">
                    <p class="m-0">{{ city }}</p>
                    <span>Cambiar de ciudad</span>
                </div>
                <i class="fa-light fa-chevron-right"></i>
            </div>
            <!-- Nuevo lok -->
            <ng-container *ngIf="loading && profile">
                <mat-divider></mat-divider>
                <div class="option d-flex align-items-center w-100" (click)="newLok()" mat-dialog-close>
                    <i class="fa-light fa-camera icon-option"></i>
                    <div class="divider-option w-100">
                        <p class="m-0">Nuevo Lok</p>
                    </div>
                    <i class="fa-light fa-chevron-right"></i>
                </div>
            </ng-container>
            <ng-template *ngIf="loading && profile">
                <!-- Mis eventos -->
                <mat-divider></mat-divider>
                <div class="option d-flex align-items-center w-100" routerLink="profile/events" mat-dialog-close>
                    <i class="fa-light fa-calendars icon-option"></i>
                    <div class="divider-option w-100">
                        <p class="m-0">Mis eventos</p>
                    </div>
                    <i class="fa-light fa-chevron-right"></i>
                </div>
            </ng-template>
            <mat-divider></mat-divider>
            <p class="title-option m-0">Información</p>
            <!-- Terminos -->
            <mat-divider></mat-divider>
            <div class="option d-flex align-items-center w-100 last-option" routerLink="terms-conditions" mat-dialog-close>
                <i class="fa-light fa-gavel icon-option"></i>
                <div class="divider-option w-100">
                    <p class="m-0">Términos y condiciones</p>
                </div>
                <i class="fa-light fa-chevron-right"></i>
            </div>
            <!-- Condiciones -->
            <mat-divider></mat-divider>
            <div class="option d-flex align-items-center w-100 last-option" routerLink="privacy" mat-dialog-close>
                <i class="fa-light fa-lock-keyhole icon-option"></i>
                <div class="divider-option w-100">
                    <p class="m-0">Política de privacidad</p>
                </div>
                <i class="fa-light fa-chevron-right"></i>
            </div>
            <!-- Ayuda -->
            <mat-divider></mat-divider>
            <div class="option d-flex align-items-center w-100 last-option" routerLink="faqs" mat-dialog-close>
                <i class="fa-light fa-square-question icon-option"></i>
                <div class="divider-option w-100">
                    <p class="m-0">Ayuda</p>
                </div>
                <i class="fa-light fa-chevron-right"></i>
            </div>

            <mat-divider></mat-divider>
            <p class="title-option m-0">Cuenta</p>
            <!-- Perfil -->
            <ng-container *ngIf="loading && profile; else divLogin">
                <mat-divider></mat-divider>
                <div class="option d-flex align-items-center w-100" routerLink="profile" mat-dialog-close>
                    <i class="fa-light fa-user icon-option"></i>
                    <div class="divider-option w-100">
                        <p class="m-0">Mi perfil</p>
                    </div>
                    <i class="fa-light fa-chevron-right"></i>
                </div>
                <!-- Salir -->
                <mat-divider></mat-divider> 
                <div class="option d-flex align-items-center w-100 last-option" (click)="logout()">
                    <i class="fa-light fa-arrow-right-from-bracket icon-option"></i>
                    <div class="divider-option w-100">
                        <p class="m-0">Salir</p>
                    </div>
                    <i class="fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
            <ng-template #divLogin>
                <mat-divider></mat-divider>
                <div class="option d-flex align-items-center w-100" routerLink="login" mat-dialog-close>
                    <i class="fa-light fa-arrow-right-to-bracket icon-option"></i>
                    <div class="divider-option w-100">
                        <p class="m-0">Iniciar sesión</p>
                    </div>
                    <i class="fa-light fa-chevron-right"></i>
                </div>
            </ng-template>
        </div>
    </div>
</div>