import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiSalesService } from 'src/app/services/api-sales.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { ConfirmPayComponent } from '../confirm-pay/confirm-pay.component';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit, OnDestroy {

  step: number = 1;
  user!: any;

  message = '';
  errorLogin: boolean = false;

  registerForm: boolean = false;
  correctPayment: boolean = false;
  titleStepForm: string = 'Inicia sesión para comenzar';
  data!: any;
  now: Date = new Date();
  idOrder: string = '';
  response!: any;
  intervalId: any;
  isAdmin: boolean = false;
  opened: boolean = false;

  constructor(private route: Router, private apiSales: ApiSalesService, private dialog: MatDialog, private auth: AuthenticationService) {
    this.data = this.route.getCurrentNavigation()?.extras.state || JSON.parse(localStorage.getItem('aux') || '');
    localStorage.removeItem('aux');
    let profile = this.auth.getProfile();
    this.isAdmin = profile?.roleFunctions?.length > 0;
    if (!this.data) {
      this.route.navigate['/'];
    }
    this.idOrder = this.data['id_order'];
    this.response = this.data['response'];

    const auxString = JSON.stringify(this.data);
    window.onbeforeunload = function () {
      localStorage.setItem('aux', auxString);
    }
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('jwt_token');
    if (this.user) {
      this.step = 2;
    }
    setTimeout(() => {
      this.intervalId = setInterval(() => {
        this.checkPayStatus();
      }, 2000)
    }, 1000);
  }

  goToStepPay() {

  }

  getMessage(event: any) {
    this.message = event;
  }

  getErrorLogin(event: any) {
    this.errorLogin = event;
  }

  changeOption(event: any, type: any) {
    this.message = '';
    this.registerForm = type == 'register' ? true : false;
    this.titleStepForm = type == 'register' ? 'Crea una cuenta para comenzar' : 'Inicia sesión para comenzar';
  }

  updateUser(event: any) {
    location.reload();
  }

  checkPay(event: any) {
    this.step = 3;
    this.correctPayment = event;
  }

  ngOnDestroy() {
    localStorage.removeItem('aux');
    clearInterval(this.intervalId);
  }

  checkPayStatus() {
    this.apiSales.getStatusPay(this.idOrder).subscribe({
      next: (res) => {
        if (res.status == 1) {
          clearInterval(this.intervalId);
          // Dialog de pago correcto
          // this.apiSales.confirmCheckoutWeb(this.response).subscribe({
          //   next: (res) => {
          //     console.log('Compra realizada correctamente.');
          //   }
          // })
          if (!this.opened) {
            this.opened = true;
            const dialogRef = this.dialog.open(ConfirmPayComponent, {
              panelClass: 'info-dialog',
              disableClose: true,
              data: {
                idOdoo: res.id
              }
            });
            dialogRef.afterClosed().subscribe({
              next: (data) => {
                let url = !this.isAdmin ? '/profile' : '/panel-control/orders/list';
                if (data?.print) {
                  // Comprobar si es gestor y mandar a pedidos
                  let url = !this.isAdmin ? '/profile' : '/panel-control/orders/list';
                  this.route.navigate([url]).then(() => {
                    let text = 'Se han descargado las entradas correctamente.';
                    this.infoDialog(text, false, '');
                  });
                } else {
                  this.infoDialog('El pago se ha realizado correctamente. Podrás consultar las entradas en tu correo electrónico o en el apartado Mis compras de tu perfil.', true, url);
                }
              }
            })
          }
        } else if (res.status != 5) {
          // Dialog de error
          clearInterval(this.intervalId);
          // Cancelar sitios en back
          this.infoDialog('¡Vaya! Ha ocurrido un error al procesar el pago, vuelve a intentarlo más tarde.', true, '/cart-checkout')
          this.cancelPay();
        }
      }, error: (err) => {
        // Dialog de error
        this.infoDialog('¡Vaya! Ha ocurrido un error al procesar el pago, vuelve a intentarlo más tarde.', false, '/cart-checkout')
        clearInterval(this.intervalId);
        this.cancelPay();
      }
    });
  }

  cancelPay() {
    this.apiSales.putStatusPay(this.idOrder, 'cancel').subscribe({
      next: () => {
        console.log('Pago cancelado');
      }, error: (err) => {
        // this.route.navigate(['/']);
      }
    })
  }

  infoDialog(msg: string, exit: boolean, route: string) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: true,
        text: msg,
        btnCancel: false
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && exit) {
        this.route.navigate([route]);
      }
    })
  }

}
