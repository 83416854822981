<div *ngIf="!business; else divInfoBusiness" class="loading-container-panel h-100 w-100 d-flex justify-content-center">
    <div class="container-lottie">
        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
            containerClass="loading-box"></ng-lottie>
    </div>
</div>
<ng-template #divInfoBusiness>
    <div class="info-business d-flex flex-wrap">
        <div class="container-in-page col-8">
            <div class="col-5 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Nombre de la empresa</p>
                </div>
                <div>
                    <p class="m-0 content-business">{{business?.business_name}}</p>
                </div>
            </div>
            <div class="col-5 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">CIF</p>
                </div>
                <div>
                    <p class="m-0 content-business">{{business?.nif}}</p>
                </div>
            </div>
            <div class="col-5 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Nombre visible en Citylok</p>
                    <i class="fal fa-pen pointer-in" *ngIf="admin"
                        (click)="openUpdateBusiness('nameCitylok', 'Nombre visible en Citylok')"></i>
                </div>
                <div>
                    <p class="m-0 content-business">{{business?.name}}</p>
                </div>
            </div>
            <div class="col-5 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Propietario/a</p>
                </div>
                <div>
                    <p class="m-0 content-business">{{owner?.nombre}}</p>
                    <p class="m-0 content-business">{{owner?.email}}</p>
                </div>
            </div>
            <div class="col-5 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Dirección fiscal</p>
                    <i class="fal fa-pen pointer-in" *ngIf="admin"
                        (click)="openUpdateBusiness('address', 'Dirección fiscal')"></i>
                </div>
                <div>
                    <p class="m-0 content-business">{{business?.address}}</p>
                    <p class="m-0 content-business">{{business?.city}}, {{business?.postal_code}}, {{business?.country}}
                    </p>
                </div>
            </div>
            <div class="col-10 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Descripción</p>
                    <i class="fas fa-info-circle icon-primary pointer-in"
                        matTooltip="Descripción que aparecerá en la página de la organización."></i>
                    <i class="fal fa-pen pointer-in" *ngIf="admin"
                        (click)="openUpdateBusiness('description', 'Descripción')"></i>
                </div>
                <div>
                    <p class="m-0 content-business" [innerHTML]="description"></p>
                </div>
            </div>
            <div class="col-10 col-info-business">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Condiciones generales</p>
                    <i class="fas fa-info-circle icon-primary pointer-in"
                        matTooltip="Condiciones generales que se usaran por defecto en la impresión de las entradas."></i>
                    <i class="fal fa-pen pointer-in" *ngIf="admin"
                        (click)="openUpdateBusiness('general_conditions', 'Condiciones generales')"></i>
                </div>
                <div>
                    <p class="m-0 content-business" [innerHTML]="business.general_conditions"></p>
                </div>
            </div>
        </div>
        <div class="col-4 col-image-business">
            <p class="title-image">LOGO DE LA ORGANIZACIÓN</p>
            <div class="container-image" (click)="file.click()">
                <div class="not-image-business">
                    <i class="fa-thin fa-image" *ngIf="!imgControl.value"></i>
                    <img [src]="imgControl.value" alt="Img user" *ngIf="imgControl.value" />
                </div>
                <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
                <div class="circle-add-image">
                    <i class="fa-light fa-plus"></i>
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-divider></mat-divider>
            <div class="container-in-page">
                <div class="d-flex align-items-center title-info-business">
                    <p class="m-0">Datos de facturación</p>
                    <i class="fal fa-lock"></i>
                </div>
                <div class="d-flex col-12">
                    <div class="col-4 col-info-business">
                        <div class="d-flex align-items-center title-billing-business">
                            <p class="m-0">Nº facturación:</p>
                        </div>
                        <div>
                            <p class="m-0 content-business">{{business?.name}}</p>
                        </div>
                    </div>
                    <div class="col-4 col-info-business">
                        <div class="d-flex align-items-center title-billing-business">
                            <p class="m-0">Tipo de IVA:</p>
                        </div>
                        <div>
                            <p class="m-0 content-business">{{+business?.taxes.value | number:'1.0-0'}}%
                                ({{business.taxes.name}})</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex col-12">
                    <div class="col-4 col-info-business">
                        <div class="d-flex align-items-center title-billing-business">
                            <p class="m-0">Tipo de Pago:</p>
                        </div>
                        <div>
                            <p class="m-0 content-business">{{business?.payment_method[0].external ? 'Externo (Redsys)'
                                :
                                business?.payment_method[0]?.name}}
                            </p>
                        </div>
                    </div>
                    <div class="col-4 col-info-business" *ngIf="business?.payment_method[0].external">
                        <div class="d-flex align-items-center title-billing-business">
                            <p class="m-0">Nº de comercio:</p>
                        </div>
                        <div>
                            <p class="m-0 content-business">
                                {{business?.payment_method[0].api_data?.Ds_Merchant_MerchantCode}}</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex col-12">
                    <div class="col-4 col-info-business">
                        <div class="d-flex align-items-center title-billing-business">
                            <p class="m-0">Nº de cuenta:</p>
                        </div>
                        <div>
                            <p class="m-0 content-business">{{business?.iban}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="btn-change-business" (click)="openContactBusiness()" *ngIf="admin">
                <p class="m-0">Solicitar cambios</p>
            </div>
        </div>
    </div>
</ng-template>