// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: "http://127.0.0.1:8000/api",
  chatbot: "https://dev.api.ventas.citylok.com",
  login: "http://127.0.0.1:8000",
  media: "http://127.0.0.1:8000",
  front: "http://127.0.0.1:4200",
  sales: "https://dev.ventas.citylok.com",
  keys: {
    stripe: 'pk_test_51N166hBqYAOLE3PaTb69ojBA3eg12VzPGvx7bUIZRDJyemLYnDc0Mx3ZmlWngWTvf1IMANMcgJRI7E3fpGAPg8fL00gXeQMExy',
    facebook: '632538772064167',
    google: '744858171463-2ipje4876setrcv8j3gemaflacci6mn7.apps.googleusercontent.com'
  },
  debug: true,
  wp_api: "https://wp.citylok.com",
  totem: true,
  user_totem: 'totem_teatroideal@citylok.com',
  pass_totem: 'GFfZ1H6uvpcEyQ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
