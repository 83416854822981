import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-config-tickets',
  templateUrl: './config-tickets.component.html',
  styleUrls: ['./config-tickets.component.scss']
})
export class ConfigTicketsComponent implements OnInit {

  typeDatepicker!: string;
  // Start and End Date
  startStringDate!: any;
  endStringDate!: any;
  startDate!: NgbDate;
  endDate!: NgbDate;

  publishDate!: any;
  publishStringDate!: any;

  hourInit!: any;
  minuteInit!: any;
  hourEnd!: any;
  minuteEnd!: any;

  formConfig!: FormGroup;
  @Input() configIn!: any;
  @Input() fromNew: boolean = false;
  @Input() profile!: any;
  @Output() sendConfig: EventEmitter<any> = new EventEmitter<any>();
  allowCreate: boolean = false;
  @Input() allowExternal: boolean = false;
  external: boolean = false;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<ConfigTicketsComponent>, public formatter: NgbDateParserFormatter,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.profile = this.auth.getProfile();
    this.formConfig = this.formBuilder.group({
      startSales: [],
      endSales: [],
      publishDate: [],
      assumeFees: [true],
      needValidation: [false],
      progPublish: [false],
      maxTickets: [],
    });
    if (this.configIn) {
      this.data = this.configIn;
    }
    if (this.allowExternal) {
      this.formConfig.addControl('informativo', new FormControl(false));
      this.formConfig.addControl('external', new FormControl(false));
      this.formConfig.addControl('url', new FormControl(''));
    }
    if (this.data) {
      if (!this.data.external) {
        if (this.data.startSales) {
          if (typeof (this.data.startSales) == 'string') {
            this.data.startSales = new Date(this.data.startSales);
          }
          if (typeof (this.data.endSales) == 'string') {
            this.data.endSales = new Date(this.data.endSales);
          }
          this.startStringDate = formatDate(this.data.startSales, 'dd/MM/yyyy', 'es');
          this.hourInit = this.data.startSales.getHours();
          this.minuteInit = this.data.startSales.getMinutes();
        }
        if (this.data.endSales) {
          this.endStringDate = formatDate(this.data.endSales, 'dd/MM/yyyy', 'es');
          this.hourEnd = this.data.endSales.getHours();
          this.minuteEnd = this.data.endSales.getMinutes();
        }
        if (this.data.publishDate) {
          this.publishStringDate = formatDate(this.data.publishDate, 'dd/MM/yyyy', 'es');
        }
      }
      if (this.data.external) {
        this.allowExternal = true;
        this.external = this.data.external;
        this.formConfig.addControl('informativo', new FormControl(false));
        this.formConfig.addControl('external', new FormControl(this.data.external));
        this.formConfig.addControl('url', new FormControl(this.data.url));
      }
      this.formConfig.setValue(this.data);
    }
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS') {
        this.allowCreate = true;
      }
    });
  }

  openDatePicker(type: string) {
    if (this.allowCreate) {
      if (this.typeDatepicker) {
        this.typeDatepicker = null;
        return;
      }
      this.typeDatepicker = type;
    }
  }

  saveDate(event: any) {
    if (event) {
      const date = new Date(this.formatter.format(event));
      date.setHours(0);
      date.setMinutes(0);
      if (this.typeDatepicker == 'start') {
        this.startDate = event;
        this.formConfig.controls['startSales'].setValue(date);
        this.startStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'end') {
        this.endDate = event;
        this.formConfig.controls['endSales'].setValue(date);
        this.endStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'auto') {
        this.publishDate = event;
        this.formConfig.controls['publishDate'].setValue(date);
        this.publishStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
    }
    this.typeDatepicker = null;
  }

  saveConfig() {
    if (this.hourInit && this.minuteInit != null) {
      const dateInit = this.formConfig.value.startSales;
      dateInit.setHours(this.hourInit);
      dateInit.setMinutes(this.minuteInit);
      this.formConfig.value.startSales = dateInit;
    }

    if (this.hourEnd && this.minuteEnd != null) {
      const dateEnd = this.formConfig.value.endSales;
      dateEnd.setHours(this.hourEnd);
      dateEnd.setMinutes(this.minuteEnd);
      this.formConfig.value.endSales = dateEnd;
    }
    if (!this.fromNew) {
      this.dialogRef.close(this.formConfig.value);
    } else {
      this.sendConfig.emit(this.formConfig.value);
    }
  }

  changeHourInit(event: any) {
    this.hourInit = event;
  }

  changeMinuteInit(event: any) {
    this.minuteInit = event;
  }

  changeHourEnd(event: any) {
    this.hourEnd = event;
  }

  changeMinuteEnd(event: any) {
    this.minuteEnd = event;
  }

}
