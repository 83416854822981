import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-card-validate',
  templateUrl: './card-validate.component.html',
  styleUrls: ['./card-validate.component.scss']
})
export class CardValidateComponent implements OnInit {

  movilUser: any;
  codeSMS: any;

  isSended: boolean = false;
  msg!: any;

  constructor(private userService: UserService, private dialogRef: MatDialogRef<CardValidateComponent>) { }

  ngOnInit(): void {
  }

  validateNumber() {
    if (this.movilUser) {
      this.userService.sendSMS('34'+this.movilUser).subscribe({
        next: (data) => {
          if (data) {
            this.isSended = true;
          }
        },
        error: (err) => this.msg = '¡Vaya! Se ha producido un error inesperado, vuelve a intentarlo más tarde.'
      })
    }
  }

  validateCode() {
    if (this.codeSMS) {
      const formData = { movil: this.movilUser, code: this.codeSMS };
      this.userService.validateSMS(formData).subscribe({
        next: (data) => {
          if (data) {
            this.msg = '';
            this.dialogRef.close('validate');
          }
        },
        error: (err) => this.msg = '¡Vaya! Se ha producido un error inesperado, vuelve a intentarlo más tarde.'
      })
    }
  }

}
