<div class="container login-page">
    <div class="loading-container" *ngIf="loadingLogin">
        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}" containerClass="loading-box"></ng-lottie>
    </div>
    <div class="w-100 justify-content-end item-small" mat-dialog-close *ngIf="fromSeats">
        <i class="btn-close-map fa-regular fa-times"></i>
    </div>
    <div class="container-login" [class.fromSeats]="fromSeats">
        <p class="h2 title-login">Iniciar sesión para continuar</p>
        <app-warning *ngIf="previousRoute!==null || errorLogin" [text]="redirectMsg"></app-warning>
        <div class="card-login">
            <app-login-card [fromSeats]="fromSeats" (redirectMsg)="getRedirect($event)"
                (registerBtn)="goRegister($event)" (errorMsg)="getErrorLogin($event)" (correctForm)="validate()"
                (loadingLogin)="loadingForm()"></app-login-card>
        </div>
    </div>
    <p class="info-publish" [class.fromSeats]="fromSeats"><i class="fa-light fa-lock"></i>Nunca publicaremos nada sin tu
        permiso</p>
</div>