import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { EventosService } from 'src/app/services/eventos.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-reports-event',
  templateUrl: './reports-event.component.html',
  styleUrls: ['./reports-event.component.scss']
})
export class ReportsEventComponent implements OnInit {

  idEvent!: any;
  event!: any;

  ticketsSold!: any;
  loaded: boolean = false;
  totalReaded: number = 0;
  displayedColumns: string[] = ['actions', 'date', 'time', 'sold', 'readed'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  business!: any;
  loadingReport: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private panelAdminService: PanelAdminService,
    private dialog: MatDialog, private eventService: EventosService, private reportsService: ReportsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.eventService.getEventById(this.idEvent).subscribe({
          next: (res: any) => {
            this.event = res;
            this.eventService.getInfoSalesByEvent(this.idEvent).subscribe({
              next: (res: any) => {
                this.ticketsSold = res.data.tickets;
                this.totalReaded = res.data.totalReaded || 0;
                this.dataSource = new MatTableDataSource(this.ticketsSold);
                this.loaded = true;
              }
            });
          }
        });
      }
    });
  }

  downloadPdf() {
    let eventSessions = [];
    let auxDates = this.event.fechas.filter(value => value.activo);
    let auxHours = this.event.horarios.filter((value) => value.activo);

    auxDates.forEach(date => {
      if (!auxHours || auxHours.length > 0) {
        auxHours.forEach(hour => {
          eventSessions.push({ id: this.event.id, multidate: this.event.multidate, name: this.event.titulo, idDate: date.id, date: date.fechaInicio, idHour: hour.id, hour: hour.horaInicio });
        });
      } else {
        eventSessions.push({ id: this.event.id, multidate: this.event.multidate, name: this.event.titulo, idDate: date.id, date: date.fechaInicio, idHour: null, hour: null });
      }
    });
    const payload = {
      events: eventSessions,
      dates: null,
      format: 'pdf',
      type: 'global',
      method: null,
      reportsgroup: null
    }

    this.reportsService.downloadReportFile(this.business?.id, payload).subscribe({
      next: (res) => {
        if (res) {
          const linkSource = res;
          const type = 'application/pdf';
          const windowOpen = '_blank';
          const base64WithMime = `data:${type};base64,${linkSource}`;

          const newTab = window.open('', '_blank');
          if (newTab) {
            newTab.document.body.innerHTML = `
              <iframe src="${base64WithMime}" frameborder="0" style="border:0; width:100%; height:100%;" allowfullscreen></iframe>
            `;
            newTab.document.title = 'Informe';
          }
        }
        this.loadingReport = false;
      }, error: (err) => {
        this.infoDialog('¡Vaya! Algo ha fallado, no se ha podido descargar el informe. Vuelve a intentarlo más tarde.');
        this.loadingReport = false;
      }
    })
  }

  infoDialog(msg: string, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

}
