<div class="user-permission-page">
    <!-- <div class="btn-back" (click)="goBack()">
        <i class="fal fa-arrow-left"></i>
        <span>Volver</span>
    </div> -->
    <div class="user-permission">
        <div class="title-user-permission d-flex justify-content-between align-items-center">
            <h1 class="m-0">{{infoUserPermission?.nombre}}</h1>
            <div class="btn-create btn-save m-0" (click)="savePermission()" *ngIf="admin && !isOwner">
                <p class="m-0">Guardar</p>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="info-personal d-flex info-padding">
            <div class="col-4">
                <p class="m-0 title-section">Cuenta</p>
                <p class="info-text">{{infoUserPermission?.email}}</p>
            </div>
            <div class="col-4">
                <p class="m-0 title-section">Último inicio de sesión</p>
                <p class="info-text">{{now | date: 'dd-MM-y'}}</p>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="info-funtions info-padding">
            <p class="title-section">Funciones</p>
            <div class="checks-row-two">
                <div *ngFor="let function of (companyInfo|async)?.roles">
                    <mat-checkbox color="primary" *ngIf="admin" (change)="changeFunction(function.id, $event.checked)"
                        [checked]="rolesUser?.includes(function.id)">{{function.name}}</mat-checkbox>
                    <span *ngIf="!admin">{{function.name}}</span>
                </div>
            </div>
        </div>
        <!-- <mat-divider></mat-divider>
        <div class="info-extra info-padding">
            <div class="d-flex align-items-center title-section pointer-in" style="gap: 30px;">
                <p class="title-section m-0" (click)="openPermission=!openPermission">Permisos adicionales</p>
                <i class="fal fa-xs" [class]="openPermission ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </div>
            <div *ngIf="openPermission">
                <div *ngFor="let addition of permissionExtra">
                    <mat-checkbox color="primary">{{addition.name}}</mat-checkbox>
                    <div class="extra-in" *ngFor="let extra of addition.extra">
                        <mat-checkbox color="primary">{{extra.name}}</mat-checkbox>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <mat-divider></mat-divider>
        <div class="info-active info-padding">
            <p class="title-section">Permisos</p>
            <div class="checks-column-two">
                <div *ngFor="let addition of permissionExtra">
                    <p class="m-0">{{addition.name}}</p>
                    <mat-checkbox color="primary">{{addition.name}}</mat-checkbox>
                </div>
            </div>
        </div> -->
        <mat-divider></mat-divider>
        <div *ngIf="(companyInfo|async)?.places?.length > 0">
            <div class="places info-padding">
                <p class="title-section">Espacios</p>
                <div class="checks-row-two">
                    <mat-checkbox color="primary" [(ngModel)]="allPlaces"
                        *ngIf="(companyInfo|async)?.places.length > 1 && admin">Todos</mat-checkbox>
                    <span *ngIf="!admin && allPlaces">Todos</span>
                    <div *ngFor="let place of (companyInfo|async)?.places">
                        <mat-checkbox color="primary" *ngIf="admin" (change)="changePlace(place.id, $event.checked)"
                            [checked]="placesUser?.includes(place.id) || allPlaces">{{place.name}}</mat-checkbox>
                        <span *ngIf="!admin">{{place.name}}</span>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <!-- <div *ngIf="(companies | async)?.length > 0">
        <div class="companies info-padding">
            <p class="title-section">Organizaciones</p>
            <div class="checks-row-two">
                <div *ngFor="let company of companies | async">
                    <mat-checkbox color="primary">{{company.name}}</mat-checkbox>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div> -->
        <div class="btns-footer link-open info-padding" (click)="deleteGestor()" *ngIf="admin && !isOwner">
            <span>Eliminar gestor</span>
        </div>
    </div>
</div>