<div class="card-change-business">
    <p class="title-change">Actualizar Organización - {{nameValue}}</p>

    <div>
        <form [formGroup]="changeForm">
            <div class="bloque-change-input container-form d-flex align-items-center password-input"
                *ngIf="valueChange == 'nameCitylok'">
                <input class="w-100" type="text" placeholder="Nombre" formControlName="name" required>
            </div>
            <div *ngIf="valueChange == 'address'">
                <div class="bloque-change-input input-address container-form d-flex align-items-center password-input">
                    <textarea #textarea class="w-100" type="textarea" placeholder="Dirección fiscal" maxlength="2500"
                        formControlName="address"></textarea>
                </div>
                <div class="bloque-change-input container-form d-flex align-items-center password-input">
                    <input class="w-100" type="text" placeholder="Ciudad" formControlName="city" required>
                </div>
                <div class="bloque-change-input container-form d-flex align-items-center password-input">
                    <input class="w-100" type="text" placeholder="Código postal" formControlName="postal_code" required>
                </div>
                <div class="bloque-change-input container-form d-flex align-items-center password-input">
                    <input class="w-100" type="text" placeholder="Provincia" formControlName="country" required>
                </div>
                <div class="bloque-change-input container-form d-flex align-items-center password-input">
                    <input class="w-100" type="text" placeholder="Estado" formControlName="state" required>
                </div>
            </div>

            <angular-editor *ngIf="valueChange == 'description'" #edit id="2"
                formControlName="description" [config]="editorConfigBusiness">
            </angular-editor>

            <angular-editor *ngIf="valueChange == 'general_conditions'" #edit id="2"
                formControlName="general_conditions" [config]="editorConfigBusiness">
            </angular-editor>

            <div class="d-flex justify-content-center btn-change">
                <button class="primary-button solid w-100" style="min-height: 40px;"
                    (click)="changeBusiness()">Actualizar</button>
            </div>
        </form>
    </div>
</div>