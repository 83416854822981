<div class="router-venue">
    <div class="d-flex info-panel">
        <app-menu-venue [tabSelected]="tabSelected" [idVenue]="idVenue" (changeTab)="changeMenuEvent($event)"></app-menu-venue>
        <div class="venue-panel-container page-panel">
            <div class="card-white-panel">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex title-menu-page">
                        <h2><span>{{titleTab}}</span>
                            <mat-divider [vertical]="true"></mat-divider>
                            <span>{{venue?.name}}</span>
                        </h2>
                    </div>
                    <div *ngIf="mapPage && actionMap" style="margin-right: 20px;">
                        <div class="btns-create">
                            <div class="btn-create btn-action btn-create-border m-0">
                                <!-- <i class="fal fa-plus-circle"></i> -->
                                <p class="m-0">Guardar</p>
                            </div>
                            <div class="btn-create btn-action m-0">
                                <!-- <i class="fal" [class]="actionMap == 'edit' ? 'fa-floppy-disk' : 'fa-circle-xmark'"></i> -->
                                <p class="m-0">{{actionMap == 'edit' ? 'Editar mapa de butacas' : 'Cancelar'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>