<div class="detail-event">
    <div class="d-flex align-items-center title-edit">
        <p class="m-0">Entradas por Nivel</p>
        <i class="fal fa-pen pointer-in" *ngIf="profile?.admin"></i>
    </div>
    <div class="container-levels">
        <div class="table-detail table-panel">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28">
                <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef><b>Nivel</b></th>
                    <td mat-cell *matCellDef="let level">
                        <div class="d-flex info-level">
                            <span class="link-titulo">{{level.name}}</span>
                            <div class="d-flex open-ticket pointer-in" (click)="level.openTickets = !level.openTickets">
                                <i class="fal fa-circle-plus"></i>
                                <span>{{level.openTickets ? 'Cerrar' : 'Ver'}} tipos</span>
                            </div>
                        </div>
                        <div *ngIf="level.openTickets">
                            <div class="d-flex align-items-center item-ticket" *ngFor="let ticket of level.ticketsLevel">
                                <p class="m-0">{{ticket.name}}</p>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef><b>Precio</b></th>
                    <td mat-cell *matCellDef="let level">
                        Desde {{level.ticketsLevel[0].price}}€
                        <div class="item-ticket" style="text-align: center;" *ngIf="level.openTickets">
                            <p class="m-0" *ngFor="let ticket of level.ticketsLevel">{{ticket.price}}€</p>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="sales">
                    <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
                    <td mat-cell *matCellDef="let level">-</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <!-- <div class="cards-info-event">
            <div class="item-card-info">
                <p class="title-card">Nº de Entradas</p>
                <div class="d-flex justify-content-between">
                    <div class="item-number">
                        <p class="m-0">Vendidas</p>
                        <p class="m-0 number-card">127</p>
                    </div>
                    <div class="item-number">
                        <p class="m-0">Disponibles</p>
                        <p class="m-0 number-card">359</p>
                    </div>
                </div>
            </div>
            <div class="item-card-info">
                <p class="title-card">Ventas</p>
                <div class="d-flex justify-content-center">
                    <div class="item-number">
                        <p class="m-0">Vendido</p>
                        <p class="m-0 number-card">1289€</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>