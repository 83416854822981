import { Component } from '@angular/core';

@Component({
  selector: 'app-order-event',
  templateUrl: './order-event.component.html',
  styleUrls: ['./order-event.component.scss']
})
export class OrderEventComponent {

}
