import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { FavoritosService } from 'src/app/services/favoritos.service';

@Component({
  selector: 'app-events-fav',
  templateUrl: './events-fav.component.html',
  styleUrls: ['./events-fav.component.scss']
})
export class EventsFavComponent implements OnInit {

  events: any[] = [];
  @Input() last: boolean = false;

  loadingPage = false;

  // Infinity scroll
  page: number = 1;
  items: number = 12;
  finishScroll: boolean = false;
  @ViewChild('containerScroll', {static: false}) containerScroll: ElementRef;
  modified: number = 0;

  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };
  constructor(private favoritosService: FavoritosService) { }

  ngOnInit(): void {
    this.loadingPage = true;
    if(window.innerWidth < 1800 && window.innerWidth >= 1400) {
      this.items = 10;
    }
    this.initLoading();
    this.favoritosService.modObs.subscribe(res => {
      if(res) {
        const index = this.events.findIndex(value => value.id = res);
        if (index != -1) {this.events.splice(index, 1)}
        this.favoritosService.setMod();
      }
    })
  }

  initLoading() {
    this.page = 1;  
    this.finishScroll = false;
    this.events = [];
    this.loadingPage = true;
    setTimeout(() => {
        this.getMoreEvents();
    }, 500); 
  }

  getMoreEvents() {
    if(!this.last) {
      this.favoritosService.getFavoritos(this.items, this.page).subscribe(favs => {
        this.getEventsPaginator(favs);
      });
    } else {
      this.favoritosService.getFavoritosLast(this.items, this.page).subscribe(favs => {
        this.getEventsPaginator(favs);
      });
    }
  }

  getEventsPaginator(data: any) {
    let eventos = data;
    if(!eventos[0]) {
      this.finishScroll = true;
      this.loadingPage = false;
      return;
    }
    if(eventos.length < this.items) {
      this.finishScroll = true;
    }
    this.events = this.events.concat(eventos);
    this.loadingPage = false;
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if(!this.finishScroll && !this.loadingPage) {
      const element = this.containerScroll.nativeElement.getBoundingClientRect();
      let bottomShown = element.bottom <= window.innerHeight;
      if(bottomShown) {
        this.page++;
        this.loadingPage = true;
        setTimeout(() => {
          this.getMoreEvents();
        }, 2000);
      }
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.items = 12;
    if(window.innerWidth < 1800 && window.innerWidth >= 1400) {
      this.items = 10;
    }
    this.recalculateItemsView();
  }

  recalculateItemsView() {
    const res = this.events.length % this.items;
    if(res > 0) {
      if(this.events.length > res) {
        this.events.splice(this.events.length - res, res);
        this.page = this.events.length / this.items + 1;
      } else {
        this.page = 1;
        this.getMoreEvents();
      }
    }
  }

  onScrollToTop() {
    window.scrollTo(0,0);
  }

}
