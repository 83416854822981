import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, first } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { Observable, of } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  constructor(private http: HttpClient, private common: CommonService) { }

  getAllCategories() {
    const url = `${ AppSettings.API_URI }/categorias`;
    return this.common.getDataHydra(url);
  }

  getCategoriesHome() {
    const url = `${ AppSettings.API_URI }/categorias?exists[eventos]=true&showhome=true`;
    return this.common.getDataHydra(url);
  }

  getCategoriesWithEvents() {
    const url = `${ AppSettings.API_URI }/categorias?exists[eventos]=true`;
    return this.common.getDataHydra(url);
  }

  getCategoriabyId(id:number) {
    const url = `${ AppSettings.API_URI }/categorias/${id}`;
    return this.common.getData(url);
  }

  getCategoriaBySlug(slug: string) {
    const url = `${ AppSettings.API_URI }/categorias?slug=${slug}`;
    return this.common.getDataHydra(url);
  }

  postCategoria(categoria:any) {
    const url = `${ AppSettings.API_URI }/categorias`;
    return this.common.postData(url, categoria);
  }

  putCategoria(categoriaId: number, categoria:any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/categorias/${ categoriaId }`;
    return this.http.put<any>(url, categoria ,formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  deleteCategoria(categoriaId: number) {
    const url = `${ AppSettings.API_URI }/categorias/${ categoriaId }`;
    return this.http.delete<any>( url ).pipe(
      map(data => {
        if(data) {
          return of(true);
        } else {
          return of(false);
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }

  uploadFilePut(catImage) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/categoria_image`;
    return this.http.put<any>(url, catImage, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }
  uploadFilePost(catImage) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/categoria_image`;
    return this.http.post<any>(url, catImage, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

}
