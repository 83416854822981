<div class="container container-search container-results-cards">
  <p class="title-page">Resultados de búsqueda</p>
  <div class="d-grid d-lg-flex justify-content-between align-items-start filters-container">
    <div class="filters-selected col-12 col-lg-6 col-xl-8 order-1">
      <div class="content-filter" *ngIf="searchFilter"><i class="fa-light fa-times fa-sm icon-delete pointer-in" (click)="deleteFilter(searchFilter)"></i>{{ searchFilter }}<i class="fa-light fa-search icon-search fa-sm"></i></div>
      <div *ngFor="let filter of filtros | async">
        <div class="content-filter" *ngIf="filter.name">
          <i class="fa-light fa-times fa-xs icon-delete pointer-in" (click)="deleteFilter(filter)"></i>
          {{ filter.type != 'price' ? filter.name : (filter.min+'-'+filter.max+' €') }}
          <i *ngIf="filter.type == 'order'" class="fa-light fa-arrow-up-arrow-down icon-search fa-sm"></i>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center col-12 col-lg-6 col-xl-4 order-0 order-lg-1">
      <span (click)="viewType='grid'" class="pointer-in option-view" [class]="viewType == 'grid' ? 'text-primary' : 'text-light'">Vista grid <i class="fa-light fa-grid"></i></span>
      <span (click)="viewType='map'" class="pointer-in option-view" [class]="viewType == 'map' ? 'text-primary' : 'text-light'">Mapa <i class="fa-light fa-map"></i></span>
      <div class="pointer-in filter-btn d-flex align-items-center justify-content-center" (click)="openFilterDialog()">
        <span>Filtros</span>
        <i class="fal fa-sliders-h text-primary"></i>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center card-not-results" *ngIf="!results && viewType == 'grid'">
    <i class="fa-light fa-circle-info"></i>
    <p class="m-0">¡Has visto todos los loks! Modifica los filtros para ampliar los resultados de búsqueda.</p>
  </div>
  
  <div *ngIf="viewType == 'grid'">
    <app-infinity-scroll (results)="existsResults($event)"></app-infinity-scroll>
  </div>
  <div *ngIf="viewType == 'map'">
    <app-list-map (results)="existsResults($event)" [map]="true"></app-list-map>
  </div>
</div>
