<div class="card-change-business">
    <p class="title-change">Actualizar Espacio - {{nameValue}}</p>

    <div>
        <form [formGroup]="changeForm">
            <div class="bloque-change-input container-form d-flex align-items-center password-input"
                *ngIf="valueChange == 'name'">
                <input class="w-100" type="text" placeholder="Nombre" formControlName="name" required>
            </div>
            <div class="bloque-change-input container-form d-flex align-items-center password-input"
                *ngIf="valueChange == 'membershipTitle'">
                <input class="w-100" type="text" placeholder="Nombre de los miembros" formControlName="membershipTitle" required>
            </div>
            <div class="bloque-change-input container-form d-flex align-items-center password-input"
                *ngIf="valueChange == 'subscriptionText'">
                <input class="w-100" type="text" placeholder="Texto de suscripción" formControlName="subscriptionText" required>
            </div>
            <div *ngIf="valueChange == 'description'">
                <div class="bloque-change-input input-address container-form d-flex align-items-center password-input">
                    <textarea #textarea class="w-100" type="textarea" placeholder="Descripción" maxlength="2500"
                        formControlName="description"></textarea>
                </div>
            </div>
            <div *ngIf="valueChange == 'location'">
                <div class="bloque-change-input container-form d-flex align-items-center password-input">
                    <input class="w-100" type="text" placeholder="Localización" formControlName="location" required>
                </div>
                <!-- <div class="bloque-change-input container-form d-flex align-items-center password-input">
                    <input class="w-100" type="text" placeholder="Ciudad" formControlName="city" required>
                </div> -->
            </div>

            <div class="d-flex justify-content-center btn-change">
                <button class="primary-button solid w-100" style="min-height: 40px;"
                    (click)="changeBusiness()">Actualizar</button>
            </div>
        </form>
    </div>
</div>