import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageWpService } from 'src/app/services/page-wp.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  contentPage!: SafeHtml;

  constructor(private pageWpService: PageWpService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.pageWpService.getStaticBySlug('privacidad').subscribe({
      next: (res) => {
        let data = res[0];
        this.contentPage = this.sanitizer.bypassSecurityTrustHtml(
          data.content.rendered
        );
      }
    })
  }

}
