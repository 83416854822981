import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-price-min',
  templateUrl: './dialog-price-min.component.html',
  styleUrls: ['./dialog-price-min.component.scss']
})
export class DialogPriceMinComponent {

  minPrice!: any;

  constructor() { }

  save() {

  }
}
