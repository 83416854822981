import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTotemComponent } from './header-totem/header-totem.component';
import { FooterTotemComponent } from './footer-totem/footer-totem.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { HomeTotemComponent } from './home-totem/home-totem.component';
import { CardsModule } from '../bloques/cards.module';
import { LottieModule } from 'ngx-lottie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfoTicketComponent } from './info-ticket/info-ticket.component';
import { LinkEmailComponent } from './link-email/link-email.component';
import { BtnsEventTotemComponent } from './btns-event-totem/btns-event-totem.component';
import { QRCodeModule } from 'angularx-qrcode';


@NgModule({
  declarations: [
    HeaderTotemComponent,
    FooterTotemComponent,
    HomeTotemComponent,
    InfoTicketComponent,
    LinkEmailComponent,
    BtnsEventTotemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    SharedModule,
    CardsModule,
    LottieModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule
  ],
  exports: [
    HeaderTotemComponent,
    FooterTotemComponent,
    BtnsEventTotemComponent
  ]
})
export class TotemModule { }
