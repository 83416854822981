import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-events-draft',
  templateUrl: './events-draft.component.html',
  styleUrls: ['./events-draft.component.scss']
})
export class EventsDraftComponent implements OnInit {

  listEvents!: Observable<any[]>;
  page: number = 1;
  totalPages: number = 2;

  urlImages: string = AppSettings.MEDIA_URI + '/images/events';

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.getEvents();
  }

  getEvents() {
    this.listEvents = this.userService.getDraftsEvents(this.page).pipe();
  }

  editEvent(idEvent: number) {
    this.router.navigate(['/draft/edit', idEvent]);
  }

  goToPage(event: any) {
    this.page = event;
    this.getEvents();
  }

}
