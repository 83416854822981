import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {


  @Input() text: string = "";
  @Input() backgroundColor: string = "#F1F1F1";
  @Input() iconColor: string = "#FF4D80";
  @Input() icon: string = "fa-circle-info";


  constructor() { }

  ngOnInit(): void {
  }

}
