import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(private http: HttpClient) { }

  sendInvitation(idCompany: number, payload: any) {
    const url = `${AppSettings.API_URI}/company/add/user/${idCompany}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  resendInvitation(idInvite: number) {
    const payload = {
      idInvite: idInvite
    };
    const url = `${AppSettings.API_URI}/resend/invite/user`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  verifyInvite(key: any, manager: any, payload: any) {
    let url = `${AppSettings.API_URI}/verify/invitation/control?key=${key}`;
    if(manager) {
      url += `&man=${manager}`;
    }
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  acceptInviteUser(key: any) {
    const url = `${AppSettings.API_URI}/company/accept/activeUser/${key}`;
    return this.http.put<any>(url, {}).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  acceptInviteNewUser(key: any, payload: any) {
    const url = `${AppSettings.API_URI}/company/accept/inactiveUser/${key}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
  
}
