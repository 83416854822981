<div class="container content-terms">
  <h3 class="title-terms" style="color: var(--color-primary); ">Política de Privacidad y Protección de Datos</h3>
  <p>De conformidad con el Reglamento (UE) 2016/679, de 27 de abril de 2016, relativo a la protección de las personas
    físicas en lo que respecta al tratamiento de los datos personales y la Ley Orgánica 3/2018, de 5 de diciembre, de
    Protección de Datos Personales y garantía de los derechos digitales, el responsable del tratamiento informa que los
    datos de carácter personal de usuarios recabados a través del portal web, se incorporarán y tratarán en un fichero
    automatizado de su propiedad que consta en su Registro de Actividades de Tratamiento, y que será gestionado
    exclusivamente con la finalidad de administrar la relación con los interesados.</p>

  <p>Al pulsar el botón “enviar” (o equivalente) el usuario consiente el tratamiento de sus datos por parte del titular
    y declara que la información y los datos que facilita son verdaderos, exactos, completos y actualizados, siendo
    responsable de cualquier daño o perjuicio que pudiera ocasionarse como consecuencia del incumplimiento de tal
    obligación. Para que la información facilitada esté siempre actualizada y no contenga errores, el usuario deberá
    comunicar las modificaciones de sus datos de carácter personal que se vayan produciendo, así como las
    rectificaciones de datos erróneos en caso de que detecte alguno.</p>

  <p>Citylok procesa los siguientes datos personales de los usuarios: nombre, correo electrónico y, en caso de tratarse
    de un usuario verificado, el número de teléfono. Estos datos son procesados con el propósito de crear y mantener las
    cuentas de usuario.</p>

  <p>El titular se compromete a que los datos de carácter personal solicitados serán los estrictamente necesarios para
    llevar a cabo el servicio demandado. Igualmente, como responsable del fichero, se obliga a mantener el secreto y la
    confidencialidad sobre los datos de carácter personal que le sean facilitados, adoptando para ello todas las medidas
    de seguridad necesarias que eviten su pérdida, modificación sin consentimiento o accesos no autorizados, de acuerdo
    con la normativa citada.</p>

  <p>Para solicitar la eliminación de una cuenta, el usuario debe enviar una petición al titular. Una vez recibida la
    petición, el titular se compromete a eliminar la cuenta en un plazo de 48 horas. En caso de que existan entradas o
    eventos activos, los administradores se pondrán en contacto con los usuarios para determinar si desean eliminar
    también esas entradas o eventos, o si prefieren mantenerlos.</p>

  <p>Por otro lado, en base a lo dispuesto en la Ley 34/2002, de 11 de Julio de Servicios de la Sociedad de la
    Información y de Comercio Electrónico, el titular se compromete a no enviar publicidad a través del correo
    electrónico sin haber recabado antes la expresa autorización del destinatario.</p>
  <p>Esta política de privacidad se aplica al tratamiento de datos de los usuarios.</p>
  <p class="subtitle-bold">
    <span class="bold-title">Identidad:</span> KREARE DIGITAL S.L.
  </p>
  <p class="subtitle-bold">
    <span class="bold-title">C.I.F.:</span> B-05338504
  </p>
  <p class="subtitle-bold">
    <span class="bold-title">Dirección Postal:</span> Calle Alfonso VI nº 4, bajo, de Logroño (La Rioja).
  </p>
  <p class="subtitle-bold">
    <span class="bold-title">Teléfono:</span> <a href="tel:+34941135052"> 941135052</a>
  </p>
  <p class="subtitle-bold">
    <span class="bold-title">Email:</span> <a href="mailto:'hola@citylok.com'"> hola@citylok.com</a>
  </p>


</div>