import { Component, OnInit } from '@angular/core';
import { EventosService } from 'src/app/services/eventos.service';
import { CommonService } from 'src/app/services/common.service';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { BehaviorSubject, flatMap, forkJoin, map, observable, Observable, of } from 'rxjs';
import { CategoriasService } from 'src/app/services/categorias.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewLokComponent } from '../new-lok/new-lok/new-lok.component';
import { MatDialog } from '@angular/material/dialog';
import { StaticService } from 'src/app/services/static.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoCardComponent } from '../shared/info-card/info-card.component';
import { AppSettings } from 'src/app/app-settings';
import { ChatbotComponent } from '../shared/chatbot/chatbot.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  selectLugar: any;
  explorar: Observable<any[]>;
  destacados: Observable<any[]>;
  city!: string;
  ciudades: any[];
  firstCities: any[];
  eventosCategoriasHome: any[] = [];
  profile!: any;
  cercanas: any[] = [];

  private tituloExplorar = new BehaviorSubject<string>('Explorar');
  public tituloObs = this.tituloExplorar.asObservable();
  focusActive: boolean = false;
  placeholder: string = 'Selecciona lugar';

  constructor(private eventosService: EventosService, private commonService: CommonService, private ciudadesService: CiudadesService,
    private categoriasService: CategoriasService, private filterService: FilterService, private dialog: MatDialog, private staticService: StaticService,
    private auth: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute) {
    // this.auth.getUsersMe().subscribe({
    //   next: result => { if (result.email) { this.profile = result } }
    // });
    this.profile = this.auth.getProfile();
    let cityId = localStorage.getItem('city');
    this.commonService.setCity(cityId)
  }

  ngOnInit(): void {
    localStorage.removeItem('aux');
    this.activatedRoute.queryParams.subscribe(
      (data) => {
        if (data.email && data.token) {
          this.auth.validateEmail(data.email, data.token).subscribe({
            next: (data) => {
              this.dialog.open(InfoCardComponent, {
                panelClass: 'info-dialog',
                data: {
                  icon: false,
                  text: 'Cuenta verificada correctamente. Inicia sesión para continuar.',
                  btnCancel: false
                }
              });
            },
            error: (err) => {
              this.dialog.open(InfoCardComponent, {
                panelClass: 'info-dialog',
                data: {
                  icon: true,
                  text: '¡Algo ha fallado! Inténtelo de nuevo de más tarde.',
                  btnCancel: false
                }
              });
            }
          })
        }
      }
    )
    this.commonService.cityStorage.subscribe(
      currentCity => {
        this.eventosCategoriasHome = [];
        if (currentCity) {
          this.ciudadesService.getCiudadbyId(currentCity).subscribe((res: any) => {
            this.city = res?.nombre
            this.tituloExplorar.next('Explorar en ' + res?.nombre);
          })
        } else {
          this.city = null;
          this.selectLugar = null;
          this.tituloExplorar.next('Explorar');
        }
        this.getData(currentCity);
      }
    );
    this.ciudadesService.getCiudades().subscribe(res => { this.ciudades = res; this.firstCities = res; });
    this.filterService.resetAllFilters();
    localStorage.removeItem('search');
    this.commonService.setCategory(null);
  }

  goToLink() {
    this.router.navigate(['/results']);
  }

  getData(ciudad: number) {
    this.explorar = this.eventosService.getEventosExplorar(ciudad).pipe();
    this.destacados = this.eventosService.getEventosBestClose(ciudad).pipe();

    // Categorias home
    this.categoriasService.getCategoriesHome().subscribe({
      next: (data) => {
        this.eventosCategoriasHome = [];
        if (data) {
          data.forEach(element => {
            let events = this.eventosService.getEventosCategoryClose(element.id, ciudad).pipe();
            this.eventosCategoriasHome.push({ titulo: element.nombre, slug: element.slug, events: events });
          });
        }
      },
      error: (e) => { if (AppSettings.DEBUG) { console.log(e) } },
      // complete: () => console.log(this.eventosCategoriasHome)
    });
  }

  async setCity(city: any) {
    if (city?.id) {
      this.city = city.nombre;
      this.commonService.setCity(city.id)
    }
    if (city) {
      if (!city.id) {
        await this.ciudadesService.getCiudadByName(city).subscribe(
          res => {
            this.ciudades = res;
          }
        )
      }
    } else {
      this.ciudades = this.firstCities;
    }
  }

  resetCity() {
    this.commonService.setCity(null);
  }

  newLok() {
    if (this.profile) {
      const dialogRef = this.dialog.open(NewLokComponent, {
        panelClass: 'new-lok-dialog',
        disableClose: true
      })
    } else {
      this.staticService.setFrom('subir');
      this.router.navigate(['/login']);
    }
  }

  displayCity(city: any): string {
    return city ? city.nombre : '';
  }

  openChatbot() {
    const dialogRef = this.dialog.open(ChatbotComponent, {
      panelClass: ['dialog-chatbot', 'not-padding'],
      position: {
        bottom: '0px',
        right: '0px'
      }
    })
  }

}
