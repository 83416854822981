import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app-settings';
import { QrCodeComponent } from 'src/app/components/bloques/qr-code/qr-code.component';

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss']
})
export class TicketsListComponent implements OnInit {

  @Input() compra!: any;
  @Input() type!: any;
  @Output() closeAction: EventEmitter<boolean> = new EventEmitter();
  urlEvents: string = AppSettings.MEDIA_URI + '/images/events/';

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  closeTickets() {
    this.closeAction.emit(this.type);
  }

  openQr(ticket: any) {
    this.dialog.open(QrCodeComponent, {
      panelClass: 'info-dialog',
      data: {localizador: ticket.localizador}
    });
  }

}
