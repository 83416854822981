import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { ApiSalesService } from 'src/app/services/api-sales.service';
import { BusinessService } from 'src/app/services/business.service';
import { PlacesService } from 'src/app/services/places.service';
import { ReportsService } from 'src/app/services/reports.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent {

  business!: any;
  loadedFilters: boolean = false;

  // Fechas
  datesRange!: any;
  stringDate: string = '';
  openDatePicker: boolean = false;
  dateFilter!: any;

  // Espacios
  openPlacesList: boolean = false;
  placesEnabled!: any;
  placeSelected: any = [];
  allPlaces: boolean = true;

  // Eventos
  openEventsList: boolean = false;
  eventsEnabled!: any;
  eventsSelected!: any;
  allowEvents: boolean = false;
  groupedEvents!: any;

  // Metodos
  methodsOptions!: any;
  methodSelected: any[] = [];
  allMethods: boolean = true;

  // Tipo de informe
  typeReport: any = 'global';
  formatReport: any = 'pdf';

  // Header Excel
  reportsGroupEnabled!: any;
  reportsGroupSelected: any[] = [];
  allColumns: boolean = false;

  // Eventos seleccionados
  openEventMultidate!: any;

  // Cargar informe
  loadingReport: boolean = false;
  selectGroup: any[] = [];

  constructor(private apiBusiness: ApiBusinessService, private placeService: PlacesService, private businessService: BusinessService,
    private apiSalesService: ApiSalesService, private reportsService: ReportsService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.apiSalesService.getAllMethods().subscribe({
      next: (res) => {
        this.methodsOptions = res;
        this.selectAllMethods();
      }
    });

    this.reportsService.getHeaderReports().subscribe({
      next: (res) => {
        this.reportsGroupEnabled = res;
        this.reportsGroupSelected = this.reportsGroupEnabled.map(value => value.id);
      }
    })
  }

  ngAfterViewInit() {
    this.apiBusiness.changed.subscribe({
      next: (value) => {
        if (value && value != 'place') {
          this.getData();
        }
      }
    })
  }

  getData() {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.placeService.getListFilterPlaces(this.business?.id, 1, null, null, true).subscribe({
      next: (res) => {
        this.placesEnabled = res;
        this.getListEvents();
        this.selectAllPlaces();
      }
    });
  }

  selectPlaceFilter() {
    this.eventsSelected = null;
    this.loadedFilters = false;
    this.getListEvents();
  }

  changeTypeReport() {
    if (this.typeReport == 'detail' && this.formatReport == 'pdf') {
      this.formatReport = 'csv';
      this.allColumns = true;
      this.selectAllColumns();
    }
  }

  // Manejar el cambio de selección
  onMethodSelectionChange(event: any) {
    // if (this.methodSelected.length != (this.methodsOptions.length + 1)) {
    //   this.allMethods = false;
    //   if (this.methodSelected.includes('all')) {
    //     let index = this.methodSelected.indexOf('all');
    //     this.methodSelected.splice(index, 1);
    //     this.methodSelected = [...this.methodSelected];
    //   }
    // } else {
    //   this.allMethods = true;
    //   this.selectAllMethods();
    // }
  }

  // Seleccionar todas las opciones
  selectAllMethods() {
    this.methodSelected = this.methodsOptions?.map(option => option.id);
  }

  selectAllPlaces() {
    this.placeSelected = this.placesEnabled.map(option => option.id);
  }

  selectColumn(column, i) {
    let index = this.reportsGroupSelected.findIndex(value => value == column.id);
    index == -1 ? this.reportsGroupSelected.push(column.id) : this.reportsGroupSelected.splice(index, 1);
    if (this.reportsGroupEnabled.length != this.reportsGroupSelected.length) {
      this.allColumns = false;
    }
  }

  selectAllColumns() {
    if (this.allColumns) {
      this.reportsGroupSelected = this.reportsGroupEnabled.map(value => value.id);
      this.reportsGroupEnabled.forEach(element => {
        element.selected = true;
      });
    }
  }

  getListEvents() {
    let venue = this.placeSelected?.length == this.placesEnabled?.length ? null : this.placeSelected;
    let toDate = this.dateFilter ? this.formatStringDate(this.datesRange.min, 'yyyy-MM-dd') : null;
    this.businessService.getEventsCompanyFilter(this.business?.id, 1, null, null, venue, false, false, toDate, true).subscribe({
      next: (res) => {
        // Ordenados: primero el más nuevo
        this.eventsEnabled = [];
        this.groupedEvents = [];

        res?.forEach(event => {
          let dates = event[0].fechas.filter((value) => value.activo);
          let hours = event[0].horarios.filter((value) => value.activo);

          if (dates.length > 1) {
            // Evento recurrente
            dates.forEach(date => {
              if (!hours || hours.length > 0) {
                hours.forEach(hour => {
                  this.eventsEnabled.push({ id: event[0].id, multidate: event[0].multidate, name: event[0].titulo, idDate: date.id, date: date.fechaInicio, idHour: hour.id, hour: hour.horaInicio })
                });
              } else {
                this.eventsEnabled.push({ id: event[0].id, multidate: event[0].multidate, name: event[0].titulo, idDate: date.id, date: date.fechaInicio, idHour: null, hour: null })
              }
            });
          } else {
            dates.forEach(date => {
              if (!date.fechaFin || date.fechaInicio == date.fechaFin) {
                hours.forEach(hour => {
                  this.eventsEnabled.push({ id: event[0].id, multidate: event[0].multidate, name: event[0].titulo, idDate: date.id, date: date.fechaInicio, idHour: hour.id, hour: hour.horaInicio })
                });
              }
            });
          }
        });
        this.groupEventsByYearAndMonth();

        this.allowEvents = this.eventsEnabled.length;
        this.loadedFilters = true;
      }
    })
  }

  // Agrupar eventos por año
  groupEventsByYearAndMonth(): void {
    const eventsByYear = this.eventsEnabled.reduce((acc: any, event: any) => {
      const year = new Date(event.date).getFullYear();
      const month = new Date(event.date).toLocaleString('default', { month: 'long' });

      if (!acc[year]) {
        acc[year] = {};
      }
      if (!acc[year][month]) {
        acc[year][month] = { events: [], open: false }; // Inicialmente cerrado
      }
      acc[year][month].events.push(event);
      return acc;
    }, {});

    // Convertir a un formato adecuado para las opciones del desplegable y ordenar
    this.groupedEvents = Object.keys(eventsByYear)
      .sort((a, b) => Number(b) - Number(a)) // Ordenar años descendente
      .map(year => ({
        year: Number(year),
        months: Object.keys(eventsByYear[year])
          .sort((a, b) => eventsByYear[year][b].monthNumber - eventsByYear[year][a].monthNumber) // Ordenar meses descendente
          .map(month => ({
            month,
            events: eventsByYear[year][month].events.sort(
              (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
            ), // Ordenar eventos descendente
            open: eventsByYear[year][month].open,
            selected: false // Inicializar como no seleccionado
          }))
      }));
  }

  // Alternar visibilidad del mes
  toggleMonthVisibility(year: number, month: string, event: Event): void {
    event.stopPropagation(); // Evita interferir con el evento de apertura del selector
    const yearGroup = this.groupedEvents.find(group => group.year === year);
    if (yearGroup) {
      const monthGroup = yearGroup.months.find(m => m.month === month);
      if (monthGroup) {
        monthGroup.open = !monthGroup.open; // Alternar estado
      }
    }
  }

  selectAllMonthEvents(year: number, month: string): void {
    const yearGroup = this.groupedEvents.find(group => group.year === year);
    if (yearGroup) {
      const monthGroup = yearGroup.months.find(m => m.month === month);
      if (monthGroup) {
        const monthEvents = monthGroup.events.filter(event => {
          const eventDate = new Date(event.date);
          return (
            eventDate.getFullYear() === year &&
            eventDate.toLocaleString('default', { month: 'long' }) === month
          );
        });

        // Verifica si todos los eventos del mes están seleccionados
        const allSelected = monthEvents.every(event =>
          this.selectGroup.some(
            selectedItem =>
              selectedItem?.id === event?.id &&
              selectedItem?.date === event?.date &&
              selectedItem?.hour === event?.hour
          )
        );

        if (allSelected) {
          // Si todos están seleccionados, quítalos y elimina la representación del mes
          this.selectGroup = this.selectGroup.filter(
            selectedItem =>
              // Elimina los eventos del mes
              !monthEvents.some(
                event =>
                  event?.id === selectedItem?.id &&
                  event?.date === selectedItem?.date &&
                  event?.hour === selectedItem?.hour
              ) &&
              // Elimina la representación del mes
              !(selectedItem?.isMonth && selectedItem?.year === year && selectedItem?.month === month)
          );
          monthGroup.selected = false;
        } else {
          // Si no todos están seleccionados, agrégalos junto con la representación del mes
          this.selectGroup = [
            ...this.selectGroup,
            ...monthEvents.filter(
              event =>
                !this.selectGroup.some(
                  selectedItem =>
                    selectedItem?.id === event?.id &&
                    selectedItem?.date === event?.date &&
                    selectedItem?.hour === event?.hour
                )
            ),
            { isMonth: true, year, month } // Representación única del mes
          ];
          monthGroup.selected = true;
        }

        // Forzar actualización del binding de Angular
        this.selectGroup = [...this.selectGroup];
      }
    }
  }


  updateMonthSelection(year: number, month: string): void {
    const yearGroup = this.groupedEvents.find(group => group.year === year);
    if (yearGroup) {
      const monthGroup = yearGroup.months.find(m => m.month === month);
      if (monthGroup) {
        // Actualizar si el mes está completamente seleccionado o no
        monthGroup.selected = monthGroup.events.every(event =>
          this.selectGroup.some(selectedEvent => selectedEvent?.id === event?.id)
        );
      }
    }
  }

  isMonthSelected(year: number, month: string): boolean {
    const yearGroup = this.groupedEvents.find(group => group.year === year);
    if (yearGroup) {
      const monthGroup = yearGroup.months.find(m => m.month === month);
      if (monthGroup) {
        return monthGroup.events.every(event =>
          this.selectGroup.some(
            selectedItem =>
              selectedItem?.id === event?.id &&
              selectedItem?.date === event?.date &&
              selectedItem?.hour === event?.hour
          )
        );
      }
    }
    return false;
  }

  compareEvents(event1: any, event2: any): boolean {
    if (event1?.isMonth && event2?.isMonth) {
      return event1.year === event2.year && event1.month === event2.month;
    }
    return event1?.id === event2?.id && event1?.date === event2?.date && event1?.hour === event2?.hour;
  }

  // Actualizar la selección
  changeEventsSelected(): void {
    this.selectGroup = [...this.selectGroup];
  }

  removeEventSelected(index: any) {
    this.eventsSelected.splice(index, 1);
    this.eventsSelected = [...this.eventsSelected];
  }

  openMultidate(event: any) {
    this.openEventMultidate = this.openEventMultidate ? null : event.id;
  }

  // Seleccionar Rango de fechas
  selectDates(datesRange: any) {
    this.datesRange = datesRange;
    let stringDates = this.formatStringDate(datesRange.min);
    if (datesRange.max) {
      stringDates += ' - ' + this.formatStringDate(datesRange.max);
    }
    this.stringDate = stringDates;
    this.dateFilter = { date_from: this.formatStringDate(datesRange.min, 'dd/MM/yyyy'), date_to: this.formatStringDate(datesRange.max, 'dd/MM/yyyy') };
    this.eventsSelected = null;
    this.getListEvents();
  }

  formatStringDate(date: any, format: string = 'dd MMM yy') {
    let dateFormat = null;
    if (date) {
      dateFormat = formatDate(new Date(date), format, 'es');
    }
    return dateFormat;
  }

  clearRangeDates(event: any) {
    if (event) {
      event.stopPropagation();  // Evita que el click se propague al div
    }
    this.datesRange = null;
    this.stringDate = '';
    this.dateFilter = null;
    this.openDatePicker = false;
    this.getListEvents();
  }

  // Descargar informe
  downloadPDF() {
    this.loadingReport = true;
    const methods = this.methodSelected?.length != this.methodsOptions?.length ? this.methodSelected : null;
    const reports = this.reportsGroupSelected?.length != this.reportsGroupEnabled?.length ? this.reportsGroupSelected : null;
    const selectedEvents = this.selectGroup.filter(item => !item.isMonth);
    const payload = {
      events: selectedEvents,
      dates: this.dateFilter,
      format: this.formatReport,
      type: this.typeReport,
      method: methods,
      reportsgroup: reports
    }
    this.reportsService.downloadReportFile(this.business?.id, payload).subscribe({
      next: (res) => {
        if (res) {
          const linkSource = res;
          const type = this.formatReport == 'csv' ? 'text/csv' : 'application/pdf';
          const windowOpen = this.formatReport == 'csv' ? '_self' : '_blank';
          const base64WithMime = `data:${type};base64,${linkSource}`;

          const newTab = window.open('', '_blank');
          if (newTab) {
            newTab.document.body.innerHTML = `
              <iframe src="${base64WithMime}" frameborder="0" style="border:0; width:100%; height:100%;" allowfullscreen></iframe>
            `;
            newTab.document.title = 'Informe';
          }
        }
        this.loadingReport = false;
      }, error: (err) => {
        let msg = '¡Vaya! Algo ha salido mal, no se ha podido descargar el informe.';
        if (err.error.events && err.error.events?.length > 0) {
          msg = 'Lo sentimos, los siguientes eventos no tienen disponible el informe detalle: ';
          err.error.events.forEach((element, key) => {
            msg += element;
            if (key != err.error.events.length - 1) msg += ', ';
          });
        }
        const dialogRef = this.dialog.open(InfoCardComponent, {
          panelClass: ['info-dialog'],
          data: {
            icon: true,
            text: msg,
            btnCancel: false
          }
        });
        this.loadingReport = false;
      }
    })
  }

  clearFilters() {
    this.clearRangeDates(null);
    this.placeSelected = null;
    this.eventsSelected = null;
    this.selectAllMethods();
    this.selectAllPlaces();
  }
}

function base64ToUint8Array(base64: string): Uint8Array {
  const binaryString = window.atob(base64); // Puedes mantener atob por ahora ya que sigue siendo funcional en navegadores
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
