import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FavoritosService } from 'src/app/services/favoritos.service';
import { SeguidoresService } from 'src/app/services/seguidores.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  user!: any;
  options: any[] = [];
  index: number = 0;

  constructor(private auth: AuthenticationService) { }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.auth.getUsersMe().subscribe(user => {
      if (user) {
        this.user = user;
      }
    })
  }

  changeTab(event: any) {
    this.options = [];
    this.index = event.index;
  }

}
