<div class="subscribers-page">
    <div class="d-flex justify-content-between align-items-center">
        <div class="content-search col-6">
            <div class="search-container container-form d-flex align-items-center borderInput search-full"
                *ngIf="!activeSelectList">
                <i class="fal fa-search search-icon pointer-in" (click)="searchUser(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar socio"
                    [(ngModel)]="searchText" (input)="searchUser(searchInput.value)">
            </div>
            <div class="info-selected" *ngIf="activeSelectList">
                <span>Seleccionados ({{selectedItems.length}})</span>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end content-btns col-6">
            <div class="link-open" (click)="activeSelecting()" *ngIf="!activeSelectList">
                <i class="fal fa-list-radio"></i>
                <span>Seleccionar varios</span>
            </div>
            <div class="d-flex" style="gap: 30px;" *ngIf="activeSelectList">
                <div [matMenuTriggerFor]="editOptions"
                    class="btn-create btn-create-border btn-select btn-edit d-flex justify-content-between m-0" [class.disabled]="selectedItems.length <= 0">
                    <p class="m-0">Acciones en grupo</p>
                    <i class="icon-option fal fa-chevron-down"></i>
                </div>
                <mat-menu class="menu-options" #editOptions="matMenu">
                    <button mat-menu-item *ngIf="containsBlocked()" (click)="editSelected('active')">Activar</button>
                    <button mat-menu-item *ngIf="containsBlocked()"
                        (click)="editSelected('inactive')">Desactivar</button>
                    <button mat-menu-item (click)="editSelected('deleted')">Eliminar</button>
                </mat-menu>
            </div>
            <div class="d-flex align-items-center" *ngIf="activeSelectList">
                <div class="btn-create btn-action m-0" (click)="activeSelecting()">
                    <p class="m-0">Cancelar</p>
                </div>
            </div>
            <!-- <div class="link-open" [class.disabled]="activeSelectList" (click)="addSubscriber()">
                <i class="fal fa-plus-circle"></i>
                <span>Añadir usuario</span>
            </div> -->
            <div class="btn-create m-0" [class.disabled]="activeSelectList" (click)="openCreateComunication()" *ngIf="marketing">
                <i class="fal fa-envelope"></i>
                <p class="m-0">Crear una comunicación para todos</p>
            </div>
        </div>
    </div>
    <div class="content-table-subscribers">
        <div *ngIf="loadingTable" class="container-lottie">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                    containerClass="loading-box"></ng-lottie>
            </div>
        </div>
        <div #containerScroll class="table-subscribers table-panel multiple-lines" *ngIf="loaded"
            (scroll)="isScrolledInfinityView($event)">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef><b>
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <mat-checkbox [(ngModel)]="allSelected" class="checkbox" color="primary"
                                    (change)="selectAll()" *ngIf="activeSelectList"></mat-checkbox>
                                <span *ngIf="!activeSelectList">Acciones</span>
                            </div>
                        </b></th>
                    <td mat-cell *matCellDef="let subscription">
                        <div class="d-flex align-items-center" style="gap: 10px;">
                            <mat-checkbox class="checkbox" [checked]="allSelected" color="primary"
                                (change)="changeSelectItem(subscription)" *ngIf="activeSelectList"></mat-checkbox>

                            <div class="d-flex" style="gap: 30px;" *ngIf="subscription.status != 'blocked' && !activeSelectList">
                                <div [matMenuTriggerFor]="actionOptions" class="pointer-in square-option">
                                    <i class="icon-option fal fa-ellipsis-vertical"></i>
                                </div>
                                <mat-menu class="options-menu-control-panel" #actionOptions="matMenu">
                                    <button mat-menu-item *ngIf="subscription.status!='active'"
                                        (click)="editSubscription(subscription.id, 'active')">Activar</button>
                                    <button mat-menu-item *ngIf="subscription.status!='inactive'"
                                        (click)="editSubscription(subscription.id, 'inactive')">Desactivar</button>
                                    <button mat-menu-item
                                        (click)="editSubscription(subscription.id, 'deleted')">Eliminar</button>
                                </mat-menu>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Nombre y apellidos</b></th>
                    <td mat-cell *matCellDef="let subscription">
                        {{subscription.subscriber.nombre||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Correo electrónico</b></th>
                    <td mat-cell *matCellDef="let subscription">
                        {{subscription.subscriber.email||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Fecha de creación</b></th>
                    <td mat-cell *matCellDef="let subscription">
                        {{subscription.createdAt | date: 'dd-MM-y'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Estado</b></th>
                    <td mat-cell *matCellDef="let subscription" [style.color]="getStatusColor(subscription.status)">
                        <div class="d-flex align-items-center" style="gap: 5px;">
                            <i class="fas fa-circle" [style.color]="getStatusColor(subscription.status)"></i>
                            {{getStatus(subscription.status)||'-'}}
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.blocked]="row.status=='blocked'">
                </tr>
            </table>
            <div class="pagination-component">
                <div class="d-flex align-items-center pagination-panel pagination-table" *ngIf="!loadingPage && !finishScroll">
                    <div>
                        <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                            containerClass="loading-box"></ng-lottie>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="loaded && !loadingTable && !loadingSort && (subscribers.length<=0)" style="text-align: center;">No hay
            resultados disponibles.
        </p>
    </div>
</div>