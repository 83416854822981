import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-waiting-pay',
  templateUrl: './waiting-pay.component.html',
  styleUrls: ['./waiting-pay.component.scss']
})
export class WaitingPayComponent implements OnInit {

  public progress = 0;
  public counter = 300;
  public tick = 0;
  public minutes = 5;
  public seconds = 0;

  idPayment: string = '';
  waiting: boolean = true;
  intervalId: any;

  constructor(private checkoutService: CheckoutService, @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<WaitingPayComponent>) {

    this.idPayment = data.idPayment;
    setTimeout(() => {
      this.intervalId = setInterval(() => {
        this.counter = this.counter - 1;
        this.minutes = Math.floor(this.counter / 60);
        this.seconds = (this.counter - this.minutes * 60);
        if (this.seconds % 10)
          this.tick = this.tick + 1;
        // interval(10000).subscribe(val => this.validate())
        this.validate();
        if (this.counter === 0) {
          clearInterval(this.intervalId);
          this.validate(true);
        }
      }, 1000)
    }, 5000);
  }

  ngOnInit(): void {
    this.validate();
  }

  checkValid() {
    this.waiting = false;
    this.validate(true);
  }

  validate(fin?: boolean) {
    let count = 0;
    if (this.idPayment) {
      this.checkoutService.checkStripe(this.idPayment).subscribe({
        next: (data) => {
          if ((fin || count == 30) && data.paymentIntent.status != 'succeeded') {
            clearInterval(this.intervalId);
            this.dialogRef.close(data.paymentIntent.status)
          }
          if (data.paymentIntent.status == 'succeeded') {
            clearInterval(this.intervalId);
            this.dialogRef.close('succeeded')
          }
          count++;
        },
        error: (err) => this.dialogRef.close('canceled')
      })
    }
  }

}
