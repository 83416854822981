import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoriasService } from 'src/app/services/categorias.service';

@Component({
  selector: 'app-circles',
  templateUrl: './circles.component.html',
  styleUrls: ['./circles.component.scss']
})
export class CirclesComponent implements AfterViewInit {

  offsetLeft = new BehaviorSubject<number>(60);
  offsetLeftValue = this.offsetLeft.asObservable();
  @ViewChild('container') containerElement: any;
  @ViewChild('swiperCircle', {static: true}) swiperCircles: any;

  options: Observable<any>;

  swiperCirclesConfig: any = {
    slidesPerView: 'auto',
    spaceBetween: 12,
    slidesOffsetAfter: 12,
    breakpoints: {
      1400: {
        spaceBetween: 20
      },
      768: {
        spaceBetween: 40
      },
      578: {
        spaceBetween: 60
      },
      468: {
        spaceBetween: 30
      }
    }
  }

  constructor(private categoryService: CategoriasService) { }

  ngAfterViewInit() {
    this.options = this.categoryService.getAllCategories().pipe();
    this.offsetLeft.next(this.containerElement.nativeElement.offsetLeft+12);
    this.offsetLeftValue.subscribe(res => {
      this.swiperCircles.elementRef.nativeElement.swiper.params.slidesOffsetBefore = res;
      this.swiperCircles.elementRef.nativeElement.swiper.init();
    })
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.offsetLeft.next(this.containerElement.nativeElement.offsetLeft+12);
  }

}
