<div class="padding-content comment-page">
    <div class="d-flex justify-content-between align-items-center">
        <div class="content-search col-6">
            <div class="search-container search-grey container-form d-flex align-items-center borderInput search-full">
                <i class="fal fa-search search-icon pointer-in" (click)="searchComments(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar comentario"
                    [(ngModel)]="searchText" (keyup.enter)="searchComments(searchInput.value)">
            </div>
            <div class="btn-create btn-filter m-0" (click)="openFilters()">
                <i class="fal fa-sliders"></i>
                <p class="m-0">Filtrar</p>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end content-btns col-6">
            <div class="link-open">
                <i class="fal fa-list-radio"></i>
                <span>Seleccionar varios</span>
            </div>
            <div class="btn-create m-0" (click)="deleteAll()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Eliminar todos</p>
            </div>
        </div>
    </div>
</div>