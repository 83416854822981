import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-description-lok',
  templateUrl: './description-lok.component.html',
  styleUrls: ['./description-lok.component.scss']
})
export class DescriptionLokComponent implements OnInit {

  @Input() textArea: any = '';
  @Output() emitDescription = new EventEmitter<any>();

  public isEmojiPickerVisible: boolean;   
  public addEmoji(event) {
    this.isEmojiPickerVisible = false;
    return event.emoji.native;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí la descripción del evento',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]    
  };

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
  @Optional() private dialogRef: MatDialogRef<DescriptionLokComponent>) { }

  ngOnInit(): void {
    if(this.data) {
      this.textArea = this.data.descripcion
    }
  }

  save() {
    this.emitDescription.emit(this.textArea);
    if(this.data) {
      this.dialogRef.close(this.textArea);
    }
  }

}
