import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { formatDate, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CiudadesService } from 'src/app/services/ciudades.service';
import { EditEventService } from 'src/app/services/edit-event.service';
import { EventosService } from 'src/app/services/eventos.service';
import { FechasService } from 'src/app/services/fechas.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { CategoriesListComponent } from '../bloques/categories-list/categories-list.component';
import { CropperImageComponent } from '../bloques/cropper-image/cropper-image.component';
import { ConfigTicketsComponent } from '../new-lok/config-tickets/config-tickets.component';
import { DatesHoursComponent } from '../new-lok/dates-hours/dates-hours.component';
import { DescriptionLokComponent } from '../new-lok/description-lok/description-lok.component';
import { MapSearchComponent } from '../new-lok/map-search/map-search.component';
import { NewTicketComponent } from '../new-lok/new-ticket/new-ticket.component';
import { TicketsComponent } from '../new-lok/tickets/tickets.component';
import { InteresesComponent } from '../profile/config/intereses/intereses.component';
// import { HideEventComponent } from '../shared/hide-event/hide-event.component';
import { InfoCardComponent } from '../shared/info-card/info-card.component';

@Component({
  selector: 'app-edit-lok',
  templateUrl: './edit-lok.component.html',
  styleUrls: ['./edit-lok.component.scss']
})
export class EditLokComponent implements OnInit {

  lokForm: FormGroup;
  nameCategoria!: any;
  textArea!: any;
  filePath!: string;
  eventInit!: any;

  // Arrays lok
  horarios: any[] = [];
  fechas: any[] = [];
  entradas: any[] = [];
  fotos: any[] = [];

  // Localizacion
  locateEvent!: any;
  localizacionName: string = '';
  editActive!: string;
  textDates: string = '';
  textTickets: string = '';

  editTitle: boolean = false;
  editHashtags: boolean = false;

  configTickets!: any;
  hashtags: any[] = [];

  // Map
  map!: any;
  profile!: any;

  croppedImage: any = '';

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private fechasService: FechasService,
    private eventosService: EventosService, private location: Location, private ciudadService: CiudadesService,
    private dialog: MatDialog, private auth: AuthenticationService, private ticketService: TicketsService,
    private editEventService: EditEventService) { }

  ngOnInit(): void {
    this.lokForm = this.formBuilder.group({
      titulo: ['', Validators.required],
      descripcion: ['', [Validators.required]],
      preciodesde: 0,
      hashtags: [],
      categoria: [],
      activo: [true],
      draft: []
    });
    this.profile = this.auth.getProfile();
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe(params => {
      const slug: string = params.slugEvent;
      const firstIndex = slug.indexOf('-');
      let idEvent = slug.substring(0, firstIndex);
      if (!idEvent) { idEvent = slug }
      this.eventosService.getEventById(idEvent).subscribe({
        next: event => {
          if (event) {
            if (event.usuario.id != this.auth.user && !this.profile?.admin && !this.profile.citylok) {
              this.location.back();
            }
            this.eventInit = event;
            this.configTickets = {
              startSales: this.eventInit.startSales,
              endSales: this.eventInit.endSales,
              assumeFees: this.eventInit.assumeFees,
              needValidation: this.eventInit.needValidation,
              maxTickets: this.eventInit.maxTikets,
              external: this.eventInit.external,
              url: this.eventInit.url,
              informativo: this.eventInit.informativo,
              publishDate: this.eventInit.publishDate || null,
              progPublish: this.eventInit.publishDate ? true : false,
            };
            this.hashtags = this.eventInit.hashtags || [];
            this.map = this.eventInit.mapEvent;
            const lok = {
              titulo: event.titulo,
              descripcion: event.descripcion,
              preciodesde: event.preciodesde,
              hashtags: event.hashtags,
              categoria: event.categoria,
              activo: event.activo,
              draft: event.draft
            }
            this.croppedImage = AppSettings.MEDIA_URI + '/images/events/' + event.id + '/' + event.imageurl;
            this.textArea = event.descripcion;
            this.nameCategoria = event.categoria.nombre;
            this.lokForm.setValue(lok);
            this.locateEvent = {
              latitude: event.latitude,
              longitude: event.longitude,
              localizacion: event.localizacion,
              ciudad: event.ciudad
            }
            this.localizacionName = event.localizacion;
            this.horarios = event.horarios.filter((value: any) => value.activo);
            this.fechas = event.fechas.filter((value: any) => value.activo);
            this.getTextDates();
            this.entradas = event.entradas.filter((value: any) => value.activo);
            this.getTextTickets();
            this.fotos = event.imagenes;
          }
        },
        error: (err) => {
          this.location.back();
        }
      })
    })
  }

  getTextDates() {
    if (this.fechas.length > 0) {
      const fecha = this.fechas[0];
      const startStringDate = formatDate(fecha.fechaInicio, 'dd/MM/yyyy', 'es');
      this.textDates = startStringDate;
      if (fecha.fechaFin) {
        const endStringDate = formatDate(fecha.fechaFin, 'dd/MM/yyyy', 'es');
        this.textDates += ' al ' + endStringDate
      };
      if (fecha.typeRepeat) {
        this.textDates += fecha.typeRepeat == 'week' ? ' - Semanal' : (fecha.typeRepeat == 'month' ? ' - Mensual' : ' - Anual');
      }
    }
  }

  getTextTickets() {
    this.textTickets = 'Gratuito';

    if (this.entradas?.length > 0) {
      this.textTickets = '';
      let price = null;
      if (this.entradas.length == 1) {
        this.entradas[0].priceTickets.forEach(element => {
          if (price == null || element.price < price) {
            price = element.price;
          }
        });
        this.textTickets = this.entradas[0].nombre + ' - ' + price + '€';
      }
      if (this.entradas.length > 1) {
        this.textTickets = this.entradas.length + ' tipos de entradas';
        this.entradas.forEach(ticket => {
          ticket.priceTickets.forEach(element => {
            if (price == null || element.price < price) {
              price = element.price;
            }
          });
        });
        let preciodesde = price;
        this.textTickets += preciodesde > 0 ? ' - Desde ' + preciodesde + ' €' : '';
      }
    }
  }

  changeTitle() {
    this.falseAllEdit();
    this.editTitle = true;
  }

  saveTitle() {
    if (this.lokForm.value.titulo) {
      // Llamada para editar titulo
      this.editEvent({ titulo: this.lokForm.value.titulo });
      this.falseAllEdit();
      this.lokForm.setControl('titulo', new FormControl(this.lokForm.value.titulo));
    }
  }

  falseAllEdit() {
    this.lokForm.setControl('titulo', new FormControl(this.eventInit.titulo));
    // this.lokForm.setControl('titulo', new FormControl(this.eventInit.titulo));
    this.editTitle = false;
    this.editHashtags = false;
  }

  onFileChange(event: any, second?: boolean) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const dialogRef = this.dialog.open(CropperImageComponent, {
        panelClass: 'info-dialog',
        data: event
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.eventosService.putEventoImage(result.object.base64, this.eventInit.id).subscribe({
            next: (err) => {
              this.croppedImage = result.pathUrl;
            }
          })
        }
      });

      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;

        };
      }
    }
  }

  openMap() {
    const dialogRef = this.dialog.open(MapSearchComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { localizacion: this.eventInit.localizacion }
    });
    dialogRef.afterClosed().subscribe(event => {
      if (event) {
        this.localizacionName = event.formatted_address;
        this.locateEvent = {
          latitude: event.geometry.location.lat(),
          longitude: event.geometry.location.lng(),
          localizacion: this.localizacionName,
          ciudad: null
        }
        const objectCity = event.address_components.find(value => value.types.includes('locality'));
        if (!objectCity) {
          this.editEvent(this.locateEvent);
        }
        if (objectCity) {
          this.ciudadService.getAllCitieByName(objectCity.long_name).subscribe(res => {
            if (res.length > 0) {
              this.locateEvent.ciudad = res[0]['@id'];
            }
            this.editEvent(this.locateEvent);
          });
        }
      }
    });
  }

  openCategories() {
    const dialogRef = this.dialog.open(CategoriesListComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { categorySelect: this.eventInit.categoria }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editCategory(result);
      }
    });
  }

  openDescription() {
    const dialogRef = this.dialog.open(DescriptionLokComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { descripcion: this.eventInit.descripcion }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.lokForm.value.descripcion = result;
        this.textArea = result;
        this.editEvent({ descripcion: result });
      }
    });
  }

  openDates() {
    let auxHours = this.getOptions();
    const dialogRef = this.dialog.open(DatesHoursComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: { dates: this.fechas, hours: this.horarios, auxArr: auxHours }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fechas[0].activo = false;
        this.fechas.unshift(result.fecha);
        this.horarios = result.horarios;
        this.textDates = result.textInput;
        this.editDatesHours({ dates: this.fechas, hours: this.horarios });
      }
    });
  }

  openTickets() {
    let auxHours = this.getOptions();
    let aux = this.eventInit.informativo ? auxHours : 0;
    const dialogRef = this.dialog.open(TicketsComponent, {
      panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
      data: {
        entradas: this.entradas, fechas: this.fechas, horarios: this.horarios, optionsTime: auxHours, map: this.eventInit.mapEvent, edit: true,
        preciodesde: this.eventInit.preciodesde, profile: this.auth.getProfile(), online: this.eventInit.online
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const result = data.tickets;
        const map = data.map;
        this.map = data.mapObject;
        let payload = { tickets: result, map: map, preciodesde: data.preciodesde };
        this.editTickets(payload);
      }
    });
  }

  openConfig() {
    const dialogRef = this.dialog.open(ConfigTicketsComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: this.configTickets
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editConfig(result);
      }
    });
  }

  getOptions() {
    let auxHours = null;
    if (this.horarios && this.horarios.length > 0) {
      if (this.horarios[0].fixedSchedule) {
        auxHours = this.horarios;
      }
      if (!this.horarios[0].fixedSchedule) {
        let hours = [];
        this.horarios.forEach(hour => {
          let arr = hour.dayWeek.length > 0 ? hour.dayWeek : hour.dayMonth;
          arr.forEach(element => {
            const date = new Date(this.fechas[0].fechaInicio);
            let elHour = hours.findIndex((val) => val.day == date);
            if (elHour != -1) {
              hours[elHour].times.push(hour);
            }
            if (elHour == -1) {
              hours.push({ day: date, times: [hour] });
            }
          });
        });
        auxHours = hours;
      }
    }
    return auxHours;
  }

  editDatesHours(payload: any) {
    this.fechasService.editDatesHours(this.eventInit.id, payload).subscribe({
      next: (data) => {
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  editTickets(payload: any) {
    this.fechasService.editTickets(this.eventInit.id, payload).subscribe({
      next: (data) => {
        this.eventInit.preciodesde = payload.preciodesde;
        this.entradas = payload.tickets;
        this.eventInit.mapEvent = this.map;
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  editConfig(payload: any) {
    this.editEventService.editConfig(this.eventInit.id, payload).subscribe({
      next: (data) => {
        this.configTickets = payload;
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  editCategory(payload: any) {
    this.editEventService.editCategory(this.eventInit.id, payload).subscribe({
      next: (data) => {
        this.lokForm.value.categoria = payload[0];
        this.eventInit.categoria = payload[0];
        this.nameCategoria = payload[0].nombre;
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  editEvent(payload: any) {
    // Comprobar al editar el evento que no deja publicarlo si no tiene titulo o fecha
    // Comprobar también en las llamadas de la API
    // Sacar mensaje para aclararlo, no has introducido titulo o mensaje
    this.eventosService.putEventos(this.eventInit.id, payload).subscribe({
      next: (data) => {
        this.horarios = data.horarios?.filter((value: any) => value.activo);
        this.fechas = data.fechas?.filter((value: any) => value.activo);
        this.eventInit = {
          titulo: this.lokForm.value.titulo,
          descripcion: this.lokForm.value.descripcion,
          hashtags: this.lokForm.value.hashtags,
          categoria: this.lokForm.value.categoria,
          fechas: this.fechas,
          horarios: this.horarios,
          latitude: this.locateEvent.latitude,
          longitude: this.locateEvent.longitude,
          localizacion: this.locateEvent.localizacion,
          ciudad: this.locateEvent.ciudad,
          activo: this.locateEvent.activo,
          preciodesde: this.locateEvent.preciodesde
        };
      },
      error: (err) => {
        this.resetValues();
      }
    })
  }

  resetValues() {
    const lok = {
      titulo: this.eventInit.titulo,
      descripcion: this.eventInit.descripcion,
      preciodesde: this.eventInit.preciodesde,
      hashtags: this.eventInit.hashtags,
      categoria: this.eventInit.categoria,
      activo: this.eventInit.activo,
      draft: this.eventInit.draft
    }
    this.textArea = this.eventInit.descripcion;
    this.nameCategoria = this.eventInit.categoria.nombre;
    this.lokForm.setValue(lok);
  }

  goBack() {
    this.location.back();
  }

  publishEvent() {
    this.eventosService.putEventos(this.eventInit.id, { draft: false, activo: true }).subscribe({
      next: (res) => {
        this.eventInit.draft = res.draft;
        this.eventInit.activo = res.activo;
        this.lokForm.value.activo = res.activo;
        this.lokForm.value.draft = res.draft;
      }
    });
  }

  editTicketsEvent(payload, idEvent) {
    this.ticketService.setTicketsEvent(payload, idEvent).subscribe({
      next: (res) => {

      }
    })
  }

  changeHashtags() {
    this.falseAllEdit();
    this.editHashtags = true;
  }

  saveHashtags() {
    // Llamada para editar link
    this.editEvent({ hashtags: this.lokForm.value.hashtags });
    this.falseAllEdit();
    this.lokForm.setControl('hashtags', new FormControl(this.lokForm.value.hashtags));
  }

  removeHashtag(item: string): void {
    if (this.editHashtags) {
      const index = this.hashtags.indexOf(item);
      if (index >= 0) {
        this.hashtags.splice(index, 1);
        this.lokForm.controls['hashtags'].setValue(this.hashtags);
      }
    }
  }

  addHashtag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.hashtags.push(value);
      this.lokForm.controls['hashtags'].setValue(this.hashtags);
    }
    event.chipInput!.clear();
  }

}
