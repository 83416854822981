import { Component } from '@angular/core';

@Component({
  selector: 'app-info-bond',
  templateUrl: './info-bond.component.html',
  styleUrls: ['./info-bond.component.scss']
})
export class InfoBondComponent {

}
