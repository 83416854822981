import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-section',
  templateUrl: './new-section.component.html',
  styleUrls: ['./new-section.component.scss']
})
export class NewSectionComponent implements OnInit {

  sectionForm!: FormGroup;
  editSection: boolean = false;
  colorHex: string = '#000';

  constructor(private formBuilder: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<NewSectionComponent>) { }

  ngOnInit(): void {
    this.sectionForm = this.formBuilder.group({
      name: ['', Validators.required],
      color: ['#000', Validators.required]
    });
    if (this.data && this.data.map) {
      this.sectionForm.setValue({
        name: this.data.name,
        color: this.data.color,
      });
    }
  }

  changeColor() {
    this.sectionForm.controls['color'].setValue(this.colorHex);
  }

  saveSection() {
    if (this.sectionForm.valid) {
      this.dialogRef.close(this.sectionForm.value);
    }
  }

}
