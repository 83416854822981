<div class="form-new-lok w-100" *ngIf="!tabOptional">
    <div class="steps-new-lok">
        <div class="d-flex align-items-center item-step" [class.step-complete]="countSteps > i" *ngFor="let step of [].constructor(numSteps); let i = index">
            <div class="step-circle">
                <span *ngIf="countSteps <= i">{{i+1}}</span>
                <i *ngIf="countSteps > i" class="fa-solid fa-check"></i>
            </div>
            <div class="line-new-lok" [class.d-none]="i==(numSteps - 1)"></div>
        </div>
    </div>
    <form [formGroup]="lokForm" (ngSubmit)="changeStatus()" enctype="application/x-www-form-urlencoded">
        <p class="title-img text-center"><i class="fa-light fa-image"></i>FOTO DE PORTADA*</p>
        <div *ngIf="!croppedImage || croppedImage == ''"
            class="main-img d-flex justify-content-center align-items-center pointer-in" (click)="file.click()">
            <i class="fa-thin fa-plus m-0"></i>
        </div>
        <div *ngIf="(croppedImage && croppedImage !== '')"
            class="main-img d-flex justify-content-center align-items-center img-event pointer-in"
            (click)="file.click()">
            <img fallimg [src]="croppedImage" alt="Imagen principal">
            <i class="fa-thin fa-check m-0"></i>
        </div>
        <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)" required>

        <div class="w-100">
            <!-- <div class="d-flex justify-content-end align-items-center check-draft"
                style="margin-bottom: 20px; gap: 10px;">
                <p class="m-0">Publicar evento</p>
                <mat-checkbox color="primary" formControlName="draft"></mat-checkbox>
            </div> -->
            <mat-divider></mat-divider>
            <mat-form-field appearence="fill" class="w-100 label-title" [hideRequiredMarker]="true">
                <mat-label class="d-flex justify-content-between w-100">
                    <span>Título del evento*</span>
                    <span class="count-letters">{{ 200 - title.value.length }}</span>
                </mat-label>
                <input #title matInput type="text" placeholder="Título del evento" maxlength="200" (change)="saveTitle($event)"
                    formControlName="titulo">
            </mat-form-field>
            <!-- Descripcion -->
            <mat-divider class="divider-focused"></mat-divider>
            <div class="text-area-description pointer-in" (click)="openOptional('description')">
                <div class="d-flex desc-header" *ngIf="textArea">
                    <p class="m-0">Descripción*</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <input *ngIf="!textArea" class="pointer-in" style="font-size: 16px;" type="text"
                        placeholder="Descripción*" readonly>
                    <div *ngIf="textArea" class="short-text" [innerHTML]="textArea"></div>
                    <i matSuffix class="fa-light fa-angle-right"></i>
                </div>
            </div>
            <mat-divider class="divider-focused"></mat-divider>
            <!-- Lugar -->
            <div class="d-flex align-items-center w-100 pointer-in" (click)="openOptional('location')">
                <input class="pointer-in w-100" style="font-size: 16px;" type="text" placeholder="Lugar" [value]="lugar"
                    readonly>
                <i matSuffix class="fa-light fa-angle-right"></i>
            </div>
            <mat-divider class="divider-focused"></mat-divider>
            <!-- Fechas y horarios -->
            <div class="d-flex align-items-center w-100 pointer-in" (click)="openOptional('dates')">
                <input class="pointer-in w-100" style="font-size: 16px;" type="text" placeholder="Fecha y hora*"
                    [value]="fecha" readonly>
                <i matSuffix class="fa-light fa-angle-right"></i>
            </div>
            <mat-divider class="divider-focused"></mat-divider>
            <!-- Entradas -->
            <div *ngIf="profile?.site">
                <div class="d-flex align-items-center w-100 pointer-in" (click)="openOptional('tickets')">
                    <i matPrefix class="fa-light fa-ticket"></i>
                    <input class="pointer-in w-100" style="font-size: 16px;" type="text"
                        placeholder="Entradas y precios" [value]="textTicket" readonly>
                    <i matSuffix class="fa-light fa-angle-right"></i>
                </div>
                <mat-divider class="divider-focused"></mat-divider>
            </div>
            <!-- Configuración -->
            <div class="d-flex align-items-center w-100 pointer-in" (click)="openOptional('config')">
                <i matPrefix class="fa-light fa-gear"></i>
                <input class="pointer-in w-100" style="font-size: 16px;" type="text"
                    placeholder="Configuración de venta" value="Configuración de venta" readonly>
                <i matSuffix class="fa-light fa-angle-right"></i>
            </div>
            <mat-divider class="divider-focused"></mat-divider>
            <!-- Fotos -->
            <!-- <div class="d-flex align-items-center w-100 pointer-in">
                <i matPrefix class="fa-light fa-camera"></i>
                <input readonly class="pointer-in w-100" style="font-size: 16px;" type="text"
                    placeholder="Subir más fotos" *ngIf="extraFiles.length <= 0" (click)="file2.click()">
                <div *ngIf="extraFiles.length > 0" class="extra-img d-flex align-items-center pointer-in w-100">
                    <div *ngFor="let img of extraFiles; let index=i"
                        class="img-extra d-flex justify-content-center align-items-center pointer-in"
                        (click)="deleteExtraImg(i)">
                        <img fallimg [src]="img.file" [alt]="img.name">
                        <i class="fa-regular fa-times m-0 delete-extra"></i>
                    </div>
                    <div class="more-img d-flex justify-content-center align-items-center pointer-in"
                        (click)="file2.click()">
                        <i class="fa-thin fa-plus m-0"></i>
                    </div>
                </div>
                <input id="file2" #file2 hidden type="file" multiple accept="image/*"
                    (change)="uploadMoreFiles($event, second)">
                <i matSufix class="fa-light fa-angle-right" style="justify-self: inline-end;"
                    (click)="file2.click()"></i>
            </div>
            <mat-divider></mat-divider> -->
            <!-- Links -->
            <!-- <mat-form-field appearence="fill" class="w-100 label-title" [floatLabel]="false">
                <i matPrefix class="fa-light fa-link"></i>
                <input matInput type="url" placeholder="Link entradas/streaming" formControlName="url">
            </mat-form-field>
            <mat-divider class="divider-focused"></mat-divider> -->
            <!-- Hashtags -->
            <mat-form-field class="label-title w-100" appearance="fill">
                <mat-chip-grid #chipList aria-label="#Hashtags">
                    <mat-chip-row *ngFor="let item of hashtags" (removed)="removeHashtag(item)">
                        #{{item}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input placeholder="#Hashtags" style="font-size: 16px;" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addHashtag($event)">
                </mat-chip-grid>
            </mat-form-field>
            <mat-divider></mat-divider>
        </div>
    </form>
</div>
<ng-container *ngIf="tabOptional && tabOpen == 'description'">
    <app-description-lok [textArea]="textArea" (emitDescription)="saveDescription($event)"></app-description-lok>
</ng-container>

<ng-container *ngIf="tabOptional && tabOpen == 'location'">
    <app-map-search [online]="online" (saveLocation)="saveLocation($event)"
        (saveOnline)="saveOnline($event)"></app-map-search>
</ng-container>

<ng-container *ngIf="tabOptional && tabOpen == 'dates'">
    <app-dates-hours [fechas]="lokForm.value.fechas" [existsTickets]="existsTickets" [horarios]="lokForm.value.horarios"
        [auxArr]="auxFechasHorarios" (saveDates)="saveDates($event)"></app-dates-hours>
</ng-container>

<ng-container *ngIf="tabOptional && tabOpen == 'tickets'">
    <app-tickets [configTickets]="configTickets" [profile]="profile" [entradas]="tickets" [idMap]="mapSelect"
        (addTicket)="openCreateTicket($event)" (selectMap)="openMapList($event)" (saveTickets)="saveAllTickets($event)"
        [online]="lokForm.value.online"></app-tickets>
</ng-container>

<ng-container *ngIf="tabOptional && tabOpen == 'add'">
    <app-new-ticket [ticket]="newTicket" [optionsTime]="auxFechasHorarios"
        (saveTickets)="saveTicket($event)"></app-new-ticket>
</ng-container>

<ng-container *ngIf="tabOptional && tabOpen == 'map'">
    <app-maps-list [idSelect]="mapSelect" (saveSelectMap)="saveMap($event)"></app-maps-list>
</ng-container>

<ng-container *ngIf="tabOptional && tabOpen == 'config'">
    <app-config-tickets [fromNew]="true" [profile]="profile" [configIn]="configTickets" [allowExternal]="true"
        (sendConfig)="saveConfig($event)"></app-config-tickets>
</ng-container>