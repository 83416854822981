<div class="activation-control-page container">
    <div *ngIf="loaded && errorValidateUser">
        <h1 class="text-center mb-4 ">{{errorValidateUser}}</h1>
        <p class="text-center mb-3">Si tienes alguna duda, por favor, ponte en contacto
            con nosotros.</p>
    </div>
    <div *ngIf="loaded && !errorValidateUser">
        <h1 class="text-center mb-4 ">Te damos la bienvenida al Panel de Control de Citylok</h1>
        <p *ngIf="manager" class="text-center mb-3">Enhoabuena, ya puedes gestionar tu equipo desde el panel de control de Citylok. 
            <a class="link" target="_self" [routerLink]="['/login']"> Inicia sesión</a>
            para acceder a todas las acciones.</p>

        <p *ngIf="!manager" class="mb-3">Para completar el proceso, por favor, introduce una contraseña:</p>
        <form [formGroup]="activationForm" enctype="application/x-www-form-urlencoded" *ngIf="!manager">
            <div class="bloque-register container-form d-flex align-items-center register-input"
                [class.invalid]="pass.invalid && (pass.dirty || pass.touched)">
                <i class="fa-regular fa-key icon-primary"></i>
                <input class="w-100" type="{{ hide ? 'password' : 'text' }}" placeholder="Contraseña"
                    formControlName="textPassword" required>
                <i (click)="hide = !hide"
                    class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
            </div>

            <div class="register-input validate-msg" *ngIf="pass.invalid && (pass.dirty || pass.touched)">
                <div class="d-flex align-items-center">
                    <i class="fa-light {{ pass.errors?.['hasSmallCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener minúsculas.</p>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa-light {{ pass.errors?.['hasCapitalCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener mayúsculas.</p>
                </div>
                <div class="d-flex align-items-center">
                    <i class="fa-light {{ pass.errors?.['hasNumber'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener números.</p>
                </div>
                <div class="d-flex align-items-center">
                    <i
                        class="fa-light {{ pass.errors?.['minlength'] || pass.errors?.['required'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                    <p>Debe contener al menos 8 caracteres.</p>
                </div>
                <p *ngIf="pass.errors?.['maxlength']">No debe tener más de 30 caracteres.</p>
            </div>
            <div class="bloque-register container-form d-flex align-items-center register-input">
                <i class="fa-regular fa-key icon-primary"></i>
                <input class="w-100" type="{{ hideConfirm ? 'password' : 'text' }}" placeholder="Confirmar contraseña"
                    formControlName="confirmPassword" required>
                <i (click)="hideConfirm = !hideConfirm"
                    class="fa-regular {{ hideConfirm ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
            </div>
            <div class="register-input validate-msg"
                *ngIf="(repPass.dirty || repPass.touched) && activationForm.errors?.['passwordNotMatch'] && !manager">
                <p *ngIf="activationForm.errors?.['passwordNotMatch']">Las contraseñas no coinciden.</p>
            </div>
            <div class="d-flex register-input align-items-center">
                <mat-checkbox formControlName="condaccept" class="checkbox" color="primary" required>
                </mat-checkbox>
                <p class="text-checkbox m-0">
                    Acepto los <a class="link" target="_blank" [routerLink]="['/terms-conditions']"> términos y
                        condiciones
                        de uso.</a>
                </p>
            </div>
            <div class="d-flex register-input align-items-start">
                <mat-checkbox formControlName="newsletter" class="checkbox" color="primary">
                </mat-checkbox>
                <p class="text-checkbox m-0">
                    También quiero recibir correos electrónicos ocasionales sobre eventos y noticias de tu interés.
                    (Puedo
                    cancelar la suscripción en cualquier momento).
                </p>
            </div>
            <button name="button" class="btn btn-register" [disabled]="activationForm.invalid"
                (click)="completeInvite()">
                Crear cuenta
            </button>
        </form>
    </div>
</div>