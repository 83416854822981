import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-menu',
  templateUrl: './config-menu.component.html',
  styleUrls: ['./config-menu.component.scss']
})
export class ConfigMenuComponent implements OnInit {

  bigScreen = false;

  constructor(){}

  ngOnInit(): void {

    this.bigScreen = window.innerWidth > 920;
    window.addEventListener("resize", event => {
      this.bigScreen = window.innerWidth > 920;
    });

  }


}
