import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SeatMapService } from 'src/app/services/seat-map.service';

@Component({
  selector: 'app-profile-maps',
  templateUrl: './profile-maps.component.html',
  styleUrls: ['./profile-maps.component.scss']
})
export class ProfileMapsComponent implements OnInit {

  listMaps!: Observable<any[]>;
  @Input() profile!: any;

  constructor(private seatMapService: SeatMapService, private router: Router) { }

  ngOnInit(): void {
    this.listMaps = this.seatMapService.getMapsUser().pipe();
    if(this.profile && !this.profile?.admin) {
      this.router.navigate(['/profile']);
    }
  }

  createMap() {
    this.router.navigate(['/create-map']);
  }

}
