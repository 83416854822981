<div class="template-new">
  <h3>{{ idC ? 'Editar Categoría' : 'Nueva Categoría' }}</h3>

  <mat-divider></mat-divider>

  <form [formGroup]="categoria" (ngSubmit)="submit()" class="row col-xl-10 col-xxl-10 col-lg-12 form-new">

      <div *ngIf="filePath && filePath !== ''" >
          <img fallimg (click)="file.click()" class="img-new img-principal" [class.errorfile]="errorfile" [src]="filePath" alt="Imagen Principal">
      </div>
    <!-- <app-warning-admin *ngIf="message" [text]="message"></app-warning-admin> -->
    <app-warning *ngIf="message" [text]="message"></app-warning>

    <div class="col-lg-10 col-md-12 mb-4">
      <div *ngIf="filePath && filePath !== ''">
        <img (click)="file.click()" class="img-new img-principal" [class.errorfile]="errorfile" [src]="filePath"
          alt="Imagen Principal">
      </div>
      <div class="d-flex align-items-center">
        <p class="m-0">Imagen de fondo</p>
        <div style="margin-left: 20px;">
          <div *ngIf="!filePath || filePath == ''" (click)="file.click()" class="main-img mb-2 mt-2 pointer-in"
            [class.error-image]="errorImage">
            <i class="fa-thin fa-plus m-0 fa-lg"></i>
          </div>
          <input id="file" hidden #file type="file" accept="image/*" (change)="onImageChange($event)">
        </div>
      </div>
      <mat-hint class="light-text" style="font-size: 14px;">Recomendado: 1920 x 250 px</mat-hint>
    </div>


    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <!-- Nombre -->
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Nombre *" formControlName="nombre">
        </mat-form-field>
      </div>

      <div class="col-12">
        <!-- Slug -->
        <mat-form-field class="w-100 mb-1" appearance="outline" [class.readInput]="idC">
          <input matInput placeholder="URL* &nbsp; Ej:'deportes-y-aventura'" formControlName="slug" [readonly]="idC">
        </mat-form-field>
      </div>

      <div class="col-12">
        <!-- Icono -->
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Icono* &nbsp; Ej:'fa-camera-movie'" formControlName="icon">
        </mat-form-field>
      </div>

      <!-- ShowHome -->
      <div class="col-12">
        <mat-checkbox formControlName="showhome" color="primary">Mostrar en la página principal</mat-checkbox>
      </div>

      <div class="d-flex justify-content-end">
        <button class="w-20 mr-20 btn btn-cancel" type="button" (click)="openDialog()">Cancelar</button>
        <button class="w-20 m-0 btn btn-primary btn-ok" type="submit">Guardar Cambios</button>
      </div>

    </div>
  </form>
</div>