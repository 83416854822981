<div class="container">
    <div class="page-reset">
        <h2 class="mb-2 mt-3">Cambiar contraseña:</h2>
        <div class="reset-page d-flex mt-4">
            <div class="container-reset w-100">
                <form [formGroup]="resetForm" (submit)="submit()" enctype="application/x-www-form-urlencoded">
                    <div class="bloque-register container-form d-flex align-items-center register-input"
                        [class.invalid]="pass.invalid && (pass.dirty || pass.touched)"
                        [style.background-color]="backgroundColor">
                        <i class="fa-regular fa-key icon-primary"></i>
                        <input class="w-100" type="{{ hide ? 'password' : 'text' }}" placeholder="Contraseña"
                            formControlName="textPassword" required>
                        <i (click)="hide = !hide"
                            class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
                    </div>

                    <div class="register-input validate-msg" *ngIf="pass.invalid && (pass.dirty || pass.touched)">
                        <div class="d-flex align-items-center">
                            <i
                                class="fa-light {{ pass.errors?.['hasSmallCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                            <p>Debe contener minúsculas.</p>
                        </div>
                        <div class="d-flex align-items-center">
                            <i
                                class="fa-light {{ pass.errors?.['hasCapitalCase'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                            <p>Debe contener mayúsculas.</p>
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fa-light {{ pass.errors?.['hasNumber'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                            <p>Debe contener números.</p>
                        </div>
                        <div class="d-flex align-items-center">
                            <i
                                class="fa-light {{ pass.errors?.['minlength'] || pass.errors?.['required'] ? 'fa-circle' : 'fa-circle-check' }}"></i>
                            <p>Debe contener al menos 8 caracteres.</p>
                        </div>
                        <p *ngIf="pass.errors?.['maxlength']">No debe tener más de 30 caracteres.</p>
                    </div>
                    <div class="bloque-register container-form d-flex align-items-center register-input"
                        [style.background-color]="backgroundColor">
                        <i class="fa-regular fa-key icon-primary"></i>
                        <input class="w-100" type="{{ hideConfirm ? 'password' : 'text' }}"
                            placeholder="Confirmar contraseña" formControlName="confirmPassword" required>
                        <i (click)="hideConfirm = !hideConfirm"
                            class="fa-regular {{ hideConfirm ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
                    </div>
                    <div class="register-input validate-msg"
                        *ngIf="(repPass.dirty || repPass.touched) && resetForm.errors?.['passwordNotMatch']">
                        <p *ngIf="resetForm.errors?.['passwordNotMatch']">Las contraseñas no coinciden.</p>
                    </div>
                    <button name="button" class="btn btn-register" [disabled]="resetForm.invalid" type="submit">Guardar</button>
                </form>
            </div>
        </div>
    </div>
</div>