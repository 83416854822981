<div class="info-business card-white-panel">
    <form [formGroup]="venueForm">
        <div class="d-flex flex-wrap conteiner-info-venue">
            <div class="container-in-page col-8">
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Nombre del espacio*</p>
                    </div>
                    <div>
                        <div class="bloque-change-input container-form d-flex align-items-center password-input">
                            <input class="w-100" type="text" placeholder="Nombre" formControlName="name" required>
                        </div>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Localización*</p>
                    </div>
                    <div class="section-search-map">
                        <mat-form-field appearence="fill" class="w-100 label-title pointer-in">
                            <input id="searchMap" #search matInput class="pointer-in bloque-change-input container-form"
                                type="text" placeholder="Buscar localización..." [(ngModel)]="lugar" name="location"
                                (input)="onSearchPlaces()" [matAutocomplete]="auto"
                                [ngModelOptions]="{standalone: true}">
                            <mat-autocomplete (optionSelected)="onSelectPlace(search.value)" #auto="matAutocomplete"
                                [displayWith]="displayFn">
                                <mat-option *ngFor="let option of searchResults" [value]="option"
                                    class="item-option-map">
                                    <div class="item-option">
                                        <i class="fa-solid fa-location-dot"></i>
                                        <span class="">{{option.description}}</span>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Descripción del espacio*</p>
                    </div>
                    <div>
                        <div
                            class="bloque-change-input input-address container-form d-flex align-items-center password-input">
                            <textarea #textarea class="w-100" type="textarea" placeholder="Descripción" maxlength="2500"
                                formControlName="description"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Aforo</p>
                    </div>
                    <div>
                        <div class="bloque-change-input container-form d-flex align-items-center password-input">
                            <input class="w-100" type="number" placeholder="Aforo del espacio"
                                formControlName="capacity">
                        </div>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Mapa de butacas</p>
                    </div>
                    <div>
                        <p>Para crear un mapa de butacas, por favor, ponte en contacto con los administradores de
                            Citylok.</p>
                    </div>
                </div>
            </div>
            <div class="col-4 col-image-business">
                <div class="d-flex align-items-center justify-content-center" style="gap: 10px; margin-bottom: 5px;">
                    <p class="title-image m-0">FOTO DE PORTADA*</p>
                    <i class="fas fa-circle-info icon-primary pointer-in"
                        matTooltip="Será la imágen que se muestre en la vista del espacio."></i>
                </div>
                <div class="container-image" (click)="file.click()">
                    <div class="not-image-business">
                        <i class="fa-thin fa-image" *ngIf="!imgControl.value"></i>
                        <img [src]="imgControl.value" alt="Img user" *ngIf="imgControl.value" />
                    </div>
                    <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
                    <div class="circle-add-image">
                        <i class="fa-light fa-plus"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- <mat-divider></mat-divider> -->
        <div class="container-in-page" *ngIf="false">
            <div class="col-info-business col-12">
                <div class="d-flex align-items-center title-info-business">
                    <mat-checkbox class="pointer-in" formControlName="allowSubscription"></mat-checkbox>
                    <p class="m-0 disabled-title">Permitir suscriptores</p>
                </div>
            </div>
            <div class="col-12">
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Nombre de subscriptores</p>
                    </div>
                    <div>
                        <div class="bloque-change-input container-form d-flex align-items-center password-input">
                            <input class="w-100" type="text" placeholder="Nombre de los miembros"
                                formControlName="membershipTitle" required>
                        </div>
                    </div>
                </div>
                <div class="col-info-business col-12">
                    <div class="d-flex align-items-center title-info-business">
                        <p class="m-0">Texto de suscripción</p>
                    </div>
                    <div>
                        <div class="bloque-change-input container-form d-flex align-items-center password-input">
                            <input class="w-100" type="text" placeholder="Texto de suscripción"
                                formControlName="subscriptionText" required>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="container-in-page">
        <div class="btn-create m-0" (click)="saveVenue()">
            <i class="fal fa-save"></i>
            <p class="m-0">Crear espacio</p>
        </div>
    </div>
    <!-- <mat-divider></mat-divider>
        <div class="container-in-page">
            <div class="btn-create m-0" (click)="updateFile()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Subir programación</p>
            </div>
        </div> -->
</div>