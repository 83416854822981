<div class="show-img-map">
    <p class="name-map">{{titleMap}}</p>
    <div class="icon-close" mat-dialog-close>
        <i class="fa-regular fa-times"></i>
    </div>
    <div class="container-map">
        <!-- Botones zoom -->
        <!-- <div class="buttons-zoom-map">
            <button (click)="zoomMap(true)">
                <i class="fa-regular fa-plus"></i>
            </button>
            <button (click)="zoomMap(false)">
                <i class="fa-regular fa-minus"></i>
            </button>
        </div> -->

        <!-- Boton fullscreen -->
        <!-- <div class="buttons-zoom-map">
            <button (click)="fullscreeenMap()">
                <i class="fa-regular fa-plus"></i>
            </button>
        </div> -->

        <div>
            <img id="imageMap" src="" />
        </div>
    </div>
</div>