<div class="card-verify-sms">
    <p class="title-verify">Introduce el correo electrónico</p>

    <div>
        <div class="item-form container-verify d-flex align-items-center">
            <i class="fa-light fa-envelope icon-primary"></i>
            <div class="w-100">
                <input class="input-custom" type="text" [(ngModel)]="emailUser">
            </div>
        </div>
        <div class="d-flex justify-content-center btn-verify">
            <button class="primary-button solid w-100" style="min-height: 40px;"
                (click)=rememberPassword()>Enviar correo</button>
        </div>
    </div>
</div>