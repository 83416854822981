import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-menu-events',
  templateUrl: './menu-events.component.html',
  styleUrls: ['./menu-events.component.scss']
})
export class MenuEventsComponent implements OnInit {

  submenuActive: any = 1;
  titleTab: string = 'Información del evento';
  urlPath: string = '/panel-control/events/detail';
  @Input() idEvent!: number;
  @Input() draft!: boolean;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  allowCreate: boolean = false;
  allowOrder: boolean = false;

  constructor(private auth: AuthenticationService) { }

  ngOnInit(): void {
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS') {
        this.allowCreate = true;
      }
      if (element == 'ACCESS_SUPPORT' || element == 'ACCESS_REPORTS' || element == 'ACCESS_SALES') {
        this.allowOrder = true;
      }
    });
  }

  ngOnChanges() {
    if(this.draft) {
      this.urlPath = '/panel-control/events/draft/detail';
    }
  }

  changeTabEvent(emit: any) {
    this.submenuActive = emit.tab;
    this.titleTab = emit.name;
    this.changeTab.emit(emit);
  }

}
