import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoriasService } from 'src/app/services/categorias.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { EntradasService } from 'src/app/services/entradas.service';
import { EventosService } from 'src/app/services/eventos.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-new-lok',
  templateUrl: './new-lok.component.html',
  styleUrls: ['./new-lok.component.scss']
})
export class NewLokComponent implements OnInit {

  profile!: any;
  categorias: Observable<any[]>;
  categorySelect!: any;
  loadingCategories: boolean = true;

  titleDialog = 'Selecciona una categoria';
  tabOptional: any = false;
  labelDetail = 'Detalles del evento';
  formValid: boolean = false;
  openEditTicket: boolean = false;

  formLok!: any;
  online: boolean = false;
  creating: boolean = false;

  constructor(private categoriasService: CategoriasService, private dialog: MatDialog,
    private entradas: EntradasService, private eventos: EventosService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.auth.getUsersMe().subscribe({
      next: result => { if (result.email) { this.profile = result } }
    });
    const user = this.auth.user;
    if (!user) {
      this.dialog.closeAll();
    }
    this.categoriasService.getAllCategories().subscribe({
      next: (data) => {
        this.categorias = data;
        this.loadingCategories = false;
      }
    })
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  selectCategory(categoria: any) {
    if (this.categorySelect == categoria) {
      this.categorySelect = null;
    } else {
      this.categorySelect = categoria;
    }
  }

  selectStep(event: any) {
    this.titleDialog = event.selectedStep.label;
  }

  tabOptionalChange(optional: any) {
    if (((this.titleDialog == 'Crear entrada' || this.titleDialog.indexOf('mapa') != -1) && !optional)) {
      this.titleDialog = 'Entradas / Tickets';
      const tab = this.titleDialog.indexOf('mapa') != -1 ? 'map' : 'tickets';
      this.entradas.setTab(tab);
      this.tabOptional = this.titleDialog;
    } else {
      this.tabOptional = optional;
      this.titleDialog = optional;
      if (!optional) {
        this.titleDialog = 'Detalles del evento';
      }
    }
  }

  closeDialog() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
      }
    })
  }

  isNextActivated(selectIndex: any) {
    let result = false;
    switch (selectIndex) {
      case 0:
        result = this.categorySelect != null;
        break;
      case 1:
        result = this.formValid;
        break;
    }
    return result;
  }

  statusFormChange(event: any) {
    this.formValid = event.valid;
    this.formLok = event?.form;
    this.online = this.formLok?.online;
  }

  createLok(publish: boolean) {
    const fechasActive = this.formLok?.fechas?.filter(value => value.activo);
    if (!this.formLok) {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Comprueba todos los campos.',
          btnCancel: true
        }
      });
      return;
    }

    if (this.formValid) {
      this.creating = true;
      this.formLok.usuario = this.auth.user;
      this.formLok.categoria = this.categorySelect['id'];
      if (!publish) {
        this.formLok.active = false;
        this.formLok.draft = false;
      } else {
        this.formLok.active = true;
        this.formLok.draft = true;
      }
      // Comprobar si no tiene título o fecha no será válido y será obligatorio el borrador
      // Comprobar también en las llamadas de la API
      if ((!this.formLok.titulo || this.formLok.titulo == ' ' || this.formLok.fechas.length <= 0)) {
        if (publish) {
          this.formLok.draft = true;
          this.formLok.active = false;
          this.openInfo('Faltan algunos campos para poder publicar el evento, se guardará como borrador.', true, true, false).then((value) => {
            if (value) {
              this.createEvent();
            } else {
              this.creating = false;
            }
          });
        } else {
          this.createEvent();
        }
      } else {
        this.createEvent();
      }
    }
  }

  createEvent() {
    this.eventos.postEventos(this.formLok).subscribe(res => {
      if (res) {
        this.creating = false;
        if (this.formLok.file) {
          this.eventos.putEventoImage(this.formLok.file, res.id).subscribe(upload => {
            if (upload) {
              this.formValid = true;
            }
          });
        }
        // Crear imagen de evento
        // Si tiene imagenes subir imagenes extra
        this.openInfo('El evento se ha creado correctamente.', false, false, true)
      }
    })
  }

  async openInfo(msg: string, btnCancel: boolean, icon: boolean, close: boolean): Promise<any> {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: btnCancel
      }
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(res => {
        let result = false;
        if (res) {
          result = true;
          if (close) {
            this.dialog.closeAll();
          }
        }
        this.creating = false;
        resolve(result);
      })
    });
  }

}
