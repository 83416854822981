
import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
interface IInterval {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable({
  providedIn: 'root'
})
export class CountdownService {

  constructor() { }

  getInitialCountDown(timeToGo: number) {
   return this.getIntervalTime(timeToGo, Date.now());

  }

  getCountDown(timeToGo: number) {
    const obvs = new Observable((observer) => {
      interval(1000).subscribe(val => observer.next(this.getIntervalTime(timeToGo, Date.now())))
    })
   return obvs;


  }

  getIntervalTime(dateA: number, dateB: number): IInterval {
    let intervalTime: number = Math.floor((dateA - dateB) / 1000);
    return this.getTime(intervalTime);
  }
  private getTime(time: number): IInterval {
    let interval: IInterval =
      { days: 0, hours: 0, minutes: 0, seconds: 0 };
    interval.days = Math.floor(time / 86400);
    time -= interval.days * 86400;
    interval.hours = Math.floor(time / 3600) % 24;
    time -= interval.hours * 3600;
    interval.minutes = Math.floor(time / 60) % 60;
    time -= interval.minutes * 60;
    interval.seconds = time % 60;
    return interval;
  }


}
