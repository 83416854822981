<div class="menu-notifications">
    <!-- Notifications User -->
    <div class="section" *ngIf="!oficial">
        <p class="title-section m-0">Usuario</p>
        <mat-divider></mat-divider>

        <!-- Options -->
        <div class="option">
            <p class="m-0 option-section">Tus intereses en tu ciudad</p>
            <mat-slide-toggle [(ngModel)]="notifications.city"></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>

        <!-- Options -->
        <div class="option">
            <p class="m-0 option-section">Actividad usuarios que sigues</p>
            <mat-slide-toggle [(ngModel)]="notifications.followed"></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>

        <!-- Options -->
        <div class="option">
            <p class="m-0 option-section">Actividad sitios que sigues</p>
            <mat-slide-toggle [(ngModel)]="notifications.useroficial"></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>
    </div>

    <!-- Notifications Oficial -->
    <div class="section" *ngIf="oficial || verified">
        <p class="title-section m-0">Creador</p>
        <mat-divider></mat-divider>

        <!-- Options -->
        <div class="option">
            <p class="m-0 option-section">Actividad venta entradas</p>
            <mat-slide-toggle [(ngModel)]="notifications.tickets"></mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>
    </div>

    <div class="d-flex justify-content-center" style="margin-top: 40px;">
        <button class="primary-button solid w-100" style="min-height: 40px;" (click)=saveNotifications()>Guardar cambios</button>
    </div>
</div>