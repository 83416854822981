<form [formGroup]="loginForm" enctype="application/x-www-form-urlencoded">
    <div class="bloque-login container-form d-flex align-items-center" [style.background-color]="backgroundColor">
        <i class="fa-regular fa-envelope icon-primary"></i>
        <input class="w-100" type="email" placeholder="Email" formControlName="username">
    </div>
    <div class="bloque-login container-form d-flex align-items-center password-input" [style.background-color]="backgroundColor">
        <i class="fa-regular fa-key icon-primary"></i>
        <input class="w-100" type="{{ hide ? 'password' : 'text' }}" placeholder="Password" formControlName="password">
        <i (click)="hide = !hide" class="fa-regular {{ hide ? 'fa-eye-slash' : 'fa-eye' }} icon-primary icon-password"></i>
    </div>
    <button name="button" class="btn btn-login" (click)="login()">{{ textBtn }}</button>
    <p class="info-login m-0 mt-2">¿Has olvidado la contraseña? <a class="link" (click)="openRemember()"> Recordar</a></p>
    <div class="divider">
        <div class="line"><mat-divider></mat-divider></div>
        <div class="text-divider">o </div>
        <div class="line"><mat-divider></mat-divider></div>
    </div>
                
    <div class="container-social d-flex align-items-center justify-content-center pointer-in">
        <div class="google-btn d-flex justify-content-center" #customBtn>
            <asl-google-signin-button (onclick)='signInWithG()' type='standard' shape='circle' size='large'></asl-google-signin-button>      
        </div>
        <div class="btn-google-sign bloque-login google-container" [style.background-color]="backgroundColor">
            <img fallimg src="/assets/img/google_icon.png" alt="Google">
            <span>Iniciar sesión con Google</span>
        </div>
    </div>

    <!-- <div class="bloque-login container-social d-flex align-items-center justify-content-center pointer-in"
        [style.background-color]="backgroundColor" (click)='signInWithFacebook()'>
        <i class="fa-brands fa-facebook icon-facebook"></i>
        <span>Iniciar sesión con Facebook</span>
    </div> -->
</form>
<p class="info-login m-0 mt-2">¿Aún no tienes cuenta? <a class="link" (click)="goToRegister()"> Regístrate.</a></p>