import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute, NavigationEnd } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from "jwt-decode";
import { PermissionService } from '../services/permission.service';


@Injectable({
  providedIn: 'root'
})
export class FunctionsGuard implements CanActivate {

  constructor(private permissionService: PermissionService, private authenticationService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const enableFunctions = route.data.accessRoles;

    var token = localStorage.getItem('jwt_token');
    if (token === null) {
    }

    const business = JSON.parse(localStorage.getItem('business') || '{}');
    const user = this.authenticationService.getProfile() || '{}';
    return this.permissionService.getPermissionUser(business.id, user.id).pipe(map((res: any) => {
      const rolesUser = res.data.roles.map((data: any) => data.role.code);
      let result = false;
      rolesUser.forEach(element => {
        if (enableFunctions.includes(element)) {
          result = true;
        }
      });
      return result;
    }), catchError(err => { return of(false) }));
  }

}
