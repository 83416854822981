import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateCanvasComponent } from './generate-canvas/generate-canvas.component';
import { CreateMapComponent } from './create-map/create-map.component';
import { MaterialModule } from 'src/app/material/material.module';
import { SelectTypeComponent } from './select-type/select-type.component';
import { EditSeatsWithNumberComponent } from './edit-seats-with-number/edit-seats-with-number.component';
import { NameMapComponent } from './name-map/name-map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LevelTypesComponent } from './level-types/level-types.component';
import { HiddenSeatsComponent } from './hidden-seats/hidden-seats.component';
import { EditSectionComponent } from './edit-section/edit-section.component';
import { EditSeatsComponent } from './edit-seats/edit-seats.component';
import { EditInfoSectionComponent } from './edit-info-section/edit-info-section.component';



@NgModule({
  declarations: [
    GenerateCanvasComponent,
    CreateMapComponent,
    SelectTypeComponent,
    EditSeatsWithNumberComponent,
    NameMapComponent,
    LevelTypesComponent,
    HiddenSeatsComponent,
    EditSectionComponent,
    EditSeatsComponent,
    EditInfoSectionComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CreateMapComponent
  ]
})
export class SeatMapModule { }
