import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CategoriasService } from 'src/app/services/categorias.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-intereses',
  templateUrl: './intereses.component.html',
  styleUrls: ['./intereses.component.scss']
})
export class InteresesComponent implements OnInit {

  categorias!: Observable<any[]>;
  categoriasSelected: any[] = [];

  userId!: any;

  constructor(private categoriasService: CategoriasService, private userService: UserService, 
    private auth: AuthenticationService, private router: Router, private dialog: MatDialog) {
    this.userId = this.auth.user;
  }

  ngOnInit(): void {
    this.categorias = this.categoriasService.getAllCategories().pipe();
    this.userService.getPreferencias().subscribe(res => this.categoriasSelected = res);
  }

  selectCategory(category: any) {
    const index = this.categoriasSelected.findIndex(value => value == category['@id']);
    index != -1 ? this.categoriasSelected.splice(index, 1) : this.categoriasSelected.push(category['@id']);
  }

  savePreferences() {
    this.userService.putUser(this.userId, {preferencias: this.categoriasSelected}).subscribe({
      next: (res) => {
        this.router.navigate(["/profile"]).then(() => {
          this.dialog.open(InfoCardComponent, {
            panelClass: 'info-dialog',
            data: {
              icon: false,
              text: 'Se han guardado los cambios correctamente',
              btnCancel: false
          }});  
        });
      }
    })
  }

}
