import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventosService } from 'src/app/services/eventos.service';
import { OrdersEventService } from 'src/app/services/orders-event.service';

@Component({
  selector: 'app-orders-event',
  templateUrl: './orders-event.component.html',
  styleUrls: ['./orders-event.component.scss']
})
export class OrdersEventComponent implements OnInit {

  searchText!: any;
  idEvent!: any;
  loading: boolean = true;

  constructor(private orderService: OrdersEventService, private eventosService: EventosService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.orderService.setLoadingTable(true);
      }
    });
  }

  searchOrder(event) {
    this.searchText = event;
    this.orderService.setSearchOrder(this.searchText);
  }

  openFilters() {

  }

  returnAll() {

  }

}
