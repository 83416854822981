<div class="new-section-maps">
    <div class="d-flex btns-close-section align-items-center justify-content-end">
        <div class="icon-close" mat-dialog-close>
            <i class="fa-regular fa-times"></i>
        </div>
    </div>

    <div class="create-section">
        <p>Crear nueva sección</p>
        <form [formGroup]="sectionForm" (ngSubmit)="saveSection()">
            <div class="bloque-section-name container-form d-flex align-items-center">
                <input class="w-100" type="text" placeholder="Nombre de sección" formControlName="name" required>
            </div>

            <div class="bloque-section-color container-form d-flex align-items-center">
                <input class="w-100" type="text" placeholder="#Hex" [(ngModel)]="colorHex" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeColor()">
                <div class="block-color">
                    <input type="color" [(ngModel)]="colorHex" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeColor()">
                </div>
            </div>
        </form>
    </div>

    <!-- Seleccionar mapa -->
    <button class="btn-new-form btn-save w-100" type="submit" [disabled]="!sectionForm.valid" (click)="saveSection()">GUARDAR</button>
</div>