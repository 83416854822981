<!-- <script src="https://js.stripe.com/v3/"></script>
<script src="https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&components=YOUR_COMPONENTS"></script> -->


<div class="container-step-payment">
    <div class="loading-container" *ngIf="waiting">
        <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
            containerClass="loading-box"></ng-lottie>
    </div>

    <div class="section resume-card">
        <p class="title-section">Resumen</p>
        <div *ngFor="let event of dataBuy.tickets">
            <div *ngFor="let option of event.options">
                <div class="number-event d-flex align-items-center justify-content-between">
                    <div class="d-flex title-event" style="gap: 10px;">
                        <span>{{event.tickets}} x</span>
                        <span>{{event.titulo}}</span>
                    </div>
                    <p class="total-price-event title-event">{{option.priceDate | number: '2.2-2'}} €</p>
                </div>
                <div class="detail-resume">
                    <!-- <div class="locate-event d-flex align-items-center">
                        <i class="fa-light fa-map-marker-alt icon-primary"></i>
                        <p class="m-0">{{event.localizacion}}</p>
                    </div> -->
                    <div class="date-option d-flex align-items-center">
                        <i class="fa-light fa-clock icon-primary"></i>
                        <p class="m-0">{{option.date | date:'EEEE, dd MMM yyyy' | titlecase}}</p>
                        <span *ngIf="option.time"> - </span>
                        <p class="m-0" *ngIf="option.time">{{option.time}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <p class="resume-section">{{ dataBuy.date | date: 'dd MMM' | titlecase }} - {{ dataBuy.time }}</p>
        <div class="d-flex line-product" *ngFor="let line of dataBuy.tickets">
            <span>x{{line.number}}</span>
            <span class="flex-fill">{{line.ticket.nombre}}</span>
            <span class="price-product">{{ (line.ticket.precio*(1-(line.ticket.descuento/100))) | number:'2.2-2' }} €</span>
        </div> -->

        <mat-divider></mat-divider>
        <div class="d-flex price-resume price-gestion justify-content-between" *ngIf="dataBuy.fees">
            <p>Gastos de gestion</p>
            <p class="price-total">{{ (dataBuy.fees) | number:'1.2-2' }} €</p>
        </div>
        <div class="d-flex price-resume justify-content-between">
            <p>Total</p>
            <p class="price-total">{{ (dataBuy.totalPrice) | number:'1.2-2' }} €</p>
        </div>

        <!-- <div class="container-button-paypal">
            <div class="paypal" *ngIf="optionPayment=='paypal'"><ngx-paypal [config]="payPalConfig"></ngx-paypal></div>
            <button class="btn btn-finish" [disabled]="waiting" [class.btn-paypal]="optionPayment=='paypal'" (click)="finishPayment()">Terminar</button>
        </div> -->
    </div>

    <mat-radio-group [(ngModel)]="optionPayment" class="payment-card" [class.payment-active]="waiting">
        <div class="section credit-card">
            <mat-radio-button value="stripe">
                <span class="title-section">Tarjeta de crédito o débito</span>
            </mat-radio-button>
            <!-- <form [formGroup]="formCredit" class="form-credit-card">
                <p class="title-input">Número de la tarjeta</p>
                <div class="input-card forms">
                    <input class="input-custom w-100" type="number" formControlName="number">
                </div>
                <div class="forms">
                    <div class="flex-fill">
                        <p class="title-input">Fecha de caducidad</p>
                        <div class="d-flex" style="column-gap: 8px;">
                            <div class="input-card input-month flex-fill">
                                <mat-select panelClass="optionsFormPayment" formControlName="month" placeholder="Seleccionar">
                                    <mat-option *ngFor="let n of [].constructor(12); let month = index"
                                        [value]="month+1">{{ month+1 | number:'2.0-0' }}</mat-option>
                                </mat-select>
                                <mat-select-trigger matSuffix style="vertical-align: middle;">
                                    <i class="fal fa-angle-down pointer-in"></i>
                                </mat-select-trigger>
                            </div>
                            <div class="input-card input-year flex-fill">
                                <mat-select panelClass="optionsFormPayment" formControlName="year" placeholder="Seleccionar">
                                    <mat-option *ngFor="let year of optionsYear" [value]="year">{{ year }}</mat-option>
                                </mat-select>
                                <mat-select-trigger matSuffix style="vertical-align: middle;">
                                    <i class="fal fa-angle-down pointer-in"></i>
                                </mat-select-trigger>
                            </div>
                        </div>
                    </div>
                    <div class="input-cvv">
                        <p class="title-input">CVC</p>
                        <div class="input-card">
                            <input class="input-custom w-100" placeholder="CVC" maxlength="3" minlength="3" type="text" pattern="\d*" formControlName="cvc">
                        </div>
                    </div>
                </div>
            </form> -->
            <div class="h-100">
                <iframe id="paylands" class="paylands-container" *ngIf="redirectPaylands" [src]="redirectPaylands" width="100%" height="auto"
                    frameborder="0"></iframe>
            </div>
            <!-- <app-paylands-template></app-paylands-template> -->
        </div>
        <!-- <div class="section paypal-card">
            <mat-radio-button value="paypal">
                <img fallimg class="img-paypal" src="/assets/img/logo-paypal.png" alt="Paypal" />
            </mat-radio-button>
        </div> -->
    </mat-radio-group>
</div>
<!-- <button (click)="refundStripe()">Refund Stripe</button> -->