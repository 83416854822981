import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormulariosService } from 'src/app/services/formularios.service';

@Component({
  selector: 'app-contact-panel',
  templateUrl: './contact-panel.component.html',
  styleUrls: ['./contact-panel.component.scss']
})
export class ContactPanelComponent {

  public sent: boolean = false;
  contactForm: FormGroup;
  emailUser!: string;
  problem!: string;

  error: boolean = false;

  constructor(private formBuilder: FormBuilder, private formularioService: FormulariosService,
    private dialogRef: MatDialogRef<ContactPanelComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.emailUser = this.data.email;
    this.contactForm = this.formBuilder.group({
      email: [this.emailUser, [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      problem: ['Otros', [Validators.required]],
      body: ['', [Validators.required]],
    });
  }

  sendContact() {
    if (this.contactForm.invalid) {
      this.error = true;
    }
    if (this.contactForm.valid) {
      var message = JSON.stringify(this.contactForm.value);
      this.formularioService.sendMessage(message).subscribe(
        (response: any) => {
          if (response && response.code === '200') {
            this.dialogRef.close(response);
          } else {
            this.dialogRef.close({ status: 'error' });
          }
        }
      );
    }
  }
}
