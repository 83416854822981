import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss']
})
export class HeaderPanelComponent implements OnInit {

  @Input() titlePanel: string = 'Eventos';
  @Input() profile!: any;

  constructor(private auth: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout() {
    this.auth.logout();
  }

}
