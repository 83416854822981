<div class="chatbot-container">
    <div class="header-chatbot">
        <span mat-dialog-close><i class="fa-light fa-arrow-left icon-primary"></i></span>
        <p class="w-100 text-chat">PREGUNTA A CITYLOK</p>
        <img src="../assets/img/icon-chatbot.svg" alt="Icono Citylok chat">
    </div>
    <div class="content-msg" id="contenedorChat">
        <div *ngFor="let msg of conversation" class="d-flex" [class]="msg.type == 'request' ? 'msgRequest' : 'msgResponse'">
            <img *ngIf="msg.type == 'response'" src="../assets/img/icon-chatbot-left.svg" alt="Icono Citylok chat">
            <p class="m-0 text-chat" [innerHTML]="msg.content"></p>
            <span>
                <i *ngIf="msg.type == 'request'" class="fa-regular fa-check icon-primary"></i>        
            </span>
        </div>
        <div *ngIf="loading" class="d-flex msgResponse">
            <img src="../assets/img/icon-chatbot-left.svg" alt="Icono Citylok chat">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/writing.json'}" containerClass="loading-box"></ng-lottie>
        </div>
    </div>
    
    <div class="input-chat">
        <form (ngSubmit)="sendMessage()" class="w-100">
            <div class="d-flex justify-content-between w-100 label-text" *ngIf="message.value && (message.dirty || message.touched)">
                <span>Mensaje para citylok*</span>
                <span class="count-letters">{{ 400 - message.value?.length }}</span>
            </div>
            <input #reply matInput [readonly]="loading"
            class="w-100 input-chatbot" max-length="400" placeholder="Escribe tu mensaje" 
            [formControl]="message">
        </form>
        <button class="btn-send-msg" (click)="sendMessage()">
            <i class="fa-light fa-paper-plane"></i>        
        </button>
    </div>
</div>
