import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, first } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { Observable, of } from 'rxjs';
import { CommonService } from './common.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CiudadesService {

  constructor(private http: HttpClient, private common: CommonService) { }

  getCiudades() {
    // const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    // const url = `${ AppSettings.API_URI }/ciudades?exists[eventos]=true&exists[eventos.activo]=true&eventos.fechas.fechaFin[after]=${now}`;
    // return this.common.getDataHydra(url);
    const url = `${ AppSettings.API_URI }/ciudad/events`;
    return this.common.getData(url);
  }

  getCiudadbyId(id:number) {
    const url = `${ AppSettings.API_URI }/ciudades/${id}`;
    return this.common.getData(url);
  }

  getCiudadByName(name: string) {
    // const now = formatDate(new Date(), 'yyyy-MM-dd', 'es-ES')
    // const url = `${ AppSettings.API_URI }/ciudades?exists[eventos.activo]=true&nombre=${name}&eventos.fechas.fechaFin[after]=${now}`;
    // return this.common.getDataHydra(url);
    const url = `${ AppSettings.API_URI }/ciudad/events/${name}`;
    return this.common.getData(url);
  }

  getAllCitieByName(name: string) {
    const url = `${ AppSettings.API_URI }/ciudades?nombre=${name}`;
    return this.common.getDataHydra(url);
  }

  postCiudades(nombre:string) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/ciudades`;
    return this.http.post<any>(url, nombre, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  putCiudades(ciudadId: number) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/ciudades/${ ciudadId }`;
    return this.http.put<any>(url, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }

  deleteCiudad(ciudadId: number) {
    const url = `${ AppSettings.API_URI }/ciudades/${ ciudadId }`;
    return this.http.delete<any>( url ).pipe(
      map(data => {
        if(data) {
          return of(true);
        } else {
          return of(false);
        }
      }), catchError(err => {
        return of(false);
      })
    );
  }
}
