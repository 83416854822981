<div class="container content-terms">
    <h3 class="title-terms" style="color: var(--color-primary); ">Aviso Legal</h3>
    <p class="sub-title-terms">Identificación del responsable de la web.</p>

    <p>Las presentes disposiciones regulan el uso del servicio del portal de Internet <a href="https://www.citylok.com"
            target="_blank">www.citylok.com</a>, dominio
        titularidad de KREARE DIGITAL S.L., con C.I.F. B-05338504, domicilio en Calle Alfonso VI nº 4, bajo, de Logroño
        (La Rioja), correo electrónico <a href="mailto:'hola@citylok.com'">hola@citylok.com</a> y teléfono 941135052.
        Esta es una página web que la empresa mencionada pone a disposición de los usuarios de Internet implicando la
        utilización de este portal y la aceptación por parte del usuario de las condiciones de uso incluidas en este
        aviso.
    </p>
    <p>
        El titular advierte que, tanto los contenidos y servicios de esta página web, como las propias condiciones de
        utilización, pueden ser modificados sin notificación previa. Además, informa que podrá terminar, suspender o
        interrumpir, en cualquier momento sin necesidad de previo aviso, el acceso a los contenidos de la página, sin
        posibilidad por parte del usuario de exigir indemnización alguna.
        En caso de no aceptar las cláusulas establecidas en el presente Aviso Legal, deberá abstenerse de acceder y/o
        utilizar los servicios y/o contenidos puestos a su disposición en este portal, procediendo a abandonar la misma.
    </p>

    <p class="sub-title-terms">Propiedad Intelectual e Industrial.</p>
    <p>Todos los contenidos de este sitio web, abarcando estos los textos, fotografías, gráficos, imágenes, iconos,
        tecnología, software, links y demás contenidos audiovisuales o sonoros, así como su diseño gráfico y códigos
        fuente, son propiedad del titular o de terceros, sin que puedan entenderse cedidos al usuario ninguno de los
        derechos de explotación reconocidos por la normativa vigente en materia de propiedad intelectual sobre los
        mismos, salvo aquellos que resulten estrictamente necesarios para el uso del portal.
        Las marcas, nombres comerciales o signos distintivos son propiedad del titular o de terceros, sin que pueda
        entenderse que el acceso al portal atribuya ningún derecho sobre las citadas marcas, nombres comerciales o
        signos distintivos.
    </p>
    <p>
        La titular no garantiza que los contenidos sean precisos o libres de error o que el libre uso de los mismos por
        el usuario no infrinja los derechos de terceras partes. El buen o mal uso de esta página y de sus contenidos
        está bajo la responsabilidad del usuario.
        Asimismo, queda prohibida la reproducción, retransmisión, copia, cesión o redifusión, total o parcial, de la
        información contenida en la página, cualquiera que fuera su finalidad y el medio utilizado para ello, sin
        autorización previa de la responsable.
    </p>

</div>