import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { EventosService } from 'src/app/services/eventos.service';
import { FilterService } from 'src/app/services/filter.service';
import { FiltersComponent } from '../shared/filters/filters.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchFilter!: string;
  filtros: Observable<any[]>;

  viewType: string = 'grid';

  // Infinity scroll
  results: boolean = true;

  // Añadir query para vista map
  
  constructor(private eventosService:EventosService, private activatedRoute: ActivatedRoute, private commonService: CommonService,
    private router: Router, private filterService: FilterService, private dialog: MatDialog) { 
      let filters = this.router.getCurrentNavigation().extras.state?.filters;
      if(filters) {
        let options = this.filterService.getOrderOptions();
        let op = options.find(value => value.name.toLowerCase() == filters.toLowerCase());
        this.filterService.setOrderBy(op);
        this.filterService.saveJsonFilters();
      };
    }

  ngOnInit(): void {
    this.filtros = this.filterService.filter;
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.search) {
        this.searchFilter = params.search;
        localStorage.setItem('search', this.searchFilter);
        this.filterService.saveJsonFilters();
      }
    });
  }

  deleteFilter(filter: any) {
    if(filter == this.searchFilter) {
      this.searchFilter = '';
      localStorage.removeItem('search');
      this.router.navigate(['/results']).then(res => location.reload())
    } else {
      this.filterService.removeFilter(filter);
    }
  }

  existsResults(exists: any) {
    this.results = exists;
  }

  // Abre los filtros
  openFilterDialog() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      width: '570px',
      panelClass: ['panel-dialog', 'animation-right-dialog'],
      position: {top: '0px', right: '0px'}
    })
  }

  ngOnDestroy() {
    localStorage.removeItem('search');
    this.filterService.resetAllFilters();
  }

}
