<div class="header-template-admin"><i class="icon-page far fa-times" (click)="openDialog()"></i><span>Datos del Usuario</span></div>
<mat-divider></mat-divider>
<div class="template-new">
  <h3 *ngIf="idU">Editar Usuario</h3>
  <h3 *ngIf="!idU">Nuevo Usuario</h3>
  <form [formGroup]="user" (ngSubmit)="submit()" class="row col-xl-10 col-xxl-10 col-lg-12">
    <app-warning-admin *ngIf="message" [text]="message"></app-warning-admin>
    <div class="col-lg-10 col-md-12 mb-4 p-1">
      <p>Imagen de usuario</p>
      <div *ngIf="filePath && filePath !== ''">
          <img fallimg (click)="file.click()" class="img-new img-principal" [class.errorfile]="errorfile" [src]="filePath" alt="Imagen Principal">
      </div>
      <div *ngIf="!filePath || filePath == ''" (click)="file.click()" class="main-img mb-2 mt-2 pointer-in" [class.error-image]="errorImage">
          <i class="fa-thin fa-plus m-0"></i>
      </div>
      <input id="file" hidden #file type="file" accept="image/*" (change)="onImageChange($event)">
      <mat-hint class="light-text">Recomendado: X x X px (Max: XMb)</mat-hint>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
        <div class="col-12">
          <mat-form-field class="w-100 mb-1" appearance="outline">
            <input matInput placeholder="Nombre y apellidos*" formControlName="nombre">
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100 mb-1" appearance="outline">
            <input matInput placeholder="Email *" formControlName="email">
          </mat-form-field>
        </div>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Nickname *" formControlName="nickname">
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Fecha nacimiento MM/DD/YYYY" formControlName="fnacimiento" [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="DNI/CIF" formControlName="dni">
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <mat-form-field class="w-100 mb-1" appearance="outline">
          <input matInput placeholder="Móvil" formControlName="movil" maxlength="11" (keypress)="phoneM($event)">
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 column-form">
      <div class="col-12">
        <mat-checkbox formControlName="oficial" color="primary">Oficial</mat-checkbox>
      </div>
    </div>

    <div>
      <button *ngIf="idU" class="w-20 m-0 btn btn-primary btn-ok" type="submit">Guardar Cambios</button>
      <button *ngIf="!idU" class="w-20 m-0 btn btn-primary btn-ok" type="submit">Crear Usuario</button>
    </div>
  </form>
</div>
