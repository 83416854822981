// google-places.service.ts
import { Injectable } from '@angular/core';

import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  private placesService: any;
  private detailsService: any;

  loader = new Loader({
    apiKey: "AIzaSyANyPC9PxHn5FWztGErqjb2brt-jrGlOiw",
    version: "weekly",
    libraries: ["places"]
  });

  google: any;
  map: any;
  public iconNormal = "../../../assets/img/mark.png";
  markers: google.maps.Marker[] = [];
  infowindow: any = null;
  clickFunction: boolean = false;

  public mapLocator = new BehaviorSubject<any>(null);
  public mapsLocatorStorage = this.mapLocator.asObservable();

  constructor() { }

  openMap() {
    this.loader.load().then((google) => {
      this.google = google;
      this.placesService = new google.maps.places.AutocompleteService();
      if (document.getElementById("maplock")) {
        this.map = new google.maps.Map(
          document.getElementById("maplock") as HTMLElement,
          {
            center: {
              lat: 39.5854434, lng: -4.4250326
            },
            zoom: 6,
            mapTypeId: "roadmap",
            streetViewControl: false,
          }
        );
        this.detailsService = new google.maps.places.PlacesService(this.map);
        this.markers = [];
        this.clickOnMap();
      }
    });
  }

  setPlace(place: any) {
    this.mapLocator.next(place);
  }

  searchPlaces(query: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const request = {
        input: query
      };
      this.loader.load().then((google) => {
        this.placesService.getPlacePredictions(request, (results: any[], status: any) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            reject(status);
          }
        });

      });
    });
  }

  getDetail(option: any) {

    this.detailsService = new google.maps.places.PlacesService(document.createElement('div'));
    if (option.place_id || option.placeId) {
      this.loader.load().then((google) => {
        this.detailsService.getDetails({ placeId: option.place_id || option.placeId }, (place: any, status: any) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.mapLocator.next(place);
          }
        });
      });
    }
  }

  selectPlace(option: any) {
    if (option.place_id || option.placeId) {
      this.loader.load().then((google) => {
        this.detailsService.getDetails({ placeId: option.place_id || option.placeId }, (place: any, status: any) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.mapLocator.next(place);
            this.viewOnMap(place);
          }
        });
      });
    }
  }

  selectTextPlace(place: any) {
    // this.mapLocator.next(place);
    this.loader.load().then((google) => {
      this.placesService.getPlacePredictions({ input: place }, (results: any[], status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.mapLocator.next(results[0]);
          // this.viewOnMap(results[0]);
        }
      });
    });
  }

  selectText(query: string) {
    this.loader.load().then((google) => {
      this.detailsService.getPlacePredictions({ input: query }, (results: any[], status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.mapLocator.next(results[0]);
          this.viewOnMap(results[0]);
        }
      });
    });
  }

  clickOnMap() {
    let map = this.map;
    this.clickFunction = true;
    google.maps.event.addListener(map, 'click', this.selectPlace.bind(this));
  }

  viewOnMap(place: any) {
    var icon = this.iconNormal;
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });

    this.map.setCenter(place.geometry.location);
    // Clear out the old markers.
    this.markers = [];
    this.markers.push(
      new google.maps.Marker({
        map: this.map,
        icon: icon,
        title: place.name,
        position: place.geometry.location,
      })
    );
    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }
    this.map.fitBounds(bounds);
  }

}