import { Dialog } from '@angular/cdk/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ComentariosService } from 'src/app/services/comentarios.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  idUser!: any;
  @Input() idEvent!: number;

  comentarios!: any;
  numberShow: number = 3;
  replyComment!: number;
  newValue!: number;

  profile!: any;

  constructor(private comentariosService: ComentariosService, private auth: AuthenticationService,
    private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.idUser = this.auth.user;
    if (this.idUser != null) {
      this.auth.getUsersMe().subscribe(usr => {
        if (usr && usr.id) {
          this.profile = usr;
        }
      });
    }
    if (this.idEvent) {
      this.getComments(3);
    }
  }

  getComments(numItems: number) {
    this.comentariosService.getComentarios(this.idEvent, numItems).subscribe(res => {
      this.comentarios = res;
    });
  }

  getMoreComments() {
    if (this.comentarios.totalItems > this.comentarios.comments.length) {
      this.numberShow += 2;
      this.getComments(this.numberShow);
    } else {
      this.numberShow = 3;
      this.comentarios.comments = this.comentarios.comments.slice(0, 3);
    }
  }

  closeComments() {
    this.numberShow = 3;
    this.getComments(3);
  }

  writeReply(idComment: number) {
    if(!this.profile) {
      this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Inicia sesión y empieza a comentar tus eventos favoritos',
          btnCancel: false
        }
      });
      return;
    }
    if (this.replyComment == idComment) {
      this.replyComment = null;
      this.getComments(this.numberShow);
      if (idComment == 0) {
        var height = document.getElementById(`comment-${this.numberShow}`).offsetTop;
        window.scrollTo(0, height - 400);
      }
    } else {
      this.replyComment = idComment;
      if (this.comentarios.totalItems > 2) {
        if (idComment == 0) {
          this.getComments(this.comentarios.totalItems);
          var height = document.getElementById('newComment').offsetTop;
          window.scrollTo(0, height);
        } else {
          var element = document.getElementById(`resp-${idComment}`);
          window.scrollTo(0, (element.offsetTop + element.offsetHeight - 300));
        }
      }
    }
  }

  sendComment(comment: string) {
    let comentario: any = {
      user: this.idUser,
      eventId: this.idEvent,
      comment: comment
    };
    if (this.replyComment) {
      comentario.idComment = this.replyComment;
    }
    this.comentariosService.postComentario(comentario).subscribe(response => {
      let newComment = { id: response.id, usuario: this.profile.nombre, comentario: comment, respuestas: [] }
      this.newValue = response.id;
      if (this.replyComment) {
        const indexReply = this.comentarios.comments.findIndex(value => value.id == this.replyComment);
        this.comentarios.comments[indexReply].respuestas.push(newComment);
      } else {
        this.numberShow = this.comentarios.comments.length + 1;
        this.comentarios.totalItems++;
        this.comentarios.comments.push(newComment)
      }
      this.replyComment = null;
    })
  }

}
