<div class="post-page container">
    <div class="header-post">
        <div class="container-header" style.background-image="url('{{ post?.acf.img_main }}')">
            <div class="container" style="height: 100%; position: relative;">
                <div class="text-header container-padding">
                    <div class="date-post d-flex align-items-center">
                        <i class="fa-light fa-calendar"></i>
                        <p class="m-0">{{ post?.date | date: 'dd MMMM yyyy' | titlecase }}</p>
                    </div>
                    <div class="title-post" [innerHTML]="post?.title.rendered"></div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="container-padding info-post row justify-content-between">
        <div class="col-xl-7 col-12 p-0 main-col">
            <!-- Contenido -->
            <div class="p-0 content-post" [innerHTML]="post?.content.rendered"></div>

            <!-- Etiquetas -->
            <div *ngIf="tags && tags.length > 0">
                <app-etiquetas title="Etiquetas" icon="fa-tag" [items]="tags"></app-etiquetas>
            </div>

            <!-- Shared -->
            <div class="content-shared d-grid d-sm-flex align-items-center justify-content-sm-start justify-content-center">
                <p class="title-section m-0"><i class="fa-light fa-share-nodes"></i>¡Comparte este post!</p>
                <div class="d-flex icons-share-event justify-content-sm-start justify-content-around">
                <i class="fa-brands icon-primary fa-x-twitter"></i>
                <i class="fa-brands icon-primary fa-linkedin"></i>
                <i class="fa-brands icon-primary fa-facebook-f"></i>
                <i class="fa-solid icon-primary fa-envelope"></i>
                </div>
            </div>
        </div>
        <div class="col p-0">
            <app-last-posts></app-last-posts>
        </div>
    </div>
</div>


<div class="events-close">
    <app-cards title="Eventos destacados cerca de tí" [arrow]="false" [events]="eventosDestacados"></app-cards>
  </div>