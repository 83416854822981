<form [formGroup]="schedule" (ngSubmit)="saveHour()" class="d-grid card-input-hour">
    <span class="close-input pointer-in" mat-dialog-close>
        <i class="fa-regular fa-times"></i>
    </span>
    <div class="d-flex justify-content-center align-items-center title-new-hour">
        <i class="fa-thin fa-clock"></i>
        <span>Introduce la hora</span>
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <div class="input-hour">
            <input placeholder="20" type="number" min="0" max="23" formControlName="hour" required />
        </div>
        <span class="points">:</span>
        <div class="input-hour">
            <input placeholder="00" type="number" step="5" min="0" max="59" formControlName="minutes" required/>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center text-inputs">
        <span>Hora</span>
        <span>Minutos</span>
    </div>
    <div class="align-items-center d-grid justify-content-center">
        <button class="primary-button" type="submit" (click)="saveHour()">Aceptar</button>
    </div>
</form>