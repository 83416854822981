import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, repeat, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class FechasService {

  private fechasSelected = new BehaviorSubject<any>(0);
  public fechas = this.fechasSelected.asObservable();

  fecha = {
    "fechaInicio": "2023-02-24T00:00:00+00:00",
    "fechaFin": "2023-02-24T00:00:00+00:00",
    "activo": true,
    "repeat": false,
    "typeRepeat": null,
    "repeatInterval": [],
    "repeatFin": null
  };

  horario = {
    "horaInicio": "1970-01-01T21:00:00+00:00",
    "horaFin": "1970-01-01T23:00:00+00:00",
    "fixedSchedule": false,
    "special": false,
    "dayWeek": [
        5,
        0
    ],
    "dayMonth": [],
    "activo": true
  };

  constructor(private http: HttpClient) { }

  getFechasHours(fechas: any, horarios: any[]) {
    const start: Date = new Date(fechas.fechaInicio);
    const end: Date = new Date(fechas.fechaFin||fechas.fechaInicio);
    let arrayFechas = [];
    if(!fechas.repeat) {
      let days = Math.floor((end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24);
      let i = 0;
      let aux = start;
      do {
        const string = formatDate(aux, 'EEE dd', 'es-ES');
        arrayFechas.push(string.toUpperCase());
        aux.setDate(aux.getDate() + 1);
        i++;
      } while (i < days);
    } else {
      switch (fechas.typeRepeat) {
        case 'week':
          
          break;
        case 'month':
          
          break;
      }
    }
  }

  editDatesHours(eventoId: any, payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/edit/dates/${eventoId}`;
    return this.http.put<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }

  editTickets(eventoId: any, payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/edit/tickets/${eventoId}`;
    return this.http.put<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      })
    );
  }

  editLevelPrice(eventoId: any, payload: any) {
    const url = `${AppSettings.API_URI}/edit/level/tickets/${eventoId}`;
    return this.http.put<any>(url, payload).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }

  addTicketLevels(eventoId: any, payload: any) {
    const url = `${AppSettings.API_URI}/add/level/ticket/${eventoId}`;
    return this.http.post<any>(url, payload).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
