import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { AppSettings } from 'src/app/app-settings';
import { FacebookLoginProvider } from "@abacritt/angularx-social-login";

const bcrypt = require('bcryptjs');

@Component({
  selector: 'app-register-card',
  templateUrl: './register-card.component.html',
  styleUrls: ['./register-card.component.scss']
})
export class RegisterCardComponent implements OnInit {

  registerForm: FormGroup;

  hide: boolean = true;
  hideConfirm: boolean = true;
  salt = bcrypt.genSaltSync(10);

  numerosValidate: boolean = false;
  titlecaseValidate: boolean = false;
  lowerValidate: boolean = false;
  socialUser!: SocialUser;
  social = '';

  @Input() backgroundColor: any = '#F8F8F8';
  @Input() textBtn: string = 'resgistrate';
  @Output() msgError: EventEmitter<string> = new EventEmitter();
  @Output() goLogin: EventEmitter<boolean> = new EventEmitter();
  @Output() correctForm: EventEmitter<boolean> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, private socialAuthService: SocialAuthService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      // nickname: ['', Validators.required],
      nombre: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email]],
      textPassword: ['', Validators.compose([
        Validators.required,
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
        Validators.maxLength(30)
      ])],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
      condaccept: [false, [Validators.required, Validators.requiredTrue]],
      newsletter: [false]
    }, {
      validators: this.password.bind(this)
    });
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.social != 'facebook' ? this.signInWithG() : this.signInWithFB();
    });
  }

  // Validacion contraseña
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.controls['textPassword'];
    const { value: confirmPassword } = formGroup.controls['confirmPassword'];
    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordNotMatch: true };
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = regex.test(control.value);
      if (valid) {
        return null;
      } else {
        return error;
      }
    };
  }

  get pass() { return this.registerForm.get('textPassword'); }
  get repPass() { return this.registerForm.get('confirmPassword'); }

  register() {
    if (this.registerForm.invalid) {
      this.msgError.emit('Rellena todos los campos');
      return;
    }
    var password = this.registerForm.controls['textPassword'].value;
    var hash = bcrypt.hashSync(password, this.salt);

    const json = this.registerForm.value;
    const registerJson = {
      nickname: json.nickname,
      nombre: json.nombre,
      email: json.email,
      password: hash,
      condaccept: json.condaccept,
      newsletter: json.newsletter
    };
    this.authService.register(registerJson).subscribe({
      next: (data) => {
        if (data == 2) {
          this.msgError.emit("Este nickname ya existe");
        } else if (data == 1) {
          this.msgError.emit("Este Email ya existe");
        } else {
          //ToDO Change Default home component when user is validated
          this.msgError.emit('Te has registrado correctamente!');
          this.correctForm.emit(true);
        }
      },
      error: (e) => {
        this.msgError.emit('No has podido registrarte, vuelve a intentarlo de nuevo');
      }
    });
  }
  //ToDo FACEBOOK, waiting have an account
  signInWithG(): void {
    this.social = 'google';
    localStorage.removeItem('jwt_token');
    this.authService.loginGoogle(this.socialUser.id, this.socialUser.idToken).subscribe((data) => {
      if (data.code == "200" || data.code == "201") {
        this.correctForm.emit(true);
      }
    },
      (error) => {
        if (AppSettings.DEBUG) {
          console.log('ERROR');
        }
      }
    )
  };

  signInWithFacebook(): void {
    this.social = 'facebook';
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.loginFacebook(this.socialUser).subscribe({
      next: (data) => {
        if (data.code == "200" || data.code == "201") {
          this.msgError.emit('Te has registrado correctamente!');
          this.correctForm.emit(true);
        }
      },
      error: (error) => {
        this.msgError.emit('No has podido registrarte, vuelve a intentarlo de nuevo');
      }
    })
  };
}
