import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiBusinessService } from 'src/app/services/api-business.service';
import { HelpBusinessComponent } from '../help-business/help-business.component';
import { InfoBusinessComponent } from '../info-business/info-business.component';

@Component({
  selector: 'app-router-business',
  templateUrl: './router-business.component.html',
  styleUrls: ['./router-business.component.scss']
})
export class RouterBusinessComponent {

  pageActive!: any;
  tabSelected: number = 0;
  titleTab: string = 'Datos de la organización';

  business: any;
  owner!: any;
  businessSelect!: any;
  places: any[] = [];
  description!: any;

  constructor(private activatedRoute: ActivatedRoute, private apiBusiness: ApiBusinessService) { }

  ngOnInit(): void {
    this.businessSelect = JSON.parse(localStorage.getItem('business') || '{}');
    this.places = JSON.parse(localStorage.getItem('places') || '[]');

    this.apiBusiness.getBusinessById(this.businessSelect?.id).subscribe({
      next: (res: any) => {
        this.business = res.business;
        this.description = res.description;
        this.business['image'] = res['image'] ? res['image'] : null;
        this.owner = res.owner;
      }
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.pageActive = params?.typePage || 'info-business';
      this.getPage();
    });
  }

  getPage() {
    if (this.pageActive === 'info-business') {
      this.titleTab = 'Datos de la organización';
      this.tabSelected = 0;
    }
    if (this.pageActive === 'custom-page') {
      this.titleTab = 'Página de la organización';
      this.tabSelected = 1;
    }
    if (this.pageActive === 'help') {
      this.titleTab = 'Ayuda';
      this.tabSelected = 2;
    }
  }

}
