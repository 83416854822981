import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments-event',
  templateUrl: './comments-event.component.html',
  styleUrls: ['./comments-event.component.scss']
})
export class CommentsEventComponent implements OnInit {

  searchText!: any;

  constructor() { }

  ngOnInit(): void {
  }

  searchComments(event: any) {

  }

  openFilters() {

  }

  deleteAll() {
    
  }

}
