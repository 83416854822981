<div class="subscribers-page">
    <div class="d-flex justify-content-between align-items-center">
        <div class="content-search col-6">
            <div class="search-container container-form d-flex align-items-center borderInput search-full">
                <i class="fal fa-search search-icon pointer-in" (click)="searchUser(searchInput.value)"></i>
                <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar usuario"
                    [(ngModel)]="searchText" (input)="searchUser(searchInput.value)">
            </div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-end content-btns col-6">
            <div class="btn-create m-0" [class.disabled]="activeSelectList" (click)="openCreateComunication()">
                <i class="fal fa-envelope"></i>
                <p class="m-0">Crear una comunicación para todos</p>
            </div>
        </div> -->
    </div>
    <div class="content-table-subscribers">
        <div *ngIf="loadingTable" class="container-lottie">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                    containerClass="loading-box"></ng-lottie>
            </div>
        </div>
        <div #containerScroll class="table-subscribers table-panel multiple-lines" *ngIf="loaded"
            (scroll)="isScrolledInfinityView($event)">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef><b></b></th>
                    <td mat-cell *matCellDef="let user">
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Nombre y apellidos</b></th>
                    <td mat-cell *matCellDef="let user">
                        {{user.nombre||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Correo electrónico</b></th>
                    <td mat-cell *matCellDef="let user">
                        {{user.email||'-'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="purchases">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef><b>Compras</b></th>
                    <td mat-cell *matCellDef="let user">
                        {{user.purchases||'-'}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
            <div class="pagination-component">
                <div class="d-flex align-items-center pagination-panel pagination-table" *ngIf="!loadingPage && !finishScroll">
                    <div>
                        <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                            containerClass="loading-box"></ng-lottie>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="loaded && !loadingTable && (users.length<=0)" style="text-align: center;">No hay
            resultados disponibles.
        </p>
    </div>
</div>