<div class="container-results-cards d-grid">
    <div #containerScroll class="container-events-scroll">
        <div class="smallCards-search col" *ngFor="let event of events">
            <app-card [card]="event"></app-card>
        </div>
    </div>

    <div class="loading-container">
        <ng-lottie *ngIf="loadingPage" class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
    </div>

    <div #infinityScroll class="paginator-scroll" *ngIf="events.length > 10">
        <div class="up-scroll pointer-in" (click)="onScrollToTop()"><i class="fa-solid fa-angle-up fa-lg"></i></div>
    </div>
 
</div>