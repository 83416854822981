import { Component, Inject, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ComunicationsService } from 'src/app/services/comunications.service';
import { NewSubscriberComponent } from '../new-subscriber/new-subscriber.component';

@Component({
  selector: 'app-new-comunication',
  templateUrl: './new-comunication.component.html',
  styleUrls: ['./new-comunication.component.scss']
})
export class NewComunicationComponent implements OnInit {

  emailSubscription: FormGroup = new FormGroup({});
  idCompany!: number;
  
  // editorConfig!: AngularEditorConfig;
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí el mensaje',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '2',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    // uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'fontName'
      ],
      [
        'textColor',
        'backgroundColor',
        'unlink',
        'insertVideo',
        'removeFormat',
      ]
    ]    
  };

  @ViewChild('editor') editor;

  filePath = '';
  nameFile!: string;
  emailSend: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<NewSubscriberComponent>, private formBuilder: FormBuilder,
    private comunicationService: ComunicationsService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.data ? this.idCompany = this.data.idCompany : null;
    this.emailSubscription = this.formBuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required],
      file: [null]
    });
    // this.editorConfig = this.comunicationService.editorConfig;
  }

  cancelSend() {
    this.dialogRef.close();
  }

  sendEmail() {
    this.emailSend = true;
    let payload = this.emailSubscription.value;
    payload.body = this.sanitizer.bypassSecurityTrustStyle(this.emailSubscription.value.body)['changingThisBreaksApplicationSecurity'];
    if (this.emailSubscription.valid) {
      this.comunicationService.sendSubscribersEmail(this.idCompany, this.emailSubscription.value).subscribe({
        next: (res) => {
          this.dialogRef.close({ result: res });
        },
        error: (err) => {
          this.emailSend = false;
          this.dialogRef.close({ err: 'Se ha producido un error al mandar el mensaje.' });
        }
      });
    }
  }
  
  //Imagen
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const reader = new FileReader();
      let formData = new FormData();
      formData.append("file", file, file.name);
      // if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          this.filePath = imageSrc;
          this.nameFile = file.name;
          this.emailSubscription.patchValue({
            file: imageSrc
          });
        };
      // }
    }
  }

}
