<div class="container-list-map">
    <div class="container-list">
        <app-infinity-scroll [slugEvent]="slugEvent" [categoryPage]="categoryPage" (chargeMap)="loadingList($event)" (results)="existsResults($event)" [map]="true"></app-infinity-scroll>
    </div>
    <div class="container-map">
         <!-- <img src="/assets/img/mapa.png" alt="Mapa" />  -->
        <div id="maps1"></div>
        <div *ngIf="!loaded" class="loading-container-map">
            <div class="loading-container">
                <ng-lottie class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
            </div>    
        </div>
    </div>
    
</div>