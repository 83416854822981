<div class="w-100 container-create-map" [class.content-page-create]="!fromVenue && !fromEvent" *ngIf="!loading">
    <div class="d-flex justify-content-between container" *ngIf="!loadingCanvas && !fromVenue && !fromEvent">
        <div class="d-flex align-items-end">
            <p class="title-map"><i (click)="cancel()" class="fa-light fa-arrow-left" style="margin-right: 10px;"></i>
                {{ idMap ? 'Editar:' : 'Crear mapa'}} </p>
            <p *ngIf="titleMap" class="name-map" (click)="editName()">
                {{titleMap}}
                <i class="fa-light fa-pen" style="font-size: 18px; margin-left: 10px;"></i>
            </p>
        </div>

        <div class="d-flex btns-save-map" *ngIf="allowCreate">
            <button class="btn primary-button solid" (click)="saveMap()">Guardar</button>
            <button class="btn cancel-button" (click)="cancel()">Cancelar</button>
        </div>
    </div>
    <div [class.content-design]="!fromVenue && !fromEvent">
        <app-generate-canvas [allowCreate]="allowCreate" [fromCreate]="fromCreate" [fromVenue]="fromVenue" [fromEvent]="fromEvent" [idEvent]="idEvent" [editEvent]="editEvent" [idMap]="idMap" [bookings]="bookingSeats" (loadCanvas)="paintCanvas($event)"
            [titleMap]="titleMap"></app-generate-canvas>
    </div>
</div>