import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventosService } from 'src/app/services/eventos.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { LoginComponent } from '../../shared/login/login.component';
import { SeatsPanelComponent } from '../seats-panel/seats-panel.component';

@Component({
  selector: 'app-info-abonos',
  templateUrl: './info-abonos.component.html',
  styleUrls: ['./info-abonos.component.scss']
})
export class InfoAbonosComponent implements OnInit {

  abono!: any;
  event: any;
  loaded: boolean = false;
  urlImages: string = AppSettings.MEDIA_URI + '/images/events';
  listEvents: any[] = [];

  loadingLottie: AnimationOptions = {
    path: 'assets/lotties/loading.json',
  };

  ticketsSelected = [];
  buysBuySelect: any[] = [];
  priceTotal: number = 0;
  openingMap: boolean = false;
  ticketsLength: number = 0;

  constructor(@Optional() private dialogRef: MatDialogRef<InfoAbonosComponent>, private dialog: MatDialog, private shoppingService: ShoppingCartService,
    private router: Router, private ticketService: TicketsService, @Optional() @Inject(MAT_DIALOG_DATA) public data, private eventService: EventosService,
    private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.abono?.length > 0) {
      this.event = this.data.event;
      this.ticketService.getAbonos(this.data.abono[0].id).subscribe({
        next: (data) => {
          this.abono = data;
          this.listEvents = data.events.filter((value) => value.id != this.event);
          this.loaded = true;
        }
      })
    }
  }

  isPastEvent(event: any) {
    let pastEvent: boolean = false;
    let now = new Date();
    let next = new Date(event.datesRange.next);

    if (event.hourStart) {
      let indexPoint = event.hourStart.indexOf(':');
      let hour = event.hourStart.substring(0, indexPoint);
      let minutes = event.hourStart.substring(indexPoint + 1);
      next.setHours(+hour);
      next.setMinutes(+minutes);
    } else {
      next.setHours(23);
      next.setMinutes(59);
    }
    if (now >= next) {
      pastEvent = true;
    }
    return pastEvent;
  }

  openSelectEvent(event: any) {
    let past = this.isPastEvent(event);
    if (!past) {
      // this.router.navigate([`/event/${event.id}-${event.titulo.replaceAll(' ','-')}`]).then(() => location.reload());
      if (!this.openingMap) {
        this.openingMap = true;
        this.buysBuySelect = [];
        let stringDate = { date: new Date(event.datesRange?.next), time: event.hourStart };
        let dateTime = this.getDateTime(stringDate.date, stringDate.time);
        this.ticketService.getBookingSeatsByEvent(event.id, dateTime.date, dateTime.time).subscribe({
          next: (res) => {
            this.buysBuySelect = res;
            let ticket = null;
            let index = this.ticketsSelected.findIndex((value) => value.event == event.id);
            if (index != -1) {
              ticket = this.ticketsSelected[index].tickets;
            }
            const dialogRef = this.dialog.open(SeatsPanelComponent, {
              panelClass: ['info-dialog', 'not-padding', 'panel-seats'],
              data: { event: event, date: dateTime.date, time: dateTime.time, bookingTickets: this.buysBuySelect, fromAbono: true, abono: this.abono, tickets: ticket, actived: event.selected }
            });
            this.openingMap = false;
            dialogRef.afterClosed().subscribe(data => {
              if (data && data.payload?.tickets?.length > 0) {
                this.priceTotal = data.payload.price;
                let index = this.ticketsSelected.findIndex((value) => value.event == event.id);
                if (index != -1) {
                  this.ticketsSelected[index] = data.payload;
                } else {
                  this.ticketsLength++;
                  this.ticketsSelected.push(data.payload);
                }
                event.ticket = data.payload.tickets[0];
                event.selected = data.actived;
              }
            })
          }
        });
      }
    }
  }

  getDateTime(date, hour) {
    let dateAux = new Date(date);
    let time = null;
    if (hour) {
      let index = hour.indexOf(':');
      dateAux.setUTCHours(hour.substring(0, index));
      dateAux.setUTCMinutes(hour.substring(index + 1));
      
      time = dateAux.toISOString();
    }
    return { date: date, time: time };
  }

  goPay(exit: boolean = true) {
    let user = this.auth.getProfile();
    if (!user) {
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: ['info-dialog', 'dialog-login'],
        data: {
          exit: false,
          seats: true
        }
      });
    }
    if (user && !this.openingMap && this.listEvents.length == this.ticketsLength) {
      this.openingMap = true;
      const payload = this.ticketsSelected;
      this.shoppingService.addBondToCard(payload).subscribe({
        next: () => {
          this.dialogRef.close({ data: payload, exit: exit });
          this.openingMap = false;
        },
        error: (err) => {
          this.openingMap = false;
          if (err.status == 400 && err.error.tickets) {
            // Quitar asientos que ya no están disponibles
            // Volver a llamar a la comprobación de asientos
            this.dialog.open(InfoCardComponent, {
              panelClass: 'info-dialog',
              data: {
                icon: false,
                text: err.error.msg,
                btnCancel: false
              }
            });
          }
        }
      });
    }
  }

  close() {
    this.dialogRef.close(true);
  }

}
