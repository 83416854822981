<div class="subscriber-front">
    <div class="main-new-subscriber">
        <h1 class="title-new-subscriber">¿Quieres ser miembro de <span>{{place?.membershipTitle? place?.membershipTitle : place?.name}}</span>?</h1>
        <p class="subtitle-new-subscriber" *ngIf="!isUser">Rellena tus datos personales. Si ya estás
            registrado inicia sesión <span class="open-link" (click)="openLogin()">aquí.</span></p>

        <div class="info-msg-validate" *ngIf="!isUser">
            <p class="m-0 text-end">*Todos los campos son obligatorios.</p>
        </div>

        <div class="form-info-user">
            <form class="form-grid" [formGroup]="subscriber">
                <!-- En caso de no estar logueado -->
                <div class="two-columns-form" *ngIf="!isUser">
                    <div class="m-2">
                        <p class="label-input">Nombre*</p>
                        <div class="form-input-container"
                            [class.invalid]="name.invalid && (name.dirty || name.touched)">
                            <input type="text" class="w-100" formControlName="name" required>
                        </div>
                    </div>
                    <div class="m-2">
                        <p class="label-input">Apellidos*</p>
                        <div class="form-input-container"
                            [class.invalid]="lastname.invalid && (lastname.dirty || lastname.touched)">
                            <input type="text" class="w-100" formControlName="lastname" required>
                        </div>
                    </div>
                </div>
                <div class="two-columns-form" *ngIf="!isUser">
                    <div class="m-2">
                        <p class="label-input">Contraseña*</p>
                        <div class="form-input-container d-flex align-items-center"
                            [class.invalid]="pass.invalid && (pass.dirty || pass.touched)">
                            <input type="{{ hide ? 'password' : 'text' }}" class="w-100" formControlName="textPassword"
                                required>
                            <i (click)="hide = !hide" class="fal pointer-in"
                                [class]="hide ? 'fa-eye' : 'fa-eye-slash'"></i>
                        </div>
                    </div>
                    <div class="m-2">
                        <p class="label-input">Repetir contraseña*</p>
                        <div class="form-input-container d-flex align-items-center">
                            <input type="{{ hideRepeat ? 'password' : 'text' }}" class="w-100"
                                formControlName="confirmPassword" required
                                [class.invalid]="repPass.invalid && (repPass.dirty || repPass.touched)">
                            <i (click)="hideRepeat = !hideRepeat" class="fal pointer-in"
                                [class]="hideRepeat ? 'fa-eye' : 'fa-eye-slash'"></i>
                        </div>
                    </div>
                </div>
                <div class="validate-msg" *ngIf="pass?.invalid && (pass?.dirty || pass?.touched)">
                    <p>La contraseña debe contener minúsculas, mayúsculas, números y al menos 8 caracteres.</p>
                </div>
                <div class="validate-msg"
                    *ngIf="!pass?.invalid && (repPass?.dirty || repPass?.touched) && subscriber?.errors?.['passwordNotMatch']">
                    <p>Las contraseñas no coinciden.</p>
                </div>
                <div class="m-2" *ngIf="!isUser">
                    <p class="label-input">Correo electrónico*</p>
                    <div class="form-input-container "
                        [class.invalid]="email.invalid && (email.dirty || email.touched)">
                        <input type="email" class="w-100" formControlName="email" required>
                    </div>
                </div>
                <!-- Aceptar las condiciones del teatro -->
                <mat-checkbox class="checkbox-conditions" formControlName="acceptTerms" class="m-1 mt-2"
                    *ngIf="!isUser">
                    Acepto los <a class="open-link" target="_blank" [routerLink]="['/terms-conditions']">términos y
                        condiciones</a> de uso de Citylok.
                </mat-checkbox>
                <!-- Términos y condiciones de Citylok -->
                <mat-checkbox class="checkbox-conditions" formControlName="acceptConditions" class="m-1">
                    Acepto las <a class="open-link" target="_blank" [routerLink]="['/terms-conditions']">condiciones</a>
                    y deseo recibir comunicaciones y correos
                    electrónicos de interés, como anuncios o recomendaciones relacionadas con el Teatro Apolo.
                </mat-checkbox>
            </form>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="footer-new-subscriber">
        <div class="btn-new btn-create btn-create-border m-0" (click)="cancelNew()">
            <p class="m-0">Cancelar</p>
        </div>
        <div class="btn-new btn-create m-0" (click)="requestSubscription()" [class.disabled]="subscriber.invalid">
            <p class="m-0">Aceptar</p>
        </div>
    </div>
</div>