import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute, NavigationEnd } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class PanelGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  hasError() {
    this.router.navigate(['/login']).then(() => { if (localStorage.getItem('jwt_token')) { this.authenticationService.logout() } });
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var token = localStorage.getItem('jwt_token');
    if (token === null) {
      return this.hasError();
    }
    var decoded: any = jwt_decode(token);
    if (decoded && decoded.username) {
      return this.authenticationService.getUsersMe().pipe(map((response: any) => {
        if (response.email == decoded.username && this.authenticationService.user == response.id && (response.organizer || response.protocolo || response.admin || response.site)) {
          return true;
        } else {
          return this.hasError();
        }
      }), catchError(err => { return of(this.hasError()) }));
    } else {
      return this.hasError();
    }
  }

}
