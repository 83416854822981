<div class="edit-seats-object">
    <div class="d-flex align-items-center mb-3 title-section" (click)="cancelEdit(true)">
        <i class="fa-light fa-arrow-left pointer-in" style="margin-right: 20px;"></i>
        <p class="m-0">Sección</p>
    </div>

    <div class="options-edit-section active">
        <div class="section-edit">
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Identificador Área:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Identificador" [(ngModel)]="sectionNumber" (change)="editSection()" required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Nombre:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Nombre" [(ngModel)]="sectionName" (change)="editNameSection()" required>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Fondo:</p>
                <mat-checkbox [(ngModel)]="hasBackground" class="checkbox" color="primary"
                    (change)="editBackground()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between" *ngIf="hasBackground">
                <p class="m-0">Color:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input class="w-100" type="text" placeholder="#Hex" [(ngModel)]="colorDefault">
                    <div class="block-color">
                        <input type="color" [(ngModel)]="colorDefault">
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Editar forma:</p>
                <mat-checkbox [(ngModel)]="editForm" class="checkbox" color="primary"
                    (change)="editFormSection()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Borde:</p>
                <mat-checkbox [(ngModel)]="hasBorder" class="checkbox" color="primary"
                    (change)="editBorder()"></mat-checkbox>
            </div>
            <div class="d-flex align-items-center justify-content-between" *ngIf="hasBorder">
                <p class="m-0">Color borde:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input class="w-100" type="text" placeholder="#Hex" [(ngModel)]="colorBorde">
                    <div class="block-color">
                        <input type="color" [(ngModel)]="colorBorde">
                    </div>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Duplicar:</p>
                <mat-checkbox [(ngModel)]="hasBorder" class="checkbox" color="primary"
                    (change)="copyForm()"></mat-checkbox>
            </div> -->
            <!-- <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Sección:</p>
                <div class="bloque-change-section container-form d-flex align-items-center">
                    <input type="text" placeholder="Nombre" [(ngModel)]="valueName" (change)="editName()" required>
                </div>
            </div> -->

            <!-- <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Inclinar:</p>
                <mat-slider min="-1.2" max="1.2" step="0.4" [value]="valueTilt" (valueChange)="editTilt($event)">
                    <input matSliderThumb>
                </mat-slider>
            </div> -->
            <!-- <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Rotar:</p>
                <mat-slider min="-180" max="180" step="10" [value]="valueAngle" (valueChange)="editRotation($event)">
                    <input matSliderThumb>
                </mat-slider>
            </div> -->
            <div *ngIf="editForm">
                <p class="link">Editando forma...</p>
            </div>
        </div>
        <div class="btns-save-apply">
            <div class="section-option w-100" (click)="cancelEdit()">
                <p class="m-0">Cancelar</p>
                <i class="fa-light fa-ban"></i>
            </div>
            <div class="section-option w-100" (click)="applyChanges()">
                <p class="m-0">Aplicar</p>
                <i class="fa-light fa-floppy-disk"></i>
            </div>
        </div>
    </div>
</div>