<div class="page-edit-profile">
    <div class="container container-page-profile">
        <div class="mb-2 d-flex align-items-center edit-title">
            <span class="pointer-in" (click)="goBack()"><i class="fa-light fa-arrow-left fa-lg"></i></span>
            <p class="m-0 title-edit">Editar perfil</p>
        </div>
        <mat-divider></mat-divider>
        <div class="form-edit">

            <div class="content-img">
                <div class="img-profile" (click)="file.click()">
                    <img fallimg src="{{ imgControl.value ? imgControl.value : urlImages+'/image_profile.png'}}" alt="Img user" />
                    <div class="content-camera">
                        <i class="fa-light fa-camera"></i>
                    </div>
                </div>
                <input id="file" #file hidden type="file" accept="image/*" (change)="onFileChange($event)">
            </div>
            
            <div class="d-flex align-items-center justify-content-center" *ngIf="profile?.oficial || profile?.smsValidate">
                <div class="cont-icon-verify d-grid justify-content-center align-items-center">                
                    <i class="fa-light fa-circle-check"></i>
                </div>
                <span class="user-verify">{{ profile?.oficial ? 'Sitio' : 'Usuario' }} verificado</span>
            </div>
            
            <form class="formulario-content" [formGroup]="userForm" enctype="application/x-www-form-urlencoded">
                <mat-divider></mat-divider>
                <div class="item-form container-form d-flex">
                    <i class="fa-light fa-user icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Nombre</span>
                        <input class="w-100" maxlength="100" type="text" formControlName="nombre">
                        <span class="info-input">Tu nombre será visible para los usuarios de Citylok</span>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <!-- <div class="item-form container-form d-flex">
                    <i class="fa-light fa-at icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Nickname</span>
                        <input class="w-100" type="text" placeholder="" readonly>
                    </div>
                </div>
                <mat-divider></mat-divider> -->
                <div class="item-form container-form d-flex">
                    <i class="fa-light fa-circle-info icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Descripción/info</span>
                        <input #desc class="w-100" maxlength="255" type="text" formControlName="descripcion">
                        <span *ngIf="desc.value" class="info-input">Tu descripción será visible para los usuarios de Citylok</span>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item-form container-form d-flex">
                    <i class="fa-light fa-envelope icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Email</span>
                        <input class="w-100" type="email" formControlName="email" readonly>
                    </div>
                </div>
                <!-- <mat-divider></mat-divider>
                    <div class="item-form container-form d-flex">
                    <i class="fa-light fa-calendar icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Fecha de nacimiento</span>
                        <input class="w-100" type="text" formControlName="fnacimiento">
                    </div>
                </div> -->
                <mat-divider *ngIf="profile?.oficial || profile?.smsValidate"></mat-divider>
                <div class="item-form container-form d-flex pointer-in" (click)="openValidatePhone()">
                    <i class="fa-light fa-phone icon-primary"></i>
                    <div class="content-input w-100">
                        <span class="m-0 subtitle-form">Teléfono</span>
                        <input class="w-100 pointer-in" type="text" formControlName="movil" readonly>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right" style="align-self: center;"></i>
                </div>
                <mat-divider></mat-divider>
                <div class="item-form change-password pointer-in" (click)="openChangePassword()" *ngIf="enabledPassword">
                    <i class="icon-primary fa-light fa-lock"></i>
                    <p class="m-0">Cambiar contraseña</p>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider *ngIf="!profile?.organizer"></mat-divider>
                <div class="d-flex justify-content-center btn-change" *ngIf="changed">
                    <button class="primary-button solid w-100" style="min-height: 40px;" (click)=saveChanges()>Guardar cambios</button>
                </div>
            </form>
        </div>
    </div>
</div>