import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class SeatMapService {

  private saveBtn = new BehaviorSubject<boolean>(false);
  public saveEvent = this.saveBtn.asObservable();

  constructor(private http: HttpClient) { }

  saveMapEvent(value: boolean) {
    this.saveBtn.next(value);
  }

  getMap(idMap: number, idEvent?: any) {
    const url = idEvent ? `${AppSettings.API_URI}/map/event/${idEvent}` :`${AppSettings.API_URI}/map/${idMap}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  getMapsUser() {
    const url = `${AppSettings.API_URI}/all/maps`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  getMapsPublicUser() {
    const url = `${AppSettings.API_URI}/public/maps`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  editMap(mapJson: any, idMap: number) {
    const url = `${AppSettings.API_URI}/edit/map/${idMap}`;
    return this.http.put<any>(url, mapJson).pipe(
      map(data => {
        return data;
      })
    );
  }

  createMap(mapJson: any) {
    const url = `${AppSettings.API_URI}/save/map`;
    return this.http.put<any>(url, mapJson).pipe(
      map(data => {
        return data;
      }),
    );
  }

  deleteMap(idMap: number) {
    const url = `${AppSettings.API_URI}/delete/map/${idMap}`;
    return this.http.delete<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  convertToBase64(url: string) {
    this.http.get(url, { responseType: "blob" }).subscribe(blob => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        //Here you can do whatever you want with the base64 String
        if(AppSettings.DEBUG) {
          console.log("File in Base64: ", event.target.result);
        }
      };

      reader.onerror = (event: any) => {
        if(AppSettings.DEBUG) {
          console.log("File could not be read: " + event.target.error.code);
        }
      };
    });
  }

  downloadFile(filename = 'data', svgData) {
    let blob = new Blob(['\ufeff' + svgData], { type: 'image/svg+xml;charset=utf-8;' });
    let dwldLink = document.createElement("a");

    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".svg");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}


// Cuadricula
// designGrid() {
//   var options = {
//     distance: 10,
//     width: this.canvasWidth * 10,
//     height: this.canvasHeight * 10,
//     param: {
//       stroke: '#f5f5f5',
//       strokeWidth: 1,
//       selectable: false,
//       hoverCursor: 'default',
//     },
//   };
//   this.canvas.options = options;
//   this.canvas.gridLen = this.canvasWidth;
//   var arrayGroup = [];
//   for (var i = 0; i < this.canvas.gridLen; i++) {
//     var distance = i * options.distance;
//     var horizontal = new fabric.Line([distance, 0, distance, options.width], options.param);
//     var vertical = new fabric.Line([0, distance, options.width, distance], options.param);
//     // this.canvas.add(horizontal);
//     // this.canvas.add(vertical);
//     if (i % 5 === 0) {
//       horizontal.set({ stroke: '#e7e7e7' }); // #e7e7e7
//       vertical.set({ stroke: '#e7e7e7' });
//     };
//     arrayGroup.push(horizontal)
//     arrayGroup.push(vertical)
//   }
//   this.groupGrid = new fabric.Group(arrayGroup, {
//     top: 0,
//     left: 0,
//     width: this.canvasWidth,
//     height: this.canvasHeight,
//     stroke: '#a9a8b3',
//     hoverCursor: 'default',
//     originX: 'left',
//     originY: 'top',
//     scaleX: 2.5,
//     scaleY: 2.5,
//     evented: false,
//     selectable: false,
//     name: 'gridTemplate'
//   });

//   this.canvas.add(this.groupGrid);
//   this.canvas.requestRenderAll();
// }