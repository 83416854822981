import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-business',
  templateUrl: './menu-business.component.html',
  styleUrls: ['./menu-business.component.scss']
})
export class MenuBusinessComponent {

  titleTab: string = 'Datos de la organización';
  @Input() tabSelected!: any;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  changeTabBusiness(emit: any) {
    this.titleTab = emit.name;
    this.changeTab.emit(emit);
  }

}
