<div class="card-contact-panel">
    <p class="title-change">¿En qué podemos ayudarte?</p>

    <div>
        <form [formGroup]="contactForm">
            <div class="bloque-change-input container-form d-flex align-items-center subject-input">
                <input class="w-100" type="text" placeholder="Asunto" formControlName="subject" />
            </div>

            <div class="bloque-change-input container-form d-flex align-items-center textarea-input">
                <textarea #textarea class="w-100" type="textarea" placeholder="Por favor, escribe aquí tu mensaje."
                    maxlength="2500" formControlName="body"></textarea>
            </div>

            <div class="d-flex justify-content-center btn-change">
                <button class="primary-button solid w-100" style="min-height: 40px;"
                    (click)="sendContact()">Enviar</button>
            </div>
        </form>
    </div>
</div>