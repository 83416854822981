<div class="users-infinity" #containerFavs>
    <mat-divider></mat-divider>
    <div *ngFor="let option of favs" class="group-users">
        <div class="user-container">
            <img fallimg class="img-fav" *ngIf="option.imagen; else divImageDefault" src="{{urlImages}}/{{option.imagen}}" />
            <ng-template #divImageDefault>
                <img fallimg class="img-fav" src="{{urlImages}}/image_profile.png" />
            </ng-template>
            <div class="info-user">
                <div class="d-flex justify-content-between w-100">
                    <div [routerLink]="['/events', option?.id, (option?.nombre.replaceAll(' ','-'))]">
                        <span class="title-user">{{ option.nombre }}</span>
                        <div class="d-none d-sm-flex align-items-center">
                            <div class="cont-icon-verify d-grid justify-content-center align-items-center" *ngIf="option.oficial || option.smsValidate">                
                                <i class="fa-light fa-circle-check"></i>
                            </div>
                            <span class="verify-user" *ngIf="option?.oficial || option.smsValidate">{{ option.oficial ? 'Sitio' : 'Usuario' }} verificado</span>
                        </div>
                    </div>
                    <div class="circle-option d-grid justify-content-center align-items-center pointer-in"
                        (click)="deleteFavorito(option)">
                        <i class="fa-solid fa-heart"></i>
                    </div>
                </div>
                <p class="desc-user pointer-in short-text" [innerHTML]="option.descripcion"></p>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div class="loading-container">
        <ng-lottie *ngIf="loadingPage" class="lottie" [options]="loadingLottie" containerClass="loading-box"></ng-lottie>
    </div>
</div>