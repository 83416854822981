<div class="page-help-business">
    <p class="title-help-business">Contacta con nosotros y te ayudamos</p>
    <div class="d-flex align-items-center info-contact-help">
        <div class="col-4">
            <p class="m-0 title-contact">Teléfono de atención</p>
            <p class="m-0 info-contact"><a href="tel:+34941135052">941 135 052</a></p>
        </div>
        <div class="col-4">
            <p class="m-0 title-contact">Email de contacto</p>
            <p class="m-0 info-contact"><a href="mailto:hola@citylok.com"> hola&#64;citylok.com</a></p>
        </div>
    </div>

    <div class="btn-create m-0 mt-4" (click)="openContactBusiness()">
        <i class="fal fa-paper-plane"></i>
        <p class="m-0">Enviar correo</p>
    </div>
</div>