import { Component, OnInit } from '@angular/core';
import { MatDialog, } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CategoriasService } from '../../../../services/categorias.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { InfoCardComponent } from '../../../shared/info-card/info-card.component';
import { WarningAdminComponent } from '../../shared/warning-admin/warning-admin.component';

@Component({
  selector: 'app-edit-categoria',
  templateUrl: './edit-categoria.component.html',
  styleUrls: ['./edit-categoria.component.scss']
})
export class EditCategoriaComponent implements OnInit {

  categoria: FormGroup;
  catImage: FormGroup;
  apiMedia: string = AppSettings.MEDIA_URI;
  message = '';
  filePath = '';
  idC;

  constructor(private dialog: MatDialog, private location: Location,
    private formBuilder: FormBuilder, private categoriasService: CategoriasService,
    private activatedRoute: ActivatedRoute,) {

    this.categoria = this.formBuilder.group({
      nombre: ['', Validators.required],
      slug: ['', Validators.required],
      showhome: false,
      icon: ['', Validators.required],
      imagen: '',
    })
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = regex.test(control.value);
      if(valid) {
        return null;
      } else {
        return error;
      }
    };
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(p => this.idC = p.id)
    this.getData();

  }

  getData() {
    if (this.idC) {
      this.categoriasService.getCategoriabyId(this.idC).subscribe(c => {
        if (c != null) {
          if (c.imagen) {
            this.filePath = this.apiMedia + '/categoria/' + c.imagen;
          }
          this.categoria = this.formBuilder.group({
            nombre: [c.nombre, Validators.required],
            slug: [c.slug, Validators.required],
            showhome: c.showhome,
            icon: [c.icon, Validators.required],
            imagen: c.imagen,
          })
        }
      });
    }
  }

  changedSlug($event: string) {
    if($event.indexOf(' ')) {

    }
  }

  submit() {
    if (this.categoria.valid) {
      if (this.idC) {
        this.categoriasService.putCategoria(this.idC, this.categoria.value).subscribe({
          next: data => {
            if (this.filePath && this.filePath !== this.apiMedia + '/categoria/' + this.categoria.value.imagen) {
              this.catImage = this.formBuilder.group({
                categoriaId: this.idC,
                file: this.filePath
              })
              this.categoriasService.uploadFilePut(this.catImage.value).subscribe()
            }
            const warn = {
              icon: false,
              text: 'La categoría se ha editado correctamete',
              btnCancel: false
            }
            this.openDialog(warn, true);
          },
          error: () => {
            const warn = {
              icon: true,
              text: '¡Vaya! Se ha producido un error al editar la categoría.',
              btnCancel: false
            }
            this.openDialog(warn);
          }
        });
      } else {
        this.categoriasService.postCategoria(this.categoria.value).subscribe({
          next: data => {
            if (this.filePath !== '') {
              this.catImage = this.formBuilder.group({
                categoriaId: data.id,
                file: this.filePath
              })
              this.categoriasService.uploadFilePut(this.catImage.value).subscribe()
            }
            const warn = {
              icon: false,
              text: 'La categoría se ha creado correctamete',
              btnCancel: false
            }
            this.openDialog(warn, true);
          },
          error: () => {
            const warn = {
              icon: true,
              text: '¡Vaya! Se ha producido un error al crear la categoría.',
              btnCancel: false
            }
            this.openDialog(warn);
          }
        });
      }
    } else {
      const warn = {
        icon: true,
        text: 'Comprueba todos los campos',
        btnCancel: false
      }
      this.openDialog(warn);
    }
  }

  openDialog(data?: any, close?: boolean): void {
    if (!data) {
      data = {
        icon: true,
        text: '¿Estás seguro de que desea cancelar la operación?',
        btnCancel: true
      }
    }
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && close) {
        this.location.back();
      }
    });
  }

  //Imagen
  onImageChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.categoria.addControl('file', new FormControl(''));
      const reader = new FileReader();
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          this.filePath = imageSrc;
          this.categoria.patchValue({
            file: imageSrc
          });
        };
      }
    }
  }

}
