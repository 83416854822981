<div class="page-title-wrapper">
  <div style.background-image="url('{{imagen}}')" class="background-title-page">
     <div class="container-text-white mt-3">
      <div class="container"><i class="fa-thin {{ category?.icon }} fa-3x"></i></div>
      <p class="mb-0 text-center container title-page">{{ category?.nombre }}</p>
      <div class="select-city-category" *ngIf="city">
        <mat-form-field appearance="fill" class="filter-city">
          <mat-select [(ngModel)]="city" (ngModelChange)="changeCity($event)" class="select-white-filter text-black" panelClass="panelfilter">
              <mat-option *ngFor="let ciudad of ciudades | async" [value]="ciudad.id" >{{ciudad.nombre}}</mat-option>
          </mat-select>
          <mat-select-trigger matSuffix style="vertical-align: middle;">
              <i class="fal fa-angle-down pointer-in"></i>
          </mat-select-trigger>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="d-grid d-lg-flex justify-content-between align-items-start filters-container">
    <div class="filters-selected col-12 col-lg-6 col-xl-8 order-1">
      <div *ngFor="let filter of filtros | async">
        <div class="content-filter" *ngIf="filter.name">
          <i class="fa-light fa-times fa-xs icon-delete pointer-in" (click)="deleteFilter(filter)"></i>
          {{ filter.type != 'price' ? filter.name : (filter.min+'-'+filter.max+' €') }}
          <i *ngIf="filter.type == 'order'" class="fa-light fa-arrow-up-arrow-down icon-search fa-sm"></i>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center col-12 col-lg-6 col-xl-4 order-0 order-lg-1">
      <span (click)="viewType='grid'" class="pointer-in option-view" [class]="viewType == 'grid' ? 'text-primary' : 'text-light'">Vista grid <i class="fa-light fa-grid"></i></span>
      <span (click)="viewType='map'" class="pointer-in option-view" [class]="viewType == 'map' ? 'text-primary' : 'text-light'">Mapa <i class="fa-light fa-map"></i></span>
      <div class="pointer-in filter-btn d-flex align-items-center justify-content-center" (click)="openFilterDialog()">
        <span>Filtros</span>
        <i class="fal fa-sliders-h text-primary"></i>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="results; else divNotResults">
  <div *ngIf="viewType == 'grid'">
    <app-infinity-scroll [categoryPage]="true" [slugEvent]="slugEvent.asObservable()" (results)="existsResults($event)"></app-infinity-scroll>
  </div>
  <div *ngIf="viewType == 'map'">
    <app-list-map [categoryPage]="true" [slugEvent]="slugEvent.asObservable()" (results)="existsResults($event)" [map]="true"></app-list-map>
  </div>
</div>


<ng-template #divNotResults>
  <div class="container not-results">
    <p class="title-page text-center">No existen eventos de esta categoría <span *ngIf="citySelected">en {{citySelected}}</span></p>
  </div>
</ng-template>