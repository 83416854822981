import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tickets-venue',
  templateUrl: './tickets-venue.component.html',
  styleUrls: ['./tickets-venue.component.scss']
})
export class TicketsVenueComponent {

  @Input() venue!: any;

}
