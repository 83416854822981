import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  admin: boolean = false;
  checkout: boolean = false;
  panelAdmin: boolean = false;

  constructor(private router: Router, private titleService: Title, private auth: AuthenticationService) {
    this.titleService.setTitle('CityLok');
    var viewAdmin = localStorage.getItem('admin');
    var rol = localStorage.getItem('currentRol');
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          var url = event.url;
          this.admin = url.indexOf('/admin') == -1 ? false : true;
          this.checkout = url.indexOf('/checkout/') == -1 ? false : true;
          this.panelAdmin = url.indexOf('/panel-control') == -1 ? false : true;
        }
      }
      );
    this.admin = (rol === 'true' && viewAdmin) ? true : false;
    // if(localStorage.getItem('jwt_token')) {
    //   this.auth.getUsersMe().subscribe({
    //     next: result => { if (result.email) { 
    //       this.auth.setProfile(result);
    //     } }
    //   });
    // }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
