<main class="main">

  <mat-sidenav-container>
    <mat-sidenav mode="side" opened class="container pr-3 pt-4"  *ngIf="bigScreen">
      <button mat-tab active [routerLink]="['/admin/config/selection']" routerLinkActive="menu-active">
          <!-- <i class="fa-light fa-calendar-days"></i> -->
          <span>Selección</span>
      </button>
      <button mat-tab [routerLink]="['/admin/config/banner']" routerLinkActive="menu-active">
        <!-- <i class="fa-light fa-ticket"></i> -->
        <span>Banners</span>
      </button>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet class="ml-2"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

</main>
