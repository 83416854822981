<div class="header-template-admin"><i class="icon-page far fa-times" (click)="openDialog()" ></i><span>Datos del Usuario</span></div>
<mat-divider></mat-divider>
<div class="template-new">
  <h3>Ficha de Usuario</h3>
    <app-warning-admin *ngIf="message" [text]="message"></app-warning-admin>
    <div class="row row-eq-height">
        <div class="col-lg-4 col-md-4 mb-4 mt-4">
          <p>Imagen de perfil</p>
          <div *ngIf="profileImage">
              <img fallimg class="main-img mb-2 mt-2 pointer-in" [class.errorfile]="errorfile" [src]="profileImage" alt="Imagen Principal">
          </div>
        </div>
        <div style="padding-top: 50px;"class="col-lg-8 col-md-8 mb-4 mt-4">
            <span class="light-text">Nombre</span>
            <p>{{ userData?.nombre }}</p>
            <span class="light-text">E-Mail</span>
            <p>{{ userData?.email}}</p>
            <span class="light-text">Descripción</span>
            <p *ngIf="userData?.descripcion" style="white-space: pre-line"> {{ userData?.descripcion | slice:0:240 }}</p>
            <p *ngIf="!userData?.descripcion" style="white-space: pre-line"> Sin descripción aún</p>
            <mat-checkbox  [checked]= "oficial" color="primary" disabled>Oficial</mat-checkbox>
        </div>
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group>
      <mat-tab label="Eventos creados">
      <div class="container-table">
          <table mat-table [dataSource]="dataSourceEventos" class="mat-elevation-z28">
            <ng-container matColumnDef="imagen">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let event"><img fallimg class="imgEvent" src="{{event?.imageurl}}"></td>
            </ng-container>
            <ng-container matColumnDef="titulo">
              <th mat-header-cell *matHeaderCellDef ><b>Título</b></th>
              <td mat-cell *matCellDef="let event"><b><a (click)="OpenEvent(event.id,event.titulo,0)"> {{event?.titulo}}</a></b></td>
            </ng-container>
            <ng-container matColumnDef="comentarios">
              <th mat-header-cell *matHeaderCellDef ><b>Comentarios</b></th>
              <td mat-cell *matCellDef="let event"><b><a (click)="OpenEvent(event.id,event.titulo,1)"> {{event.comentario}}</a></b></td>
            </ng-container>
            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef ><b>Fecha</b></th>
              <td mat-cell *matCellDef="let event">{{event.datesRange.start | date:'d MMM y' }}</td>
            </ng-container>
            <ng-container matColumnDef="ciudad">
              <th mat-header-cell *matHeaderCellDef ><b>Ciudad</b></th>
              <td mat-cell *matCellDef="let event">{{event?.ciudad}}</td>
            </ng-container>
            <ng-container matColumnDef="localizacion">
              <th mat-header-cell *matHeaderCellDef ><b>Localización</b></th>
              <td mat-cell *matCellDef="let event">{{event.localizacion}}</td>
            </ng-container>
            <ng-container matColumnDef="precio">
              <th mat-header-cell *matHeaderCellDef ><b>Precio desde</b></th>
              <td mat-cell *matCellDef="let event">{{event.preciodesde}} &euro;</td>
            </ng-container>
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let event">
                <button mat-button [matMenuTriggerFor]="menuOptions" class="menu-table">
                  <i class="fas fa-ellipsis-v blue-icon"></i>
                </button>
                <mat-menu class="menu-options" #menuOptions="matMenu">
                    <button mat-menu-item (click)="OpenEvent(event.id,event.titulo,0)"  class="viewIcon"><i class="far fa-eye"></i> Ver</button>
                    <!-- <button mat-menu-item [routerLink]="['/admin/eventos/view-evento',event.id]" class="viewIcon"><i class="far fa-eye"></i> Ver</button> -->
                    <button mat-menu-item class="editIcon" [routerLink]="['/admin/eventos/new-evento',event.id]"><i class="fal fa-edit"></i> Editar</button>
                    <button mat-menu-item class="deleteIcon" (click)="openDelete(event.id)"><i class="far fa-trash-alt"></i> Eliminar</button>
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsEventos"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsEventos;"></tr>
          </table>
          <app-pagination-page [totalItems]="numItems"  [numPages]="totalPages"  (items)="itemsChange($event)"  (page)="pageChange($event)"></app-pagination-page>
        </div>
    </mat-tab>
    <mat-tab label="Eventos inscritos">
        <div class="container-table">
            <table mat-table [dataSource]="dataSourceCompras" class="mat-elevation-z28">
              <ng-container matColumnDef="imagen">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let event"><img fallimg class="imgEvent" src="{{event.evento?.imageurl}}"></td>
              </ng-container>
              <ng-container matColumnDef="evento">
                <th mat-header-cell *matHeaderCellDef ><b>Evento</b></th>
                <td mat-cell *matCellDef="let event"><b><a (click)="OpenEvent(event.id,event.titulo,0)"> {{event.evento?.titulo}}</a></b></td>
              </ng-container>
              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef ><b>Fecha</b></th>
                <td mat-cell *matCellDef="let event">{{event.evento?.datesRange.start | date:'d MMM y' }}</td>
              </ng-container>
              <ng-container matColumnDef="entradas">
                <th mat-header-cell *matHeaderCellDef ><b>Entradas</b></th>
                <td mat-cell *matCellDef="let event">{{event.nentradas}}</td>
              </ng-container>
              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef ><b>Total</b></th>
                <td mat-cell *matCellDef="let event">{{event.total}}</td>
              </ng-container>
              <ng-container matColumnDef="pagado">
                <th mat-header-cell *matHeaderCellDef ><b>Pagado</b></th>
                <td mat-cell *matCellDef="let event">{{event.pagado === true ? 'Sí':'No'}}</td>
              </ng-container>
              <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let event">
                  <button mat-button [matMenuTriggerFor]="menuOptions" class="menu-table">
                    <i class="fas fa-ellipsis-v blue-icon"></i>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsCompras"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsCompras;"></tr>
            </table>
          </div>
          <app-pagination-page [totalItems]="numItemsCompras"  (items)="itemsChange($event)" [numPages]="totalPagesCompras" (page)="pageChange($event)"></app-pagination-page>
    </mat-tab>
    </mat-tab-group>
</div>

