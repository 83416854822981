import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-discounts-create',
  templateUrl: './discounts-create.component.html',
  styleUrls: ['./discounts-create.component.scss']
})
export class DiscountsCreateComponent {

  venue!: any;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveDiscounts: EventEmitter<any> = new EventEmitter<any>();

  @Input() discounts!: any;

  nextStep() {
    let step = {tab: 4, name: 'Mapa de butacas', slug: 'map-event'};
    this.changeTab.emit(step);
  }

  save() {
    this.saveDiscounts.next(this.discounts);
  }
}
