<!DOCTYPE html>
<html lang="{{data.language}}">
<head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <meta name="theme-color" content="#ee1b4e" />

    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap"
        rel="stylesheet"
    />
    <title>Payment Gateway</title>

    <style>
        :root {
            --primary-color: #FF4D80;
            --accent-color: #FF4D80;
            --text-color: #303030;
            --error-color: #ee1b1b;
            --background-color: white;
            --border-color: #ccc;
        }

        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-family: 'Nunito Sans', sans-serif;
        }

        /* Estilos generales */
        html {
            height: 100%;
            overflow: auto;
        }

        /* Elementos de encabezado */
        .subheader {
            margin: 8px 0;
        }

        hr.divider {
            width: 100%;
            color: var(--text-color);
            margin: 0;
        }

        /* Enlaces */
        a,
        a:active,
        a:focus,
        a:visited {
            color: var(--accent-color);
            text-decoration: none;
        }

        /* Tipografía */
        h6 {
            font-size: 20px;
            font-weight: 700;
        }

        .caption {
            line-height: 20px;
            font-size: 12px !important;
            letter-spacing: 0.4px;
            font-weight: 400;
        }

        /* Formularios y grupos de entrada */
        .form-check,
        .input-group {
            display: flex;
            align-items: center;
            padding: 12px;
        }

        .input-group > input,
        .form-check > .form-check-input {
            margin-right: 12px;
            width: 15px;
            height: 15px;
        }

        .input-group > input:checked,
        .form-check > .form-check-input:checked {
            background-color: var(--accent-color);
        }

        .input-group > #CardPNPIsSavedLabel {
            padding-left: 0.6em;
        }

        /* Formularios de entrada */
        .form-outline {
            position: relative;
            width: 100%;
        }

        .form-outline .form-icon {
            position: absolute;
            top: 0.5rem;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .form-outline .form-icon svg {
            height: 16px;
            fill: rgba(0, 0, 0, 0.54);
        }

        /* Estilos específicos para formularios con adornos al inicio o al final */
        .form-outline--start-decoration > .form-control {
            padding-left: 2.6em !important;
        }

        .form-outline--start-decoration .form-icon {
            left: 0.725rem;
        }

        .form-outline--start-decoration .form-icon#card-icon img {
            width: 32px;
            max-height: 24px;
            border-radius: 4px;
            margin: 0 4px;
        }

        .form-outline--end-decoration > .form-control {
            padding-right: 2.5em !important;
        }

        .form-outline--end-decoration .form-icon {
            right: 0.725rem;
        }

        .form-outline > .form-control {
            display: block;
            width: 100%;
            border: 1px solid var(--border-color);
            padding: 0.5em 0.75em 0.25em;
            min-height: auto;
            transition: all 0.2s linear;
            line-height: 1.6;
            font-size: 1rem;
            font-weight: 400;
            border-radius: 1.25rem;
            color: var(--text-color);
        }

        .form-outline > .form-control:focus {
            border-color: rgba(0, 0, 0, 0.54);
            outline-color: unset;
            outline-width: 0;
            box-shadow: none;
        }

        .form-outline > .form-control:focus ~ .form-label {
            transform: translateY(-10px) translateY(0.1rem) scale(0.8);
            color: rgba(0, 0, 0, 0.54);
        }

        .form-outline > .form-control ~ .form-label {
            position: absolute;
            top: 0;
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            left: 0.75rem;
            padding-top: 0.37rem;
            pointer-events: none;
            transform-origin: 0 0;
            transition: all 0.2s ease-out;
            background-color: #fff;
            color: rgba(0, 0, 0, 0.54);
            margin-bottom: 0;
            line-height: 14px;
            transform: translateY(-10px) translateY(0.1rem) scale(0.8);
        }

        .form-outline > .form-control__error-helper {
            color: var(--error-color);
        }

        .form-outline > .form-control.invalid {
            border-color: var(--error-color) !important;
        }

        .form-outline > .form-control.invalid ~ .form-label {
            color: var(--error-color);
        }

        /* Colores personalizados */
        .text-primary {
            color: var(--primary-color) !important;
        }

        .text-secondary {
            color: var(--accent-color) !important;
        }

        /* Botones */
        .btn {
            display: block;
            width: 100%;
            text-transform: uppercase;
            border: 0;
            font-weight: 600;
            padding: 8px 22px;
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.46px;
            border-radius: 24px;
            color: #ffffff;
        }

        /* Botones secundarios y estado deshabilitado */
        .btn-secondary {
            background-color: var(--accent-color);
        }

        .btn-secondary:hover {
            background-color: #a71337;
        }

        .btn:not(:disabled) {
            cursor: pointer;
        }

        .btn:disabled {
            background-color: rgba(0, 0, 0, 0.1);
            color: rgba(0, 0, 0, 0.4);
        }

        .btn span {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 32px;
        }

        .btn span:last-child {
            margin-left: 0.5rem;
        }

        /* Cuerpo de la página */
        body.body-paylands {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
            'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-size: cover;
            color: var(--text-color);
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: auto;
            background-color: white !important;
        }

        /* Contenedor principal */
        .container {
            margin: 0 auto;
            background-color: var(--background-color);
            padding: 3rem 22px 0px 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        /* Logotipo */
        .logo {
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .logo img {
            margin-right: 1rem;
            max-height: 50px;
            height: 30px;
        }

        /* Contenido principal */
        .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        /* Contenedor de resumen */
        .content .summary__container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        /* Lista de resumen */
        .content .summary__list {
            width: 100%;
            list-style: none;
            padding-inline-start: 0;
            margin: 8px auto;
        }

        /* Elemento de lista de resumen y total */
        .content .summary__list-item,
        .content .summary__total {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        .content .summary__list-item {
            margin-bottom: 8px;
        }

        .content .summary__total {
            margin: 16px 0;
        }

        /* Grupo de formulario de tarjeta */
        .content .card__form-group {
            display: flex;
            justify-content: space-between;
            margin: 8px auto;
        }

        .content .card__form-group > .form-outline:first-of-type {
            margin-right: 12px;
            flex: 6;
        }

        .content .card__form-group > .form-outline:last-of-type {
            flex: 4;
        }

        /* Formulario de tarjeta */
        .content .card__form {
            display: flex;
            flex-direction: column;
        }

        .content .card__form > .form-outline {
            margin: 8px auto;
        }

        /* Grupo de botones */
        .content .card__container .button-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .content .card__container .button-group .btn {
            margin: 8px 0;
        }

        /* Pie de página */
        .footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 24px 0 0;
            text-align: center;
        }

        .footer a {
            color: var(--text-color);
        }

        .footer a:active,
        .footer a:focus,
        .footer a:visited {
            color: var(--text-color);
        }

        .footer .poweredBy {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .footer .poweredBy span {
            margin-right: 8px;
        }

        /* Estilo de tooltip */
        .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip .tooltiptext {
            visibility: hidden;
            width: 160px;
            background-color: rgba(0, 0, 0, 0.54);
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
            position: absolute;
            top: 110%;
            margin-right: 7em; /* Ajustar la posición del tooltip a tu preferencia */
        }

        .tooltip .tooltiptext::after {
            content: ' ';
            position: absolute;
            bottom: 100%;
            right: 12%;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent rgba(0, 0, 0, 0.54)
            transparent;
        }

        .tooltip:hover .tooltiptext {
            visibility: visible;
        }

        /* Estilos para campos de entrada numérica */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        /* Estilos para etiquetas de campos de entrada obligatorios */
        input:required ~ label.form-label::after {
            content: '*';
            margin: 0 0.2rem;
        }

        /* Estilos para imágenes */
        img {
            image-rendering: -moz-crisp-edges; /* Firefox */
            image-rendering: -o-crisp-edges; /* Opera */
            image-rendering: -webkit-crisp-edges; /* Webkit */
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }

        /* Estilos para títulos de error */
        .error-title {
            color: var(--error-color);
        }

        /* Estilos para el spinner */
        #spinner {
            max-width: 2.5em;
            max-height: 2.5em;
            display: none;
        }

        #spinner img {
            max-height: 100%;
            max-width: 100%;
        }

        #alternativePayments {
            display: none;
        }

        #separator {
            padding-top: 24px;
            padding-bottom: 24px;
            position: relative;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            overflow: hidden;
            margin-top: 1em;
        }

        #separator hr {
            width: 100%;
            height: 1px;
            border: none;
            background-color: var(--text-color);
        }

        #separator span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 8px;
            margin: 0;
            white-space: nowrap;
            background-color: var(--background-color);
            color: var(--text-color);
            font-size: 14px;
        }

        .apm-button {
            border: 2px solid var(--accent-color);
            background-color: transparent;
            margin-top: 1em;
        }

        #clickToPay {
            width: 375px;
            margin: 0 auto;
            background-color: var(--background-color);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            overflow: hidden;
        }

        .max-width {
            width: 100%;
        }

        .clicktopay-link {
            cursor: pointer;
            color: var(--accent-color);
        }

        .centered {
            display: flex;
            justify-content: center;
        }

        .aligned {
            align-items: center;
        }

        .hidden {
            display: none;
        }

        #loader {
            height: 100%;
        }

        #loader img {
            max-height: 2.5em;
            max-width: 2.5em;
        }

        #checkout-iframe {
            top: 10%;
            position: absolute;
            z-index: 1;
            border: 0;
        }

        #clicktopay-modal {
            background-color: #fefefe;
            padding: 20px;
            border: 1px solid #888;
            border-radius: 0.5em;
            max-width: 400px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            width: 80%;
        }

        #clicktopay-modal-x {
            display: flex;
            justify-content: right;
            width: 1.5em;
        }

        #clicktopay-modal-x button {
            background: var(--background-color);
            border: none;
        }

        #clicktopay-modal-title img {
            margin-right: 4px;
            width: 36px;
        }

        #clicktopay-modal-title h2 {
            font-weight: 700;
            margin: 0;
            display: table-cell;
            vertical-align: middle;
        }

        #clicktopay-modal-info {
            margin-top: 2em;
        }

        #clicktopay-modal-info div {
            display: flex;
        }

        #clicktopay-modal-info-title {
            margin-bottom: 8%;
            text-align: center;
        }

        #clicktopay-modal-info-text-1 svg {
            width: 48px;
            height: 48px;
            margin-right: 0.5em;
        }

        #clicktopay-modal-info-text-1 p {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            padding: 3px;
        }

        #clicktopay-modal-info-text-1 p img {
            margin-bottom: 2px;
            width: 18px;
        }

        #clicktopay-modal-info-text-2 svg {
            width: 36px;
            height: 36px;
            margin-right: 0.5em;
        }

        #clicktopay-modal-info-text-2 p {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            padding: 3px;
        }

        #clicktopay-modal-info-text-3 svg {
            width: 36px;
            height: 36px;
            margin-right: 0.5em;
        }

        #clicktopay-modal-info-text-3 p {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            padding: 3px;
        }

        #error {
            margin-bottom: 1em;
        }

        #error-message {
            color: #ee1b1b;
            font-size: 1em;
        }

        #have-account {
            display: flex;
            margin-bottom: 1em;
        }

        #clicktopay-brands {
            float: left;
        }

        #clicktopay-brands-info {
            margin-right: 5px;
        }

        #clicktopay-brands-clicktopay {
            width: 24px;
            margin-right: 5px;
        }

        #clicktopay-brands-mastercard {
            width: 26px;
            margin-left: 5px;
            margin-right: 5px;
        }

        #clicktopay-brands-visa {
            width: 40px;
        }

        #clicktopay-brand {
            width: 40px;
        }

        #login {
            float: right;
            margin-top: 1px;
        }

        #switch-account-change {
            padding: 0.5em;
            font-size: 0.85em;
            margin-bottom: 1em;
        }

        #ClickToPayCardExpireDate {
            opacity: 0;
            line-height: 1px;
            position: relative;
        }

        #ClickToPayCardExpireMonth {
            height: 34px;
            width: 20px;
            position: absolute;
            top: 100px;
            left: 30px;
            z-index: -100;
        }

        #ClickToPayCardExpireYear {
            height: 34px;
            width: 20px;
            position: absolute;
            top: 100px;
            left: 80px;
            z-index: -100;
        }

        #ClickToPayCheckbox img {
            width: 19px;
        }

        #welcome-back {
            margin-bottom: 1em;
        }

        #welcome-back span {
            text-align: center;
            margin-right: 4px;
        }

        .otp-text {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
        }

        #otp-inputs {
            display: flex;
            flex-direction: row;
        }

        .passInput {
            width: 16.6%;
            height: 50px;
            text-align: center;
            border: 1px solid var(--accent-color);
            border-radius: 4px;
            font-weight: bold;
            font-size: 12px;
            margin: 3px;
        }

        #info-title {
            margin-bottom: 1em;
        }

        #info-title span {
            text-align: center;
            margin-right: 4px;
        }

        #info-not-you {
            margin-bottom: 0.5em;
        }

        #info-not-you p {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
        }

        #info-select-card {
            padding: 0.5em;
        }

        #info-select-card span {
            text-align: center;
        }

        #card-main {
            background: var(--background-color);
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 1em;
            display: flex;
            align-items: center;
        }

        #card-arrow {
            display: block;
            margin: 0 1% 0 auto;
        }

        #dropdown-menu {
            width: 100%;
            padding: 0;
        }

        .list-card {
            background: var(--background-color);
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 1em;
            margin: 0 0 0.5em;
            transition: background-color 0.3s ease, opacity 0.3s ease;
        }

        .list-card:hover {
            background-color: var(--accent-color);
        }

        .card-label {
            display: flex;
            align-items: center;
        }

        .card-img {
            margin-right: 0.7em;
        }

        .card-status {
            color: gray;
        }

        /* Estilos CSS para el contenido dentro del div #clicktopay-modal */
        #clicktopay-modal .modal-content {
            text-align: center; /* Centrar el contenido horizontalmente */
            padding: 20px;
            background-color: #fff;
            border-radius: 5px;
            max-width: 400px; /* Establecer un ancho máximo para el contenido */
            margin: 0 auto 20px; /* Centrar el modal horizontalmente y agregar margen inferior */
        }

        /* Estilos para el botón de cierre (x) */
        #clicktopay-modal .close-modal {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

        /* Estilos para la lista desordenada */
        #clicktopay-modal ul {
            text-align: left; /* Alinear el texto de la lista a la izquierda */
            list-style-type: disc;
            padding-left: 20px; /* Agregar espacio a la izquierda de las viñetas */
        }

        /* Estilos para la imagen */
        #clicktopay-modal img {
            max-width: 100%;
            height: auto;
            display: block; /* Para centrar la imagen horizontalmente */
            margin: 20px auto; /* Centrar la imagen horizontalmente y agregar margen superior e inferior */
        }

        /* Estilos para los textos en negrita */
        #clicktopay-modal span b {
            font-weight: bold;
            display: block; /* Para que cada título esté en una línea separada */
            margin-bottom: 10px; /* Agregar margen inferior entre los títulos y otros elementos */
        }

        /* Estilos para el párrafo final */
        #clicktopay-modal p {
            margin-top: 20px; /* Agregar margen superior */
            margin-bottom: 0; /* Eliminar margen inferior predeterminado */
        }

        @media (max-width: 437px) {
            #clickToPay {
                width: 100%;
            }
        }
    </style>
</head>

<body class="body-paylands">
<div class="container">
    <!-- <div class="logo">
        <img
            src="https://www.citylok.com/assets/img/citylok_horizontal.svg"
            alt="Citylok"
        />
    </div> -->

    <div class="content">
        <div class="card__container">
            <div id="payButtons"></div>
            <div id="clickToPay"></div>
            <form
                id="card-data"
                action=""
                method="POST"
                class="card__form"
            >
<p class="title-input">Titular de la tarjeta</p>
                <div
                    class="form-outline form-outline--start-decoration"
                >
                    <div class="form-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                            />
                        </svg>
                    </div>
                    <input
                        type="text"
                        name="cardHolder"
                        id="CardPNPCardHolder"
                        class="form-control"
                        autocapitalize="off"
                        autocomplete="cc-name"
                        required
                        tabindex="1"
                    />
                    <label
                        class="form-label"
                        for="CardPNPCardHolder"
                        id="CardPNPCardHolderLbl"
                    >
                    </label>
                </div>
<p class="title-input">Tarjeta de crédito</p>

                <div
                    class="form-outline form-outline--start-decoration"
                >
                    <div class="form-icon" id="card-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                        >
                            <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z"
                            />
                        </svg>
                    </div>
                    <input
                        type="tel"
                        name="cardNumber"
                        id="CardPNPCardPan"
                        class="form-control"
                        autocapitalize="off"
                        autocomplete="cc-number"
                        minlength="15"
                        maxlength="19"
                        required
                        tabindex="2"
                    />
                    <input
                        type="hidden"
                        name="cardPan"
                        id="cardpanField"
                    />
                    <label
                        class="form-label"
                        for="CardPNPCardPan"
                        id="CardPNPCardPanLbl"
                    >
                    </label>
                </div>
<p class="title-input">Fecha de caducidad</p>

                <div class="card__form-group">
                    <div class="form-outline">
                        <input
                            id="CardPNPCardExpireFull"
                            name="cardExpireFull"
                            autocomplete="cc-exp"
                            class="form-control"
                            placeholder="MM/YY"
                            autocapitalize="off"
                            required
                            tabindex="3"
                        />
                        <label
                            class="form-label"
                            for="CardPNPCardExpireFull"
                            id="CardPNPCardExpireFullLbl"
                        >
                        </label>
                        <div
                            style="
                                        opacity: 0;
                                        line-height: 1px;
                                        position: relative;
                                    "
                        >
                            <input
                                id="CardPNPCardExpireMonth"
                                autocomplete="cc-exp-month"
                                style="
                                            height: 34px;
                                            width: 20px;
                                            position: absolute;
                                            top: 100px;
                                            left: 30px;
                                            z-index: -100;
                                        "
                            />
                            <input
                                id="CardPNPCardExpireYear"
                                autocomplete="cc-exp-year"
                                style="
                                            height: 34px;
                                            width: 20px;
                                            position: absolute;
                                            top: 100px;
                                            left: 80px;
                                            z-index: -100;
                                        "
                            />
                        </div>
                    </div>
                    <div
                        class="form-outline form-outline--end-decoration"
                    >
                        <input
                            id="CardPNPCVV2"
                            name="cvv2"
                            class="form-control"
                            placeholder="111"
                            autocomplete="cc-csc"
                            autocapitalize="off"
                            type="number"
                            required
                            tabindex="4"
                        />
                        <label
                            class="form-label"
                            for="CardPNPCVV2"
                            id="CardPNPCVV2Lbl"
                        >
                        </label>
                        <div class="form-icon tooltip">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                                />
                            </svg>
                            <span class="tooltiptext" id="CVVhelper">
                                        El CVV (o CVC) es un código de seguridad
                                        de 3 dígitos impreso en el reverso de la
                                        tarjeta.
                                    </span>
                        </div>
                    </div>
                </div>
                <div class="button-group">
                    <button
                        type="submit"
                        class="btn btn-secondary btn-finish"
                        id="submit"
                        tabindex="6"
                    >
                                <span>
                                    <span id="paymentText"></span>
                                    <h6>
                                        <!-- {{ data.currencySymbol }}{{
                                        data.amountInteger }}.{{
                                        data.amountDecimal }} -->
                                        Terminar
                                    </h6>
                                    <div id="spinner">
                                        <img
                                            src="https://paylands-web-assets.s3.eu-west-1.amazonaws.com/payment_card/spinner_pink.gif"
                                        />
                                    </div>
                                </span>
                    </button>
                </div>
            </form>
            <div id="alternativePayments">
                <div id="separator">
                    <hr />
                    <span id="or_pay">O paga con</span>
                </div>
                <div id="manualCardEntry"></div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="poweredBy">
                    <span
                        id="serviceOfferedBy"
                        class="caption text-secondary"
                    ></span>
        </div>
        <a
            href="https://paynopain.com/politica-de-privacidad"
            class="caption"
            target="_blank"
            id="termsAndConditions"
        ></a>
    </div>
</div>

<script src="https://code.jquery.com/jquery-3.7.1.min.js"></script>
<script src="https://s3-eu-west-1.amazonaws.com/paylands-web-assets/js/bootstrap.min.js"></script>
<script src="https://s3-eu-west-1.amazonaws.com/paylands-web-assets/js/jquery.payment.js"></script>
<script src="https://s3-eu-west-1.amazonaws.com/paylands-web-assets/js/client.min.js"></script>

<script src="/assets/templates/js/translations.js"></script>
<script src="/assets/templates/js/v1-device.js"></script>
<script src="/assets/templates/js/v1.1-paylands.js"></script>
<script type="text/javascript">
    const translateFields = function (translations) {
        $('#CVVhelper').html(translations.alert_cvv_info);
        $('#CardPNPCardHolderLbl').html(
            translations.card_holder_placeholder
        );
        $('#CardPNPCardPanLbl').html(
            translations.card_number_placeholder
        );
        $('#CardPNPCardExpireFullLbl').html(
            translations.card_expiry_placeholder
        );
        $('#CardPNPCVV2Lbl').html(translations.cvv_placeholder);
        $('#CardPNPIsSavedLabel').html(translations.save_card);
        $('#serviceOfferedBy').html(translations.service_offered_by);
        $('#termsAndConditions').html(
            translations.terms_and_conditions
        );
        $('#paymentText').html(translations.pay + '&nbsp;');
        $('#or_pay').html(translations.checkout.or_pay);
    };

    const submitForm = () => {
        $('#submit').attr('disabled', true);
        $('#spinner').show();
        $('#cardpanField').val(
            $('#CardPNPCardPan').val().replace(/\s/g, '')
        );

        $('<input>', {
            type: 'hidden',
            id: 'device',
            name: 'device',
            value: getDevice(),
        }).appendTo('form');
        return true;
    };

    const amount = '{{ data.amount }}';
    const amountInteger = '{{ data.amountInteger }}';
    const amountDecimal = '{{ data.amountDecimal }}';
    const currencyCode = '{{ data.currencyCode }}';
    const currencySymbol = '{{ data.currencySymbol }}';
    const language = '{{ data.language }}';
    const cancelPaymentUrl = '{{ data.cancel_payment_url }}';
    const environment = '{{ data.environment }}';
    const transactionUuid = '{{ data.transaction }}';
    const order = '{{ data.order }}';

    const acquirerMID = environment === 'PRODUCTION' ? '{{ data.acquirer_mid }}' : '12345678';
    const acquirerBINs = JSON.parse('{{ data.acquirer_bins | json_encode | raw }}');
    const acquirerBIN = environment === 'PRODUCTION' ? (acquirerBINs != null ? acquirerBINs.VISA : null) : '455555';

    const currentTranslations = getTranslations();

    const extraData = JSON.parse(
        "{{ data.extra_data | json_encode | e('js') }}"
    );

    const profile = extraData?.profile ?? null;
    const prefilledName = profile?.first_name ?? null;
    const prefilledLastName = profile?.last_name ?? null;
    let prefilledEmail = profile?.email ?? null;
    const prefilledPhone = profile?.phone ?? null;
    const prefilledPhoneNumber = prefilledPhone?.number ?? null;
    const prefilledPhonePrefix = prefilledPhone?.prefix ?? null;
    const prefilledAddress = extraData?.address ?? null;
    const prefilledCountry = prefilledAddress?.country ?? null;

    const visaClicktoPay = JSON.parse(
        '{{ data.visa_clicktopay | json_encode | raw }}'
    );

    const mastercardClicktoPay = JSON.parse(
        '{{ data.mastercard_clicktopay | json_encode | raw }}'
    );

    const googlePayData = JSON.parse(
        '{{ data.google_pay | json_encode | raw }}'
    );

    const applePayData = JSON.parse(
        '{{ data.apple_pay | json_encode | raw }}'
    );

    const payButtons = $('#payButtons');

    $(document).ready(() => {
        translateFields(currentTranslations);
        addValidationEvents(currentTranslations);
        $('#CardPNPCardHolder').focus();
        $('form').submit(() => submitForm());
    });
</script>

</body>
</html>