import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, first } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoritosService {

  private modified = new BehaviorSubject<any>(false);
  public modObs = this.modified.asObservable();

  constructor(private http: HttpClient) { }

  setMod() {
    this.modified.next(false);
  }

  getFavoritos(items: any = 12, page: any = 1) {
    const url = `${ AppSettings.API_URI }/favoritos?items=${items}&page=${page}`;
    return this.http.get<any>( url ).pipe(
      map(data => {
        return data;
      }
    ));
  }

  getFavoritosLast(items: any = 12, page: any = 1) {
    const url = `${ AppSettings.API_URI }/favoritos/last?items=${items}&page=${page}`;
    return this.http.get<any>( url ).pipe(
      map(data => {
        return data;
      }
    ));
  }

  postFavoritos(eventId: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/favoritos`;
    return this.http.post<any>(url, {evento: eventId}, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        }
        return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  deleteFavorito(eventId: number) {
    const url = `${ AppSettings.API_URI }/favoritos/${ eventId }`;
    return this.http.delete<any>( url ).pipe(
      map(data => {
        if(data) {
          this.modified.next(eventId);
          return of(true);
        }
        return of(false);
      }), catchError(err => {
        return of(false);
      })
    );
  }
}

