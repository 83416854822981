import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class PanelAdminService {

  breadCrumb: any[] = [];

  constructor(private http: HttpClient) { }

  getBreadcrumbsPage(page: any) {
    let breadcrumb = {
      name: '',
      url: '',
      params: {},
      extra: []
    }
    switch (page) {
      case 'list-business':
        breadcrumb.name = 'Empresas';
        breadcrumb.url = '/panel-control/list-business';
        break;
      // Eventos
      case 'events':
        breadcrumb.name = 'Eventos';
        breadcrumb.url = '/panel-control/events';
        break;
      case 'list-events':
        breadcrumb.name = 'Lista de eventos';
        breadcrumb.url = '/panel-control/events';
        break;
      case 'create-venue':
        breadcrumb.name = 'Crear espacio';
        break;
      case 'info-event':
      case 'ticket-event':
      case 'discount-event':
      case 'map-event':
      case 'order-event':
      case 'report-event':
      case 'mailing-event':
      case 'comment-event':
        breadcrumb.name = 'Detalle Evento';
        breadcrumb.url = '';
        break;
      case 'draft':
        breadcrumb.name = 'Borradores';
        breadcrumb.url = '/panel-control/events/draft';
        break;
      case 'bonos':
        breadcrumb.name = 'Abonos';
        breadcrumb.url = '/panel-control/events/bonos';
        break;

      // Pedidos
      case 'orders':
        breadcrumb.name = 'Pedidos';
        breadcrumb.url = '/panel-control/orders';
        breadcrumb.extra = [{
          name: 'Lista de pedidos',
          url: '/panel-control/orders'
        }];
        break;
      case 'info-order':
        breadcrumb.name = 'Detalle Pedido';
        breadcrumb.url = '';
        break;

      // Usuarios      
      case 'subscribers':
        breadcrumb.name = 'Usuarios';
        breadcrumb.url = '/panel-control/subscribers/list-subscribers';
        break;
      case 'list-users':
        breadcrumb.name = 'Lista de usuarios';
        breadcrumb.url = '';
        break;
      case 'list-subscribers':
        breadcrumb.name = 'Lista de miembros';
        breadcrumb.url = '';
        break;

      // Informes
      case 'reports':
        breadcrumb.name = 'Informes';
        breadcrumb.url = '/panel-control/reports';
        break;

      // Configuracion
      case 'config':
        breadcrumb.name = 'Configuración';
        breadcrumb.url = '/panel-control/config/venues';
        break;
      case 'venues':
        breadcrumb.name = 'Lista de espacios';
        breadcrumb.url = '/panel-control/config/venues';
        break;
      case 'info-venue':
      case 'tickets-venue':
      case 'discounts-venue':
      case 'map-venue':
      case 'event-venue':
      case 'docs-event':
        breadcrumb.name = 'Detalle espacio';
        breadcrumb.url = '';
        break;
      case 'business':
        breadcrumb.name = 'Organización';
        breadcrumb.url = '/panel-control/config/business';
        break;
      case 'permission':
        breadcrumb.name = 'Permisos y gestores';
        breadcrumb.url = '/panel-control/config/permission/function';
        breadcrumb.params = { name: 'role', value: 'all' };
        break;
      case 'info-profile':
        breadcrumb.name = 'Perfil de usuario';
        breadcrumb.url = '';
        break;
      case 'user':
        breadcrumb.name = 'Detalle usuario';
        breadcrumb.url = '';
        break;
      default:
        break;
    }
    return breadcrumb;
  }

  getEventsOrganizer(page: number) {
    const url = `${AppSettings.API_URI}/events/organizer?page=${page}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  getOrdersOrganizer(page: number, searchText: any, filtersOrders: any) {
    let url = `${AppSettings.API_URI}/reports/user?page=${page}`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    if (filtersOrders) {
      if (filtersOrders.dateFilter) {
        url += `&date=${filtersOrders.dateFilter}`;
      }
      if (filtersOrders.priceFilter) {
        url += `&price=${filtersOrders.priceFilter}`;
      }
      if (filtersOrders.statusFilter) {
        url += `&status=${filtersOrders.statusFilter}`;
      }
    }
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  downloadTicket(channel: string, idTicket: any) {
    const url = `${AppSettings.API_URI}/download/pdf/ticket/${channel}/${idTicket}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  downloadPrintTicket(channel: string, idTicket: any) {
    const url = `${AppSettings.API_URI}/download/print/ticket/${channel}/${idTicket}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      })
    );
  }

  downloadReportEvent(idEvent: any) {
    const url = `${AppSettings.API_URI}/download/report/event/${idEvent}`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data
      }),
      catchError(err => { return throwError(() => err); })
    );
  }
}
