<div class="w-100 d-flex justify-content-end">
    <div class="container-last">
        <p class="title-last">Lo último</p>
        <div class="row-blogs">
            <div class="post-row pointer-in" *ngFor="let post of lastBlogs | async" [routerLink]="['/cityblog', post.slug]">
                <img (error)="post.acf.img_main = '../../../../assets/img/image_default.png'" src="{{post.acf.img_main}}" alt="{{post.title.rendered}}" />
                <div class="title-date-post">
                    <p class="m-0">
                        <i class="fa-light fa-clock" style="padding-right: 5px;"></i>
                        {{ post?.date | date: 'dd MMMM yyyy' | titlecase }}
                    </p>
                    <div class="title-post short-text" [innerHTML]="post.title.rendered"></div>
                </div>
            </div>
        </div>
    </div>
</div>