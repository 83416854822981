<div class="page-tickets-type">
    <!-- Descuentos -->
    <div class="d-flex justify-content-between align-items-center">
        <p class="title-tab">Descuentos base del espacio:</p>
        <!-- <div class="btn-create m-0" (click)="openCreateDiscount()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Crear descuento para este espacio</p>
        </div> -->
    </div>
    <div *ngIf="discountsPlace; else divNotDiscounts">

    </div>
    <ng-template #divNotDiscounts>
        <p>No existen descuentos para este espacio.</p>
    </ng-template>

    <!-- Abonos -->
    <div class="div-pass">
        <!-- <p class="title-tab">Abonos</p> -->
        <!-- <p>Este evento aún no pertenece a ningún abono. Puedes añadirlo a un abono disponible o crear uno nuevo desde el apartado <a class="link-open" target="_blank" href="/panel-control/events/bonds">Abonos.</a></p> -->
        <!-- <div class="btn-create btn-create-border m-0" (click)="openCreateDiscount()">
            <i class="fal fa-plus-circle"></i>
            <p class="m-0">Añadir evento a un abono disponible</p>
        </div> -->
    </div>
</div>