import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CategoriasService } from 'src/app/services/categorias.service';
import { Location } from '@angular/common';
import { InfoCardComponent } from '../../../../shared/info-card/info-card.component';

@Component({
  selector: 'app-add-categoria',
  templateUrl: './add-categoria.component.html',
  styleUrls: ['./add-categoria.component.scss']
})
export class AddCategoriaComponent implements OnInit {


  displayedColumns: string[] = ['icono', 'nombre', 'slug','options'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  catSinMostrar = [];

  idCat;

  constructor(private categoriasService: CategoriasService,private dialog: MatDialog, private location: Location) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.categoriasService.getAllCategories().subscribe(c => {
      c.forEach(element => {
        if(!element.showhome){
          this.catSinMostrar.push(element);
        }
      });
      this.dataSource.data = this.catSinMostrar;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InfoCardComponent, {

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.goBack();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  addCat(catId){
    var cat;

    this.categoriasService.getCategoriabyId(catId).subscribe(c => {
      cat = c;
      cat.showhome = true;

      this.categoriasService.putCategoria(catId,cat).subscribe();

      this.goBack();
    });
  }

}
