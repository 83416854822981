import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettings } from 'src/app/app-settings';
import { UserService } from '../../../services/user.service';
import { DeletesComponent } from '../shared/deletes/deletes.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  // displayedColumns: string[] = [ 'imagen','nickname', 'nombre', 'email','roles','options'];
  displayedColumns: string[] = [ 'imagen','nickname', 'nombre', 'email', 'movil', 'sms_validate', 'oficial', 'options'];
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)sort: MatSort = new MatSort;

  page = 1;
  totalPages = 1;

  numItems = 0;
  items = 10;

  apiMedia: string = AppSettings.MEDIA_URI + '/images/';

  search!: string;
  sorted!: string;

  constructor(private userService: UserService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.userService.getUsers(this.page,this.items).subscribe(users => {
      this.dataSource = users['hydra:member'];
      this.dataSource.sort = this.sort;
      this.numItems = users['hydra:totalItems'];
      this.totalPages = Math.ceil(this.numItems/this.items);
    });
  }

  getFilterOrderData() {
    this.userService.getUsers(this.page,this.items,this.sorted,this.search).subscribe(users => {
      this.dataSource = users['hydra:member'];
      this.dataSource.sort = this.sort;
      this.numItems = users['hydra:totalitems'];
      this.totalPages = Math.ceil(this.numItems/this.items);
    });
  }

  roleUser(rol: string[]): String{
    var rolesUser: String = '';

    rol.forEach(r => {
      rolesUser += r.split('_')[1] + ' , '
    });

    return rolesUser.trim().substring(0,rolesUser.trim().length-1);
  }

  applySearch(event){
    this.search = (event.target as HTMLInputElement).value.trim();
    this.getFilterOrderData();
  }

  // Delete
  openDelete(userId: number) {
    const dialogRef = this.dialog.open(DeletesComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteUser(userId);
      }
    });
  }

  deleteUser(userId: number){
    this.userService.deleteUser(userId).subscribe(data => {
       this.getData();
    })
  }

  //Pagination
  pageChange(event:any) {
    this.page = event;
    if(this.sorted != null) {
      this.getFilterOrderData();
    } else {
      this.getData();
    }
  }

  itemsChange(event:any) {
    this.items = event;
    if(this.sorted != null) {
      this.getFilterOrderData();
    } else {
      this.getData();
    }
  }

  // Sort
  sortData(sort: Sort) {
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      if(sort.active == 'nombre') {
        this.sorted = `order[nombre]=${ isAsc }`;
      } else if(sort.active == 'nickname') {
        this.sorted = `order[nickname]=${ isAsc }`;
      } else if(sort.active == 'email') {
        this.sorted = `order[email]=${ isAsc }`;
      } else {
        this.sorted = `order[${ sort.active }]=${ isAsc }`;
      }
      this.getFilterOrderData();
    } else {
      this.sorted = null;
      this.getData();
    }
  }

}
