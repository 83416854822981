import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute, NavigationEnd } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from "jwt-decode";
import { AppSettings } from '../app-settings';


@Injectable({
  providedIn: 'root'
})
export class TotemGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  hasError() {
    this.router.navigate(['/home-totem']);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var totemActive = AppSettings.ENV_TOTEM;
    if (totemActive) {
      return this.hasError();
    } else {
      return true;
    }
  }

}
