import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { AppSettings } from '../app-settings';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  date = new Date().getTime().toString().substring(0, 10);
  urlsNotUse: Array<string>;
  loading: boolean = false;

  constructor(private router: Router, private http: HttpClient, private dialog: MatDialog) {
    this.urlsNotUse = [
      '/api/categorias',
      '/api/ciudades',
      '/api/eventos',
      '/token/refresh',
      '/login'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.isValidRequestForInterceptor(request.url)) {
      const jwt_token = localStorage.getItem('jwt_token');
      if (jwt_token) {
        var decoded: any = jwt_decode(jwt_token);
        if (decoded && decoded.exp > this.date) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt_token}` //Authorization
            }
          });
        }
      }
    }

    return next.handle(request).pipe(
      tap((res: any) => { }
      ), catchError((error: any) => {
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }

        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && !this.loading) {
            if (error.url.indexOf('/token/refresh') != -1) {
              localStorage.removeItem('jwt_token');
              localStorage.removeItem('refresh');
              return this.goBackUser(error);
            }
            if (localStorage.getItem('refresh')) {
              this.loading = true;
              this.handle401Error(request, next, error);
            }
          } else if (error.status === 403 && !this.loading) {
            if (error.url.indexOf('/token/refresh') != -1) {
              localStorage.removeItem('jwt_token');
              localStorage.removeItem('refresh');
              return this.goBackUser(error);
            }
            if (localStorage.getItem('refresh')) {
              this.loading = true;
              this.handle401Error(request, next, error);
            } else {
              this.loading = false;
              this.router.navigate(['/']);
            }
          } else if (error.status == 500) {
            this.goBackUser(error);
          }
        }
        return throwError(() => error);
      }));
  }

  goBackUser(error: HttpErrorResponse) {
    // localStorage.clear();
    this.router.navigate(['/']);
    this.dialog.closeAll();
    this.loading = false;
    // location.reload();
    return throwError(() => new HttpErrorResponse(error));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    var refresh = localStorage.getItem('refresh');
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(AppSettings.API_URI + '/token/refresh', { refresh_token: refresh }, formHeaders).subscribe({
      next: (jwt: any) => {
        if (!jwt.token) {
          this.loading = false;
          return this.goBackUser(error);
        }
        localStorage.setItem('jwt_token', jwt.token);
        localStorage.setItem('refresh', jwt.refresh_token);
        if (this.isValidRequestForInterceptor(request.url)) {
          let clone: HttpRequest<any>;
          clone = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt.token}`
            }
          });
        }
        this.loading = false;
        return next.handle(request);
      },
      error: (err: any) => {
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('refresh');
        return this.goBackUser(error);
      }
    });
  };

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = '/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substring(position + positionIndicator.length);
      for (let address of this.urlsNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
}
