<div class="bonds-panel">
    <div class="btns-filters">
        <div class="buscador-event container-form d-flex align-items-center borderInput">
            <i class="fal fa-search search-icon pointer-in" (click)="searchEvents(searchInput.value)"></i>
            <input #searchInput class="input-custom w-100" type="text" placeholder="Buscar evento"
                [ngModel]="searchText" (input)="searchEvents(searchInput.value)">
        </div>
        <div class="btns-create" *ngIf="allowCreate">
            <div class="btn-create btn-create-border btn-bono m-0" (click)="openCreateBond()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Crear abono</p>
            </div>
            <div class="btn-create m-0" (click)="openCreateEvent()">
                <i class="fal fa-plus-circle"></i>
                <p class="m-0">Crear evento</p>
            </div>
        </div>
    </div>

    <div *ngIf="loadingPage" class="container-lottie">
        <div class="loading-container">
            <ng-lottie class="lottie" [options]="{path: 'assets/lotties/loading.json'}"
                containerClass="loading-box"></ng-lottie>
        </div>
    </div>

    <div #containerScroll class="table-events table-panel multiple-lines" *ngIf="loaded"
        (scroll)="isScrolledInfinityView($event)">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let bond">
                    <div [matMenuTriggerFor]="actionOptions" style="width: 20px;" class="pointer-in d-flex justify-content-center">
                        <i class="icon-option fal fa-ellipsis-vertical"></i>
                    </div>
                    <mat-menu class="options-menu-control-panel" #actionOptions="matMenu">
                        <!-- <button mat-menu-item class="deleteIcon" (click)="openBond(bond[0])">Ver</button> -->
                        <button mat-menu-item class="editIcon" (click)="openCreateBond(bond[0], true)" *ngIf="allowCreate">Editar</button>
                        <button mat-menu-item class="editIcon" (click)="deleteBond(bond)" *ngIf="bond.purchases <= 0 && allowCreate">Eliminar</button>
                    </mat-menu>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef><b>Abono</b></th>
                <td mat-cell *matCellDef="let bond" (click)="openCreateBond(bond[0], true)">
                    <span class="link-titulo">{{bond[0].name}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="events">
                <th mat-header-cell *matHeaderCellDef><b>Eventos</b></th>
                <td mat-cell *matCellDef="let bond" (click)="openCreateBond(bond[0], true)">
                    <span>{{bond[0].events?.length}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef><b>Descuento</b></th>
                <td mat-cell *matCellDef="let bond" (click)="openCreateBond(bond[0], true)">
                    <span>{{bond[0].discount?.amount ? bond[0].discount?.amount+'€' : bond[0].discount?.percentage+'%'}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="sales">
                <th mat-header-cell *matHeaderCellDef><b>Vendidos</b></th>
                <td mat-cell *matCellDef="let bond" (click)="openCreateBond(bond[0], true)">
                    <span>{{(bond.purchases > 0 ? bond.purchases/bond[0].events?.length : 0) | number:'1.0-0'}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><b>Estado</b></th>
                <td mat-cell *matCellDef="let bond" (click)="openCreateBond(bond[0], true)">
                    <div class="d-flex align-items-center status-event" [style.color]="getStatus(bond).color">
                        <i class="fa-solid fa-circle"></i>
                        <span>{{getStatus(bond).status}}</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="pagination-component">
            <div class="d-flex align-items-center pagination-panel pagination-table"
                *ngIf="!loadingPage && !finishScroll">
                <div>
                    <ng-lottie class="lottie-pagination" [options]="{path: 'assets/lotties/loading.json'}"
                        containerClass="loading-box"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
    <p *ngIf="loaded && !loadingPage && eventos?.length <= 0" style="text-align: center; margin-top: 80px;">No hay
        abonos disponibles</p>
</div>