import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { CreateEventService } from 'src/app/services/create-event.service';
import { EventosService } from 'src/app/services/eventos.service';

@Component({
  selector: 'app-map-seats-create',
  templateUrl: './map-seats-create.component.html',
  styleUrls: ['./map-seats-create.component.scss']
})
export class MapSeatsCreateComponent {

  @Input() idEvent!: any;
  @Input() venue!: any;
  @Input() capacity!: any;
  @Input() map!: any;
  @Input() useMapVenue!: any;
  business = JSON.parse(localStorage.getItem('business') || '{}');

  openedCreateMap: boolean = false;
  editCreateMap: boolean = true;
  loading: boolean = true;
  max: number = 10000;

  constructor(private eventService: EventosService, private dialog: MatDialog, private activatedRoute: ActivatedRoute,
    private createService: CreateEventService) { }

  ngOnInit(): void {
    let capacity = this.createService.getCapacity();
    if (capacity) {
      this.capacity = capacity;
    }
    let infoEvent = this.createService.getInfoEvent();
    if (infoEvent) {
      this.venue = infoEvent.values?.place;
      this.capacity = this.venue?.capacity;
      if (this.venue && this.useMapVenue) {
        this.map = this.venue?.mainMap;
        this.eventService.setActionMap({ open: true });
      } else {
        this.max = this.capacity ? this.capacity : 100000;
      }
    }
    this.loading = false;
  }

  createMap() {
    // this.openedCreateMap = !this.openedCreateMap;
    // let action = !this.openedCreateMap ? 'close' : 'edit';
    // this.eventService.setActionMap({ action: action });
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: true,
        text: 'Aún no puedes crear mapas de butacas, por favor, contacta con el administrador de Citylok para más información.',
        btnCancel: false
      }
    });
  }

  changeMap() {
    // this.createService.setMapEvent(this.capacity);
  }

  changeCapacity() {
    this.createService.setCapacity(this.capacity);
  }

  ngOnDestroy(): void {
    this.eventService.setActionMap({ open: false });
  }
}
