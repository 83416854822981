import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SeatMapService } from 'src/app/services/seat-map.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { NameMapComponent } from '../name-map/name-map.component';

@Component({
  selector: 'app-create-map',
  templateUrl: './create-map.component.html',
  styleUrls: ['./create-map.component.scss']
})
export class CreateMapComponent implements OnInit, OnDestroy {

  @Input() fromVenue!: boolean;
  @Input() fromEvent!: boolean;
  @Input() fromCreate!: boolean;
  @Input() idMap!: number;
  @Input() idEvent!: number;
  @Input() allowCreate: boolean = false;
  loading: boolean = true;
  loadingCanvas: boolean = true;
  titleMap!: any;
  editEvent: boolean = false;
  bookingSeats!: any;

  constructor(private activatedRoute: ActivatedRoute, private seatMap: SeatMapService, private router: Router,
    private dialog: MatDialog, private location: Location, private ticketsService: TicketsService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        if (params.idMap) { this.idMap = params.idMap }
        if (params.idMap) { this.idEvent = params.idEvent }
        if (location.href.indexOf('edit-event') != -1) {
          this.editEvent = true;
          this.auth.getMyData().subscribe({
            next: (data) => {
              if (data) {
                let organizer = false;
                let organizerEvents = data.organizerEvents?.map(value => value.id);
                if (organizerEvents?.includes(+this.idEvent)) {
                  organizer = true;
                }
                if (!organizer) {
                  this.location.back();
                }
              }
            }
          });
        }
        if (this.fromEvent) {
          this.editEvent = true;
        }
        if (this.idEvent) {
          this.ticketsService.getAllBookingsByEvent(this.idEvent).subscribe({
            next: (data) => {
              this.bookingSeats = data;
              this.loading = false;
            }
          })
        } else {
          this.loading = false;
        }
      }
    });
  }

  saveMap() {
    this.seatMap.saveMapEvent(true);
  }

  cancel() {
    this.editEvent ? this.location.back() :
      this.router.navigate(['/profile/seat-maps']);
  }

  paintCanvas(event: any) {
    this.titleMap = event;
    this.loadingCanvas = false;
  }

  editName() {
    const dialogRef = this.dialog.open(NameMapComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { edit: this.idMap != null, name: this.titleMap }
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result.edit) {
          this.titleMap = result.name;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.ticketsService.setBookingsStorage(null);
  }

}
