<div class="container-page-checkout">
    <div class="logo-checkout">
        <div class="container">
            <img fallimg src="assets/img/citylok_horizontal.svg" alt="Citylok logo">
        </div>
    </div>
    <div class="header-checkout">
        <div class="btns-header-checkout">
            <div class="d-flex justify-content-center">
                <div class="d-flex col-6 justify-content-end" style="position: relative;">
                    <div class="circle-btn" [class.active]="step == 1">
                        <span *ngIf="step == 1; else divCheckLogin">1</span>
                        <ng-template #divCheckLogin>
                            <i class="fa-regular fa-check icon-green"></i>
                        </ng-template>
                    </div>
                    <mat-divider></mat-divider>
                </div>
                <div class="d-flex col-6" style="position: relative;">
                    <mat-divider></mat-divider>
                    <div class="circle-btn" [class.active]="step == 2">
                        <span *ngIf="step <= 2; else divCheckPay">2</span>
                        <ng-template #divCheckPay>
                            <i class="fa-regular fa-check icon-green"></i>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="names-steps d-flex containers-checkout">
                    <p class="col-6">Correo electrónico</p>
                    <p class="col-6">Pago</p>
                </div>
            </div>
        </div>
    </div>
    <div class="steps-checkout">
        <div class="step-login" *ngIf="step == 1 && !user; else stepPay">
            <p class="title-step">{{ titleStepForm }}</p>
            <app-warning *ngIf="message" [text]="message"></app-warning>
            <app-login-card *ngIf="!registerForm; else divRegisterForm" textBtn="Siguiente" backgroundColor="white" (registerBtn)="changeOption($event, 'register')"
            (redirectMsg)="getMessage($event)" (errorMsg)="getErrorLogin($event)" (correctForm)="updateUser($event)"></app-login-card>
            <ng-template #divRegisterForm>
                <app-register-card backgroundColor="white" textBtn="next" (goLogin)="changeOption($event, 'login')"
                (msgError)="getMessage($event)" (correctForm)="updateUser($event)"></app-register-card>
            </ng-template>
        </div>
        <ng-template #stepPay>
            <div class="step-pay" *ngIf="step == 2 && !correctPayment; else stepCorrect">
                <p class="title-step">Seleccionar método de pago</p>
                <app-step-checkout [dataBuy]="data" (sendPayment)="checkPay()"></app-step-checkout>
            </div>
            <ng-template #stepCorrect>
                <div class="step-confirm">
                    <app-confirm-pay></app-confirm-pay>
                </div>
            </ng-template>
        </ng-template>
    </div>

    <div class="footer-checkout">
        <div class="container d-md-flex justify-content-center justify-content-md-between align-items-center">
            <p class="m-0">&#169; Kreare Mobile {{now | date:'y'}}</p>
            <div class="d-flex align-items-center legacy-links">
                <a class="link-f pointer-in" routerLink="/contact" target="_blank">Contacto</a>·
                <a class="link-f pointer-in" routerLink="/terms-conditions" target="_blank">Términos y condiciones</a>·
                <a class="link-f pointer-in" routerLink="/privacy" target="_blank">Privacidad</a>·
                <a class="link-f pointer-in" target="_blank">Cookies</a>
            </div>
        </div>
    </div>    
</div>