import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-discounts-event',
  templateUrl: './discounts-event.component.html',
  styleUrls: ['./discounts-event.component.scss']
})
export class DiscountsEventComponent implements OnInit {

  @Input() profile!: any;
  @Input() event!: any;
  @Input() discounts!: any;
  @Input() abonos!: any;

  displayedColumnsDiscounts: string[] = ['name', 'discount', 'numMax', 'code', 'dates', 'orders', 'hiddenSeat'];
  dataSourceDiscounts: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayedColumnsAbonos: string[] = ['name', 'discount', 'events', 'code', 'dates', 'orders'];
  dataSourceAbonos: MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.dataSourceDiscounts = this.discounts;
    this.dataSourceAbonos = this.abonos;
  }

  copyCode(text: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
