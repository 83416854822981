import { Component } from '@angular/core';

@Component({
  selector: 'app-draft-events',
  templateUrl: './draft-events.component.html',
  styleUrls: ['./draft-events.component.scss']
})
export class DraftEventsComponent {
  
}
