<div class="d-grid justify-content-center card-info">
  <i class="fa-thin fa-trash-can"></i>
  <p class="text-info-card">
      ¿Estás seguro de que deseas eliminarlo?
  </p>
  <div class="d-flex justify-content-between align-items-center">
      <button class="cancel-button" [mat-dialog-close]="false">Cancelar</button>
      <button class="primary-button" [mat-dialog-close]="true">Aceptar</button>
  </div>
</div>
