<div class="template-admin-card">

  <div w-100 class="row align-items-center justify-content-between title-table">
      <h2 class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 title-h2">Categorías</h2>
      <div class="col col-lg-9 col-md-9 col-sm-12 col-xs-12 div-buttons" style="text-align: end">
        <button class="mat-raised-button" [routerLink]="['/admin/categorias/edit-categoria']">
          <i class="new far fa-plus"></i>Nueva categoría
        </button>
      </div>
  </div>
  <div class="platform-card">

    <mat-card appearance="outlined" class="mt-1">
      <mat-card-content class="platform-card-description" class="content-table">

        <div class="container-table table-cat">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z28">
            <ng-container matColumnDef="icono">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let cat"><i class="fa-thin {{ cat.icon }} icon-primary fa-2x"></i>
            </ng-container>
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef><b>Nombre</b></th>
              <td mat-cell *matCellDef="let cat">{{cat.nombre}}</td>
            </ng-container>
            <ng-container matColumnDef="eventos">
              <th mat-header-cell *matHeaderCellDef><b>Eventos</b></th>
              <td mat-cell *matCellDef="let cat">{{cat.countEvents}}</td>
            </ng-container>
            <ng-container matColumnDef="showhome">
              <th mat-header-cell *matHeaderCellDef><b>Pantalla principal</b></th>
              <td mat-cell *matCellDef="let cat">
                <i *ngIf="cat.showhome" class="fa-solid fa-check"></i>
                <!-- <i *ngIf="!cat.showhome" class="fa-solid fa-x"></i> -->
              </td>
            </ng-container>

            <!-- Eliminar/Editar -->
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let cat">
                <button mat-button [matMenuTriggerFor]="menuOptions" class="menu-table">
                  <i class="fas fa-ellipsis-v pointsIcon"></i>
                </button>
                <mat-menu class="menu-options" #menuOptions="matMenu">
                    <button mat-menu-item [routerLink]="['/admin/categorias/edit-categoria',cat.id]" class="editIcon"><i class="fal fa-edit"></i> Editar</button>
                    <button mat-menu-item class="deleteIcon" *ngIf="cat.countEvents == 0" (click)="openDelete(cat)"><i class="far fa-trash-alt"></i> Eliminar</button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

      </mat-card-content>
    </mat-card>

  </div>

</div>
