import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormulariosService {

  constructor(private http: HttpClient) { }


  sendSuggestion(dataForm: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/sendsuggestion`;
    return this.http.post<any>(url, dataForm, formHeaders).pipe(
      map(
        data => {
          return data;
        }
      ), catchError((err) => {
        return of(false);
      })
    );

  }

  sendMessage(dataForm: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/sendmessage`;
    return this.http.post<any>(url, dataForm, formHeaders).pipe(
      map(
        data => {
          return data;
        }
      ), catchError((err) => {
        return of(false);
      })
    );

  }
}
