import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InfoCardComponent } from '../info-card/info-card.component';

const bcrypt = require('bcryptjs');

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  resetForm: FormGroup;
  message = '';
  messagePassword = '';

  private token = '';
  private email = '';

  hide: boolean = true;
  hideConfirm: boolean = true;
  salt = bcrypt.genSaltSync(10);
  backgroundColor: any = '#F8F8F8';

  constructor(private formBuilder: FormBuilder, private auth: AuthenticationService,
    private dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {
    this.resetForm = this.formBuilder.group({
      textPassword: ['', Validators.compose([
        Validators.required,
        this.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        this.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
        Validators.maxLength(30)
      ])],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]]
    }, {
      validators: this.password.bind(this)
    });
  }

  // Validacion contraseña
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.controls['textPassword'];
    const { value: confirmPassword } = formGroup.controls['confirmPassword'];
    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordNotMatch: true };
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = regex.test(control.value);
      if (valid) {
        return null;
      } else {
        return error;
      }
    };
  }

  get pass() { return this.resetForm.get('textPassword'); }
  get repPass() { return this.resetForm.get('confirmPassword'); }


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (data) => {
        if (data.email && data.token) {
          this.email = data.email;
          this.token = data.token;
        } else {
          this.router.navigate(['/']);
        }
      }
    )
  }

  submit() {
    if (this.resetForm.valid && this.email && this.token) {
      var password = this.resetForm.controls['textPassword'].value;
      var hash = bcrypt.hashSync(password, this.salt);

      const payload = {
        password: hash,
        email: this.email,
        token: this.token
      }

      this.auth.resetPassword(payload).subscribe({
        next: (data) => {
          const dialogRef = this.dialog.open(InfoCardComponent, {
            panelClass: 'info-dialog',
            data: {
              icon: false,
              text: 'Se ha actualizado la contraseña correctamente',
              btnCancel: false
            }
          });
          dialogRef.afterClosed().subscribe(
            data => {
              this.router.navigate(['/login']);
            }
          );
        },
        error: () => {
          const dialogRef = this.dialog.open(InfoCardComponent, {
            panelClass: 'info-dialog',
            data: {
              icon: false,
              text: '¡Vaya! Algo ha fallado, vuelve a intentarlo más tarde.',
              btnCancel: false
            }
          });
        }
      });
    } else {
      const dialogRef = this.dialog.open(InfoCardComponent, {
        panelClass: 'info-dialog',
        data: {
          icon: false,
          text: 'Comprueba todos los campos',
          btnCancel: false
        }
      });
    }
  }
}