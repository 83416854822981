import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-discount',
  templateUrl: './dialog-discount.component.html',
  styleUrls: ['./dialog-discount.component.scss']
})
export class DialogDiscountComponent {

}
