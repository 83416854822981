<div class="page-profile">
    <div class="container container-page-profile">
        <!-- <div class="loading-container" *ngIf="!profile; else divProfile"> -->
        <!-- <ng-lottie class="lottie" *ngIf="!profile" [options]="loadingLottie" containerClass="loading-box"></ng-lottie> -->
        <!-- </div> -->
        <!-- <ng-template #divProfile> -->
        <!-- Header Profile -->
        <div class="header-profile" [class.header-sitio]="profileMe?.site">
            <div class="img-profile"><img fallimg src="{{urlImages}}/{{profileMe?.imagen}}" alt="Img user" /></div>
            <!-- <div class="img-profile" *ngIf="!profile?.imagen"><img fallimg src="{{urlImages}}/image_default.png" alt="Img user" /></div> -->
            <div class="container-small-header">
                <div class="container-header-profile">
                    <p class="name-profile m-0">{{ profileMe?.nombre }}</p>
                    <!-- <p class="nickname-profile title-mobile" *ngIf="!profile?.oficial">@{{ profile?.nickname }}</p> -->
                    <div class="d-flex align-items-center info-verify">
                        <div class="cont-icon-verify d-grid justify-content-center align-items-center">
                            <i class="fa-light fa-circle-check"></i>
                        </div>
                        <span class="user-verify"
                            *ngIf="(!profileMe?.sms && (!profileMe?.roleFunctions || profileMe?.roleFunctions?.length <= 0))">Usuario
                            validado por email</span>
                        <span class="user-verify"
                            *ngIf="(profileMe?.sms && (!profileMe?.roleFunctions || profileMe?.roleFunctions?.length <= 0))">Usuario
                            verificado por sms</span>

                        <span class="user-verify" *ngIf="profileMe?.roleFunctions?.length > 0">Gestor verificado</span>
                        <!-- <span class="user-verify" *ngIf="!profileMe?.site && !profileMe?.sms && !profileMe?.protocolo">Email verificado</span> -->
                    </div>
                    <p class="info-create m-0" *ngIf="profileMe?.roleFunctions?.length > 0">
                        Herramientas especiales de gestor activadas</p>
                    <!-- <button *ngIf="profileAdmin" class="btn-admin" (click)="openAdmin()">Panel de Administrador</button> -->
                    <button *ngIf="profileMe?.roleFunctions?.length > 0" class="btn-admin" (click)="openPanel()">Panel
                        de Administrador</button>
                </div>
                <i class="fa-light fa-pen fa-lg pointer-in" [routerLink]="['./edit']" *ngIf="!openTab"></i>
            </div>
        </div>
        <div class="header-profile-small">
            <div class="d-flex align-items-center">
                <div class="cont-icon-verify d-grid justify-content-center align-items-center">
                    <i class="fa-light fa-circle-check"></i>
                </div>
                <span class="user-verify"
                    *ngIf="(!profileMe?.sms && (!profileMe?.roleFunctions || profileMe?.roleFunctions?.length <= 0))">Usuario
                    validado por email</span>
                <span class="user-verify"
                    *ngIf="(profileMe?.sms && (!profileMe?.roleFunctions || profileMe?.roleFunctions?.length <= 0))">Usuario
                    verificado por sms</span>
                <span class="user-verify" *ngIf="profileMe?.roleFunctions?.length > 0">Gestor verificado</span>
                <!-- <span class="user-verify" *ngIf="!profileMe?.site && !profileMe?.sms && !profileMe?.protocolo">Email verificado</span> -->
            </div>
            <p class="info-create m-0" *ngIf="profileMe?.roleFunctions?.length > 0">Herramientas especiales de
                creador activadas</p>
        </div>
        <!-- </ng-template> -->

        <!-- <div class="msg-info-container" *ngIf="!profile?.oficial && !profile?.smsValidate && !profile?.organizer && false">
            <i class="fa-light fa-circle-info icon-warning"></i>
            <span class="msg-sent">¿Quieres crear tus propios eventos? Pulsa <span class="link-info-warning"
                    (click)="openValidate()">aquí</span></span>
        </div> -->

        <!-- Menu Profile -->
        <div class="menu-profile" *ngIf="!openTab; else divTab">
            <!-- Eventos -->
            <div class="section section-bookings">
                <!-- <p class="title-section m-0">Compras</p> -->
                <!-- <mat-divider></mat-divider> -->
                <!--<div *ngIf="profileMe?.citylok">
                    // Creados
                    <div class="option"
                        [routerLink]="['/events', profileMe?.id, (profileMe?.nombre.replaceAll(' ','-'))]">
                        <i class="icon-section fa-light fa-camera"></i>
                        <p class="m-0 option-section">{{ profileMe?.site ? 'Eventos publicados' : 'Mis eventos creados'
                            }}
                        </p>
                    </div>
                    <mat-divider></mat-divider>
                    // Borradores
                    <div (click)="openListDrafts()">
                        <div class="option">
                            <i class="icon-section fa-light fa-camera-slash"></i>
                            <p class="m-0 option-section">Eventos sin publicar</p>
                            <i class="icon-open fa-light fa-chevron-right"></i>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
                -->
                <!-- Compras -->
                <div [routerLink]="['/profile/purchases']">
                    <div class="option">
                        <i class="icon-section fa-light fa-ticket"></i>
                        <p class="m-0 option-section">Mis compras</p>
                        <i class="icon-open fa-light fa-chevron-right"></i>
                    </div>
                    <!-- <mat-divider></mat-divider> -->
                </div>

                <!-- Butacas -->
                <!-- <div *ngIf="profileMe?.admin" (click)="openMapList()">
                    <div class="option">
                        <i class="icon-section fa-light fa-loveseat"></i>
                        <p class="m-0 option-section">Mapas de asientos</p>
                        <i class="icon-open fa-light fa-chevron-right"></i>
                    </div>
                    <mat-divider></mat-divider>
                </div> -->
            </div>
            <!-- Preferencias -->
            <div class="section">
                <p class="title-section m-0">Preferencias</p>
                <mat-divider></mat-divider>
                <!-- Intereses -->
                <div *ngIf="!profileMe?.site" [routerLink]="['./preferences']">
                    <div class="option">
                        <i class="icon-section fa-light fa-head-side-heart"></i>
                        <p class="m-0 option-section">Mis intereses</p>
                        <i class="d-none icon-open fa-light fa-chevron-right"></i>
                    </div>
                    <mat-divider></mat-divider>
                </div>

                <!-- Suscripciones -->
                <!-- <div [routerLink]="['./subscriptions']">
                    <div class="option">
                        <i class="icon-section fa-light fa-envelopes-bulk"></i>
                        <p class="m-0 option-section">Suscripciones</p>
                        <i class="d-none icon-open fa-light fa-chevron-right"></i>
                    </div>
                    <mat-divider></mat-divider>
                </div> -->
                <div>
                    <div class="option" (click)="openTabSection(3)">
                        <i class="icon-section fa-light fa-envelopes-bulk"></i>
                        <div>
                            <p class="m-0 option-section">Suscripciones</p>
                            <p class="m-0 option-info">Perteneces como miembro a espacios</p>
                        </div>
                        <i class="icon-open fa-light fa-chevron-right"></i>
                    </div>
                    <mat-divider></mat-divider>
                </div>

                <!-- Notificaciones -->
                <div class="option" (click)="openTabSection(1)">
                    <i class="icon-section fa-light fa-bell"></i>
                    <div>
                        <p class="m-0 option-section">Notificaciones</p>
                        <p class="m-0 option-info">Personalizar las notificaciones</p>
                    </div>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>

                <!-- Newsletter -->
                <div class="option">
                    <i class="icon-section fa-light fa-envelope"></i>
                    <div>
                        <p class="m-0 option-section">Newsletter</p>
                        <p class="m-0 option-info">Recibe las últimas noticias</p>
                    </div>
                    <mat-slide-toggle (ngModelChange)="saveNewsLetter($event)"
                        [(ngModel)]="notifications.newsletter"></mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
            </div>
            <!-- Info -->
            <div class="section">
                <p class="title-section m-0">Información</p>
                <mat-divider></mat-divider>
                <div *ngFor="let op of infoOptions">
                    <div class="option" [routerLink]="op.link">
                        <i *ngIf="op.icon; else divImg" class="icon-section fa-light fa-{{op.icon}}"></i>
                        <ng-template #divImg>
                            <img fallimg src="assets/img/citylok_logo.png" alt="Citylok logo">
                        </ng-template>
                        <p class="m-0 option-section">{{ op.option }}</p>
                        <i class="d-none icon-open fa-light fa-chevron-right"></i>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <!-- Cuenta -->
            <div class="section">
                <p class="title-section m-0">Cuenta</p>
                <mat-divider></mat-divider>
                <!-- Borrar -->
                <!-- <div class="option" (click)="deleteAccount()">
                    <i class="icon-section fa-light fa-triangle-exclamation"></i>
                    <p class="m-0 option-section">Borrar cuenta</p>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider> -->
                <!-- Salir -->
                <div class="option" (click)="logout()">
                    <i class="icon-section fa-light fa-arrow-right-from-bracket"></i>
                    <p class="m-0 option-section">Salir</p>
                    <i class="icon-open fa-light fa-chevron-right"></i>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
        <ng-template #divTab>
            <div class="d-flex align-items-center title-notifications">
                <span (click)="backTab()"><i class="icon-back fa-light fa-arrow-left"></i></span>
                <p class="name-profile m-0">{{ titleTab }}</p>
            </div>
            <div *ngIf="openedMapList; else divOptionOpen">
                <app-profile-maps [profile]="profile"></app-profile-maps>
            </div>
            <ng-template #divOptionOpen>
                <div *ngIf="openDraftsList; else divPreferences">
                    <app-events-draft></app-events-draft>
                </div>
                <ng-template #divPreferences>
                    <div *ngIf="openTab == 1; else divTickets">
                        <app-notifications [notifications]="notifications" [oficial]="profileMe.site"
                            [verified]="profileMe.sms" (emitSave)="saveNotifications($event)"></app-notifications>
                    </div>
                    <ng-template #divTickets>
                        <div *ngIf="openTab == 2; else divSubscriptions">
                            <app-tickets-profile></app-tickets-profile>
                        </div>
                        <ng-template #divSubscriptions>
                            <app-subscriptions [subscriptions]="subscriptions"></app-subscriptions>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</div>