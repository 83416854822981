import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ComunicationsService } from 'src/app/services/comunications.service';

@Component({
  selector: 'app-subscribers-notifications',
  templateUrl: './subscribers-notifications.component.html',
  styleUrls: ['./subscribers-notifications.component.scss']
})
export class SubscribersNotificationsComponent implements OnInit {

  searchText!: string;
  loadingTable: boolean = true;
  finishScroll: boolean = false;
  loadingScroll: boolean = false;
  loaded: boolean = false;
  subscribers!: any[];
  now: Date = new Date();

  displayedColumns: string[] = ['options', 'subject', 'email', 'attachment', 'createdAt'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  page = 1;
  totalPages = 1;

  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;

  private searchTerms = new Subject<string>();
  business = JSON.parse(localStorage.getItem('business') || '{}');
  places = JSON.parse(localStorage.getItem('places') || '[]');

  constructor(private comunicationService: ComunicationsService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.subscribers = [];
    this.getData();
    this.searchTerms.pipe(
      debounceTime(300), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.getFilter();
    });
  }

  getData() {
    this.comunicationService.getSubscribersEmail(this.business?.id, this.places, this.page, this.searchText).subscribe({
      next: (res) => {
        if (res) {
          this.subscribers = this.subscribers.concat(res);
          if (this.subscribers.length <= 0 || this.subscribers.length < 20) {
            this.finishScroll = true;
          }
          this.dataSource = new MatTableDataSource(this.subscribers);
        }
        this.loaded = true;
        this.loadingTable = false;
        this.loadingScroll = false;
      },
      error: (err) => {
        this.loaded = true;
        this.loadingTable = false;
        this.loadingScroll = false;
      }
    });
  }

  getFilter() {
    this.subscribers = [];
    this.page = 1;
    this.loadingTable = true;
    this.loadingScroll = true;
    this.finishScroll = false;
    this.getData();
  }

  searchNotification(res: string) {
    this.searchText = res;
    this.searchTerms.next(this.searchText);
  }

  openInfoCard(msg: string, disabledClose: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFilter();
    });
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingTable) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingScroll = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
      } else {
        this.finishScroll = true;
      }
    }
  }

}