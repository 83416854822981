import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EventosService } from '../../../../services/eventos.service';
import { Location } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppSettings } from 'src/app/app-settings';
import { MapsService } from '../../../../services/maps.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { element } from 'protractor';
import { MatTabGroup } from '@angular/material/tabs';
import { WarningAdminComponent } from '../../shared/warning-admin/warning-admin.component';



@Component({
  selector: 'app-new-evento',
  templateUrl: './new-evento.component.html',
  styleUrls: ['./new-evento.component.scss'],

})
export class NewEventoComponent implements AfterViewInit {

  event: FormGroup;
  eventImage: FormGroup;
  imagenesExtra: FormGroup;

  message = '';

  imgEv = '';

  filePath = '';
  filesPath=[]
  apiMedia: string = AppSettings.MEDIA_URI;

  idE;
  profile!: any;
  hashttag!:[]
  ext:Boolean=false;
  listaCategorias: any[];
  listacomAuto: any[];
  cityOptions: any[];

  selected2=[]

  selectedIndex: number = 0;
  numberOfTabs:number = 4;
  informativo:Boolean = false;
  fechasPrecios = [];
  selected = []
  disableInitTab: boolean = false;
  saved:number=0;
  savedPrice = [];
  enableAdd:boolean = true;
  enableDatesButton:boolean = true;
  @ViewChild('tabGroup',{static:false}) tabGroup: MatTabGroup;
  constructor(private dialog: MatDialog, private location: Location,
    private formBuilder: FormBuilder, private eventosService: EventosService, private userService: UserService,
    private activatedRoute: ActivatedRoute, private mapsService: MapsService,private auth: AuthenticationService)  {

    // })
    //Ambos forms  son temporales hasta que se defina todo bien en este apartado
    this.event = this.formBuilder.group({
      imageurl:'',
      titulo:[''],
      url:'',
      preciodesde:[''],
      localizacion:'',
      latitud:'',
      longitud:'',
      fecha:[''],
      hora:[''],
      hasta:'',
      descripcion:[''],
      activo: true,
      external: false,
      informativo: [''],
      hashtags:[],
      categoria: [],
      ciudad: [''],
      fechahasta: ['']
    })

  }

    ngOnInit(): void {
      this.activatedRoute.params.subscribe(u => this.idE = u.id)
      this.eventosService.getCategories().subscribe(categorias => {
        if(categorias){
          this.listaCategorias = categorias
        }
      })
      this.eventosService.getAllCity().subscribe(cities => {
        if(cities){
          this.cityOptions = cities
        }
      })
    this.getData();
    }
    
    ngAfterViewInit() {
      this.auth.getUsersMe().subscribe(
        result => {this.profile = result
      });
      let i=0;
      for ( this.numberOfTabs ; this.numberOfTabs<= this.numberOfTabs; i++ ) {
        this.tabGroup._tabs['_results'][i].disabled = true;
      }
    }

    nextStep() {
      if (this.selectedIndex != this.numberOfTabs) {
        this.selectedIndex = this.selectedIndex + 1;
        this.selectedIndex == 1 ?this.disableInitTab=true:'';
      }
      this.selectedIndex == 1 ?this.enableDatesButton=true:'';
      this.selectedIndex == 1 ?this.enableAdd=false:'';
      this.selectedIndex == 2 ?this.enableAdd=false:'';
      if(!this.idE && this.fechasPrecios.length >0){
        this.selectedIndex == 1 ?this.enableAdd=true:'';
      }
      if(this.idE){
        this.selectedIndex == 1 ?this.enableAdd=true:'';
        this.selectedIndex == 2 ?this.enableAdd=true:'';
        if( this.fechasPrecios.length >0){
          this.selectedIndex == 2 ?this.enableAdd=false:'';
        }
      }
    }

    previousStep() {
      if (this.selectedIndex != 0) {
        this.selectedIndex = this.selectedIndex - 1;
      }
      this.selectedIndex == 0 ?this.enableAdd=true:'';
    }

    addFechas() {
      this.fechasPrecios.push({ fechainicio: '', fechahasta: '',horainicio:'',horafin:'',precio:''});
      this.enableAdd = false;
      this.enableDatesButton = false;
    }
  
    deleteFechas(index) {
      this.fechasPrecios.splice(index, 1);
      this.saved = this.saved-1;
      this.fechasPrecios.length == 0?this.enableAdd=false:'';
      this.fechasPrecios.length == 0?this.savedPrice=[]:'';
      this.savedPrice.splice(index,1)
    }

    updatePrecio(item,precio){
      let index = this.fechasPrecios[item];
      index['precio'] = precio;
      this.fechasPrecios[index] = item;
      this.savedPrice[item].value = 1000;
      this.savedPrice.forEach(element => {
        element.value == 1000 ? this.enableAdd =true:this.enableAdd =false;
      });
    }

    updateFechasHorarios(item,finicio,fhasta,hinicio,hfin){
      let index = this.fechasPrecios[item];
      index['fechainicio'] = finicio;
      index['fechahasta'] = fhasta;
      index['horainicio'] = hinicio;
      index['horafin'] = hfin;
      this.fechasPrecios[index] = item;
      if(this.fechasPrecios.length > 0 && this.fechasPrecios[item].fechainicio !='' && this.fechasPrecios[item].horainicio) {
        this.saved = item+1;
      }
      this.enableAdd=true
      this.enableDatesButton=true
      this.savedPrice.push({"value":item});
    }

    comChanged(comAutoId){
      comAutoId = comAutoId.split("/").pop()
      this.eventosService.getCitys(comAutoId).subscribe(cities => {
        if(cities){
           this.cityOptions = cities
        }
      })
    }


  getData(){
    if(this.idE) {
       this.eventosService.getEventById(this.idE).subscribe(data => {
          this.event.controls['imageurl'].setValue(data.imageurl);
          this.event.controls['titulo'].setValue(data.titulo);
          if(data.imageurl) {
            this.filePath =data.imageurl;
          }else {
            this.event.addControl('file', new FormControl('', Validators.required));
          }
          this.filesPath = [];
          data.imagenes.forEach(element => {
            this.filesPath.push({"id":element['id'],"imagen":element['imagen']});
          });
          this.event.controls['preciodesde'].setValue(data.preciodesde);
          this.event.controls['latitud'].setValue(data.latitud);
          this.event.controls['longitud'].setValue(data.longitud);
          this.event.controls['fecha'].setValue(data.fecha);
          this.event.controls['hora'].setValue(new Date(data.hora).toUTCString().substring(17,25));
          this.event.controls['hasta'].setValue(data.hasta);
          this.event.controls['descripcion'].setValue(data.descripcion);
          this.event.controls['activo'].setValue(data.activo);
          this.event.controls['localizacion'].setValue(data.localizacion);
          
          if(data.ciudad) {
            this.event.controls['ciudad'].setValue(data.ciudad['@id']);
            this.selected = data.ciudad['@id']
          }

          // this.eventosService.getCity(city).subscribe(city => {
          //   if(city){
          //       this.cityOptions = Array({"uri":city['@id'],"nombre": city.nombre});
          //       this.selected = this.cityOptions[0].uri
          //   }
          // })
      
        this.ext = data.external
        this.event.controls['external'].setValue(data.external);
        
        this.event.controls['informativo'].setValue(data.informativo);
        this.event.controls['hashtags'].setValue(data.hashtags);
        if(this.event.controls["informativo"].value == true){
          this.informativo = true;
        }

        if(data.categoria) {
          this.event.controls.categoria.setValue(data.categoria['@id'])
        }
      })
    }
  }

  submit(){
    if(this.event.valid){
      if(this.idE){
        if (this.event.controls['hashtags'].value != null){
          if(this.event.controls['hashtags'].value.length > 0 && typeof(this.event.controls['hashtags'].value)=='string'){
            let hashtagArray = (this.event.controls['hashtags'].value).split(',');
            this.event.controls['hashtags'].value
            this.event.controls.hashtags.setValue(hashtagArray);
          }
        }
        this.eventosService.putEventos(this.idE, this.event.value).subscribe(data => {
          if (data) {
           if( this.event.value.file){
              this.eventImage = this.formBuilder.group({
                eventId: this.idE,
                file: this.event.value.file
              })
             this.eventosService.putEventoImage(this.eventImage.value).subscribe()
           }
           if(this.filesPath.length >0){
              this.eventosService.putExtraEventoImage(this.idE,this.filesPath).subscribe()
           }
          } 
          if(data['@id']){
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Evento editado correctamente",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();
            });
          }else{
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Error al editar el evento",
                icon: "fas fa-times-circle",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();
            });
          }
        })
      }else{
        let hashtagArray = (this.event.controls['hashtags'].value).split(',');
        this.event.controls['hashtags'].value
        this.event.controls.hashtags.setValue(hashtagArray);
        this.event.addControl("usuarios",new FormControl(''));
        this.event.controls.usuario.setValue('api/users/'+this.profile.id)
        this.eventosService.postEventos(this.event.value).subscribe(data=>{
          if(data){
            var id = data.id
            this.eventImage = this.formBuilder.group({
              eventId: id,
              file: this.event.value.file
            })
            this.eventosService.putEventoImage(this.eventImage.value).subscribe()
          }
          if(data['@id']){
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Evento creado correctamente",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();
            });
          }else{
            const dialogRef =  this.dialog.open(WarningAdminComponent, {
              width: '400px',
              height: '350px',
              data: {
                text: "Error al crear el evento",
                icon: "fas fa-times-circle",
                confirm:"true"
              },
            })
            dialogRef.afterClosed().subscribe(result => {
              this.location.back();
            });
          }
        })
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InfoCardComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.location.back();
      }
    });
  }

  setImg(event){
    this.imgEv = (event.target as HTMLInputElement).value.trim();;
  }

  //Imagen
  onImageChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.event.addControl('file', new FormControl(''));
      const reader = new FileReader();
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          this.filePath = imageSrc;
          this.event.patchValue({
            file: imageSrc
          });
        };
      }
    }
  }

  extraImages(event) {
    if (event.target.files && event.target.files.length) {
      const files= event.target.files[0];
      const reader = new FileReader();
      if (files.type.match('image.*')) {
        reader.readAsDataURL(files);
        reader.onload = () => {
          var imageSrc = reader.result as string;
          this.filesPath.push({file:imageSrc});
        };
      }
    }
  }

  textArea: any = '';
  @Output() emitDescription = new EventEmitter<any>();

  public isEmojiPickerVisible: boolean;

  public addEmoji(event) {
    this.isEmojiPickerVisible = false;
    return event.emoji.native;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí la descripción del evento',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Satoshi-Regular',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

}
