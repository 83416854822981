<div class="qr-buy">
    <div class="d-grid align-items-center">
        <p class="title-event">Así soy yo</p>
        <div class="d-flex align-items-center date-event mb-2">
            <i class="fa-light fa-calendar"></i>
            <span>13 Junio</span>
            <span> | </span>
            <span>20:00h</span>
        </div>
        <div>
            <p class="name-ticket m-0">Entrada general</p>
            <p class="price-ticket">15,95 €</p>
        </div>

    </div>
    <div class="content-qr">
        <i class="fa-light fa-qrcode fa-3x"></i>
    </div>
</div>